import { PGUtilities } from '../pg-utilities';
import { PGLocation } from './map.model';
import { SurveyData, SurveyDataQuestion } from './survey.model';
import { TicketsData, TicketsDataOtherTickets } from './tickets.model';
import { TimetableData, TimetableDataList } from './timetable.model';

export class PGAccommodation {
    accommodation_description: string;
    accommodation_type:'room'|'apartment';
    accommodation_room_services:string;
    accommodation_appartaments_services:string;
    accommodation_prices:string;
}

export class PGSurveyAnswersQuestion extends SurveyDataQuestion {
    answer:string|boolean;
}

export class PGSurveyAnswers extends SurveyData {

    questions:Array<PGSurveyAnswersQuestion>

    constructor(fromData?:SurveyData) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                this[i] = JSON.parse(JSON.stringify(fromData[i]))
            }
        }
    }
}

export type PGPaymentMethod = 'bonifico'|'sul_posto'|'online'|'broker'|'gratis'|'price_on_request'

export class PGTicketsSelectionOtherTickets extends TicketsDataOtherTickets {
    quantity:number = 0;
}

export class PGTicketsSelection extends TicketsData {
    fullQuantity:number = 0;

    otherTickets:Array<PGTicketsSelectionOtherTickets>

    constructor(fromData?:TicketsData) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                this[i] = JSON.parse(JSON.stringify(fromData[i]))
            }

            for(let _cOther of this.otherTickets) {
                _cOther.quantity = 0;
            }
        }
    }
}

export class PGUserContact {
    name:string = null;
    email:string = null;
    phone:string = null;
}

export class PgAvailabilitySelectData {
    id:string = null;
    capacity:number = 1;
    availability:TimetableDataList = null;
    languages?:Array<string> = null;
    data?:any = null;
}


export class PGAvailabilitySelection {
    id:string = null;

    date:string = null;
    begin?:string = null;
    end?:string = null;
    timezone?:string = null;

    availability?: {
        current:number,
        total:number
    } = null;
}

export class PGAvailabilitySelectionLapse {
    from:string = null;
    to:string = null;
}

export type PGProductType = 'experience'|'host'|'eatery';

export class PGProductData {
    type:PGProductType;
    subtype:string;

	id: string;
    pid: string;
	title: string;
	category: string;
	tags: string;
	description: string;
    languages:Array<string>;
    parent:string;

    province: string;
	address: string;
	city: string;
	zipcode:  string;
	geolocation: PGLocation;

	payment: Array<string>;
    payment_method: Array<string>;
    bank_transfer_method: { iban: string, swift: string }
	target: Array<string>;

    open_event: boolean;
	maximum_participants: number;
	minimum_guaranteed: number;
    overbooking: boolean;
	broker_discount: number;
    booking_deadline: number;
	cancellation_deadline: number;
    price_on_request: boolean;
    tickets: TicketsData;
    supplier:string;

    availability:Array<TimetableData>;

    videos: Array<string>;
	images: Array<string>;
    
    survey?:SurveyData;

    translations:Array<{
        id:string,
        language:string,
        title: string,
        description: string
    }>

    bookable = true;
    booking?:string;
    mandatory_email?:boolean;

    raw_data?:any = null;

    constructor(fromData?:any, dataType?:'experience'|'host'|'eatery') {
        if(dataType == null) dataType = 'experience'

        this.type = dataType;

        if(fromData != null) {
            this.raw_data = fromData;

            this.subtype = fromData.type;

            for(let i of ['id','pid','tags','description','parent','province','address','city','zipcode','geolocation','booking','mandatory_email']) {   
                this[i] = fromData[i];
            }

            for(let i of ['languages','videos']) {   
                this[i] = PGUtilities.tryParseJSON(fromData[i]);
            }

            if(dataType == 'experience' || dataType == 'host') {
                this.broker_discount = fromData.broker_discount;
            }

            if(dataType == 'experience') {
                for(let i of ['title','category','bank_transfer_method','maximum_participants','minimum_guaranteed','overbooking','booking_deadline','cancellation_deadline','supplier','open_event']) {
                    this[i] = fromData[i];
                }

                for(let i of ['payment','target','images']) {   
                    this[i] = PGUtilities.tryParseJSON(fromData[i]);
                }

                if(fromData.tickets != null) {
                    this.tickets = new TicketsData(fromData.tickets);
                }
   
                if(this.booking_deadline == null) this.booking_deadline = 12;
            }
            else if(dataType == 'host' || dataType == 'eatery') {
                this.supplier = dataType.charAt(0).toUpperCase() + dataType.substring(1) + '_' + fromData.id;

                this.title = fromData.name;
                this.category = fromData.type;

                let _imagesMerge = [];
                for(let i in fromData) {
                    if(/^images/.test(i) && fromData[i] != null) {
                        let _cParsedList = PGUtilities.tryParseJSON(fromData[i]);
                        for(let _cParsed of _cParsedList) {
                            _imagesMerge.push(_cParsed)
                        }
                    }
                }

                this.images = _imagesMerge;
            }

            if(fromData.availability != null) {
                this.availability = PGUtilities.tryParseJSON(fromData.availability);
            }

            if(fromData.survey != null) {
                this.survey = new SurveyData(fromData.survey);
            }

            if(fromData.translations != null) {
                this.translations = [];
                for(let _cTranslation of fromData.translations) {
                    if(dataType == 'experience') {
                        this.translations.push(_cTranslation)
                    }
                    else if(dataType == 'host' || dataType == 'eatery') {
                        this.translations.push({
                            id: _cTranslation.id,
                            language: _cTranslation.language,
                            title: _cTranslation.name,
                            description: _cTranslation.description,
                        })
                    }
                }
            }

            this.bookable = fromData.bookable != false;
            this.price_on_request = fromData.price_on_request != false;
        }
    }
}

export class PGSupplierData {
    id: string;
    name: string;
    company_name: string;

    landline_number: string;
    phone: string;
    email: string;
    website: string;
    languages: string;
    geolocation: PGLocation;
    region: string;
    province: string;
    city: string;
    address: string;
    zipcode: string;

    logo?: string;
    description?:string;
    store_url?:string;

    translations:Array<{
        id:string,
        language:string,
        name: string,
        description: string
    }>
}

export class PGBrokerData {
    id:string;
    name:string;
}

export class PGVoucherData {
    id:string = null;
    code:string = null;
    discount:number = null; // 0-100
    start:Date = null;
    end:Date = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in this) {
                if(fromData[i] != null) {
                    if(i == 'start' || i == 'end') {
                        this[i] = new Date(fromData[i]) as any
                    }
                    else {
                        this[i] = fromData[i]
                    }
                }
            }
        }
    }
}

export type PGBookingStatus = 'pending'|'canceled'|'available'|'confirmed';

export class PGBookingData {
    id:string = null;

    // queste property vengono fillate dal server

    date?:string;
    start?:string;
    end?:string;

    experience_type:'experience'|'host'|'eatery' = null;

    experience_id:string = null;
    experience_data:PGProductData = null;

    availability:PGAvailabilitySelection|PGAvailabilitySelectionLapse = null;
    tickets:PGTicketsSelection = null;

    booking_data:any = null;

    survey_answers:PGSurveyAnswers = null;

    status:PGBookingStatus = null;
    optioned:boolean = false;

    price:number = null;

    discount_code:string = null;
    discount_value:number = 0;

    broker_code:string = null;
    
    payment:PGPaymentMethod = null;

    user_name:string = null;
    user_email:string = null;
    user_phone:string = null;

    language:string = null;
    additional_info:string = null;

    pid:string = null;

    user_id:string = null;

    related_user:{ id: string, name: string, surname: string } = null

    constructor(bookingId?:string, productData?:PGProductData, selectedLanguage?:string, selectedAvailability?:PGAvailabilitySelection|PGAvailabilitySelectionLapse, selectedTickets?:PGTicketsSelection, bookingData?:any, surveyAnswers?:PGSurveyAnswers, userContact?:PGUserContact, paymentMethod?:PGPaymentMethod, additionalInformations?:string, voucherCode?:string, brokerCode?:string) {
        if(bookingId != null) this.id = bookingId;

        if(productData != null) {
            this.experience_type = productData.type;
            this.experience_id = productData.id;

            if(productData.raw_data != null) {
                this.experience_data = productData.raw_data;
            }
            else {
                this.experience_data = productData;
            }
        }
        
        if(selectedLanguage != null) {
            this.language = selectedLanguage;
        }

        if(selectedAvailability != null) this.availability = selectedAvailability;

        if(bookingData != null) {
            this.booking_data = bookingData;
        }

        if(selectedTickets != null) {
            this.tickets = selectedTickets;

            let _cTotal = 0;
            _cTotal += this.tickets.fullQuantity * this.tickets.fullPrice;

            for(let _cOther of this.tickets.otherTickets) {
                _cTotal += _cOther.quantity * _cOther.price;
            }

            this.price = _cTotal;
        }

        if(surveyAnswers != null) {
            this.survey_answers = surveyAnswers;
        }

        if(userContact != null) {
            this.user_name = userContact.name;
            this.user_email = userContact.email;
            this.user_phone = userContact.phone;
        }

        if(paymentMethod != null) {
            this.payment = paymentMethod;
        }

        if(additionalInformations != null) {
            this.additional_info = additionalInformations;
        }

        if(voucherCode != null) {
            this.discount_code = voucherCode;
        }

        if(brokerCode != null) {
            this.broker_code = brokerCode;
        }
    }
}

export class PGBookingsCountData { 
    [year:number] : { 
        [month:number]: {
            [day:number]: PgBookingsCountDayData
        } 
    } 
}

export class PgBookingsCountDayData { 
    [time:string]: number 
}