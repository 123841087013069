<div class="EFScreensaver">
    <div class="text-light pg-animation-fadein">
        <div>
            <app-ef-showcase [productsData]="productsData"></app-ef-showcase>
        </div>
        <div class="EFScreensaver-Action">
            <div>
                <div>
                    {{ 'experience-finder.ef-screensaver.touch-to-start' | locTranslate }}
                </div>    
            </div>
        </div>
    </div>
</div>
