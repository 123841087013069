<div *ngIf="accommodationData != null" class="PGProductHostAccommodation">
    <div class="PGProductHostAccommodation-Prop" pdfgen="line">
        <span>{{ 'RESOURCES.Host.fields.accommodation_type.label' | locTranslate }}</span>
        <b *ngIf="accommodationData.accommodation_type != null && accommodationData.accommodation_type != null" >{{ 'OPTIONMAPS.Host.accommodation_type.' + accommodationData.accommodation_type | locTranslate }}</b>
    </div>
    <div pdfgen="line" class="mt-3" [innerHTML]="getAccommodationDescription()"></div>
    <div *ngIf="getAccommodationServices(); let services" pdfgen="line" [innerHTML]="services"></div>
    <ng-container *ngIf="getAccommodationPrices(); let prices">
        <hr/>
        <div class="PGProductHostAccommodation-Prop" pdfgen="line">
            <span>{{ 'RESOURCES.Host.fields.accommodation_prices.label' | locTranslate }}</span>
            <b [innerHTML]="prices"></b>
        </div>
    </ng-container>
</div>