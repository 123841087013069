<div class="PGResourceViewTable {{!isInitialized ? 'PGResourceViewTable--NotInitialized' : ''}}">
    <div class="PGResourceViewTable-Actions mb-3">
        <ng-container  *ngIf="actions.create">
            <button *ngFor="let draft of unsavedDrafts" class="btn btn-sm btn-primary ms-1" (click)="resumeDraft(draft.id)">
                <i class="fa-regular fa-share"></i><span>{{ 'pg-resource-view-table.resume-draft' | locTranslate:{ draft: draft.description} }}</span>
            </button>
            <button class="btn btn-sm btn-primary ms-1" (click)="createNew()">
                <i class="fa-regular fa-plus"></i><span>{{ 'pg-resource-view-table.add-resource' | locTranslate:{ resource: resourceName} }}</span>
            </button>
        </ng-container>
    </div>
    <div class="PGResourceViewTable-Wrapper">
        <table class="container-fluid">
            <thead class="PGResourceViewTable-Header bg-light PGResourceViewTable-Header--Outer" #tableHeaderOuter>
                <tr class="row PGResourceViewTable-Header-Row"></tr>
            </thead>
        </table>
        <div class="PGResourceViewTable-Scrollable" #tableScrollable>
            <table class="container-fluid">
                <thead class="PGResourceViewTable-Header bg-light PGResourceViewTable-Header--Inner" #tableHeaderInner>
                    <tr *ngIf="viewConfig != null" class="row PGResourceViewTable-Header-Row">
                        <th class="col PGResourceViewTable-Col-Actions"></th>
                        <ng-container *ngFor="let dataCol of columnStatus">
                            <th class="col">
                                <span>
                                    <span>{{(dataCol.label != null ? dataCol.label : dataCol.name) | locTranslate}}</span>
                                    <small *ngIf="dataCol.filter" class="text-muted"><i class="fa-regular fa-filter"></i></small>
                                    <small *ngIf="dataCol.order; let direction;" class="text-muted">
                                        <i *ngIf="direction == 'asc'" class="fa-regular fa-chevron-down"></i>
                                        <i *ngIf="direction == 'desc'" class="fa-regular fa-chevron-up"></i>
                                    </small>
                                </span>
                            </th>
                        </ng-container>
                        <th class="col PGResourceViewTable-Col-Flags"></th>
                    </tr>
                </thead>
                <tbody *ngFor="let dataRow of dataRows" class="PGResourceViewTable-Body pg-animation-fadein" (click)="onRowClick($event, dataRow);">
                    <tr class="row PGResourceViewTable-Body-Row {{getRowClass(dataRow)}}">
                        <td class="col PGResourceViewTable-Col-Actions">
                            <div>
                                <app-pg-resource-element-menu [resourceId]="resourceId" [elementId]="dataRow.id" [elementData]="dataRow" [baseURL]="baseURL" [readOnly]="readOnly" (dataChange)="handleElementChange(dataRow.id, $event)"></app-pg-resource-element-menu>
                            </div>
                        </td>
                        <ng-container *ngFor="let dataCol of columnStatus">
                            <td *ngIf="dataCol.field != null" class="col PGResourceViewTable-Col-Field PGResourceViewTable-Col-Field--Type-{{dataCol.type.type}}">
                                <span locFormat="byConfig" [formatValue]="[ dataRow[dataCol.field], dataCol.type ]"></span>
                            </td>
                            <td *ngIf="dataCol.roleview != null" class="col PGResourceViewTable-Col-Roleview">
                                <span [innerHTML]="getRoleview(dataCol.roleview, dataRow)"></span>
                            </td>
                            <td *ngIf="dataCol.cluster != null" class="col PGResourceViewTable-Col-Cluster">
                                <span *ngFor="let clusterColumn of getClusterColumns(dataCol.cluster)" locFormat="byConfig" [formatValue]="[ dataRow[clusterColumn], dataCol.type ]"></span>
                            </td>
                        </ng-container>
                        <td class="col PGResourceViewTable-Col-Flags">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <div *ngIf="actions.book" class="me-3">
                                        <i *ngIf="dataRow.bookable" class="fa-regular fa-book"></i>
                                        <i *ngIf="!dataRow.bookable" class="fa-regular fa-book PGSlashedIcon text-muted">
                                            <i class="fa-regular fa-slash text-white"></i>
                                            <i class="fa-regular fa-slash"></i>
                                        </i>
                                    </div>
                                    <div *ngIf="actions.publish" class="me-3">
                                        <i *ngIf="dataRow.published" class="fa-regular fa-eye"></i>
                                        <i *ngIf="!dataRow.published" class="fa-regular fa-eye-slash text-muted"></i>
                                    </div>
                                </div>
                                <div class="PGResourceViewTable-Col-Flags-Users">
                                    <div *ngIf="dataRow.user != null" class="me-3">
                                        <small class="text-muted" title="{{getUserLabel(dataRow.user, true)}}">
                                            <i class="fa-regular fa-user"></i><span>{{getUserLabel(dataRow.user)}}</span>
                                        </small>
                                    </div>
                                    <div *ngIf="dataRow.group_id != null" class="me-3">
                                        <small class="text-muted">
                                            <i class="fa-regular fa-users"></i><span>{{getGroupLabel(dataRow.group_id)}}</span>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot class="PGResourceViewTable-Footer" *ngIf="isAtEnd && hasFooter()">
                    <tr class="row PGResourceViewTable-Footer-Sums">
                        <td *ngIf="hasExtendedActions()" class="col PGResourceViewTable-Col-Actions"></td>
                        <ng-container *ngFor="let dataCol of columnStatus">
                            <td class="col">
                                <span *ngIf="dataCol.sum != null">
                                    <small>{{ 'pg-resource-view-table.footer-sum' | locTranslate }}</small>
                                    <span locFormat="byConfig" [formatValue]="[ dataCol.sum, dataCol.type ]"></span>
                                </span>
                            </td>
                        </ng-container>
                    </tr>
                </tfoot>
            </table>
            <div class="PGResourceViewTable-After">
                <app-pg-view-more [hidden]="isLoadingData || isAtEnd" (viewMore)="loadData()"></app-pg-view-more>
                <div  *ngIf="isLoadingData" class="PGResourceViewTable-After-LoadingData {{isLoadingData ? 'PGResourceViewTable-LoadingData--Show' : ''}}">
                    <app-pg-loading></app-pg-loading>
                </div>
            </div>
        </div>
    </div>
</div>