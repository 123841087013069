import { Component, Input, OnInit } from '@angular/core';import { ConfigResourceView } from '../../../models/config.resources.model';
import { PgSelectOption } from '../../../pg-ui-controls/pg-select/pg-select.component';
import { ConfigService } from '../../../services/config.service';
;
import { PGViewResourceConfigComponent } from '../../resource-view-common';

export class PgResourceViewCalendarConfig
{
    dateField:string;
}

@Component({
  selector: 'app-pg-resource-view-calendar-config',
  templateUrl: './pg-resource-view-calendar-config.component.html',
  styleUrls: ['./pg-resource-view-calendar-config.component.scss']
})
export class PgResourceViewCalendarConfigComponent extends PGViewResourceConfigComponent implements  OnInit {
    @Input() view:ConfigResourceView<PgResourceViewCalendarConfig>;

    noDateFields = false;

    viewDateOptions:Array<PgSelectOption> = null;

    constructor(private configService:ConfigService) {
        super();
    }

    ngOnInit() {
        this.viewDateOptions = [];

        let _cFieldTypes = this.configService.getResourceFieldTypes(this.view.resource);

        for(let i in _cFieldTypes) {
            if(_cFieldTypes[i].type == 'date' || _cFieldTypes[i].type == 'datetime') {
                this.viewDateOptions.push({
                    value: i,
                    text: _cFieldTypes[i].label
                })
            }
        }

        if(this.viewDateOptions.length == 0) {
            this.noDateFields = true;
        }
        else {
            if(this.view.config == null) {
                this.view.config = {
                    dateField: this.viewDateOptions[0].value
                }
            }
        }
    }

}
