import { Component, Input, forwardRef, ElementRef, Directive, QueryList, ContentChildren } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

/**
 * @ignore
 */

@Directive({
    selector: 'app-pg-multistate > state'
})

export class PgMultistateComponentState {
    @Input() value:any;

    constructor(public el:ElementRef) {
    }

    setSelected(selected:boolean) {
        this.el.nativeElement.style.display = selected ? 'inline' : 'none'
    }
}

/**
 * Controllo pulsante multistato: su click cicla il proprio valore in base agli elementi &lt;state&gt; che contiene.<br/>
 * Gli elementi &lt;state&gt; specificano un attributo "value" a cui il controllo viene impostato, il loro contenuto HTML viene visualizzato quando lo stato è selezionato
 */

@Component({
    selector: 'app-pg-multistate',
    templateUrl: './pg-multistate.component.html',
    styleUrls: ['./pg-multistate.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgMultistateComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgMultistateComponent),
        multi: true,
    }]
})

export class PgMultistateComponent implements ControlValueAccessor, Validator {

    @ContentChildren(PgMultistateComponentState) elements: QueryList<PgMultistateComponentState>;

    public value = null;

    setNext() {
        let _cArray = this.elements.toArray();
        let _cIndex = -1;

        for(let i = 0; i < _cArray.length; i++) {
            if(_cArray[i].value == this.value) {
                _cIndex = i;
                break;
            }
        }

        _cIndex = (_cIndex + 1) % _cArray.length;
        this.value = _cArray[_cIndex].value;

        this.setDisplay();

        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(this.value);
    }

    constructor() { }

    hasDisplay = false;

    setDisplay() {
        this.hasDisplay = false;

        if(this.elements != null) {
            this.elements.forEach(element => {
                let _isSelected = this.value == element.value;
                element.setSelected(_isSelected);
                
                if(_isSelected) this.hasDisplay = true;
            })
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
        this.setDisplay();
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        let _isValid = false;

        if(this.elements != null) {
            this.elements.forEach(element => {
                if(element.value == this.value) _isValid = true;
            });
        }

        if(_isValid) return null;
        else return {
            valid: false
        };
    };
}