import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NotificationsService, PGNotification } from '../../services/notifications.service';
import { PGTask, TasksService } from '../../services/tasks.service';
import { DataService } from '../../services/data.service';
import { PgNotificationComponent } from '../../pg-ui-elements/pg-notification/pg-notification.component';

@Component({
  selector: 'app-pg-tasks-view',
  templateUrl: './pg-tasks-view.component.html',
  styleUrls: ['./pg-tasks-view.component.scss']
})
export class PgTasksViewComponent implements OnInit, OnDestroy {

    @ViewChild(NgbDropdown) dropdownComponent:NgbDropdown;

    tasks:Array<PGTask> = null;
    currentNotifications:Array<PGNotification> = null;

    notificationsHistory:Array<PGNotification> = null;
    historyLimit = 10;
    historyPage = -1;
    isHistoryAtEnd = false;
    isLoadingHistory = false;

    showCompleted = false;

    constructor(private tasksService:TasksService, private notificationsService:NotificationsService, private dataService:DataService) { }

    private _taskServiceSubscription:Subscription = null;

    ngOnInit(): void {
        this.tasks = this.tasksService.tasks;
        this._taskServiceSubscription = this.tasksService.statusChange.subscribe((change) => {
            if(change.action == 'add') {
                this.dropdownComponent.open();
            }
            else if(change.action == 'success') {
                setTimeout(() => {
                    this.doneTasks[change.task.id] = true;
                }, 2500)
            }
        })

        this.currentNotifications = [];
        
        for(let _notification of this.notificationsService.notifications) {
            if(!_notification.local) {
                this.currentNotifications.push(_notification)
            }
        }

        this.notificationsService.onNotification.subscribe((change) => {
            if(change.operation == 'add') {
                if(!change.notification.local) {
                    this.currentNotifications.unshift(change.notification)
                    this.resetHistory(true);
                }
            }
            else if(change.operation == 'del') {
                for(let i = 0; i < this.currentNotifications.length; i++) {
                    if(this.currentNotifications[i].id == change.notification.id) {
                        this.currentNotifications.splice(i, 1);
                        this.resetHistory(true);
                        break;
                    }
                }
            }
        })

        this.resetHistory();
    }

    private _resetHistoryTimeout = null;

    resetHistory(withDelay?:boolean) {
        this.notificationsHistory = [];
        this.historyPage = -1;
        this.isHistoryAtEnd = false;

        clearTimeout(this._resetHistoryTimeout);

        if(withDelay) {
            this._resetHistoryTimeout = setTimeout(() => {
                this.checkLoadHistory();
            }, 1000)
        }
        else {
            this.checkLoadHistory();
        }
    }

    checkLoadHistory() {
        if(!this.isHistoryAtEnd) {
            this.historyPage++;
            this.isLoadingHistory = true;

            this.dataService.getNotifications({ 
                offset: this.historyPage * this.historyLimit,
                limit: this.historyLimit + 1,
                filter: [
                    { field: 'status', operator: '==', value: ['read'] }
                ]
            }).subscribe((data) => {
                this.isLoadingHistory = false;
                if(data.length < this.historyLimit + 1) this.isHistoryAtEnd = true;
    
                for(let i = 0; i < this.historyLimit && i < data.length; i++) {
                    this.notificationsHistory.push(new PGNotification(data[i]))
                }
            }, () => {
                this.isHistoryAtEnd = true;
                this.isLoadingHistory = false;
            })
        }
    }

    ngOnDestroy() {
        if(this._taskServiceSubscription != null) this._taskServiceSubscription.unsubscribe();
    }

    getProgressBarClass(task:PGTask) {
        switch(task.status) {
            case 'waiting': return 'bg-secondary opacity-00';
            case 'success': return 'bg-success';
            case 'error': return 'bg-danger';
            case 'running': return 'progress-bar-striped progress-bar-animated' + (task.progress == null ? ' bg-secondary' : '');
        }
    }

    getProgressBarWidth(task:PGTask) {
        if(task.status == 'running' && task.progress != null) return (task.progress * 100) + '%';
        else return '100%'
    }

    getTaskCount(status:'todo'|'done'|'error') {
        let _cCount = 0;

        for(let _cTask of this.tasks) {
            if(status == 'todo') {
                if(_cTask.status == 'waiting' || _cTask.status == 'running') {
                    _cCount++;
                }
            }
            else if(status == 'done') {
                if((_cTask.status == 'success' || _cTask.status == 'error') && this.doneTasks[_cTask.id]) {
                    _cCount++;
                }
            }
            else if(status == 'error') {
                if(_cTask.status == 'error' && !this.doneTasks[_cTask.id]) {
                    _cCount++;
                }
            }
        }

        return _cCount;
    }

    doneTasks: {[id:number]: boolean} = {};

    isTaskHidden(task:PGTask) {
        if(this.showCompleted) return false;
        else {
            if(task.status == 'success' || task.status == 'error') {
                return this.doneTasks[task.id]
            }
        }
    }

    areAllTasksHidden() {
        if(this.showCompleted) return this.tasks.length == 0;
        else {
            for(let _cTask of this.tasks) {
                if(!this.isTaskHidden(_cTask)) return false;
            }
        }

        return true;
    }

    hideErrorTask(task:PGTask) {
        this.doneTasks[task.id] = true;
    }

    redoErrorTask(task:PGTask) {
        this.doneTasks[task.id] = true;
        this.tasksService.addTask(new PGTask(task.action, task.name, task.description))
    }

    getUnreadNotificationsCount() {
        let _count = 0;

        if(!this._isOpen) {
            for(let _notification of this.currentNotifications) {
                if(_notification.user_id != null && _notification.status != 'read') {
                    _count++;
                }
            }
        }

        return _count;
    }

    private _isOpen = false;

    @ViewChildren(PgNotificationComponent) notificationComponents:QueryList<PgNotificationComponent>

    onOpenChange(isOpen:boolean) {
        this._isOpen = isOpen;

        if(!isOpen) {
            let _markAsRead:Array<string> = [];

            for(let _notification of this.currentNotifications) {
                if(_notification.status != 'read') {
                    _markAsRead.push(_notification.id)
                }
            }

            this.notificationComponents.forEach((item) => {
                if(item.childNotifications != null) {
                    for(let _notification of item.childNotifications) {
                        if(_notification.status != 'read') {
                            _markAsRead.push(_notification.id)
                            _notification.status = 'read'
                        }
                    }
                }
            })

            if(_markAsRead.length > 0) {
                this.notificationsService.markAsRead(_markAsRead)
            }
        }
    }
}
