import { Component, Input, OnInit } from '@angular/core';

import moment from 'moment';
import { PGAccommodation, PGAvailabilitySelection, PGAvailabilitySelectionLapse, PGBookingStatus, PGProductData, PGSupplierData, PGSurveyAnswers, PGTicketsSelection } from '../../../models/booking.model';
import { LocalizationService } from '../../../services/localization.service';
import { BookingService } from '../../../services/booking.service';
import { EnvironmentService } from '../../../services/environment.service';

@Component({
  selector: 'app-pg-booking-product',
  templateUrl: './pg-booking-product.component.html',
  styleUrls: ['./pg-booking-product.component.scss']
})
export class PgBookingProductComponent implements OnInit {

    @Input() status:PGBookingStatus;
    @Input() productData:PGProductData;
    @Input() supplierData:PGSupplierData;
    @Input() selectedAvailability:PGAvailabilitySelection|PGAvailabilitySelectionLapse;
    @Input() selectedTickets:PGTicketsSelection;
    @Input() selectedAccommodation:PGAccommodation;
    @Input() surveyAnswers:PGSurveyAnswers;
    @Input() paymentMethod:string;

    @Input() discountCode:string;
    @Input() discountValue:number;

    @Input() brokerCode:string;

    @Input() userName:string;
    @Input() userEmail:string;
    @Input() userPhone:string;

    @Input() isOptioned:boolean;

    @Input() selectedLanguage:string;
    @Input() additionalInformations:string;

    @Input() pid:string;

    @Input() compact:boolean|'never';

    constructor(private localizationService:LocalizationService, private bookingService:BookingService, private environmentService:EnvironmentService) { }

    private _nowDate:Date = null;

    currentTimezone:string = null;

    ngOnInit(): void {
        this._nowDate = new Date();
        this.currentTimezone = moment.tz.guess();
    }

    ngOnChanges() {
    }
    
    formatSelectedAvailabilityDate() {
        return this.localizationService.format.date(moment((this.selectedAvailability as PGAvailabilitySelection).date, 'YYYY-MM-DD').toDate())
    }

    formatSelectedAvailabilityTime() {
        return this.localizationService.format.time((this.selectedAvailability as PGAvailabilitySelection).begin)
    }

    formatSelectedAvailabilityLapseFrom() {
        return this.localizationService.format.time((this.selectedAvailability as PGAvailabilitySelection).begin)
    }

    formatSelectedAvailabilityLapseTo() {
        return this.localizationService.format.time((this.selectedAvailability as PGAvailabilitySelection).end)
    }

    formatSelectedAvailabilityDateFrom() {
        return this.localizationService.format.date(moment((this.selectedAvailability as PGAvailabilitySelectionLapse).from, 'YYYY-MM-DD').toDate())
    }
    
    formatSelectedAvailabilityDateTo() {
        return this.localizationService.format.date(moment((this.selectedAvailability as PGAvailabilitySelectionLapse).to, 'YYYY-MM-DD').toDate())
    }

    formatDiscountPrice() {
        if(this.discountValue == 100) return 'GRATIS';
        else {
            let _cPrice = this.getSelectedTicketsTotal() * (1 - this.discountValue / 100);
            _cPrice = Math.round(_cPrice * 100) / 100;
            return (_cPrice + ' €')
        }
    }

    getSelectedTicketsTotal() {
        return this.bookingService.calculateSelectedTicketsTotal(this.selectedTickets);
    }

    getBrokerQuota() {
        return this.bookingService.calculateBrokerQuota(this.productData, this.selectedTickets, this.discountValue)
    }

    formatTicketName(name:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(name)
    }

    formatQuestionText(text:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(text)
    }

    formatPrice(val:number, useGratis?:boolean) {
        if(val == null) return null;
        else if(val == 0) {
            return useGratis ? 'GRATIS' : '';
        }
        else return val.toFixed(2) + ' €'
    }

    getBonificoCausale() {
        return this.localizationService.translate('pg-booking-product.payment-method-bonifico-causale') + ' ' + (this.productData.pid || this.productData.id) + ' ' + (this.userEmail || this.userPhone) + ' ' + this.formatSelectedAvailabilityDate() + ' ' + this.formatSelectedAvailabilityTime()
    }

    getLanguageLabel() {
        return this.localizationService.languageLabels[this.selectedLanguage]
    }

    getWebsiteURLClean() {
        if(this.environmentService.externalParameters.returnUrl != null) {
            return this.environmentService.externalParameters.returnUrl;
        }
    }

    getStatusColor() {
        switch(this.status) {
            case 'confirmed': return 'success';
            case 'canceled': return 'danger';
            case 'available': return 'primary';
            case 'pending': return 'secondary';
        }
    }

    showNotCancelableWarning() {
        if(this.productData.type == 'experience' && this.selectedAvailability != null) {
            let _cancellationDeadline:Date = null;
            
            if('from' in this.selectedAvailability) {
                _cancellationDeadline = new Date(this.selectedAvailability.from + 'T00:00:00')
            }
            else {
                _cancellationDeadline = new Date(this.selectedAvailability.date + 'T' + this.selectedAvailability.begin); 
            }

            _cancellationDeadline.setTime(_cancellationDeadline.getTime() - this.productData.cancellation_deadline * 1000 * 60 * 60 * 24);

            return _cancellationDeadline.getTime() < this._nowDate.getTime();
        }
    }
}
