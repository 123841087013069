import { Directive, ElementRef, Input, Output, AfterViewChecked, OnInit, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appPgCollapsibleContainer]'
})
export class PgCollapsibleContainerDirective implements OnInit {
    @Input()
    startCollapsed: boolean;

    @Output()
    onFirstShow = new EventEmitter()

    private _wasShow = false;

    toggleButton:HTMLElement = null;

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        this.el.nativeElement.classList.add('PGCollapsibleContainer');

        for(let _cChild of this.el.nativeElement.childNodes) {
            if(_cChild.classList != null && _cChild.classList.contains('PGCollapsibleContainer-ShowWhenCollapsed')) {
                this.el.nativeElement.classList.add('PGCollapsibleContainer--HasShowWhenCollapsed');
                break;
            }
        }

        this.toggleButton = document.createElement('button');
        this.toggleButton.className = "btn btn-small btn-white PGCollapsibleContainer-Toggle";
        this.toggleButton.innerHTML = '<i class="fa-regular fa-chevron-down"></i>';

        this.toggleButton.addEventListener('click', () => {
            this.toggleButton.parentElement.classList.toggle('PGCollapsibleContainer--Collapsed');

            if(this.toggleButton.parentElement.classList.contains('PGCollapsibleContainer--Collapsed')) {
                this.toggleButton.innerHTML = '<i class="fa-regular fa-chevron-right"></i>';
            }
            else {
                this.toggleButton.innerHTML = '<i class="fa-regular fa-chevron-down"></i>';
            }

            if(!this._wasShow) {
                this._wasShow = true;
                this.onFirstShow.emit();
            }
        })

        if(this.startCollapsed) {
            this.el.nativeElement.classList.add('PGCollapsibleContainer--Collapsed');
            this.toggleButton.innerHTML = '<i class="fa-regular fa-chevron-right"></i>';
        }
        else {
            this._wasShow = true;
        }

        this.el.nativeElement.insertBefore(this.toggleButton, this.el.nativeElement.childNodes[0]);
    }
}
