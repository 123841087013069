import { Component, OnInit, OnChanges, Input, SimpleChanges} from '@angular/core';

export class PGShowcaseElement {
    title: string
    image: string
    description: string
}

@Component({
  selector: 'app-pg-showcase',
  templateUrl: './pg-showcase.component.html',
  styleUrls: ['./pg-showcase.component.scss']
})
export class PgShowcaseComponent implements OnInit, OnChanges {

    @Input() showcaseElements:Array<PGShowcaseElement>;

    blockList:Array<Array<PGShowcaseElement>> = []

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.blockList = [];

        if(this.showcaseElements != null) {
            let _block:Array<PGShowcaseElement> = null;

            for(let i = this.showcaseElements.length - 1; i >= 0; i--) {
                if(_block == null) {
                    _block = [];
                    this.blockList.unshift(_block)
                }
    
                _block.unshift(this.showcaseElements[i])
    
                if(_block.length >= 5) _block = null;
            }
        }
    }

    getGlobalPosition(block:number, index:number) {
        let _sum = 0;

        for(let i = 0; i < block; i++) {
            _sum += this.blockList[i].length;
        }

        _sum += index;

        return _sum;
    }

    expandedElement:{ block: number, index: number } = null;

    toggleElementExpanded(block:number, index:number) {
        if(this.isElementExpanded(block, index)) {
            this.expandedElement = null;
        }
        else {
            this.expandedElement = { block: block, index: index };
        }
    }

    isElementExpanded(block:number, index:number) {
        return this.expandedElement != null && this.expandedElement.block == block && this.expandedElement.index == index
    }

    isElementExpandedInBlock(block:number) {
        return this.expandedElement != null && this.expandedElement.block == block
    }
}
