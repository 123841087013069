
import { Subscriber, Observable } from "rxjs";
import { ConfigService } from "../services/config.service";
import { DataFilter, DataService } from "../services/data.service";
import { SemanticsService } from "../services/semantics.service";
import { ConfigRelation } from "./config.relations.model";
import { ConfigResourceTypes } from "./config.resources.model";

export class CachedDataElements {

    cache: {
        [reosource:string]: {
            [element:string] : any;
        }
    } = {}

    constructor(private dataService:DataService, private idField?:string) {

    }

    getElementData(resourceId:string, elementId:string):Observable<any> {
        return new Observable<any>((observer) => {
            this.getElementsData(resourceId, [elementId]).subscribe((data) => {
                observer.next(data[0]);
                observer.unsubscribe();
            })
        })
    }

    getElementsData(resourceId:string, elementIdList:Array<string>):Observable<Array<any>> {
        return new Observable<Array<any>>((observer) => {
            if(this.cache[resourceId] == null) this.cache[resourceId] = {};
            
            let _cachedData = this._getCachedData(resourceId, elementIdList);

            if(_cachedData.miss.length == 0) {
                observer.next(_cachedData.hit);
                observer.unsubscribe();
            }
            else {
                this._enqueueRequest(resourceId, _cachedData.miss, observer);
            }
        })
    }

    private _getCachedData(resourceId:string, elementIdList:Array<string>) {
        let _hitData:Array<any> = [];
        let _missIds:Array<string> = [];

        for(let _cElementId of elementIdList) {
            if(this.cache[resourceId] != null && this.cache[resourceId][_cElementId] != null) {
                _hitData.push(this.cache[resourceId][_cElementId]);
            }
            else {
                _missIds.push(_cElementId)
            }
        }

        return {
            hit: _hitData,
            miss: _missIds
        }
    }

    // ho bisogno di una queue di chiamate perché voglio che eventuali richieste precedenti riempiano la cache per quelle successive
    private queue:Array<{ resourceId:string, elementIdList:Array<string>, observer:Subscriber<Array<any>> }> = [];

    private inProgress = false;

    private _enqueueRequest(resourceId:string, elementIdList:Array<string>, observer:Subscriber<any>) {
        this.queue.push({ resourceId: resourceId, elementIdList: elementIdList, observer: observer });
        this._checkQueue();
    }

    private _checkQueue() {
        if(!this.inProgress && this.queue.length > 0) {
            let _cRequest = this.queue.shift();

            let _cachedData = this._getCachedData(_cRequest.resourceId, _cRequest.elementIdList);

            if(_cachedData.miss.length == 0) {
                _cRequest.observer.next(_cachedData.hit);
                _cRequest.observer.unsubscribe();

                this._checkQueue();
            }
            else {
                this.inProgress = true;

                let _cIdField = this.idField == null ? 'id' : this.idField;

                let _cFilter:Array<DataFilter> = [];
                _cFilter.push(new DataFilter(_cIdField, 'in', _cRequest.elementIdList));

                this.dataService.getResourceData(_cRequest.resourceId, { filter: _cFilter }).subscribe((data) => {

                    for(let _cData of data) {
                        this.cache[_cRequest.resourceId][_cData[_cIdField]] = _cData;
                    }

                    this.inProgress = false;

                    _cRequest.observer.next(this._getCachedData(_cRequest.resourceId, _cRequest.elementIdList).hit);
                    _cRequest.observer.unsubscribe();

                    this._checkQueue();
                })
            }
        }
    }
}

export class CachedDataSelects extends CachedDataElements {

    tableRelations:Array<ConfigRelation> = null;

    constructor(dataService:DataService, private configService:ConfigService, private semanticsService:SemanticsService, private resourceId:string, 
        private fieldConfig:{ [field:string]: ConfigResourceTypes }) {
        super(dataService);

        this.tableRelations = this.configService.getResourceRelations(this.resourceId);
    }

    fillSelects(data) {
        return new Promise((resolve, reject) => {
            let _selectRequests = 0;

            for(let _cRelation of this.tableRelations) {
                if(_cRelation.type == 'N:1') {
    
                    let _joinCol:ConfigResourceTypes = this.fieldConfig[_cRelation.joinField];
    
                    let _requestObject = {};
    
                    for(let i = 0; i < data.length; i++) {
                        let _cValue = null;
    
                        if(data[i][_cRelation.joinField] != null) {
                            _cValue = data[i][_cRelation.joinField];
                            _requestObject[_cValue] = true;
                        }
                    }
    
                    let _requestValues = [];
    
                    for(let i in _requestObject) {
                        _requestValues.push(i);
                    }
    
                    if(_requestValues.length > 0) {
                        _selectRequests++;
                        
                        this.getElementsData(_cRelation.modelB, _requestValues).subscribe(selectData => {
                            let _selectOptions = this.semanticsService.getSelectOptionsFromResourceData(_cRelation.modelB, selectData).options;
    
                            for(let _cOption of _selectOptions) {
                                let _isIn = false;
    
                                for(let _inOption of _joinCol.options) {
                                    if(_inOption.value == _cOption.value) {
                                        _isIn = true;
                                        break;
                                    }
                                }
    
                                if(!_isIn) {
                                    _joinCol.options.push(_cOption)
                                }
                            }
    
                            _selectRequests--;
    
                            if(_selectRequests <= 0) {
                                resolve(data);
                            }
                        })
                    }
                }
            }
    
            if(_selectRequests <= 0) {
                resolve(data);
            }
        })
    }
}