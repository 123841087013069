import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { ConfigProfileRoleActionsExtended, ConfigService } from '../../services/config.service';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { PgCalendar, PGCalendarElement, PGCalendarDay } from './pg-calendar';

@Component({
    selector: 'app-pg-calendar-availability',
    templateUrl: './pg-calendar.component.html',
    styleUrls: ['./pg-calendar.component.scss']
})

export class PgCalendarAvailabilityComponent extends PgCalendar implements OnChanges {

    @Input()
    resourceId:string = null;

    @Input()
    elementId:string = null;

    @Input()
    isReadonly:boolean = null;

    actions:ConfigProfileRoleActionsExtended = null;

    constructor(localizationService: LocalizationService, private dataService: DataService, private configService:ConfigService) {
        super(localizationService);
    }

    ngOnChanges() {

        this.actions = this.configService.getResourceActionsExtended(this.resourceId);

        if(this.actions.book) {
            this.generateCalendar();
        }
    }

    getData():Promise<Array<PGCalendarElement>> {
        return new Promise<Array<PGCalendarElement>>((resolve, reject) => {
            this.dataService.getElementAvailability(this.resourceId, this.elementId, new Date(this.year, this.month, 1), new Date(this.year, this.month + 1, 0)).subscribe(data => {
                let _retVal:Array<PGCalendarElement> = [];

                for(let _cData of data) {
                    let _cElement = new PGCalendarElement();

                    _cElement.start = _cData.start;
                    _cElement.end =_cData.end;
                    _cElement.status = _cData.status;

                    _retVal.push(_cElement);
                }

                resolve(_retVal);
            });
        });
    }

    getAvailableActions() {
        if(this.actions.book && !this.isReadonly) {
            return { book: true };
        }
    }

    @Output() onSelectElement = new EventEmitter<any>()

    onElementAction(action:string, elementData:PGCalendarElement) {
        if(!this.isReadonly) {
            this.onSelectElement.emit(elementData);
        }
    }

    getEventStatusColor(elementData:PGCalendarElement) {
        switch(elementData.status) {
            case 'esaurimento': return 'warning';
            case 'ultimi': return 'danger';
            default: return 'success';
        }
    }

    getEventStatusIcon(elementData:PGCalendarElement) {
        switch(elementData.status) {
            case 'esaurimento': return 'fa-exclamation';
            case 'ultimi': return 'fa-exclamation';
            default: return 'fa-check';
        }
    }

    getEventStatusTitle(elementData:PGCalendarElement) {
        switch(elementData.status) {
            case 'esaurimento': return 'In esaurimento';
            case 'ultimi': return 'Ultimi posti';
            default: return 'Disponibile';
        }
    }

    setSelectedDay(dayData:PGCalendarDay) {
        super.setSelectedDay(dayData);

        if(dayData.elements.length == 1) {
            this.onElementAction('book', dayData.elements[0]);
        }
    }
}
