<div class="FormsMain {{inModal ? 'FormsMain--InModal' : ''}}">
    <app-pg-loading *ngIf="isLoading" [isOverlay]="true" [isLarge]="true"></app-pg-loading>
    <div *ngIf="notFound">
        <div class="FormsMain-Heading mb-3 d-block d-sm-flex flex-row-reverse">
            <div class="text-end pb-2">
                <button class="btn btn-sm btn-secondary ms-1" (click)="cancelEdit.emit()">
                    <i class="fa-regular fa-undo"></i>
                    <span>{{ 'forms-main.cancel' | locTranslate }}</span>
                </button>
            </div>
            <h2>
                {{ 'forms-main.not-found' | locTranslate }}
            </h2>
        </div>
    </div>
    <div *ngIf="!notFound">
        <ng-container *ngIf="!viewOnly">
            <div class="FormsMain-Heading mb-3 d-block d-sm-flex flex-row-reverse">
                <div class="text-end pb-2"></div>
                <h2>
                    <ng-container *ngIf="currentState != null">{{formFlow.states[currentState].title | locTranslate}}</ng-container>
                    <ng-container *ngIf="currentState == null">{{ 'forms-main.summary' | locTranslate }}</ng-container>
                </h2>
            </div>
            <div *ngIf="flowSteps != null" #progressCont class="FormsMain-Progress mb-3">
                <div class="FormsMain-Progress-Inner" [style.width]="(flowSteps.length * 8) + 'rem'">
                    <div>
                        <div class="progress">
                            <div class="progress-bar progress-bar-primary" role="progressbar" [style.width]="(flowProgress * 100) + '%'">
                                <span *ngIf="flowProgress > 0">{{flowProgressString}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="FormsMain-Progress-Ticks">
                        <ng-container *ngFor="let id of flowSteps">
                            <div *ngIf="formFlow.states[id]; let state" [attr.data-state-id]="id" class="{{getProgressStepClass(id)}}" [style.flex]="getProgressStepFlex(id)" (click)="jumpToState(id)">
                                <span class="{{id == currentState ? 'text-primary' : 'text-dark'}}">{{state.title | locTranslate}}</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="currentState != null">
                <p *ngIf="formFlow.states[currentState].description; let description">{{description | locTranslate}}</p>
    
                <!-- STATE NORMALE -->
                <app-pg-form #stateForm *ngIf="!formFlow.states[currentState].multi" [formLayout]="currentForm" [hasCancel]="false" [hasSubmit]="false"
                [cancelText]="formCancelText" [submitText]="formSubmitText" (valueChange)="onValueChange()" (formCancel)="onFormCancel()" (formSubmit)="onFormSubmit($event)" ></app-pg-form>
    
                <!-- STATE MULTI -->
                <ng-container *ngIf="formFlow.states[currentState].multi; let multi">
                    <ng-container *ngIf="values[multi] != null">
                        <ng-container *ngFor="let item of values[multi]; let i = index">
                            <div class="card FormsMain-Multiform-Item mb-3"> 
                                <div *ngIf="currentStateMultiEdit == null" class="card-header FormsMain-Multiform-Item-Actions">
                                    <button *ngIf="i > 0" class="btn btn-sm btn-link" (click)="multiFormMove(item, -1)">
                                        <i class="fa-regular fa-chevron-up"></i>
                                    </button>
                                    <button *ngIf="i < values[multi].length - 1" class="btn btn-sm btn-link" (click)="multiFormMove(item, 1)">
                                        <i class="fa-regular fa-chevron-down"></i>
                                    </button>
                                    <button class="btn btn-sm btn-link" (click)="multiFormEdit(item)">
                                        <i class="fa-regular fa-edit"></i>
                                    </button>
                                    <button class="btn btn-sm btn-link text-danger" (click)="multiFormDelete(item)">
                                        <i class="fa-regular fa-trash"></i>
                                    </button>
                                </div>
                                <div *ngIf="currentStateMultiEdit != null" class="card-header">
                                    <b>&nbsp;</b>
                                </div>
                                <div class="card-body">
                                    <app-pg-detail [formLayout]="currentForm" [formValues]="item"></app-pg-detail>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- aggiunta elemento -->
                    <div class="text-end">
                        <button class="btn btn-primary" [disabled]="currentStateMultiEdit != null" (click)="multiFormAdd()">
                            <span>{{ 'forms-main.add' | locTranslate }} {{formFlow.states[currentState].title | locTranslate}}</span>
                            <i class="fa-regular fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-buttons mt-3">
                        <button class="btn btn-secondary" (click)="previousState()">
                            <i class="fa-regular fa-chevron-left"></i>
                            <span>{{ 'forms-main.previous' | locTranslate }}</span>
                        </button>
                        <button class="btn btn-primary" (click)="nextState()">
                            <span>{{ 'forms-main.next' | locTranslate }}</span>
                            <i class="fa-regular fa-chevron-right"></i>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </ng-container>

        <!-- RECAP -->

        <div *ngIf="viewOnly || currentState == null" class="FormsMain-Recap">
            <ng-container *ngFor="let id of stateHistory; let i = index">
                <ng-container *ngIf="formFlow.states[id]; let state">
                    <div *ngIf="!state.noRecap" class="mb-3">
                        <h4 class="mb-2 pb-2 border-bottom">{{state.title | locTranslate}}</h4>
                        <div class="mb-2">{{state.description | locTranslate}}</div>

                        <!-- STATE NORMALE -->
                        <app-pg-detail *ngIf="!state.multi" [formLayout]="state.form"></app-pg-detail>

                        <!-- STATE MULTI -->
                        <ng-container *ngIf="state.multi; let multi">
                            <ng-container *ngIf="values[multi] != null">
                                <ng-container *ngFor="let item of values[multi]; let i = index">
                                    <div class="card FormsMain-Multiform-Item mb-3"> 
                                        <div class="card-body">
                                            <app-pg-detail [formLayout]="state.form" [formValues]="item"></app-pg-detail> <!-- TODO: fixare con copie di formLayout -->
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>

        </div>
    </div>    
</div>

<ng-template #modalStateMultiEdit>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'forms-main.edit' | locTranslate }}</h4>
        <button type="button" class="modal-close btn btn-link" (click)="onMultiFormEditCancel()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body FormsMain-StateMultiEdit">
        <app-pg-form *ngIf="formLayoutMultiEdit != null" [formLayout]="formLayoutMultiEdit" [hasCancel]="true" 
            [submitText]="multiFormEditSubmitText" [cancelText]="multiFormEditCancelText" 
            (formSubmit)="onMultiFormEditSubmit($event)" (formCancel)="onMultiFormEditCancel()"></app-pg-form>
    </div>
</ng-template>