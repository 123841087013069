import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { PgFile, PgFileData, PgFileUtils } from '../../models/file.model';

@Component({
  selector: 'app-pg-file-preview',
  templateUrl: './pg-file-preview.component.html',
  styleUrls: ['./pg-file-preview.component.scss']
})
export class PgFilePreviewComponent implements OnChanges, OnDestroy {
    @Input() fileData:PgFileData|File;
    @Input() viewMode:string;

    file:PgFile = null;

    info:{
        path: string,
        size: string
    } = null;

    private _revokeUrl = false;

    private _checkRevokeUrl() {
        if(this._revokeUrl) {
            this._revokeUrl = false;
            URL.revokeObjectURL(this.file.url)
        }
    }

    constructor() { }

    ngOnChanges() {
        this._checkRevokeUrl();

        if(this.fileData instanceof File) {
            this._revokeUrl = true;

            let _cFileData = new PgFileData();
            
            _cFileData.nome = this.fileData.name;
            _cFileData.nome_file = this.fileData.name;
            let _cType = this.fileData.type.split('/')[0];

            if(_cType == 'image' || _cType == 'audio' || _cType == 'video') {
                _cFileData.type = _cType + 's' as any;
            }
            else {
                let _cExtension = '';

                let _cExtensionMatch = _cFileData.nome_file.match(/\.[^\.]*$/);

                if(_cExtensionMatch != null) {
                    _cExtension = _cExtensionMatch[0].substr(1).toLowerCase();
                } 

                if(PgFileUtils.getCategoryExtensions('documents').indexOf(_cExtension) != -1) {
                    _cFileData.type = 'documents'
                }
            }

            _cFileData.url = URL.createObjectURL(this.fileData);

            this.info = {
                path: this.fileData.webkitRelativePath,
                size: PgFileUtils.getFileSizeString(this.fileData.size)
            }
 
            this.file = new PgFile(_cFileData);
        }
        else {
            this.file = new PgFile(this.fileData);
        }
    }

    ngOnDestroy() {
        this._checkRevokeUrl();
    }

    getView() {
        if(this.file == null || this.file.url == null) return null;
        else if(['images','audios','videos'].indexOf(this.viewMode) != -1) return this.viewMode;
        else return 'files'
    }
}
