<div class="EFCalendar {{ full ? 'EFCalendar--Full' : vertical ? 'EFCalendar--Vertical' : 'EFCalendar--Horizontal' }}">
    <div  *ngIf="dateInterval == null" class="EFCalendar-Filter ps-3 pe-2 py-2">
        <div>
            <h5 class="m-0">{{getFormattedMonthOffset()}}</h5>
        </div>
        <div>
            <button class="btn btn-link" [disabled]="!canGoPrev()" (click)="goPrev()">
                <i class="fa-regular fa-chevron-left"></i>
            </button>
            <button class="btn btn-link" [disabled]="!canGoNext()" (click)="goNext()">
                <i class="fa-regular fa-chevron-right"></i>
            </button>
        </div>
    </div>
    <div class="EFCalendar-Content" #datesContainer>
        <app-pg-loading *ngIf="isLoading"></app-pg-loading>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="calendarData.length == 0">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h5 class="my-3 text-muted">{{ 'experience-finder.general.no-results' | locTranslate }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let day of visibleCalendarData; let i = index" class="border-top">
                <div class="container-fluid pt-3">
                    <div class="row">
                        <div class="col-12">
                            <h6 class="m-0">{{formatDate(day.date)}}</h6>
                        </div>
                    </div>
                    <div class="row EFCalendar-Day-Products py-3">
                        <div *ngFor="let product of day.visibleProducts" class="{{ vertical ? 'col-12 pb-3' : 'col-12 col-md-6 col-lg-4' }}">
                            <app-ef-product-card [productData]="product" [vertical]="true" (showProduct)="showProduct.emit($event)"></app-ef-product-card>
                        </div>
                        <div class="{{ vertical ? 'col-12 pb-3' : 'col-12 col-md-6 col-lg-4' }}">
                            <app-pg-view-more [hidden]="day.visibleProducts.length == day.products.length" (viewMore)="viewMoreProducts(i)"></app-pg-view-more>
                        </div>
                    </div>
                </div>
            </div>
            <app-pg-view-more [hidden]="visibleCalendarData.length == calendarData.length" (viewMore)="viewMoreCalendar()"></app-pg-view-more>
        </ng-container>
    </div>
</div>
