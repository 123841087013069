import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UpdateCheckerService {  
    private _inactivityTime = 5 * 60 * 1000;
    private _reloadTime = 60 * 60 * 1000;

    isInactiveChange = new Subject<boolean>();

    isInitialized = false
    
    init(inactivityTime?:number, reloadTime?:number, startInactive?:boolean) {
        console.log('UpdateCheckerService engaged')

        this.isInitialized = true;

        if(inactivityTime != null) this._inactivityTime = inactivityTime;
        if(reloadTime != null) this._reloadTime = reloadTime;

        console.log('inactivityTime: ' + this._inactivityTime + 'ms')
        console.log('reloadTime: ' + this._reloadTime + 'ms')

        document.body.addEventListener('click', () => {
            this._resetInactivity()
        })

        document.body.addEventListener('mousemove', () => {
            this._resetInactivity()
        })

        this._resetInactivity(startInactive)   

        setTimeout(() => {
            console.log('mustReload = true')
            this.mustReload = true;
            this._checkReload();
        }, this._reloadTime)
    }

    isInactive = false;
    mustReload = false;

    private _inactivityTimeout = null;

    private _resetInactivity(startInactive?:boolean) {
        if(this.isInactive) {
            console.log('isInactive = false')
            this.isInactive = false;
            this.isInactiveChange.next(this.isInactive);
        }
        
        clearTimeout(this._inactivityTimeout);

        this._inactivityTimeout = setTimeout(() => {
            console.log('isInactive = true')
            this.isInactive = true;
            this.isInactiveChange.next(this.isInactive);

            this._checkReload();
        }, startInactive ? 10 : this._inactivityTime)
    }

    private _checkReload() {
        if(this.isInactive && this.mustReload) {
            console.log('isInactive && mustReload, reloading')
            window.location.reload();
        }
    }
}