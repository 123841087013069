<div class="PGSlider {{scaledValueList != null ? 'PGSlider--HasScale' : ''}}">
    <div class="d-flex align-items-center">
        <div class="PGSlider-Inner">
            <input [id]="fieldId" class="form-control" type="range"
            [readonly]="readonly" [required]="required" [attr.min]="scaledMin" [attr.max]="scaledMax" [attr.step]="scaledStep"
            [ngModel]="getValue()" (ngModelChange)="setValue($event);"/>

            <div *ngIf="scaledValueList != null" class="small text-muted PGSlider-Scale">
                <div *ngFor="let item of scaledValueList" class="border-start">
                    <div>{{item}}</div>
                </div>
            </div>
        </div>
        <div class="ps-2 text-end" style="flex: 0; min-width: 2em;">{{value}}</div>
    </div>
</div>