import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../services/auth.service';
import { EnvironmentService } from '../services/environment.service';
import { Websocket2Registration } from '../services/websocket2.service';
import { Subject } from 'rxjs';

export type WSServiceUserStatusValue = 'online'|'offline'|'busy'

export class WSServiceUserStatusProfile {
    api:string = null;
    id:string = null;
    name:string = null;
    surname:string = null;
    email:string = null;
    superadmin:boolean = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    switch(i) {
                        case 'id': this[i] = fromData[i].toString(); break;
                        case 'superadmin': this[i] = fromData[i] == 1; break;
                        default: this[i] = fromData[i]; break;
                    }
                }
            }
        }
    }
}

export class PgUserStatusIndex { 
    [id:string]: { 
        profile: WSServiceUserStatusProfile, 
        status: WSServiceUserStatusValue
    } 
}

export class WSServiceLocalUserStatusMessage {
    action:'set-status';
    user?:WSServiceUserStatusProfile;
    value:WSServiceUserStatusValue;
}

@Injectable({
  providedIn: 'root'
})
export class PgUserStatusService {
    constructor(private environmentService:EnvironmentService, private authService:AuthService) {
        this._handleAuthStatusChange()

        this.authService.statusChange.subscribe(() => {
            this._handleAuthStatusChange()
        })
    }

    statusOptions = [
        { value: 'online', text: 'Online' },
        { value: 'busy', text: 'Busy' },
        { value: 'offline', text: 'Offline' },
    ]

    registered = false;

    registeredChange = new Subject<boolean>();

    userStatus:WSServiceUserStatusValue = null;

    userStatusChange = new Subject<WSServiceUserStatusValue>();

    usersStatusIndex:PgUserStatusIndex = {}

    usersStatusIndexChange = new Subject<PgUserStatusIndex>();

    private socket:Socket = null;

    private registration:Websocket2Registration = null;

    private _handleAuthStatusChange() {
        if(this.authService.isLoggedIn() && this.environmentService.environment.WebSocket != null) {
            this.socket = new Socket({ 
                url: this.environmentService.environment.WebSocket, 
                options: {} 
            });

            this.registration = new Websocket2Registration(this.socket, 'user-status', async () => {
                let _token = await this.authService.getAuthToken();
                
                return {
                    api: this.environmentService.environment.APIUrl,
                    token: _token
                }
            });

            this.registration.statusChange.subscribe((status) => {
                this.userStatus = null;
                this.userStatusChange.next(this.userStatus);
                this.usersStatusIndex = {}
                this.usersStatusIndexChange.next(this.usersStatusIndex);

                this.registered = status == 'registered';
                this.registeredChange.next(this.registered);
            })

            this.registration.onMessage.subscribe((data:WSServiceLocalUserStatusMessage) => {
                if(data.action == 'set-status') {
                    if(this.authService.isLoggedIn() && this.authService.user.id == data.user.id) {
                        this.userStatus = data.value;
                        this.userStatusChange.next(this.userStatus);
                    }

                    this.usersStatusIndex[data.user.id] = {
                        profile: data.user,
                        status: data.value
                    }
                    
                    this.usersStatusIndexChange.next(this.usersStatusIndex);
                }
            })
        }
        else {
            if(this.registration != null) {
                this.registration.unregister();
                this.registration = null;
                this.socket.disconnect();
                this.socket = null;
            }

            this.registered = false;
            this.registeredChange.next(this.registered);
            this.userStatus = null;
            this.userStatusChange.next(this.userStatus);
            this.usersStatusIndex = {}
            this.usersStatusIndexChange.next(this.usersStatusIndex);
        }
    }

    setUserStatus(status:WSServiceUserStatusValue) {
        let _message:WSServiceLocalUserStatusMessage = {
            action: 'set-status',
            value: status
        }

        this.registration.send(_message)
    }
}
