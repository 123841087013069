<div class="container py-3 PGConfirmBooking">
    <app-pg-loading *ngIf="isLoading || isSaving" [isLarge]="true" [isOverlay]="isSaving"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <div>
            <div class="PGConfirmBooking-Header text-center">
                <h3>{{ 'pg-confirm-booking.manage-bookings' | locTranslate }}</h3>
                <h4 class="my-3">{{productDate | locFormat:'date'}}<ng-container *ngIf="productTime != null"> - {{productTime | locFormat:'time'}}</ng-container></h4>
            </div>
            <app-pg-product-card *ngIf="productData != null" [productData]="productData" [supplierData]="supplierData"></app-pg-product-card>
            <div class="row">
                <div class="col-12">
                    <h5 class="mt-4 px-2">{{ 'pg-confirm-booking.date-time' | locTranslate }}</h5>
                    <div class="PGBookedProduct-Selected border-top border-bottom p-2">
                        <div>
                            <div class="PGConfirmBooking-Field">
                                <span class="me-1">{{ 'pg-confirm-booking.date-time-day' | locTranslate }}</span><b>{{formatSelectedAvailabilityDate()}}</b>
                            </div>
                            <div *ngIf="productTime != null" class="PGConfirmBooking-Field">
                                <span class="me-1">{{ 'pg-confirm-booking.date-time-hour' | locTranslate }}</span><b>{{formatSelectedAvailabilityTime()}}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="PGConfirmBooking-ReceivedHeader mt-4 mb-2 px-2">
                        <h5 class="m-0">{{ 'pg-confirm-booking.received-bookings' | locTranslate }}</h5>
                        <div *ngIf="bookingsData.length > 0">
                            <button class="btn btn-sm btn-primary py-0 px-1 d-block" (click)="exportCSV()">
                                <i class="fa-regular fa-download"></i><span>{{ 'pg-confirm-booking.booking-download' | locTranslate }}</span>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="bookingsData.length == 0" class="border-top border-bottom py-2">
                        <span class="text-muted">{{ 'pg-confirm-booking.received-bookings-none' | locTranslate }}</span>
                    </div>
                    <div *ngFor="let booking of bookingsData; let i = index;" class="PGBookedProduct-Selected border-bottom {{i == 0 ? 'border-top' : ''}} pt-2 px-2">
                        <div>
                            <div class="PGConfirmBooking-Field">
                                <span class="me-1">{{ 'pg-confirm-booking.booking-contact' | locTranslate }}</span>
                                <div class="text-end">
                                    <div *ngIf="booking.user_name != null">{{booking.user_name}}</div>
                                    <div><a *ngIf="booking.user_email != null" href="mailto:{{booking.user_email}}">{{booking.user_email}}</a></div>
                                    <div><a *ngIf="booking.user_phone != null" href="tel:{{booking.user_phone}}">{{booking.user_phone}}</a></div>
                                </div>
                            </div>
                            <div *ngIf="booking.availability.from != null && booking.availability.to" class="PGConfirmBooking-Field PGConfirmBooking-Field--Accommodation mt-1">
                                <span class="me-1">{{ 'pg-confirm-booking.booking-period' | locTranslate }}</span>
                                <span [innerHTML]="formatBookingPeriod(booking.availability)"></span>
                            </div>
                            <ng-container *ngIf="productType == 'host'">
                                <div *ngIf="booking.booking_data != null" class="PGConfirmBooking-Field PGConfirmBooking-Field--Accommodation mt-1">
                                    <span class="me-1">{{ 'pg-confirm-booking.booking-accommodation' | locTranslate }}</span>
                                    <span>{{formatAccommodationName(booking.booking_data)}}</span>
                                </div>
                                <div *ngIf="booking.tickets != null" class="mt-1">
                                    <div *ngIf="booking.tickets.fullQuantity > 0" class="PGConfirmBooking-Field">
                                        <span></span>
                                        <span class="me-1">{{ 'pg-confirm-booking.label-full-price-single' | locTranslate }}</span>
                                    </div>
                                    <ng-container *ngFor="let other of booking.tickets.otherTickets">
                                        <div *ngIf="other.quantity > 0" class="PGConfirmBooking-Field">
                                            <span></span>
                                            <span class="me-1">{{formatTicketName(other.name)}}</span>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="productType == 'experience' || productType == null">
                                <div *ngIf="booking.tickets != null" class="mt-1">
                                    <div *ngIf="booking.tickets.fullQuantity > 0" class="PGConfirmBooking-Field">
                                        <span class="me-1">{{ 'pg-confirm-booking.label-full-price' | locTranslate }}</span><b>x {{booking.tickets.fullQuantity}}</b>
                                    </div>
                                    <ng-container *ngFor="let other of booking.tickets.otherTickets">
                                        <div *ngIf="other.quantity > 0" class="PGConfirmBooking-Field">
                                            <span class="me-1">{{formatTicketName(other.name)}}</span><b>x {{other.quantity}}</b>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <div *ngIf="booking.survey_answers != null" class="mt-1">
                                <ng-container *ngFor="let question of booking.survey_answers.questions">
                                    <div class="PGConfirmBooking-Field">
                                        <span class="me-1">{{formatQuestionText(question.text)}}</span>
                                        <span *ngIf="question.type == 'boolean' && question.answer != null">{{ 'pg-survey-editor.answer-boolean-' + question.answer  | locTranslate }}</span>
                                        <span *ngIf="question.type == 'string'">{{question.answer}}</span>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf="booking.pid != null" class="PGConfirmBooking-Field text-muted mt-1">
                                <span class="me-1">PID</span>
                                <span class="text-monospace text-uppercase">{{booking.pid}}</span>
                            </div>
                        </div>
                        <div>
                            <div class="text-center py-2 mt-2 d-flex justify-content-between align-items-center">
                                <div *ngIf="booking.status == 'pending'" class="text-muted">
                                    <i class="fa-regular fa-ellipsis me-2"></i>
                                    <b>{{ 'pg-confirm-booking.status-pending' | locTranslate }}</b>
                                </div>
                                <div *ngIf="booking.status == 'canceled'" class="text-danger">
                                    <i class="fa-regular fa-times me-2"></i>
                                    <b>{{ 'pg-confirm-booking.status-canceled' | locTranslate }}</b>
                                </div>
                                <div *ngIf="booking.status == 'available'" class="text-primary">
                                    <i class="fa-regular fa-check me-2"></i>
                                    <b>{{ 'pg-confirm-booking.status-available' | locTranslate }}</b>
                                </div>
                                <div *ngIf="booking.status == 'confirmed'" class="text-success">
                                    <i class="fa-regular fa-check me-2"></i>
                                    <b>{{ 'pg-confirm-booking.status-confirmed' | locTranslate }}</b>
                                </div>
                                <div>
                                    <app-pg-confirm-action *ngIf="booking.status != 'canceled'" [disabled]="isSaving" (confirmed)="cancelBooking(booking)"> 
                                        <button class="btn btn-sm btn-danger ms-1" [disabled]="isSaving">
                                            <i class="fa-regular fa-times"></i>
                                            <span>{{ 'pg-confirm-booking.button-cancel' | locTranslate }}</span>
                                        </button>
                                    </app-pg-confirm-action>
                                    <button *ngIf="booking.status == 'pending'" [disabled]="isSaving" class="btn btn-sm btn-primary ms-1" (click)="confirmBooking(booking)">
                                        <i class="fa-regular fa-check"></i>
                                        <span>{{ 'pg-confirm-booking.button-confirm' | locTranslate }}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="col-12 PGConfirmBooking-SlotSummary">
                    <div class="border-bottom">
                        <h5 class="mt-4 px-2">{{ 'pg-confirm-booking.summary' | locTranslate }}</h5>
                        <div class="py-2 px-2 border-top">
                            <app-pg-loading *ngIf="isSaving"></app-pg-loading>
                            <app-pg-bookings-slot-summary *ngIf="!isSaving" [productData]="productData" [bookingsData]="bookingsData"></app-pg-bookings-slot-summary>
                        </div>
                    </div>
                </div>

                <div *ngIf="hasPendingBookings || hasNonCanceledBookings" class="col-12 d-flex justify-content-end pt-3">
                    <div *ngIf="hasNonCanceledBookings" class="ms-1">
                        <app-pg-confirm-action [disabled]="isSaving" (confirmed)="cancelSlot()"> 
                            <button class="btn btn-danger btn-block" [disabled]="isSaving">
                                <i class="fa-regular fa-times"></i>
                                <span>{{ 'pg-confirm-booking.button-cancel-slot' | locTranslate }}</span>
                            </button>
                        </app-pg-confirm-action>
                    </div>
                    <div *ngIf="hasPendingBookings" class="ms-1">
                        <button class="btn btn-primary btn-block" [disabled]="isSaving" (click)="confirmSlot()">
                            <i class="fa-regular fa-check"></i>
                            <span>{{ 'pg-confirm-booking.button-confirm-slot' | locTranslate }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>