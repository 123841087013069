import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ExternalLinksService {

    private _externalLinkHandler:(string) => void = null;

    setExternalLinkHandler(fn:(string) => void) {
        this._externalLinkHandler = fn
    }
    
    openExternalLink(url:string) {
        if(this._externalLinkHandler != null) {
            this._externalLinkHandler(url);
        }
        else {
            window.open(url)
        }
    }
}
