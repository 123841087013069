import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PgChartComponent } from './pg-chart/pg-chart.component';
import { PgChartBarsComponent } from './pg-chart-bars/pg-chart-bars.component';



@NgModule({
    declarations: [
        PgChartComponent,
        PgChartBarsComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        PgChartComponent,
        PgChartBarsComponent
    ],
    providers:[]
})
export class PgChartsModule { }
