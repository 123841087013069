<div class="PGGraph PGGraph--{{graphMode}}" [ngClass]="{ 'PGGraph--Building' : rebuildGraph }">
    <div class="PGGraph-Scale">
        <div *ngFor="let value of scaleValues">{{value}}</div>
    </div>
    <div class="PGGraph-Values">
        <ng-container *ngIf="graphMode == 'Bars'">
            <div *ngFor="let element of dataSet; let i = index;" 
                [style.height]="(percentValues[i] * 100) + '%'" 
                [style.opacity]="0.5 + percentValues[i] * 0.5">
                <span>{{element.value}}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="graphMode == 'Line'">
            <svg class="PGGraph-Line-Values-Path" viewBox="0 0 100 100" preserveAspectRatio="none">
                <path class="PGGraph-Line-Values-Path-Fill" [attr.d]="getGraphLinePath(true)"/>
                <path class="PGGraph-Line-Values-Path-Stroke" [attr.d]="getGraphLinePath()"
                    stroke-width="2" 
                    stroke-linejoin="round" 
                    stroke-linecap="round"
                    vector-effect="non-scaling-stroke" />
            </svg>
            <svg class="PGGraph-Line-Values-Labels">
                <ng-container *ngFor="let element of dataSet; let i = index;">
                    <line
                        [attr.x1]="((i + 0.5) / dataSet.length * 100) + '%'" 
                        [attr.y1]="(90 - percentValues[i] * 82.5) + '%'"
                        [attr.x2]="((i + 0.5) / dataSet.length * 100) + '%'" 
                        [attr.y2]="'100%'"
                        stroke-dasharray="2,3"
                        stroke-width="1" />
                    <circle r="2.5" 
                        [attr.cx]="((i + 0.5) / dataSet.length * 100) + '%'" 
                        [attr.cy]="(90 - percentValues[i] * 82.5) + '%'"/>
                    <text text-anchor="middle"
                        [attr.x]="((i + 0.5) / dataSet.length * 100) + '%'" 
                        [attr.y]="(87.5 - percentValues[i] * 82.5) + '%'"
                        >{{element.value}}</text>
                </ng-container>
            </svg>
        </ng-container>
        <ng-container *ngIf="graphMode == 'Pie'">
            <svg>
                <g transform="translate(240,200)">
                    <ng-container *ngFor="let element of dataSet; let i = index;">
                        <g class="PGGraph-Pie-Values-Arc" 
                            [attr.transform]="'rotate(' + getGraphPieRotation(i) + ')'">
                            <path
                                [attr.d]="getGraphPiePath(i)"
                                [attr.fill-opacity]="0.5 + percentValues[i] * 0.5"/>
                        </g>
                    </ng-container>
                    <ng-container *ngFor="let element of dataSet; let i = index;">
                        <g class="PGGraph-Pie-Values-Border"  
                            [attr.transform]="'rotate(' + getGraphPieRotation(i) + ')'">
                            <line x1="0" y1="0" 
                                x2="0" y2="-170"
                                stroke-width="0.5"
                                stroke="#ffffff"/>
                        </g>
                    </ng-container>
                    <ng-container *ngFor="let element of dataSet; let i = index;">
                        <g class="PGGraph-Pie-Values-Label"
                            [attr.transform]="'rotate(' + getGraphPieRotation(i, true) + ') translate(0,-185)'">
                            <line
                                x1="0" y1="5" 
                                x2="0" y2="15"
                                stroke-width="1"/>
                            <text [attr.transform]="getGraphPieLabelTransform(i)"
                                [attr.text-anchor]="getGraphPieRotation(i, true) &lt; 180 ? 'start' : 'end'"
                                dominant-baseline="central">
                                {{element.label}}
                            </text>
                        </g>
                    </ng-container>
                    <ng-container *ngFor="let element of dataSet; let i = index;">
                        <g class="PGGraph-Pie-Values-Value"
                            [attr.transform]="'rotate(' + getGraphPieRotation(i, true) + ') translate(0,' + (-150 + (150 * element.value / sumValue)) + ')'">
                            <text [attr.transform]="'rotate(-' + getGraphPieRotation(i, true) + ')'"
                                [attr.font-size]="(0.75 + (10 * element.value / sumValue)) + 'em'"
                                text-anchor="middle"
                                dominant-baseline="central">
                                {{element.value}}
                            </text>
                        </g>
                    </ng-container>
                </g>
            </svg>
        </ng-container>
    </div>
    <div class="PGGraph-Labels">
        <div *ngFor="let element of dataSet" [innerHTML]="element.label"></div>
    </div>
</div>
