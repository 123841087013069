import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EFProduct } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { PGUtilities } from '../../../pg-utilities';

@Component({
  selector: 'app-ef-showcase',
  templateUrl: './ef-showcase.component.html',
  styleUrls: ['./ef-showcase.component.scss']
})
export class EfShowcaseComponent implements OnInit, OnDestroy {

    @Input() productsData:{ [type:string]: Array<EFProduct> };

    showcaseProductsList:Array<EFProduct> = null;

    previousShowcaseIndex:number = null;
    currentShowcaseIndex:number = null;
    nextShowcaseIndex:number = null;

    constructor(private localizationService:LocalizationService) { }

    private _showcaseInterval = null;
    private _showcaseTimeout = null;

    ngOnInit(): void {
        this.showcaseProductsList = [];
        
        for(let i of ['experience','host','eatery']) {
            if(this.productsData[i] != null) {
                for(let _cProduct of this.productsData[i]) {
                    if(_cProduct.languages.indexOf(this.localizationService.currentLanguage) != -1) {
                        if(_cProduct.images != null && _cProduct.images.length > 0) {
                            this.showcaseProductsList.push(_cProduct)
                        }
                    }
                }
            }
        }

        if(this.showcaseProductsList.length > 0) {
            this.nextShowcaseIndex = 0;

            this._showcaseTimeout = setTimeout(() => {
                this._showcaseTimeout = null;

                this.currentShowcaseIndex = 0;

                if(this.showcaseProductsList.length > 1) {
                    this.nextShowcaseIndex = 1;

                    this._showcaseInterval = setInterval(() => {
                        this.previousShowcaseIndex = this.currentShowcaseIndex;
                        this.currentShowcaseIndex = null;

                        this._showcaseTimeout = setTimeout(() => {
                            this._showcaseTimeout = null;

                            this.currentShowcaseIndex = this.nextShowcaseIndex;
                            this.nextShowcaseIndex = (this.nextShowcaseIndex + 1) % this.showcaseProductsList.length;
                        }, 1000)
                    }, 8000)
                }
            }, 1000)
        }

        // array shuffle

        PGUtilities.shuffleArray(this.showcaseProductsList)
    }

    ngOnDestroy() {
        if(this._showcaseInterval != null) clearInterval(this._showcaseInterval)
        if(this._showcaseTimeout != null) clearTimeout(this._showcaseTimeout)
    }

    getTranslatedProperty(product:EFProduct, prop:string) {
        if(product.translations != null && product.translations[this.localizationService.currentLanguage] != null && product.translations[this.localizationService.currentLanguage][prop] != null) {
            return product.translations[this.localizationService.currentLanguage][prop]
        }
        else if(product[prop] != null) {
            return product[prop]
        }
    }

    formatProductLocation(product:EFProduct) {
        let _retVal = '';
        
        if(product.city != null) _retVal += product.city;
        if(product.province != null) {
            if(_retVal != '') _retVal += ' '
            _retVal += '(' + product.province + ')';
        }
        if(product.address != null) {
            if(_retVal != '') _retVal += ', '
            _retVal += product.address;
        }

        return _retVal
    }
}
