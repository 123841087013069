import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { PgFormField } from '../../models/form.model';
import { OptionMapsService } from '../../services/option-maps.service';

@Component({
  selector: 'app-pg-totem-widget-editor',
  templateUrl: './pg-totem-widget-editor.component.html',
  styleUrls: ['./pg-totem-widget-editor.component.scss']
})
export class PgTotemWidgetEditorComponent implements OnInit, OnChanges {

    constructor(private localizationService:LocalizationService, private optionMapsService:OptionMapsService) {
        this.availableLanguages = this.localizationService.availableApplicationLanguages;

        for(let _option of this.colorOptions) { // TOFIX: codice replicato
            _option.text = '<div class="rounded px-1 text-white bg-' + _option.value + '">' + _option.value + '</div>'
        }

        for(let i in this.widgetFields) {
            for(let _field of this.widgetFields[i]) {
                if(_field.label == 'auto') _field.label = _field.name.charAt(0).toUpperCase() + _field.name.substring(1).replace(/\./g, ' ');
            }
        }
    }

    @Input() values:any;
    @Input() widget:any;

    @Input() viewOnly:boolean;

    @Output() valuesChange = new EventEmitter<any>()

    colorOptions = [
        { value: 'primary', text: null }, 
        { value: 'secondary', text: null }, 
        { value: 'info', text: null },
        { value: 'success', text: null }, 
        { value: 'warning', text: null }, 
        { value: 'danger', text: null }
    ]

    availableLanguages:Array<string> = null;

    widgetFields:{ [type:string]: Array<PgFormField> } = {
        'totem-widget-products': [
            new PgFormField({ label: 'auto', type: 'select', name: 'filter.type', multi: true, options: [] }),
            new PgFormField({ label: 'auto', type: 'string', name: 'filter.category' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'filter.tags' }),
            new PgFormField({ label: 'auto', type: 'select', name: 'filter.groups', multi: true, options: [] }),
            new PgFormField({ label: 'auto', type: 'string', name: 'filter.ids' }),
        ],
        'totem-widget-text': [
            new PgFormField({ label: 'auto', type: 'string', locale: true, name: 'abstract' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'logo' }),
            new PgFormField({ label: 'auto', type: 'file', fileType: 'images', name: 'cover' }),
            new PgFormField({ label: 'auto', type: 'select', name: 'background', options: this.colorOptions }),
            new PgFormField({ label: 'auto', type: 'html', locale: true, name: 'text' }),
        ],
        'totem-widget-identity': [
            new PgFormField({ label: 'auto', type: 'file', fileType: 'images', name: 'image' }),
            new PgFormField({ label: 'auto', type: 'string', name: 'name' }),
            new PgFormField({ label: 'auto', type: 'string', locale: true, name: 'abstract' }),
            new PgFormField({ label: 'auto', type: 'string', locale: true, name: 'description' }),
            new PgFormField({ label: 'auto', type: 'recipient', name: 'contact' })
        ]
    }

    ngOnInit(): void {
        for(let _field of this.widgetFields['totem-widget-products']) {
            if(_field.name == 'filter.type') {
                for(let _item of ['experience','host','eatery','poi','event','itinerary','article']) {
                    _field.options.push({
                        value: _item,
                        text: _item
                    })
                }
            }
            else if(_field.name == 'filter.groups') {
                _field.options = this.optionMapsService.getFixedOptionMap('*', 'group_id')
            }
        }
    }

    ngOnChanges() {
        if(this.widget != null) {
            for(let i in this.widget) {
                if(i != 'params') {
                    if(this.values[i] == null) this.values[i] = this.widget[i];
                }
            }
    
            for(let j in this.widget.params) {
                if(this.values.params[j] == null) this.values.params[j] = this.widget.params[j];
            }
    
            // CONTENT LOCALIZATION
    
            this.values.title = this._tryContentLocalization(this.values.title)
    
            for(let j in this.widget.params) {
                if(this.widgetFields[this.widget.name] != null) {
                    if(this.values.params[j] != null) {
                        let _mustLocalize = false;
    
                        for(let _field of this.widgetFields[this.widget.name]) {
                            if(_field.name == j) {
                                _mustLocalize = _field.locale;
                                break;
                            }
                        }
                        
                        if(_mustLocalize) {
                            this.values.params[j] = this._tryContentLocalization(this.values.params[j])
                        }
                    }
                }
            }
        }
    }

    private _tryContentLocalization(val:string) {
        if(val == null) return val;
        else {
            let _valObj = null;
        
            try {
                _valObj = JSON.parse(val);
            }
            catch(ex) {
            }
    
            if(_valObj != null && typeof _valObj == 'object') {
                return val;
            }
            else {
                let _content:any = {}
    
                if(this.localizationService.contentDataByTid[val] != null) {
                    _content = this.localizationService.contentDataByTid[val];
                }
                else {
                    _content[this.localizationService.currentLanguage] = val
                }
        
                let _retVal:any = {}
        
                for(let _language of this.availableLanguages) {
                    _retVal[_language] = _content[_language];
                }
        
                return JSON.stringify(_retVal)
            }
        }
    }

    getParamField(name:string, obj?:any) {
        let _nameSplit = name.split('.');
        if(obj == null) obj = this.values.params;

        if(_nameSplit.length > 1) {
            obj = obj[_nameSplit[0]]
            if(obj == null) return null;
            else {
                _nameSplit.shift()
                return this.getParamField(_nameSplit.join('.'), obj);
            }
        }
        else {
            return obj[name];
        }
    }

    setParamField(name:string, val:any, obj?:any) {
        let _nameSplit = name.split('.');
        if(obj == null) obj = this.values.params;

        if(_nameSplit.length > 1) {
            if(obj[_nameSplit[0]] == null) obj[_nameSplit[0]] = {};
            obj = obj[_nameSplit[0]]

            _nameSplit.shift()
            return this.setParamField(_nameSplit.join('.'), val, obj);
        }
        else {
            obj[name] = val;
        }
    }

    JSONStringify(val:any) {
        try {
            return JSON.stringify(val)
        }
        catch(ex) {}
    }

    JSONParse(val:string) {
        try {
            return JSON.parse(val)
        }
        catch(ex) {}
    }

    handleValuesChange() {
        this.valuesChange.emit(this.values)
    }
}
