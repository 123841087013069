import { Directive, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigFormLayoutGroupField } from '../../models/config.resources.model';
import { FormFlow, PgFormField, PgFormGroup } from '../../models/form.model';
import { DataService } from '../../services/data.service';
import { EnvironmentService } from '../../services/environment.service';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';
import { OptionMapsService } from '../../services/option-maps.service';
import { FormsFlowState } from '../elements/forms-main/forms-main.component';
import { PGUtilities } from '../../pg-utilities';
import { AuthService } from '../../services/auth.service';

@Directive()
export abstract class StepFormBase implements OnChanges {

    abstract formId:string;
    abstract resourceId:string;

    abstract mapImportData(data:google.maps.places.PlaceResult):any
    abstract createFormFlow():FormFlow;

    formFlow:FormFlow = null;

    selectOptions:{ [select:string] : Array<{ value:string, text:string }> } = {}

    notFound = false;

    customizeFormFlow() {}

    adaptLoadData(values:any) { 
        if(values != null) {
            let _retVal = JSON.parse(JSON.stringify(values))

            for(let i in this.formFlow.states) {
                for(let _cField of this.formFlow.states[i].form.fieldList) {
                    if(_cField.type == 'boolean') {
                        if(/^flag_/.test(_cField.name)) {
                            let _targetField = _cField.name.replace(/^flag_/, '');
                            let _targetVal = values[_targetField];

                            try {
                                let _targetObj = JSON.parse(_targetVal);
                                if(_targetObj.length > 0) {
                                    _retVal[_cField.name] = true;
                                }
                            }
                            catch(ex) {}
                        }
                    }
                }
            }

            return _retVal;
        } 
        else return {}    
    }

    adaptSaveData(values:any) { 
        if(values != null) {
            let _retVal = JSON.parse(JSON.stringify(values))

            let _flagFields = []

            for(let i in _retVal) {
                if(/^flag_/.test(i)) {
                    _flagFields.push(i)
                }
            }

            for(let _field of _flagFields) {
                if(!_retVal[_field]) {
                    _retVal[_field.replace(/^flag_/, '')] = null;
                }
            }

            for(let i of _flagFields) {
                delete _retVal[i];
            }

            return _retVal;
        } 
        else return {} 
    }    

    initData() { 
        return new Promise<null>((resolve, reject) => {
            resolve(null);
        })
    }
    
    constructor(protected optionMapsService:OptionMapsService, protected localizationService:LocalizationService, protected dataService:DataService, protected authService:AuthService, protected router:Router, protected route:ActivatedRoute, protected environmentService:EnvironmentService, protected notificationsService:NotificationsService) {
    }

    private _initFormFlow() {
        return new Promise<null>((resolve, reject) => {
            // GENERAZIONE OPTIONS DA LISTE
            this.selectOptions = this.optionMapsService.getResourceOptionMaps(this.resourceId);

            this.initData().then(() => {
                this.formFlow = this.createFormFlow()

                // AUTOMATISMI: label, options, layout checkbox, switch
        
                for(let i in this.formFlow.states) {
                    // TOFIX: questo è un po' troppo specifico per stare qua
    
                    let _cFormGroup = this.formFlow.states[i].form.formGroups[0];
                    let _brokerFieldsIndexList:Array<number> = [];

                    for(let i = 0; i < _cFormGroup.fields.length; i++) {
                        if(_cFormGroup.fields[i].name == 'is_broker' || _cFormGroup.fields[i].name == 'broker_code') {
                            _brokerFieldsIndexList.push(i);
                        }
                    }

                    if(_brokerFieldsIndexList.length > 1) {
                        let _brokerFieldsLayoutList:Array<ConfigFormLayoutGroupField> = [];

                        for(let i = _brokerFieldsIndexList.length - 1; i >= 0; i--) {
                            let _cIndex = _brokerFieldsIndexList[i];

                            let _cBrokerFieldLayout:ConfigFormLayoutGroupField = { name: _cFormGroup.fields[_cIndex].name }
                            if(_cBrokerFieldLayout.name == 'broker_code') {
                                _cBrokerFieldLayout.condition = '$form.is_broker';
                            }

                            _brokerFieldsLayoutList.unshift(_cBrokerFieldLayout)
                            _cFormGroup.fields.splice(_cIndex, 1);
                        }
                        
                        this.formFlow.states[i].form.formGroups.unshift(new PgFormGroup({
                            fields: _brokerFieldsLayoutList
                        }))
                    }        

                    if(this.formFlow.states[i].title == null) this.formFlow.states[i].title = 'forms-' + this.formId + '.step.' + i + '.title';
                    if(this.formFlow.states[i].description == 'auto') this.formFlow.states[i].description = 'forms-' + this.formId + '.step.' + i + '.description';
        
                    let _hasLanguages = false;
                    let _hasGroupId = false;
                    let _hasType = false;

                    for(let _cField of this.formFlow.states[i].form.fieldList) {                        
                        if(_cField.name == 'languages') {
                            _cField.default = JSON.stringify(this.localizationService.defaultContentLanguages);
                            _hasLanguages = true;
                        }

                        if(_cField.name == 'group_id') {
                            _hasGroupId = true;
                        }

                        if(_cField.name == 'realm_id') {
                            _cField.default = this.authService.user.realm?.id;
                            _cField.visible = this.authService.user.availableRealms.length > 1;
                        }

                        if(_cField.name == 'type') {
                            _hasType = true;
                        }

                        let _cleanFieldName = _cField.name.replace(/@.*$/, '')

                        if(_cField.label == 'auto') _cField.label = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.label';
                        if(_cField.tooltip == 'auto') _cField.tooltip = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.tooltip';
                        if(_cField.placeholder == 'auto') _cField.placeholder = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.placeholder';

                        if(_cField.type == 'select') {
                            if(_cField.options == null) {
                                _cField.options = this.selectOptions[_cField.name]
                            }
        
                            if(_cField.multi) {
                                this.formFlow.states[i].form.getFieldLayout(_cField.name).display = { fullWidth: true, multiLine: true }
                            }
                        }
                        else if(_cField.type == 'boolean') {
                            if(_cField.name == 'bookable') {
                                this.formFlow.states[i].form.getFieldLayout(_cField.name).display = { fullWidth: true }
                            }
                            else if(/^flag_/.test(_cField.name)) {
                                let _targetField = _cField.name.replace(/^flag_/, '');
                                _cField.label = 'RESOURCES.' + this.resourceId + '.fields.' + _targetField + '.label';
                                _cField.default = false;
        
                                this.formFlow.states[i].form.getFieldLayout(_cField.name).display = { fullWidth: true }
                                this.formFlow.states[i].form.getFieldLayout(_targetField).condition = '$form.' + _cField.name;
                            }
                        }
                        else if(_cField.type == 'html' || _cField.type == 'file' || _cField.type == 'location' || _cField.type == 'timetable-openings' || _cField.type == 'timetable-slots' || _cField.type == 'timetable-periods' || _cField.type == 'timetable-dates' || _cField.type == 'timetable-departures' || _cField.type == 'timetable-days' || _cField.type == 'tickets' || _cField.type == 'tickets-host' || _cField.type == 'survey' || _cField.type == 'info') {
                            this.formFlow.states[i].form.getFieldLayout(_cField.name).display = { fullWidth: true }
                        }
                    }

                    if(!_hasLanguages) {
                        this.formFlow.states[i].form.fieldList.push(new PgFormField({ label: '', type: 'select', multi: true, name: 'languages', readonly: true }))
                    }

                    if(!_hasGroupId) {
                        this.formFlow.states[i].form.fieldList.push(new PgFormField({ label: '', type: 'select', multi: true, name: 'group_id', readonly: true }))
                    }

                    if(!_hasType) {
                        this.formFlow.states[i].form.fieldList.push(new PgFormField({ label: '', type: 'select',name: 'type', readonly: true }))
                    }
        
                    this.formFlow.states[i].form.resetData();
                }

                this.applyEnvironmentConfig();
        
                this.customizeFormFlow();

                for(let i in this.formFlow.states) {
                    this.formFlow.states[i].form.resetData();
                }

                resolve(null)
            })  
        })
    }
    

    applyEnvironmentConfig() {
        if(this.environmentService.environment.CustomFormConfig != null) {
            for(let _id of ['*', this.resourceId]) {
                let _config = this.environmentService.environment.CustomFormConfig[_id];

                if(_config != null) {
                    for(let i in _config) {
                        for(let j in this.formFlow.states) {
                            let _field = this.formFlow.states[j].form.getFieldByName(i);

                            if(_field != null) {
                                for(let k in _config[i]) {
                                    _field[k] = _config[i][k];
                                }

                                break;
                            }
                        }
                    }
                }
            }
        }
    }

    flowState:any = null;
    
    showImport = true;

    @Input() elementId:string = null;
    @Input() returnUrl:string = null;

    @Input() viewOnly:boolean;

    isLoading = false;

    translationJoinField:string = null;
    translationResource:string = null;

    private _translationByLanguage:{
        [language:string]: any
    } = null;

    ngOnChanges(): void {
        if(this.returnUrl == null) this.returnUrl = '/'

        this.translationResource = this.resourceId + 'Translation';
        this.translationJoinField = this.resourceId.replace(/([A-Z])/g, '_$1').replace(/^_/, '').toLowerCase() + '_id';
        this._translationByLanguage = {};

        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
            let _cLanguageBaseObj:any = {
                language: _cLanguage
            }

            _cLanguageBaseObj[this.translationJoinField] = this.elementId;

            this._translationByLanguage[_cLanguage] = _cLanguageBaseObj;
        }

        this.isLoading = true;
        this.notFound = false;

        this.flowState = null;

        this._initFormFlow().then(() => {
            if(this.dataService.isDraftId(this.elementId)) {
                this.getDraftData().subscribe((data) => {
                    this.isLoading = false;

                    if(data.stateHistory != null) {
                        this.flowState = data;

                        if(Object.keys(data).length == 1 && !data.template) {
                            this.showImport = true;
                        }
                        else {
                            this.showImport = false;
                        }
    
                        // TODO: indagare su
                        // - perché se currentState è null esplode tutto?
                        // - perché flowState è any?
    
                        if(this.flowState.currentState == null && this.flowState.stateHistory.length > 0) {
                            this.flowState.currentState = this.flowState.stateHistory.pop();
                        }
    
                        this.onValuesChange(data.values);

                        this.afterInit()
                    }
                    else {
                        this._loadFromValues(data)
                    }
                })
            }
            else {
                this.dataService.getElementData(this.resourceId, this.elementId).subscribe((data) => {
                    if(data == null) {
                        this.isLoading = false;
                        this.notFound = true;
                        this.showImport = false;
                    }
                    else {
                        this.dataService.getResourceData(this.translationResource, null, this.resourceId, this.elementId).subscribe((translData) => {
                            this.isLoading = false;
    
                            this._loadFromValues(data, translData)
                        })
                    }
                })
            }
        })
    }

    private _loadFromValues(data:any, translData?:any) {
        if(Object.keys(data).length == 1 && !data.template) {
            this.showImport = true;
        }
        else {
            this.showImport = false;
        }

        if(translData == null && data.translations != null) {
            translData = data.translations;
            delete data.translations;
        }

        if(translData != null) {
            for(let _cTransl of translData) {
                this._translationByLanguage[_cTransl.language] = _cTransl;
    
                for(let i in _cTransl) {
                    if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'language') {
                        let _localeValue:any = {};
    
                        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                            if(this._translationByLanguage[_cLanguage]) {
                                _localeValue[_cLanguage] = this._translationByLanguage[_cLanguage][i];
                            }
                            else {
                                _localeValue[_cLanguage] = {}
                            }
                        }
    
                        data[i + '@' + this.translationResource] = JSON.stringify(_localeValue);
                    }
                }
            }
        }

        this.importData = this.adaptLoadData(data);

        this.onValuesChange(this.importData);

        this.afterInit()
    }

    afterInit() {}

    @Output() valuesChange = new EventEmitter<any>();

    onValuesChange(values) {
        values = this.adaptSaveData(values);

        for(let i in values) {
            let _cResource = i.split('@')[1];

            if(_cResource != null) {
                if(values[i] != null) {
                    let _localeValue = JSON.parse(values[i]);

                    values[i.split('@')[0]] = _localeValue[this.localizationService.availableApplicationLanguages[0]];
                }
                else {
                    values[i.split('@')[0]] = null;
                }
            }
        }

        this.valuesChange.emit(values);
    }

    protected getDraftResourceId() {
        return this.resourceId;
    }

    protected getDraftData() {
        return this.dataService.getDraftElementData(this.getDraftResourceId(), this.elementId)
    }

    protected saveDraftData(data:any) {
        return this.dataService.saveDraftElementData(this.getDraftResourceId(), this.elementId, data)
    }

    protected deleteDraft() {
        return this.dataService.deleteDraftElement(this.getDraftResourceId(), this.elementId)
    }

    private _checkDraftSaveTimeout = null;

    draftStatus = null;

    onFlowStateChange(data:FormsFlowState) {
        if(this.dataService.isDraftId(this.elementId) && !this.isSaving) {
            if(this._checkDraftSaveTimeout) clearTimeout(this._checkDraftSaveTimeout)
            this.draftStatus = 'dirty'

            this._checkDraftSaveTimeout = setTimeout(() => {
                this._checkDraftSaveTimeout = null;
            
                this.draftStatus = 'saving'

                this.saveDraftData(data).subscribe(() => {
                    this.draftStatus = 'saved'
                }, () => {
                    this.draftStatus = 'error'
                })
            }, 1000)
        }
    }

    importData: any = null;

    setImportData(data:google.maps.places.PlaceResult) {

        if(data == null) {
            this.importData = null;
        }
        else {
            this.importData = this.mapImportData(data);
        }

        this.showImport = false;
    }

    setTemplateData(data:any) {
        if(data == null) {
            this.showImport = false;
        }
        else {
            this.isLoading = true;

            this.dataService.getResourceData(this.translationResource, null, this.resourceId, data.id).subscribe((translData) => {
                this.isLoading = false;
                let _templateData = JSON.parse(JSON.stringify(data));
    
                _templateData.id = null;
                _templateData.title = null;
                _templateData.template = 0;
                _templateData.certificate = null;
    
                this._loadFromValues(_templateData, translData)
            })
        }
    }

    parseAddressComponents(data:Array<google.maps.GeocoderAddressComponent>) {
        
        return PGUtilities.parseAddressComponents(data)
    }

    isSaving = false;

    onSaveData(values:any) {
        if(!this.isSaving) {
            this.isSaving = true;

            values = this.adaptSaveData(values);

            let _keysToRemove = [];

            for(let i in values) {
                let _cResource = i.split('@')[1];

                if(_cResource != null) {
                    _keysToRemove.push(i);

                    if(values[i] != null) {
                        let _localeValue = JSON.parse(values[i]);

                        for(let _cLanguage in _localeValue) {
                            if(this._translationByLanguage[_cLanguage] != null) {
                                this._translationByLanguage[_cLanguage][i.split('@')[0]] = _localeValue[_cLanguage]
                            }
                        }

                        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                            // prendo la prima lingua che ha un valore e la uso come valore del campo di base
                            if(_localeValue[_cLanguage] != null && _localeValue[_cLanguage] != '') {
                                values[i.split('@')[0]] = _localeValue[_cLanguage];
                                break;
                            }
                        }
                    }
                    else {
                        values[i.split('@')[0]] = null;
                    }
                }
            }

            for(let _cKey of _keysToRemove) {
                delete values[_cKey];
            }

            this.dataService.saveElementData(this.resourceId, this.elementId, values).subscribe((data) => {
                if(data == null) {
                    this.isSaving = false;
                }
                else {
                    let _cAction:'update'|'insert' = 'update';
                    
                    if(values.id != data.id) {
                        _cAction = 'insert'
                    }

                    this.dataService.saveElementTranslations(data.id, this.translationResource, this.translationJoinField, data.languages, this._translationByLanguage).subscribe(() => {
                        this.isSaving = false;

                        if(data != null) {
                            if(this.resourceId == 'Experience' && _cAction == 'insert' && !data.published) { // TODO: questo andrebbe spostato nella form di experience in qualche modo
                                this.notificationsService.addLocalNotification('forms-result-splash.action-text-' + _cAction + '-experience-unpublished', 'success')
                            }
                            else {
                                this.notificationsService.addLocalNotification('forms-result-splash.action-text-' + _cAction, 'success', null, null, 3000)
                            }

                            this.doFormReturn(_cAction, data.id)
                        }
                    })
                }
            })
        }
    }

    onDeleteData() {
        if(!this.isSaving) {
            this.isSaving = true;

            this.dataService.deleteElement(this.resourceId, this.elementId).subscribe((data) => {
                this.isSaving = false;

                if(data) {
                    this.notificationsService.addLocalNotification('forms-result-splash.action-text-delete', 'success', null, null, 3000)
                    this.doFormReturn('delete')
                }
            })
        }
    }

    onCancelEdit() {
        if(!this.isSaving) {
            this.doFormReturn('cancel')
        }
    }

    onResetForm() {
        if(!this.isSaving) {
            this.isSaving = true;

            this.deleteDraft().subscribe((data) => {
                this.isSaving = false;

                if(data) {
                    this.doFormReturn('reset')
                }
            })
        }
    }

    @Input() inModal:boolean;

    @Output() formReturn = new EventEmitter<{ action:string, id:string }>();

    doFormReturn(action:string, id?:string) {
        this.formReturn.emit({ action: action, id: id || this.elementId })

        if(!this.inModal) {
            this.router.navigate([this.returnUrl], { relativeTo: this.route })
        }
    }
}