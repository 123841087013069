import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalizationService } from '../../../services/localization.service';
import { BookingService } from '../../../services/booking.service';
import { AuthService } from '../../../services/auth.service';
import { EnvironmentService } from '../../../services/environment.service';
import { PersistenceService } from '../../../services/persistence.service';
import { PGAccommodation, PGAvailabilitySelection, PGBrokerData, PGPaymentMethod, PGProductData, PGSupplierData, PGSurveyAnswers, PGTicketsSelection, PGUserContact, PGVoucherData } from '../../../models/booking.model';
import { TimetableDataList } from '../../../models/timetable.model';
import { TicketsData } from '../../../models/tickets.model';
import { SurveyData } from '../../../models/survey.model';
import { PGUtilities } from '../../../pg-utilities';

@Component({
  selector: 'app-pg-create-booking',
  templateUrl: './pg-create-booking.component.html',
  styleUrls: ['./pg-create-booking.component.scss']
})
export class PgCreateBookingComponent implements OnInit, OnChanges {

    constructor(private router:Router, private bookingService:BookingService, private authService:AuthService, private localizationService:LocalizationService, private environmentService:EnvironmentService, private persistenceService:PersistenceService) { }

    @Input() productType:'experience'|'host'|'eatery'
    @Input() productId:string;

    @Input() defaultSelections:{ language:string, date:string, begin:string, end:string };

    @Input() testMode:boolean;

    isLoading = false;

    productData:PGProductData = null;
    supplierData:PGSupplierData = null;
    availabilityData:TimetableDataList = null;
    accommodationData:Array<PGAccommodation> = null;
    priceOnRequest:boolean = null;
    ticketsData:TicketsData = null;
    surveyData:SurveyData = null;
    
    languageOptions:Array<{ value: string, text: string }> = null;
    paymentMethodOptions:Array<{ icon: string, value: string, text: string }> = null;

    state: {
        draftId?:string,
        selectedLanguage?:string,
        selectedAvailability?:PGAvailabilitySelection,
        selectedAccommodation?:any,
        selectedTickets?:PGTicketsSelection,
        surveyAnswers?:PGSurveyAnswers,
        selectedPaymentMethod?:PGPaymentMethod,
        additionalInformations?:string
    } = {}

    brokerRegisterURL:string = null;

    localStorageEnabled = false;

    stepHistory:Array<'language'|'availability'|'tickets'|'accommodation'|'survey'|'payment-method'|'confirm'> = null;
    lastStep:'language'|'availability'|'tickets'|'accommodation'|'survey'|'payment-method'|'confirm' = null;
    currentStep:'language'|'availability'|'tickets'|'accommodation'|'survey'|'payment-method'|'confirm' = null;

    editUserContact:{ name:string, via:'email'|'phone', value: string, phone:string } = null;

    editBrokerCode:{ 
        enabled: boolean, 
        status: 'checking'|'valid'|'invalid', 
        result: PGBrokerData,
        value: string 
    } = { 
        enabled: false, 
        status: null, 
        result: null,
        value: null 
    }

    editVoucherCode:{ 
        enabled: boolean, 
        status: 'checking'|'valid'|'invalid', 
        result: PGVoucherData,
        error: string,
        value: string 
    } = { 
        enabled: false, 
        status: null, 
        result: null,
        error: null,
        value: null 
    }

    checkBrokerCode:(value:string) => Observable<PGBrokerData> = null;
    checkVoucherCode:(value:string) => Observable<PGVoucherData> = null;

    ngOnInit(): void {
        let _that = this;

        this.checkBrokerCode = function(value:string) {
            return _that.bookingService.checkBrokerCode(value)
        }

        this.checkVoucherCode = function(value:string) {
            return _that.bookingService.checkVoucherCode(value)
        }

        this.brokerRegisterURL = this.environmentService.environment.FormsURL;

        let _savedBrokerCode = this.persistenceService.getItem('PGCheckout_BrokerCode');
        if(this.hasBroker() && _savedBrokerCode != null) {
            this.localStorageEnabled = true;

            this.editBrokerCode.enabled = true;
            this.editBrokerCode.value = _savedBrokerCode;
        }

        this.editUserContact = {
            name: null,
            via: null,
            value: null,
            phone: null
        }

        this.authService.statusChange.subscribe(() => {
            if(this.isFullyLoggedIn()) {
                this._checkFillEditUserContact()
    
                if(this.mustLoginToProceed) {
                    this.saveDraft();
                }
            }
        })
    }

    private _checkFillEditUserContact() {
        if(this.currentStep == 'payment-method' && this.authService.user != null) {
            if(this.editUserContact.name == null || this.editUserContact.name == '') this.editUserContact.name = this.authService.user.name;

            if(this.editUserContact.via == null) {
                if(this.authService.user.email != null && this.authService.user.email != '') {
                    this.editUserContact.via = 'email';
                    this.editUserContact.value = this.authService.user.email;
                    this.editUserContact.phone = this.authService.user.phone;
                }
                else {
                    this.editUserContact.via = 'phone';
                    this.editUserContact.value = this.authService.user.phone;
                }
            }
        }
    }

    ngOnChanges() {
        this.productData = null;
        this.accommodationData = [];

        if(this.productId != null) {
            this.isLoading = true;

            this.bookingService.getProduct(this.productId, this.productType, this.testMode).subscribe((data) => {
    
                this.productData = data;

                this.bookingService.getProductSupplier(this.productData, this.testMode).subscribe((data) => {
                    this.supplierData = data;

                    if(this.productType == 'host') {
                        if(this.productData.raw_data != null && this.productData.raw_data.accommodations != null)   {
                            for(let _cAccommodation of this.productData.raw_data.accommodations) {
                                if(_cAccommodation.accommodation_prices != null) {
                                    let _cPrices:TicketsData =  PGUtilities.tryParseJSON(_cAccommodation.accommodation_prices);
                                    if(_cPrices.fullPrice != null) this.accommodationData.push(_cAccommodation);
                                }
                            }
                        }
                    }
    
                    this._finishLoading()
                })
            })
        }
    }

    hasSupplierData() {

    }

    private _finishLoading() {
        this.isLoading = false;

        this._resetBooking();

        let _savedData = this.persistenceService.getItem('PGCheckout_State');
        if(_savedData != null) {
            let _savedObj = JSON.parse(_savedData)
            let _savedId = this.productType + '_' + this.productId;

            if(_savedObj[_savedId] != null) {
                if(_savedObj[_savedId].data != null && _savedObj[_savedId].time + 20 * 60 * 60 * 1000 > Date.now()) {
                    for(let i in _savedObj[_savedId].data) {
                        if(_savedObj[_savedId].data[i] != null) this[i] = _savedObj[_savedId].data[i];
                    }
                }
            }
        }

        this._checkMustLoginToProceed()
    }

    private _saveState() {
        let _savedId = this.productType + '_' + this.productId;

        let _currentState:any = {}

        _currentState[_savedId] = {
            time: Date.now(),
            data: {}
        }

        for(let _prop of ['state','editUserContact','editVoucherCode','editBrokerCode','stepHistory','lastStep','currentStep']) {
            _currentState[_savedId].data[_prop] = this[_prop]
        }

        this.persistenceService.setItem('PGCheckout_State', JSON.stringify(_currentState), 'technical');
    }

    private _resetBooking() {
        this.languageOptions = [];
        this.availabilityData = null;
        this.priceOnRequest = null;
        this.ticketsData = null;
        this.surveyData = null;
        this.paymentMethodOptions = [];

        this.state = {}

        this.stepHistory = [];
        this.lastStep = null;
        this.currentStep = null;

        let _cLanguageList = PGUtilities.tryParseJSON(this.productData.languages);

        if(_cLanguageList != null) {
            if(typeof _cLanguageList == 'string') _cLanguageList = [_cLanguageList];

            for(let _cLanguage of _cLanguageList) {
                this.languageOptions.push({ value: _cLanguage, text: this.localizationService.languageLabels[_cLanguage] })
                if(this.localizationService.currentLanguage == _cLanguage) {
                    this.state.selectedLanguage = _cLanguage;
                }
            }

            if(this.state.selectedLanguage == null && this.languageOptions[0] != null) {
                this.state.selectedLanguage = this.languageOptions[0].value;
            }
        }

        if(this.state.selectedLanguage == null) {
            this.currentStep = 'language'
        }
        else {
            this.currentStep = 'availability'
            if(this.languageOptions.length > 1) {
                this.lastStep = 'language'
                this.stepHistory.push(this.lastStep)
            }
        }

        this.availabilityData = new TimetableDataList(this.productData.availability)

        this.priceOnRequest = this.productData.price_on_request;
        this.ticketsData = new TicketsData(this.productData.tickets);
        this.surveyData = new SurveyData(this.productData.survey);

        if(this.defaultSelections != null) {
            this.state.selectedLanguage = this.defaultSelections.language;

            this.state.selectedAvailability = {
                id: this.productId,
                date: this.defaultSelections.date,
                begin: this.defaultSelections.begin,
                end: this.defaultSelections.end,
                availability: null
            }

            if(this.hasParticipantsLimit()) {
                this.state.selectedAvailability.availability = {
                    current: this.productData.maximum_participants,
                    total: this.productData.maximum_participants
                }

                let _cDateSplit = this.defaultSelections.date.split('-');

                let _cYear = parseInt(_cDateSplit[0]);
                let _cMonth = parseInt(_cDateSplit[1]);
                let _cDay = parseInt(_cDateSplit[2]);
                
                this.isLoading = true;

                this.bookingService.getBookingsCountDay(this.productType, this.productId, _cYear, _cMonth, _cDay).subscribe((count) => {
                    this.isLoading = false;

                    if(count != null && count[this.defaultSelections.begin] != null) {
                        this.state.selectedAvailability.availability.current -= count[this.defaultSelections.begin];
                    }
                })
            }
        }

        let _cPaymentMethodList = null;

        if(this.productType != 'experience') {
            _cPaymentMethodList = ['sul_posto']
        }
        else {
            _cPaymentMethodList = PGUtilities.tryParseJSON(this.productData.payment);
        }

        if(_cPaymentMethodList != null) {
            if(typeof _cPaymentMethodList == 'string') _cPaymentMethodList = [_cPaymentMethodList];

            for(let _cPaymentMethod of _cPaymentMethodList) {
                if(_cPaymentMethod != 'online' || (this.environmentService.environment.StripeAPIKey != null && this.environmentService.environment.StripeAPIKey != '')) {
                    let _cIcon = null;

                    switch(_cPaymentMethod) {
                        case 'sul_posto': _cIcon = 'fa-regular fa-map-marker'; break;
                        case 'bonifico': _cIcon = 'fa-regular fa-money-check-alt'; break;
                        case 'online': _cIcon = 'fa-regular fa-credit-card'; break;
                    }

                    this.paymentMethodOptions.push({ icon: _cIcon, value: _cPaymentMethod, text: 'OPTIONMAPS.' + this.getCapitalizedProductType() + '.payment.' + _cPaymentMethod })
                }
            }
        }
    }

    isSavingDraft = false;

    saveDraft(andConfirm?:boolean) {
        if(this.isFullyLoggedIn()) {

            if(this.canSaveDraft()) {
                this.warnNoLongerAvailable = false;
                this.warnBookingClosed = false;
    
                this.isSavingDraft = true;

                if(this.state.draftId == null) {
                    this.bookingService.createDraft(this.productData, this.state.selectedLanguage, this.state.selectedAvailability, this.state.selectedTickets, this.state.selectedAccommodation, this.state.surveyAnswers, this._getCurrentUserContact(), this.state.selectedPaymentMethod, this.state.additionalInformations, this._getCurrentVoucherCode(), this._getCurrentBrokerCode()).subscribe((data) => {
                        this.isSavingDraft = false;  

                        if(data != null) {
                            this.state.draftId = data.id;
                            
                            if(andConfirm) this._confirmDraft();
                        }
                        else {
                            this._checkStillAvailable()
                        }
                    });
                }
                else {
                    this.bookingService.updateDraft(this.state.draftId, this.state.selectedLanguage, this.state.surveyAnswers, this._getCurrentUserContact(), this.state.selectedPaymentMethod, this.state.additionalInformations, this._getCurrentVoucherCode(), this._getCurrentBrokerCode()).subscribe((data) => {
                        this.isSavingDraft = false;
                        
                        if(data != null) {
                            if(andConfirm) this._confirmDraft();
                        }
                        else {
                            this.state.draftId = null;
                            this.saveDraft(andConfirm)
                        }
                    });
                }
            }
            else {
                if(this.state.draftId != null) {

                    this.isSavingDraft = true;
                    this.bookingService.deleteDraft(this.state.draftId).subscribe(() => {
                        this.isSavingDraft = false;

                        this.state.draftId = null;
                    });
                }
            }
        }
    }

    private _checkStillAvailable() {
        let _cDateSplit = this.state.selectedAvailability.date.split('-');
        
        let _cYear = parseInt(_cDateSplit[0]);
        let _cMonth = parseInt(_cDateSplit[1]);
        let _cDay = parseInt(_cDateSplit[2]);
        this.isSavingDraft = true;  

        this.bookingService.getBookingsCountDay(this.productType, this.productId, _cYear, _cMonth, _cDay).subscribe((count) => {
            this.isSavingDraft = false; 

            if(count != null && count[this.state.selectedAvailability.begin] != null) {
                if(this.state.selectedAvailability.availability.current > count[this.state.selectedAvailability.begin]) {
                    this.warnNoLongerAvailable = true;
                    this._resetBooking();
                    this.saveDraft();
                }
            }

            if(!this.warnNoLongerAvailable) { // TODO: non è il massimo andare per esclusione così, createBooking dovrebbe dare la reason nella error
                this.warnBookingClosed = true;
                this._resetBooking();
                this.saveDraft();
            }
        })
    }

    private _confirmDraft() {
        this.isLoading = true;

        this.bookingService.confirmDraft(this.state.draftId).subscribe((data) => {
            this.isLoading = false;

            this.persistenceService.removeItem('PGCheckout_State');
            this.router.navigate(['/checkout'], { fragment: '/booking/' + data.id })
        });
    }

    goPrevStep() {
        this.currentStep = this.lastStep;
        this.stepHistory.pop();

        if(this.currentStep == 'payment-method') {
            this.state.selectedPaymentMethod = null;
        }
        else if(this.currentStep == 'survey') {
            this.state.surveyAnswers = null;
        }
        else if(this.currentStep == 'tickets') {
            this.state.selectedTickets = null;
        }
        else if(this.currentStep == 'accommodation') {
            this.state.selectedAccommodation = null;
        }
        else if(this.currentStep == 'availability') {
            this.state.selectedAvailability = null;
        }
        else if(this.currentStep == 'language') {
            this.state.selectedLanguage = null;
        }

        this.lastStep = this.stepHistory[this.stepHistory.length - 1]

        this._saveState();
        this.saveDraft();
    }

    goNextStep() {
        if(!this.isSavingDraft) {
            // TODO: gestire un pelo meglio

            this.lastStep = this.currentStep;
            this.stepHistory.push(this.lastStep)

            if(this.lastStep == 'language') {
                this.currentStep = 'availability'
            }
            else if(this.lastStep == 'availability') {
                if(this.productType == 'eatery') {
                    this.currentStep = 'survey'
                }
                else if(this.productType == 'host') {
                    this.currentStep = 'accommodation'
                }
                else {
                    this.currentStep = 'tickets'   
                }
            }
            else if(this.lastStep == 'accommodation') {
                this.ticketsData = new TicketsData(this.state.selectedAccommodation.accommodation_prices);
                this.currentStep = 'tickets'
            }
            else if(this.lastStep == 'tickets') {
                this.currentStep = 'survey'
            }
            else if(this.lastStep == 'survey') {
                this.currentStep = 'payment-method'
            }
            else if(this.lastStep == 'payment-method') {
                this.currentStep = 'confirm'
            }

            if(this.currentStep == 'survey' && !this.hasSurvey()) {
                this.currentStep = 'payment-method'
            }

            this._checkFillEditUserContact();
            this._saveState();
            this._checkMustLoginToProceed();
            this.saveDraft();
        }
    }

    canSaveDraft() {
        if(this.hasTickets()) {
            return this.state.selectedTickets != null;
        }
        else {
            return this.state.selectedAvailability != null;
        }
    }

    mustLoginToProceed = false;

    private _checkMustLoginToProceed() {
        this.mustLoginToProceed = this.canSaveDraft();
    }

    isFullyLoggedIn() {
        return this.authService.isFullyLoggedIn()
    }

    onAvailabilitySelected(availability:PGAvailabilitySelection) {
        this.state.selectedAvailability = availability;
        this.goNextStep();
    }

    setSelectedAccommodation(accommodation:PGAccommodation) {
        this.state.selectedAccommodation = accommodation;
        this.goNextStep()
    }

    onTicketsSelected(selection:PGTicketsSelection) {
        this.state.selectedTickets = selection;
        this.goNextStep();
    }

    onSurveyAnswered(anwsers:PGSurveyAnswers) {
        this.state.surveyAnswers = anwsers;
        this.goNextStep();
    }

    onPaymentMethodSelected(value:PGPaymentMethod) {
        this.state.selectedPaymentMethod = value;
        this.goNextStep();
    }

    isBookingFree() {
        return this.productType == 'experience' && !this.priceOnRequest && this.state.selectedTickets != null && this.state.selectedTickets.fullPrice == 0;
    }

    hasParticipantsLimit() {
        return (this.productData.maximum_participants != null && !this.productData.open_event && !this.productData.overbooking)
    }

    onLocalStorageEnabledChange() {
        if(!this.localStorageEnabled) {
            this.persistenceService.removeItem('PGCheckout_BrokerCode');
        }
        else {
            if(this.editBrokerCode != null && this.editBrokerCode.status == 'valid') {
                this.persistenceService.setItem('PGCheckout_BrokerCode', this.editBrokerCode.value, 'technical');
            }
        }
    }

    isEditUserContactPhoneValid() { 
        return(this.editUserContact.phone != null && this.editUserContact.phone.length > 3 && PGUtilities.phoneRegExp.test(this.editUserContact.phone))
    }

    isEditUserContactValid() {
        if(this.editUserContact.name == null || this.editUserContact.name == '') return false;
        else if(this.editUserContact.via == null) return false;
        else if(this.editUserContact.via != 'phone' && !this.isEditUserContactPhoneValid()) return false;
        else return true;
    }

    hasSurvey() {
        return this.productData.survey != null && this.productData.survey.questions.length > 0;
    }

    hasTickets() {
        return this.productType == 'experience' || this.productType == 'host';
    }

    hasVoucher() {
        return this.productType == 'experience';
    }

    hasBroker() {
        return this.productType == 'experience' || this.productType == 'host';
    }

    canConfirmBooking() {
        return this.isFullyLoggedIn() && !this.isLoading && !this.isSavingDraft &&
            this.state.selectedAvailability != null && this.isEditUserContactValid() &&
            (!this.hasTickets() || this.state.selectedTickets != null) && 
            (!this.hasBroker() || (!this.editBrokerCode.enabled || this.editBrokerCode.status == 'valid'));
    }

    warnNoLongerAvailable = false;
    warnBookingClosed = false;

    private _getCurrentUserContact() {
        let _retVal = new PGUserContact();
        _retVal.name = this.editUserContact.name;
        
        if(this.editUserContact.via == 'email') {
            _retVal.email = this.editUserContact.value;
            _retVal.phone = this.editUserContact.phone;
        }
        else if(this.editUserContact.via == 'phone') {
            _retVal.phone = this.editUserContact.value;
        }

        return _retVal;
    }

    private _getCurrentVoucherCode() {
        if(this.editVoucherCode.enabled && this.editVoucherCode.status == 'valid') return this.editVoucherCode.value
        else return null;
    }

    private _getCurrentBrokerCode() {
        if(this.editVoucherCode.enabled && this.editVoucherCode.status == 'valid') return this.editVoucherCode.value
        else return null;
    }

    confirmBooking() {
        if(this.canConfirmBooking()) {
            this._saveState();
            this._checkMustLoginToProceed();
            this.saveDraft(true);
        }
    }

    setVoucherCodeEnabled(val:boolean) {
        this.editVoucherCode.enabled = val;
        if(val) {
            this.editBrokerCode.enabled = false;
        }
    }

    setBrokerCodeEnabled(val:boolean) {
        this.editBrokerCode.enabled = val;
        if(val) {
            this.editVoucherCode.enabled = false;
        }
    }

    getBrokerQuota() {
        return this.bookingService.calculateBrokerQuota(this.productData, this.state.selectedTickets)
    }

    getDiscountPrice(val:number) {
        return Math.round(this.bookingService.calculateSelectedTicketsTotal(this.state.selectedTickets) * (100 - val)) / 100
    }

    getCapitalizedProductType() {
        return this.productType.charAt(0).toUpperCase() + this.productType.substring(1);
    }

    getLastStepTranslationId() {
        if(this.lastStep != null) {
            if(this.productType == 'host' && this.lastStep == 'tickets') return 'tickets-host'
            else return this.lastStep;
        }
    }

    getAvailabilityDayMode() {
        if(this.productType == 'host') return 'lapse';
        else if(this.productType == 'experience') {
            if(this.productData.subtype == 'package' || this.productData.subtype == 'event') return 'single'
        }
    }

    isPayable() {
        return this.priceOnRequest || this.ticketsData.fullPrice == 0 || this.paymentMethodOptions.length > 0
    }
}
