<div class="EFMap">
    <div class="EFMap-Buttons my-3 px-2">
        <button *ngIf="resultNum == 0" class="btn btn-link bg-white shadow-sm text-muted">
            {{ 'experience-finder.general.no-results' | locTranslate }}
        </button>
        <button *ngIf="resultNum > 0" class="btn btn-link bg-white shadow-sm" (click)="centerMap(true)">
            <span>{{ 'experience-finder.general.num-results' | locTranslate:{ num: resultNum } }}</span>
            <i class="fa-regular fa-dot-circle"></i>
        </button>
        <div *ngIf="hasDirections" class="ms-2 btn-group">
            <button class="btn {{travelMode == 'walking' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('walking')">
                <i class="fa-regular fa-walking"></i>
            </button>
            <button class="btn {{travelMode == 'bicycling' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('bicycling')">
                <i class="fa-regular fa-biking"></i>
            </button>
            <button class="btn {{travelMode == 'driving' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('driving')">
                <i class="fa-regular fa-car"></i>
            </button>
        </div>
    </div>
    <div class="EFMap-Inner GoogleMaps" #gmapsElement></div>
</div>