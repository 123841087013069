<div class="PGBookedProduct">
    <div>
        <div *ngIf="selectedAvailability != null || status != null" class="PGBookedProduct-ProductHeader border rounded bg-white">
            <div class="d-flex justify-content-between align-items-center p-3">
                <ng-container *ngIf="selectedAvailability != null">
                    <div *ngIf="selectedAvailability.from != null && selectedAvailability.to != null">
                        <i class="fa-regular fa-calendar me-2"></i> {{formatSelectedAvailabilityDateFrom()}} - {{formatSelectedAvailabilityDateTo()}}
                    </div>
                    <div *ngIf="selectedAvailability.from == null && selectedAvailability.to == null">
                        <i class="fa-regular fa-calendar me-2"></i>{{formatSelectedAvailabilityDate()}}
                        <i class="fa-regular fa-clock ms-3 me-2"></i>{{formatSelectedAvailabilityTime()}}
                    </div>
                </ng-container>
                <div *ngIf="status != null">
                    <div class="badge bg-{{getStatusColor()}}">{{ 'pg-booking-product.status-' + status | locTranslate}}</div>
                </div>
            </div>
        </div>
        <app-pg-product-card *ngIf="productData != null" [compact]="compact == true" [productData]="productData" [supplierData]="supplierData"></app-pg-product-card>
    </div>
    <div pdfgen="block" class="px-3 pt-3 rounded border PGBookedProduct-Content bg-white">
        <span [hidden]="compact == true">
            <div *ngIf="selectedLanguage != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-selected-' + (productData.type || 'experience') + '-language' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3" pdfgen="line">
                    {{getLanguageLabel()}}
                </div>
            </div>
            
            <div *ngIf="selectedAvailability != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-date' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected PGBookedProduct-Selected--BFloatDeep border-bottom pb-3 mb-3">
                    <div pdfgen="line">
                        <ng-container *ngIf="selectedAvailability.from != null && selectedAvailability.to != null">
                            <div [innerHTML]="'pg-booking-product.date-day-from' | locTranslate: { day: formatSelectedAvailabilityDateFrom() }"></div>
                            <div [innerHTML]="'pg-booking-product.date-day-to' | locTranslate: { day: formatSelectedAvailabilityDateTo() }"></div>
                        </ng-container>
                        <ng-container *ngIf="selectedAvailability.from == null && selectedAvailability.to == null">
                            <div [innerHTML]="'pg-booking-product.date-day' | locTranslate: { day: formatSelectedAvailabilityDate() }"></div>
                            <ng-container *ngIf="selectedAvailability.slotDuration != null">
                                <div [innerHTML]="'pg-booking-product.date-time' | locTranslate: { time: formatSelectedAvailabilityTime() }"></div>
                            </ng-container>
                            <ng-container *ngIf="selectedAvailability.slotDuration == null">
                                <ng-container *ngIf="selectedAvailability.begin != null || selectedAvailability.end != null">
                                    <div [innerHTML]="'pg-booking-product.date-time-from' | locTranslate: { time: formatSelectedAvailabilityLapseFrom() }"></div>
                                    <div [innerHTML]="'pg-booking-product.date-time-to' | locTranslate: { time: formatSelectedAvailabilityLapseTo() }"></div>
                                    <div *ngIf="selectedAvailability.timezone != currentTimezone" [innerHTML]="'pg-booking-product.date-timezone' | locTranslate: { tz: selectedAvailability.timezone }"></div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div *ngIf="showNotCancelableWarning()" class="alert alert-warning mt-3 mb-0">
                        <h5>{{ 'pg-booking-product.not-cancelable-title' | locTranslate }}</h5>
                        <div>{{ 'pg-booking-product.not-cancelable-text' | locTranslate }}</div>
                    </div>
                </div>
            </div>
    
            <div *ngIf="selectedAccommodation != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-accommodation' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected PGBookedProduct-Selected--BFloatDeep border-bottom pb-3 mb-3">
                    <app-pg-product-host-accommodation [accommodationData]="selectedAccommodation"></app-pg-product-host-accommodation>
                </div>
            </div>
    
            <div *ngIf="selectedTickets != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-tickets' + (productData.type == 'host' ? '-host' : '') | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected PGBookedProduct-Selected--BFloatDeep border-bottom pb-3 mb-3">
                    <div *ngIf="productData.price_on_request">
                        <div *ngIf="selectedTickets.fullQuantity > 0" [innerHTML]="'pg-booking-product.tickets-price-on-request' | locTranslate: { quantity: selectedTickets.fullQuantity }"></div>
                    </div>
                    <div *ngIf="!productData.price_on_request">
                        <div pdfgen="line">
                            <div *ngIf="selectedTickets.fullQuantity > 0" [innerHTML]="'pg-booking-product.tickets-full-price' + (productData.type == 'host' ? '-host' : '') | locTranslate: { price: formatPrice(selectedTickets.fullPrice, true), quantity: selectedTickets.fullQuantity }"></div>
                            <ng-container *ngFor="let other of selectedTickets.otherTickets">
                                <div *ngIf="other.quantity > 0" [innerHTML]="'pg-booking-product.tickets-other' + (productData.type == 'host' ? '-host' : '') | locTranslate: { label: formatTicketName(other.name), price: formatPrice(other.price, true), quantity: other.quantity }"></div>
                            </ng-container>
                        </div>
                        <div *ngIf="productData.type != 'host'" pdfgen="line" [innerHTML]="'pg-booking-product.tickets-total' | locTranslate: { price: formatPrice(getSelectedTicketsTotal(), true) }"></div>
                    </div>
                </div>
            </div>
    
            <div *ngIf="surveyAnswers != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-survey' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <div>
                        <ng-container *ngFor="let question of surveyAnswers.questions">
                            <div  pdfgen="line"><b>{{formatQuestionText(question.text)}}</b></div>
                            <div *ngIf="question.type == 'boolean' && question.answer != null" pdfgen="line">{{ 'pg-survey-editor.answer-boolean-' + question.answer  | locTranslate }}</div>
                            <div *ngIf="question.type == 'string'" pdfgen="line">{{question.answer}}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
        
            <div *ngIf="discountCode != null" class="PGBookedProduct-Section">
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <div>
                        <span>{{ 'pg-booking-product.discount-code' | locTranslate }}</span>
                        <b class="float-end">{{discountCode}}</b>
                    </div>
                    <ng-container *ngIf="discountValue != null">
                        <div>
                            <span>{{ 'pg-booking-product.discount-applied' | locTranslate }}</span>
                            <b class="float-end text-success">-{{discountValue}}%</b>
                        </div>
                        <div *ngIf="selectedTickets != null">
                            <span>{{ 'pg-booking-product.discount-total' | locTranslate }}</span>
                            <b class="float-end">{{formatDiscountPrice()}}</b>
                        </div>
                    </ng-container>
                </div>
            </div>
    
            <div *ngIf="userEmail != null || userPhone != null" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-contact' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <div *ngIf="userName != null" pdfgen="line">
                        <span>{{ 'pg-booking-product.contact-name' | locTranslate }}</span>
                        <b class="float-end">{{userName}}</b>
                    </div>
                    <div *ngIf="userEmail != null" pdfgen="line">
                        <span>{{ 'pg-booking-product.contact-email' | locTranslate }}</span>
                        <b class="float-end">{{userEmail}}</b>
                    </div>
                    <div *ngIf="userPhone != null" pdfgen="line"> 
                        <span>{{ 'pg-booking-product.contact-phone' | locTranslate }}</span>
                        <b class="float-end">{{userPhone}}</b>          
                    </div>
                </div>
            </div>
    
            <div *ngIf="brokerCode != null" class="PGBookedProduct-Section">
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <div>
                        <span>{{ 'pg-booking-product.broker-code' | locTranslate }}</span>
                        <b class="float-end">{{brokerCode}}</b>
                    </div>
                    <div>
                        <span>{{ 'pg-booking-product.broker-discount' | locTranslate }}</span>
                        <b class="float-end">{{productData.broker_discount}} %</b>
                    </div>
                    <div *ngIf="selectedTickets != null">
                        <span>{{ 'pg-booking-product.broker-quota' | locTranslate }}</span>
                        <b class="float-end">{{getBrokerQuota()}} &euro;</b>          
                    </div>
                </div>
            </div>
    
            <div *ngIf="isOptioned" class="PGBookedProduct-Section PGBookedProduct-Section-Optioned">
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <h5 class="m-0">
                        <i class="fa-regular fa-hourglass me-2"></i>{{ 'pg-booking-product.title-optioned' | locTranslate }}
                    </h5>
                </div>
            </div>
    
            <div *ngIf="paymentMethod != null" class="PGBookedProduct-Section PGBookedProduct-Section-PaymentMethod">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-payment-method' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3">
                    <div *ngIf="paymentMethod == 'sul_posto'" pdfgen="line">
                        <i class="fa-regular fa-map-marker text-muted me-1"></i>{{ 'pg-booking-product.payment-method-inloco' | locTranslate }}
                    </div>
                    <div *ngIf="paymentMethod == 'bonifico'" pdfgen="line">
                        <i class="fa-regular fa-money-check-alt text-muted me-1"></i>{{ 'pg-booking-product.payment-method-bonifico' | locTranslate }}
                        <div class="mt-2">
                            <div pdfgen="line">
                                <div>{{ 'pg-booking-product.payment-method-bonifico-todo-1' | locTranslate }}</div>
                                <div>
                                    <span>IBAN</span>
                                    <b class="float-end">{{productData.bank_transfer_method != null ? productData.bank_transfer_method.iban : null}}</b>
                                </div>
                                <div>                        
                                    <span>SWIFT</span>
                                    <b class="float-end">{{productData.bank_transfer_method != null ? productData.bank_transfer_method.swift : null}}</b>
                                </div>
                            </div>
                            <div pdfgen="line">
                                <div>{{ 'pg-booking-product.payment-method-bonifico-todo-2' | locTranslate }}</div>
                                <div>
                                    <b>{{getBonificoCausale(productData)}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="paymentMethod == 'broker'" pdfgen="line">
                        <i class="fa-regular fa-receipt text-muted me-1"></i>{{ 'pg-booking-product.payment-method-broker' | locTranslate }}
                    </div>
                    <div *ngIf="paymentMethod == 'online'" pdfgen="line">
                        <i class="fa-regular fa-credit-card text-muted me-1"></i>{{ 'pg-booking-product.payment-method-online' | locTranslate }}
                    </div>
                    <div *ngIf="paymentMethod == 'gratis'" pdfgen="line">
                        <i class="fa-regular fa-gift text-muted me-1"></i>{{ 'pg-booking-product.payment-method-gratis' | locTranslate }}
                    </div>
                    <div *ngIf="paymentMethod == 'price_on_request'" pdfgen="line">
                        <i class="fa-regular fa-phone text-muted me-1"></i>{{ 'pg-booking-product.payment-method-price_on_request' | locTranslate }}
                    </div>
                </div>
            </div>
    
            <div *ngIf="additionalInformations != null && additionalInformations != ''" class="PGBookedProduct-Section">
                <h5 class="mb-2" pdfgen="line" pdfgen-weight="bold">{{ 'pg-booking-product.title-additional-infos' | locTranslate }}</h5>
                <div class="PGBookedProduct-Selected border-bottom pb-3 mb-3" pdfgen="line">
                    {{additionalInformations}}
                </div>
            </div>
        </span>

        <div class="PGBookedProduct-Footer d-flex mb-3 justify-content-between align-items-center">
            <div class="text-muted">
                <span *ngIf="pid != null && pid != ''" pdfgen="line">
                    PID: <span class="text-uppercase text-monospace">{{pid}}</span>
                </span>
            </div>
            <div *ngIf="compact != 'never'">
                <button class="btn btn-link btn-sm p-0" (click)="compact = !compact">
                    <span *ngIf="compact">{{ 'pg-booking-product.show-more' | locTranslate }}</span>
                    <span *ngIf="!compact">{{ 'pg-booking-product.show-less' | locTranslate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="d-none" pdfgen="block">
        <div pdfgen="line">
            {{ 'pg-booking-product.footer' | locTranslate }} {{getWebsiteURLClean()}}
        </div>
    </div>
</div>