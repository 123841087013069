import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AudioMixerService {   
    private audioContext:AudioContext;

    private globalGain:GainNode;

    private lowPriorityGain:GainNode;
    private mediumPriorityGain:GainNode;
    private highPriorityGain:GainNode;

    private mediumPriorityAnalyser:AnalyserNode;
    private highPriorityAnalyser:AnalyserNode;

    private priorityThreshold = 64;

    private highCanvas:HTMLCanvasElement = null;
    private mediumCanvas:HTMLCanvasElement = null;

    onAnalysis = new Subject<Array<{ id:string, name:string, role:string, value:number }>>();

    constructor(private ngZone:NgZone) {}

    init() {
        this.audioContext = new (window.AudioContext || window['webkitAudioContext'])();

        window.addEventListener('click', () => {
            if(this.audioContext.state != 'running') {
                this.audioContext.resume();
            }

            for(let _cMedia of this._toBeActivated) {
                console.log('AudioMixerService: activating media', _cMedia.currentSrc)

                _cMedia.volume = 0;
                _cMedia.play();

                setTimeout(() => {
                    _cMedia.pause();
                    _cMedia.currentTime = 0;
                    _cMedia.volume = 1;
                }, 100)
            }

            this._toBeActivated = [];
        })

        this.globalGain = this.audioContext.createGain();
        this.globalGain.connect(this.audioContext.destination);

        this.lowPriorityGain = this.audioContext.createGain();
        this.lowPriorityGain.connect(this.globalGain);

        this.highPriorityGain = this.audioContext.createGain();

        this.highPriorityAnalyser = this.audioContext.createAnalyser();
        this.highPriorityAnalyser.fftSize = Math.pow(2, 8);
        let _highBufferLength = this.highPriorityAnalyser.frequencyBinCount;
        let _highDataArray = new Uint8Array(_highBufferLength);

        this.highPriorityGain.connect(this.highPriorityAnalyser)
        this.highPriorityAnalyser.connect(this.globalGain);   
        
        this.mediumPriorityGain = this.audioContext.createGain();

        this.mediumPriorityAnalyser = this.audioContext.createAnalyser();
        this.mediumPriorityAnalyser.fftSize = Math.pow(2, 8);
        let _mediumBufferLength = this.mediumPriorityAnalyser.frequencyBinCount;
        let _mediumDataArray = new Uint8Array(_mediumBufferLength);

        this.mediumPriorityGain.connect(this.mediumPriorityAnalyser)
        this.mediumPriorityAnalyser.connect(this.globalGain);   
        
        let _voiceRange = { start: 500, end: 6000 };

        let _freqStep = this.audioContext.sampleRate / 2 / _highBufferLength;
        let _voiceWeightList:Array<number> = [];
        let _voiceWeightSum = 0;
        let _voiceWeightMax = 0;

        for(let i = 0; i < _highBufferLength; i++) {
            let _cFreqSegment = { 
                start: _freqStep * i,
                end: _freqStep * (i + 1)
            }

            if(_cFreqSegment.start < _voiceRange.end && _cFreqSegment.end > _voiceRange.start) {
                let _segmentOverlap = {
                    start: Math.max(_voiceRange.start, _cFreqSegment.start),
                    end: Math.min(_voiceRange.end, _cFreqSegment.end)
                }

                let _overlapLength = _segmentOverlap.end - _segmentOverlap.start;

                _voiceWeightList[i] = _overlapLength;
                _voiceWeightSum += _voiceWeightList[i];
                _voiceWeightMax = Math.max(_voiceWeightMax, _voiceWeightList[i])
            }
            else {
                _voiceWeightList[i] = 0;
            }
        }

        this.ngZone.runOutsideAngular(() => {
            setInterval(() => {
                this.highPriorityAnalyser.getByteFrequencyData(_highDataArray);
    
                let _cHighVoiceAmount = 0;
    
                for(let i = 0; i < _highBufferLength; i++) {
                    if(_voiceWeightList[i] > 0) {
                        _cHighVoiceAmount += _highDataArray[i] * _voiceWeightList[i]
                    }
                }
    
                _cHighVoiceAmount /= _voiceWeightSum;

                this._updateVisualization(this.highCanvas, _highDataArray, _voiceWeightList, _voiceWeightMax, _cHighVoiceAmount);
    
                if(_cHighVoiceAmount > this.priorityThreshold) {
                    this.mediumPriorityGain.gain.value = Math.max(0.25, this.mediumPriorityGain.gain.value - 0.05);
                    this.lowPriorityGain.gain.value = Math.max(0.25, this.lowPriorityGain.gain.value - 0.05);
                }
                else {
                    this.mediumPriorityGain.gain.value = Math.min(1, this.mediumPriorityGain.gain.value + 0.05);
                    
                    this.mediumPriorityAnalyser.getByteFrequencyData(_mediumDataArray);
    
                    let _cMediumVoiceAmount = 0;
        
                    for(let i = 0; i < _mediumBufferLength; i++) {
                        if(_voiceWeightList[i] > 0) {
                            _cMediumVoiceAmount += _mediumDataArray[i] * _voiceWeightList[i]
                        }
                    }
        
                    _cMediumVoiceAmount /= _voiceWeightSum;

                    this._updateVisualization(this.mediumCanvas, _mediumDataArray, _voiceWeightList, _voiceWeightMax, _cMediumVoiceAmount);

                    if(_cMediumVoiceAmount > this.priorityThreshold) {
                        this.lowPriorityGain.gain.value = Math.max(0.25, this.lowPriorityGain.gain.value - 0.05);
                    }
                    else {
                        this.lowPriorityGain.gain.value = Math.min(1, this.lowPriorityGain.gain.value + 0.05);
                    }
                }

                if(this.analysers.length > 0) { 
                    for(let _cAnalyser of this.analysers) {
                        _cAnalyser.analyser.getByteFrequencyData(_cAnalyser.data);

                        let _cAvg = 0;
                        for(let _cVal of _cAnalyser.data) {
                            _cAvg += _cVal;
                        }

                        _cAvg /= _cAnalyser.data.length;

                        _cAnalyser.count ++;
                        _cAnalyser.sum += _cAvg;
                    }
                }                
            }, 50) // fare dei check su intervalli più lunghi dà risultati "strani"
        })

        setInterval(() => {
            let _analyserOutput:Array<{ id:string, name:string, role:string, value:number }> = []

            for(let _cAnalyser of this.analysers) {
                if(_cAnalyser.count > 0) {
                    _analyserOutput.push({ id: _cAnalyser.id, name: _cAnalyser.name, role: _cAnalyser.role, value: _cAnalyser.sum / _cAnalyser.count })
                }

                _cAnalyser.count = 0;
                _cAnalyser.sum = 0;
            }

            this.onAnalysis.next(_analyserOutput)
        }, 250)

        //this.visualize();
    }

    visualize() {
        if(this.highCanvas == null) {
            this.highCanvas = document.createElement('canvas');

            this.highCanvas.width = 256;
            this.highCanvas.height = 256;
    
            this.highCanvas.style.position = 'fixed'
            this.highCanvas.style.zIndex = '10000'
            this.highCanvas.style.left = '10px';
            this.highCanvas.style.bottom = '10px';
    
            document.body.appendChild(this.highCanvas)
        }

        if(this.mediumCanvas == null) {
            this.mediumCanvas = document.createElement('canvas');

            this.mediumCanvas.width = 256;
            this.mediumCanvas.height = 256;
    
            this.mediumCanvas.style.position = 'fixed'
            this.mediumCanvas.style.zIndex = '10000'
            this.mediumCanvas.style.left = '266px';
            this.mediumCanvas.style.bottom = '10px';
    
            document.body.appendChild(this.mediumCanvas)
        }
    }

    private _updateVisualization(canvas:HTMLCanvasElement, _dataArray:Uint8Array, _voiceWeightList:Array<number>, _voiceWeightMax:number, _cVoiceAmount:number) {
        if(canvas != null) {
            let _ctx = canvas.getContext('2d');

            _ctx.fillStyle = '#111111';
            _ctx.fillRect(0, 0, canvas.width, canvas.height);

            _ctx.fillStyle = '#222222';

            let _scaleLines = 16;
            for(let i = 0; i < _scaleLines; i++) {
                _ctx.fillRect(0, i * canvas.height / _scaleLines, canvas.width, 1);
            }

            let _barWidth = canvas.width / _dataArray.length;
            
            let _didBegin = false;

            for(let i = 0; i < _voiceWeightList.length; i++) {
                if((!_didBegin && _voiceWeightList[i] > 0) || (_didBegin && _voiceWeightList[i] == 0)) {
                    _didBegin = !_didBegin;

                    _ctx.fillRect(i * _barWidth, 0, 1, canvas.height);
                }
            }

            for(let i = 0; i < _dataArray.length; i++) {

                _ctx.globalAlpha = 0.2 + 0.8 * (_voiceWeightList[i] / _voiceWeightMax);

                if(_voiceWeightList[i] > 0) {
                    _ctx.fillStyle = 'cyan';
                }
                else {
                    _ctx.fillStyle = 'white';
                }

                _ctx.fillRect(i * _barWidth, canvas.height - _dataArray[i], _barWidth, _dataArray[i]);
            }

            _ctx.globalAlpha = 1;

            _ctx.fillStyle = 'red';
            _ctx.fillRect(0, canvas.height - this.priorityThreshold, canvas.width, _ctx.lineWidth);

            if(_cVoiceAmount > this.priorityThreshold) {
                _ctx.strokeStyle = 'red';
                _ctx.fillStyle = 'red';
                _ctx.lineWidth = 2;
            }
            else {
                _ctx.strokeStyle = '#333333';
                _ctx.fillStyle = 'cyan';
                _ctx.lineWidth = 1;
            }

            _ctx.fillRect(0, canvas.height - _cVoiceAmount, canvas.width, _ctx.lineWidth);
            _ctx.strokeRect(0, 0, canvas.width, canvas.height);

            _ctx.fillStyle = 'green';
            _ctx.fillRect(0, 0, canvas.width * this.lowPriorityGain.gain.value, 4);
        }
    }

    private _toBeActivated:Array<HTMLMediaElement> = [];

    private _sourceList:Array<MediaElementAudioSourceNode|MediaStreamAudioSourceNode> = [];
    analysers:Array<{ 
        id:string, 
        name:string,
        role:string,
        source: MediaElementAudioSourceNode|MediaStreamAudioSourceNode, 
        analyser: AnalyserNode, 
        data:Uint8Array,
        count:number,
        sum:number 
    }> = [];

    addMedia(media:HTMLMediaElement) {
        media.setAttribute('crossorigin', 'anonymous');
        this._toBeActivated.push(media);

        let _cSource = this.audioContext.createMediaElementSource(media);

        this._sourceList.push(_cSource);

        _cSource.connect(this.lowPriorityGain);
    }

    addStream(stream:MediaStream, priority?:'high'|'medium'|'low', analyser?: { id:string, name:string, role:string }) {
        let _cSource = this.audioContext.createMediaStreamSource(stream);

        this._sourceList.push(_cSource);

        if(analyser != null) {
            let _cAnalyser = this.audioContext.createAnalyser();

            _cAnalyser.fftSize = Math.pow(2, 5);
            let _bufferLength = _cAnalyser.frequencyBinCount;
            let _dataArray = new Uint8Array(_bufferLength);

            this.analysers.push({ 
                id: analyser.id,
                name: analyser.name,
                role: analyser.role,
                source: _cSource, 
                analyser: _cAnalyser, 
                data: _dataArray,
                count: 0,
                sum: 0
            })

            _cSource.connect(_cAnalyser);
            
            if(priority == 'high') {
                _cAnalyser.connect(this.highPriorityGain);
            }
            else if(priority == 'low') {
                _cAnalyser.connect(this.lowPriorityGain);
            }
            else {
                _cAnalyser.connect(this.mediumPriorityGain);
            }
        }
        else {
            if(priority == 'high') {
                _cSource.connect(this.highPriorityGain);
            }
            else if(priority == 'low') {
                _cSource.connect(this.lowPriorityGain);
            }
            else {
                _cSource.connect(this.mediumPriorityGain);
            }
        }
    }

    removeStream(stream:MediaStream) {
        for(let i = 0; i < this._sourceList.length; i++) {
            let _cSource = this._sourceList[i];

            if('mediaStream' in _cSource && _cSource.mediaStream == stream) {
                this._sourceList[i].disconnect();
                this._sourceList.splice(i, 1);
                break;
            }
        }

        for(let i = 0; i < this.analysers.length; i++) {
            let _cSource = this.analysers[i].source;

            if('mediaStream' in _cSource && _cSource.mediaStream == stream) {
                this.analysers[i].analyser.disconnect();
                this.analysers.splice(i, 1);
                break;
            }
        }
    }

    removeMedia(media:HTMLMediaElement) {
        let _cIndex = this._toBeActivated.indexOf(media);
        if(_cIndex != -1) {
            this._toBeActivated.splice(_cIndex, 1)
        }

        for(let i = 0; i < this._sourceList.length; i++) {
            let _cSource = this._sourceList[i];

            if('mediaElement' in _cSource && _cSource.mediaElement == media) {
                this._sourceList[i].disconnect();
                this._sourceList.splice(i, 1);
                break;
            }
        }

        for(let i = 0; i < this.analysers.length; i++) {
            let _cSource = this.analysers[i].source;

            if('mediaElement' in _cSource && _cSource.mediaElement == media) {
                this.analysers[i].analyser.disconnect();
                this.analysers.splice(i, 1);
                break;
            }
        }
    }

    setVolume(val:number) {
        this.globalGain.gain.value = val;
    }
}