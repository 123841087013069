import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PGAvailabilitySelection, PGTicketsSelection } from '../../../models/booking.model';
import { LocalizationService } from '../../../services/localization.service';
import { TicketsData } from '../../../models/tickets.model';

@Component({
  selector: 'app-pg-tickets-select',
  templateUrl: './pg-tickets-select.component.html',
  styleUrls: ['./pg-tickets-select.component.scss']
})
export class PgTicketsSelectComponent implements OnInit, OnChanges {
    constructor(private localizationService:LocalizationService) { }

    selection:PGTicketsSelection = null;

    @Input() priceOnRequest:boolean;
    @Input() ticketsData:TicketsData;
    @Input() availabilitySelected:PGAvailabilitySelection;

    @Input() single:boolean;

    randomId = Math.random()

    ngOnInit() {
    }

    ngOnChanges() {
        if(this.priceOnRequest) {
            this.selection = new PGTicketsSelection(new TicketsData({ fullPrice: 0, otherTickets: [] }));
        }
        else {
            this.selection = new PGTicketsSelection(this.ticketsData);
        }
    }

    getSelectionTotalQuantity() {
        let _cTotal = 0;
        _cTotal += this.selection.fullQuantity;

        for(let _cOther of this.selection.otherTickets) {
            _cTotal += _cOther.quantity;
        }

        return _cTotal;
    }

    getSelectionTotalPrice() {
        let _cTotal = 0;
        _cTotal += this.selection.fullQuantity * this.selection.fullPrice;

        for(let _cOther of this.selection.otherTickets) {
            _cTotal += _cOther.quantity * _cOther.price;
        }

        return _cTotal;
    }

    isSelectionValid() {
        return this.getSelectionTotalQuantity() > 0 && (this.availabilitySelected.availability == null || this.getSelectionTotalQuantity() <= this.availabilitySelected.availability.current);
    }

    @Output() ticketsSelected = new EventEmitter<PGTicketsSelection>();

    formatTicketName(name:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(name)
    }

    formatPrice(val:number, useGratis?:boolean) {
        if(val == null) return null;
        else if(val == 0) {
            return useGratis ? 'GRATIS' : '';
        }
        else return val.toFixed(2) + ' €'
    }

    getSingleValue() {
        if(this.selection.fullQuantity > 0) return 'full'
        else {
            for(let i = 0; i < this.selection.otherTickets.length; i++) {
                if(this.selection.otherTickets[i].quantity > 0) return 'other' + i;
            }
        }
    }

    setSingleValue(id:string) {
        this.selection.fullQuantity = id == 'full' ? 1 : 0;
        for(let i = 0; i < this.selection.otherTickets.length; i++) {
            this.selection.otherTickets[i].quantity = (id == 'other' + i) ? 1 : 0;
        }
    }

    checkQuantity(val:number) {
        if(typeof val == 'number') {
            return Math.floor(val)
        }
        else return null
    }
}
