import { Component, OnChanges, AfterViewChecked, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-pg-image-autosize',
  templateUrl: './pg-image-autosize.component.html',
  styleUrls: ['./pg-image-autosize.component.scss']
})
export class PgImageAutosizeComponent implements OnChanges, AfterViewChecked {
    constructor() { }

    @Input() src:string;
    @Input() fit:'contain'|'cover';
    @ViewChild('imageContainer') imageContainer:ElementRef;

    isLoading = false;

    private _hasSrcChanges = false;

    ngOnChanges(simpleChanges:any): void {
        if(simpleChanges.src) {
            this.isLoading = true;
            this._hasSrcChanges = true;
        }
    }

    private _lastWidth:number = 0;
    private _lastResizedSrc:string = null;

    private _imageElementsByUrl:{
        [url:string]: {
            status: 'loading'|'error'|'success',
            image: HTMLImageElement
        }
    } = {}

    ngAfterViewChecked() {
        let _container = this.imageContainer.nativeElement as HTMLElement;

        if(this._hasSrcChanges || this._lastWidth != _container.offsetWidth) {
            // TODO: considerare pixel density del device
            if(this._hasSrcChanges) {
                this._hasSrcChanges = false;

            }
            
            this._lastWidth = _container.offsetWidth;

            if(this.src != null) {
                let _pixelWidth = _container.offsetWidth * (window.devicePixelRatio || 1)
    
                let _resizedSrc = this.src;
                let _sizeString:string = null;
    
                if(_pixelWidth <= 320) {
                    _sizeString = 'small'
                }
                else if(_pixelWidth <= 640) {
                    _sizeString = 'medium'
                }
                else if(_pixelWidth <= 1200) {
                    _sizeString = 'large'
                }
                else if(_pixelWidth <= 1600) {
                    _sizeString = 'xl'
                }
    
                if(_sizeString != null) {
                    _resizedSrc = _resizedSrc.replace(/\/uploads\/images/, '/uploads/images/' + _sizeString)
                }
    
                if(this._lastResizedSrc != _resizedSrc) {
                    this._lastResizedSrc = _resizedSrc;
    
                    if(this._imageElementsByUrl[this._lastResizedSrc] != null) {
                        setTimeout(() => {
                            this._refreshImageDisplay();

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 200);
                        }, 200)
                    }
                    else {
                        let _image = new Image();
                        _image.loading = 'lazy';
                        _image.style.opacity = '0'
    
                        this._imageElementsByUrl[_resizedSrc] = {
                            status: 'loading',
                            image: _image
                        }
    
                        _image.onload = () => {
                            this._imageElementsByUrl[_resizedSrc].status = 'success';

                            setTimeout(() => {
                                this._refreshImageDisplay();

                                setTimeout(() => {
                                    this.isLoading = false;
                                }, 200);
                            }, 200)
                        }
                        
                        _image.onerror = () => {
                            this._imageElementsByUrl[_resizedSrc].status = 'error';

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 200);
                        }
            
                        _image.src = _resizedSrc;

                        _container.appendChild(_image)
                    }
                }
            }
        }
    }

    private _refreshImageDisplay() {
        if(this._imageElementsByUrl[this._lastResizedSrc] != null && this._imageElementsByUrl[this._lastResizedSrc].status == 'success') {
            for(let i in this._imageElementsByUrl) {
                this._imageElementsByUrl[i].image.style.opacity = '1'
                this._imageElementsByUrl[i].image.style.display = i == this._lastResizedSrc ? 'block' : 'none'
            }
        }
    }
}
