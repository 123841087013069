<div class="PGProductCard" #productCardElement>
    <div class="card">
        <div *ngIf="isLoading" class="PGProductCard-Loading">
            <app-pg-loading></app-pg-loading>
        </div>
        <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="productCardData; let product">
                <div *ngIf="product.image != null &amp;&amp; product.image != ''" class="card-img-top bg-secondary" [style.backgroundImage]="'url(&quot;' + product.image + '&quot;)'"></div>
                <div class="card-body">
                    <div *ngIf="product.type != null" class="PGProductCard-Type mb-2 text-end">
                        <span class="badge text-white bg-{{getProductTypeClass()}}">
                            <i class="fa-regular {{getProductTypeIcon()}}"></i>
                            <span class="ms-2">{{ 'pg-product-card.type-' + product.type | locTranslate }}</span>
                        </span>
                    </div>
                    <div *ngIf="!compact" class="float-end PGProductCard-Print py-1">
                        <button class="btn btn-sm btn-primary py-0 px-1 d-block" (click)="downloadPDF()">
                            <span>PDF</span>
                        </button>
                    </div>
                    <span pdfgen="block">
                        <h4 class="card-title mb-3">
                            <span pdfgen="line" pdfgen-weight="bold" pdfgen-h="1">{{product.name}}</span>
                        </h4>
                        <h6 *ngIf="hasProperty(product.abstract)" class="card-subtitle mb-3 text-muted" [innerHTML]="product.abstract" pdfgen="line"></h6>
                        <ng-container *ngIf="hasProperty(product.description)">
                            <div #cardDescription class="card-text mb-3 {{ compact && isDescriptionCollapsed ? 'PGProductCard-Description--Short' : ''}}" [innerHTML]="product.description" pdfgen="line"></div>
                            <div *ngIf="hasDescriptionCollapse" class="PGProductCard-Description-Collapse text-end mb-3">
                                <div>
                                    <button class="btn btn-link p-0 btn-sm" (click)="isDescriptionCollapsed = !isDescriptionCollapsed">
                                        {{ 'pg-product-card.description-' + (isDescriptionCollapsed ? 'more' : 'less') | locTranslate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
        
                        <div *ngIf="hasProperty(product.location)" class="PGProductCard-Property mb-2">
                            <div pdfgen="line" pdfgen-weight="bold">
                                <i class="fa-regular fa-map-marker text-muted me-1"></i>{{ 'pg-product-card.label-place' | locTranslate }}
                            </div>
                            <div pdfgen="line">
                                <a *ngIf="product.geolocation != null" target="_blank" href="{{getProductGeolocationURL()}}">
                                    {{product.location}}
                                </a>
                                <ng-container *ngIf="product.geolocation == null">
                                    {{product.location}}
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="!compact && product.price_on_request" class="PGProductCard-Property mb-2">
                            <div pdfgen="line" pdfgen-weight="bold">
                                <i class="fa-regular fa-tag text-muted me-1"></i>{{ 'pg-product-card.label-price' | locTranslate }}
                            </div>
                            <div pdfgen="line">
                                <span>{{ 'pg-product-card.price-on-request' | locTranslate }}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="!product.price_on_request">
                            <div *ngIf="!compact && hasProperty(product.price)" class="PGProductCard-Property mb-2">
                                <div pdfgen="line" pdfgen-weight="bold">
                                    <i class="fa-regular fa-tag text-muted me-1"></i>{{ 'pg-product-card.label-price' | locTranslate }}
                                </div>
                                <div pdfgen="line">
                                    <span [ngClass]="{ 'text-success': discountVal != null && discountVal != 0 }">{{formatPrice(product.price, true)}}</span>
                                    <span *ngIf="discountVal != null && discountVal != 0" class="PGProductCard-FullPrice">{{formatPrice(product.price)}}</span>
                                </div>
                            </div>
                            <div *ngIf="!compact && hasProperty(product.tickets)" class="PGProductCard-Property mb-2">
                                <div pdfgen="line" pdfgen-weight="bold">
                                    <i class="fa-regular fa-tag text-muted me-1"></i>{{ 'pg-product-card.label-tickets' | locTranslate }}
                                </div>
                                <div pdfgen="line">
                                    <span [ngClass]="{ 'text-success': discountVal != null && discountVal != 0 }" [innerHTML]="formatTickets(product.tickets, true)"></span>
                                    <span *ngIf="discountVal != null && discountVal != 0" class="PGProductCard-FullPrice" [innerHTML]="formatTickets(product.tickets)"></span>
                                </div>
                            </div>
                            <div *ngIf="!compact && hasProperty(product.payment)" class="PGProductCard-Property border-top mb-2 pt-2">
                                <div pdfgen="line" pdfgen-weight="bold">
                                    {{ 'pg-product-card.label-payment' | locTranslate }}
                                </div>
                                <div pdfgen="line">
                                    {{formatPayment(product.payment)}}
                                </div>
                            </div>
                        </ng-container>
                    </span>

                    <div *ngIf="productCardSupplierData; let supplier" class="PGProductCard-Supplier {{isSupplierProduct() ? 'PGProductCard-Supplier--IsSupplierProduct' : ''}} border-top my-3 pt-3" pdfgen="block">
                        <div class="row">
                            <div class="{{ supplier.logo != null && supplier.logo != '' ? 'col-8' : 'col-12'}}">
                                <h5 class="mb-3" pdfgen="line" pdfgen-h="2">{{supplier.name}}</h5>
                                <div *ngIf="!compact && supplier.description != null" class="PGProductCard-Supplier-Description mb-3" [innerHTML]="supplier.description" pdfgen="line"></div>
                                <div *ngIf="supplier.phone != null" class="PGProductCard-Supplier-Contact" pdfgen="line">
                                    <span class="PGProductCard-Supplier-Contact-Label">
                                        <i class="fa-regular fa-phone text-muted me-1"></i>
                                        <span>{{ 'pg-product-card.label-phone' | locTranslate }}</span>
                                    </span>
                                    
                                    <a href="tel:{{supplier.phone}}">{{supplier.phone}}</a>
                                </div>
                                <div *ngIf="supplier.email != null" class="PGProductCard-Supplier-Contact" pdfgen="line">
                                    <span class="PGProductCard-Supplier-Contact-Label">
                                        <i class="fa-regular fa-envelope text-muted me-1"></i>
                                        <span>{{ 'pg-product-card.label-email' | locTranslate }}</span>
                                    </span>

                                    <a href="mailto:{{supplier.email}}">{{supplier.email}}</a>
                                </div>
                                <div *ngIf="supplier.website != null" class="PGProductCard-Supplier-Contact" pdfgen="line">
                                    <span class="PGProductCard-Supplier-Contact-Label">
                                        <i class="fa-regular fa-globe text-muted me-1"></i>
                                        <span>{{ 'pg-product-card.label-website' | locTranslate }}</span>
                                    </span>
                                    
                                    <a target="_blank" href="{{supplier.website}}">{{supplier.website}}</a>
                                </div>
                                <div *ngIf="supplier.store_url != null" class="PGProductCard-Supplier-Contact" pdfgen="line">
                                    <span class="PGProductCard-Supplier-Contact-Label">
                                        <i class="fa-regular fa-shopping-cart text-muted me-1"></i>
                                        <span>{{ 'pg-product-card.label-store_url' | locTranslate }}</span>
                                    </span>
                                    
                                    <a target="_blank" href="{{supplier.store_url}}">{{supplier.store_url}}</a>
                                </div>
                            </div>
                                
                            <div *ngIf="supplier.logo != null && supplier.logo != ''" class="col-4">
                                <img  class="PGProductCard-Supplier-Image" [src]="supplier.logo"/>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div #cardFooter [hidden]="!hasContent" class="card-footer text-end">
                    <ng-content></ng-content>
                </div>
            </ng-container>
        </ng-container>
      </div>
</div>
