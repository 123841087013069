<div *ngIf="!isHidden" class="PGHeader {{routeTitle == null && routeLogo == null ? 'PGHeader--NoTitleLogo' : ''}} bg-white border-bottom">
    <div class="PGHeader-Inner px-3 py-2">
        <div>
            <div class="PGHeader-Title">
                <div class="PGHeader-Title-Image"><img *ngIf="routeLogo != null" [src]="routeLogo"/></div>
                <div class="d-flex align-items-baseline">
                    <h3 *ngIf="routeName != null">{{routeName | locTranslate}}</h3>
                    <h5 *ngIf="routeSubName != null">{{routeSubName | locTranslate}}</h5>
                    <h6 *ngIf="routeTitle != null">{{routeTitle | locTranslate}}</h6>
                </div>
            </div>
            <div class="PGHeader-Back">
                <button *ngIf="routeBack != null && routeBack.url != null" class="btn btn-link btn-sm" (click)="goRouteBack()">
                    <i class="fa-regular fa-chevron-left"></i>
                    <span *ngIf="routeBack.text != null">{{routeBack.text | locTranslate}}</span>
                </button>
            </div>
        </div>
        <div>
            <ng-content></ng-content>
        </div>
        <div class="PGHeader-Buttons d-flex align-items-center">
            <div>
                <app-pg-help></app-pg-help>
            </div>
            <div *ngIf="!noTasks">
                <app-pg-tasks-view></app-pg-tasks-view>
            </div>
            <div *ngIf="noUser || !isLoggedIn()">
                <app-pg-language-selector></app-pg-language-selector>
            </div>
            <ng-container *ngIf="!noUser && isLoggedIn()">
                <div *ngIf="isChatEnabled()" class="PGHeader-Buttons-Chat" ngbDropdown [autoClose]="false" [open]="true" [container]="null">
                    <button class="btn btn-white position-relative PGHeader-Buttons-Chat-Toggle" ngbDropdownAnchor (click)="isChatVisible = !isChatVisible"> 
                        <i class="fa-regular fa-lg fa-comments opacity-100 m-0 text-{{isChatVisible ? 'primary' : 'dark'}}"></i>
                        <div class="PGHeader-Buttons-Chat-Toggle-Badges">
                            <span *ngIf="chatNotificationCount; let count" class="badge bg-danger m-0 p-1">{{count}}</span>
                            <ng-container *ngIf="!chatNotificationCount">
                                <span *ngIf="chatUnreadCount; let count" class="badge bg-secondary m-0 p-1">{{count}}</span>
                            </ng-container>
                        </div>
                    </button>
                    <div ngbDropdownMenu class="PGHeader-Buttons-Chat-Menu p-0 pg-animation-fadein {{isChatVisible ? '' : 'd-none'}}">
                        <div class="PGHeader-Buttons-Chat-Menu-Inner">
                            <div>
                                <app-pg-customer-care-settings></app-pg-customer-care-settings>
                            </div>
                            <div>
                                <app-pg-chat (unreadCount)="chatUnreadCount = $event;" (notificationCount)="chatNotificationCount = $event;" [isHidden]="!isChatVisible"></app-pg-chat>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <app-pg-user-menu [menuElements]="menuElements"></app-pg-user-menu>
                </div>
            </ng-container>
        </div>
    </div>
</div>
