import { Injectable } from '@angular/core';
import { ConfigResourceTypesConstraint } from '../models/config.resources.model';
import { PgFormField } from '../models/form.model';
import { LocalizationService } from '../services/localization.service';
import { PGUtilities } from '../pg-utilities';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {
    constructor(private localizationService:LocalizationService) {}

    validateForm(fieldList:Array<PgFormField>) {
        let _retArray = [];

        for(let _cField of fieldList) {
            if(_cField.name != 'id') {
                let _constraintsValues = {};
                
                if(_cField.constraints != null) {
                    for(let _cConstraint of _cField.constraints) {
                        let _targetField:PgFormField = null;
                        
                        for(let _cTargetField of fieldList) {
                            if(_cConstraint.field == _cTargetField.name) {
                                _targetField = _cTargetField;
                                break;
                            }
                        }

                        if(_targetField != null) {
                            _constraintsValues[_targetField.name] = _targetField.value;
                        }
                    }
                }

                if(_cField.locale && !this.localizationService.format.hasTypeInternalLanguageHandling(_cField.type)) {
                    let _cLocaleValue = null;

                    try {
                        _cLocaleValue = JSON.parse(_cField.value);
                    }
                    catch(ex) {}
        
                    if(_cLocaleValue == null) _cLocaleValue = {};

                    let _cLanguagesList = this.localizationService.defaultContentLanguages;

                    for(let _cField of fieldList) {
                        if(_cField.name == 'languages') {
                            if(_cField.value == null) {
                                _cLanguagesList = [this.localizationService.availableApplicationLanguages[0]];
                            }
                            else {
                                _cLanguagesList = JSON.parse(_cField.value)
                            }
                        }
                    }

                    let _hasAllLanguages = true;
                    let _hasNoLanguage = true;
        
                    for(let _cLanguage of _cLanguagesList) {
                        let _cIssues = this.validateFieldValue(_cLocaleValue[_cLanguage], _cField, _constraintsValues);

                        for(let i in _cIssues) {
                            _retArray.push({ 
                                field: _cField, 
                                locale: _cLanguage,
                                type: i, 
                                value: _cIssues[i]
                            });
                        }

                        if(_cLocaleValue[_cLanguage] == null || _cLocaleValue[_cLanguage] == '') {
                            _hasAllLanguages = false;
                        }
                        else {
                            _hasNoLanguage = false;
                        }
                    }

                    if(!_cField.required && !_hasAllLanguages && !_hasNoLanguage) {
                        _retArray.push({ 
                            field: _cField, 
                            type: 'partial-language'
                        });
                    }
                }
                else {
                    let _cIssues = this.validateFieldValue(_cField.value, _cField, _constraintsValues);

                    for(let i in _cIssues) {
                        _retArray.push({ 
                            field: _cField, 
                            type: i, 
                            value: _cIssues[i]
                        });
                    }
                }
            }
        }

        if(_retArray.length == 0) return null;
        else return _retArray;
    }

    getWarningsForFieldValue(fieldValue:any, fieldData:PgFormField) {
        let warningIssues:any = {};

        if(fieldData.warnLength != null) {
            let _checkValue = fieldValue;

            if(_checkValue != null) {
                if(fieldData.type == 'html') {
                    _checkValue = _checkValue.replace(/<[^>]*>/g, '');
                }
    
                if(_checkValue.length > fieldData.warnLength) {
                    warningIssues.warnLength = false;
                }
            }
        }
        
        if(fieldData.warnIf != null) {
            let _cWarning = this.evaluateCondition(fieldData.warnIf, fieldValue, fieldData)

            if(_cWarning != null) {
                warningIssues.warnIf = _cWarning;
            }
        }

        if(Object.keys(warningIssues).length == 0) return null;
        else return warningIssues;
    }

    private _conditionCache:{ [condition:string]: Function } = {};

    evaluateCondition(condition:string, value?:any, self?:any) {
        // TODO: codice duplicato
        if(condition == null || condition == '') return true;
        else {
            let _conditionFunction:Function = this._conditionCache[condition];

            if(_conditionFunction == null) {
                _conditionFunction = eval('(function($value, $self) { return ' + condition + '; })');

                this._conditionCache[condition] = _conditionFunction;
            }

            try {
                return _conditionFunction.call(self, value, self);
            }
            catch(ex) {
                console.log('evaluateCondition failed', condition, value, self)
                return null;
            }
        }
    }
    
    validateFieldValue(fieldValue:any, fieldData:PgFormField, constraintsValues:{ [id:string]: string }) {

        let validationIssues:any = {};

        if(!this.validateFieldValueFormat(fieldData.type, fieldValue))
        {
            validationIssues.format = false;
        }
        else {
            for(let _cProperty of ['required','min','max','maxLength']) {
                if(fieldData[_cProperty] != null) {
                    let _checkValue = fieldValue;
                    if(_cProperty == 'maxLength' && fieldData.type == 'html' && _checkValue != null) {
                        _checkValue = _checkValue.replace(/<[^>]*>/g, '');
                    }

                    if(!this.validateFieldValueProperty(_cProperty, fieldData[_cProperty], _checkValue)) {
                        validationIssues[_cProperty] = fieldData[_cProperty];
                    }
                }
            }

            if(fieldData.constraints != null && constraintsValues != null) {
                let _cConstraintsIssues = this.validateFieldValueConstraints(fieldValue, fieldData.constraints, constraintsValues);

                if(_cConstraintsIssues.length > 0) {
                    validationIssues['constraints'] = _cConstraintsIssues;
                }
            }
        }

        if(Object.keys(validationIssues).length == 0) return null;
        else return validationIssues;
    }

    validateFieldValueFormat(fieldFormat:String, fieldValue:any) {
        if(fieldValue == null || fieldValue == '') return true;
        else {
            switch(fieldFormat) {
                case 'recipient': {
                    let _cVia = fieldValue.split('/')[0]
                    if(_cVia == 'email') return PGUtilities.emailRegExp.test(fieldValue.replace(/^[^\/]*\//, ''));
                    else if(_cVia == 'phone') return PGUtilities.phoneRegExp.test(fieldValue.replace(/^[^\/]*\//, ''));
                    else return false;
                }
                case 'email': return PGUtilities.emailRegExp.test(fieldValue);
                case 'phone': return PGUtilities.phoneRegExp.test(fieldValue);
                case 'integer': return /^-?\d+$/.test(fieldValue);
                case 'decimal':
                case 'float': return /^-?\d+.?\d*$/.test(fieldValue);
                case 'tickets': 
                case 'tickets-host': 
                    try {
                        let _cObj = JSON.parse(fieldValue);
                        if(typeof _cObj.fullPrice == 'number') return true;
                    } catch(ex) {} 
                    return false;
                default: return true;
            }
        }
    }
    
    validateFieldValueProperty(fieldProperty:string, propertyValue:any, fieldValue:any) {
        if(propertyValue == null) return true;
        else {
            switch(fieldProperty) {
                case 'required': {
                    if(propertyValue && (fieldValue == null || fieldValue === '')) return false;
                    else return true;
                }
                case 'maxLength': {
                    if(fieldValue != null && fieldValue.length > propertyValue) return false;
                    else return true;
                }
                case 'min': {
                    if(propertyValue != null && fieldValue != null && fieldValue < propertyValue) return false;
                    else return true;
                }
                case 'max': {
                    if(propertyValue != null && fieldValue != null && fieldValue > propertyValue) return false;
                    else return true;
                }
                default: return true;
            }
        }
    }

    validateFieldValueConstraints(fieldValue:any, fieldConstraints:Array<ConfigResourceTypesConstraint>, targetValues:{ [id:string]: any }):Array<any> {
        let _cIssues = [];

        for(let i = 0; i < fieldConstraints.length; i++) {
            let _cConstraint = fieldConstraints[i];
            let _cTargetValue = targetValues[_cConstraint.field];

            switch(_cConstraint.operator) {
                case '==': {
                    if(fieldValue != _cTargetValue) _cIssues.push(_cConstraint);
                } break;
                case '!=': {
                    if(fieldValue == _cTargetValue) _cIssues.push(_cConstraint);
                } break;
                case '<': {
                    if(fieldValue >= _cTargetValue) _cIssues.push(_cConstraint);
                } break;
                case '<=': {
                    if(fieldValue > _cTargetValue) _cIssues.push(_cConstraint);
                } break;
                case '>': {
                    if(fieldValue <= _cTargetValue) _cIssues.push(_cConstraint);
                } break;
                case '>=': {
                    if(fieldValue < _cTargetValue) _cIssues.push(_cConstraint);
                } break;
            }
        }

        return _cIssues;
    }
}