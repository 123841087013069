<div class="PGCalendar {{isLoading ? 'PGCalendar--IsLoading' : ''}}">
    <div class="PGCalendar-Header">
        <div class="PGCalendar-Header-Controls bg-light">
            <div class="PGCalendar-Header-Controls-Left">
                <button class="btn btn-primary {{smallMode ? 'btn-sm' : ''}}" [tabIndex]="smallMode ? -1 : 0" (click)="year = year - 1"><i class="fa-regular fa-angle-double-left"></i></button>
                <button class="btn btn-primary {{smallMode ? 'btn-sm' : ''}}" [tabIndex]="smallMode ? -1 : 0" (click)="month = month - 1"><i class="fa-regular fa-angle-left"></i></button>
            </div>
            <div class="PGCalendar-Header-Controls-Center">
                <select *ngIf="!smallMode" [(ngModel)]="month" class="form-control {{smallMode ? 'form-control-sm' : ''}}">
                    <option *ngFor="let monthIndex of [0,1,2,3,4,5,6,7,8,9,10,11]" value="{{monthIndex}}">{{localizationService.data.monthNames[monthIndex]}}</option>
                </select>
                <select *ngIf="!smallMode" [(ngModel)]="year" class="form-control {{smallMode ? 'form-control-sm' : ''}}">
                    <option *ngFor="let yearOffset of [-5,-4,-3,-2,-1,0,1,2,3,4,5]" value="{{year + yearOffset}}">{{year + yearOffset}}</option>
                </select>
                <span *ngIf="smallMode">{{localizationService.data.monthNames[month]}} {{year}}</span>
            </div>
            <div class="PGCalendar-Header-Controls-Right">
                <button class="btn btn-primary {{smallMode ? 'btn-sm' : ''}}" [tabIndex]="smallMode ? -1 : 0" (click)="month = month + 1"><i class="fa-regular fa-angle-right"></i></button>
                <button class="btn btn-primary {{smallMode ? 'btn-sm' : ''}}" [tabIndex]="smallMode ? -1 : 0" (click)="year = year + 1"><i class="fa-regular fa-angle-double-right"></i></button>
            </div>
        </div>
        <div class="PGCalendar-Header-Days bg-light">
            <div *ngFor="let day of [0,1,2,3,4,5,6]">
                {{localizationService.data.dayNames[(day + localizationService.data.weekStart) % 7].substring(0, 3)}}
            </div>
        </div>
    </div>
    <div *ngIf="weeks != null" class="PGCalendar-Content">
        <div *ngIf="isLoading &amp;&amp; !smallMode" class="PGCalendar-Loading">
            <app-pg-loading></app-pg-loading>
        </div>
        <div *ngFor="let week of weeks" class="PGCalendar-Content-Week">
            <div *ngFor="let day of week" class="PGCalendar-Content-Week-Day {{getFlagClasses(day)}} border" (click)="setSelectedDay(day)">
                <div *ngIf="day != null">
                    <span class="PGCalendar-Content-Week-Day-Title">
                        {{day.date.day}}
                    </span>
                    <div class="PGCalendar-Content-Week-Day-Events text-muted">
                        <span *ngFor="let element of day.elements; let i = index;">
                            <div *ngIf="i < maxEventsDisplay" class="PGCalendar-Content-Week-Day-Events-Element">
                                <span>{{localizationService.format.time(element.start)}}<ng-container *ngIf="element.end != null"> - {{localizationService.format.time(element.end)}}</ng-container></span>
                                <span *ngIf="element.status != null" class="badge bg-{{getEventStatusColor(element) || 'secondary'}} text-white PGCalendar-Content-Week-Day-Events-Element-Status" title="{{getEventStatusTitle(element)}}">
                                    <i class="fa-regular {{getEventStatusIcon(element) || 'fa-clock' }}"></i>
                                </span>
                                <span *ngIf="element.value != null" class="badge text-white PGCalendar-Content-Week-Day-Events-Element-Value" [ngClass]="{ 'bg-secondary': element.value == 0, 'bg-success': element.value &gt; 0, 'bg-danger': element.value &lt; 0 }">
                                    {{element.value}}
                                </span>
                            </div>
                        </span>
                        <div *ngIf="day.elements.length > maxEventsDisplay" class="PGCalendar-Content-Week-Day-Events-More">+{{day.elements.length - maxEventsDisplay}}</div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
    <div *ngIf="selectedDay != null &amp;&amp; selectedDay.elements != null &amp;&amp; selectedDay.elements.length > 0" class="PGCalendar-Detail border" appPgAnimations="Grow;origin:{{getDetailAnimationOrigin()}}">
        <div class="PGCalendar-Detail-Header bg-light">
            <span>{{localizationService.data.dayNames[selectedDay.date.weekday]}} {{selectedDay.date.day}} {{localizationService.data.monthNames[selectedDay.date.month]}} {{selectedDay.date.year}}</span>
            <button class="btn btn-primary" (click)="selectedDay = null"><i class="fa-regular fa-times"></i></button>
        </div>
        <div class="PGCalendar-Detail-Content border-top border-bottom">
            <div *ngFor="let element of selectedDay.elements" class="PGCalendar-Detail-Content-Element border-bottom" appPgAnimations="FadeIn">
                <div class="PGCalendar-Detail-Content-Element-Header text-muted">
                    <span *ngIf="element.status != null" class="badge bg-{{getEventStatusColor(element)}} text-white">{{getEventStatusTitle(element)}}</span>
                    <div class="PGCalendar-Detail-Content-Element-Header-Timespan">
                        <i class="fa-regular fa-clock"></i>
                            <span *ngIf="element.end != null">
                                Dalle <b>{{localizationService.format.time(element.start)}}</b> alle <b>{{localizationService.format.time(element.end)}}</b>
                            </span>
                            <span *ngIf="element.end == null">
                                Alle <b>{{localizationService.format.time(element.start)}}</b>
                            </span>
                    </div>
                </div>
                <div class="PGCalendar-Detail-Content-Element-Title">{{element.title}}</div>
                <div class="PGCalendar-Detail-Content-Element-Content text-muted">{{element.description}}</div>
                <div *ngIf="getAvailableActions(); let actions" class="PGCalendar-Detail-Content-Element-Actions">
                    <button *ngIf="actions.book" class="btn btn-primary" (click)="onElementAction('book', element)">
                        Prenota
                    </button>
                    <button *ngIf="actions.edit" class="btn btn-primary" (click)="onElementAction('edit', element)">
                        <i *ngIf="actions.edit" class="fa-regular fa-edit"></i>
                    </button>
                    <button *ngIf="!actions.edit &amp;&amp; actions.view" class="btn btn-primary" (click)="onElementAction('view', element)">
                        <i class="fa-regular fa-chevron-right"></i>
                    </button>
                    <button *ngIf="actions.delete" class="btn btn-danger" (click)="onElementAction('delete', element)">
                        <i class="fa-regular fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="PGCalendar-Detail-Footer bg-light">
            <span class="PGCalendar-Detail-Footer-Title">Date a {{localizationService.data.monthNames[selectedDay.date.month]}} {{selectedDay.date.year}}:</span>
            <ul class="pagination">
                <li *ngIf="daysWithElementsAround[0] != daysWithElements[0]" class="page-item disabled">
                    <a class="page-link">...</a>
                </li>
                <li *ngFor="let day of daysWithElementsAround" class="page-item {{selectedDay == day ? 'active' : ''}}" (click)="setSelectedDay(day)">
                    <a class="page-link" href="javascript:void(0)">{{day.date.day}}</a>
                </li>
                <li *ngIf="daysWithElementsAround[daysWithElementsAround.length - 1] != daysWithElements[daysWithElements.length - 1]" class="page-item disabled">
                    <a class="page-link">...</a>
                </li>
            </ul>
        </div>
    </div>
</div>
