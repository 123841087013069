<div class="PGAcceptConditions">
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-accept-conditions.title' | locTranslate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body py-0">
        <ng-container *ngFor="let condition of conditionList; let i = index">
            <h5 [innerHTML]="condition.title | locTranslate" class="my-3"></h5>
            <div class="PGAcceptConditions-Item-Text border rounded p-2" [innerHTML]="condition.text | locTranslate"></div>
            <div class="my-3">
                <label class="d-flex justify-content-end">
                    <span class="pe-1">{{ 'pg-accept-conditions.accept-item' | locTranslate }}</span>
                    <b>{{condition.title | locTranslate}}</b>
                    <div class="ms-2">
                        <app-pg-switch [small]="true" [(ngModel)]="condition.accepted"></app-pg-switch>
                    </div>
                </label>
            </div>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.dismiss();">{{ 'pg-accept-conditions.button-cancel' | locTranslate }}</button>
        <button class="btn btn-primary" [disabled]="!allRequiredAccepted()" (click)="modal.close();">{{ 'pg-accept-conditions.button-confirm' | locTranslate }}</button>
    </div>
</div>