<div *ngIf="getView(); let view" class="PGFilePreview PGFilePreview--{{view.toUpperCase()}} bg-light">
    <div class="PGFilePreview-Text pe-2 {{view == 'files' ? '' : 'ps-2'}}">
        <div class="PGFilePreview-Text-Name">
            <a target="_blank" [href]="file.url | safe:'url'" title="{{file.nome}}">{{file.nome}}</a>&nbsp;
        </div>
        <div *ngIf="info && info.path" class="PGFilePreview-Text-Path">
            <small class="text-muted" title="{{info.path}}">{{info.path}}</small>
        </div>
    </div>
    <div class="PGFilePreview-Display">
        <div *ngIf="view == 'images'" [appPgCenterImage]="file.url"></div>
        <audio *ngIf="view == 'audios'" controls controlsList="nodownload">
            <source [src]="file.url | safe:'url'" type="audio/mpeg">
        </audio>
        <video *ngIf="view == 'videos'" controls controlsList="nodownload">
            <source [src]="file.url | safe:'url'" type="video/mp4">
        </video>
        <i *ngIf="view == 'files'" class="{{file.icon}}"></i>
    </div>
</div>