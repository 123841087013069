import { RelationData, ModelsData } from '../services/data.service';

export class ConfigRelation {
    public type:'1:1' | 'N:1' | '1:N' | 'N:N' | 'TranslationOf' | 'TranslatedBy';

    public modelA:string = null;
    public modelB:string = null;
    public joinField:string = null;
    public joinTable:string = null;

    constructor(fromData?:RelationData) {
        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }

    inverse() {
        let _inverseRelation = new ConfigRelation();

        if(this.type == '1:N') {
            _inverseRelation.type = 'N:1';
        }
        else if(this.type == 'N:1') {
            _inverseRelation.type = '1:N';
        }
        else if(this.type == 'TranslationOf') {
            _inverseRelation.type = 'TranslatedBy';
        }
        else {
            _inverseRelation.type = this.type;
        }

        _inverseRelation.modelA = this.modelB;
        _inverseRelation.modelB = this.modelA;
        _inverseRelation.joinField = this.joinField;

        return _inverseRelation;
    }
}

export class ConfigRelationsIndex {
    public list: Array<ConfigRelation> = [];
    public byModel: { [model:string]: Array<ConfigRelation> } = {}

    constructor(fromData?:Array<RelationData>, modelsData?:ModelsData) {
        if(fromData != null) {
            for(let _cRelationData of fromData) {
        
                this._addRelation(new ConfigRelation(_cRelationData));
            }
        }

        if(modelsData != null) {
            // relazioni automatiche sui campi con .*_id
            // ATTUALMENTE RIMOSSE, reinserite solo per user_id, group_id

            for(let i in modelsData) {
                for(let _cField of modelsData[i]) {
                    if(_cField.name == 'user_id' || _cField.name == 'group_id') {
                        let _modelB = _cField.name.replace(/_id$/, '');

                        _modelB = _modelB.charAt(0).toUpperCase() + _modelB.substr(1);
                        _modelB = _modelB.replace(/_./g, (substring) => {
                            return substring.toUpperCase();
                        });

                        let _isIn = false;

                        for(let _cRelation of this.list) {
                            if(_cRelation.type == 'N:1' && _cRelation.modelA == i && _cRelation.modelB == _modelB && _cRelation.joinField == _cField.name) {
                                _isIn = true;
                                break;
                            }
                        }

                        if(!_isIn) {
                            this._addRelation(new ConfigRelation({ type: 'N:1', modelA: i, modelB: _modelB, joinField: _cField.name }))
                        }
                    }
                }
            }

            /*
            for(let i in modelsData) {
                for(let _cField of modelsData[i]) {
                    if(/_id$/.test(_cField.name)) {
                        let _modelB = _cField.name.replace(/_id$/, '');

                        _modelB = _modelB.charAt(0).toUpperCase() + _modelB.substr(1);
                        _modelB = _modelB.replace(/_./g, (substring) => {
                            return substring.toUpperCase();
                        });

                        let _isIn = false;

                        for(let _cRelation of this.list) {
                            if(_cRelation.type == 'N:1' && _cRelation.modelA == i && _cRelation.modelB == _modelB && _cRelation.joinField == _cField.name) {
                                _isIn = true;
                                break;
                            }
                        }

                        if(!_isIn) {
                            this._addRelation(new ConfigRelation({ type: 'N:1', modelA: i, modelB: _modelB, joinField: _cField.name }))
                        }
                    }
                }
            }
            */
        }
    }

    private _addRelation(relation:ConfigRelation) {

        this.list.push(relation);

        if(this.byModel[relation.modelA] == null) {
            this.byModel[relation.modelA] = [];
        }

        this.byModel[relation.modelA].push(relation);

        if(this.byModel[relation.modelB] == null) {
            this.byModel[relation.modelB] = [];
        }

        this.byModel[relation.modelB].push(relation.inverse());
    }
}