<div *ngIf="isUnderMaintenance" class="PGMaintenanceHandler bg-light">
    <h1 class="mb-3">{{ getTranslation('under-maintenance') }}</h1>
    <div class="PGMaintenanceHandler-Icon text-muted mb-3">
        <i class="fa-regular fa-exclamation-triangle"></i>
    </div>
    <h3 class="mb-0">{{ getTranslation('sorry-inconvenience') }}</h3>
    <div *ngIf="maintenanceEndDateTime != null" class="mt-3">
        <div>{{ getTranslation('maintenance-end')}}</div>
        <div>{{ formatDateTime(maintenanceEndDateTime) }}</div>
    </div>
</div>