<div class="PGResourceViewTableConfig">
    <div class="container-fluid">
        <div class="row">
            <label class="col-2">
                getRowStatus
            </label>
            <div class="col">
                <textarea class="form-control" [(ngModel)]="view.config.getRowStatus" title="function($row) {{'{\n' + (view.config.getRowStatus || '')  + '\n}'}}"></textarea>
            </div>
        </div>
        <div class="row PGAdmin-Header PGAdmin-Permissions">
            <div class="col-2">COLUMN</div>
            <div class="col-1">TYPE</div>
            <div class="col"></div>
            <div class="col-1"></div>
        </div>
        <div class="row">
            <div droppable dropScope="table_{{dragDropScope}}" (onDrop)="moveConfig($event.dragData, 0);"></div>
        </div>
        <ng-container *ngFor="let column of view.config.columns; let i = index;">
            <div *ngIf="isFieldColumn(column)" class="row PGAdmin-Permissions">
                <label class="col-2" draggable dragScope="table_{{dragDropScope}}" [dragData]="i">
                    {{column.field}}
                </label>
                <div class="col-1"><b>field</b></div>
                <div class="col">
                    <div *ngIf="canFieldSum(column.field)" class="PGResourceViewTableConfig-DisplayOption"><input type="checkbox" [(ngModel)]="column.sum"/> sum</div>
                </div>
                <div class="col-1"><i class="fa-regular fa-trash text-danger" (click)="removeColumn(column)"></i></div>
            </div>
            <div *ngIf="isRoleviewColumn(column)" class="row PGAdmin-Permissions">
                <label class="col-2" draggable dragScope="table_{{dragDropScope}}" [dragData]="i">
                    <input class="form-control" [(ngModel)]="column.name"/>
                </label>
                <div class="col-1"><b>roleview</b></div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="column.roleview"/>
                </div>
                <div class="col-1"><i class="fa-regular fa-trash text-danger" (click)="removeColumn(column)"></i></div>
            </div>
            <div *ngIf="isClusterColumn(column)" class="row PGAdmin-Permissions">
                <label class="col-2" draggable dragScope="table_{{dragDropScope}}" [dragData]="i">
                    <input class="form-control" [(ngModel)]="column.name"/>
                </label>
                <div class="col-1"><b>cluster</b></div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="column.cluster" (ngModelChange)="disableInsertedOptions()"/>
                </div>
                <div class="col-1"><i class="fa-regular fa-trash text-danger" (click)="removeColumn(column)"></i></div>
            </div>
            <div class="row">
                <div droppable dropScope="table_{{dragDropScope}}" (onDrop)="moveConfig($event.dragData, i + 1);"></div>
            </div>
        </ng-container>
        <div class="row PGResourceViewTableConfig-AddNew">
            <div class="col-2">
                <button class="btn btn-sm btn-primary" (click)="addNewColumn()">
                    <i class="fa-regular fa-plus"></i>
                </button>
            </div>
            <div class="col">
                <app-pg-select [options]="viewTypeOptions" [(ngModel)]="newColumnType" [display]="{ oneLine: true }" [required]="true"></app-pg-select>
            </div>
        </div>
    </div>
</div>
