import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qr-links-modal',
  templateUrl: './qr-links-modal.component.html',
  styleUrls: ['./qr-links-modal.component.scss']
})
export class QrLinksModalComponent implements OnInit {
    
    @Input() targetURL:string;

    constructor(private activeModal:NgbActiveModal) { }

    ngOnInit(): void {
    }

    closeModal() {
        this.activeModal.close();
    }
}
