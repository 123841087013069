import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketsData, TicketsDataOtherTickets } from '../../models/tickets.model';

@Component({
  selector: 'app-pg-tickets-editor',
  templateUrl: './pg-tickets-editor.component.html',
  styleUrls: ['./pg-tickets-editor.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => PgTicketsEditorComponent),
      multi: true
  }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PgTicketsEditorComponent),
      multi: true,
  }]
})
export class PgTicketsEditorComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() availableLanguages:Array<string>;
    @Input() selectedLanguage:string;

    @Input() ticketsType:string;

    value:TicketsData = null;
    
    constructor(private modalService:NgbModal) { }

    translPrefix = '';

    ngOnInit(): void {
        if(this.ticketsType != null && this.ticketsType != '') {
            this.translPrefix = this.ticketsType + '-';
        }
    }

    onValueChange() {        
        if(this._onTouched) this._onTouched();
        if(this._onChange) {
            let _cData = JSON.parse(this.value.toData());

            for(let i = _cData.otherTickets.length - 1; i >= 0; i--) {
                if((_cData.otherTickets[i].name == null || _cData.otherTickets[i].name == '') && _cData.otherTickets[i].price == null) {
                    _cData.otherTickets.splice(i, 1)
                }
            }

            this._onChange(JSON.stringify(_cData));
        }
    }

    getTicketName(item:TicketsDataOtherTickets, language?:string) {
        if(item.name == null || typeof item.name == 'string' || this.availableLanguages == null) {
            return item.name;
        }
        else {
            if(language == null) language = this.selectedLanguage;
            return item.name[language];
        }
    }

    setTicketName(item:TicketsDataOtherTickets, value:string, language?:string) {
        if(this.availableLanguages == null) {
            item.name = value;
        }
        else {
            if(language == null) language = this.selectedLanguage;

            if(item.name == null || typeof item.name == 'string') {
                item.name = {};
            }
            
            item.name[language] = value;
        }
    }

    addTicket() {
        this.value.otherTickets.push(new TicketsDataOtherTickets())
        this.onValueChange();
    }

    removeTicket(item:TicketsDataOtherTickets) {
        let _cIndex =  this.value.otherTickets.indexOf(item);

        if(_cIndex != -1) {
            this.value.otherTickets.splice(_cIndex, 1)
            this.onValueChange();
        }

        if(this.value.otherTickets.length == 0) {
            this.value.otherTickets.push({
                name: null,
                price: null
            })
        }
    }

    /*
    editTicket = new TicketsDataOtherTickets();

    editSelectedLanguage:string = null;

    addTicket() {
        this.value.otherTickets.push(this.editTicket)
        this.editTicket = new TicketsDataOtherTickets();
        this.onValueChange();
    }

    @ViewChild('modalAddTicket', { static: true }) modalAddTicket;

    private _modalAddTicketRef = null;

    openAddTicketModal() { 
        this.editSelectedLanguage = this.selectedLanguage;
        this._modalAddTicketRef = this.modalService.open(this.modalAddTicket, { size: 'lg' });
    }

    dismissAddTicketModal() {
        this._modalAddTicketRef.dismiss();
    }
    */

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        this.value = new TicketsData(obj);

        if(this.value.otherTickets.length == 0) {
            this.value.otherTickets.push({
                name: null,
                price: null
            })
        }
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
    };
}
