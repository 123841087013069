import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgFormFieldComponent } from './pg-form-field/pg-form-field.component';
import { PgFormComponent } from './pg-form/pg-form.component';
import { PgDetailComponent } from './pg-detail/pg-detail.component';
import { PgFilterEditor } from './pg-filter-editor/pg-filter-editor.component';
import { PgTotemWidgetEditorComponent } from './pg-totem-widget-editor/pg-totem-widget-editor.component';
import { PgDetailFieldComponent } from './pg-detail-field/pg-detail-field.component';
import { PgResourceViewModule } from '../pg-resource-view/pg-resource-view.module';
import { PgUiMoleculesModule } from '../pg-ui-molecules/pg-ui-molecules.module';

@NgModule({
    declarations: [
        PgFormFieldComponent,
        PgFormComponent,
        PgDetailFieldComponent,
        PgDetailComponent,
        PgFilterEditor,
        PgTotemWidgetEditorComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiControlsModule,
        PgUiElementsModule,
        PgUiMoleculesModule,
        PgResourceViewModule
    ],
    exports: [
        PgFormFieldComponent,
        PgFormComponent,
        PgDetailFieldComponent,
        PgDetailComponent,
        PgFilterEditor,
        PgTotemWidgetEditorComponent
    ],
    providers:[]
})
export class PgFormModule { }
