import { Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild } from '@angular/core';

/// <reference types="@types/google.maps" />

@Component({
  selector: 'app-forms-place-picker',
  templateUrl: './forms-place-picker.component.html',
  styleUrls: ['./forms-place-picker.component.scss']
})
export class FormsPlacePickerComponent implements OnInit {
    
    @ViewChild('gmaps', { static: true }) gmapsElement: ElementRef;
    @ViewChild('searchbox', { static: true }) searchBoxElement: ElementRef;

    @Output() valueChange = new EventEmitter<google.maps.places.PlaceResult>();

    search:google.maps.places.SearchBox = null;

    value:string = null;
    valueDetails:google.maps.places.PlaceResult = null;

    constructor(private ngZone:NgZone) { }

    map:google.maps.Map = null;
    placesService:google.maps.places.PlacesService = null;

    searchMarkers:Array<google.maps.Marker> = [];

    isLoading = false;
    mapReady = false;

    ngOnInit() {
        this.gmapsElement.nativeElement.addEventListener('click', (eventObj) => {
            // per qualche motivo, clickando la mappa il click si propaga fino alla label
            // la label di default triggera un qualche evento sull'elemento map, credo un focus
            // questo mandava la mappa fullscreen ad ogni click

            eventObj.preventDefault();
        })

        this.map = new google.maps.Map(this.gmapsElement.nativeElement, {
            center: new google.maps.LatLng(0, 0),
            zoom: 1,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: true,
            fullscreenControl: true          
        });

        this.map.addListener('idle', () => {
            this.mapReady = true;
        })

        this.placesService = new google.maps.places.PlacesService(this.map);

        this.map.addListener('click', (eventObj:google.maps.IconMouseEvent) => {
            if(eventObj.placeId != null) {
                this.setValue(eventObj.placeId);
            }
        });

        this.search = new google.maps.places.SearchBox(this.searchBoxElement.nativeElement);

        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.searchBoxElement.nativeElement);

        this.map.addListener('bounds_changed', () => {
            this.search.setBounds(this.map.getBounds());
        });
  
        this.search.addListener('places_changed', () => {
            while (this.searchMarkers.length > 0) {
                this.searchMarkers[0].setMap(null);
                this.searchMarkers.splice(0, 1);
            }

            let places = this.search.getPlaces();

            if (places.length > 0) {

                let bounds = new google.maps.LatLngBounds();

                places.forEach((place) => {
                    if (place.geometry != null) {
                        let marker = new google.maps.Marker({
                            map: this.map,
                            icon: {
                                url: 'assets/images/map-search-icon.png',
                                size: new google.maps.Size(9, 9),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(4, 4),
                                scaledSize: new google.maps.Size(9, 9)
                            },
                            title: place.name,
                            position: place.geometry.location,
                            zIndex: 5
                        })

                        marker.addListener('click', (event) => {
                            if (place.geometry.viewport) {
                                this.map.fitBounds(place.geometry.viewport);
                            } else {
                                let bounds = new google.maps.LatLngBounds();
                                bounds.extend(place.geometry.location);
                                this.map.fitBounds(bounds);
                            }

                            this.setValue(place.place_id)
                        })

                        this.searchMarkers.push(marker);
            
                        if (place.geometry.viewport) {
                            bounds.union(place.geometry.viewport);
                        } else {
                            bounds.extend(place.geometry.location);
                        }
                    }
                });
            
                this.map.fitBounds(bounds);

                if(places.length == 1) {
                    this.setValue(places[0].place_id)
                }
            }
        });
    }

    setValue(value:string) {
        if(value != this.value) {
            this.value = value;

            this.ngZone.run(() => {
                this.isLoading = true;

                this.placesService.getDetails({
                    placeId: this.value
                }, (data) => {

                    this.ngZone.run(() => {
                        this.isLoading = false;
                        this.valueDetails = data;
                        this.valueChange.emit(this.valueDetails);
                    })
                })
            })
        }
    }
}

