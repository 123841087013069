
<span class="PGSuggest {{!readonly ? 'PGSuggest--NotReadOnly' : ''}}">
    <span ngbDropdown [autoClose]="false" class="PGSuggest-Select">
        <div ngbDropdownAnchor class="input-group">
            <input class="form-control PGSuggest-Value"
                [readonly]="readonly" [ngModel]="value" (ngModelChange)="setValue($event)"
                (focus)="onInputFocus()" (focusout)="onInputBlur()" (keypress)="onKeyPress($event)"/>
        </div>
        <app-pg-loading *ngIf="isLoading" pgAnimations="FadeIn"></app-pg-loading>
        <div ngbDropdownMenu class="dropdown-menu PGSuggest-Select-Dropdown {{isDropdownClosing ? 'pg-animation-fadeout' : 'pg-animation-fadein'}}">
            <span class="PGSuggest-Select-Dropdown-Options">
                <app-pg-loading *ngIf="isDropdownLoading" pgAnimations="FadeIn" [isOverlay]="true"></app-pg-loading>
                <!-- NB: sulle options ho usato il mousedown come evento perché il click dava problemi "strani" -->
                <ng-container *ngIf="getVisibleOptions(); let visibleOptions">
                    <div *ngIf="visibleOptions.length == 0" class="dropdown-item disabled">
                        {{ searchMin != null && (search == null || search.length < searchMin) ? 'Inserisci almeno ' + searchMin + ' caratteri per la ricerca' : 'Nessun suggerimento' }}
                    </div>
                    <ng-container *ngFor="let option of visibleOptions; let i = index;" >
                        <div *ngIf="showCategoryBefore(i); let category" class="PGSuggest-Select-Dropdown-Options-Category border-bottom">{{category}}</div>
                        <div class="dropdown-item {{isOptionDisabled(option) ? 'disabled' : ''}} {{isOptionSelected(option) ? 'active' : ''}}" 
                            (mousedown)="selectOption(option)">
                            <span [innerHTML]="option.view != null ? option.view : option.value"></span>
                        </div>
                    </ng-container>
                </ng-container>
                <div *ngIf="isIncomplete" class="dropdown-item disabled">...</div>
            </span>
        </div>
    </span>
</span>
