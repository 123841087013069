import { Component, Input, OnInit } from '@angular/core';
import { EFProduct } from '../../../models/experience.model';

@Component({
  selector: 'app-ef-screensaver',
  templateUrl: './ef-screensaver.component.html',
  styleUrls: ['./ef-screensaver.component.scss']
})
export class EfScreensaverComponent implements OnInit {

    constructor() { }

    @Input() productsData:{ [type:string]: Array<EFProduct> };

    ngOnInit(): void {
    }

}
