import { Component, Input, OnInit } from '@angular/core';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

export class PgChartBarsSeries {
    field: string
    name: string
    color?: string|number
}

export interface PgChartBarsData {
    category?: string
    date?: number;
}

@Component({
  selector: 'app-pg-chart-bars',
  templateUrl: './pg-chart-bars.component.html',
  styleUrls: ['./pg-chart-bars.component.scss']
})
export class PgChartBarsComponent implements OnInit {

    @Input() series:Array<PgChartBarsSeries>;
    @Input() data:Array<PgChartBarsData>;

    constructor() { }

    ngOnInit(): void {
        
    }

    createChart = (root:am5.Root) => {
        if(this.data != null && this.series != null) {
            let chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panY: false,
                    layout: root.verticalLayout
                })
            );
    
            // Create Y-axis
            let yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                })
            );

            let _isDate = true;
            for(let _item of this.data) {
                if(_item.date == null) {
                    _isDate = false;
                    break;
                }
            }

            let xAxis:am5xy.CategoryAxis<am5xy.AxisRenderer>|am5xy.DateAxis<am5xy.AxisRenderer> = null;

            if(_isDate) {
                xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        baseInterval: { timeUnit: "day", count: 1 },
                        renderer: am5xy.AxisRendererX.new(root, {})
                    })
                );
            }
            else {
                let xRenderer = am5xy.AxisRendererX.new(root, { 
                    minGridDistance: 30, 
                    minorGridEnabled: true
                });
                
                xRenderer.labels.template.setAll({
                    oversizedBehavior: "truncate",
                    maxWidth: 150,
                    maxHeight: 150,
                    rotation: -45,
                    centerY: am5.p50,
                    centerX: am5.p100
                });
    
                // Create X-Axis
                xAxis = chart.xAxes.push(
                    am5xy.CategoryAxis.new(root, {
                        categoryField: "category",
                        renderer: xRenderer,
                        tooltip: am5.Tooltip.new(root, {})
                    })
                );
            }

            xAxis.data.setAll(this.data);

            for(let _series of this.series) {
                let _seriesSettings:am5xy.IColumnSeriesSettings = {
                    name: _series.name,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: _series.field,
                    valueXField: _isDate ? "date": null,
                    categoryXField: _isDate ? null: "category",
                    stacked: true
                }

                if(_series.color != null) {
                    _seriesSettings.fill = am5.color(_series.color);
                    _seriesSettings.stroke = am5.color(_series.color);
                }

                // Create series
                let series = chart.series.push(
                    am5xy.ColumnSeries.new(root, _seriesSettings)
                );
                series.data.setAll(this.data);
            }
    
            // Add legend
            let legend = chart.children.push(am5.Legend.new(root, {}));
            legend.data.setAll(chart.series.values);
    
            // Add cursor
            chart.set("cursor", am5xy.XYCursor.new(root, {}));
        }
    }
}
