import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalizationService } from '../../../services/localization.service';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-forms-template-import',
  templateUrl: './forms-template-import.component.html',
  styleUrls: ['./forms-template-import.component.scss']
})
export class FormsTemplateImportComponent implements OnInit {
    @Output() setTemplateData = new EventEmitter<any>();

    selectedTemplate:any = null;
    selectedCategory:string = null;
    selectedSubcategory:string = null;

    subcategoryVisible = false;

    templateData:Array<any> = null;

    categoryOptions:Array<{ value: string, text: string }> = null;
    subcategoryOptions:Array<{ value: string, text: string, hidden?: boolean, _catId:string }> = null;
    templateOptions:Array<{ value: string, text: string, category:string, hidden?: boolean, _catId:string, _subcatId:string }> = null;

    constructor(private dataService:DataService, private localizationService:LocalizationService) { }

    isLoading = false;

    ngOnInit(): void {
        this.isLoading = true;

        this.dataService.getRequest('/public/template/experience').subscribe((data) => {
            this.templateData = data;

            this.templateOptions = [];
            this.categoryOptions = [];
            this.subcategoryOptions = [];

            let _categoriesById:any = {}
            let _subcategoriesById:any = {}

            for(let _cData of data) {
                this.templateOptions.push({ 
                    value: _cData.id, 
                    text: _cData.title, 
                    category: this.localizationService.translate('OPTIONMAPS.Experience.category.' + _cData.category),
                    _catId: _cData.category,
                    _subcatId: _cData.subcategory,
                })

                _categoriesById[_cData.category] = true;
                if(_cData.subcategory != null) {
                    _subcategoriesById[_cData.subcategory] = _cData.category;
                }
            }

            this.templateOptions.sort((a, b) => {
                if(a.category < b.category) return -1;
                else if(a.category > b.category) return 1;
                else {
                    if(a.text < b.text) return -1;
                    else if(a.text > b.text) return 1;
                    else return 0;
                }
            })

            this.selectedCategory = '*'
            this.categoryOptions.push({ value: '*', text: 'Tutte le categorie' })

            for(let i in _categoriesById) {
                this.categoryOptions.push({
                    value: i, text: this.localizationService.translate('OPTIONMAPS.Experience.category.' + i)
                })
            }

            this.categoryOptions.sort((a, b) => {
                if(a.value == '*') return -1;
                else if(b.value == '*') return 1;
                else {
                    if(a.text < b.text) return -1;
                    else if(a.text > b.text) return 1;
                    else return 0;
                }
            })

            this.selectedSubcategory = '*'
            this.subcategoryOptions.push({ value: '*', text: 'Tutte le sottocategorie', _catId: null })

            for(let i in _subcategoriesById) {
                this.subcategoryOptions.push({
                    value: i, text: this.localizationService.translate('OPTIONMAPS.Experience.subcategory.' + i), hidden: true, _catId: _subcategoriesById[i]
                })
            }

            this.subcategoryOptions.sort((a, b) => {
                if(a.value == '*') return -1;
                else if(b.value == '*') return 1;
                else {
                    if(a.text < b.text) return -1;
                    else if(a.text > b.text) return 1;
                    else return 0;
                }
            })

            this.isLoading = false;

            if(this.templateOptions.length == 0) {
                this.skipImport();
            }
        })
    }

    selectedCategoryChange() {
        this.subcategoryVisible = false;

        for(let _cOption of this.subcategoryOptions) {
            if(this.selectedCategory != '*' && this.selectedCategory == _cOption._catId) {
                _cOption.hidden = false;
                this.subcategoryVisible = true;
            }
            else {
                _cOption.hidden = true;
            }
        }

        this.selectedSubcategory = '*'

        for(let _cOption of this.templateOptions) {

            if(this.selectedCategory == '*') {
                _cOption.category = this.localizationService.translate('OPTIONMAPS.Experience.category.' + _cOption._catId)
            }
            else {
                if(_cOption._subcatId == null) {
                    _cOption.category = null;
                }
                else {
                    _cOption.category = this.localizationService.translate('OPTIONMAPS.Experience.subcategory.' + _cOption._subcatId)
                }
            }

            if(this.selectedCategory == '*' || this.selectedCategory == _cOption._catId) {
                _cOption.hidden = false;
            }
            else {
                _cOption.hidden = true;
            }
        }

        this.templateOptions.sort((a, b) => {
            if(a.category < b.category) return -1;
            else if(a.category > b.category) return 1;
            else {
                if(a.text < b.text) return -1;
                else if(a.text > b.text) return 1;
                else return 0;
            }
        })

        this.setSelectedTemplateValue(null);
    }

    selectedSubcategoryChange() {
        for(let _cOption of this.templateOptions) {
            if(this.selectedCategory == '*' || this.selectedCategory == _cOption._catId) {
                if(this.selectedSubcategory == '*' || this.selectedSubcategory == _cOption._subcatId) {
                    _cOption.hidden = false;
                }
                else {
                    _cOption.hidden = true;
                }
            }
            else {
                _cOption.hidden = true;
            }
        }

        this.setSelectedTemplateValue(null);
    }

    getSelectedTemplateValue() {
        if(this.selectedTemplate == null) return null;
        else return this.selectedTemplate.id;
    }

    setSelectedTemplateValue(val:string) {
        this.selectedTemplate = null;

        for(let _cData of this.templateData) {
            if(_cData.id == val) {
                this.selectedTemplate = _cData;
                break;
            }
        }
    }

    skipImport() {
        this.setTemplateData.emit(null)
    }

    doImport() {
        this.setTemplateData.emit(this.selectedTemplate)
    }
}
