import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

    private _copyInput:HTMLInputElement = null;

    constructor() {
        this._copyInput = document.createElement('input');
        this._copyInput.style.position = 'fixed';
        this._copyInput.style.zIndex = '-1';
        this._copyInput.style.opacity = '0';

        document.body.append(this._copyInput)
    }

    copyText(text:string) {
        this._copyInput.value = text;

        this._copyInput.select();
        this._copyInput.setSelectionRange(0, 99999);

        navigator.clipboard.writeText(this._copyInput.value);
    }
}
