<div class="PGFileLicensePicker">
    <div class="modal-header">
        <h5 class="modal-title">Seleziona una licenza</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <div>
            <app-pg-select [(ngModel)]="selectedLicenseValue" (ngModelChange)="onSelectedLicenseValueChange()" [options]="selectLicenseOptions"></app-pg-select>
        </div>
        <div class="mt-3">
            <input class="form-control" [ngModel]="selectedLicenseValue == 'other' ? selectedValue : null" (ngModelChange)="selectedValue = $event"
                [disabled]="selectedLicenseValue != 'other'" placeholder="{{ selectedLicenseValue == 'other' ? 'Specifica altro...' : '' }}"/>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modal.dismiss();">
            Annulla
        </button>
        <button class="btn btn-primary" (click)="modal.close(selectedValue);">
            OK
        </button>
    </div>
</div>