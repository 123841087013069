import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { ExternalLinksService } from './external-links.service';

@Directive({
  selector: '[appExternalLinks]'
})
export class ExternalLinksDirective implements AfterViewChecked {

    constructor(private el: ElementRef, private externalLinksService:ExternalLinksService) {
    }

    @Input() appExternalLinks:string|boolean = null;

    ngAfterViewChecked() {
        if(this.appExternalLinks != false && this.appExternalLinks != 'false') {
            let _linkList = (this.el.nativeElement as HTMLElement).getElementsByTagName('a');

            for(let i = 0; i < _linkList.length; i++) {
                let _cLink = _linkList.item(i);

                if(!_cLink['_isBound']) {
                    _cLink['_isBound'] = true;
                    _cLink.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.externalLinksService.openExternalLink(_cLink.getAttribute('href'))
                    })
                }
            }
        }
    }
}
