import { Directive, Input, AfterViewChecked, ElementRef, OnChanges, Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Directive({
    selector: '[locTranslate]'
})

export class TranslatorDirective implements AfterViewChecked, OnChanges {
    @Input()
    locTranslate: string;

    @Input()
    replaceData: any;

    private _lastLanguage:string = null;

    constructor(private el: ElementRef, private localizationService:LocalizationService) {
    }

    ngOnChanges() {
        this._doTranslation();
    }

    ngAfterViewChecked() {
        if(this.localizationService.currentLanguage != this._lastLanguage) {
            this._doTranslation();
        }
    }

    private _doTranslation() {
        this._lastLanguage = this.localizationService.currentLanguage;

        this.el.nativeElement.innerHTML = this.localizationService.translate(this.locTranslate, this.replaceData);
    }
}

@Directive({
    selector: '[locFormat]'
})

export class FormatterDirective implements AfterViewChecked, OnChanges {
    @Input()
    locFormat: string;

    @Input()
    formatValue: any;

    private _lastLanguage:string = null;

    constructor(private el: ElementRef, private localizationService:LocalizationService) {
    }

    ngOnChanges() {
        this._doFormat();
    }

    ngAfterViewChecked() {
        if(this.localizationService.currentLanguage != this._lastLanguage) {
            this._doFormat();
        }
    }

    private _doFormat() {
        this._lastLanguage = this.localizationService.currentLanguage;

        let _cFn = this.localizationService.format[this.locFormat];

        if(_cFn == null) {
            this.el.nativeElement.innerHTML = '';
        }
        else {
            let _argsArray = [];

            if(this.formatValue != null) {
                if(this.formatValue instanceof Array) {
                    for(let _cParam of this.formatValue) {
                        _argsArray.push(_cParam);
                    }
                }
                else {
                    _argsArray.push(this.formatValue);
                }
            }

            this.el.nativeElement.innerHTML = _cFn.apply(this.localizationService.format, _argsArray);
        }
    }
}