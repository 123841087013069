import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EFItinerary, EFItineraryContent, EFProduct } from '../../../models/experience.model';
import { MapStylesService } from '../../../services/map-styles.service';
import { EFDataService } from '../../../services/ef-data.service';

@Component({
  selector: 'app-ef-product-map',
  templateUrl: './ef-product-map.component.html',
  styleUrls: ['./ef-product-map.component.scss']
})
export class EfProductMapComponent implements OnInit, AfterViewInit {

    @Input() productData:EFProduct;

    @Output() showStop = new EventEmitter<EFItineraryContent>()

    constructor(private mapStylesService:MapStylesService, private efDataService:EFDataService) { }

    ngOnInit(): void {
        this._loadProductItinerary();
    }

    ngAfterViewInit() {
        this.initMap();
    }

    @ViewChild('gmapsElement') gmapsElement: ElementRef;

    map:google.maps.Map = null;
    mapMarker:google.maps.Marker = null;

    initMap() {
        if(this.gmapsElement != null) {
            this.map = new google.maps.Map(this.gmapsElement.nativeElement, {
                center: new google.maps.LatLng(0, 0),
                zoom: 1,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                styles: this.mapStylesService.mapStyles.light,
                gestureHandling: 'cooperative'
            });
    
            if(this.productData.geolocation != null) {
                this.mapMarker = new google.maps.Marker({ 
                    position: new google.maps.LatLng(this.productData.geolocation.coordinates[0], this.productData.geolocation.coordinates[1]),
                    zIndex: 10,
                    title: this.productData.getTranslatedProductField('title'),
                    icon: this.mapStylesService.markerStyles.general.icon
                });
    
                this.mapMarker.setMap(this.map)
            }
    
            this.centerMap();
        }
    }

    centerMap() {
        if(this.mapMarker != null) {
            this.map.setCenter(this.mapMarker.getPosition());
            this.map.setZoom(15);
        }
    }

    itineraryData:EFItinerary = null;
    isLoadingItinerary = false;
    isItineraryFullyLoaded = false;

    private _loadProductItinerary() {
        let _itineraryId = this.productData.getItineraryId();

        if(_itineraryId != null) {
            this.isItineraryFullyLoaded = false;

            if(_itineraryId != null) {
                this.isLoadingItinerary = true;
    
                this.efDataService.getItinerary(_itineraryId).subscribe((data) => {
                    this.isLoadingItinerary = false;
    
                    this.itineraryData = data;
        
                    this.isItineraryFullyLoaded = this.itineraryData.isFullyLoaded;
                    this.itineraryData.isFullyLoadedChange.subscribe((val) => {
                        this.isItineraryFullyLoaded = val;
                    })
                })
            }
        }
    }

    onShowStop(stop:EFItineraryContent) {
        this.showStop.emit(stop)
    }
}
