import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { LocalizationService } from './localization.service';

export type WeatherCategory = 'Thunderstorm' | 'Drizzle' | 'Rain' | 'Snow' | 'Atmosphere' | 'Clear' | 'Clouds'

export class WeatherData {
    main: {
        temp:number,
        pressure:number,
        humidity:number,
        temp_min:number,
        temp_max:number,
        sea_level:number,
        grnd_level:number
    } = null;

    weather:Array<{
        id:number,
        main:string,
        description:string,
        icon:string
    }> = null;

    wind:{
        speed:number,
        deg:number
    } = null;
    
    clouds:{
        all: number
    } = null;

    rain:{
        '3h':number
    } = null;

    snow:{
        '3h':number
    } = null;
    
    dt:number = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }

    private static _weatherDisplayIndex: {
        [main:string]: Array<{ icon: string, color: string, transform?: string }>
    } = {
        Thunderstorm: [{
            color: '#eedd77', icon: 'fa fa-bolt', transform: 'scale(0.5, 0.6) translate(0, 40%)'
        }, {
            color: '#777777', icon: 'fa fa-cloud', transform: 'scale(0.9, 0.85) translate(0, -20%)'
        }],
        Drizzle: [{
            color: '#99bbdd', icon: 'fa fa-cloud-drizzle'
        }, {
            color: '#777777', icon: 'fa fa-cloud', transform: 'scale(0.9, 0.85) translate(0, -20%)'
        }],
        Rain: [{
            color: '#99bbdd', icon: 'fa fa-cloud-rain'
        }, {
            color: '#777777', icon: 'fa fa-cloud', transform: 'scale(0.9, 0.85) translate(0, -20%)'
        }],
        Snow: [{
            color: '#aaddff', icon: 'fa fa-snowflake', transform: 'scale(1.2, 1.2)'
        }],
        Atmosphere: [{
            color: '#777777', icon: 'fa fa-align-justify', transform: 'scale(1.2, 1.2)'
        }],
        Clear: [{
            color: '#eecc00', icon: 'fa fa-sun', transform: 'scale(1.2, 1.2)'
        }],
        Clouds: [{
            color: '#777777', icon: 'fa fa-cloud'
        }]
    }

    static getDisplayData(id:number) {
        return this._weatherDisplayIndex[this.getWeatherCategory(id)];
    }

    private static _weatherImages: {
        [main:string]: string
    } = {
        Thunderstorm: 'https://as1.ftcdn.net/v2/jpg/06/16/64/62/1000_F_616646260_9i1YIzvLA1enfDVdrugeZ3wZWk1pK8hm.jpg',
        Drizzle: 'https://as1.ftcdn.net/v2/jpg/06/14/52/42/1000_F_614524249_8mYUpfK8UsYpTDigM2OxP7em76gCG2sS.jpg',
        Rain: 'https://as1.ftcdn.net/v2/jpg/06/14/52/42/1000_F_614524249_8mYUpfK8UsYpTDigM2OxP7em76gCG2sS.jpg',
        Snow: 'https://as2.ftcdn.net/v2/jpg/06/23/74/43/1000_F_623744319_xDhIzTayHqscL6tvxvbgulwWCo8l5JEQ.jpg',
        Atmosphere: 'https://as2.ftcdn.net/v2/jpg/06/43/59/57/1000_F_643595708_yA9JblUwkzbyeDXqauT4zHyMAMLj0a95.jpg',
        Clear: 'https://as1.ftcdn.net/v2/jpg/03/35/36/38/1000_F_335363862_JCXoFi18ZLEuyUiS7lI7JktWKASPU3Ge.jpg',
        Clouds: 'https://as1.ftcdn.net/v2/jpg/03/02/03/70/1000_F_302037028_WgdzBqp7MCTF0iITajUUVryCKJsyjOE6.jpg',
    }

    static getWeatherImage(id:number) {
        return this._weatherImages[this.getWeatherCategory(id)];
    }

    static getWeatherCategory(id:number):WeatherCategory {

        switch(id) {
            case 200: // thunderstorm with light rain
            case 201: // thunderstorm with rain
            case 202: // thunderstorm with heavy rain
            case 210: // light thunderstorm
            case 211: // thunderstorm
            case 212: // heavy thunderstorm
            case 221: // ragged thunderstorm
            case 230: // thunderstorm with light drizzle
            case 231: // thunderstorm with drizzle
            case 232: // thunderstorm with heavy drizzle 

                return 'Thunderstorm';

            case 300: // light intensity drizzle
            case 301: // drizzle
            case 302: // heavy intensity drizzle
            case 310: // light intensity drizzle rain
            case 311: // drizzle rain
            case 312: // heavy intensity drizzle rain
            case 313: // shower rain and drizzle
            case 314: // heavy shower rain and drizzle
            case 321: // shower drizzle

                return 'Drizzle';

            case 500: // light rain
            case 501: // moderate rain
            case 502: // heavy intensity rain
            case 503: // very heavy rain
            case 504: // extreme rain
            case 511: // freezing rain
            case 520: // light intensity shower rain
            case 521: // shower rain
            case 522: // heavy intensity shower rain
            case 531: // ragged shower rain 

                return 'Rain';

            case 600: // light snow
            case 601: // snow
            case 602: // heavy snow
            case 611: // sleet
            case 612: // shower sleet
            case 615: // light rain and snow
            case 616: // rain and snow
            case 620: // light shower snow
            case 621: // shower snow
            case 622: // heavy shower snow

                return 'Snow';

            case 701: // mist
            case 711: // smoke
            case 721: // haze
            case 731: // sand, dust whirls
            case 741: // fog
            case 751: // sand
            case 761: // dust
            case 762: // volcanic ash
            case 771: // squalls
            case 781: // tornado

                return 'Atmosphere';

            case 800: // clear sky 

                return 'Clear';

            case 801: // few clouds
            case 802: // scattered clouds
            case 803: // broken clouds
            case 804: // overcast clouds

                return 'Clouds';
        }
    }

    getCategory():WeatherCategory {
        return WeatherData.getWeatherCategory(this.weather[0].id)
    }

    getImage() {
        return WeatherData.getWeatherImage(this.weather[0].id)
    }

    getDisplay() {
        let _displayData = WeatherData.getDisplayData(this.weather[0].id);

        let _displayHTML = '';

        for(let _cDisplay of _displayData) {
            let _cStyle = 'color: ' + _cDisplay.color + ';';

            if(_cDisplay.transform != null) {
                _cStyle += 'transform: ' + _cDisplay.transform + ';';
            }
            
            _displayHTML += '<i class="' + _cDisplay.icon + '" style="' + _cStyle + '"></i>';
        }
        
        return '<div class="PGWeatherDisplay">' + _displayHTML + '</div>';
    }

    getDescription() {
        return this.weather[0].description;
    }

    getTemperature(val?:'min'|'max'|'curr') {
        if(val == 'min') {
            return Math.round(this.main.temp_min - 273.15);
        }
        else if(val == 'max') {
            return Math.round(this.main.temp_max - 273.15);
        }
        else return Math.round(this.main.temp - 273.15);
    }
}

export class CurrentWeatherData extends WeatherData {
    coord:{
        lon:number,
        lat:number
    } = null;
    
    base: string = null;

    visibility:number = null;

    sys:{
        type:number,
        id:number,
        message:number,
        country:string,
        sunrise:number,
        sunset:number
    } = null;

    id:number = null;
    name:string = null;
    cod:number = null;

    constructor(fromData?:any) {
        super();

        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }
}

export class ForecastWeatherData {

    cod:string = null;
    message:number = null;
    cnt:number = null;

    city: {
        id:number,
        name:string,
        coord: {
            lat:number,
            lon:number
        },
        country:string,
        population:number
    } = null;

    list:Array<WeatherData> = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(i != 'list') {
                    this[i] = fromData[i];
                }
            }

            this.list = [];

            if(fromData.list != null) {
                for(let _cWeather of fromData.list) {
                    this.list.push(new WeatherData(_cWeather));
                }
            }
        }
    }

    private _getIdMax() {
        let _idCount:any = {}
        let _idMax = 0;

        for(let _weather of this.list) {
            let _id = _weather.weather[0].id;

            if(_idCount[_id] == null) _idCount[_id] = 0;
            _idCount[_id]++;

            _idMax = Math.max(_idMax, _idCount[_id])
        }

        
        for(let i in _idCount) {
            if(_idCount[i] == _idMax) return parseInt(i);
        }
    }

    getDisplay() {
        let _idMax = this._getIdMax()
        for(let _weather of this.list) {
            if(_weather.weather[0].id == _idMax) return _weather.getDisplay();
        }
    }

    getDescription() {
        let _idMax = this._getIdMax()
        for(let _weather of this.list) {
            if(_weather.weather[0].id == _idMax) return _weather.getDescription();
        }
    }

    getTemperature(val?:'min'|'max') {
        let _allTemperatures:Array<number> = [];
        for(let _cWeather of this.list) {
            _allTemperatures.push(_cWeather.getTemperature(val))
        }

        if(val == 'min') {
            let _cMin = null;

            for(let _cTemperature of _allTemperatures) {
                if(_cMin == null || _cMin > _cTemperature) _cMin = _cTemperature;
            }

            return _cMin;
        }
        else if(val == 'max') {
            let _cMax = null;

            for(let _cTemperature of _allTemperatures) {
                if(_cMax == null || _cMax < _cTemperature) _cMax = _cTemperature;
            }

            return _cMax;
        }
        else {
            let _cSum = 0;

            for(let _cTemperature of _allTemperatures) {
                _cSum += _cTemperature;
            }

            return _cSum / _allTemperatures.length;
        }
    }

    getHumidity() {
        let _allHumidity:Array<number> = [];
        for(let _cWeather of this.list) {
            _allHumidity.push(_cWeather.main.humidity)
        }

        let _cSum = 0;

        for(let _cHumidity of _allHumidity) {
            _cSum += _cHumidity;
        }

        return Math.round(_cSum / _allHumidity.length);
    }
}

export class AirQualityData {
    dt:number = null;

    main:{
        aqi:number
    } = null;

    components:{
        co:number,
        no:number,
        no2:number,
        o3:number,
        so2:number,
        pm2_5:number,
        pm10:number,
        nh3:number
    } = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }
}

export class CurrentAirQualityData {
    coord: {
        lat:number,
        lon:number
    } = null;

    list:Array<AirQualityData> = null;

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(i != 'list') {
                    this[i] = fromData[i];
                }
            }

            this.list = [];

            if(fromData.list != null) {
                for(let _cAirQuality of fromData.list) {
                    this.list.push(new AirQualityData(_cAirQuality));
                }
            }
        }
    }
}

export class ForecastAirQualityData {
    cod:string = null;
    message:number = null;
    cnt:number = null;

    coord: {
        lat:number,
        lon:number
    } = null;

    list:Array<AirQualityData> = null;
    
    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(i != 'list') {
                    this[i] = fromData[i];
                }
            }

            this.list = [];

            if(fromData.list != null) {
                for(let _cAirQuality of fromData.list) {
                    this.list.push(new AirQualityData(_cAirQuality));
                }
            }
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class WeatherService {

    public status = {
        inProgress: 0
    }

    constructor(private http: HttpClient, private localizationService:LocalizationService) {

    }

    private _getQueryForParameters(latOrLocation:number|string, lon?:number) {
        if(typeof latOrLocation == 'number' && typeof lon == 'number') {
            return 'lat=' + latOrLocation + '&lon=' + lon;
        }
        else if(typeof latOrLocation == 'string') {
            return 'q=' + latOrLocation;
        }
    }

    getCurrentWeather(lat:number, lon:number):Observable<CurrentWeatherData>
    getCurrentWeather(location:string):Observable<CurrentWeatherData>
    getCurrentWeather(latOrLocation:number|string, lon?:number):Observable<CurrentWeatherData> {
        let _query = this._getQueryForParameters(latOrLocation, lon);

        if(_query != null) {
            return new Observable<CurrentWeatherData> ((observer) => {
                this.http.get('https://api.openweathermap.org/data/2.5/weather?' + _query + '&lang=' + this.localizationService.currentLanguage.split('_')[0] + '&appid=0cfe5efbe8528b22ba64e67039ce6261').subscribe(data => {
    
                    observer.next(new CurrentWeatherData(data));
                    observer.unsubscribe();
                }, error => {
                    console.debug('DATASERVICE - getWeather error:', error);
    
                    observer.next();
                    observer.unsubscribe();
                });
            })
        }
    }

    getForecastWeather(lat:number, lon:number):Observable<Array<ForecastWeatherData>>
    getForecastWeather(location:string):Observable<Array<ForecastWeatherData>>
    getForecastWeather(latOrLocation:number|string, lon?:number):Observable<Array<ForecastWeatherData>> {
        let _query = this._getQueryForParameters(latOrLocation, lon);

        if(_query != null) {
            return new Observable<Array<ForecastWeatherData>> ((observer) => {
                this.http.get('https://api.openweathermap.org/data/2.5/forecast?' + _query + '&lang=' + this.localizationService.currentLanguage.split('_')[0] + '&appid=0cfe5efbe8528b22ba64e67039ce6261').subscribe(data => {
                    let _retVal:Array<ForecastWeatherData> = []

                    let _forecastData:any = data;

                    let _dayMSecs = 1000 * 60 * 60 * 24;
                    let _upToTime = Date.now();

                    let _cForecast:ForecastWeatherData = null;

                    for(let _cWeather of _forecastData.list) {
                        if(_cForecast == null || _cWeather.dt * 1000 >= _upToTime) {
                            _cForecast = new ForecastWeatherData(JSON.parse(JSON.stringify(_forecastData)))
                            _cForecast.list = [];
                            _retVal.push(_cForecast)
                            _upToTime += _dayMSecs;
                        }

                        _cForecast.list.push(new WeatherData(_cWeather))
                    }

                    observer.next(_retVal);
                    observer.unsubscribe();
                }, error => {
                    console.debug('DATASERVICE - getForecast error:', error);

                    observer.next();
                    observer.unsubscribe();
                });
            })
        }
    }

    getCurrentAirQuality(lat:number, lon:number):Observable<CurrentAirQualityData> {
        return new Observable<CurrentAirQualityData> ((observer) => {
            this.http.get('https://api.openweathermap.org/data/2.5/air_pollution?lat=' + lat + '&lon=' + lon + '&lang=' + this.localizationService.currentLanguage.split('_')[0] + '&appid=0cfe5efbe8528b22ba64e67039ce6261').subscribe(data => {

                observer.next(new CurrentAirQualityData(data));
                observer.unsubscribe();
            }, error => {
                console.debug('DATASERVICE - getWeather error:', error);

                observer.next();
                observer.unsubscribe();
            });
        })
    }

    getForecastAirQuality(lat:number, lon:number):Observable<ForecastAirQualityData> {
        return new Observable<ForecastAirQualityData> ((observer) => {
            this.http.get('https://api.openweathermap.org/data/2.5/air_pollution/forecast?lat=' + lat + '&lon=' + lon + '&lang=' + this.localizationService.currentLanguage.split('_')[0] + '&appid=0cfe5efbe8528b22ba64e67039ce6261').subscribe(data => {

                observer.next(new ForecastAirQualityData(data));
                observer.unsubscribe();
            }, error => {
                console.debug('DATASERVICE - getForecast error:', error);

                observer.next();
                observer.unsubscribe();
            });
        })
    }
}