import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../services/config.service';
import { PgSelectOption } from '../../pg-ui-controls/pg-select/pg-select.component'

@Component({
  selector: 'app-pg-select-picker',
  templateUrl: './pg-select-picker.component.html',
  styleUrls: ['./pg-select-picker.component.scss']
})
export class PgSelectPickerComponent implements OnInit {

    label:string = null;

    resource:string = null;
    options:Array<PgSelectOption> = null;
    multi:boolean = null;

    selectedValue:string = null;
    
    resourceSemantic:string = null;

    constructor(public modal:NgbActiveModal, private configService:ConfigService) { }

    ngOnInit(): void {
        if(this.resource != null) {
            this.resourceSemantic = this.configService.getResourceSemantic(this.resource, 'select')
        }
    }

}
