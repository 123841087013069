import { AfterViewInit, Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';

// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

@Component({
  selector: 'app-pg-chart',
  templateUrl: './pg-chart.component.html',
  styleUrls: ['./pg-chart.component.scss']
})
export class PgChartComponent  implements OnInit, OnDestroy, AfterViewInit {

    @Input() createChart:(root:am5.Root) => void

    private root!: am5.Root;

    constructor(private zone: NgZone) {}

    randomId:number = null;
  
    ngOnInit(): void {
        this.randomId = Math.random();
    }
  
    ngAfterViewInit() {
        this.zone.runOutsideAngular(() => {
            let root = am5.Root.new("pgchart_" + this.randomId);

            root.setThemes([am5themes_Animated.new(root)]);
    
            this.createChart(root)
    
            this.root = root;
        });
    }
  
    ngOnDestroy() {
        this.zone.runOutsideAngular(() => {
            if (this.root) {
                this.root.dispose();
            }
        });
    }

}
