import { Component, OnInit, forwardRef, Input } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

/**
 * Controllo switch<br/>
 * Se @Input value è null, è uno switch che fa toggle true/false<br/>
 * Se @Input value è specificato, agisce come un radio button e imposta il valore specificato sul click
 */

@Component({
  selector: 'app-pg-switch',
  templateUrl: './pg-switch.component.html',
  styleUrls: ['./pg-switch.component.scss'],
  providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgSwitchComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgSwitchComponent),
        multi: true,
    }]
})

export class PgSwitchComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    /**
     * Se è null, è uno switch che fa toggle true/false<br/>
     * Se è specificato, agisce come un radio button e imposta il valore specificato sul click
     */
    @Input() value:any;
 
    /** 
     * opzionale: versione piccola, per liste di switch/radio
     */
    @Input() small:boolean;
    @Input() little:boolean;
    @Input() color:string;

    selected:boolean = null;

    constructor() { }

    ngOnInit() {
    }

    toggleSwitch() {
        if(!this.readonly) {
            if(this.value != null) {
                if(!this.required || !this.selected) {
                    this.selected = !this.selected;

                    if(this._onTouched != null) this._onTouched();
                    if(this._onChange != null) {
                        if(this.selected) {
                            this._onChange(this.value);
                        }
                        else {
                            this._onChange(null);
                        }
                    }
                }
            }
            else {
                this.selected = !this.selected;

                if(this._onTouched != null) this._onTouched();
                if(this._onChange != null) this._onChange(this.selected);
            }   
        }
    }

    setSwitch(val:boolean) {
        if(this.selected != val) this.toggleSwitch()
    }

    onKeyDown(eventObj:KeyboardEvent) {
        if(eventObj.keyCode == 32) {
            eventObj.stopPropagation();
            eventObj.preventDefault();
        }
    }

    onKeyUp(eventObj:KeyboardEvent) {
        if(eventObj.keyCode == 32) {
            eventObj.stopPropagation();
            eventObj.preventDefault();
            this.toggleSwitch();
        }
    }

    getSelectedColorClass() {
        if(this.color == null) return 'primary'
        else return this.color;
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        if(this.value != null) {
            this.selected = obj == this.value;
        }
        else {
            this.selected = (obj == 'true' || obj == true);
        }
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        return (!this.required || this.selected)  ? null : {
            required: {
                valid: false
            }
        }
    };
}