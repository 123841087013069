import { Component, forwardRef, Input, OnInit } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

@Component({
    selector: 'app-pg-rating',
    templateUrl: './pg-rating.component.html',
    styleUrls: ['./pg-rating.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgRatingComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgRatingComponent),
        multi: true,
    }]
})
export class PgRatingComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() icon:string;

    value:number = null;

    constructor() { }

    ngOnInit(): void {
    }

    setValue(val:number) {
        if(this.value == val) {
            if(!this.required) {
                this.value = null;
                
                if(this._onTouched) this._onTouched();
                if(this._onChange) this._onChange(this.value);
            }
        }
        else {
            this.value = val;

            if(this._onTouched) this._onTouched();
            if(this._onChange) this._onChange(this.value);
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:number) {
        this.value = obj;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
    };
}
