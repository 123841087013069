import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PGViewResourceInfiniteComponent } from '../resource-view-common';
import { DataFilter, DataService, GetResourceDataOptions } from '../../services/data.service';
import { ConfigService } from '../../services/config.service';
import { SemanticsService } from '../../services/semantics.service';
import { EnvironmentService } from '../../services/environment.service';
import { PgDirectoryData, PgFile, PgFileData, PgFileType, PgFileUtils } from '../../models/file.model';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';
import { AuthService } from '../../services/auth.service';
import { ConfigProfileRoleActions } from '../../models/config.profiles.model';
import { PGUtilities } from '../../pg-utilities';
import JSZip from 'jszip';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { PgModalService } from '../../pg-ui-elements/pg-modal.service';

@Component({
  selector: 'app-pg-resource-view-files',
  templateUrl: './pg-resource-view-files.component.html',
  styleUrls: ['./pg-resource-view-files.component.scss']
})
export class PgResourceViewFilesComponent extends PGViewResourceInfiniteComponent {
    dataRows:Array<PgFile>;

    constructor(dataService: DataService, configService: ConfigService, semanticsService:SemanticsService, router:Router, route:ActivatedRoute, environmentService:EnvironmentService, modalService: NgbModal, localizationService:LocalizationService, notificationsService:NotificationsService, private authService:AuthService, private http:HttpClient, private pgModalService:PgModalService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService);
    }

    directoryActions:ConfigProfileRoleActions = null;

    searchAllOptions = [
        { value: 'true', text: null },
        { value: 'false', text: null },
    ]

    searchAllValue = true;

    setSearchAllValue(value:string) {
        this.searchAllValue = value == 'true';

        this.loadCurrentDirectory()
        this.reset()
    }

    ngOnInit(): void {
        super.ngOnInit()

        this.directoryActions = this.configService.getResourceActions('Directory')

        if(this.hasDirectoriesNavigation()) {
            this.initCurrentDirectory(this.route.snapshot.fragment).then(() => {
                this.loadCurrentDirectory()
            })
        }

        for(let _option of this.searchAllOptions) {
            _option.text = this.localizationService.translate('pg-resource-view-file.search-all-' + _option.value);
        }
    }

    initCurrentDirectory(id:string) {
        return new Promise<void>((resolve, reject) => {
            if(id == null || id == '') {
                resolve()
            }
            else {
                this.isLoadingDirectoryData = true;

                this.dataService.getElementData('Directory', id).subscribe((data) => {
                    this.currentDirectory = data;

                    if(this.currentDirectory.path == null) {
                        resolve()
                    }
                    else {
                        let _pathSplit = this.currentDirectory.path.replace(/^\//, '').replace(/\/$/, '').split('/')

                        if(_pathSplit.length == 0) resolve();
                        else {
                            this.dataService.getResourceData('Directory', {
                                limit: 1000,
                                filter: [{
                                    field: 'id',
                                    operator: 'in',
                                    value: _pathSplit
                                }]
                            }).subscribe((data) => {
                                for(let _id of _pathSplit) {
                                    for(let _item of data) {
                                        if(_item.id == _id) {
                                            this.currentDirectoryPath.push(_item)
                                            break;
                                        }
                                    }
                                }
        
                                resolve()
                            })
                        }
                    }
                })
            }
        })
    }

    viewMode: string = null;

    pageSize = 12;

    setViewMode(viewMode) {
        this.viewMode = viewMode;

        this.reset();
    }

    beforeGetData(options:GetResourceDataOptions) {
        if(!this.hasSearchOrTagsOrCategories() || !this.searchAllValue) {
            options.filter = [{ field: 'directory_id', operator: '==', value: [ this.currentDirectory?.id ] }];
        }

        if(this.viewMode != null) {
            options.filter.push({ field: 'type', operator: '==', value: [ this.viewMode ] })
        }

        if(options.order == null) options.order = [];
        
        if(options.order.length == 0) options.order.push({
            direction: 'desc',
            field: 'id'
        })

        return options;
    }

    onLoadData(data:Array<any>) {
        return new Observable<any>((observer) => {
            let _filesData = [];

            for(let _cData of data) {
                _filesData.push(new PgFile(_cData))
            }

            observer.next(_filesData);
            observer.unsubscribe();
        })
    }

    getLayoutMode() {
        if(this.viewMode == 'images' || this.viewMode == 'videos' || this.viewMode == 'audios') return this.viewMode.toUpperCase();
        else return 'FILES'
    }

    createFile() {
        this.configService.getResourceNewElement(this.resourceId, this.relatedResource, this.relatedElement).subscribe((newData) => {
            if(this.currentDirectory != null) {
                newData.directory_id = this.currentDirectory.id;
            }

            this.dataService.createDraftElement(this.resourceId, newData).subscribe(data => {
                this.router.navigate([this.getBaseURL() +'/form/' + this.resourceId + '/' + data.id], { relativeTo: this.route });
            });
        })
    }

    getCategoryIcon(type:PgFileType) {
        return PgFileUtils.getCategoryIcon(type)
    }

    ngOnChanges() {
        super.ngOnChanges();

        this.viewMode = null;

        if(this.filterStatus != null) {
            for(let _filter of this.filterStatus.filter) {
                if(_filter.field == 'type' && _filter.operator == '==') {
                    this.viewMode = _filter.value[0];
                    return;
                }
            }
        }
    }

    hasViewModeSelection() {
        if(this.filterStatus != null) {
            for(let _filter of this.filterStatus.filter) {
                if(_filter.field == 'type') return false;
            }
        }

        return true;
    }

    currentRenameFile:PgFileData = null;
    currentRenameFileName:string = null;

    @ViewChild('renameFileInput') renameFileInput:ElementRef

    renameFile(file:PgFileData) {
        this.currentRenameFile = file;
        this.currentRenameFileName = this.currentRenameFile.nome

        setTimeout(() => {
            if(this.renameFileInput != null) {
                let _input = this.renameFileInput.nativeElement as HTMLInputElement;
                _input.focus();
                _input.select();
                _input.setSelectionRange(0, 99999);
            }
        }, 10)
    }

    canCurrentRenameFile() {
        if(this.currentRenameFileName == null || this.currentRenameFileName == '' || this.currentRenameFileName == this.currentRenameFile.nome)  return false;
        else return true;
    }

    checkDoCurrentRenameFile() {
        if(this.canCurrentRenameFile()) {
            for(let i = 0; i < this.dataRows.length; i++) {
                if(this.dataRows[i].id == this.currentRenameFile.id) {
                    this.dataRows[i].nome = this.currentRenameFileName
                    this.dataRows[i] = new PgFile(this.dataRows[i])
                    break;
                }
            }

            this.dataService.putElementData('File', this.currentRenameFile.id, { nome: this.currentRenameFileName }).subscribe(() => {}, () => {})
        }

        this.currentRenameFile = null;
    }

    // GESTIONE DIRECTORY

    currentDirectory:PgDirectoryData = null;

    currentDirectoryPath:Array<PgDirectoryData> = [];
    currentDirectoryList:Array<PgDirectoryData> = null;

    hasDirectoriesNavigation() {
        return this.directoryActions?.list;
    }

    canEditDirectories() {
        return this.hasDirectoriesNavigation() && this.directoryActions?.edit
    }

    goToDirectory(directory:PgDirectoryData) {
        if(directory != this.currentDirectory &&
            (this.currentRenameDirectory == null || this.currentRenameDirectory != directory)) {
                
            this.currentDirectoryList = null;
            this.currentDirectory = directory;
    
            if(this.hasSearchOrTagsOrCategories()) {
                this.filterStatus.search = null;
                this.filterStatus.tags = null;
                this.filterStatus.system_tags = null;
                this.filterStatus.filter = [];
    
                this.initCurrentDirectory(directory.id).then(() => {
                    this.loadCurrentDirectory()
                    this.reset()
            
                    this._updateDirectoryFragment()
                })
            }
            else {
                this.loadCurrentDirectory()
                this.reset()
        
                this._updateDirectoryFragment()
            }
        }
    }

    private _updateDirectoryFragment() {
        let _fragment = this.currentDirectory?.id || '';
        _fragment = _fragment.toString();

        this.router.navigate([], { fragment: _fragment })
    }

    hasSearchOrTagsOrCategories() {
        return this.getFilterSearch() || this.getFilterTags() || this.getFilterSystemTags() || this.getFilterCategories();
    }

    getFilterSearch() {
        if(this.filterStatus != null && this.filterStatus.search != null && this.filterStatus.search != '') {
            return this.filterStatus.search
        }
    }

    getFilterTags() {
        if(this.filterStatus != null && this.filterStatus.tags != null && this.filterStatus.tags != '') {
            return this.filterStatus.tags
        }
    }

    getFilterSystemTags() {
        if(this.filterStatus != null && this.filterStatus.system_tags != null && this.filterStatus.system_tags != '') {
            return this.filterStatus.system_tags
        }
    }

    getFilterCategories() {
        if(this.filterStatus != null && this.filterStatus.filter != null) {
            for(let _filter of this.filterStatus.filter) {
                if(_filter.resource == this.resourceId && _filter.field == 'categories' && _filter.operator == 'in') return _filter.value;
            }
        }
    }

    isLoadingDirectoryData = false;

    loadCurrentDirectory() {
        this.setShowCreateDirectory(false);

        this.isLoadingDirectoryData = true;

        let _filter:Array<DataFilter> = [];
        let _search:string = this.getFilterSearch();
        let _tags:string = this.getFilterTags();
        let _system_tags:string = this.getFilterSystemTags();

        let _id = null;

        if(this.currentDirectory == null)  {
            this.currentDirectoryPath = [];
        }
        else {
            _id = this.currentDirectory.id

            let _index = this.currentDirectoryPath.indexOf(this.currentDirectory)
            if(_index != -1) {
                this.currentDirectoryPath.splice(_index + 1)
            }
            else {
                this.currentDirectoryPath.push(this.currentDirectory)
            }
        }

        if(!this.hasSearchOrTagsOrCategories() || !this.searchAllValue) {
            _filter.push({
                resource: 'Directory',
                field: 'parent_id',
                operator: '==',
                value: [ _id ]
            })
        }
        
        let _categories = this.getFilterCategories();

        if(_categories != null) {
            _filter.push({
                resource: 'Directory',
                field: 'categories',
                operator: 'in',
                value: _categories
            })
        }

        if(_filter.length == 0) _filter = null;

        this.dataService.getResourceData('Directory', {
            limit: 1000,
            search: _search,
            tags: _tags,
            system_tags: _system_tags,
            filter: _filter
        }).subscribe((data) => {
            this.isLoadingDirectoryData = false;
            this.currentDirectoryList = data;
        })
    }

    createDirectoryLabel:string = null;

    @ViewChild('createDirectoryInput') createDirectoryInput:ElementRef

    showCreateDirectory = false;

    private _setShowCreateDirectoryTimeout = null

    setShowCreateDirectory(val:boolean, delay?:boolean) {
        clearTimeout(this._setShowCreateDirectoryTimeout);

        if(delay) {
            this._setShowCreateDirectoryTimeout = setTimeout(() => {
                this.setShowCreateDirectory(val)
            }, 2500)
        }
        else {
            this.showCreateDirectory = val;

            if(this.showCreateDirectory) {
                this.createDirectoryLabel = null
        
                this._setShowCreateDirectoryTimeout = setTimeout(() => {
                    if(this.createDirectoryInput != null) {
                        let _input = this.createDirectoryInput.nativeElement as HTMLInputElement;
                        _input.focus()
                        _input.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }, 250)
            }
        }
    }

    canCreateDirectory() {
        if(this.createDirectoryLabel == null || this.createDirectoryLabel == '')  return false;
        else {
            for(let _directory of this.currentDirectoryList) {
                if(_directory.label == this.createDirectoryLabel) return false
            }

            return true;
        }
    }

    createDirectory() {
        if(this.canCreateDirectory()) {
            let _parentId = this.currentDirectory?.id;

            this.dataService.postResourceData('Directory', { parent_id: _parentId, realm_id: this.authService.user.realm?.id, label: this.createDirectoryLabel }).subscribe((data) => {
                if(data != null) {
                    this.currentDirectoryList.unshift(data)
                    this.setShowCreateDirectory(false);
                    this.createDirectoryLabel = null;
                    this.loadCurrentDirectory()
                }
            })
        }
    }

    deleteDirectory(directory:PgDirectoryData) {
        this.dataService.deleteElement('Directory', directory.id).subscribe(() => {
            this.loadCurrentDirectory()
        })
    }

    currentRenameDirectory:PgDirectoryData = null;
    currentRenameDirectoryLabel:string = null;

    @ViewChild('renameDirectoryInput') renameDirectoryInput:ElementRef

    renameDirectory(directory:PgDirectoryData) {
        this.currentRenameDirectory = directory;
        this.currentRenameDirectoryLabel = this.currentRenameDirectory.label

        setTimeout(() => {
            if(this.renameDirectoryInput != null) {
                let _input = this.renameDirectoryInput.nativeElement as HTMLInputElement;
                _input.focus();
                _input.select();
                _input.setSelectionRange(0, 99999);
            }
        }, 10)
    }

    canCurrentRenameDirectory() {
        if(this.currentRenameDirectoryLabel == null || this.currentRenameDirectoryLabel == '' || this.currentRenameDirectory.label == this.currentRenameDirectoryLabel)  return false;
        else return true;
    }

    checkDoCurrentRenameDirectory() {
        if(this.canCurrentRenameDirectory()) {
            this.currentRenameDirectory.label = this.currentRenameDirectoryLabel;
            this.dataService.putElementData('Directory', this.currentRenameDirectory.id, { label: this.currentRenameDirectoryLabel }).subscribe(() => {}, () => {})
        }

        this.currentRenameDirectory = null;
    }

    editSelectionMode = false;

    editSelection:{
        directories:Array<PgDirectoryData>,
        files:Array<PgFileData>,
    } = {
        directories: [],
        files: [],
    }

    private _getEditSelectedListFor(target:PgDirectoryData|PgFileData) {
        if('parent_id' in target) {
            return this.editSelection.directories
        }
        else {
            return this.editSelection.files
        }
    }

    setEditSelected(target:PgDirectoryData|PgFileData, value:boolean) {
        let _checkList = this._getEditSelectedListFor(target)

        if(_checkList != null) {
            for(let i = _checkList.length - 1; i >= 0; i--) {
                if(_checkList[i].id == target.id) {
                    _checkList.splice(i, 1);
                }
            }

            if(value) {
                _checkList.push(target as any)
            }
        }
    }

    isEditSelected(target:PgDirectoryData|PgFileData) {
        let _checkList = this._getEditSelectedListFor(target)

        if(_checkList != null) {
            for(let _item of _checkList) {
                if(_item.id == target.id) return true;
            }
        }

        return false;
    }

    getEditSelectionCount() {
        return this.editSelection.directories.length + this.editSelection.files.length;
    }

    editSelectionReset() {
        this.editSelection.directories = [];
        this.editSelection.files = [];
    }

    private _editSelectionCanMoveHere(item:PgDirectoryData|PgFileData) {
        if('parent_id' in item) {
            if(item.parent_id == this.currentDirectory?.id) {
                return false;
            }
            else {
                if(this.currentDirectory == null) return true;
                else if(this.currentDirectory.path == null) return true; // NB: questo è solo per retrocompatibilità
                else {
                    let _pathSplit = this.currentDirectory.path.split('/')
                    return _pathSplit.indexOf(item.id.toString()) == -1;
                }
            }            
        }
        else {
            return item.directory_id != this.currentDirectory?.id;
        }
    }

    editSelectionCanMoveHere() {
        for(let _item of this.editSelection.directories) {
            if(this._editSelectionCanMoveHere(_item)) return true;
        }

        for(let _item of this.editSelection.files) {
            if(this._editSelectionCanMoveHere(_item)) return true;
        }
    }

    async editSelectionDoMoveHere() {
        let _total = 0;

        for(let _item of this.editSelection.directories) {
            if(this._editSelectionCanMoveHere(_item)) {
                _total++;
            }
        }

        for(let _item of this.editSelection.files) {
            if(this._editSelectionCanMoveHere(_item)) {
                if(this._editSelectionCanMoveHere(_item)) {
                    _total++;
                }
            }
        }

        let _completed = 0;

        let _successDirectories:Array<PgDirectoryData> = [];
        let _successFiles:Array<PgFileData> = [];
  
        if(_total > 0) {
            this.isRunningOperation = true;
            this.isRunningOperationProgress = 0;

            for(let _item of this.editSelection.directories) {
                if(this._editSelectionCanMoveHere(_item)) {
                    try {
                        await new Promise<void>((resolve, reject) => {
                            this.dataService.putElementData('Directory', _item.id, { parent_id: this.currentDirectory.id }).subscribe(() => {
                                resolve()
                            }, () => {
                                reject()
                            })
                        })

                        _successDirectories.push(_item)
                    }
                    catch(ex) {
                        
                    }

                    _completed++;

                    this.isRunningOperationProgress = _completed / _total;
                }
            }
    
            for(let _item of this.editSelection.files) {
                try {
                    await new Promise<void>((resolve, reject) => {
                        this.dataService.putElementData('File', _item.id, { directory_id: this.currentDirectory.id }).subscribe(() => {
                            resolve()
                        }, () => {
                            reject()
                        })
                    })

                    _successFiles.push(_item)
                }
                catch(ex) {
                    
                }

                _completed++;

                this.isRunningOperationProgress = _completed / _total;
            }

            this.isRunningOperationProgress = 1
            this.isRunningOperation = false;

            let _notificationContent = this.localizationService.translate('pg-resource-view-file.move-here-complete');

            if(_successDirectories.length > 0) {
                _notificationContent += '<br/>'
                _notificationContent += this.localizationService.translate('pg-resource-view-file.move-here-complete-directories', { count: _successDirectories.length })

                for(let _item of _successDirectories) {
                    for(let i = 0; i < this.editSelection.directories.length; i++) {
                        if(this.editSelection.directories[i].id == _item.id) {
                            this.editSelection.directories.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            if(_successFiles.length > 0) {
                _notificationContent += '<br/>'
                _notificationContent += this.localizationService.translate('pg-resource-view-file.move-here-complete-files', { count: _successFiles.length })

                for(let _item of _successFiles) {
                    for(let i = 0; i < this.editSelection.files.length; i++) {
                        if(this.editSelection.files[i].id == _item.id) {
                            this.editSelection.files.splice(i, 1);
                            break;
                        }
                    }
                }
            }

            this.notificationsService.addLocalNotification(_notificationContent, 'success', null, null, 3000)

            this.loadCurrentDirectory()
            this.reset()
        }
    }

    editSelectionGetTooltip() {
        let _retVal = '';

        for(let _item of this.editSelection.directories) {
            if(_retVal != '') _retVal += ', ';
            _retVal += _item.label;
        }

        for(let _item of this.editSelection.files) {
            if(_retVal != '') _retVal += ', ';
            _retVal += _item.nome;
        }

        return _retVal;
    }

    downloadFile(file:PgFileData) {
        let _a = document.createElement('a');
        _a.href = file.url;
        _a.target = '_blank';
        _a.download = file.nome;
        _a.click();
    }

    getSystemTagsLabel(systemTags:string) {
        let _parsed = PGUtilities.tryParseJSON(systemTags)
        if(_parsed != null && _parsed.length > 0) {
            let _retVal = ''

            for(let _item of _parsed) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.localizationService.translate('OPTIONMAPS.*.system_tags.' + _item)
            }

            return _retVal;
        }
    }

    getCategoriesLabel(categories:Array<string>) {
        let _retVal = ''

        for(let _item of categories) {
            if(_retVal != '') _retVal += ', '
            _retVal += this.localizationService.translate('OPTIONMAPS.' + this.resourceId + '.categories.' + _item)
        }

        return _retVal
    }

    directoryPropagateChanges = false;

    isLoadingDirectoryDataProgress:number = null;

    private async _loadDirectoryRecursiveLists(id:string) {
        let _checkList:Array<string> = [id]

        let _directoryList:Array<any> = []
        let _fileList:Array<any> = []

        while(_checkList.length > 0) {
            await new Promise<void>((resolve, reject) => {
                this.dataService.getResourceData('File', { filter: [
                    { field: 'directory_id', operator: 'in', value: _checkList }
                ] }).subscribe((data) => {
                    for(let _item of data) {
                        if(_checkList.indexOf(_item.directory_id) != -1) { // NB: patch FE per un errore BE, ad un certo punto si potrà rimuovere
                            _fileList.push(_item)
                        }
                    }
             
                    resolve()
                })
            })

            await new Promise<void>((resolve, reject) => {
                this.dataService.getResourceData('Directory', { filter: [
                    { field: 'parent_id', operator: 'in', value: _checkList }
                ] }).subscribe((data) => {
                    _checkList = []

                    for(let _item of data) {
                        _checkList.push(_item.id)
                        _directoryList.push(_item)
                    }

                    resolve()
                })
            })
        }

        return {
            directories: _directoryList,
            files: _fileList
        }
    }

    async checkDirectoryPropagateChanges(data:any) {
        if(this.directoryPropagateChanges) {
            this.isLoadingDirectoryData = true;

            this.isLoadingDirectoryDataProgress = 0;

            let _lists = await this._loadDirectoryRecursiveLists(data.data.id)
            
            this.isLoadingDirectoryDataProgress = 0.1;

            let _requests = 0;

            for(let _file of _lists.files) {
                await new Promise<Array<any>>((resolve, reject) => {
                    this.dataService.putElementData('File', _file.id, data.changes).subscribe((data) => {
                        _requests++;
                        this.isLoadingDirectoryDataProgress = 0.1 + 0.9 * _requests / (_lists.files.length + _lists.directories.length);

                        resolve(data)
                    })
                })
            }


            for(let _directory of _lists.directories) {
                await new Promise<Array<any>>((resolve, reject) => {
                    this.dataService.putElementData('Directory', _directory.id, data.changes).subscribe((data) => {
                        _requests++;
                        this.isLoadingDirectoryDataProgress = 0.1 + 0.9 * _requests / (_lists.files.length + _lists.directories.length);

                        resolve(data)
                    })
                })
            }

            this.isLoadingDirectoryDataProgress = 1;

            setTimeout(() => {
                this.isLoadingDirectoryDataProgress = null;

                this.loadCurrentDirectory()
            }, 500)
        }
        else {
            this.loadCurrentDirectory()
        }
    }

    isRunningOperation = false;
    isRunningOperationProgress = null;

    async downloadDirectory() {
        if(this.currentDirectory != null) {
            this.isRunningOperation = true;
            this.isRunningOperationProgress = null;

            let _downloadDirectory = this.currentDirectory
    
            let _lists = await this._loadDirectoryRecursiveLists(_downloadDirectory.id)
    
            let _confirmed = false;
    
            try {
                await this.pgModalService.confirm(this.localizationService.translate('pg-resource-view-file.directory-download-confirm-title'), 
                    this.localizationService.translate('pg-resource-view-file.directory-download-confirm-text', { files: _lists.files.length, directories: _lists.directories.length }))
    
                _confirmed = true;
            }
            catch(ex) {
                console.log('downloadDirectory: user cancel')
                this.isRunningOperation = false;
            }
    
            if(_confirmed) {
                this.isRunningOperationProgress = 0;
                
                let _zip = new JSZip();
    
                let _zipDirectories: {
                    [id:string]: JSZip
                } = {}
    
                _zipDirectories[_downloadDirectory.id] = _zip.folder(_downloadDirectory.label)
    
                for(let _directory of _lists.directories) {
                    let _parentZip = _zipDirectories[_directory.parent_id]
                    _zipDirectories[_directory.id] = _parentZip.folder(_directory.label)
                }
        
                for(let i = 0; i < _lists.files.length; i++) {
                    let _file = _lists.files[i]

                    this.isRunningOperationProgress = i / _lists.files.length

                    await new Promise<void>((resolve, reject) => {
                        this.http.get(_file.url, { observe: 'events', responseType: 'arraybuffer' }).subscribe((event: HttpEvent<any>) => {
        
                            switch (event.type) {
                                case HttpEventType.DownloadProgress:
                                    this.isRunningOperationProgress = (i + event.loaded / event.total) / _lists.files.length
        
                                    break;
            
                                case HttpEventType.Response:
                                    _zipDirectories[_file.directory_id].file(_file.nome_file, event.body)
                                    resolve()
                                    break;
                            }
                        })
                    })
        
                    this.isRunningOperationProgress = (i + 1) / _lists.files.length
                }
        
                this.isRunningOperationProgress = 1;
        
                let _content = await _zip.generateAsync({ type: "blob" })
        
                this.isRunningOperationProgress = null
                this.isRunningOperation = false
        
                let _url = URL.createObjectURL(_content)
        
                let _a = document.createElement('a');
                _a.href = _url;
                _a.target = '_blank';
                _a.download = _downloadDirectory.label + '.zip';
                _a.click();
        
                /*zip.file("Hello.txt", "Hello World\n");
        
                const img = zip.folder("images");
                img.file("smile.gif", imgData, {base64: true});
        
                zip.generateAsync({type:"blob"}).then(function(content) {
                    // see FileSaver.js
                    saveAs(content, "example.zip");
                }); */
        
            }
        }
    }
}
