import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiMoleculesModule } from '../pg-ui-molecules/pg-ui-molecules.module';
import { PgTicketsSelectComponent } from './elements/pg-tickets-select/pg-tickets-select.component';
import { PgBookingProductComponent } from './elements/pg-booking-product/pg-booking-product.component';
import { PgBookingComponent } from './elements/pg-booking/pg-booking.component';
import { PgCreateBookingComponent } from './elements/pg-create-booking/pg-create-booking.component';
import { PgProductHostAccommodationComponent } from './elements/pg-product-host-accommodation/pg-product-host-accommodation.component';
import { PgSurveyAnswersComponent } from './elements/pg-survey-answers/pg-survey-answers.component';
import { PgProductCardComponent } from './elements/pg-product-card/pg-product-card.component';
import { PgConfirmBookingComponent } from './elements/pg-confirm-booking/pg-confirm-booking.component';
import { PgBookingsCalendarComponent } from './elements/pg-bookings-calendar/pg-bookings-calendar.component';
import { PgBookingsSlotSummaryComponent } from './elements/pg-bookings-slot-summary/pg-bookings-slot-summary.component';


@NgModule({
    declarations: [
        PgTicketsSelectComponent,
        PgBookingProductComponent,
        PgBookingComponent,
        PgCreateBookingComponent,
        PgProductHostAccommodationComponent,
        PgSurveyAnswersComponent,
        PgProductCardComponent,
        PgConfirmBookingComponent,
        PgBookingsCalendarComponent,
        PgBookingsSlotSummaryComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        PgUiElementsModule,
        PgUiControlsModule,
        PgUiMoleculesModule
    ],
    exports: [
        PgBookingProductComponent,
        PgBookingComponent,
        PgCreateBookingComponent,
        PgProductCardComponent,
        PgConfirmBookingComponent,
        PgBookingsCalendarComponent
    ],
    providers: []
})
export class CheckoutModule { }
