import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { PgProfileEditorService } from '../pg-profile-editor.service';

@Component({
  selector: 'app-pg-profile-editor',
  templateUrl: './pg-profile-editor.component.html',
  styleUrls: ['./pg-profile-editor.component.scss']
})
export class PgProfileEditorComponent implements OnInit {

    constructor(private pgProfileEditorService:PgProfileEditorService, private modalService:NgbModal, private authService:AuthService) { }

    @ViewChild('profileEditorTemplate') profileEditorTemplate: ElementRef;

    private _profileEditorRef:NgbModalRef = null;

    editorSection:string = null;

    ngOnInit(): void {

        this.pgProfileEditorService.request.subscribe((data) => {
            
            if(this._profileEditorRef == null) {
                let _cOptions:NgbModalOptions = {
                    backdrop: 'static',
                    keyboard: false,
                    windowClass: 'PGProfileEditorModal',
                    size: 'md'
                }
    
                this._profileEditorRef = this.modalService.open(this.profileEditorTemplate, _cOptions);
                this._profileEditorRef.result.then(() => {
                    this._profileEditorRef = null;
                }, () => {
                    this._profileEditorRef = null;
                })
            }

            this.editorSection = data.fn;

            this.changePasswordNew = '';
            this.changePasswordNewConfirm = '';
            this.onChangePasswordNewChange();
        })
    }

    closeEditor() {
        this._profileEditorRef.close();
    }

    changePasswordNew = '';
    changePasswordNewConfirm = '';

    changePasswordErrorLength = false;
    changePasswordErrorFormat = false;
    changePasswordErrorConfirm = false;

    onChangePasswordNewChange() {
        this.changePasswordErrorLength = false;
        this.changePasswordErrorFormat = false;
        this.changePasswordErrorConfirm = false;

        if(this.changePasswordNew.length < 8) {
            this.changePasswordErrorLength = true;
        }
        else if(!/[a-z]/.test(this.changePasswordNew) || !/[A-Z]/.test(this.changePasswordNew) || !/\d/.test(this.changePasswordNew)) {
            this.changePasswordErrorFormat = true;
        }
        else if(this.changePasswordNew != this.changePasswordNewConfirm) {
            this.changePasswordErrorConfirm = true;
        }
    }

    canChangePassword() {
        return !this.changePasswordErrorLength && !this.changePasswordErrorFormat && !this.changePasswordErrorConfirm;
    }

    doChangePassword() {
        if(this.canChangePassword()) {
            this.authService.changePassword(this.changePasswordNew).then((data) => {
                if(data != null) {
                    this.closeEditor();
                    this.authService.logout();
                }
            }, () => {
            })
        }
    }

    onChangePasswordKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            this.doChangePassword();
        }
    }
}
