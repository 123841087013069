import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-pg-view-more',
  templateUrl: './pg-view-more.component.html',
  styleUrls: ['./pg-view-more.component.scss']
})
export class PgViewMoreComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input() hidden:boolean;
    @Output() viewMore = new EventEmitter<void>();

    @ViewChild('viewMoreButton') viewMoreButton:ElementRef;

    private _checkViewMore:() => void;

    ngOnInit(): void {
        this._checkViewMore = () => {
            if(!this.hidden && this.viewMoreButton != null) {
                let _button = this.viewMoreButton.nativeElement as HTMLButtonElement;
                let _rect = _button.getBoundingClientRect();
                
                if(_rect.width > 0 &&
                    _rect.height > 0 &&
                    _rect.top >= 0 &&
                    _rect.left >= 0 &&
                    _rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    _rect.right <= (window.innerWidth || document.documentElement.clientWidth)) {

                    this.viewMore.emit();
                }
            }
        }

        window.addEventListener('scroll', this._checkViewMore, true)
        window.addEventListener('resize', this._checkViewMore)
    }

    ngOnDestroy(): void {
        window.removeEventListener('scroll', this._checkViewMore)
        window.removeEventListener('resize', this._checkViewMore)
    }

    private _mustCheckAfterViewChecked = false;

    ngOnChanges() {
        this._mustCheckAfterViewChecked = true;
    }

    private _checkTimeout = null;

    ngAfterViewChecked() {
        if(this._mustCheckAfterViewChecked) {
            this._mustCheckAfterViewChecked = false;

            clearTimeout(this._checkTimeout)
            this._checkTimeout = setTimeout(() => {
                this._checkViewMore();
            }, 100);
        }
    }
}
