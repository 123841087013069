import { Component, Input, OnChanges } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-pg-group-widget',
  templateUrl: './pg-group-widget.component.html',
  styleUrls: ['./pg-group-widget.component.scss']
})
export class PgGroupWidgetComponent implements OnChanges {

    @Input() groupId:string;

    constructor(private dataService:DataService) { }

    isLoading = false;

    groupData:{
        id:number,
        label:string
    } = null;

    ngOnChanges(): void {
        this.isLoading = true;

        this.groupData = null;

        this.dataService.getElementData('Group', this.groupId).subscribe((data) => {
            this.isLoading = false;
            this.groupData = data;
        }, () => {
            this.isLoading = false;
        })
    }
}
