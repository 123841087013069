import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ConfigResourceView } from '../../../models/config.resources.model';
import { PgSelectOption } from '../../../pg-ui-controls/pg-select/pg-select.component';
import { ConfigService } from '../../../services/config.service';
import { PGViewResourceConfigComponent } from '../../resource-view-common';

export class PgResourceViewTableConfigColumnField
{
    field:string = null;
    sum:boolean = null;
}

export class PgResourceViewTableConfigColumnRoleview
{
    name:string = null;
    roleview:string = null;
}

export class PgResourceViewTableConfigColumnCluster
{
    name:string = null;
    cluster:string = null;
}

export class PgResourceViewTableConfig
{
    getRowStatus:string;
    columns:Array<PgResourceViewTableConfigColumnField|PgResourceViewTableConfigColumnRoleview|PgResourceViewTableConfigColumnCluster> = [];

    static isFieldColumn(column): column is PgResourceViewTableConfigColumnField {
        return (typeof column.field != 'undefined');
    }

    static isRoleviewColumn(column): column is PgResourceViewTableConfigColumnRoleview {
        return (typeof column.roleview != 'undefined');
    }

    static isClusterColumn(column): column is PgResourceViewTableConfigColumnCluster {
        return (typeof column.cluster != 'undefined');
    }
}

@Component({
  selector: 'app-pg-resource-view-table-config',
  templateUrl: './pg-resource-view-table-config.component.html',
  styleUrls: ['./pg-resource-view-table-config.component.scss']
})

export class PgResourceViewTableConfigComponent extends PGViewResourceConfigComponent implements OnInit {
    @Input() view:ConfigResourceView<PgResourceViewTableConfig>;

    dragDropScope:string = null;

    viewTypeOptions:Array<PgSelectOption> = null;

    newColumnType = 'roleview';

    constructor(private configService:ConfigService) {
        super();
    }

    ngOnInit() {
        this.dragDropScope = Math.random().toString();

        this.viewTypeOptions = [
            { value: 'roleview', text: 'ROLEVIEW' },
            { value: 'cluster', text: 'CLUSTER' }
        ]

        let _cModel = this.configService.models[this.view.resource];
        
        for(let _cField of _cModel) {
            this.viewTypeOptions.push({
                value: _cField.name,
                text: _cField.name,
                category: 'field'
            })
        }

        let _translatedByRelation = this.configService.getResourceTranslatedByRelation(this.view.resource);

        if(_translatedByRelation != null) {
            let _translationModel = this.configService.models[_translatedByRelation.modelB];

            for(let _cField of _translationModel) {
                if(_cField.name != 'id' && _cField.name != 'language' && _cField.name != _translatedByRelation.joinField) {
                    this.viewTypeOptions.push({
                        value: _cField.name + '@' + _translatedByRelation.modelB,
                        text: _cField.name,
                        category: 'translation'
                    })
                }
            }
        }

        if(this.view.config == null) {
            this.view.config = new PgResourceViewTableConfig();

            let _columnCount = 0;

            for(let _cField of _cModel) {
                let _cColumn = new PgResourceViewTableConfigColumnField();
                _cColumn.field = _cField.name;

                this.view.config.columns.push(_cColumn);

                _columnCount++;
                if(_columnCount >= 5) break;
            }
        }

        this.disableInsertedOptions();
    }

    isFieldColumn = PgResourceViewTableConfig.isFieldColumn;
    isRoleviewColumn = PgResourceViewTableConfig.isRoleviewColumn;
    isClusterColumn = PgResourceViewTableConfig.isClusterColumn;

    canFieldSum(field:string) {
        let _cModel = this.configService.models[this.view.resource];
        
        for(let _cField of _cModel) {
            if(_cField.name == field) {
                return _cField.type == 'decimal' || _cField.type == 'float' || _cField.type == 'integer';
            }
        }
    }

    disableInsertedOptions() {
        let _insertedFields = [];

        for(let _cColumn of this.view.config.columns) {
            if(this.isFieldColumn(_cColumn)) {
                _insertedFields.push(_cColumn.field);
            }
            else if(this.isClusterColumn(_cColumn)) {
                if(_cColumn.cluster != null) {
                    for(let _cField of _cColumn.cluster.split(',')) {
                        _insertedFields.push(_cField);
                    }
                }
            }
        }

        for(let _cViewTypeOption of this.viewTypeOptions) {
            if(_cViewTypeOption.category == 'field') {
                if(_insertedFields.indexOf(_cViewTypeOption.value) > -1) _cViewTypeOption.disabled = true;
                else _cViewTypeOption.disabled = false;
            }
        }
    }

    moveConfig(fromPosition:number, toPosition:number) {
        let _cField = this.view.config.columns.splice(fromPosition, 1);
        if(fromPosition < toPosition) toPosition--;
        this.view.config.columns.splice(toPosition, 0, _cField[0]);
    }

    addNewColumn() {
        if(this.newColumnType == 'roleview') {
            let _cNewColumn = new PgResourceViewTableConfigColumnRoleview();
            _cNewColumn.name = 'Nuova colonna';
            this.view.config.columns.push(_cNewColumn);
        }
        else if(this.newColumnType == 'cluster') {
            let _cNewColumn = new PgResourceViewTableConfigColumnCluster();
            _cNewColumn.name = 'Nuova colonna';
            this.view.config.columns.push(_cNewColumn);
        }
        else {
            let _cNewColumn = new PgResourceViewTableConfigColumnField();
            _cNewColumn.field = this.newColumnType;
            this.view.config.columns.push(_cNewColumn);
        }
        
        this.newColumnType = 'roleview';

        this.disableInsertedOptions();
    }

    removeColumn(column:PgResourceViewTableConfigColumnRoleview|PgResourceViewTableConfigColumnField) {
        this.view.config.columns.splice(this.view.config.columns.indexOf(column), 1);

        this.disableInsertedOptions();
    }
}
