import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { PersistenceConsentConfig, PersistenceConsentData, PersistenceService } from '../../services/persistence.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-pg-cookie-consent',
  templateUrl: './pg-cookie-consent.component.html',
  styleUrls: ['./pg-cookie-consent.component.scss']
})
export class PgCookieConsentComponent implements OnInit {

    constructor(private environmentService:EnvironmentService, private persistenceService:PersistenceService, private http:HttpClient) { }

    showConsent = false;
    consentData:PersistenceConsentData = null;
    consentConfig:Array<PersistenceConsentConfig> = null;

    privacyPolicyURL:string = null;

    ngOnInit(): void {
        this.privacyPolicyURL = this.environmentService.getPrivacyPolicyURL()

        this.showConsent = this.persistenceService.needConsent;
        this.consentConfig = this.persistenceService.consentConfig;
        this.consentData = JSON.parse(JSON.stringify(this.persistenceService.consentData));

        if(this.consentData != null) {
            for(let _config of this.consentConfig) {
                if(_config.required || this.consentData[_config.id] == null) this.consentData[_config.id] = true;
            }
        }
    }

    saveConsent() {
        this.persistenceService.saveConsent(this.consentData)
        
        this.http.post('api/acceptPrivacy', null).subscribe(() => {}, () => {})
    }

    cancelConsent() {
        this.showConsent = false;
    }
}
