<div class="PGAdminGroups">
    <div *ngIf="isLoading" class="PGAdmin-Loading">
        <app-pg-loading></app-pg-loading>
    </div>
    <div *ngIf="!isLoading">
        <div class="PGAdmin-Header navbar row">
            <div class="col">
                GROUPS
            </div>
            <div class="col PGAdmin-Row-Actions">
            </div>
        </div>
        <div class="container-fluid">
            <div class="row PGAdmin-Header">
                <div class="col col-1">id</div>
                <div class="col">label</div>
                <div class="col PGAdminGroups-Col-Buttons"></div>
            </div>
            <div *ngIf="groups.length == 0" class="row">
                <div class="col">
                    <span class="my-3 text-muted">Nessun gruppo presente</span>
                </div>
            </div>
            <ng-container *ngFor="let group of groups">
                <div class="row">
                    <div class="col col-1">{{group.id}}</div>
                    <div class="col">
                        <input class="form-control" [(ngModel)]="group.label" [readonly]="!actions.edit"/>
                    </div>
                    <div class="col PGAdminGroups-Col-Buttons">
                        <app-pg-confirm-action (confirmed)="deleteGroup(group)" [disabled]="!actions.delete">
                            <button class="btn btn-sm btn-danger" [disabled]="!actions.delete">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </app-pg-confirm-action>
                    </div>
                </div>
            </ng-container>
            <div class="row PGAdmin-Footer">
                <div class="col col-1"></div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="addGroupName" [readonly]="!actions.create"/>
                </div>
                <div class="col PGAdminGroups-Col-Buttons">
                    <button class="btn btn-sm btn-primary" (click)="addGroup()" [disabled]="!actions.create">
                        <i class="fa-regular fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>