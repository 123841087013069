<div *ngIf="imagesList != null && imagesList.length > 0" class="EFCarousel {{isFullscreen ? 'EFCarousel--Fullscreen' : ''}}" (click)="resetInactivity()">
    <button *ngIf="!isFullscreen" class="EFCarousel-ToggleFullscreen btn btn-light m-2" (click)="isFullscreen = !isFullscreen">
        <i class="fa-regular fa-grid-2"></i>
    </button>
    <button *ngIf="isFullscreen" class="EFCarousel-ToggleFullscreen btn btn-lg btn-link text-white" (click)="isFullscreen = !isFullscreen">
        <i class="fa-regular fa-times"></i>
    </button>
    <div class="EFCarousel-Slides">
        <div>
            <div *ngFor="let image of imagesList" [style.transform]="'translate(' + (-currentIndex * 100) + '%, 0%)'">
                <app-pg-image-autosize [src]="image" [fit]="isFullscreen ? 'contain' : 'cover'"></app-pg-image-autosize>
            </div>
        </div>
    </div>
    <div *ngIf="imagesList.length > 1" class="EFCarousel-Index">
        <button *ngFor="let image of imagesList; let i = index" class="btn btn-link text-white" (click)="currentIndex = i">
            <i class="{{i == currentIndex ? '' : 'opacity-50'}} fa fa-circle"></i>
        </button>
    </div>
</div>
