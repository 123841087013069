import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, forwardRef, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { CommonModule, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbDropdownConfig, NgbModalConfig, NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../environments/environment';

import "@angular/compiler"
import { ApiInterceptor, DataService, EnvironmentService, ExperienceFinderModule, LocalizationService, MapStylesService, NotificationsService, OptionMapsService, PgUiControlsModule, PgUiElementsModule, PgUiMoleculesModule, UpdateCheckerService, PreserveQueryParamsPathLocationStrategy, AnalyticsService, PGUtilities, PersistenceService } from 'pg-lib';
import { EfLandingComponent } from './ef-landing/ef-landing.component';
import { AppRoutingModule } from './app-routing.module';
import { EfShowcaseViewComponent } from './ef-showcase-view/ef-showcase-view.component';
import { EfProductViewComponent } from './ef-product-view/ef-product-view.component';
import { EfContentViewComponent } from './ef-content-view/ef-content-view.component';

@NgModule({
    declarations: [
        AppComponent,
        EfLandingComponent,
        EfShowcaseViewComponent,
        EfProductViewComponent,
        EfContentViewComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        AppRoutingModule,
        PgUiElementsModule,
        PgUiControlsModule,
        PgUiMoleculesModule,
        ExperienceFinderModule
    ],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: (optionMapsService:OptionMapsService, environmentService:EnvironmentService, ngbDropdownConfig:NgbDropdownConfig, ngbTooltipConfig:NgbTooltipConfig, ngbModalConfig:NgbModalConfig, localizationService:LocalizationService, mapStylesService:MapStylesService, updateCheckerService:UpdateCheckerService, notificationsService:NotificationsService, dataService:DataService, analyticsService:AnalyticsService, persistenceService:PersistenceService): Function => {
            return async () => {
                persistenceService.activate();

                ngbDropdownConfig.container = null;
                ngbTooltipConfig.container = null;
                ngbModalConfig.container = '.EFApp';
                ngbModalConfig.backdrop = 'static';
                ngbModalConfig.keyboard = false;
                
                await environmentService.init(environment);

                if(environmentService.getTimeUntilManteinance() > 0) {
                    dataService.init(environmentService);
                    
                    await localizationService.init();
                    await optionMapsService.init();

                    await mapStylesService.init(); 
                    if(persistenceService.hasConsentFor('technical')) await PGUtilities.loadScript('https://maps.googleapis.com/maps/api/js?key=' + environmentService.environment.GoogleMapsAPIKey + '&libraries=places', { async: null, defer: null })
                     
                    await notificationsService.init();

                    updateCheckerService.init();

                    analyticsService.init('experience-finder');
                }
            };
        },
        deps: [OptionMapsService,EnvironmentService,NgbDropdownConfig,NgbTooltipConfig,NgbModalConfig,LocalizationService,MapStylesService,UpdateCheckerService,NotificationsService,DataService,AnalyticsService,PersistenceService],
        multi: true
    }, {
        provide: HTTP_INTERCEPTORS,
        useExisting: forwardRef(() => ApiInterceptor),
        multi: true
    }, {
        provide: LocationStrategy,
        useClass: PreserveQueryParamsPathLocationStrategy
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
