<div class="PGTickets">
    <div class="container-fluid">
        <ng-container *ngIf="priceOnRequest">
            <div class="row PGTickets-Element PGTickets-Element-Heading border-bottom">
                <div class="col PGTickets-Col-Label">
                    <span *ngIf="availabilitySelected.availability != null" class="{{ getSelectionTotalQuantity() > availabilitySelected.availability.current ? 'text-danger' : ''}}">
                        {{ 'pg-tickets-select.column-available' | locTranslate:{ num: availabilitySelected.availability.current} }}
                    </span>
                </div>
                <div class="col PGTickets-Col-Quantity">{{ 'pg-tickets-select.column-quantity' | locTranslate }}</div>
            </div>
            <div class="row PGTickets-Element">
                <div class="col PGTickets-Col-Label">{{ 'pg-tickets-select.label-price-on-request' | locTranslate }}</div>
                <div class="col PGTickets-Col-Quantity">
                    <input type="number" min="0" step="1" class="form-control form-control-sm" [ngModel]="selection.fullQuantity" (ngModelChange)="selection.fullQuantity = checkQuantity($event)"/>
                </div>
            </div>  
        </ng-container>
        <ng-container *ngIf="!priceOnRequest">
            <div class="row PGTickets-Element PGTickets-Element-Heading border-bottom">
                <div class="col PGTickets-Col-Label">
                    <span *ngIf="availabilitySelected.availability != null" class="{{ getSelectionTotalQuantity() > availabilitySelected.availability.current ? 'text-danger' : ''}}">
                        {{ 'pg-tickets-select.column-available' | locTranslate:{ num: availabilitySelected.availability.current} }}
                    </span>
                </div>
                <div class="col PGTickets-Col-Price">{{ 'pg-tickets-select.column-price' | locTranslate }}</div>
                <div class="col PGTickets-Col-Quantity">{{ 'pg-tickets-select.column-quantity' | locTranslate }}</div>
            </div>         
            <div *ngIf="selection.fullPrice != null" class="row PGTickets-Element">
                <div class="col PGTickets-Col-Label">{{ 'pg-tickets-select.label-full-price' + (single ? '-single' : '') | locTranslate }}</div>
                <div class="col PGTickets-Col-Price">
                    <span>{{formatPrice(selection.fullPrice, true)}}</span>
                </div>
                <div class="col PGTickets-Col-Quantity">
                    <input *ngIf="!single" type="number" min="0" step="1" class="form-control form-control-sm" [ngModel]="selection.fullQuantity" (ngModelChange)="selection.fullQuantity = checkQuantity($event)"/>
                    <input *ngIf="single" type="radio" name="{{randomId}}_single" class="form-check" value="full" [ngModel]="getSingleValue()" (ngModelChange)="setSingleValue('full')"/>
                </div>
            </div>
            <ng-container *ngFor="let other of selection.otherTickets; let i = index">
                <div class="row PGTickets-Element">
                    <div class="col PGTickets-Col-Label">{{formatTicketName(other.name)}}</div>
                    <div class="col PGTickets-Col-Price">
                        <span>{{formatPrice(other.price, true)}}</span>
                    </div>
                    <div class="col PGTickets-Col-Quantity">
                        <input *ngIf="!single" type="number" min="0" step="1" class="form-control form-control-sm" [ngModel]="other.quantity" (ngModelChange)="other.quantity = checkQuantity($event)"/>
                        <input *ngIf="single" type="radio" name="{{randomId}}_single" class="form-check" value="other{{i}}" [ngModel]="getSingleValue()" (ngModelChange)="setSingleValue('other' + i)"/>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="!single" class="row PGTickets-Element PGTickets-Total border-top">
                <div class="col PGTickets-Col-Label">{{ 'pg-tickets-select.label-total' | locTranslate }}</div>
                <div class="col PGTickets-Col-Price">{{formatPrice(getSelectionTotalPrice(), getSelectionTotalQuantity() > 0)}}</div>
                <div class="col PGTickets-Col-Quantity">{{getSelectionTotalQuantity()}}</div>
            </div>
        </ng-container>  
        <div class="row PGTickets-Buttons border-top pt-2">
            <div class="col">
                <button class="btn btn-primary float-end" [disabled]="!isSelectionValid()" (click)="ticketsSelected.emit(selection)">{{ 'pg-tickets-select.button-select' | locTranslate }}</button>
            </div>
        </div>
    </div>
</div>
