import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pg-import',
  templateUrl: './pg-import.component.html',
  styleUrls: ['./pg-import.component.scss']
})
export class PgImportComponent implements OnInit, OnChanges {

    @Input() APIUrlOptions:Array<{ value: string, text: string }>;
    @Input() resourceOptions:Array<{ value:string, text: string }>;
    @Input() getFormURL:(resource:string, id:string) => string;

    @Input() adminMode:boolean;
    @Input() resourceId:string;
    
    elementIdList:Array<string> = [];

    APIUrl:string = null;

    resourceAdapters:{ 
        [resource:string]: { 
            admin?: boolean, 
            keys?: Array<string>,
            adapt: (values:any) => any 
        } 
    } = {
        'Experience': {
            admin: false,
            adapt: (values) => {
                values.user_id = null;
                values.certificate = null;
                values.published = false;
                values.supplier = null;

                return values;
            }
        },
        'Host': {
            admin: false,
            adapt: (values) => {
                values.user_id = null;

                return values;
            }
        },
        'Eatery': {
            admin: false,
            adapt: (values) => {
                values.user_id = null;

                return values;
            }
        },
        'ExperienceSupplier': {
            admin: false,
            adapt: (values) => {
                values.user_id = null;

                return values;
            }
        },
        'Configuration': {
            admin: true,
            keys: ['modello','tipo'],
            adapt: (values) => {
                return values;
            }
        },
        'Environment': {
            admin: true,
            keys: ['name'],
            adapt: (values) => {
                return values;
            }
        },
        'Content': {
            admin: true,
            keys: ['tid'],
            adapt: (values) => {
                return values;
            }
        }
    }

    loginMode:'otp'|'default' = 'otp';
    loginStatus:'success'|'error' = null;

    loginDefaultUsername:string = null;
    loginDefaultPassword:string = null;

    loginOtpContact:string = null;
    loginOtpSendStatus:'success'|'error' = null;
    loginOtpCode:string = null;
    loginOtpVia:'email'|'phone' = null;

    authToken:string = null;

    resourceData:Array<{ value: string, text:string, data:any }> = null;
    resourceColumns:Array<string> = null;

    isLoading = false;

    constructor(private http:HttpClient, private dataService:DataService, private authService:AuthService) { }

    ngOnInit() {
        this.loginDefaultUsername = this.authService.user.email || this.authService.user.phone;
    }

    ngOnChanges(): void {
        if(this.adminMode) {
            this.loginMode = 'default';
        }

        if(this.resourceOptions == null) {
            this.resourceOptions = [];

            for(let i in this.resourceAdapters) {
                if((this.adminMode && this.resourceAdapters[i].admin) || (!this.adminMode && !this.resourceAdapters[i].admin)) {
                    this.resourceOptions.push({ value: i, text: i })
                }
            }
        }

        if(!this.adminMode) {
            if(this.APIUrlOptions != null && this.APIUrlOptions.length == 1) {
                this.APIUrl = this.APIUrlOptions[0].value;
            }
    
            if(this.resourceOptions != null && this.resourceOptions.length == 1) {
                this.resourceId = this.resourceOptions[0].value;
            }
        }
    }
    
    requestOTP() {
        this.loginOtpSendStatus = null;

        this.isLoading = true;

        this.http.post(this.APIUrl + '/otp/' + this.loginOtpVia + '/' + this.dataService.cleanRecipient(this.loginOtpContact, this.loginOtpVia), null).subscribe(() => {
            this.isLoading = false;
            this.loginOtpSendStatus = 'success'
        }, () =>{
            this.isLoading = false;
            this.loginOtpSendStatus = 'error'
        })
    }   

    onLoginKeyup(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            this.doLogin();
        }
    }

    doLogin() {
        this.authToken = null;
        this.loginStatus = null;

        if(this.loginMode == 'otp') {
            this.isLoading = true;

            this.http.post(this.APIUrl + '/otp/login', { contact: this.dataService.cleanRecipient(this.loginOtpContact, this.loginOtpVia), otp: this.loginOtpCode }).subscribe((data: any) => {
                this.isLoading = false;
                this.loginStatus = 'success';
                this.authToken = data.access_token;

                this._afterLogin();
            }, () => {
                this.loginStatus = 'error';
                this.isLoading = false;
            })
        }
        else if(this.loginMode == 'default') {
            this.isLoading = true;

            this.http.post(this.APIUrl + '/login', { email: this.loginDefaultUsername, password: this.loginDefaultPassword }).subscribe((data: any) => {
                this.isLoading = false;
                this.loginStatus = 'success';
                this.authToken = data.access_token;

                this._afterLogin();
            }, () => {
                this.loginStatus = 'error';
                this.isLoading = false;
            })
        }
    }

    private _afterLogin() {
        // sta cosa non è un gran che

        if(this.adminMode && this.resourceId != null) {
            this.loadResource();
        }
    }

    loadResource() {
        this.resourceData = null;
        this.elementIdList = [];

        let _cURL = this.APIUrl + '/data/' + this.dataService.normalizeResourceName(this.resourceId) + '?limit=1000';

        this.isLoading = true;
        this.http.get(_cURL, { 
            headers: {
                'Authorization': 'Bearer ' + this.authToken
            }
        }).subscribe((data:Array<any>) => {
            this.isLoading = false;
            this.resourceData = [];
            this.resourceColumns = [];

            if(data != null && data.length > 0) {
                for(let i in data[0]) {
                    this.resourceColumns.push(i)
                }

                for(let _column of ['title','name','id']) {
                    let _index = this.resourceColumns.indexOf(_column);

                    if(_index != -1) {
                        let _items = this.resourceColumns.splice(_index, 1)
                        this.resourceColumns.unshift(_items[0])
                    }
                }

                for(let _cElement of data) {
                    let _cText = _cElement[this.resourceColumns[0]];

                    if(this.resourceColumns.length > 1) {
                        _cText += ' - ' + _cElement[this.resourceColumns[1]]
                    }
    
                    this.resourceData.push({
                        value: _cElement.id,
                        text: _cText,
                        data: _cElement
                    })
                }
            }
        }, () => {
            this.isLoading = false;
        })
    }

    isElementSelected(element:string) {
        return this.elementIdList.indexOf(element) != -1;
    }

    setElementSelected(element:string, val:boolean) {
        if(val) {
            if(this.elementIdList.indexOf(element) == -1) {
                this.elementIdList.push(element);
            }
        }
        else {
            let _index = this.elementIdList.indexOf(element);

            if(_index != -1) {
                this.elementIdList.splice(_index, 1);
            }
        }
    }

    isResourceKeyColumn(col:string) {
        return this.resourceAdapters[this.resourceId].keys != null && this.resourceAdapters[this.resourceId].keys.indexOf(col) != -1;
    }

    private _getResourceItemKey(data:any)  {
        let _retVal = null;

        if(this.resourceAdapters[this.resourceId].keys != null) {
            let _retList = []
            
            for(let _key of this.resourceAdapters[this.resourceId].keys) {
                _retList.push(data[_key])
            }

            _retVal = _retList.join('|')
        }

        return _retVal;
    }

    loadTotal:number = null;
    loadCurrent:number = null;

    async importElements(all?:boolean, clear?:boolean) {
        this.isLoading = true;

        this.loadTotal = null;
        this.loadCurrent = null;

        if(clear) {
            let _currentResource = await new Promise<Array<any>>((resolve) => {
                this.dataService.getResourceData(this.resourceId, { limit: 5000 }).subscribe(data => {
                    resolve(data)
                });
            })

            if(_currentResource != null) {
                this.loadTotal = _currentResource.length;
                this.loadCurrent = 0;

                for(let _item of _currentResource) {
                    await new Promise<void>((resolve) => {
                        this.dataService.deleteElement(this.resourceId, _item.id).subscribe(data => {
                            resolve();
                        });
                    })

                    this.loadCurrent++;
                }
            }
        }

        let _currentResourceByKey:{ [key:string]: any } = {}

        if(this.resourceAdapters[this.resourceId].keys != null) {
            await new Promise<void>((resolve) => {
                this.dataService.getResourceData(this.resourceId, { limit: 5000 }).subscribe(data => {
                    if(data != null) {
                        for(let _item of data) {
                            _currentResourceByKey[this._getResourceItemKey(_item)] = _item;
                        }
                    }

                    resolve()
                });
            })
        }

        this.loadTotal = (all ? this.resourceData.length : this.elementIdList.length);
        this.loadCurrent = 0;

        for(let i = this.resourceData.length - 1; i >= 0; i--) {
            let _cElement = this.resourceData[i];

            if(all || this.elementIdList.indexOf(_cElement.value) != -1) {
                let _cURL = this.APIUrl + '/data/' + this.dataService.normalizeResourceName(this.resourceId) + '/' + _cElement.value;
    
                await new Promise<boolean>((resolve) => {
                    this.http.get(_cURL, { 
                        headers: {
                            'Authorization': 'Bearer ' + this.authToken
                        }
                    }).subscribe((data:any) => {
                        let _cElementData = JSON.parse(JSON.stringify(data))
                        _cElementData.id = null;
                        _cElementData.updated_at = null;
                        _cElementData.created_at = null;
                
                        if(_cElementData.translations != null) {
                            for(let _cTransl of _cElementData.translations) {
                                _cTransl.id = null;
                                _cTransl.updated_at = null;
                                _cTransl.created_at = null;
                            }
                        }
                
                        _cElementData = this.resourceAdapters[this.resourceId].adapt(_cElementData);
                        _cElementData.imported = _cURL;
    
                        let _currentElement = _currentResourceByKey[this._getResourceItemKey(_cElementData)];

                        if(_currentElement != null) {
                            this.dataService.putElementData(this.resourceId, _currentElement.id, _cElementData).subscribe(data => {
                                resolve(data != null);
                            });
                        }
                        else {
                            this.dataService.postResourceData(this.resourceId, _cElementData).subscribe(data => {
                                resolve(data != null);
                            });
                        }
                    }, () => {
                        resolve(false);
                    })
                })
            }
        }

        setTimeout(() => {
            this.isLoading = false;
        }, 500)
    }

    isSomeElementsSelected() {
        return this.elementIdList.length > 0;
    }

    isAllElementsSelected() {
        return this.elementIdList.length == this.resourceData.length;
    }

    isNoElementSelected() {
        return this.elementIdList.length == 0;
    }

    setAllElementsSelected(val:boolean) {
        this.elementIdList = [];

        if(val) {
            for(let _option of this.resourceData) {
                this.elementIdList.push(_option.value)
            }
        }
    }
}
