import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService, RealmData } from '../../services/environment.service';

@Component({
  selector: 'app-pg-realm-invite',
  templateUrl: './pg-realm-invite.component.html',
  styleUrls: ['./pg-realm-invite.component.scss']
})
export class PgRealmInviteComponent implements OnInit {

    constructor(private route:ActivatedRoute, private authService:AuthService, private environmentService:EnvironmentService) { }

    realmData:RealmData = null;

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.realmData = this.environmentService.getRealm(params.realm)
        })
    }

    isLoggedIn() {
        return this.authService.isLoggedIn()
    }

    getRealmStatus() {
        for(let _realm of this.authService.user.availableRealms) {
            if(_realm.id == this.realmData.id) return 'available'
        }

        for(let _realm of this.authService.user.unavailableRealms) {
            if(_realm.id == this.realmData.id) return 'unavailable'
        }

        for(let _realm of this.authService.user.pendingRealms) {
            if(_realm.id == this.realmData.id) return 'pending'
        }
    }

    isRequestingAddRealm = false;

    requestAddRealm() {
        this.isRequestingAddRealm = true;

        this.authService.requestAddRealm(this.realmData.id).then((data) => {
            this.isRequestingAddRealm = false;
        })
    }
}
