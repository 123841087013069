import { Component, Input, OnChanges } from '@angular/core';
import { PgCalendar, PGCalendarElement } from './pg-calendar';
import { Router } from '@angular/router';
import { ConfigProfileRoleActionsExtended, ConfigService } from '../../services/config.service';
import { DataFilter, DataService } from '../../services/data.service';
import { CachedDataElements } from '../../models/cached-data';
import { LocalizationService } from '../../services/localization.service';
import { SemanticsService } from '../../services/semantics.service';
import { PgSemanticView } from '../../pg-semantic-view/pg-semantic-view-common';

@Component({
    selector: 'app-pg-calendar-resource',
    templateUrl: './pg-calendar.component.html',
    styleUrls: ['./pg-calendar.component.scss']
})

export class PgCalendarResourceComponent extends PgCalendar implements OnChanges {

    @Input()
    resourceId:string = null;

    @Input()
    relatedResource:string = null;

    @Input()
    relatedElement:string = null;

    @Input()
    dateField:string = null;

    @Input()
    dataFilter:Array<DataFilter> = null;

    actions:ConfigProfileRoleActionsExtended = null;
    canView = false;

    cachedElements: CachedDataElements = null;

    constructor(localizationService: LocalizationService, private dataService: DataService, private configService:ConfigService, private semanticsService:SemanticsService, private router:Router) {
        super(localizationService);
    }

    ngOnChanges() {
        this.actions = this.configService.getResourceActionsExtended(this.resourceId);
        this.canView = (this.relatedResource != null && this.relatedElement != null) ? this.actions.view : this.actions.list;

        this.cachedElements = new CachedDataElements(this.dataService);

        this.generateCalendar();
    }

    private _formatDate(date:Date) {
        let _retVal = date.getFullYear().toString() + '-';

        if(date.getMonth() < 9) _retVal += '0'
        _retVal += (date.getMonth() + 1).toString() + '-'
        if(date.getDate() < 10) _retVal += '0'
        _retVal += date.getDate().toString();

        return _retVal;
    }

    getData():Promise<Array<PGCalendarElement>> {
        return new Promise<Array<PGCalendarElement>>((resolve, reject) => {
            if(this.canView) {
                let _cDataFilter = [];
                if(this.dataFilter != null) {
                    for(let _cFilter of this.dataFilter) {
                        _cDataFilter.push(_cFilter);
                    }
                }

                let _cDateBegin = new Date(this.year, this.month, 1);
                let _cDateEnd = new Date(this.year, this.month + 1, 0, 23, 59, 59, 999);

                let _cDateBeginString = _cDateBegin.toISOString();
                let _cDateEndString = _cDateEnd.toISOString();

                let _cField = this.configService.getResourceFieldTypes(this.resourceId)[this.dateField];

                if(_cField != null && _cField.type == 'date') {
                    _cDateBeginString = this._formatDate(_cDateBegin)
                    _cDateEndString = this._formatDate(_cDateEnd)
                }

                _cDataFilter.push(new DataFilter(this.dateField, 'between', [_cDateBeginString, _cDateEndString]))

                this.dataService.getResourceData(this.resourceId, {
                    filter: _cDataFilter
                }, this.relatedResource, this.relatedElement).subscribe(data => {

                    let _retVal:Array<PGCalendarElement> = [];

                    for(let _cData of data) {
                        let _cElement = new PGCalendarElement();

                        _cElement.id = _cData.id;

                        let _cRoleView = new PgSemanticView(this.dataService, this.configService, this.semanticsService); // TODO: verificare la correttezza di questa cosa
                        _cRoleView.resourceId = this.resourceId;
                        _cRoleView.useCache = this.cachedElements;
                        _cRoleView.elementData = _cData;
                        _cRoleView.ngOnInit();

                        _cElement.start = _cRoleView.roles.start;
                        _cElement.end = _cRoleView.roles.end;
                        _cElement.title = _cRoleView.roles.title;
                        _cElement.description = _cRoleView.roles.description;
                        
                        _retVal.push(_cElement);
                    }

                    resolve(_retVal);
                });
            }
            else reject();
        })
    }

    getAvailableActions() {
        return this.actions;
    }

    getEventStatusColor(elementData:PGCalendarElement) {
    }

    getEventStatusIcon(elementData:PGCalendarElement) {
    }

    getEventStatusTitle(elementData:PGCalendarElement) {
    }

    onElementAction(action:string, elementData:PGCalendarElement) {
        switch(action) {
            case 'book':
                this.router.navigate(['/main/book/' + this.resourceId + '/' + elementData.id])
                break;
            case 'view': 
            case 'edit': 
                this.router.navigate(['/backoffice/form/' + this.resourceId + '/' + elementData.id])
                break;
            case 'delete':
                this.router.navigate(['/backoffice/delete/' + this.resourceId + '/' + elementData.id])
                break;
        }
    }
}
