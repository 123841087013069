import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveyData, SurveyDataQuestion } from '../../models/survey.model';

@Component({
    selector: 'app-pg-survey-editor',
    templateUrl: './pg-survey-editor.component.html',
    styleUrls: ['./pg-survey-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgSurveyEditorComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgSurveyEditorComponent),
        multi: true,
    }]
})
export class PgSurveyEditorComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() availableLanguages:Array<string>;
    @Input() selectedLanguage:string;

    value:SurveyData = null;
    
    constructor(private modalService:NgbModal) { }

    ngOnInit(): void {
    }

    onValueChange() {        
        if(this._onTouched) this._onTouched();
        if(this._onChange) {
            let _cData = JSON.parse(this.value.toData());

            for(let i = _cData.questions.length - 1; i >= 0; i--) {
                if((_cData.questions[i].text == null || _cData.questions[i].text == '') && _cData.questions[i].type == null) {
                    _cData.questions.splice(i, 1)
                }
            }

            this._onChange(JSON.stringify(_cData));
        }
    }

    getQuestionText(item:SurveyDataQuestion, language?:string) {
        if(item.text == null || typeof item.text == 'string' || this.availableLanguages == null) {
            return item.text;
        }
        else {
            if(language == null) language = this.selectedLanguage;
            return item.text[language];
        }
    }

    setQuestionText(item:SurveyDataQuestion, value:string, language?:string) {
        if(this.availableLanguages == null) {
            item.text = value;
        }
        else {
            if(language == null) language = this.selectedLanguage;

            if(item.text == null || typeof item.text == 'string') {
                item.text = {};
            }
            
            item.text[language] = value;
        }
    }

    addQuestion() {
        this.value.questions.push({ text: null, type: 'boolean' })
        this.onValueChange();
    }

    removeQuestion(item:SurveyDataQuestion) {
        let _cIndex =  this.value.questions.indexOf(item);

        if(_cIndex != -1) {
            this.value.questions.splice(_cIndex, 1)
            this.onValueChange();
        }

        if(this.value.questions.length == 0) {
            this.value.questions.push({
                text: null,
                type: 'boolean'
            })
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        this.value = new SurveyData(obj);

        if(this.value.questions.length == 0) {
            this.value.questions.push({
                text: null,
                type: 'boolean'
            })
        }
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
    };
}
