import { Component, HostListener, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EFFilterData, EFPOI, EFProduct, EFProductType, EFSupplier } from '../../models/experience.model';
import { EFDataService } from '../../services/ef-data.service';
import { EnvironmentService } from '../../services/environment.service';
import { LocalizationService } from '../../services/localization.service';
import { EfFilterComponent } from '../elements/ef-filter/ef-filter.component';
import { EfMapComponent } from '../elements/ef-map/ef-map.component';
import { AnalyticsService } from '../../services/analytics.service';
import { Observable } from 'rxjs';

export type EfMainAppMode = 'web'|'kiosk'|'hybrid'|'iframe'|'iframekiosk';

export class EfMainExternalFilter {
    language?:string
    view?:string
    tags?:string
    type?:string
    category?:string
    product?:string
    groups?:string
    ids?:string
}

export class EfMainFragment {
    filter?:Partial<EFFilterData>
    view?:string
    product?:string
}

@Component({
  selector: 'app-ef-main',
  templateUrl: './ef-main.component.html',
  styleUrls: ['./ef-main.component.scss']
})
export class EfMainComponent implements OnInit {

    constructor(private dataService:EFDataService, private localizationService:LocalizationService, private environmentService:EnvironmentService, private analyticsService:AnalyticsService) {}

    showScreensaver = false;

    private _inactivityTimeout = null;

    useBookingQR = false;

    @Input() appMode:EfMainAppMode;
    @Input() externalFilter:EfMainExternalFilter;
    @Input() productsList:Array<EFProduct>;

    @Input() fragment:EfMainFragment;
    @Output() fragmentChange = new EventEmitter<EfMainFragment>();

    @Input() hasBack:boolean;
    @Output() goBack = new EventEmitter<void>()

    resetInactivity() {
        this.showScreensaver = false;

        // perché questo è qui e non nella init?
        this.useBookingQR = this.appMode == 'kiosk' || this.appMode == 'iframekiosk';

        if(this.appMode == 'kiosk') {
            if(this._inactivityTimeout != null) clearTimeout(this._inactivityTimeout);

            this._inactivityTimeout = setTimeout(() => {
                this._inactivityTimeout = null;
                this.showScreensaver = true;
            }, 1000 * 60 * 5)
        }
    }

    isLoading = false;

    suppliersData:Array<EFSupplier> = null;
    POIsData:Array<EFPOI> = null;
    productsData:{ [type:string]: Array<EFProduct> } = null;
    filteredProductsData:Array<EFProduct> = null;
    mapFilteredProductsData:Array<EFProduct> = null;

    filterData = new EFFilterData();

    availableTypes:Array<EFProductType> = [];

    currentSection:string = 'map';

    lockFilter = false;
    lockFilterType = false;

    isPortrait = false;

    @HostListener('window:resize', [])
    onResize() {
        this.isPortrait = window.innerWidth < window.innerHeight;
    }

    ngOnInit(): void {
        this.onResize();

        if(this.appMode == null) {
            this.appMode = this.environmentService.environment.AppMode;
        }

        this.lockFilter = false;
        this.lockFilterType = false;

        this.filterData.type = null;

        this.filterData.tags = null;

        this.filterData.category = {}
        this.filterData.subcategory = {}

        this.filterData.language = {}

        this.isLoading = true;

        if(this.appMode == 'web' || this.appMode == 'iframe' || this.appMode == 'iframekiosk') {
            console.log('externalFilter', this.externalFilter)
            console.log('fragment', this.fragment)

            this._handleExternalFilter().then((filtered) => {
                this._handleFilterFragment();
                this._loadProducts(filtered.types, filtered.groups).then(() => {
                    if(this.fragment != null && this.fragment.product != null) this.onShowProduct(this.fragment.product);

                    this.resetInactivity()
                }, () => {});
            });
        }
        else {
            this._loadProducts().then(() => {
                this.resetInactivity()
            }, () => {});
        }
    }


    private _setDefaultFilterLanguages() {
        for(let i in this.filterData.language) {
            
            this.filterData.language[i] = null;

            // Rimossi i filtri automatici per lingua

            /*
            for(let j in this.filterData.language[i]) {
                this.filterData.language[i][j] = false;
            }
    
            this.filterData.language[i]['en_EN'] = true;
            if(this.filterData.language[i][this.localizationService.currentLanguage] != null) this.filterData.language[i][this.localizationService.currentLanguage] = true;
            */
        }
    }

    @ViewChild('filterComponent') filterComponent:EfFilterComponent;

    private _checkLoadProducts(filteredTypes?:Array<string>, filteredGroups?:Array<string>) {
        if(this.productsList != null) {
            return new Observable<{ [type:string]: Array<EFProduct> }>((observer) => {
                setTimeout(() => {
                    let _retVal:{ [type:string]: Array<EFProduct> } = {}

                    for(let _product of this.productsList) {
                        if(_retVal[_product.type] == null) _retVal[_product.type] = [];

                        _retVal[_product.type].push(_product);
                    }

                    observer.next(_retVal);
                    setTimeout(() => {
                        observer.complete();
                    }, 100)
                }, 100)
            })
        }
        else {
            return this.dataService.listProducts(filteredTypes, filteredGroups)
        }
    }

    isLoadingProducts = false;

    presetCategories:{ [type:string]: boolean } = {};

    private _loadProducts(filteredTypes?:Array<string>, filteredGroups?:Array<string>) {
        return new Promise<void>((resolve, reject) => {

            this.isLoadingProducts = true;
    
            this._checkLoadProducts(filteredTypes, filteredGroups).subscribe((data) => {
                this.productsData = data;
    
                for(let i in this.productsData) {
                    if(this.productsData[i] != null && this.productsData[i].length > 0 && this.availableTypes.indexOf(i as any) == -1) {
                        this.availableTypes.push(i as any);
                    }
                }
    
                console.log('listProducts progress', this.availableTypes)

                if(this.filterData.type == null) {
                    for(let _cType of ['experience','host','eatery','poi','itinerary','article','event','utility'] as any) {
                        if(typeof this.productsData[_cType] != 'undefined') {
                            if(this.productsData[_cType] == null) break;
                            else {
                                if(this.productsData[_cType].length > 0) {
                                    this.filterData.type = _cType as any;
                                    break;
                                }
                            }
                        }
                    }
                }
                
                for(let i in this.productsData) {
                    if(this.productsData[i] != null) {
                        if(this.filterData.category[i] != null) { 
                            // categorie presettate da filtro esterno
                            this.presetCategories[i] = true;
                        }
                        else {
                            let _costsList:Array<number> = [];

                            for(let _product of this.productsData[i]) {
                                if(_product.category != null && _product.category != '') {
                                    let _categoryList = _product.category.split(',')
                                    let _subcategoriesList = null;
                                    if(_product.subcategory != null && _product.subcategory != '') _subcategoriesList = _product.subcategory.split(',')

                                    for(let _category of _categoryList) {
                                        if(this.filterData.category[i] == null) {
                                            this.filterData.category[i] = {}
                                        }
    
                                        this.filterData.category[i][_category] = false;

                                        if(_subcategoriesList != null) {
                                            for(let _subcategory of _subcategoriesList) {
                                                if(this.filterData.subcategory[i] == null) {
                                                    this.filterData.subcategory[i] = {}
                                                }

                                                if(this.filterData.subcategory[i][_category] == null) {
                                                    this.filterData.subcategory[i][_category] = {}
                                                }

                                                this.filterData.subcategory[i][_category][_subcategory] = false;
                                            }
                                        }
                                    }
                                }

                                if(_product.stars != null) {
                                    if(this.filterData.stars[i] == null) this.filterData.stars[i] = {}
                                    
                                    this.filterData.stars[i][_product.stars] = false;
                                }

                                if(_product.cost_list != null) {                                    
                                    for(let _item of _product.cost_list) { // inserisco i range come singoli prezzi
                                        _costsList.push(_item)
                                    }
                                }
                            }

                            if(_costsList.length > 0) {
                                _costsList.sort((a, b) => {
                                    if(a < b) return -1;
                                    else if(a > b) return 1;
                                    else return 0;
                                })

                                // prendo il più basso e il più alto e divido in 5 segmenti

                                let _segments = 5;

                                let _min = Math.floor(_costsList[0]);
                                let _max = _costsList[_costsList.length - 1];

                                let _diff = _max - _min;

                                let _segmentDiff = Math.ceil(_diff / _segments);

                                this.filterData.cost[i] = [];

                                for(let j = 0; j < _segments; j++) {
                                    this.filterData.cost[i].push({
                                        from: _min + _segmentDiff * j,
                                        to: _min + _segmentDiff * (j + 1),
                                        selected: false
                                    })
                                }
                            }
                        }

                        let _productLanguages:Array<string> = [];
    
                        for(let _cProduct of this.productsData[i]) {
                            for(let j of _cProduct.languages) {
                                if(_productLanguages.indexOf(j) == -1) {
                                    _productLanguages.push(j)
                                }
                            }
                        }

                        _productLanguages.sort();

                        for(let _language of _productLanguages) {
                            if(this.filterData.language[i] == null) {
                                this.filterData.language[i] = {}
                            }

                            this.filterData.language[i][_language] = true;
                        }
                    }
                }

                this._setDefaultFilterLanguages();
    
                if(this.appMode != 'hybrid') {
                    if(this.isLoading && this.filterData.type != null && this.productsData[this.filterData.type] != null) {
                        console.log('Selected tab loaded', this.filterData.type)
    
                        this.isLoading = false;
                        this.onFilterDataChange(true);
    
                        setTimeout(() => {
                            this.centerMap()
                        }, 10)
                    }
                }
    
                if(this.filterComponent != null) {
                    this.filterComponent.ngOnInit();
                }
            }, 
            () => {}, 
            () => {            
                this.isLoadingProducts = false;
    
                console.log('listProducts complete')
                let _missingGeolocation:Array<EFProduct> = [];
    
                for(let i in this.productsData) {
                    for(let _cProduct of this.productsData[i]) {
                        if(_cProduct.geolocation == null) {
                            _missingGeolocation.push(_cProduct)
                        }
                    }
                }
    
                if(_missingGeolocation.length > 0) {
                    let _cText = '';
                    
                    for(let _cProduct of _missingGeolocation) {
                        if(_cText != '') _cText += '\n'
    
                        if(_cProduct.base_api != null) {
                            _cText += _cProduct.base_api.replace(/^[^/]*\/\//, '').replace(/\/.*$/, '') + ' '
                        }
    
                        _cText += _cProduct.id + ' - ' + _cProduct.title
                    }
    
                    console.log('Products missing geolocation: ' + _cText)
                }
    
                console.log('Available types', this.availableTypes)
                console.log('Available categories', this.filterData.category)
                console.log('Available languages', this.filterData.language)
    
                this.dataService.listSuppliers().subscribe((data) => {
                    this.suppliersData = data;
    
                    console.log('listSuppliers complete')

                    this.dataService.listPOIs().subscribe((data) => {
                        this.POIsData = data;
        
                        console.log('listPoIs complete')

                        if(this.isLoading) {
                            this.isLoading = false;
                            this.onFilterDataChange();
                            
                            setTimeout(() => {
                                this.centerMap()
                            }, 10)
                        }
    
                        resolve();
                    })
                })
            })
        })
    }

    private _checkExternalLanguage(lang:string) {
        if(lang != null) {
            return this.localizationService.setLanguage(lang)
        }
        else {
            return new Promise((resolve) => { 
                setTimeout(() => {
                    resolve(null); 
                }, 100)
            })
        }
    }

    private _externalFilterArrayOrSplit(val:string|Array<string>) {
        if(typeof val == 'string') {
            if(val.startsWith('[') && val.endsWith(']')) {
                try {
                    return JSON.parse(val)
                }
                catch(ex) {
                    return val.split(/\s*,\s*/);
                }
            }
            else {
                return val.split(/\s*,\s*/);
            }
        }
        else return val;
    }

    private _handleExternalFilter() {
        return new Promise<{ types: Array<string>, groups: Array<string> }>((resolve) => {    
            if(this.externalFilter == null) {
                resolve({ types: null, groups: null });
            }
            else {
                console.log('External filter', this.externalFilter)

                this.analyticsService.sendEvent('externalFilter', this.externalFilter)

                this._checkExternalLanguage(this.externalFilter.language).then(() => {
                    if(this.externalFilter.view != null) this.currentSection = this.externalFilter.view;
    
                    let _filteredGroups:Array<string> = null;

                    if(this.externalFilter.groups != null) {
                        _filteredGroups = this._externalFilterArrayOrSplit(this.externalFilter.groups);
                        this.filterData.groups = JSON.parse(JSON.stringify(_filteredGroups))
                    }

                    let _filteredTypes:Array<string> = null;

                    if(this.externalFilter.type != null) {
                        _filteredTypes = this._externalFilterArrayOrSplit(this.externalFilter.type);

                        this.filterData.type = _filteredTypes[0] as any;

                        if(_filteredTypes.length == 1) {
                            this.lockFilterType = true;
                        }
                    }
        
                    if(this.externalFilter.tags != null) {
                        if(this.filterData.type == null) {
                            _filteredTypes = ['experience'];
                            this.filterData.type = 'experience';
                        }

                        this.lockFilterType = true;

                        this.filterData.tags = this._externalFilterArrayOrSplit(this.externalFilter.tags);
                    }
    
                    if(this.externalFilter.category != null) {
                        if(this.filterData.type == null) {
                            _filteredTypes = ['experience'];
                            this.filterData.type = 'experience';
                        }
                        
                        this.lockFilterType = true;

                        this.filterData.category[this.filterData.type] = {}

                        let _filterCategories = this._externalFilterArrayOrSplit(this.externalFilter.category)

                        for(let _cCategory of _filterCategories) {
                            this.filterData.category[this.filterData.type][_cCategory] = true;
                        }
        
                        if(_filterCategories.length == 1) {
                            this.lockFilter = true;
                        }
                    }

                    if(this.externalFilter.ids != null) {
                        this.lockFilterType = true;
                        this.lockFilter = true;
                        this.filterData.ids = this._externalFilterArrayOrSplit(this.externalFilter.ids)
                    }
    
                    if(this.externalFilter.product != null) this.onShowProduct(this.externalFilter.product)

                    console.log('Filter status', {
                        filterData: this.filterData,
                        lockFilterType: this.lockFilterType,
                        lockFilter: this.lockFilter
                    })

                    resolve({ types: _filteredTypes, groups: _filteredGroups });
                });
            }
        })
    }

    private _handleFilterFragment() {
        if(this.fragment != null) {
            if(this.fragment.view != null) this.currentSection = this.fragment.view;

            if(this.fragment.filter != null) {
                for(let i in this.fragment.filter) {
                    this.filterData[i] = this.fragment.filter[i]
                }
            }

            if(this.fragment != null && this.fragment.product != null) this.onShowProduct(this.fragment.product)
        }
    }

    private _generateFragment() {
        this.fragment = {
            view: this.currentSection,
            filter: this.filterData,
            product: this.modalProductTarget == null ? null : this.modalProductTarget.id
        }

        this.fragmentChange.emit(this.fragment) 
    }

    mapBounds:google.maps.LatLngBounds = null;

    private _mapBoundsTimeout = null;

    onMapBoundsChange(val:google.maps.LatLngBounds) {
        if(this._mapBoundsTimeout != null) {
            clearTimeout(this._mapBoundsTimeout)
        }

        this._mapBoundsTimeout = setTimeout(() => {
            this._mapBoundsTimeout = null;

            this.mapBounds = val;

            this.mapFilteredProductsData = []

            let _sortValue:{ [id:string]: number|string } = {}

            let _mapCenter = [
                this.mapBounds.getSouthWest().lat() + (this.mapBounds.getNorthEast().lat() - this.mapBounds.getSouthWest().lat()) / 2,
                this.mapBounds.getSouthWest().lng() + (this.mapBounds.getNorthEast().lng() - this.mapBounds.getSouthWest().lng()) / 2,
            ]

            for(let _product of this.filteredProductsData) {
                if(_product.geolocation != null) {
                    if(_product.geolocation.coordinates[0] <= this.mapBounds.getNorthEast().lat() && _product.geolocation.coordinates[1] <= this.mapBounds.getNorthEast().lng() && 
                        _product.geolocation.coordinates[0] >= this.mapBounds.getSouthWest().lat() && _product.geolocation.coordinates[1] >= this.mapBounds.getSouthWest().lng()) {

                        this.mapFilteredProductsData.push(_product)

                        if(this.environmentService.environment.ExperienceFinderSort == 'alphabetical') {
                            _sortValue[_product.id] = this.getTranslated(_product, 'title')
                        }
                        else if(this.environmentService.environment.ExperienceFinderSort == 'shuffle') {
                            _sortValue[_product.id] = Math.random();
                        }
                        else {
                            _sortValue[_product.id] = Math.sqrt(Math.pow(_product.geolocation.coordinates[0] - _mapCenter[0], 2) + Math.pow(_product.geolocation.coordinates[1] - _mapCenter[1], 2))
                        }
                    }
                }
            }

            this.mapFilteredProductsData.sort((a, b) => {
                let _aVal = _sortValue[a.id]
                let _bVal = _sortValue[b.id]

                if(_aVal < _bVal) return -1;
                else if(_aVal > _bVal) return 1;
                else return 0;
            })

        }, 250)
    }

    onFilterDataChange(isInit?:boolean) {
        this.applyFilter(isInit);
    }

    @ViewChild('efMapComponent') efMapComponent:EfMapComponent;

    goToSection(section:string) {
        this.currentSection = section;
        this.applyFilter();
    }

    private _filterToEventData() {
        let _eventData:any = {};
        let _type = this.filterData.type;

        _eventData.type = _type

        let _categoryList = []

        if(this.filterData.category[_type] != null) {
            if(!this.filterData.allCategories) {
                let _allIn = true;

                for(let i in this.filterData.category[_type]) {
                    if(this.filterData.category[_type][i]) {
                        _categoryList.push(i)
                    }
                    else {
                        _allIn = false;
                    }
                }
    
                if(!_allIn) {
                    _eventData.category = _categoryList;
                }
            }
        }

        if(!this.filterData.allSubcategories) {
            if(this.filterData.subcategory[_type] != null) {
                let _subcategoryList = [];

                let _allIn = true;

                for(let i in this.filterData.subcategory[_type]) {
                    if(this.filterData.allCategories || _categoryList.indexOf(i) != -1) {
                        for(let j in this.filterData.subcategory[_type][i]) {
                            if(this.filterData.subcategory[_type][i][j]) {
                                _subcategoryList.push(j)
                            }
                            else {
                                _allIn = false;
                            }
                        }
                    }
                }

                if(!_allIn) {
                    _eventData.subcategory = _subcategoryList;
                }
            }
        }

        if(this.filterData.language[_type] != null) {
            let _languageList = []

            for(let i in this.filterData.language[_type]) {
                if(this.filterData.language[_type][i]) {
                    _languageList.push(i)
                }
            }

            if(_languageList.length != Object.keys(this.filterData.language[_type]).length) {
                _eventData.language = _languageList;
            }
        }

        if(this.filterData.groups != null && this.filterData.groups[_type] != null && this.filterData.groups[_type].length > 0) {
            _eventData.groups = this.filterData.groups[_type];
        }

        if(this.filterData.tags != null && this.filterData.tags.length > 0) {
            _eventData.tags = this.filterData.tags;
        }

        if(this.filterData.ids != null && this.filterData.ids.length > 0) {
            _eventData.ids = this.filterData.ids;
        }

        if(this.filterData.interval != null && (this.filterData.interval.from != null || this.filterData.interval.to != null)) {
            _eventData.interval = this.filterData.interval;
        }

        return _eventData;
    }

    private _applyFilterSendEventTimeout = null;

    applyFilter(isInit?:boolean) {
        this.filteredProductsData = [];

        for(let i in this.productsData) {
            if(this.productsData[i] != null) {
                for(let _product of this.productsData[i]) {
                    this.filteredProductsData.push(_product)
                }
            }
        }

        let _eventData = this._filterToEventData();

        clearTimeout(this._applyFilterSendEventTimeout) // questo delay è per evitare di mandare un sacco di eventi se l'utente smanetta con gli switch dei filtri
        this._applyFilterSendEventTimeout = setTimeout(() => {
            this.analyticsService.sendEvent('applyFilter', _eventData)
        }, 2500)

        this.filteredProductsData = this.dataService.applyProductsFilter(this.filteredProductsData, this.filterData, this.presetCategories)

        this.mapFilteredProductsData = null;

        if(!isInit) {
            setTimeout(() => {
                this.centerMap();
            }, 100)

            this._generateFragment();
        }
    }

    centerMap() {
        if(this.efMapComponent != null) {
            this.efMapComponent.centerMap(true);
            if(this.mapBounds != null) this.onMapBoundsChange(this.mapBounds);
        }
    }

    modalProductPath:Array<string> = null;
    modalProductTarget:EFProduct = null;
    modalSupplierTarget:EFSupplier = null;
    modalPOITarget:EFPOI = null;

    isLoadingDetail = false;

    productsHighlight:Array<string> = []

    onShowProduct(product:string) {
        
        if(this.suppliersData != null && this.POIsData != null) {
            this.modalProductPath = product.split('/');

            let _productId = this.modalProductPath[this.modalProductPath.length - 1]

            this.productsHighlight = [_productId];

            this.modalProductTarget = null;
            this.modalSupplierTarget = null;
            this.modalPOITarget = null;
    
            for(let i in this.productsData) {
                for(let _cProduct of this.productsData[i]) {
                    if(_cProduct.id == _productId) {
        
                        this.isLoadingDetail = true;
        
                        this.dataService.getProductDetail(_cProduct).subscribe((data) => {
                            this.isLoadingDetail = false;
        
                            this.modalProductTarget = data;
                            if(_cProduct.analytics != null) this.analyticsService.sendEvent('showProduct', _cProduct.analytics)
        
                            if(_cProduct.supplier != null) {
                                for(let _cSupplier of this.suppliersData) {
                                    if(_cProduct.supplier == _cSupplier.id) {
                                        this.modalSupplierTarget = _cSupplier;
                                        break;
                                    }
                                }
                            }

                            if(_cProduct.poi_id != null) {
                                for(let _cPOI of this.POIsData) {
                                    if(_cProduct.poi_id == _cPOI.id) {
                                        this.modalPOITarget = _cPOI;
                                        break;
                                    }
                                }
                            }

                            this._generateFragment();
                        }, () => {
                            this.isLoadingDetail = false;
                        })
        
                        break;
                    }
                }
            }
        }
    }

    modalProductBack() {
        if(this.modalProductPath.length == 1) {
            this.modalProductPath = null;
            this.modalProductTarget = null;

            this.productsHighlight = null;
        }
        else {
            this.modalProductPath.pop();
            this.onShowProduct(this.modalProductPath.join('/'))
        }

        this._generateFragment();
    }

    getModalProductBackLabel() {
        if(this.modalProductPath.length == 1) {
            return this.localizationService.translate('experience-finder.ef-main.nav-back-' + this.currentSection)
        }
        else {
            let _productId = this.modalProductPath[this.modalProductPath.length - 2]

            for(let i in this.productsData) {
                for(let _cProduct of this.productsData[i]) {
                    if(_cProduct.id == _productId) {
                        return this.localizationService.translate('experience-finder.ef-main.nav-back-' + (_cProduct.type), { title: _cProduct.title })
                    }
                }
            }
        }
    }

    getCurrentProductsData(mapFiltered?:boolean) {
        return mapFiltered ? this.mapFilteredProductsData : this.filteredProductsData;
    }

    getTranslated(product:EFProduct, prop:string) {
        if(product.translations != null && product.translations[this.localizationService.currentLanguage] != null && product.translations[this.localizationService.currentLanguage][prop] != null) {
            return product.translations[this.localizationService.currentLanguage][prop]
        }
        else return product[prop]
    }
}
