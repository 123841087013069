import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QRCodeRenderersOptions, toCanvas } from 'qrcode';

@Component({
  selector: 'app-pg-qrcode',
  templateUrl: './pg-qrcode.component.html',
  styleUrls: ['./pg-qrcode.component.scss']
})
export class PgQrcodeComponent implements OnInit, AfterViewInit {

    @Input() text:string;
    @Input() options:QRCodeRenderersOptions;

    @ViewChild('QRCanvas') QRCanvas:ElementRef;

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        toCanvas(this.QRCanvas.nativeElement, this.text, this.options);
    }
}
