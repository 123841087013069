<div class="PGBookingsSlotSummary">
    <div *ngIf="slotStart != null" class="me-3">
        <i class="fa-regular fa-clock me-2"></i>

        <span>{{slotStart}}<ng-container *ngIf="slotEnd != null"> - {{slotEnd}}</ng-container></span>
    </div>

    <div *ngIf="getSlotCapacity(); let capacity" class="me-3 PGBookingsSlotSummary-Capacity">
        <i class="fa-regular fa-chair me-2"></i>

        <small class="PGBookingsSlotSummary-StatusList">
            <span>{{ 'pg-bookings-slot-summary.capacity' | locTranslate }}</span>
            <span>{{ capacity }}</span>&nbsp;
        </small>
    </div>

    <div *ngIf="hasSomeParticipants()" class="me-3 PGBookingsSlotSummary-Participants">
        <i class="fa-regular fa-users me-2"></i>

        <small class="PGBookingsSlotSummary-StatusList">
            <span>{{ 'pg-bookings-slot-summary.participants' | locTranslate }}</span>
            <span *ngIf="slotSummary.participants.pending > 0"> {{ 'pg-bookings-slot-summary.participants-pending' | locTranslate:{ num: slotSummary.participants.pending } }}</span>
            <span *ngIf="slotSummary.participants.available > 0" class="text-primary"> {{ 'pg-bookings-slot-summary.participants-available' | locTranslate:{ num: slotSummary.participants.available } }}</span>
            <span *ngIf="slotSummary.participants.confirmed > 0" class="text-success"> {{ 'pg-bookings-slot-summary.participants-confirmed' | locTranslate:{ num: slotSummary.participants.confirmed } }}</span>
        </small>
    </div>
    
    <div *ngIf="hasSomeBookings()" class="PGBookingsSlotSummary-Bookings">
        <i class="fa-regular fa-book me-2"></i>

        <small class="PGBookingsSlotSummary-StatusList">
            <span>{{ 'pg-bookings-slot-summary.bookings' | locTranslate }}</span>
            <span *ngIf="slotSummary.bookings.pending > 0">{{ 'pg-bookings-slot-summary.bookings-pending' | locTranslate:{ num: slotSummary.bookings.pending } }}</span>
            <span *ngIf="slotSummary.bookings.available > 0" class="text-primary">{{ 'pg-bookings-slot-summary.bookings-available' | locTranslate:{ num: slotSummary.bookings.available } }}</span>
            <span *ngIf="slotSummary.bookings.confirmed > 0" class="text-success">{{ 'pg-bookings-slot-summary.bookings-confirmed' | locTranslate:{ num: slotSummary.bookings.confirmed } }}</span>
            <span *ngIf="slotSummary.bookings.canceled > 0" class="text-danger">{{ 'pg-bookings-slot-summary.bookings-canceled' | locTranslate:{ num: slotSummary.bookings.canceled } }}</span>
        </small>
    </div>
</div>