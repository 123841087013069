export class TimetableDataHour {
    begin = '00:00'     // orario inizio slot
    end = '00:00'       // orario fine slot, se non c'è una slot duration definita esternamente
    days:Array<boolean> = [true,true,true,true,true,true,true] // flag per giorni della settimana in cui lo slot è attivo
}

export class TimetableDataDays {
    begin = { month: 1, day: 1 };   // data inizio periodo di validità
    end = { month: 1, day: 1 };     // data fine periodi di validità
    year?:number = null;            // anno di validità, se non è specificato l'evento è ciclico
}

export class TimetableData {
    slotDuration?:string = null;            // durata degli slot, se non è definito gli slot definisco un'ora di fine internamente
    hours:Array<TimetableDataHour> = [];    // orari degli slot
    days:Array<TimetableDataDays> = [];     // periodi dell'anno in cui è valida
    unavailable?:boolean = false;           // se è true, definisce un periodo di indisponibilità
    timezone?:string = null;                // timezone degli orari degli slot

    constructor(fromData?:any) {
        if(fromData != null) {
            if(typeof fromData == 'string') {
                fromData = JSON.parse(fromData);
            }
            
            for(let i in fromData) {
                if(typeof this[i] != 'undefined' && typeof this[i] != 'function') {
                    this[i] = fromData[i];
                }
            }
        }
    }
}

export class TimetableDataListItem extends TimetableData {

    containsDay(year:number, month:number, day:number, justDate?:boolean) {
        let _isIn = true;

        let _dateCheck = new Date(year, month - 1, day)
            
        if(this.days != null && this.days.length > 0) {
            _isIn = false;

            for(let _cPeriod of this.days) {
                let _cYear = _cPeriod.year;
                if(_cYear == null) _cYear = year;

                let _dateBegin = new Date(_cYear, _cPeriod.begin.month - 1, _cPeriod.begin.day)
                if(_cPeriod.year == null && _dateBegin.getTime() > _dateCheck.getTime()) _dateBegin.setFullYear(_dateBegin.getFullYear() + 1) // se è ciclico e la data di inizio è successiva al check, provo a spostarla prima

                let _dateEnd = new Date(_dateBegin.getFullYear(), _cPeriod.end.month - 1, _cPeriod.end.day)
                if(_dateEnd.getTime() < _dateBegin.getTime()) { // questa cosa penso ci vada sempre per evitare il problema di periodi che sono a cavallo di capodanno
                    _dateEnd.setFullYear(_dateEnd.getFullYear() + 1)
                }

                if(_dateBegin.getTime() <= _dateCheck.getTime() && _dateEnd.getTime() >= _dateCheck.getTime()) {
                    _isIn = true;
                    break;
                }
                    
            }
        }
        
        if(_isIn) {
            if(justDate) return true;
            else {
                for(let _cSlot of this.hours) {
                    if(_cSlot.days[_dateCheck.getDay()]) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    toData():TimetableData {
        return {
            timezone: this.timezone,
            slotDuration: this.slotDuration,
            unavailable: this.unavailable,
            hours: this.hours,
            days: this.days
        }
    }
}

export class TimetableDataList {
    list:Array<TimetableDataListItem> = [];

    constructor(fromData?:any) {
        if(fromData != null) {
            if(typeof fromData == 'string') {
                fromData = JSON.parse(fromData)
            }

            if('length' in fromData) {
                for(let _item of fromData) {
                    this.list.push(new TimetableDataListItem(_item))
                }
            }
            else {
                this.list.push(new TimetableDataListItem(fromData))
            }
        }
    }

    isAvailableOn(year:number, month:number, day:number, justDate?:boolean) {
        let _retVal = false;

        for(let _item of this.list) {
            if(_item.unavailable) {
                if(_item.containsDay(year, month, day, true)) return false;
            }
            else if(_retVal == false) {
                if(_item.containsDay(year, month, day, justDate)) _retVal = true;
            }
        }

        return _retVal;
    }

    getFirstAvailableAfter(date:Date, justDate?:boolean) {
        let _dateCheck = new Date(date);
        let _dateMin:Date = null;

        let _datesAvailable:Array<{ from:Date, to:Date, hours:Array<TimetableDataHour> }> = []
        let _datesUnavailable:Array<{ from:Date, to:Date }> = []

        for(let _item of this.list) {
            if(_item.days != null && _item.days.length > 0) {
                for(let _cPeriod of _item.days) {
                    let _cYear = _cPeriod.year;
                    if(_cYear == null) _cYear = _dateCheck.getFullYear();
    
                    let _dateBegin = new Date(_cYear, _cPeriod.begin.month - 1, _cPeriod.begin.day)
                    if(_cPeriod.year == null && _dateBegin.getTime() > _dateCheck.getTime()) _dateBegin.setFullYear(_dateBegin.getFullYear() + 1) // se è ciclico e la data di inizio è successiva al check, provo a spostarla prima
    
                    let _dateEnd = new Date(_dateBegin.getFullYear(), _cPeriod.end.month - 1, _cPeriod.end.day)
                    if(_dateEnd.getTime() < _dateBegin.getTime()) _dateEnd.setFullYear(_dateEnd.getFullYear() + 1) // se la data fine è precedente a quella di inizio, la sposto all'anno dopo

                    if(_item.unavailable) {
                        _datesUnavailable.push({
                            from: _dateBegin,
                            to: _dateEnd
                        })
                    }
                    else {
                        _datesAvailable.push({
                            from: _dateBegin,
                            to: _dateEnd,
                            hours: _item.hours
                        })
                    }
                }
            }
        }

        // ordino i 2 array per data di inizio del periodo

        _datesAvailable.sort((a, b) => {
            if(a.from.getTime() < b.from.getTime()) return -1;
            else if(b.from.getTime() < a.from.getTime()) return 1;
            else return 0;
        })

        _datesUnavailable.sort((a, b) => {
            if(a.from.getTime() < b.from.getTime()) return -1;
            else if(b.from.getTime() < a.from.getTime()) return 1;
            else return 0;
        })

        for(let _dates of _datesAvailable) {
            if(_dates.to.getTime() >= _dateCheck.getTime()) { // se la dispobilità finisce dopo il giorno di controllo
                let _findDay = new Date(_dates.from) // comincio dall'inizio della disponibilità

                if(_findDay.getTime() <= _dateCheck.getTime()) _findDay = new Date(_dateCheck) // se è precedente al giorno di controllo, inizio da questo

                for(let _unavailable of _datesUnavailable) {
                    if(_unavailable.from.getTime() < _findDay.getTime() && _unavailable.to.getTime() > _findDay.getTime()) { // se sono in un periodo di indisponibilità lo sposto in avanti a dopo il periodo di indisponibilità
                        _findDay = new Date(_unavailable.to)
                        _findDay.setDate(_findDay.getDate() + 1) // includo l'ultimo giorno di indispobilità
                    }
                }
 
                let _firstSlotTime:Date = null;

                if(justDate) {
                    _firstSlotTime = new Date(_findDay.getFullYear(), _findDay.getMonth(), _findDay.getDate())
                }
                else {
                    for(let i = 0; i < 7; i++) { // itero sui giorni per vedere il primo giorno effettivamente attivo
                        // NB: tecnicamente qua sposto il giorno in avanti fino al primo giorno della settimana attivo, potrei finire in un nuovo periodo di indisponibilità, ma me ne frego
                        if(_findDay.getTime() > _dates.to.getTime()) { // se sono finito fuori dal periodo esco
                            break;
                        }
                        else {
                            for(let _cSlot of _dates.hours) { // per ogni slot
                                if(_cSlot.days[_findDay.getDay()]) { // se lo slot è attivo per quel giorno della settimana
                                    let _slotTime = new Date(_findDay.getFullYear(), _findDay.getMonth(), _findDay.getDate());
    
                                    if(_cSlot.begin != null) {
                                        _slotTime.setHours(parseInt(_cSlot.begin.split(':')[0]))
                                        _slotTime.setMinutes(parseInt(_cSlot.begin.split(':')[1]))
                                    }
                                    
                                    if(_slotTime.getTime() >= _dateCheck.getTime()) { // se lo slot inizia dopo il giorno di controllo
                                        if(_firstSlotTime == null || _firstSlotTime.getTime() > _slotTime.getTime()) {
                                            _firstSlotTime = _slotTime;
                                        }
                                    }
                                }
                            }
        
                            if(_firstSlotTime != null) break;
                            else {
                                _findDay.setDate(_findDay.getDate() + 1) // controllo il giorno dopo, a partire dalla mezzanotte
                                _findDay.setHours(0);
                                _findDay.setMinutes(0);
                                _findDay.setSeconds(0);
                                _findDay.setMilliseconds(0);
                            }
                        }
                    }
                }

                if(_firstSlotTime != null) {
                    if(_dateMin == null || _dateMin.getTime() > _firstSlotTime.getTime()) {
                        _dateMin = new Date(_firstSlotTime);
                    }
                }
            }
        }

        return _dateMin;
    }

    toData():Array<TimetableData> {
        let _retVal = [];

        for(let _availability of this.list) {
            _retVal.push(_availability.toData())
        }

        return _retVal;
    }
}