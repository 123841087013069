<app-pg-loading *ngIf="isSending" [isOverlay]="true" [isLarge]="true"></app-pg-loading>
<div class="modal-header">
    <h4 class="modal-title">{{titleText}}</h4>
    <button type="button" class="modal-close btn btn-link" (click)="modal.dismiss()">
        <i class="fa-regular fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <p *ngIf="descriptionText != null" [innerHTML]="descriptionText"></p>
    <div *ngIf="sendVia == null && sendRecipient == null" class="form-group">
        <app-pg-recipient [(ngModel)]="recipient" (viaChange)="via = $event;" [readonly]="isSending || sendResult != null"></app-pg-recipient>
    </div>
    <div *ngIf="sendVia == null && sendRecipient != null" class="form-group">
        <label><b>Tramite</b></label>
        <div>
            <div class="form-check form-check-inline">
                <input id="PGSendMessageViaSMS" name="PGSendMessageVia" class="form-check-input" type="radio" value="phone" 
                    [(ngModel)]="via" [disabled]="isSending || sendResult != null"/>
                <label for="PGSendMessageViaSMS" class="form-check-label">SMS</label>
            </div>
            <div class="form-check form-check-inline">
                <input id="PGSendMessageViaEmail" name="PGSendMessageVia" class="form-check-input" type="radio" value="email" 
                    [(ngModel)]="via" [disabled]="isSending || sendResult != null"/>
                <label for="PGSendMessageViaEmail" class="form-check-label">Email</label>
            </div>
        </div>
    </div>
    <ng-container  *ngIf="sendVia != null && sendRecipient == null">
        <div *ngIf="via == 'email'" class="form-group">
            <label for="PGSendMessageRecipientPhone"><b>Indirizzo email</b></label>
            <input id="PGSendMessageRecipientPhone" type="email" placeholder="email@website.com"
                [disabled]="isSending || sendResult != null" class="form-control" [(ngModel)]="recipient"/>
        </div>
        <div *ngIf="via == 'phone'" class="form-group">
            <label for="PGSendMessageRecipientPhone"><b>Numero di telefono</b></label>
            <input id="PGSendMessageRecipientPhone" type="tel" class="form-control" placeholder="+39 xxx xxx xxxx"
                [disabled]="isSending || sendResult != null" [(ngModel)]="recipient"/>
        </div>
    </ng-container>
    <div *ngIf="sendContent == null" class="form-group">
        <label for="PGSendMessageRecipientContent"><b>Testo</b></label>
        <textarea id="PGSendMessageRecipientContent"  class="form-control" [disabled]="isSending || sendResult != null" [(ngModel)]="content"></textarea>
    </div>
</div>
<div class="modal-footer">
    <div *ngIf="sendResult != null" class="PGSendMessage-Result">
        <span *ngIf="sendResult == 'error'" class="text-danger">Errore nell'invio del messaggio</span>
        <span *ngIf="sendResult == 'success'" class="text-success">Messaggio inviato con successo!</span>
    </div>
    <button type="button" class="btn btn-secondary" (click)="modal.close()">
        <span>Chiudi</span>
    </button>
    <button *ngIf="sendResult != 'success'" type="button" class="btn btn-primary" [disabled]="isSending || !canSend()" (click)="doSend()">
        <i class="fa-regular fa-paper-plane"></i>
        <span>Invia</span>
    </button>
</div>