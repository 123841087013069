import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms'
import { DataService } from '../../services/data.service';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-phone',
  templateUrl: './pg-phone.component.html',
  styleUrls: ['./pg-phone.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => PgPhoneComponent),
      multi: true
  }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PgPhoneComponent),
      multi: true,
  }]
})
export class PgPhoneComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly: boolean;
    @Input() required: boolean;

    @Input() international: boolean;

    value:string

    constructor(private dataService:DataService) { }

    ngOnInit(): void {
    }

    onValueChange() {
        if(this._onTouched) this._onTouched();
        if(this._onChange) {
            let _val = this.dataService.cleanPhone(this.value);

            this._onChange(_val);
        }
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
    }

    // INTERFACCIA Validator

    validate() {
        if(this.value == null || this.value == '') {
            if(this.required) {
                return {
                    required: {
                        valid: false
                    }
                } 
            }
        }
        else {
            if(this.international) {
                if(!PGUtilities.phoneInternationalRegExp.test(this.value)) {
                    return {
                        format: {
                            valid: false
                        }
                    } 
                }
            }
            else {
                if(!PGUtilities.phoneRegExp.test(this.value)) {
                    return {
                        format: {
                            valid: false
                        }
                    } 
                }
            }
        }
    };
}
