import { Injectable } from '@angular/core';
import { LocalizationService } from '../services/localization.service';
import moment from 'moment';
import { ExportCsvService } from '../services/export-csv.service';
import { PGAccommodation, PGBookingData } from '../models/booking.model';
import { PdfBlock, PdfGeneratorService, PdfLine, PdfModel, PdfSpan } from '../services/pdf-generator.service';

@Injectable({
    providedIn: 'root'
})
export class ExportBookingsService {

    constructor(private localizationService:LocalizationService, private exportCsvService:ExportCsvService, private pdfGeneratorService:PdfGeneratorService) { }

    private _exportBookingsFormatBookingPeriod(period:{ from:string, to:string }) {
        return this.localizationService.translate('pg-confirm-booking.booking-period-from') + ' ' + this.localizationService.format.date(moment(period.from, 'YYYY-MM-DD').toDate()) + 
        '<br/>' + this.localizationService.translate('pg-confirm-booking.booking-period-to') + ' ' + this.localizationService.format.date(moment(period.to, 'YYYY-MM-DD').toDate())
    }

    private _exportBookingsFormatTicketName(name:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(name);
    }

    private _exportBookingsFormatAccommodationName(accommodation:PGAccommodation) {
        if(accommodation != null && accommodation.accommodation_description != null) {
            return this.localizationService.getTranslatedValue(JSON.parse(accommodation.accommodation_description)).replace(/<[^>]*>/ig, '');
        }
    }

    exportBookings(mode:'csv'|'pdf', productName:string, productType:'experience'|'host'|'eatery', bookings:Array<PGBookingData>, productDate:string, productTime?:string) {
        let _hasPeriod = false;

        for(let _booking of bookings) {
            if('from' in _booking.availability && 'to' in _booking.availability &&  _booking.availability.to) {
                _hasPeriod = true;
            } 
        }

        let _headerRow = [
            this.localizationService.translate('pg-confirm-booking.date-time-hour'),
            this.localizationService.translate('pg-confirm-booking.user'),
            this.localizationService.translate('pg-confirm-booking.booking-contact'),
            null,
        ]

        if(_hasPeriod) {
            _headerRow.push(this.localizationService.translate('pg-confirm-booking.booking-period'))
        }

        if(productType == 'host') {
            _headerRow.push(this.localizationService.translate('pg-confirm-booking.booking-accommodation'))
            _headerRow.push(this.localizationService.translate('pg-confirm-booking.label-full-price-single'))
        }
        else if(productType == 'experience' || productType == null) {
            _headerRow.push(this.localizationService.translate('pg-confirm-booking.tickets')) 
        }

        _headerRow.push('PID')

        let _bodyRows = []

        for(let _booking of bookings) {
            if(_booking.status == 'confirmed' || _booking.status == 'available') {
                let _bookingTime = null;
    
                if('from' in _booking.availability) {
                    _bookingTime = _booking.availability.from;
                }
                else {
                    _bookingTime = _booking.availability.begin;
                }

                let _userData = '';
                if(_booking.related_user != null) {
                    for(let _prop of ['name','surname']) {
                        if(_booking.related_user[_prop]) {
                            if(_userData != '') _userData += ' ';
                            _userData += _booking.related_user[_prop];
                        }
                    }

                    _userData = _booking.related_user.id + ' - ' + _userData;
                }

                let _bookingContact = '';
                for(let _prop of ['user_email','user_phone']) {
                    if(_booking[_prop]) {
                        if(_bookingContact != '') _bookingContact += '\r\n';
                        _bookingContact += _booking[_prop];
                    }
                }
    
                let _dataRow:Array<string|number> = [
                    _bookingTime,
                    _userData,
                    _booking.user_name,
                    _bookingContact
                ];
    
                if(_hasPeriod) {
                    if('from' in _booking.availability && 'to' in _booking.availability &&  _booking.availability.to) {
                        _dataRow.push(this._exportBookingsFormatBookingPeriod(_booking.availability))
                    }
                    else {
                        _dataRow.push(null);
                    }
                }
        
                if(productType == 'host') {
                    if(_booking.booking_data != null) {
                        _dataRow.push(this._exportBookingsFormatAccommodationName(_booking.booking_data))
                    }
                    else {
                        _dataRow.push(null);
                    }
                }
                else if(productType == 'experience' || productType == null) {
                    let _tickets = '';

                    if(_booking.tickets.fullQuantity > 0) {
                        _tickets += this.localizationService.translate('pg-confirm-booking.label-full-price') + ' x ' + _booking.tickets.fullQuantity;
                    }
    
                    for(let _ticket of _booking.tickets.otherTickets) {
                        _tickets += '\r\n'
                        _tickets += this._exportBookingsFormatTicketName(_ticket.name) + ' x ' + _ticket.quantity;
                    }
                    
                    _dataRow.push(_tickets);
                }
    
                _dataRow.push(_booking.pid);
    
                _bodyRows.push(_dataRow);
            }
        }

        let _fileName = 'Bookings_' + productName + '_' + productDate;
        if(productTime != null) _fileName += '_' + productTime.replace(':', '-')

        if(mode == 'csv') {
            let _exportArray = [];

            _exportArray.push(_headerRow)
    
            for(let _row of _bodyRows) {
                _exportArray.push(_row)
            }

            this.exportCsvService.exportArray(_fileName, _exportArray);
        }
        else if(mode == 'pdf') {
            let _headerColumSize = [0.35, 1, 2, 0, 1, 0.5]
            let _bodyColumSize = [0.35, 1, 1, 1, 1, 0.5]

            let _headerColumnSizeTotal = 0;
            for(let _size of _headerColumSize) {
                _headerColumnSizeTotal += _size
            }

            let _bodyColumnSizeTotal = 0;
            for(let _size of _bodyColumSize) {
                _bodyColumnSizeTotal += _size
            }        

            let _exportModel = new PdfModel();

            _exportModel.margin = 12.5;
            _exportModel.size = 8;
            _exportModel.line = 0.4;
            _exportModel.blocks = [];

            let _titleBlock = new PdfBlock();
            _titleBlock.size = 12;
            _titleBlock.hr = true;
            _titleBlock.weight = 'bold';
            _titleBlock.lines = [];

            let _titleLine = new PdfLine();
            _titleLine.text = productName + ' - ' + this.localizationService.format.date(productDate)
            if(productTime != null) _titleLine.text += ' ' + productTime;
            _titleBlock.lines.push(_titleLine)

            _exportModel.blocks.push(_titleBlock);

            let _headBlock = new PdfBlock();
            _headBlock.size = 10;
            _headBlock.hr = true;
            _headBlock.weight = 'normal';
            _headBlock.lines = [];
            
            let _baseHeaderCellWidth = (210 - _exportModel.margin * 2) / _headerColumnSizeTotal;
            let _baseBodyCellWidth = (210 - _exportModel.margin * 2) / _bodyColumnSizeTotal;

            let _headLine = new PdfLine();
            _headLine.spans = [];

            let _cellLeft = 0;

            for(let i = 0; i < _headerRow.length; i++) {
                let _cellWidth = _baseHeaderCellWidth * _headerColumSize[i];

                let _span = new PdfSpan();
                _span.w = _cellWidth;
                _span.x = _cellLeft;
                _span.text = _headerRow[i] || '';
                _headLine.spans.push(_span)

                _cellLeft += _cellWidth;
            }

            _headBlock.lines.push(_headLine);
            _exportModel.blocks.push(_headBlock)

            let _bodyBlock = new PdfBlock();
            _headBlock.weight = 'normal';
            _bodyBlock.lines = [];

            for(let j = 0; j < _bodyRows.length; j++) {
                let _bodyLine = new PdfLine();
                if(j > 0) _bodyLine.y = 3;
                _bodyLine.spans = [];

                let _cellLeft = 0;

                for(let i = 0; i < _bodyRows[j].length; i++) {
                    let _cellWidth = _baseBodyCellWidth * _bodyColumSize[i];

                    let _span = new PdfSpan();
                    _span.w = _cellWidth;
                    _span.x = _cellLeft;
                    _span.text = _bodyRows[j][i] || '';

                    if(i == _bodyRows[j].length - 1) {
                        _span.font = 'courier'
                        _span.text = _span.text.toUpperCase();
                    }

                    _bodyLine.spans.push(_span)

                    _cellLeft += _cellWidth;
                }

                _bodyBlock.lines.push(_bodyLine)
            }

            _exportModel.blocks.push(_bodyBlock)

            this.pdfGeneratorService.generateDocument(_fileName, _exportModel)
        }
    }
}
