import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-pg-user-picker',
  templateUrl: './pg-user-picker.component.html',
  styleUrls: ['./pg-user-picker.component.scss']
})
export class PgUserPickerComponent implements OnInit {

    selectedValue:string = null;

    userSelectResourceSemantic:string = null;

    constructor(public modal:NgbActiveModal, private configService:ConfigService) { }

    ngOnInit(): void {
        this.userSelectResourceSemantic = this.configService.getResourceSemantic('User', 'select')
    }

}
