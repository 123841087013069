import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';
import { PGCallParticipantData } from '../pg-call-participant/pg-call-participant.component';

@Component({
  selector: 'app-pg-call',
  templateUrl: './pg-call.component.html',
  styleUrls: ['./pg-call.component.scss']
})
export class PgCallComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {

    constructor() { }

    @Input() channel:string;
    @Input() callToken:string;
    @Input() callURL:string;

    @Input() disableAudio:boolean;
    @Input() disableVideo:boolean;

    @Input() stacked:boolean;
    @Input() focus:string;
    @Input() hideInactive:string;

    @Input() audioSource:string;
    @Input() videoSource:string;

    @Output() participantsChange = new EventEmitter<Array<PGCallParticipantData>>();

    callParticipants:Array<PGCallParticipantData> = []

    private _callObject:DailyCall = null;

    isLoading = false;

    ngOnChanges() {
        if(this._callObject != null && !this.isLoading) {
            this._callObject.setLocalAudio(!this.disableAudio)
            this._callObject.setLocalVideo(!this.disableVideo)
        }
    }

    ngOnInit() {
        this.callParticipants = []

        this._callObject = DailyIframe.createCallObject({
            url: this.callURL,
            token: this.callToken,
        })

        this.isLoading = true;

        this._callObject.join({
            audioSource: this.audioSource,
            videoSource: this.videoSource,
        }).then((data) => {
            if(typeof data == 'object') {
                this.isLoading = false;

                this._callObject.setLocalAudio(!this.disableAudio)
                this._callObject.setLocalVideo(!this.disableVideo)

                this.callParticipants.push({
                    participant: data.local,
                    audio: data.local.audioTrack,
                    video: data.local.videoTrack,
                    local: true
                })

                this.participantsChange.emit(this.callParticipants);

                this._callObject.on('participant-joined', (data) => {
                    this.callParticipants.push({
                        participant: data.participant
                    });

                    this.participantsChange.emit(this.callParticipants);
                })
    
                this._callObject.on('participant-left', (data) => {
                    for(let i = 0; i < this.callParticipants.length; i++) {
                        if(this.callParticipants[i].participant.user_id == data.participant.user_id) {
                            this.callParticipants.splice(i, 1);
                            break;
                        }
                    }

                    this.participantsChange.emit(this.callParticipants);
                })
    
                this._callObject.on('track-started', (data) => {
                    console.log('track-started', data.participant.user_id)
                    for(let _participant of this.callParticipants) {
                        if(_participant.participant.user_id == data.participant.user_id) {
                            _participant[data.track.kind] = data.track;
                        }
                    }

                    this.participantsChange.emit(this.callParticipants);
                })
    
                this._callObject.on('track-stopped', (data) => {
                    for(let _participant of this.callParticipants) {
                        if(_participant.participant.user_id == data.participant.user_id) {
                            _participant[data.track.kind] = null;
                        }
                    }

                    this.participantsChange.emit(this.callParticipants);
                })
            }
        })
    }

    ngOnDestroy() {
        this._callObject.leave()
    }

    @ViewChild('callContainer') callContainer:ElementRef;

    ngAfterViewChecked(): void {
        let _callContainerElement = this.callContainer.nativeElement as HTMLElement

        if(_callContainerElement.offsetWidth > _callContainerElement.offsetHeight) {
            _callContainerElement.classList.add('PGCall--Landscape')
        }
        else {
            _callContainerElement.classList.remove('PGCall--Landscape')
        }
    }

    isFocused(participant:PGCallParticipantData) {
        return this.focus == participant.participant.user_id
    }

    isInactive(participant:PGCallParticipantData) {
        return participant.audio == null && participant.video == null;
    }
}
