<div class="TinyMCECont {{readonly ? 'TinyMCECont--ReadOnly' : ''}}">
    <div *ngIf="readonly" class="TinyMCECont-ReadonlyOverlay bg-secondary"></div>
    <div [hidden]="showAdvanced">
        <editor [init]="editorParams"></editor>
    </div>
    <div *ngIf="showAdvanced">
        <textarea [readonly]="readonly" class="form-control" [(ngModel)]="value" (ngModelChange)="setEditorValue()"></textarea>
    </div>
    <div *ngIf="allowAdvanced" class="PGControlsAdvanced">
        <button class="btn btn-sm btn-link" (click)="showAdvanced = !showAdvanced">
            <i *ngIf="!showAdvanced" class="fa-regular fa-code"></i>
            <i *ngIf="showAdvanced" class="fa-regular fa-circle"></i>
        </button>
    </div>
</div>

<ng-template #modalSelectImage>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-html-editor.insert-image' | locTranslate }}</h5>
    </div>
    <div class="modal-body">
        <app-pg-file-picker fileType="images" [(ngModel)]="selectImageValue"></app-pg-file-picker>
        <div class="text-end mt-3">
            <button class="btn btn-secondary" (click)="selectImageDismiss()">{{ 'pg-html-editor.cancel' | locTranslate }}</button>
            <button class="btn btn-primary ms-1" [disabled]="selectImageValue == null || selectImageValue == ''" (click)="selectImageClose()">{{ 'pg-html-editor.ok' | locTranslate }}</button>
        </div>
    </div>
</ng-template>

