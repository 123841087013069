import { Component } from '@angular/core';
import { FormFlow, PgFormField, PgFormGroup, PgFormLayout } from '../../../models/form.model';
import { FormsFlowState } from '../../elements/forms-main/forms-main.component';
import { ConfigFormLayoutGroupField } from '../../../models/config.resources.model';
import { StepFormBase } from '../step-form-base';

@Component({
  selector: 'app-forms-host',
  templateUrl: './forms-host.component.html',
  styleUrls: ['./forms-host.component.scss']
})
export class FormsHostComponent extends StepFormBase {

    formId = 'host';
    resourceId = 'Host';

    formFlow:FormFlow = null;

    createFormFlow() {
        let _phoneWarnMessage = this.localizationService.translate('forms-' + this.formId + '.warning.phone-sms');

        let _phoneWarnIf = '($value != null && /^\\+/.test($value) && !/^\\+[39|33|34|44|49|1]/.test($value) ? "' + _phoneWarnMessage + '" : null)'

        return new FormFlow({
            'dati_generali': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'is_broker', default: false, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'broker_code', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'company_name', required: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'landline_number' }),
                    new PgFormField({ label: 'auto', type: 'phone', name: 'phone', required: true, tooltip: (this.environmentService.environment.DisableSMS ? null : 'auto'), warnIf: _phoneWarnIf }),
                    new PgFormField({ label: 'auto', type: 'email', name: 'email', required: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'website' }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'store_url' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'languages', required: true }),
                    new PgFormField({ label: 'auto', type: 'location', name: 'geolocation', placeholder: 'auto', required: true,
                        slaves: {
                            'country': 'country',
                            'region': 'region',
                            'province': 'province',
                            'city': 'city',
                            'address': 'address',
                            'zipcode': 'zipcode',
                            'timezone': 'timezone'
                        }
                    }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'country', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'region', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'province', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'city', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'address', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'zipcode', readonly: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'timezone', readonly: true, default: 'Europe/Rome' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'priority', default: 5, required: true }),
                ])
            },
            'descrizione': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'bookable', default: true }),
                    new PgFormField({ label: 'auto', type: 'string', name: 'booking', tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'type', required: true }),
                    new PgFormField({ label: 'auto', type: 'rating', name: 'stars' }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'description@HostTranslation', required: true, locale: true, tooltip: 'auto', maxLength: 350 }),
                    new PgFormField({ label: 'auto', type: 'integer', min: 0, max: 100, name: 'broker_discount', default: 20, icon: 'fas fa-percent', tooltip: 'auto', required: true }),
                    new PgFormField({ label: 'auto', type: 'timetable-periods', name: 'availability', multi: true }),
                    new PgFormField({ label: 'auto', type: 'boolean', name: 'checkin_h24', default: false }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkin_start' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkin_end' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkout_start' }),
                    new PgFormField({ label: 'auto', type: 'time', name: 'checkout_end' }),
                ])
            },
            'servizi_base': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'general_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accessibility' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'reception' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_area' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'cleaning' }),
                ])
            },
            'servizi_accessori': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'transports' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_eatery_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'eatery_services' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_wellness_services' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'wellness_services' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_activities' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'activities' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_entertainment' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'entertainment' }),
                    new PgFormField({ label: 'auto', type: 'boolean', multi: true, name: 'flag_business' }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'business' }),
                    new PgFormField({ label: 'auto', type: 'html', name: 'services_description@HostTranslation', locale: true, tooltip: 'auto' }),
                ])
            },
            'servizi_alloggi': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_room_services', options: this.selectOptions.room_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'common_appartaments_services', options: this.selectOptions.appartaments_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'ondemand_services' }),
                ])
            },
            'alloggi': {
                title: null,
                description: 'auto',
                multi: 'accommodations',
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'html', name: 'accommodation_description', required: true, locale: true, tooltip: 'auto' }),
                    new PgFormField({ label: 'auto', type: 'integer', name: 'accommodation_size' }),
                    new PgFormField({ label: 'auto', type: 'integer', name: 'accommodation_people' }),
                    new PgFormField({ label: 'auto', type: 'select', name: 'accommodation_type' }),
                    new PgFormField({ label: 'auto', type: 'tickets-host', name: 'accommodation_prices', required: true, locale: true }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accommodation_room_services', options: this.selectOptions.room_services }),
                    new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'accommodation_appartaments_services', options: this.selectOptions.appartaments_services }),
                ])
            },
            'file': {
                title: null,
                form: new PgFormLayout([
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'videos', name: 'videos' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_outside' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_rooms' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_reception' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_bar' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_ristorante' }),
                    new PgFormField({ label: 'auto', type: 'file', multi: true, fileType: 'images', name: 'images_common_area' })
                ])
            },
            'survey': {
                title: null,
                description: 'auto',
                form: new PgFormLayout([
                    new PgFormField({ label: '', type: 'survey', name: 'survey', locale: true }),
                ])
            }
        })
    }

    customizeFormFlow() {
        this.formFlow.states['dati_generali'].form.getFieldByName('priority').visible = false;

        this.formFlow.states['descrizione'].form.getFieldLayout('stars').condition = '$form.type == "hotel" || $form.type == "albergo_diffuso" || $form.type == "aparthotel"';
        this.formFlow.states['descrizione'].form.getFieldLayout('checkin_h24').display = { fullWidth: true }

        for(let _cField of ['checkin_start','checkin_end','checkout_start','checkout_end']) {
            this.formFlow.states['descrizione'].form.getFieldLayout(_cField).condition = '!$form.checkin_h24';
        }

        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_room_services').condition = '$form.accommodation_type == "room"';
        this.formFlow.states['alloggi'].form.getFieldLayout('accommodation_appartaments_services').condition = '$form.accommodation_type == "apartment"';

        for(let _cField of ['country','region','province','city','address','zipcode','timezone']) {
            this.formFlow.states['dati_generali'].form.getFieldByName(_cField).visible = false;
        }

        this.formFlow.states['descrizione'].form.getFieldLayout('bookable').display = { fullWidth: false }
        this.formFlow.states['descrizione'].form.getFieldLayout('booking').condition = '$form.bookable';

        let _fieldsList:Array<ConfigFormLayoutGroupField> = []

        while(this.formFlow.states['descrizione'].form.formGroups[0].fields.length > 2) {
            _fieldsList.unshift(this.formFlow.states['descrizione'].form.formGroups[0].fields.pop())
        }

        this.formFlow.states['descrizione'].form.formGroups.push(new PgFormGroup({
            collapsible: false,
            fields: _fieldsList
        }))
    }

    mapImportData(data:google.maps.places.PlaceResult) {
        let _addressComponents = this.parseAddressComponents(data.address_components)

        return {
            country: _addressComponents.country,
            region: _addressComponents.region,
            province: _addressComponents.province,
            city: _addressComponents.city,
            address: _addressComponents.address,
            zipcode: _addressComponents.zipcode,
            website: data.website,
            landline_number: data.international_phone_number,
            name: data.name,
            geolocation: { 
                coordinates: [data.geometry.location.lat(), data.geometry.location.lng()], 
                type: 'Point' 
            }
        }
    }

    onFlowStateChange(data:FormsFlowState) {
        this.formFlow.states['alloggi'].form.getFieldByName('accommodation_room_services').default = data.values.common_room_services
        this.formFlow.states['alloggi'].form.getFieldByName('accommodation_appartaments_services').default = data.values.common_appartaments_services

        super.onFlowStateChange(data);
    }

    afterInit() {
        if(this.environmentService.environment.CheckoutURL != null) {
            let _id = this.elementId;
            if(this.dataService.isDraftId(_id)) _id = 'id';

            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = this.environmentService.environment.CheckoutURL + '/book-host/' + _id;
        }
        else {
            this.formFlow.states['descrizione'].form.getFieldByName('booking').placeholder = null;
        }
    }

    adaptLoadData(values:any) {
        this._lastTimezone = null;

        return super.adaptLoadData(values);
    }

    adaptSaveData(values:any) {
        if(values.booking == '') values.booking = null;

        return values;
    }

    onValuesChange(data) {
        super.onValuesChange(data);

        this._checkTimezone(data);
    }

    private _lastTimezone = null;

    _checkTimezone(data) {
        if(data.timezone != this._lastTimezone) {
            this._lastTimezone = data.timezone;

            this.formFlow.states.descrizione.form.getFieldByName('availability').timezone = data.timezone;
        }
    }
}
