import { Component, OnChanges } from '@angular/core';
import { PgResourceViewCalendarConfigComponent, PgResourceViewCalendarConfig } from './config/pg-resource-view-calendar-config.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PGViewResourceComponent } from '../resource-view-common';
import { ConfigResourceView } from '../../models/config.resources.model';
import { SemanticsService } from '../../services/semantics.service';
import { EnvironmentService } from '../../services/environment.service';
import { ConfigService } from '../../services/config.service';
import { DataFilter, DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-pg-resource-view-calendar',
  templateUrl: './pg-resource-view-calendar.component.html',
  styleUrls: ['./pg-resource-view-calendar.component.scss']
})
export class PgResourceViewCalendarComponent extends PGViewResourceComponent implements OnChanges {
    viewConfig:ConfigResourceView<PgResourceViewCalendarConfig>;

    constructor(dataService: DataService, configService: ConfigService, semanticsService:SemanticsService, router:Router, route:ActivatedRoute, environmentService:EnvironmentService, modalService:NgbModal, localizationService:LocalizationService, notificationsService:NotificationsService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService);
    }

    dateField:string = null;
    dataFilter:Array<DataFilter> =  null;

    ngOnChanges() {
        super.ngOnChanges();

        this.dateField = this.viewConfig.config.dateField;

        this.dataFilter = null;

        if(this.filterStatus != null) {
            this.dataFilter = this.filterStatus.filter;
        }
    }

    handleElementChange(id:string, data:any) {
    }
}