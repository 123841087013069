import { Component, Input, OnChanges } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-pg-user-widget',
  templateUrl: './pg-user-widget.component.html',
  styleUrls: ['./pg-user-widget.component.scss']
})
export class PgUserWidgetComponent implements OnChanges {

    @Input() userId:string;

    constructor(private dataService:DataService) { }

    isLoading = false;

    userData:{
        id:number,
        name:string,
        email: string,
        telephone: string
    } = null;

    ngOnChanges(): void {
        this.isLoading = true;

        this.userData = null;

        this.dataService.getElementData('User', this.userId).subscribe((data) => {
            this.isLoading = false;
            this.userData = data;
        }, () => {
            this.isLoading = false;
        })
    }

}
