import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export class PGTask {
    private static _progressiveId = 0;

    id:number = null;
    status:'waiting'|'running'|'success'|'error' = 'waiting';
    progress:number = null;
    errorMessage:string = null;
    errorFatal:boolean = null;
    
    constructor(public action:(resolve:() => void, reject:(isFatal?:boolean, error?:string) => void, progress?:(value:number) => void) => void, public name:string, public description?:string) {
        this.id = PGTask._progressiveId;
        PGTask._progressiveId++;
    };

    run() {
        return new Promise<void>((resolve, reject) => {
            this.status = 'running';
            this.errorMessage = null;
            this.errorFatal = null;
            this.progress = null;

            this.action(() => {
                this.status = 'success';
                resolve();
            }, (isFatal?:boolean, error?:string) => {
                this.status = 'error';
                this.errorMessage = error;
                this.errorFatal = isFatal;
                reject();
            }, (value) => {
                this.progress = value;
            })
        })
    }
}

@Injectable({
  providedIn: 'root'
})
export class TasksService {

    tasks: Array<PGTask> = [];
    current = -1;
    status: 'active'|'idle' = 'idle';

    statusChange = new Subject<{ action: 'add'|'run'|'success'|'error', task: PGTask }>()

    constructor() { }

    addTask(task:PGTask) {
        this.tasks.push(task);
        this.statusChange.next({ action: 'add', task: task });
        this._checkNext();
    }

    private _checkNext() {
        if(this.status == 'idle' && this.tasks[this.current + 1] != null) {
            this.current++;

            this.status = 'active';
            let _cTask = this.tasks[this.current];
            this.statusChange.next({ action: 'run', task: _cTask });

            _cTask.run().then(() => {
                this.status = 'idle'
                this.statusChange.next({ action: 'success', task: _cTask });
                this._checkNext();
            }, () => {
                this.status = 'idle'
                this.statusChange.next({ action: 'error', task: _cTask });
                this._checkNext();
            })
        }
    }
}
