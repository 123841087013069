<div class="PGNavTree border-end">
    <div class="list-group">
        <ng-container *ngFor="let navElement of navList">
            <div *ngIf="navElement.visible != null"
                class="list-group-item {{navElement.active ? 'active' : ''}} PGNavTree-Element {{navElement.type == 'directory' ? 'PGNavTree-Element--Directory' : ''}}"
                appPgAnimations="{{navElement.visible ? 'EnterL' : 'ExitL'}}"
                (click)="elementClick(navElement);">
                <div [style.paddingLeft]="(navElement.level * 20) + 'px'">
                    <span class="PGNavTree-Element-Icon">
                        <i *ngIf="navElement.type != 'directory'" class="{{navElement.icon}}"></i>
                        <i *ngIf="navElement.type == 'directory' &amp;&amp; !navElement.expanded" class="fa-regular fa-folder"></i>
                        <i *ngIf="navElement.type == 'directory' &amp;&amp; navElement.expanded" class="fa-regular fa-folder-open"></i>
                    </span>
                    <span class="PGNavTree-Element-Name">{{navElement.name | locTranslate}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>
