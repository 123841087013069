import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DailyParticipant } from '@daily-co/daily-js';

export class PGCallParticipantData {
    participant: DailyParticipant
    local?: boolean
    audio?: MediaStreamTrack
    video?: MediaStreamTrack 
}

@Component({
  selector: 'app-pg-call-participant',
  templateUrl: './pg-call-participant.component.html',
  styleUrls: ['./pg-call-participant.component.scss']
})
export class PgCallParticipantComponent implements OnInit, AfterViewChecked {

    @Input() participant:PGCallParticipantData;

    constructor() { }

    ngOnInit(): void {
    }

    @ViewChild('videoElement') videoElement:ElementRef;
    @ViewChild('audioElement') audioElement:ElementRef;

    ngAfterViewChecked() {
        for(let _kind of ['audio','video']) {
            if(!this.participant.local || _kind != 'audio') {
                let _mediaElement = this[_kind + 'Element'].nativeElement as HTMLMediaElement;

                if(_mediaElement.srcObject == null) {
                    if(this.participant[_kind] != null) {
                        let _stream = new MediaStream([this.participant[_kind]])
                        _mediaElement.srcObject = _stream;
                        _mediaElement.play();
                    }
                }
                else {
                    if(this.participant[_kind] == null) {
                        _mediaElement.pause();
                        _mediaElement.srcObject = null;
                    }
                }
            }
        }
    }

}
