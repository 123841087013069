import { Component, Input, OnInit } from '@angular/core';
import { RealmData } from '../../services/environment.service';

@Component({
  selector: 'app-pg-realm-card',
  templateUrl: './pg-realm-card.component.html',
  styleUrls: ['./pg-realm-card.component.scss']
})
export class PgRealmCardComponent implements OnInit {
    constructor() { }

    @Input() realmData:RealmData;

    ngOnInit(): void {
    }

    hasContacts() {
        return Object.keys(this.realmData.contacts).length > 0
    }
}
