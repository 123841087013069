import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { PGViewResourceInfiniteComponent } from '../resource-view-common';
import { CachedDataElements } from '../../models/cached-data';
import { ConfigService } from '../../services/config.service';
import { DataService } from '../../services/data.service';
import { SemanticsService } from '../../services/semantics.service';
import { EnvironmentService } from '../../services/environment.service';
import { PgFile } from '../../models/file.model';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-pg-resource-view-cards',
  templateUrl: './pg-resource-view-cards.component.html',
  styleUrls: ['./pg-resource-view-cards.component.scss']
})
export class PgResourceViewCardsComponent extends PGViewResourceInfiniteComponent {
    dataRows:Array<PgFile>;

    cache:CachedDataElements = null;

    constructor(dataService: DataService, configService: ConfigService, semanticsService:SemanticsService, router:Router, route:ActivatedRoute, environmentService:EnvironmentService, modalService: NgbModal, localizationService:LocalizationService, notificationsService:NotificationsService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService);

        this.cache = new CachedDataElements(dataService);
    }

    // TODO: fixare questa roba, probabilmente nemmeno serve passando i bottoni da fuori

    viewElement(element:any) {
        this.router.navigate(['webtour/' + element.id ])
    }

    editElement(element:any) {
        this.router.navigate(['/shapeup/tour/toureditor/' + element.id])
    }
}

