import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {EditorView, basicSetup} from "codemirror"
import {EditorState} from "@codemirror/state"
import {json} from "@codemirror/lang-json"

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

@Component({
  selector: 'app-pg-json-editor',
  templateUrl: './pg-json-editor.component.html',
  styleUrls: ['./pg-json-editor.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => PgJsonEditorComponent),
      multi: true
  }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PgJsonEditorComponent),
      multi: true,
  }]
})
export class PgJsonEditorComponent implements OnInit, ControlValueAccessor, Validator, AfterViewInit {

    @Input() fieldId:string;
    
    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() jsonType:string;

    value:string = null;

    constructor() { }

    ngOnInit(): void {
    }

    private _editor:EditorView = null;

    @ViewChild('codeMirrorContainer') codeMirrorContainer:ElementRef;

    ngAfterViewInit(): void {
        this._editor = new EditorView({
            parent: this.codeMirrorContainer.nativeElement,
        })

        this._setEditorState();
    }

    private _setEditorState() {
        if(this._editor != null) {
            this._editor.setState(EditorState.create({
                doc: this.beautifyJSON(this.value),
                extensions: [
                    basicSetup, 
                    json(),  
                    EditorView.lineWrapping,
                    EditorView.updateListener.of((update) => {
                        let _val = '';

                        for(let _line of update.state.doc) {
                            _val +=_line
                        }

                        if(_val != this.value) {
                            this.value = this.uglifyJSON(_val);
                            this.onValueChange();
                        }
                    })
                ],
            }))
        }
    }

    onValueChange() {
        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(this.value);
    }

    beautifyJSON(json:string) {
        try {
            return JSON.stringify(JSON.parse(json), null, 4);
        }
        catch(ex) {
        }
    }

    uglifyJSON(json:string) {
        try {
            return JSON.stringify(JSON.parse(json));
        }
        catch(ex) {
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
        this._setEditorState();
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || (this.value != '' && this.value != null)) ? null : {
            required: {
                valid: false
            }
        }
    };
}
