<div class="PGRecipient">
    <div class="input-group">
        <input class="form-control" [readonly]="readonly" placeholder="{{ disablePhone ? 'email@website.com' : 'pg-recipient.placeholder' | locTranslate}}" [(ngModel)]="value" (ngModelChange)="onRecipientChange()"/>
        <div *ngIf="!disablePhone" class="input-group-text" title="{{via == 'phone' ? 'pg-recipient.via-sms' : via == 'email' ? 'pg-recipient.via-email' : ''}}">
            <small *ngIf="via == null">-</small>
            <small *ngIf="via == 'phone'">
                <b>SMS</b>
            </small>
            <i *ngIf="via == 'email'" class="fa-regular fa-at"></i>
        </div>
    </div>
</div>