<div class="PGFilePicker">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <div *ngIf="!isLoading" class="container-fluid p-0">
        <div *ngIf="!readonly" class="row mb-1">
            <div class="col-12">
                <div class="d-flex">
                    <div>
                        <app-pg-upload-button buttonText="{{ 'pg-file-picker.upload' | locTranslate }}" [buttonIcon]="getButtonIcon()" [fileType]="fileType" [accept]="acceptString" [multiple]="multi" (setFiles)="setFiles($event)"></app-pg-upload-button>
                    </div>
                    <div *ngIf="canSelectFile" class="ms-2">
                        <app-pg-resource-picker resourceId="File" [selectionMode]="multi ? 'multi' : 'single'" selectionField="url" [(selection)]="resourcePickerSelection" (selectionChange)="onResourcePickerSelectionChange()"  customContent="true" [filterStatus]="resourcePickerFilterStatus">
                            <button class="btn btn-primary ms-2">{{ 'pg-file-picker.select' | locTranslate }}</button>
                        </app-pg-resource-picker>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="(alreadyLoaded != null && alreadyLoaded.length > 0) || (selectedFiles != null && selectedFiles.length > 0)" class="PGFilePicker-Preview mt-1 row">
            <ng-container *ngIf="alreadyLoaded != null && (multi || selectedFiles == null || selectedFiles.length == 0)">
                <div *ngFor="let file of alreadyLoaded" class="PGFilePicker-Item {{ file.deleted ? 'PGFilePicker-Item--Deleted' : '' }} col-12 {{ multi ? 'col-sm-6 col-md-4' : '' }}">
                    <app-pg-file-preview  [fileData]="file" [viewMode]="fileType"></app-pg-file-preview>
                    <div class="PGFilePicker-Item-Actions">
                        <ng-container *ngIf="extraActions != null">
                            <button *ngFor="let action of extraActions" class="btn btn-sm btn-{{action.color}} ms-1" [disabled]="isSaving" (click)="$event.stopPropagation(); $event.preventDefault(); onExtraAction.emit({ action: action.name, file: file.url })">
                                <i class="fa-regular {{action.icon}}"></i>
                            </button>
                        </ng-container>
                        <button *ngIf="!readonly && !autoConfirm" class="btn btn-sm {{ file.deleted ? 'btn-secondary' : 'btn-danger' }} ms-1" [disabled]="isSaving" (click)="$event.stopPropagation(); $event.preventDefault(); toggleAlreadyUploadedDelete(file)">
                            <i *ngIf="!file.deleted" class="fa-regular fa-trash"></i>
                            <i *ngIf="file.deleted" class="fa-regular fa-undo"></i>
                        </button>
                        <app-pg-confirm-action *ngIf="!readonly && autoConfirm" (confirmed)="toggleAlreadyUploadedDelete(file)" [disabled]="isSaving">
                            <button class="btn btn-sm btn-danger ms-1">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </app-pg-confirm-action>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="selectedFiles != null">
                <div *ngFor="let file of selectedFiles" class="PGFilePicker-Item col-12 {{ multi ? 'col-sm-6 col-md-4' : '' }}">
                    <div *ngIf="isSaving || file.error" class="PGFilePicker-Item-Status">
                        <app-pg-loading *ngIf="isSaving" [isOverlay]="true" [progressVal]="file.progress"></app-pg-loading>
                        <div *ngIf="file.error" class="PGFilePicker-Item-Status-Message text-danger">
                            <div>
                                <b>{{file.error}}</b>
                            </div>
                        </div>
                    </div>
                    <app-pg-file-preview [fileData]="file.file" [viewMode]="fileType"></app-pg-file-preview>
                    <div *ngIf="!isSaving" class="PGFilePicker-Item-Actions">
                        <button *ngIf="fileType == 'images' && !file.error" class="btn btn-sm btn-primary me-1" (click)="openImageCropModal(file)">
                            <i *ngIf="multi" class="fa-regular fa-crop-alt"></i>
                        </button>
                        <button class="btn btn-sm {{ multi ? 'btn-danger' : 'btn-secondary' }}" [disabled]="isSaving" (click)="$event.stopPropagation(); $event.preventDefault(); removeSelectedFile(file);">
                            <i *ngIf="multi" class="fa-regular fa-trash"></i>
                            <i *ngIf="!multi" class="fa-regular fa-undo"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>
        <div *ngIf="!readonly && !autoConfirm && hasChanges()" class="text-end mt-1">
            <button class="btn btn-primary ms-1" [disabled]="isSaving" (click)="saveFiles()">
                <span>{{ 'pg-file-picker.confirm' | locTranslate }}</span>
                <i class="fa-regular fa-check"></i>
            </button>
        </div>
    </div>
</div>

<ng-template #modalPicker>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-file-picker.modal-title' | locTranslate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modalPickerCancel()"></button>
    </div>
    <div class="modal-body">
        
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="modalPickerClear()">
            {{ 'pg-file-picker.modal-button-clear' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="modalPickerSelect()">
            {{ 'pg-file-picker.modal-button-select' | locTranslate }}
        </button>
    </div>
</ng-template>