import { Component, Input, OnChanges } from '@angular/core';
import { PGBookingData, PGProductData } from '../../../models/booking.model';

@Component({
  selector: 'app-pg-bookings-slot-summary',
  templateUrl: './pg-bookings-slot-summary.component.html',
  styleUrls: ['./pg-bookings-slot-summary.component.scss']
})
export class PgBookingsSlotSummaryComponent implements OnChanges {

    constructor() { }

    @Input() slotStart:string;
    @Input() slotEnd:string;
    @Input() productData:PGProductData;
    @Input() bookingsData:Array<PGBookingData>;

    slotSummary: {
        bookings: { [status:string]: number },
        participants: { [status:string]: number }
    }

    ngOnChanges(): void {
        this.slotSummary = {
            bookings: {
                pending: 0,
                available: 0,
                confirmed: 0,
                canceled: 0
            },
            participants: {
                confirmed: 0,
                available: 0,
                pending: 0
            }
        }

        if(this.bookingsData != null) {
            for(let _item of this.bookingsData) {
                if(this.slotSummary.bookings[_item.status] != null) this.slotSummary.bookings[_item.status]++;

                if(this.slotSummary.participants[_item.status] != null) {
                    if(_item.tickets != null) {
                        let _participants = _item.tickets.fullQuantity;
        
                        for(let _cTicket of _item.tickets.otherTickets) {
                            _participants += _cTicket.quantity;
                        }
        
                        this.slotSummary.participants[_item.status] += _participants;
                    }
                }
            }
        }
    }

    getSlotCapacity() {
        if(this.productData != null) {
            if(!this.productData.open_event) return this.productData.maximum_participants
        }
    }

    hasSomeParticipants() {
        for(let i in this.slotSummary.participants) {
            if(this.slotSummary.participants[i] != 0) return true;
        }
    }

    hasSomeBookings() {
        for(let i in this.slotSummary.bookings) {
            if(this.slotSummary.bookings[i] != 0) return true;
        }
    }
}
