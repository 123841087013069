<div *ngIf="isLoggedIn()" class="PGUserMenu" ngbDropdown [autoClose]="false">
    <div class="PGUserMenu-Button btn btn-white" ngbDropdownToggle>
        <i class="PGUserMenu-Button-MainIcon fa-regular fa-lg fa-user opacity-100 m-0" title="{{getUserName()}}"></i>
        <i *ngIf="registered" class="PGUserMenu-Button-StatusIcon fa fa-circle opacity-100 m-0 {{getStatusColorClass(userStatus)}}"></i>
    </div>
    <div ngbDropdownMenu class="PGUserMenu-Menu">
        <app-pg-loading *ngIf="isSwitchingLanguage || isSwitchingRealm"></app-pg-loading>
        <ng-container *ngIf="!isSwitchingLanguage && !isSwitchingRealm">
            <div class="dropdown-item-text">{{getUserName()}}</div>

            <ng-container *ngIf="registered">
                <div class="dropdown-divider"></div>
                <div class="dropdown-item-text">
                    <b>{{ 'pg-user-menu.status' | locTranslate }}</b>
                </div>
                <div class="dropdown-item-text">
                    <app-pg-select #statusSelect [required]="true" [ngModel]="userStatus" (ngModelChange)="setUserStatus($event)" [options]="statusOptions" [display]="{ oneLine: true }"></app-pg-select>
                </div>
            </ng-container>

            <div class="dropdown-divider"></div>
            <div class="dropdown-item-text PGUserMenu-Menu-Item--Flex">
                <b>{{ 'pg-user-menu.language' | locTranslate }}</b>
            </div>
            <div class="dropdown-item-text">
                <app-pg-select [required]="true" [ngModel]="getLanguage()" (ngModelChange)="setLanguage($event)" [options]="languageOptions" [display]="{ oneLine: true }"></app-pg-select>
            </div>

            <ng-container *ngIf="hasRealms">
                <div class="dropdown-divider"></div>
                <div class="dropdown-item-text PGUserMenu-Menu-Item--Flex">
                    <b>{{ 'pg-user-menu.realms' | locTranslate }}</b>
                    <div class="PGUserMenu-Menu-Item-Button">
                        <button class="btn btn-link btn-sm" (click)="openAddRealm()">
                            <i class="fa-regular fa-castle">
                                <i class="fa fa-plus"></i>
                            </i>
                        </button>
                    </div>
                </div>
                
                <ng-container *ngIf="getAvailableRealms(); let availableRealms">
                    <div *ngFor="let realm of availableRealms" class="dropdown-item {{realm.id == getUserRealmId() ? 'active' : ''}}" (click)="setUserRealm(realm.id)">
                        {{realm.name}}
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="getAvailableRoles(); let availableRoles">
                <div class="dropdown-divider"></div>
                <div class="dropdown-item-text">
                    <b>{{ 'pg-user-menu.roles' | locTranslate }}</b>
                </div>
                
                <div *ngFor="let role of availableRoles" class="dropdown-item {{role.id == getUserRoleId() ? 'active' : ''}}" (click)="setUserRole(role.id)">
                    {{role.name}}
                </div>
            </ng-container>

            <ng-container *ngIf="getUserGroups(); let groups">
                <div class="dropdown-divider"></div>
                <div class="dropdown-item-text">
                    <b>{{ 'pg-user-menu.groups' | locTranslate }}</b>
                </div>
                <div class="dropdown-item-text">
                    <span class="text-muted" *ngIf="groups.length == 0">{{ 'pg-user-menu.groups-none' | locTranslate }}</span>
                    <ng-container *ngFor="let group of groups; let i = index;">
                        <ng-container *ngIf="i > 0">, </ng-container>{{group.label}}
                    </ng-container>
                </div>
            </ng-container>

            <ng-container *ngIf="stripeAvailable">
                <ng-container *ngIf="getUserStripeId(); let stripe">
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item-text">
                        <span>{{ 'pg-user-menu.stripe-connected' | locTranslate }}</span>
                        &nbsp;
                        <a target="_blank" href="https://dashboard.stripe.com/connect/accounts/overview">{{ 'pg-user-menu.stripe-connected-link' | locTranslate }}</a>
                    </div>
                </ng-container>
        
                <ng-container *ngIf="!getUserStripeId()">
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item-text pt-0 pb-0 d-flex justify-content-between align-items-center">
                        <span class="me-1">{{ 'pg-user-menu.stripe-not-connected' | locTranslate }}</span>
                        &nbsp;
                        <app-pg-stripe-connect [small]="true"></app-pg-stripe-connect>
                    </div>
                </ng-container>
            </ng-container>

            <!-- TODO: verificare perché il menu sembra triggerare change di continuo -->

            <ng-container *ngIf="menuElements != null">
                <ng-container *ngFor="let group of menuElements">
                    <div class="dropdown-divider"></div>
                    <div *ngIf="group.category != null" class="dropdown-item-text PGHeader-Menu-Content-Category">
                        {{ group.category | locTranslate }}
                    </div>
                    <div *ngFor="let element of group.elements" class="dropdown-item{{element.action == null ? '-text' : ''}}" (click)="onMenuElementClick(element)">
                        {{ element.label | locTranslate }}
                    </div>
                </ng-container>
            </ng-container>

            <div class="dropdown-divider"></div>
            <div class="dropdown-item" (click)="changePassword();">{{ 'pg-user-menu.change-password' | locTranslate }}</div>
            <div class="dropdown-item" (click)="doLogout();">{{ 'pg-user-menu.logout' | locTranslate }}</div>
        </ng-container>
    </div>

    <div *ngIf="showRealmsWarning != null" class="alert alert-warning PGUserMenu-RealmsWarning pb-2 text-center" [appPgAnimations]="showRealmsWarning ? 'EnterR' : 'ExitR'">
        <div>
            <div class="mb-2">
                <i class="fa-regular fa-exclamation-triangle fa-lg"></i>
            </div>
            <div class="mb-3">
                {{ 'pg-user-menu.realms-warning-text' | locTranslate }}
            </div>
        </div>
        <div class="d-flex justify-content-around border-top border-warning pt-2">
            <button class="btn btn-link text-muted" (click)="showRealmsWarning = false;">{{ 'pg-user-menu.realms-warning-button-later' | locTranslate }}</button>
            <button class="btn btn-link" (click)="openAddRealm()">{{ 'pg-user-menu.realms-warning-button-request' | locTranslate }}</button>
        </div>
    </div>
</div>

<ng-template #modalAddRealm>
    <app-pg-loading *ngIf="isRequestingAddRealm" [isOverlay]="true"></app-pg-loading>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-user-menu.modal-add-realm-title' | locTranslate }}</h5>
        <button type="button" class="modal-close btn btn-link" (click)="cancelAddRealm()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <ng-container *ngIf="!hasUserSomeRealms()">
                {{ 'pg-user-menu.modal-add-realm-no-access' | locTranslate }}
            </ng-container>

            <ng-container *ngIf="getAvailableRealms(); let availableRealms">
                <ng-container *ngIf="availableRealms.length > 0">
                    <div>
                        <b class="text-primary">{{ 'pg-user-menu.modal-add-realm-text-available-realms' | locTranslate }}</b>: <span *ngFor="let realm of availableRealms; let i = index"><ng-container *ngIf="i > 0">, </ng-container>{{realm.name}}</span>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="getUnavailableRealms(); let unavailableRealms">
                <ng-container *ngIf="unavailableRealms.length > 0">
                    <div>
                        <b class="text-danger">{{ 'pg-user-menu.modal-add-realm-text-unavailable-realms' | locTranslate }}</b>: <span *ngFor="let realm of unavailableRealms; let i = index"><ng-container *ngIf="i > 0">, </ng-container>{{realm.name}}</span>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="getPendingRealms(); let pendingRealms">
                <ng-container *ngIf="pendingRealms.length > 0">
                    <div>
                        <b>{{ 'pg-user-menu.modal-add-realm-text-pending-realms' | locTranslate }}</b>: <span *ngFor="let realm of pendingRealms; let i = index"><ng-container *ngIf="i > 0">, </ng-container>{{realm.name}}</span>
                    </div>
                </ng-container>
            </ng-container>

            <hr/>

            <div class="mb-3 PGUserMenu-ModalAddRealm-Add">
                <div class="form-group mb-0">
                    <label><b>{{ 'pg-user-menu.modal-add-realm-request-access' | locTranslate }}</b></label>
                    <app-pg-select [(ngModel)]="editAddRealmValue" [options]="editAddRealmOptions" [display]="{ oneLine: true }"></app-pg-select>
                </div>
                <div>
                    <button class="btn btn-primary ms-2" [disabled]="!editAddRealmValue" (click)="requestAddRealm()">{{ 'pg-user-menu.modal-add-realm-button-request' | locTranslate }}</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
