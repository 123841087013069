<div class="EFProductMap">
    <div *ngIf="!productData.hasItinerary() && productData.geolocation != null" class="EFProductMap-Simple">
        <div #gmapsElement class="EFProductMap-Simple-Map GoogleMaps"></div>
    </div>
    <div *ngIf="productData.hasItinerary()" class="EFProductMap-Itinerary">
        <app-pg-loading *ngIf="isLoadingItinerary"></app-pg-loading>
        <ng-container *ngIf="!isLoadingItinerary && itineraryData != null">
            <div class="EFProductMap-Itinerary-Map">
                <app-ef-itinerary-map *ngIf="isItineraryFullyLoaded" [itineraryData]="itineraryData"></app-ef-itinerary-map>
            </div>
            <div class="EFProductMap-Itinerary-List">
                <div *ngFor="let item of itineraryData.collection" class="EFProductMap-Itinerary-List-Item">
                    <div>
                        <app-ef-itinerary-stop-card [stopData]="item" (showStop)="onShowStop(item)"></app-ef-itinerary-stop-card>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>