import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PgFormModule } from '../pg-form/pg-form.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiMoleculesModule } from '../pg-ui-molecules/pg-ui-molecules.module';
import { FormsDataImportComponent } from './elements/forms-data-import/forms-data-import.component';
import { FormsMainComponent } from './elements/forms-main/forms-main.component';
import { FormsPlacePickerComponent } from './elements/forms-place-picker/forms-place-picker.component';
import { FormsTemplateImportComponent } from './elements/forms-template-import/forms-template-import.component';
import { FormsExperienceSupplierComponent } from './step-forms/forms-experience-supplier/forms-experience-supplier.component';
import { FormsExperienceComponent } from './step-forms/forms-experience/forms-experience.component';
import { FormsHostComponent } from './step-forms/forms-host/forms-host.component';
import { FormsEateryComponent } from './step-forms/forms-eatery/forms-eatery.component';
import { FormsItineraryComponent } from './single-forms/forms-itinerary/forms-itinerary.component';
import { FormsPoiComponent } from './step-forms/forms-poi/forms-poi.component';
import { ExperienceFinderModule } from '../experience-finder/experience-finder.module';
import { FormsTotemEnvironmentComponent } from './single-forms/forms-totem-environment/forms-totem-environment.component';
import { FormsFileComponent } from './single-forms/forms-file/forms-file.component';
import { FormsArticleComponent } from './single-forms/forms-article/forms-article.component';

@NgModule({
    declarations: [
        FormsDataImportComponent,
        FormsMainComponent,
        FormsPlacePickerComponent,
        FormsTemplateImportComponent,
        FormsExperienceComponent,
        FormsHostComponent,
        FormsEateryComponent,
        FormsExperienceSupplierComponent,
        FormsItineraryComponent,
        FormsPoiComponent,
        FormsTotemEnvironmentComponent,
        FormsFileComponent,
        FormsArticleComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        NgbModule,
        PgUiElementsModule,
        PgUiControlsModule,
        PgUiMoleculesModule,
        PgFormModule,
        ExperienceFinderModule
    ],
    exports: [
        FormsExperienceComponent,
        FormsHostComponent,
        FormsEateryComponent,
        FormsExperienceSupplierComponent,
        FormsItineraryComponent,
        FormsPoiComponent,
        FormsTotemEnvironmentComponent,
        FormsFileComponent,
        FormsArticleComponent
    ],
    providers:[]
})
export class CustomFormsModule { }
