export class TicketsDataOtherTickets { 
    name:string|{ [lang:string]: string };
    price: number;
}

export class TicketsData {
    
    fullPrice:number = null;

    otherTickets:Array<TicketsDataOtherTickets> =[];

    constructor(fromData?:any) {
        if(fromData != null) {
            let _cObj = fromData;

            if(typeof fromData == 'string') {
                _cObj = JSON.parse(fromData);
            }
    
            for(let i in _cObj) {
                this[i] = _cObj[i];
            }
        }
    }

    toData() {
        
        return JSON.stringify({
            fullPrice: this.fullPrice,
            otherTickets: this.otherTickets
        })
    }
}