/// <reference types="@types/google.maps" />

import { Component, OnInit } from '@angular/core';
import { EnvironmentService, ExternalLinksService, LocalizationService, PersistenceService } from 'pg-lib';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'experience-finder';

    constructor(private environmentService:EnvironmentService, private externalLinksService:ExternalLinksService, private localizationService:LocalizationService, private persistenceService:PersistenceService) {}

    isLoading = false;

    isWeb = false;

    hasHeaderFooter = true;

    isSwitchingLanguage = false;

    needConsent = false;

    ngOnInit(): void {
        this.needConsent = this.persistenceService.needConsent;

        this.isWeb = this.environmentService.environment.AppMode == 'web'

        if(this.environmentService.environment.AppMode == 'kiosk') {
            this.externalLinksService.setExternalLinkHandler((url:string) => {
                this.openLinkInIframe(url);
            })   
        }

        if(this.environmentService.externalParameters.iframe == 'true' || this.environmentService.externalParameters.iframekiosk == 'true') this.hasHeaderFooter = false

        this.localizationService.statusChange.subscribe((langId) => {
            this.isSwitchingLanguage = true;

            setTimeout(() => {
                this.isSwitchingLanguage = false;
            }, 200)
        })
    }

    externalLinkUrl:string = null;
    
    openLinkInIframe(url:string) {
        this.externalLinkUrl = url;
    }

    isUnderMaintenance() {
        return this.environmentService.getTimeUntilManteinance() <= 0;
    }
}
