import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';
import { PgFileType, PgFileUtils } from '../../models/file.model';
import { NotificationsService } from '../../services/notifications.service';
import { LocalizationService } from '../../services/localization.service';

@Component({
  selector: 'app-pg-upload-button',
  templateUrl: './pg-upload-button.component.html',
  styleUrls: ['./pg-upload-button.component.scss']
})
export class PgUploadButtonComponent implements OnInit {

    @Input() buttonText:string;
    @Input() buttonClass:string;
    @Input() buttonIcon:string;

    @Input() accept:string;
    @Input() multiple:boolean;
    @Input() directory:boolean;

    @Input() fileType:PgFileType;
    @Input() disabled:boolean;
    @Input() overlay:boolean;

    @Output() setFiles = new EventEmitter<Array<File>>();

    constructor(private environmentService:EnvironmentService, private notificationsService:NotificationsService, private localizationService:LocalizationService) { }

    ngOnInit(): void {
        if(this.accept == null) {
            this.accept = PgFileUtils.getFileAcceptString(this.fileType)
        }
    }

    @ViewChild('fileInput') fileInput;

    // TODO: aggiungere check per le estensioni e fare meglio la gestione del popup

    checkFiles() {
        if(!this.disabled) {
            let fileBrowser:HTMLInputElement = this.fileInput.nativeElement;
            if (fileBrowser.files != null) {
                let _retVal:Array<File> = [];

                let _excludedByReason:{ [reason:string]: Array<File> } = {
                    size: [],
                    extension: [],
                }

                for(let i = 0; i < fileBrowser.files.length; i++) {
                    let _file = fileBrowser.files.item(i)
                
                    if(PgFileUtils.doesExtensionMatch(this.fileType, _file.name)) {
                        if(_file.size > this.environmentService.fileSizeLimitNumber) {
                            _excludedByReason.size.push(_file)
                        }
                        else {
                            _retVal.push(_file)
                        }
                    }
                    else {
                        _excludedByReason.extension.push(_file)
                    }
                }

                let _hasExclusions = false;

                for(let i in _excludedByReason) {
                    if(_excludedByReason[i].length > 0) {
                        _hasExclusions = true;
                        break
                    }
                }

                if(_hasExclusions) {
                    if(this.multiple) {
                        let _excludedWarning = this.localizationService.translate('pg-upload-button.excluded-warning-multi')

                        for(let i in _excludedByReason) {
                            if(_excludedByReason[i].length > 0) {
                                _excludedWarning += '<br><span>' + this.localizationService.translate('pg-upload-button.excluded-warning-' + i)

                                if(i == 'size') {
                                    _excludedWarning += ' (' + this.environmentService.fileSizeLimitString + ')'
                                }

                                 _excludedWarning += '</span>'
    
                                for(let _file of _excludedByReason[i]) {
                                    _excludedWarning += '<br><small>- ' + (_file.webkitRelativePath || _file.name)

                                    if(i == 'size') {
                                        _excludedWarning += ' (' + PgFileUtils.getFileSizeString(_file.size) + ')'
                                    }

                                    _excludedWarning += '</small>'
                                }
                            }
                        }
    
                        this.notificationsService.addLocalNotification(_excludedWarning, 'warning')
                    }
                }

                this.setFiles.emit(_retVal);
            }
        }
    }

}
