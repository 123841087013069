import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-pg-send-message-modal',
  templateUrl: './pg-send-message-modal.component.html',
  styleUrls: ['./pg-send-message-modal.component.scss']
})
export class PgSendMessageModalComponent implements OnInit {

    @Input() sendType:'shapeupMessage';
    @Input() sendVia:'phone'|'email'|'list';
    @Input() sendRecipient:{ value:string, description: string }|string;
    @Input() sendContent:{ value:string, description: string }|string;
    @Input() customSend:(via:'phone'|'email'|'list', recipient:string, content:string) => Observable<boolean>;

    via:'phone'|'email'|'list' = 'phone';
    recipient:string = null;
    content:string = null;

    sendResult:'success'|'error' = null;

    isSending = false;

    titleText:string = null;
    descriptionText:string = null;

    constructor(private dataService:DataService, public modal:NgbActiveModal) { }

    ngOnInit(): void {
        let _cTitle = '';
        let _cDescription = '';

        if(this.sendType == 'shapeupMessage') {
            _cTitle += ' messaggio';
        }

        if(this.sendVia != null) {
            this.via = this.sendVia;

            if(this.sendVia == 'list') {
                _cTitle += ' a una lista';

                if(this.sendRecipient != null) {

                    if(typeof this.sendRecipient == 'string') {
                        _cDescription += ' alla lista <b>' + this.sendRecipient + '</b>';
                        this.recipient = this.sendRecipient;
                    }
                    else {
                        _cDescription += ' alla lista <b>' + this.sendRecipient.description + '</b>';
                        this.recipient = this.sendRecipient.value;
                    }
                }
                else {
                    _cDescription += ' a una lista';
                }
            }
            else {
                _cDescription += ' tramite <b>' + (this.sendVia == 'phone' ? 'SMS' : 'email') + '</b>';
                _cTitle += ' tramite ' + (this.sendVia == 'phone' ? 'SMS' : 'email');
            
                if(this.sendRecipient != null) {
                    if(typeof this.sendRecipient == 'string') {
                        this.recipient = this.sendRecipient;
                        _cDescription += (this.sendVia == 'phone' ? ' al numero' : ' all\'indirizzo') + ' <b>' + this.sendRecipient + '</b>';
                    }
                    else {
                        this.recipient = this.sendRecipient.value;
                        _cDescription += ' a <b>' + this.sendRecipient.description + '</b>';
                    }
                }
            }
        }

        if(this.sendContent != null) {
            if(this.sendType == 'shapeupMessage') {
                _cDescription += ' il messaggio';
            }
            else {
                _cDescription += ' il testo';
            }

            if(typeof this.sendContent == 'string') {
                this.content = this.sendContent;
                _cDescription += ' <b>&quot;' + this.sendContent + '&quot;</b>';
            }
            else {
                this.content = this.sendContent.value;
                _cDescription += ' <b>&quot;' + this.sendContent.description + '&quot;</b>';
            }
        }

        if(_cDescription != '') {
            this.descriptionText = 'Invia' + _cDescription;
        }

        this.titleText = 'Invia' + _cTitle;
    }

    doSend() {
        if(this.customSend != null) {
            this.customSend(this.via, this.recipient, this.content).subscribe((close) => {
                this.isSending = false;

                if(close) {
                    this.modal.close()
                }
                else {
                    this.sendResult = 'success';
                }
            }, (error) => {
                this.isSending = false;
                this.sendResult = 'error';
            })
        }
        else {
            let _cObservable:Observable<any> = null;

            if(this.sendType == 'shapeupMessage') {
                // TODO: manca gestione shapeupMessage
            }
            
            if(_cObservable != null) {
                this.isSending = true;
    
                _cObservable.subscribe(() => {
                    this.isSending = false;
                    this.sendResult = 'success';
                }, (error) => {
                    this.isSending = false;
                    this.sendResult = 'error';
                })
            }
        }
    }

    canSend() {
        return this.via != null && this.recipient != null && this.content != null;
    }
}
