import { PGLocationAddress } from "./models/map.model";

export class PGUtilities {
    static tryParseJSON(val:any) {
        if(typeof val == 'string') {
            try {
                return JSON.parse(val);
            }
            catch(ex) {
                console.log(ex)
            }

            return null;
        }
        else {
            return val;
        }
    }

    static getRandomId(length?:number) {
        if(length == null) length = 8;

        let _retVal = '';

        for(let i = 0; i < length; i++) {
            _retVal += Math.floor(Math.random() * 16).toString(16);
        }

        return _retVal;
    }

    static shuffleArray(array:Array<any>) {
        for(let i = 0; i < array.length - 1; i++) {
            let _cRandomI = i + 1 + Math.floor(Math.random() * (array.length - i - 1))

            let _cSwap = array[i];
            array[i] = array[_cRandomI]
            array[_cRandomI] = _cSwap;
        }
    }

    static loadScript(url:string, attrs?:{[name:string]:string}) {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';
    
            if(attrs != null) {
                for(let i in attrs) {
                    script.setAttribute(i, attrs[i]);
                }
            }
    
            script.src = url;
            script.onload = () => {
                resolve(null);
            };
            script.onerror = (error: any) => reject();
            document.getElementsByTagName('head')[0].appendChild(script);
        })
    }

    static parseAddressComponents(data:Array<google.maps.GeocoderAddressComponent>, timezone?:string):PGLocationAddress {
        let _addressComponents = {
            street_number: null,
            route: null,
            locality: null,
            administrative_area_level_3: null,
            administrative_area_level_2: null,
            administrative_area_level_1: null,
            country: null,
            postal_code: null
        }

        for(let _cComponent of data) {
            for(let _cComponentType of _cComponent.types) {
                if(typeof _addressComponents[_cComponentType] != 'undefined') {
                    _addressComponents[_cComponentType] = _cComponent.short_name;
                }
            }
        }

        let _streetAddress = null;
        if(_addressComponents.route != null) {
            _streetAddress = _addressComponents.route; 
            if(_addressComponents.street_number != null) _streetAddress += ' ' + _addressComponents.street_number;
        }

        return {
            country: _addressComponents.country,
            region: _addressComponents.administrative_area_level_1,
            province: _addressComponents.administrative_area_level_2,
            city: _addressComponents.locality || _addressComponents.administrative_area_level_3,
            address: _streetAddress,
            zipcode: _addressComponents.postal_code,
            timezone: timezone
        }
    }

    static phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    static phoneInternationalRegExp = /^[+]+[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

    static emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

    static urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
}