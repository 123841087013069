import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgImportComponent } from './pg-import/pg-import.component';



@NgModule({
    declarations: [
        PgImportComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiControlsModule,
        PgUiElementsModule
    ],
    exports: [
      PgImportComponent
    ]
})
export class PgImportModule { }
