import { Component, Input, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { ConfigRelation } from '../../models/config.relations.model';
import { PgFormField } from '../../models/form.model';
import { PgSelectOption } from '../../pg-ui-controls/pg-select/pg-select.component';
import { ConfigService } from '../../services/config.service';
import { DataFilter, DataOrder, PgFilterStatus, PgFilterStatusDataFilter } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { OptionMapsService } from '../../services/option-maps.service';

class PgFilterByResource {
    id: string;
    name: string;
    list: Array<PgFilterStatusDataFilter>;
}

@Component({
  selector: 'app-pg-filter-editor',
  templateUrl: './pg-filter-editor.component.html',
  styleUrls: ['./pg-filter-editor.component.scss']
})
export class PgFilterEditor implements OnInit, OnChanges {

    @Input() resourceId:string = null;

    @Input() editFilter:boolean = false;
    @Input() editOrder:boolean = false;

    @Input() adminMode:boolean = false; // se true: non ho il bottone apply, non nascondo le opzioni non filtrabili

    @Input() relatedFilters:boolean = true;

    @Input() filterStatus:PgFilterStatus = null;

    @Input() disableAdvancedFilters:boolean;

    @Output() applyFilter = new EventEmitter<PgFilterStatus>();
    @Output() advancedFilters = new EventEmitter<boolean>();

    resourceOptions:Array<PgSelectOption> = null;
    resourceSelection:string = null;

    hasMultipleResourceOptions:boolean = false;

    configByResource: {
        [resourceId:string]: {
            formConfig:Array<PgFormField>,
            fieldOptions:Array<PgSelectOption>,
            operatorOptionsByField: { [fieldName:string]: Array<PgSelectOption> }
        }
    } = null;

    canFilterSomething = false;

    operatorOptions: Array<PgSelectOption> = [
        { value: '==', text: 'Uguale a' },
        { value: '!=', text: 'Diverso da' },
        { value: '<', text: 'Minore di' },
        { value: '>', text: 'Maggiore di' },
        { value: 'between', text: 'Compreso tra' },
        { value: 'contains', text: 'Contiene' },
        { value: '!contains', text: 'Non contiene' },
        { value: 'in', text: 'Nella lista' }
    ]

    typesByOperator = {
        '!contains': ['string', 'text', 'html', 'select-multi'],
        '<': ['date', 'datetime', 'decimal', 'float', 'integer'],
        '>': ['date', 'datetime', 'decimal', 'float', 'integer'],
        'between': ['date', 'datetime', 'decimal', 'float', 'integer'],
        'in': ['select-multi'],
    }

    orderSelection: {
        field:string,
        direction:'asc'|'desc'
    } = null;

    status:PgFilterStatus = null;
    
    filterByResource:Array<PgFilterByResource> = null;

    valueFields:Array<Array<PgFormField>> = null;
    
    hasTags = false;
    hasSystemTags = false;

    categoriesOptions:Array<PgSelectOption> = null;

    constructor(private configService: ConfigService, private localizationService:LocalizationService, private optionMapsService:OptionMapsService) { }

    ngOnInit() {
    }

    ngOnChanges(simpleChanges:any) {
        if(simpleChanges.resourceId || simpleChanges.relatedFilters || simpleChanges.adminMode) {
            this.categoriesOptions = null;

            if(this.resourceId == 'File') this.categoriesOptions = this.optionMapsService.getResourceFieldOptionMap(this.resourceId, 'categories');

            this.canFilterSomething = simpleChanges.adminMode; // in admin ho sempre filtri

            this.valueFields = [];
            this.status = new PgFilterStatus();

            let _isHidden = false;
            if(!this.adminMode) _isHidden = this.configService.getResourceFilterableFields(this.resourceId).length == 0;

            this.resourceSelection = this.resourceId;
            this.resourceOptions = [{ 
                value: this.resourceId, 
                text: this.localizationService.translate('RESOURCES.' + this.resourceId + '.name'), 
                hidden: _isHidden
            }]

            this.hasMultipleResourceOptions = false;

            let _cRelationsConfig:Array<ConfigRelation> = [];

            if(this.relatedFilters) {
                _cRelationsConfig = this.configService.getResourceRelations(this.resourceId, ['1:N','N:N']);
            }

            let _cTranslationRelation = this.configService.getResourceTranslatedByRelation(this.resourceId);

            if(_cTranslationRelation != null) {
                _cRelationsConfig.unshift(_cTranslationRelation);
            }

            for(let _cConfig of _cRelationsConfig) {
                let _isHidden = false;

                let _relatedResource = _cConfig.modelA == this.resourceId ? _cConfig.modelB : _cConfig.modelA;
                if(!this.adminMode) _isHidden = this.configService.getResourceFilterableFields(_relatedResource).length == 0;

                this.resourceOptions.push({
                    value: _relatedResource,
                    text: this.localizationService.translate( 'RESOURCES.' + _relatedResource + '.name'),
                    category: 'Correlati',
                    hidden: _isHidden
                })

                if(!_isHidden) {
                    this.hasMultipleResourceOptions = true;
                }
            }

            this.configByResource = {};

            for(let _cRelated of this.resourceOptions) {
                let _cFormConfig = this.configService.getResourceFormFields(_cRelated.value);
                let _cResourceFilterable = this.configService.getResourceFilterableFields(_cRelated.value);

                let _cFieldOptions:Array<PgSelectOption> = [];
                let _cOperatorOptionsByField:{ [fieldName:string]: Array<PgSelectOption> } = {};

                for(let _cField of _cFormConfig) {
                    if(!this.adminMode) {
                        if(_cTranslationRelation != null && _cRelated.value == _cTranslationRelation.modelB && _cField.name == 'language') {
                            _isHidden = true;
                        }
                        else {
                            _isHidden = _cResourceFilterable.indexOf(_cField.name) == -1;
                        }
                    }

                    if(!_cRelated.hidden && !_isHidden) {
                        this.canFilterSomething = true;
                    }

                    _cFieldOptions.push({ value: _cField.name, text: this.localizationService.translate(_cField.label), hidden: _isHidden });
    
                    _cOperatorOptionsByField[_cField.name] = [];
    
                    for(let _cOperator of this.operatorOptions) {
                        let _cType:string = _cField.type;
    
                        if(_cType == 'select' && _cField.multi) _cType = 'select-multi';
    
                        if(this.typesByOperator[_cOperator.value] == null || this.typesByOperator[_cOperator.value].indexOf(_cType) != -1) {
                            _cOperatorOptionsByField[_cField.name].push(_cOperator);
                        }
                    }
                }

                this.configByResource[_cRelated.value] = {
                    formConfig: _cFormConfig,
                    fieldOptions: _cFieldOptions,
                    operatorOptionsByField: _cOperatorOptionsByField
                }
            }

            if(this.resourceOptions.length == 0) {
                this.orderSelection = null;
            }
            else {
                this.orderSelection = { field: this.configByResource[this.resourceId].fieldOptions[0].value, direction: 'asc' };
            }

            let _fieldList = this.configService.getResourceFilterableFields(this.resourceId);

            this.hasTags = _fieldList.indexOf('tags') != -1;
            this.hasSystemTags = _fieldList.indexOf('system_tags') != -1;
        }

        if(simpleChanges.filterStatus) {
            if(this.filterStatus != null) {
                this.status = this.filterStatus;

                this.valueFields = [];

                for(let _cFilter of this.status.filter) {
                    if(_cFilter.value != null) this.generateValueFields(_cFilter);
                }
            }
            else {
                this.status = new PgFilterStatus();
            }
        }

        this.generateFilterByResource();
        this.generateApplied();

        if(this.status.filter.length == 0) this.addFilter();
    }

    onFilterFieldChange(filter:DataFilter) {
        filter.operator = null;
        filter.value = null;
    }

    onFilterOperatorChange(filter:DataFilter) {
        filter.value = [];

        this.generateValueFields(filter);
    }

    onFilterValueChange(filter:DataFilter) {}

    getFilterIndex(filter:DataFilter) {
        for(let i = 0; i < this.status.filter.length; i++) {
            if(this.status.filter[i] == filter) return i;
        }
    }

    generateValueFields(filter:PgFilterStatusDataFilter) {
        let _cIndex = this.getFilterIndex(filter);
        this.valueFields[_cIndex] = [];

        for(let _cField of this.configByResource[filter.resource].formConfig) {
            if(_cField.name == filter.field) {
                let _valueNum = 1;

                if(filter.operator == 'between') _valueNum = 2;

                for(let i = 0; i < _valueNum; i++) {
                    let _cFormField = new PgFormField(_cField);
                    _cFormField.editable = true;
                    _cFormField.readonly = filter.readonly;
                    _cFormField.required = false;
                    _cFormField.multi = false;
                    _cFormField.value = filter.value[i];

                    this.valueFields[_cIndex].push(_cFormField);
                }

                break;
            }
        }
    }
    
    addFilter() {
        this.status.filter.push(new DataFilter(null, null, null, this.resourceSelection));
        this.generateFilterByResource();
    }

    removeFilter(filter:DataFilter) {
        this.status.filter.splice(this.status.filter.indexOf(filter), 1);
        this.generateFilterByResource();
    }

    addOrder() {
        let _toRemove = [];

        for(let i = 0; i < this.status.order.length; i++) {
            if(this.status.order[i].field == this.orderSelection.field) {
                _toRemove.push(i);
            }
        }

        _toRemove.reverse();

        for(let _cIndex of _toRemove) {
            this.status.order.splice(_cIndex, 1);
        }

        this.status.order.push(new DataOrder(this.orderSelection.field, this.orderSelection.direction));
    }

    removeOrder(order:DataOrder) {
        this.status.order.splice(this.status.order.indexOf(order), 1);
    }

    generateFilterByResource() {
        this.filterByResource = [];

        let _filterByResourceObj = {};

        for(let _cFilter of this.status.filter) {
            let _cResource = _cFilter.resource;
            if(_cResource == null) _cResource = this.resourceId;

            if(_filterByResourceObj[_cResource] == null) _filterByResourceObj[_cResource] = [];

            _filterByResourceObj[_cResource].push(_cFilter);
        }

        for(let _cRelated of this.resourceOptions) {
            if(_filterByResourceObj[_cRelated.value] != null) {
                this.filterByResource.push({
                    id: _cRelated.value,
                    name: _cRelated.text,
                    list: _filterByResourceObj[_cRelated.value]
                })
            }
        }
    }

    isFilterValid(filter:DataFilter) {
        if(filter.field != null && filter.operator != null && filter.value != null) {
            let _allValuesSet = true;

            for(let _cValue of filter.value) {
                if(_cValue == null) {
                    _allValuesSet = false;
                    break;
                }
            }

            return _allValuesSet
        }
        else return false;
    }

    appliedFilter:PgFilterStatus = null;

    appliedFilterByResource: Array<PgFilterByResource> = null;
    appliedOrder: Array<DataOrder> = null;

    generateApplied() { // TODO: qua andrebbe gestito tutto con l'oggetto appliedFilter, così può essere modificato anche da fuori, per ora ho sistemato solo appliedSearch perché mi serviva quello
        this.appliedFilterByResource = [];

        for(let _cResource of this.filterByResource) {
            let _cApplied = new PgFilterByResource();
            _cApplied.id = _cResource.id;
            _cApplied.name = _cResource.name;
            _cApplied.list = [];

            for(let _cFilter of _cResource.list) {
                if(this.isFilterValid(_cFilter)) {
                    let _appliedFilter = new DataFilter(_cFilter.field, _cFilter.operator, _cFilter.value, _cFilter.resource);

                    _cApplied.list.push(_appliedFilter);
                }
            }

            if(_cApplied.list.length > 0) this.appliedFilterByResource.push(_cApplied);
        }

        this.appliedOrder = [];

        for(let _cOrder of this.status.order) {
            this.appliedOrder.push(new DataOrder(_cOrder.field, _cOrder.direction));
        }

        // questa parte di sopra andrebbe generata nell'oggetto direttamente, per ora non ho toccato per non fare casini

        this.appliedFilter = new PgFilterStatus()

        if(this.status.search != null && this.status.search != '') {
            this.appliedFilter.search = this.status.search;
        }

        if(this.status.tags != null && this.status.tags != '') {
            this.appliedFilter.tags = this.status.tags;
        }

        if(this.status.system_tags != null && this.status.system_tags != '') {
            this.appliedFilter.system_tags = this.status.system_tags;
        }

        this.appliedFilter.filter = [];

        for(let _cFilterByResource of this.appliedFilterByResource) {
            for(let _cFilter of _cFilterByResource.list) {
                this.appliedFilter.filter.push(new DataFilter(_cFilter.field, _cFilter.operator, _cFilter.value, _cFilter.resource))
            }
        }

        this.appliedFilter.order = []

        for(let _cOrder of this.appliedOrder) {
            this.appliedFilter.order.push(new DataOrder(_cOrder.field, _cOrder.direction));
        }
    }

    doApplySearch(regenerateFilters?:boolean) {
        if(regenerateFilters) this.generateFilterByResource()

        this.generateApplied(); 

        this.applyFilter.emit(this.appliedFilter);

        if(this.hasAdvancedFilters) this.toggleAdvancedFilters();
    }

    hasAppliedSearch() {
        return this.appliedFilter?.search != null;
    }

    hasAppliedFilter() {
        if(this.appliedFilterByResource != null) {
            for(let _applied of this.appliedFilterByResource) {
                for(let _filter of _applied.list) {
                    if(_filter.resource != this.resourceId || _filter.field != 'categories' || _filter.operator != 'in') return true;
                }
            }
        }
    }

    hasAppliedOrder() {
        return this.appliedOrder != null && this.appliedOrder.length > 0;
    }

    onSearchKeyUp(event:KeyboardEvent) {
        if(event.keyCode == 13) {
            this.doApplySearch();
        }
    }

    getFieldName(resource:string, field:string) {
        for(let _cField of this.configByResource[resource].fieldOptions) {
            if(_cField.value == field) {
                return _cField.text;
            }
        }
    }

    getOperatorName(operator:string) {
        for(let _cOperator of this.operatorOptions) {
            if(_cOperator.value == operator) {
                return _cOperator.text;
            }
        }
    }

    getResourceFilterString(byResource:PgFilterByResource) {
        let _retVal = '';

        for(let _cFilter of byResource.list) {
            let _cFieldName = this.getFieldName(_cFilter.resource, _cFilter.field);

            let _cOperatorName = this.getOperatorName(_cFilter.operator);
            if(_cOperatorName != null) _cOperatorName = _cOperatorName.toLowerCase();

            let _cValueFormat = null;
        
            for(let _cField of this.configByResource[byResource.id].formConfig) {
                if(_cField.name == _cFilter.field) {
                    _cValueFormat = '';

                    for(let _cValue of _cFilter.value) {
                        if(_cValueFormat != '') _cValueFormat += ' e ';
                        _cValueFormat += this.localizationService.format.byConfig(_cValue, _cField);
                    }

                    break;
                }
            }

            if(_retVal != '') _retVal += ', ';

            _retVal += _cFieldName + ' ' + _cOperatorName + ' ' + _cValueFormat;
        }

        return _retVal;
    }

    getOrderString(orderList:Array<DataOrder>) {
        let _retVal = '';

        for(let _cOrder of orderList) {
            if(_retVal != '') _retVal += ', ';
            _retVal += this.getFieldName(this.resourceId, _cOrder.field) + ' ' + (_cOrder.direction == 'asc' ? 'crescente' : 'decrescente')
        }
        
        return _retVal;
    }

    hasAdvancedFilters = false;

    toggleAdvancedFilters() {
        if(!this.adminMode) {
            this.hasAdvancedFilters = !this.hasAdvancedFilters;

            this.editFilter = this.hasAdvancedFilters;
            this.editOrder = this.hasAdvancedFilters;

            this.advancedFilters.emit(this.hasAdvancedFilters);
        }
    }

    hasAppliedTags() {
        return this.appliedFilter?.tags != null;
    }
    
    hasAppliedSystemTags() {
        return this.appliedFilter?.system_tags != null;
    }
}
