import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class WebPushService {

    constructor(private environmentService:EnvironmentService, private authService:AuthService) { }

    private _registration:ServiceWorkerRegistration = null;

    enabled = false;

    async init() {
        this._registration = await navigator.serviceWorker.getRegistration();

        let _fileName = 'web-push-sw.js?v=' + this.environmentService.getBuildTime();

        if(this._registration == null) {
            console.log('WebPushService init: service worker missing')

            await navigator.serviceWorker.register(_fileName)

            this._registration = await navigator.serviceWorker.ready;
            console.log('WebPushService init: finished')
        }
        else {
            if(!this._registration.active.scriptURL.endsWith(_fileName)) {
                console.log('WebPushService init: service worker changed version')

                this._deleteSubscription();

                await(new Promise<void>((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, 250)
                }))

                await this._registration.unregister()

                await navigator.serviceWorker.register(_fileName)

                this._registration = await navigator.serviceWorker.ready;
                console.log('WebPushService init: finished')
            }
        }

        this.enabled = window.Notification.permission == 'granted';

        this.authService.afterLogin.subscribe(() => {
            this._createSubscription();
        })

        this.authService.beforeLogout.subscribe(() => {
            this._deleteSubscription();
        })

        await this._checkSubscription();
    }

    async enable() {
        const permission = await window.Notification.requestPermission()
        if (permission == 'granted') {
            this.enabled = true;
            this._checkSubscription();
        }
    }

    private async _checkSubscription() {
        if(this.enabled) {
            if(this.authService.isLoggedIn()) {
                await this._createSubscription();
            }
            else {
                await this._deleteSubscription();
            }
        }
    }

    private async _createSubscription() {
        await this._sendMessage({ 
            APIUrl: this.environmentService.environment.APIUrl,
            operation: 'create',
            token: await this.authService.getAuthToken()
        })
    }

    private async _deleteSubscription() {
        await this._sendMessage({ 
            APIUrl: this.environmentService.environment.APIUrl,
            operation: 'delete',
            token: await this.authService.getAuthToken()
        })
    }
    
    private _sendMessage(data:any) {
        this._registration.active.postMessage(JSON.stringify(data))
    }

}
