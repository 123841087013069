import { Injectable } from '@angular/core'
import { EnvironmentService } from './environment.service'
import { LocalizationService } from './localization.service'
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OptionMapsService
{
    constructor(private environmentService:EnvironmentService, private localizationService:LocalizationService, private http:HttpClient) {}

    private _localizedMaps: {
        [resource:string]: { 
            [field:string]: string|Array<string | { 
                value: string, 
                hidden?:boolean, 
                disabled?:boolean, 
                data?: any 
            }> 
        }
    } = null;

    private _fixedMaps:{
        [resource:string]: { 
            [field:string]: Array<{ 
                value: string, 
                text: string, 
                data?:any 
            }> 
        }
    } = {
        '*': {
            languages: null
        }
    }

    init() {
        return new Promise<void>((resolve, reject) => {
            this.http.get('assets/option-maps.json').pipe((catchError(() => {
                return of(null)
            }))).subscribe((data:any) => {
                this._localizedMaps = data;

                this._fixedMaps['*'].languages = [];

                for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                    this._fixedMaps['*'].languages.push({ value: _cLanguage, text: this.localizationService.languageLabels[_cLanguage] })
                }

                //this._fixedMaps['*'].language = this._fixedMaps['*'].languages;
        
                if(this.environmentService.environment.StripeAPIKey == null || this.environmentService.environment.StripeAPIKey == '') {
                    let _paymentMap = this._localizedMaps.Experience.payment as Array<string>
                    _paymentMap.splice(this._localizedMaps.Experience.payment.indexOf('online'), 1)
                }
        
                this._fixedMaps['*'].group_id = [];

                resolve();
            })
        })
    }

    getResourceOptionMaps(resource:string):{[field:string]: Array<{ value: string, text: string, data?:any }>} {
        let _retVal = {}

        if(this._localizedMaps[resource] != null) { 
            for(let i in this._localizedMaps['*']) {
                _retVal[i] = this.getResourceFieldOptionMap('*', i)
            }      

            for(let i in this._localizedMaps[resource]) {
                _retVal[i] = this.getResourceFieldOptionMap(resource, i)
            }
        }

        if(this._fixedMaps['*'] != null) {       
            for(let i in this._fixedMaps['*']) {
                _retVal[i] = this._fixedMaps['*'][i];
            }
        }

        if(this._fixedMaps[resource] != null) {       
            for(let i in this._fixedMaps[resource]) {
                _retVal[i] = this._fixedMaps[resource][i];
            }
        }

        return _retVal;
    }

    getResourceFieldOptionMap(resource:string, field:string) {
        resource = this.localizationService.getCleanResourceId(resource)

        if(this._localizedMaps[resource] == null || this._localizedMaps[resource][field] == null) {
            return null;
        }
        else {
            let _map = this._localizedMaps[resource][field];
            
            if(this.environmentService.environment.CustomOptionMaps != null && this.environmentService.environment.CustomOptionMaps[resource] != null && this.environmentService.environment.CustomOptionMaps[resource][field] != null) {
                _map = this.environmentService.environment.CustomOptionMaps[resource][field];
            }

            let _translation = 'OPTIONMAPS.' + resource + '.' + field

            if(typeof _map == 'string') {
                _translation = 'OPTIONMAPS.' + resource + '.' + _map
                _map = this._localizedMaps[resource][_map]
            }

            let _retVal:Array<{ value: string, text: string, data?:any, hidden?:boolean, disabled?:boolean }> = [];

            for(let _option of _map) {
                let _value = null;
                let _data = null;
                let _hidden = null;
                let _disabled = null;

                if(typeof _option == 'string') _value = _option;
                else {
                    _value = _option.value;
                    _data = _option.data;
                    _hidden = _option.hidden;
                    _disabled = _option.disabled;
                }

                let _text = this.localizationService.translate(_translation + '.' + _value);
                _retVal.push({ 
                    value: _value, 
                    text: _text, 
                    data: _data,
                    hidden: _hidden,
                    disabled: _disabled
                })
            }

            return _retVal;
        }
    }

    getFixedOptionMap(resource:string, field:string) {
        if(this._fixedMaps[resource] != null) return this._fixedMaps[resource][field];
    }

    setFixedOptionMap(resource:string, field:string, options:Array<{ value: string, text: string, data?:any }>) {
        this._fixedMaps[resource][field] = options;
    }
}