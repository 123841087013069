import { Injectable } from '@angular/core';
import { PGUtilities } from '../pg-utilities';

@Injectable({
  providedIn: 'root'
})
export class ImportJsService {

    constructor() { }

    imported:{ 
        [url:string]: { 
            status: 'loading'|'success'|'error', 
            wait:Array<{ 
                resolve: () => void, 
                reject: () => void 
            }> 
        } 
    } = {}

    import(fileOrList:string|Array<string>) {
        return new Promise<void>((resolve, reject) => {
            if(typeof fileOrList == 'string') {
                if(this.imported[fileOrList] == null) {
                    this.imported[fileOrList] = {
                        status: 'loading',
                        wait: []
                    }

                    PGUtilities.loadScript(fileOrList).then(() => {
                        this.imported[fileOrList].status = 'success'

                        for(let _item of this.imported[fileOrList].wait) {
                            _item.resolve();
                        }

                        this.imported[fileOrList].wait = []
                    }, () => {
                        this.imported[fileOrList].status = 'error'

                        for(let _item of this.imported[fileOrList].wait) {
                            _item.reject();
                        }

                        this.imported[fileOrList].wait = []
                    })
                }
                
                if(this.imported[fileOrList].status == 'loading') {
                    this.imported[fileOrList].wait.push({ resolve: resolve, reject: reject })
                }
                else {
                    if(this.imported[fileOrList].status == 'success') resolve();
                    else reject()
                }
            }
            else {
                let _reqNum = 0;
                let _mustReject = false;
    
                for(let _file of fileOrList) {
                    _reqNum++;
                    this.import(_file).then(() => {
                        _reqNum--;
                        if(_reqNum == 0) {
                            if(_mustReject) reject();
                            else resolve();
                        }
                    }, () => {
                        _mustReject = true;
                        _reqNum--;
                        if(_reqNum == 0) {
                            if(_mustReject) reject();
                            else resolve();
                        }
                    })
                }
            }
        })
    }
}
