<div class="EFApp">
    <ng-container *ngIf="!isUnderMaintenance()">
        <app-pg-loading *ngIf="isLoading" [isLarge]="true" [isOverlay]="true"></app-pg-loading>
        <ng-container *ngIf="!isLoading">
            <div class="EFApp-Inner">
                <div *ngIf="hasHeaderFooter" class="EFApp-Inner-Header">
                    <app-pg-header title="Finder" [alwaysShow]="true" [noTasks]="true" [noUser]="true"></app-pg-header>
                </div>
                <div class="EFApp-Inner-Content">
                    <div *ngIf="!isSwitchingLanguage && !needConsent">
                        <router-outlet></router-outlet>
                    </div>
                </div>
                <div *ngIf="hasHeaderFooter" class="EFApp-Inner-Footer">
                    <app-pg-footer></app-pg-footer>
                </div>
            </div> 
        </ng-container>
    
        <div *ngIf="externalLinkUrl != null" class="EFModal EFModal--ExternalLink pg-animation-slideup">
            <div class="EFModal-Header border-bottom">
                <div>
                </div>
                <div>
                    <button class="btn btn-link" (click)="externalLinkUrl = null">
                        <span>{{ 'experience-finder.app.nav-close-external' | locTranslate }}</span>
                        <i class="fa-regular fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="EFModal-Body">
                <iframe sandbox="" [src]="externalLinkUrl | safe:'resourceUrl'"></iframe>
            </div>
        </div>
    </ng-container>
    <app-pg-maintenance></app-pg-maintenance>
    <app-pg-cookie-consent></app-pg-cookie-consent>
</div>