import { Component, Input, OnChanges, OnInit } from '@angular/core';
import moment from 'moment';
import { LocalizationService } from '../../../services/localization.service';
import { BookingService, BookingServiceListType } from '../../../services/booking.service';
import { PGAccommodation, PGBookingData, PGProductData, PGSupplierData } from '../../../models/booking.model';
import { DataFilter } from '../../../services/data.service';
import { ExportBookingsService } from '../../export-bookings.service';

@Component({
  selector: 'app-pg-confirm-booking',
  templateUrl: './pg-confirm-booking.component.html',
  styleUrls: ['./pg-confirm-booking.component.scss']
})
export class PgConfirmBookingComponent implements OnInit, OnChanges {

    constructor(private localizationService:LocalizationService, private bookingService:BookingService, private exportBookingsService:ExportBookingsService) { }

    @Input() requestAs:string;

    @Input() productId:string;
    @Input() productType:'experience'|'host'|'eatery';

    @Input() productDate:string;
    @Input() productTime:string;

    productData:PGProductData = null;
    supplierData:PGSupplierData = null;

    bookingsData:Array<PGBookingData> = null;

    hasPendingBookings = false;
    hasNonCanceledBookings = false;

    ngOnInit() {}

    ngOnChanges(): void {
        this.loadData();
    }

    isLoading = false;

    loadData() {

        this.productData = null;
        this.bookingsData = null;
        this.hasPendingBookings = false;
        this.hasNonCanceledBookings = false;

        this.isLoading = true;

        let _cFilter:Array<DataFilter> = [
            { field: 'experience_id', operator: '==', value: [ this.productId ] },
            { field: 'date', operator: '==', value: [ this.productDate ] },
        ]

        if(this.productTime != null) {
            _cFilter.push({ field: 'start', operator: '==', value: [ this.productTime ] })
        }

        this.bookingService.listBookings(this.requestAs == 'supplier' ? ('supplier/' + this.productType as BookingServiceListType) : null, {
            filter: _cFilter
        }).subscribe((data) => {
            this.bookingsData = data;

            // TODO: prendo i dati dell'esperienza dalla prima prenotazione, andrebbe gestito un diff con quelli seguenti (se c'è)

            if(this.bookingsData != null && this.bookingsData[0] != null) {
                this.productData = new PGProductData(this.bookingsData[0].experience_data, this.productType);
            }
            
            this._refreshSlotStatus()

            this.bookingService.getProductSupplier(this.productData, true /*this.requestAs != 'supplier'*/).subscribe((data) => { // questo check commentato non mi quadrava, ma mi sembra troppo deliberato per essere casuale
                this.supplierData = data;
                this.isLoading = false;
            })
        })
    }

    private _refreshSlotStatus() {
        this.hasPendingBookings = false;
        this.hasNonCanceledBookings = false;

        for(let _data of this.bookingsData) {
            if(_data.status != 'canceled') {
                this.hasNonCanceledBookings = true;

                if(_data.status == 'pending') {
                    this.hasPendingBookings = true;
                }
            }
        }
    }

    formatSelectedAvailabilityDate() {
        return this.localizationService.format.date(moment(this.productDate, 'YYYY-MM-DD').toDate())
    }

    formatSelectedAvailabilityTime() {
        return this.productTime;
    }

    formatBookingPeriod(period:{ from:string, to:string }) {
        return this.localizationService.translate('pg-confirm-booking.booking-period-from') + ' ' + this.localizationService.format.date(moment(period.from, 'YYYY-MM-DD').toDate()) + 
        '<br/>' + this.localizationService.translate('pg-confirm-booking.booking-period-to') + ' ' + this.localizationService.format.date(moment(period.to, 'YYYY-MM-DD').toDate())
    }

    formatTicketName(name:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(name);
    }

    formatQuestionText(text:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(text);
    }

    formatAccommodationName(accommodation:PGAccommodation) {
        if(accommodation != null && accommodation.accommodation_description != null) {
            return this.localizationService.getTranslatedValue(JSON.parse(accommodation.accommodation_description)).replace(/<[^>]*>/ig, '');
        }
    }

    isSaving = false;

    cancelBooking(booking:PGBookingData) {
        this.isSaving = true;        

        this.bookingService.cancelBooking(booking.id).subscribe((data) => {
            for(let i in data) {
                booking[i] = data[i]
            }

            this._refreshSlotStatus()
            this.isSaving = false; 
        })
    }

    confirmBooking(booking:PGBookingData) {
        this.isSaving = true;        

        this.bookingService.confirmBooking(booking.id).subscribe((data) => {
            for(let i in data) {
                booking[i] = data[i]
            }

            this._refreshSlotStatus()
            this.isSaving = false; 
        })
    }

    cancelSlot() {
        this.isSaving = true;
        let _reqNum = 0;

        for(let _booking of this.bookingsData) {
            if(_booking.status != 'canceled') {
                _reqNum++;

                this.bookingService.cancelBooking(_booking.id).subscribe((data) => {
                    for(let i in data) {
                        _booking[i] = data[i]
                    }

                    _reqNum--;
                    if(_reqNum == 0) {
                        this._refreshSlotStatus()
                        this.isSaving = false;
                    }
                })
            }
        }
    }

    confirmSlot() {
        this.isSaving = true;
        let _reqNum = 0;

        for(let _booking of this.bookingsData) {
            if(_booking.status == 'pending') {
                _reqNum++;

                this.bookingService.confirmBooking(_booking.id).subscribe((data) => {
                    for(let i in data) {
                        _booking[i] = data[i]
                    }

                    _reqNum--;
                    if(_reqNum == 0) {
                        this._refreshSlotStatus()
                        this.isSaving = false;
                    }
                })
            }
        }
    }

    exportCSV() {
        this.exportBookingsService.exportBookings('pdf', this.productData.title, this.productType, this.bookingsData, this.productDate, this.productTime)
    }
}
