
export class PgTotemWidget {
    id:string = null;
    name:string = null;
    title:string = null;
    color:string = null;
    params:any = null;

    static widgetOptions = [{ 
        value: 'totem-widget-text',
        text: 'Text',
        params: {
            abstract: '',
            logo: '',
            cover: '',
            text: '',
            background: ''
        }
    }, { 
        value: 'totem-widget-identity',
        text: 'Identity',
        params: {
            image: '',
            name: '',
            abstract: '',
            description: '',
            contact: ''
        }
    }, { 
        value: 'totem-widget-carousel',
        text: 'Carousel',
        params: {
            imageList: [{ file: '', text: '', author: '' }]
        }
    }, { 
        value: 'totem-widget-products',
        text: 'Products',
        params: {
            filter: null,
            showMap: false,
            showText: null,
            verticalList: false
        }
    }, { 
        value: 'totem-widget-weather',
        text: 'Weather',
        params: {
            location: ''
        }
    }]

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    this[i] = fromData[i]
                }
            }
        }
    }

    getLabel() {
        let _widgetLabel:string = '';

        for(let _option of PgTotemWidget.widgetOptions) {
            if(_option.value == this.name) {
                _widgetLabel = _option.text;
                break;
            }
        }

        if(this.name == 'totem-widget-products') {
            if(this.params?.showText != null) {
                _widgetLabel += ' - Text'
            }
            else if(this.params?.showMap) {
                _widgetLabel += ' - Map'
            }
            else if(this.params?.verticalList) {
                _widgetLabel += ' - V. List'
            }
            else {
                _widgetLabel += ' - H. List'
            }
        }

        return _widgetLabel;
    }
}

export class PgTotemScreenSaver {
    params:Array<{
        video?:string
        slides?:Array<{
            image:string,
            text:string
        }>
    }>

    images:Array<string>
    videos:Array<string>
    open:boolean
}