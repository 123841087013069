import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PGGraphData } from '../../pg-ui-elements/pg-graph/pg-graph.component';
import { LocalizationService } from '../../services/localization.service';
import { AirQualityData, CurrentAirQualityData, CurrentWeatherData, ForecastAirQualityData, ForecastWeatherData, WeatherData, WeatherService } from '../../services/weather.service';
import { PGLocation } from '../../models/map.model';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-weather',
  templateUrl: './pg-weather.component.html',
  styleUrls: ['./pg-weather.component.scss']
})
export class PgWeatherComponent implements OnChanges {

    @Input() locations:Array<string|PGLocation>;
    @Input() forecast:'full-open'|'full'|'partial';

    currentWeather:{ [location:string] : { isLoading: boolean, data: CurrentWeatherData } } = null;
    currentAirQuality:{ [location:string] : { isLoading: boolean, data: CurrentAirQualityData } } = null;

    forecastWeather:{ [location:string] : { isLoading: boolean, data: Array<ForecastWeatherData> } } = null;
    forecastAirQuality:{ [location:string] : { isLoading: boolean, data: Array<ForecastAirQualityData> } } = null;

    constructor(private weatherService:WeatherService, private localizationService:LocalizationService, private domSanitizer:DomSanitizer) { }

    cleanLocations:Array<string> = null;
    
    selectedForecastLocation:string = null;
    selectedForecastDay:number = null;
    selectedForecastDataSet:Array<PGGraphData> = null;

    ngOnChanges() {
        this.selectedForecastLocation = null;
        this.selectedForecastDay = null;
        this.selectedForecastDataSet = null;

        this.currentWeather = {};
        this.currentAirQuality = {};
        this.forecastWeather = {};
        this.forecastAirQuality = {};

        this._generateCleanLocations().then(() => {
            for(let _item of this.cleanLocations) {
                
                this.currentWeather[_item] = { isLoading: true, data: null }
                this.currentAirQuality[_item] = { isLoading: true, data: null }
    
                this.weatherService.getCurrentWeather(_item).subscribe((data) => {
                    this.currentWeather[_item].isLoading = false;
                    this.currentWeather[_item].data = data;
    
                    this.weatherService.getCurrentAirQuality(data.coord.lat, data.coord.lon).subscribe((data) => {
                        this.currentAirQuality[_item].isLoading = false;
                        this.currentAirQuality[_item].data = data;
                    })
                })
    
                if(this.forecast != null) {
                    this.forecastWeather[_item] = { isLoading: true, data: null }
                    this.forecastAirQuality[_item] = { isLoading: true, data: null }
    
                    this.weatherService.getForecastWeather(_item).subscribe((data) => {
                        this.forecastWeather[_item].isLoading = false;
                        this.forecastWeather[_item].data = data
    
                        this.weatherService.getForecastAirQuality(data[0].city.coord.lat, data[0].city.coord.lon).subscribe((data) => {
                            this.forecastAirQuality[_item].isLoading = false;
                            this.forecastAirQuality[_item].data = [];
    
                            let _dayMSecs = 1000 * 60 * 60 * 24;
                            let _upToTime = Date.now();
        
                            let _cForecast:ForecastAirQualityData = null;
        
                            for(let _cAirQuality of data.list) {
                                if(_cForecast == null || _cAirQuality.dt * 1000 >= _upToTime) {
                                    _cForecast = JSON.parse(JSON.stringify(data))
                                    _cForecast.list = [];
                                    this.forecastAirQuality[_item].data.push(_cForecast)
                                    _upToTime += _dayMSecs;
                                }
    
                                _cForecast.list.push(_cAirQuality)
                            }
                        })
    
                        if(this.forecast == 'full-open' && _item == this.cleanLocations[0]) {
                            this.toggleSelectedForecast(this.cleanLocations[0], 0)
                        }
                    })
                }
            }
        })
    }

    private _geocoder:google.maps.Geocoder = null;

    private async _generateCleanLocations() {
        this.cleanLocations = []

        for(let _item of this.locations) {
            if(_item != null) {
                if(typeof _item == 'string') {
                    this.cleanLocations.push(_item)
                }
                else {
                    if(this._geocoder == null) {
                        try {
                            this._geocoder = new google.maps.Geocoder();
                        }
                        catch(ex) {
                        }
                    }

                    if(this._geocoder != null) {
                        let _data = await this._geocoder.geocode({
                            location: new google.maps.LatLng(_item.coordinates[0], _item.coordinates[1])
                        })

                        if(_data.results != null && _data.results.length > 0) {
                            let _parsed = PGUtilities.parseAddressComponents(_data.results[0].address_components)
                            this.cleanLocations.push(_parsed.city + ',' + _parsed.province + ',' + _parsed.country)
                        }
                    }
                }
            }
        }
    }

    getForecastDate(dayDiff:number) {
        if(dayDiff == 0) return this.localizationService.translate('pg-weather.today');
        else if (dayDiff == 1) return this.localizationService.translate('pg-weather.tomorrow');
        else {
            let _nowDate = new Date();

            let _thenDate = new Date(_nowDate.getFullYear(), _nowDate.getMonth(), _nowDate.getDate() + dayDiff);

            return this.localizationService.data.dayNames[_thenDate.getDay()];
        }
    }

    getForecastDays(list:Array<WeatherData>, num:number) {
        let _retVal = [];

        let _upToDate = new Date(list[0].dt * 1000);

        for(let _cWeather of list) {

            if(_cWeather.dt >= _upToDate.getTime() / 1000) {
                _retVal.push(_cWeather);

                if(_retVal.length >= num) {
                    break;
                }
                else {
                    _upToDate.setDate(_upToDate.getDate() + 1);
                }
            }
        }

        while(_retVal.length < num) {
            _retVal.push(null);
        }

        return _retVal;
    }

    // TODO: queste andrebbero prese dalla classe ForecastWeather

    getWeatherDisplay(weather:WeatherData|Array<WeatherData>) {
        if(weather instanceof Array) {
            let _displayCount = {}
            let _displayMax = 0;

            for(let _cWeather of weather) {
                let _cDisplay = _cWeather.getDisplay();
                if(_displayCount[_cDisplay] == null) _displayCount[_cDisplay] = 0;
                _displayCount[_cDisplay]++;

                _displayMax = Math.max(_displayMax, _displayCount[_cDisplay])
            }

            for(let i in _displayCount) {
                if(_displayCount[i] == _displayMax) return i;
            }
        }
        else {
            return weather.getDisplay();
        }
    }

    getWeatherDescription(weather:WeatherData|Array<WeatherData>) {
        if(weather instanceof Array) {
            let _descriptionCount = {}
            let _descriptionMax = 0;

            for(let _cWeather of weather) {
                let _cDescription = _cWeather.getDescription();
                if(_descriptionCount[_cDescription] == null) _descriptionCount[_cDescription] = 0;
                _descriptionCount[_cDescription]++;

                _descriptionMax = Math.max(_descriptionMax, _descriptionCount[_cDescription])
            }

            for(let i in _descriptionCount) {
                if(_descriptionCount[i] == _descriptionMax) return i;
            }
        }
        else {
            return weather.getDescription();
        }
    }

    getWeatherTemperature(weather:WeatherData|Array<WeatherData>, val?:'min'|'max') {
        if(weather instanceof Array) {
            let _allTemperatures:Array<number> = [];
            for(let _cWeather of weather) {
                _allTemperatures.push(_cWeather.getTemperature(val))
            }

            if(val == 'min') {
                let _cMin = null;

                for(let _cTemperature of _allTemperatures) {
                    if(_cMin == null || _cMin > _cTemperature) _cMin = _cTemperature;
                }

                return _cMin;
            }
            else if(val == 'max') {
                let _cMax = null;

                for(let _cTemperature of _allTemperatures) {
                    if(_cMax == null || _cMax < _cTemperature) _cMax = _cTemperature;
                }

                return _cMax;
            }
            else {
                let _cSum = 0;

                for(let _cTemperature of _allTemperatures) {
                    _cSum += _cTemperature;
                }

                return _cSum / _allTemperatures.length;
            }
        }
        else {
            return weather.getTemperature(val);
        }
    }

    getWeatherHumidity(weather:WeatherData|Array<WeatherData>) {
        if(weather instanceof Array) {
            let _allHumidity:Array<number> = [];
            for(let _cWeather of weather) {
                _allHumidity.push(_cWeather.main.humidity)
            }

            let _cSum = 0;

            for(let _cHumidity of _allHumidity) {
                _cSum += _cHumidity;
            }

            return Math.round(_cSum / _allHumidity.length);
        }
        else {
            return weather.main.humidity;
        }
    }

    getAirQualityIndex(aq:AirQualityData|Array<AirQualityData>) {
        if(aq instanceof Array) {
            let _cSum = 0;

            for(let _cAq of aq) {
                _cSum += _cAq.main.aqi;
            }

            return Math.round(_cSum / aq.length * 10) / 10;
        }
        else {
            return aq.main.aqi;
        }
    }

    toggleSelectedForecast(location:string, index:number) {
        if(this.selectedForecastLocation == location && this.selectedForecastDay == index) {
            if(this.forecast != 'full-open') {
                this.selectedForecastLocation = null;
                this.selectedForecastDay = null;
    
                this.selectedForecastDataSet = null;
            }
        }
        else {
            this.selectedForecastLocation = location;
            this.selectedForecastDay = index;

            this.selectedForecastDataSet = [];

            for(let _cWeather of this.forecastWeather[location].data[index].list) {
                this.selectedForecastDataSet.push({ 
                    value: _cWeather.getTemperature(), 
                    label: this.domSanitizer.bypassSecurityTrustHtml(new Date(_cWeather.dt * 1000).toISOString().split('T')[1].replace(/:[^:]*$/, '') +  _cWeather.getDisplay())
                })
            }
        }
    }

    getAQIColor(aqi:number) {
        let _redLevel = (aqi - 1) / 4;
        let _greenLevel = 1 - _redLevel;

        _redLevel = 1 - Math.pow(1 - _redLevel, 2)
        _greenLevel = 1 - Math.pow(1 - _greenLevel, 2)

        let _cRGB = {
            r: 0,
            g: 0,
            b: 0
        }

        let _cRed = {
            r: 220,
            g: 53,
            b: 69
        }

        let _cGreen = {
            r: 40,
            g: 167,
            b: 69  
        }

        for(let i in _cRGB) {
            _cRGB[i] = Math.round(_cRed[i] * _redLevel + _cGreen[i] * _greenLevel);
        }

        return 'rgb(' + _cRGB.r + ',' + _cRGB.g + ',' + _cRGB.b + ')';
    }

    hasFullForecast() {
        return this.forecast != null && this.forecast.startsWith('full')
    }
}
