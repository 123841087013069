import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { EnvironmentService } from '../../services/environment.service';
import { PersistenceService } from '../../services/persistence.service';

@Component({
  selector: 'app-pg-maintenance',
  templateUrl: './pg-maintenance.component.html',
  styleUrls: ['./pg-maintenance.component.scss']
})
export class PgMaintenanceComponent implements OnInit {

    isUnderMaintenance = false;
    maintenanceEndDateTime:Date = null;

    constructor(private environmentService:EnvironmentService, private persistenceService:PersistenceService) { }

    currentLanguage = 'en'

    ngOnInit(): void {
        let _setLanguage = this.persistenceService.getItem('POIGEST_Language');
        if(this.environmentService.externalParameters.language != null) _setLanguage = this.environmentService.externalParameters.language;
        if(_setLanguage == null) _setLanguage = navigator.language;

        _setLanguage = _setLanguage.substring(0, 2);

        if(this.translations[_setLanguage] != null) this.currentLanguage = _setLanguage;

        this._checkUnderMaintenance()

        this.environmentService.applicationStatusChange.subscribe(() => {
            this._checkUnderMaintenance();
        })
    }

    private _checkUnderMaintenanceTimeout = null;

    private _checkUnderMaintenance() {
        clearTimeout(this._checkUnderMaintenanceTimeout)

        let _timeUntilMaintenance = this.environmentService.getTimeUntilManteinance();

        if(_timeUntilMaintenance > 0) {
            if(this.isUnderMaintenance) window.location.reload();
            else {
                if(_timeUntilMaintenance != Infinity) {
                    this._checkUnderMaintenanceTimeout = setTimeout(() => {
                        this.goUnderMaintenance();
                    }, _timeUntilMaintenance + 1000)
                }
            }
        }
        else {
            this.goUnderMaintenance();
        }
    }

    goUnderMaintenance() {
        this.isUnderMaintenance = true;

        if(this.environmentService.applicationStatus.maintenance.to != null) {
            this.maintenanceEndDateTime = new Date(this.environmentService.applicationStatus.maintenance.to)
        }
    }

    translations = {
        'en': {
            "under-maintenance": "Under maintenance",
            "sorry-inconvenience": "We apologize for the inconvenience",
            "maintenance-end": "Estimated end of maintenance"
        },
        'it': {
            "under-maintenance": "In manutenzione",
            "sorry-inconvenience": "Ci scusiamo per il disagio",
            "maintenance-end": "Fine manutenzione prevista"
        }
    }

    getTranslation(label:string) {
        return this.translations[this.currentLanguage][label]
    }

    formatDateTime(val:Date) {
        return moment(val).format('DD/MM/YYYY HH:mm');
    }
}
