<div class="PGLoading text-muted {{hasError ? 'PGLoading--Error' : ''}} {{isSmall ? 'PGLoading--Small' : ''}} {{isLarge ? 'PGLoading--Large' : ''}} {{isOverlay ? 'PGLoading--Overlay' : ''}} {{isFullscreen ? 'PGLoading--Fullscreen' : ''}}">
    <span *ngIf="hasProgressBar()">
        <div class="PGLoading-Progress" [ngClass]="{ 'pg-animation-shake': hasError }">
            <div class="progress">
                <div class="progress-bar" class="bg-{{ hasError ? 'danger' : getProgressRatio() < 1 ? 'secondary' : 'primary' }}" [style.transform]="'scale(' + getProgressRatio() + ', 1)'"></div>
            </div>
        </div>
    </span>
    <span>
        <div class="pg-animation-fadein">
            <div *ngIf="isLarge" class="PGMultiSpin">
                <div>
                    <div> 
                        <div>
                            <div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
            <i *ngIf="!isLarge" class="fa-regular fa-circle-notch pg-animation-spin"></i>
        </div>
    </span>
    <span *ngIf="hasError">
        <div class="pg-animation-shake" class="text-danger">
            <i class="fa-regular fa-exclamation-triangle"></i>
        </div>
    </span>
</div>