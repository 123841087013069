import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { EFProduct } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';

class EfCalendarData {
    date: Date
    products: Array<EFProduct>
    visibleProducts: Array<EFProduct>
}

@Component({
  selector: 'app-ef-calendar',
  templateUrl: './ef-calendar.component.html',
  styleUrls: ['./ef-calendar.component.scss']
})
export class EfCalendarComponent implements OnInit {

    @Input() productsData:Array<EFProduct>;
    @Input() dateInterval:{ from: string, to: string };
    @Input() vertical:boolean;
    @Input() full:boolean;

    @Output() showProduct = new EventEmitter<string>();

    isLoading = false;

    calendarData:Array<EfCalendarData> = [];
    visibleCalendarData:Array<EfCalendarData> = [];

    constructor(private localizationService:LocalizationService) { }

    @ViewChild('datesContainer') datesContainer:ElementRef;


    ngOnInit(): void {
    }

    viewMoreProducts(index:number) {
        let _day = this.calendarData[index]

        if(_day != null) {
            for(let i = 0; i < 3; i++) {
                if(_day.visibleProducts.length < _day.products.length) {
                    _day.visibleProducts.push(_day.products[_day.visibleProducts.length])
                }
            }
        }
    }

    viewMoreCalendar() {
        for(let i = 0; i < 3; i++) {
            if(this.visibleCalendarData.length < this.calendarData.length) {
                this.visibleCalendarData.push(this.calendarData[this.visibleCalendarData.length])
                this.viewMoreProducts(this.visibleCalendarData.length - 1)
            }
        }
    }

    ngOnChanges() {
        this.monthOffset = 0;

        this._loadData();
    }

    private _loadTimeout = null;

    private _loadData() {
        this.calendarData = [];
        this.visibleCalendarData = []
        this.isLoading = true;

        clearTimeout(this._loadTimeout)

        this._loadTimeout = setTimeout(() => {
            let _cDate = new Date(Date.now());

            this._currentMonth = _cDate.getMonth();
            this._currentYear = _cDate.getFullYear();
            
            let _cDateTo = new Date(_cDate);
            _cDateTo.setDate(_cDate.getDate() + 7 * 5);
    
            if(this.dateInterval != null) {
                _cDate = new Date(this.dateInterval.from);
                _cDateTo = new Date(this.dateInterval.to);
            }
            else {
                if(this.monthOffset > 0) {
                    _cDate = new Date(_cDate.getFullYear(), _cDate.getMonth() + this.monthOffset, 1)
                    _cDateTo = new Date(_cDate.getFullYear(), _cDate.getMonth() + 1, 0)
                }
            }
    
            while(_cDate <= _cDateTo) {
                let _cCalendarData = {
                    date: new Date(_cDate),
                    products: [],
                    visibleProducts: []
                }
    
                for(let _cProduct of this.productsData) {
                    if(_cProduct.availability != null) {
                        if(_cProduct.availability.isAvailableOn(_cDate.getFullYear(), _cDate.getMonth() + 1, _cDate.getDate(), true)) {
                            _cCalendarData.products.push(_cProduct);
                        }
                    }
                }
    
                if(_cCalendarData.products.length > 0) this.calendarData.push(_cCalendarData)
    
                _cDate.setDate(_cDate.getDate() + 1)
            }

            this.viewMoreCalendar();

            for(let i = 0; i < this.visibleCalendarData.length; i++) {
                this.viewMoreProducts(i);
            }

            this.isLoading = false;
    
            this._loadTimeout = setTimeout(() => {
                if(this.datesContainer != null) {
                    (this.datesContainer.nativeElement as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' })
                }
            }, 100)
        }, 100)
    }

    getTranslatedProperty(product:EFProduct, prop:string) {
        if(product.translations != null && product.translations[this.localizationService.currentLanguage] != null && product.translations[this.localizationService.currentLanguage][prop] != null) {
            return product.translations[this.localizationService.currentLanguage][prop]
        }
        else if(product[prop] != null) {
            return product[prop]
        }
        else return ''
    }

    formatDate(date:Date) {
        return this.localizationService.format.date(date, true)
    }

    private _currentMonth:number = null;
    private _currentYear:number = null;

    monthOffset = 0;

    canGoPrev() {
        return this.monthOffset > 0;
    }

    canGoNext() {
        return this.monthOffset < 5;
    }

    goPrev() {
        if(this.canGoPrev()) {
            this.monthOffset--;
            this._loadData();
        }
    }

    goNext() {
        if(this.canGoNext()) {
            this.monthOffset++;
            this._loadData();
        }
    }

    getFormattedMonthOffset() {
        if(this.monthOffset == 0) {
            return this.localizationService.translate('experience-finder.general.next-dates')
        }
        else {
            let _cDate = new Date(this._currentYear, this._currentMonth + this.monthOffset, 1)
            return this.localizationService.data.monthNames[_cDate.getMonth()] + ' ' + _cDate.getFullYear();
        }
    }
}
