<div class="PGSurveyAnswers">
    <div class="container-fluid">        
        <div class="row">
            <ng-container *ngFor="let question of answers.questions">
                <div class="col-12">
                    <label class="mb-2"><b>{{formatQuestionText(question.text)}}</b></label>
                </div>
                <div class="col-12 mb-3">
                    <input *ngIf="question.type == 'string'" type="text" class="form-control" [(ngModel)]="question.answer">
                    <ng-container *ngIf="question.type == 'boolean'">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="true" id="{{randomId}}_radio_true" name="{{randomId}}_radio" [(ngModel)]="question.answer">
                            <label class="form-check-label ps-2" for="{{randomId}}_radio_true">
                                {{ 'pg-survey-editor.answer-boolean-true' | locTranslate }}
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" value="false" id="{{randomId}}_radio_false" name="{{randomId}}_radio" [(ngModel)]="question.answer">
                            <label class="form-check-label ps-2" for="{{randomId}}_radio_false">
                                {{ 'pg-survey-editor.answer-boolean-false' | locTranslate }}
                            </label>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="row border-top pt-2">
            <div class="col">
                <button class="btn btn-primary float-end" (click)="surveyAnswered.emit(answers)">{{ 'pg-survey-answers.button-confirm' | locTranslate }}</button>
            </div>
        </div>
    </div>
</div>
