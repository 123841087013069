import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

import { ValidationService } from './validation.service';

@Directive({
    selector: 'input[format]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => FormatValidator), multi: true }
    ]
})

export class FormatValidator implements Validator {
    @Input()
    format: any;

    constructor(private validationService:ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if(this.validationService.validateFieldValueFormat(this.format, c.value)) return null;
        else return {
            format: false
        }
    }
}

@Directive({
    selector: 'input[min]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValidator), multi: true }
    ]
})

export class MinValidator implements Validator {
    @Input()
    min: any;

    constructor(private validationService:ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if(this.validationService.validateFieldValueProperty('min', this.min, c.value)) return null;
        else return {
            min: false
        }
    }
}

@Directive({
    selector: 'input[max]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxValidator), multi: true }
    ]
})

export class MaxValidator implements Validator {
    @Input()
    max: any;

    constructor(private validationService:ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if(this.validationService.validateFieldValueProperty('max', this.max, c.value)) return null;
        else return {
            max: false
        }
    }
}

@Directive({
    selector: 'input[maxLength],textarea[maxLength]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxLengthValidator), multi: true }
    ]
})

export class MaxLengthValidator implements Validator {
    @Input()
    maxLength: any;

    constructor(private validationService:ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any } {

        if(this.validationService.validateFieldValueProperty('maxLength', this.maxLength, c.value)) return null;
        else return {
            maxLength: false
        }
    }
}

@Directive({
    selector: 'input[constraints],textarea[constraints]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ConstraintsValidator), multi: true }
    ]
})

export class ConstraintsValidator implements Validator {
    @Input()
    constraints: any;

    @Input()
    constraintsValues: any;

    constructor(private validationService:ValidationService) {
    }

    validate(c: AbstractControl): { [key: string]: any } {
        if(this.constraints == null) return null;
        else {
            let _constraintValidation = this.validationService.validateFieldValueConstraints(c.value, this.constraints, this.constraintsValues);
            if(_constraintValidation.length == 0) return null;
            else return {
                constraints: _constraintValidation
            }
        }
    }
}