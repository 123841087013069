<div class="PGNotification m-0 {{getMainClass()}}">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <i class="{{getIconClass()}} fa-fw"></i>
            <span *ngIf="notification.from != null" class="ms-2">{{notification.from}}</span>
            <span *ngIf="notification.from == null" class="ms-2" [innerHTML]="formatContent()"></span>
        </div>
        <div>
            <span *ngIf="getStatusClass(); let class">
                <i class="{{class}}"></i>
            </span>
            <small class="text-muted ms-2">{{formatTime()}}</small>
        </div>
    </div>
    <ng-container *ngIf="notification.from != null">
        <hr class="my-2"/>
        <div class="PGNotification-Content">
            <div class="PGNotification-Content-Inner">
                <div [innerHTML]="formatContent()"></div>
                <div class="d-flex justify-content-between">
                    <div>
                        <button *ngIf="notification.has_children" class="btn btn-sm btn-link p-0" (click)="toggleChildNotifications()">
                            <i class="fa-regular fa-chevron-{{areChildNotificationsShown ? 'up' : 'down'}}"></i>
                            <span>{{ 'pg-notification.children' | locTranslate }}</span>
                        </button>
                    </div>
                    <div>
                        <a *ngIf="notification.content.link" class="btn btn-sm btn-link p-0" href="{{notification.content.link}}" target="_blank">
                            <span>{{ 'pg-notification.link' | locTranslate }}</span> 
                            <i class="fa-regular fa-arrow-up-right-from-square"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="areChildNotificationsShown" class="PGNotification-Children mt-2">
            <app-pg-loading *ngIf="isLoadingChildren"></app-pg-loading>
            <div *ngIf="!isLoadingChildren" class="border-start border-primary ps-3 mt-1 mb-2">
                <div *ngFor="let child of childNotifications" class="my-2">
                    <app-pg-notification [notification]="child"></app-pg-notification>
                </div>
            </div>
        </div>
    </ng-container>
</div>