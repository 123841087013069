import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PgSemanticViewCardComponent } from './pg-semantic-view-card/pg-semantic-view-card.component';
import { PgSemanticViewSimplecardComponent } from './pg-semantic-view-simplecard/pg-semantic-view-simplecard.component';

import { CommonModule } from '@angular/common';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';

@NgModule({
    declarations: [
        PgSemanticViewCardComponent,
        PgSemanticViewSimplecardComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
        PgUiElementsModule
    ],
    exports: [
        PgSemanticViewCardComponent,
        PgSemanticViewSimplecardComponent
    ],
    providers: [],
    bootstrap: []
})
export class PgSemanticViewModule { }
