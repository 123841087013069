<div class="EFItineraryMap">
    <div *ngIf="directionsUnavailable" class="EFItineraryMap-DirectionsUnavailable">
        <div class="alert alert-warning m-1 px-2 py-1 d-flex align-items-center justify-content-center">
            <div class="me-2">
                <i class="fa-regular fa-triangle-exclamation"></i>
            </div>
            <div>
                <span>{{ 'experience-finder.ef-itinerary-map.no-directions' | locTranslate }}</span>
            </div>
        </div>
    </div>
    <div *ngIf="!directionsUnavailable" class="EFItineraryMap-Status m-3 pe-2 py-1 bg-white text-dark rounded">
        <div *ngIf="getFormattedTravelDistance(); let formattedDistance" class="ms-2">
            <i class="fa-regular fa-road me-1"></i>{{formattedDistance}}
        </div>
        <div *ngIf="getFormattedTravelTime(); let formattedTime" class="ms-2">
            <i class="fa-regular fa-clock me-1"></i>{{formattedTime}}
        </div>
    </div>
    <div class="EFItineraryMap-Buttons my-3 px-3">
        <div *ngIf="hasDirections" class="ms-2 btn-group">
            <button class="btn {{travelMode == 'walking' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('walking')">
                <i class="fa-regular fa-walking"></i>
            </button>
            <button class="btn {{travelMode == 'bicycling' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('bicycling')">
                <i class="fa-regular fa-biking"></i>
            </button>
            <button class="btn {{travelMode == 'driving' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('driving')">
                <i class="fa-regular fa-car"></i>
            </button>
            <button class="btn {{travelMode == 'motorcycle' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('motorcycle')">
                <i class="fa-regular fa-motorcycle"></i>
            </button>
            <button class="btn {{travelMode == 'public_transport' ? 'btn-primary' : 'btn-link bg-white'}}" (click)="setTravelMode('public_transport')">
                <i class="fa-regular fa-bus"></i>
            </button>
        </div>
    </div>
    <div class="EFItineraryMap-Inner GoogleMaps" #gmapsElement></div>
</div>