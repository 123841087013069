import { PGUtilities } from '../pg-utilities';
import { PGMapGeometryPoint } from './map.model';

export class WebTour {
    id:string = null;
    type:string = null;

    name:string = null;
    description:string = null;
    location:string = null;
    coordinates:PGMapGeometryPoint = new PGMapGeometryPoint([0, 0]);
    price:number = null;

    image:string = null;
    logos:Array<string> = null;

    content = new WebTourContent()

    user_id:string = null;
    group_id:string = null;

    translations:{ 
        [language:string]: {
            id:string,
            language:string,
            title: string,
            description: string
        }
    } = {};

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(i != 'content' && i != 'logos' && i != 'coordinates' && i != 'translations') {
                    this[i] = fromData[i]
                }
            }

            if(fromData.content != null) {
                this.content = new WebTourContent(fromData.content);
                this.location = this.content['location'];
                this.image = this.content['image'];  // TODO: andrà gestito tramite la relazione con File
                this.logos = PGUtilities.tryParseJSON(this.content['logos']);
            }

            if(fromData.logos != null) this.logos = PGUtilities.tryParseJSON(fromData.logos);
            if(fromData.coordinates != null) this.coordinates = new PGMapGeometryPoint(fromData.coordinates.coordinates);

            if(fromData.translations != null) {
                for(let _cTranslation of fromData.translations) {
                    this.translations[_cTranslation.language] = _cTranslation;
                }
            }
        }
    }

    getTranslatedProperty(prop:string, language:string) {
        if(language == null) return this[prop];
        else {
            if(this.translations != null && this.translations[language] != null && this.translations[language][prop] != null && this.translations[language][prop] != '') return this.translations[language][prop];
            else return this[prop]
        }
    }

    setTranslatedProperty(prop:string, language:string, val:any) {
        if(language == null) this[prop] = val;
        else {
            if(this.translations == null) this.translations = {};

            if(this.translations[language] == null) {
                this.translations[language] = {
                    id: null,
                    language: language,
                    title: null,
                    description: null
                }
            }

            this.translations[language][prop] = val;
        }
    }

    getLocationTranslatedProperty(location:WebTourLocation, prop:string, language:string) {
        if(language == null) return location[prop];
        else {
            if(location.translations != null && location.translations[language] != null && location.translations[language][prop] != null && location.translations[language][prop] != '') return location.translations[language][prop];
            else return location[prop];
        }
    }

    setLocationTranslatedProperty(location:WebTourLocation, prop:string, language:string, val:any) {
        if(language == null) location[prop] = val;
        else {
            if(location.translations == null) location.translations = {};
            if(location.translations[language] == null) location.translations[language] = {}
            location.translations[language][prop] = val;
        }
    }

    toData() {
        let _retVal:any = {};

        for(let i in this) {
            if(typeof this[i] != 'function') {
                if(this[i] != null) {
                    if(i == 'content') {
                        _retVal[i] = JSON.stringify(this[i]);
                    }
                    else if (i == 'coordinates') {
                        _retVal[i] = JSON.parse(JSON.stringify(this[i]));
                    }
                    else if (i == 'translations') {
                        _retVal.translations = [];

                        for(let j in this.translations) {
                            _retVal.translations.push(this.translations[j])
                        }
                    }
                    else {
                        _retVal[i] = this[i];    
                    }
                }
                else {
                    _retVal[i] = this[i];
                }
            }
        }

        return _retVal;
    }
}

export class WebTourContent {
    intro:Array<WebTourMedia> = [];
    outro:Array<WebTourMedia> = [];

    locations:Array<WebTourLocation> = [];
    actors:Array<WebTourActor> = [];
    languages:Array<string> = [];
    logos:Array<string> = [];

    image:string = null;
    imageOnlyCover?:boolean = null;

    password?:string = null;
 
    constructor(fromData?:any) {
        if(fromData != null) {
            fromData = PGUtilities.tryParseJSON(fromData);

            for(let i in fromData) {
                if(fromData[i] != null) {
                    this[i] = fromData[i];
                }
            }

            // TODO: MIGRAZIONE TOUR

            for(let _cLocation of this.locations) {
                if(_cLocation.sections == null) {
                    let _cMediaList = _cLocation['content'] as any as Array<WebTourMedia>;

                    _cLocation.sections = [{ media: _cMediaList }]

                    delete _cLocation['content'];
                }

                for(let _cSection of _cLocation.sections) {
                    for(let i = 0; i < _cSection.media.length; i++) {
                        if( _cSection.media[i].carousel != null &&  _cSection.media[i].type as string == 'carousel') {
                            let _cCarousel =  _cSection.media[i].carousel;
                            _cSection.media.splice(i, 1);
    
                            for(let j = _cCarousel.length - 1; j >= 0; j--) {
                                _cSection.media.splice(i, 0, {
                                    type: 'image',
                                    file: _cCarousel[j].file,
                                    title: _cCarousel[j].title,
                                })
                            }
                        }
                    }
                }
            }
        }
    }
}

export class WebTourActor {
    id:string = null;
    name:string = null;
    image:string = null;
    color:string = null;

    constructor(id:string) {
        this.id = id;
    }
}

export class WebTourLocationSection {
    title?:string = null;
    media:Array<WebTourMedia> = [];
}

export class WebTourLocation {
    id:string = null;
    title:string = null;
    coordinates?:PGMapGeometryPoint = null;
    map?:string = null;

    sections:Array<WebTourLocationSection> = [{ media: [] }];

    question?:string = null;
    password?:string = null;
    passwordSuccess?:string = null;

    translations?:{ [lang:string]: {
        title?:string;
        question?:string;
        password?:string;
        passwordSuccess?:string;
    } } = null;

    constructor(id:string) {
        this.id = id;
    }
}

export class WebTourMedia {
    type:'html'|'image'|'audio'|'video';
    actor?:string;
    language?:string;

    title?:string = null;
    content?:string = null;
    file?:string = null;

    carousel?:Array<WebTourCarouselSlide> = null;
}

export class WebTourCarouselSlide {
    title:string = null;
    file:string = null;
    showUntil?:number = null;
}