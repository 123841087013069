import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-modal',
  templateUrl: './pg-modal.component.html',
  styleUrls: ['./pg-modal.component.scss']
})
export class PgModalComponent implements OnInit {
    @Input() modalData

    constructor(public modal:NgbActiveModal) { }

    ngOnInit() {
    }

}
