import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { PgCalendar, PGCalendarElement, PGCalendarDay, PGCalendarDate } from './pg-calendar';

@Component({
  selector: 'app-pg-calendar-select',
  templateUrl: './pg-calendar.component.html',
  styleUrls: ['./pg-calendar.component.scss']
})
export class PgCalendarSelectComponent extends PgCalendar implements OnChanges {
    @Input() selectedDate:PGCalendarDate = null;
    @Output() onSelectDate = new EventEmitter<PGCalendarDate>();

    smallMode = true;
    selectOnlyWithElements = false;

    constructor(localizationService:LocalizationService) {
        super(localizationService)
    }

    ngOnChanges() {
        let _cDate = new PGCalendarDate();

        if(this.selectedDate != null) {
            _cDate = this.selectedDate;
        }

        if(this.weeks == null || this.year != _cDate.year || this.month != _cDate.month) {
            this.year = _cDate.year;
            this.month = _cDate.month;

            this.generateCalendar(() => {
                this._selectedDayFromDate();
            });
        }
        else {
            this._selectedDayFromDate();
        }
    }

    private _selectedDayFromDate() {
        this.lastSelectedDay = this.selectedDay;
        this.selectedDay = null;

        if(this.selectedDate != null) {
            for(let _week of this.weeks) {
                for(let _day of _week) {
                    if(_day.date.year == this.selectedDate.year && _day.date.month == this.selectedDate.month && _day.date.day == this.selectedDate.day) {
                        this.selectedDay = _day;
                        break;
                    }

                    if(this.selectedDay != null) break;
                }
            }
        }
    }

    setSelectedDay(dayData:PGCalendarDay) {
        super.setSelectedDay(dayData);

        this.selectedDate = dayData.date;
        this.onSelectDate.emit(this.selectedDate);
    }

    getEventStatusColor(elementData:PGCalendarElement) {}
    getEventStatusIcon(elementData:PGCalendarElement) {}
    getEventStatusTitle(elementData:PGCalendarElement) {}
    getAvailableActions() {}
    onElementAction(action:string, elementData:PGCalendarElement) {}

    getData():Promise<Array<PGCalendarElement>> {
        return new Promise((resolve) => { resolve([]); })
    }
}
