import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PgHelpHttpError, PgHelpJsError, PgHelpTicket } from '../models/pg-help.model';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PgHelpService implements HttpInterceptor, ErrorHandler {

    httpError = new Subject<PgHelpHttpError>();
    httpErrorLog:Array<PgHelpHttpError> = [];

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {    
        let _silent:string = null;

        if(req.headers.has('X-Interceptor-Silent')) {
            _silent = req.headers.get('X-Interceptor-Silent')

            req = req.clone({
                headers: req.headers.delete('X-Interceptor-Silent')
            });
        }

        return next.handle(req).pipe(tap((resp) => {
        
        }, (error:HttpErrorResponse) => {
            let _handleError = true;

            if(_silent != null) {
                if(_silent == '*') {
                    _handleError = false;
                }
                else {
                    let _errorStatus = error.status.toString();
                    if(_silent.split(',').indexOf(_errorStatus) != -1) {
                        _handleError = false;
                    }
                }
            }

            if(_handleError) {
                let _error:PgHelpHttpError = { time: Date.now(), request: req, response: error }
            
                this.httpErrorLog.push(_error);
                this.httpError.next(_error);
            }
            else {
                console.log('X-Interceptor-Silent: ' + _silent + ' prevented default error handling')
                console.log(req, error)
            }
        }))
    }

    jsError = new Subject<PgHelpJsError>();
    jsErrorLog:Array<PgHelpJsError> = [];

    handleError(error:any) {        
        console.error(error)
        
        let _error:PgHelpJsError = { time:Date.now(), error: error }

        this.jsErrorLog.push(_error);
        this.jsError.next(_error);
    }

    getTicketData() {
        let _httpErrorLog:Array<PgHelpHttpError> = []
        let _jsErrorLog:Array<PgHelpJsError> = []

        for(let _error of this.httpErrorLog) {
            _httpErrorLog.push(_error)
        }

        for(let _error of this.jsErrorLog) {
            _jsErrorLog.push(_error)
        }

        let _retVal = new PgHelpTicket();

        _retVal.url = window.location.toString();
        
        _retVal.info = {
            user_agent: navigator.userAgent || navigator.vendor
        }

        _retVal.httpErrorLog = _httpErrorLog;
        _retVal.jsErrorLog = _jsErrorLog;

        return _retVal;
    }
}
