<div class="EFShowcase text-light">
    <ng-container *ngFor="let product of showcaseProductsList; let i = index">
        <div *ngIf="i == previousShowcaseIndex || i == currentShowcaseIndex || i == nextShowcaseIndex" class="EFShowcase-Item {{ i == previousShowcaseIndex ? 'EFShowcase-Item--Previous pg-animation-fadeout' : i == currentShowcaseIndex ? 'EFShowcase-Item--Current pg-animation-fadein' : 'EFShowcase-Item--Next' }}">
            <img class="EFShowcase-Item-Image" src="{{product.images[0]}}"/>
            <div class="EFShowcase-Item-Text">
                <h2 class="m-0">{{getTranslatedProperty(product, 'title')}}</h2>
                <p *ngIf="formatProductLocation(product); let location" class="m-0 mt-2">{{location}}</p>
            </div>
        </div>
    </ng-container>
</div>
