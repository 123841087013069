import { Component, forwardRef } from '@angular/core';

import { 
    NG_VALUE_ACCESSOR, 
    NG_VALIDATORS 
} from '@angular/forms';
import { PgSelectComponent } from '../pg-select/pg-select.component';

@Component({
  selector: 'app-pg-list-editor',
  templateUrl: './pg-list-editor.component.html',
  styleUrls: ['./pg-list-editor.component.scss'],
  providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgListEditorComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgListEditorComponent),
        multi: true,
    }]
})

export class PgListEditorComponent extends PgSelectComponent {
    multi = true;
}

