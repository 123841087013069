import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PgProfileEditorService {
    constructor() { }

    request = new Subject<{ fn: string }>()

    requestChangePassword() {
        this.request.next({ fn: 'change-password' })
    }
}
