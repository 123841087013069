import { AfterViewChecked, Directive, ElementRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QrLinksModalComponent } from './qr-links-modal/qr-links-modal.component';

@Directive({
  selector: '[appQrLinks]'
})
export class QrLinksDirective implements AfterViewChecked {

    constructor(private el: ElementRef, private modalService:NgbModal) {
    }

    @Input() appQrLinks:string|boolean = null;

    ngAfterViewChecked() {
        if(this.appQrLinks != false && this.appQrLinks != 'false') {
            let _linkList = (this.el.nativeElement as HTMLElement).getElementsByTagName('a');

            for(let i = 0; i < _linkList.length; i++) {
                let _cLink = _linkList.item(i);

                if(!_cLink['_isBound']) {
                    _cLink['_isBound'] = true;
                    _cLink.addEventListener('click', (event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        
                        let _cModal = this.modalService.open(QrLinksModalComponent);
                        (_cModal.componentInstance as QrLinksModalComponent).targetURL = _cLink.getAttribute('href')
                    })
                }
            }
        }
    }

}
