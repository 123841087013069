import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent, HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Subject, Observer } from 'rxjs';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { EnvironmentService } from './environment.service';
import { PGUtilities } from '../pg-utilities';

class ApiInterceptorQueueItem { 
    req: HttpRequest<any>;
    next: HttpHandler;
    observer: Observer<HttpEvent<any>>;
}

@Injectable({
    providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

    constructor(private environmentService:EnvironmentService, private authService:AuthService) { }

    private _isReleaseRequest(req: HttpRequest<any>) {
        return /^assets\/release\.json$/.test(req.url)
    }

    private _isAssetsRequest(req: HttpRequest<any>) {
        return /^assets\/[^\?]*$/.test(req.url)
    }

    private _isAPIRequest(req: HttpRequest<any>) {
        return req.url.startsWith(this.environmentService.environment.APIUrl)
    }

    private async _checkRequestAuthToken(req: HttpRequest<any>) {
        if(this._isAPIRequest(req)) {
            let _ignoreLock = /\/refresh\?refresh_token/.test(req.urlWithParams)
            let _cToken = await this.authService.getAuthToken(_ignoreLock);
        
            if(_cToken != null) {
                return req.clone({
                    setHeaders: {
                        'Authorization': 'Bearer ' + _cToken
                    }
                });
            }
            else {
                return req;
            }
        }
        else {
            return req;
        }
    }

    private _calculateNextDelay(req:HttpRequest<any>, resp:HttpResponse<any>, time:number) {
        if(this._isAPIRequest(req)) {
            let _rateLimit = parseInt(resp.headers.get('x-ratelimit-limit'));
            let _rateRemaining = parseInt(resp.headers.get('x-ratelimit-remaining'));

            if(!isNaN(_rateLimit) && !isNaN(_rateRemaining)) {
                let _rateAverage = 60 / _rateLimit * 1000;
                let _ratePoint = 1 - (_rateRemaining / _rateLimit);

                let _nextDelay = Math.floor(Math.pow(_ratePoint, 2) * 4 * _rateAverage);
                let _cleanDelay = Math.max(1, _nextDelay - time);

                //console.log('API Rate limit ' + Math.floor(_ratePoint * 100) + '% Delay (' + _nextDelay + ' - ' + time + ') = ' + _cleanDelay);

                return _cleanDelay;
            }
            else {
                let _nextDelay = 250;
                let _cleanDelay = Math.max(1, _nextDelay - time);
                //console.log('API Fixed Delay (' + _nextDelay + ' - ' + time + ') = ' + _cleanDelay);

                return _cleanDelay;
            }
        }
        else {
            let _nextDelay = 250;
            let _cleanDelay = Math.max(1, _nextDelay - time);
            //console.log('NON-API Delay (' + _nextDelay + ' - ' + time + ') = ' + _cleanDelay);

            return _cleanDelay;
        }
    }

    private _requestQueue:Array<ApiInterceptorQueueItem> = []

    private _retryRequestCount:number = null;
    private _requestQueueLock = false;
    private _checkRequestQueue() {
        if(!this._requestQueueLock) {
            let _ctx = this._requestQueue[0];

            if(_ctx != null) {
                this._requestQueueLock = true;

                let _timeStart = Date.now();

                this._checkRequestAuthToken(_ctx.req).then((req) => {
                    _ctx.req = req;

                    _ctx.next.handle(_ctx.req).subscribe((resp) => {
                        if(resp.type == HttpEventType.Response) {
                            let _delay = this._calculateNextDelay(_ctx.req, resp, Date.now() - _timeStart);
                            this._onRequestComplete(this._onRequestSuccess(_ctx, resp), _delay);
                        }
                        else {
                            _ctx.observer.next(resp);
                        }
                    }, (error) => {
                        let _mustRetry = this._isAPIRequest(_ctx.req) && error.status == 429;
                        
                        if(_mustRetry) {
                            this._retryRequestCount++;
                            if(this._retryRequestCount > 15) {
                                _mustRetry = false;
                            }
                        }
    
                        if(_mustRetry) {
                            setTimeout(() => {
                                this._requestQueueLock = false;
                                this._checkRequestQueue();
                            }, 5000)
                        }
                        else {
                            this._retryRequestCount = 0;
    
                            if(this._isAPIRequest(_ctx.req) && error.status == 401 && this.authService.isLoggedIn()) {
                                this.authService.clearAuthToken();
                                window.location.reload();
                            }
                            else {
                                let _delay = this._calculateNextDelay(_ctx.req, error, Date.now() - _timeStart);
                                this._onRequestComplete(this._onRequestError(_ctx, error), _delay);
                            }
                        }
                    })
                })
            }   
        }
    }

    private _isSameContext(ctxa:ApiInterceptorQueueItem, ctxb:ApiInterceptorQueueItem) {
        if(ctxa == ctxb) return true;
        else { // NB: lo applico solo alle GET
            if(ctxa.req.method.toUpperCase() == 'GET' && ctxb.req.method.toUpperCase() == 'GET' && ctxa.req.urlWithParams == ctxb.req.urlWithParams) {
                /*if((ctxa.req.body == null && ctxb.req.body == null) || (JSON.stringify(ctxa.req.body) == JSON.stringify(ctxb.req.body))) return true
                else return false;*/
                return true
            }
        }
    }

    private _onRequestSuccess(ctx:ApiInterceptorQueueItem, event:HttpEvent<any>) {
        let _retVal:Array<ApiInterceptorQueueItem> = [];

        for(let i = this._requestQueue.length - 1; i >= 0; i--) {
            if(this._isSameContext(ctx, this._requestQueue[i])) {
                _retVal.push(this._requestQueue[i]);
                this._requestQueue[i].observer.next(event);
            }
        }

        return _retVal;
    }

    private _onRequestError(ctx:ApiInterceptorQueueItem, error:any) {
        let _retVal:Array<ApiInterceptorQueueItem> = [];

        for(let i = this._requestQueue.length - 1; i >= 0; i--) {
            if(this._isSameContext(ctx, this._requestQueue[i])) {
                _retVal.push(this._requestQueue[i]);
                this._requestQueue[i].observer.error(error);
            }
        }

        return _retVal;
    }

    private _onRequestComplete(ctxList:Array<ApiInterceptorQueueItem>, delay:number) {
        this._retryRequestCount = 0;

        setTimeout(() => {
            for(let _ctx of ctxList) {
                _ctx.observer.complete()

                let _index = this._requestQueue.indexOf(_ctx);
                if(_index != -1) {
                    this._requestQueue.splice(_index, 1);
                }
            }
            this._requestQueueLock = false;
            this._checkRequestQueue();
        }, delay)
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(this._isReleaseRequest(req)) {
            req = req.clone({
                setParams: { 't': Date.now().toString() }
            });
        }
        else if(this._isAssetsRequest(req)) {
            req = req.clone({
                setParams: { 'v': this.environmentService.getBuildTime() }
            });
        }
        else if(this._isAPIRequest(req)) {
            req = req.clone({
                setHeaders: { 'PG-TransactionId': PGUtilities.getRandomId() }
            });
        }

        return new Observable<HttpEvent<any>> ((observer) => {
            this._requestQueue.push({ 
                req: req, 
                next: next, 
                observer: observer 
            });
            this._checkRequestQueue();
        })
    }
}