<ng-template #profileEditorTemplate>
    <ng-container *ngIf="editorSection == 'change-password'">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'pg-profile-editor.title-change-password' | locTranslate }}</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="closeEditor()"></button>
        </div>
        <div class="modal-body PGProfileEditor">
            <p class="text-muted mb-3">
                {{'pg-profile-editor.subtitle-change-password' | locTranslate}}
            </p>

            <div class="form-group">
                <label>
                    <b>{{ 'pg-profile-editor.input-new-password' | locTranslate }}</b>
                </label>
                <input type="password" class="form-control" [(ngModel)]="changePasswordNew" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" (ngModelChange)="onChangePasswordNewChange()" (keyup)="onChangePasswordKeyUp($event)"/>
            </div>
            <div class="form-group">
                <label>
                    <b>{{ 'pg-profile-editor.input-new-password-confirm' | locTranslate }}</b>
                </label>
                <input type="password" class="form-control" [(ngModel)]="changePasswordNewConfirm" placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;" (ngModelChange)="onChangePasswordNewChange()" (keyup)="onChangePasswordKeyUp($event)"/>
            </div>
            <div class="form-group">
                <div *ngIf="changePasswordErrorLength" class="text-danger">{{ 'pg-profile-editor.change-password-error-length' | locTranslate }}</div>
                <div *ngIf="changePasswordErrorFormat" class="text-danger">{{ 'pg-profile-editor.change-password-error-format' | locTranslate }}</div>
                <div *ngIf="changePasswordErrorConfirm" class="text-danger">{{ 'pg-profile-editor.change-password-error-confirm' | locTranslate }}</div>
            </div>
            <button class="btn btn-primary btn-block" [disabled]="!canChangePassword()" (click)="doChangePassword()">
                <i class="fa-regular fa-sign-in-alt"></i>
                <span>{{ 'pg-profile-editor.button-change-password' | locTranslate }}</span>
            </button>
        </div>
    </ng-container>
</ng-template>
