import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionMapsService } from '../../services/option-maps.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pg-realm-picker',
  templateUrl: './pg-realm-picker.component.html',
  styleUrls: ['./pg-realm-picker.component.scss']
})
export class PgRealmPickerComponent implements OnInit {

    selectOptions:Array<{ value: string, text: string }> = []
    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private authService:AuthService, private optionMapsService:OptionMapsService) { }

    ngOnInit(): void {
        this.selectOptions = this.optionMapsService.getFixedOptionMap('*', 'realm_id');
    }

    isSuperAdmin() {
        return this.authService.isSuperAdmin()
    }
}
