import { Component, OnChanges, Input, forwardRef, ViewChild, ElementRef } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';

class PgTagsOption {
    value: string
    view?: string
}

@Component({
    selector: 'app-pg-tags',
    templateUrl: './pg-tags.component.html',
    styleUrls: ['./pg-tags.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgTagsComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgTagsComponent),
        multi: true,
    }]
})
export class PgTagsComponent implements OnChanges, ControlValueAccessor, Validator {

    @Input() fieldId:string;

    @Input() readonly: boolean;

    @Input() required: boolean;

    @Input() placeholder:string;

    options:Array<PgTagsOption> = [];

    isDropdownLoading = false;

    value:string = null;

    constructor(private dataService:DataService) { }

    ngOnChanges(simpleChanges) {
        this.doSearch();
    }

    private _searchTimeout = null;
    private _searchObserver:Subscription = null;
    private _lastSearch:string = null;

    doSearch(delay?:number) {
        if(this._searchTimeout != null) {
            clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
        }

        if(this._searchObserver != null) {
            this._searchObserver.unsubscribe();
            this._searchObserver = null;
        }

        let _searchValue = (this.value || '').replace(/^.*,/, '');
    
        if(_searchValue != this._lastSearch) {
            if(delay == null) delay = 10;

            this.isDropdownLoading = true;

            this._searchTimeout = setTimeout(() => {
                this._searchTimeout = null;

                this._searchObserver = this.dataService.getSearchTags(_searchValue).subscribe(data => {
                    this._searchObserver = null;
                    
                    this.options = [];

                    for(let _item of data) {
                        this.options.push({ value: _item.label })
                    }

                    this._highlightSearch(_searchValue);

                    this.isDropdownLoading = false;

                    this._lastSearch = _searchValue;
                })
            }, delay)
        }
    }

    private _highlightSearch(highlight:string) {
        if(highlight != null && highlight != '') {
            let _searchRegExp = new RegExp('(' + highlight.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')', 'i');

            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].value.replace(_searchRegExp, '<b>$1</b>')
            }
        }
        else {
            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].value;
            }
        }
    }

    hasFocus = false;
    isDropdownClosing = false;
    isDropdownOpen = false;

    @ViewChild(NgbDropdown, { static: false }) dropdownComponent:NgbDropdown;

    private _blurTimer = null;

    onInputFocus() {
        this.hasFocus = true;
        this.isDropdownOpen = true;
        this.isDropdownClosing = false;

        clearTimeout(this._blurTimer);
        
        this.dropdownOpen();
    }

    onInputBlur() {
        this.hasFocus = false;

        clearTimeout(this._blurTimer);

        this._blurTimer = setTimeout(() => {
            this.isDropdownClosing = true;
            this._blurTimer = setTimeout(() => {
                this.isDropdownClosing = false;
                this.isDropdownOpen = false;
                this.dropdownClose();
            }, 250);
        }, 100);
    }

    dropdownOpen() {
        if(!this.readonly) {
            if(this.dropdownComponent != null) {
                this.dropdownComponent.open()
            }
        }
    }

    dropdownClose() {
        if(this.dropdownComponent != null) {
            this.dropdownComponent.close();
        }
    }

    isOptionSelected(option:PgTagsOption) {
        if(this.value != null) {
            let _tags = this.value.split(',')

            for(let _item of _tags) {
                if(_item.toLowerCase() == option.value.toLowerCase()) return true;
            }
        }
    }

    @ViewChild('inputElement') inputElement:ElementRef

    selectOption(option:PgTagsOption) {
        if(option != null) {
            if(this.isOptionSelected(option)) {
                let _cleanVal = (this.value || '').replace(option.value, '').replace(/^,+/, '').replace(/,,+/g, ',')
                this.setValue(_cleanVal)
            }
            else {
                let _cleanVal = (this.value || '').replace(/(,?)[^,]*$/, '$1') + option.value + ','
                this.setValue(_cleanVal)
            }
        }

        setTimeout(() => {
            if(this.inputElement != null) {
                this.inputElement.nativeElement.focus();
            }
        }, 100)
    }

    setValue(val:string) {
        let _didChange = this.value != val;
        this.value = val

        if(_didChange) {
            if(this._onChange != null) this._onChange(this.value);
            this.doSearch(250)
        }
    }

    // TO FIX: interazione via tastiera

    onKeyPress(eventObj:KeyboardEvent) {
        if(eventObj.keyCode == 38) {
            // arrow up
            eventObj.preventDefault();
            eventObj.stopPropagation();

            if(this.value == null || this.value === '') {
                this.selectOption(this.options[this.options.length - 1]);
            }
            else {
                let _cIndex = -1;
                
                for(let i = 0; i < this.options.length; i++) {
                    if(this.options[i].value == this.value) {
                        _cIndex = i;
                        break;
                    }
                }

                _cIndex--;

                if(_cIndex < 0) {
                    _cIndex = this.options.length - 1;
                }

                this.selectOption(this.options[_cIndex]);
            }
        }
        else if(eventObj.keyCode == 40) {
            // arrow down
            eventObj.preventDefault();
            eventObj.stopPropagation();

            if(this.value == null || this.value === '') {
                this.selectOption(this.options[0]);
            }
            else {
                let _cIndex = -1;
                
                for(let i = 0; i < this.options.length; i++) {
                    if(this.options[i].value == this.value) {
                        _cIndex = i;
                        break;
                    }
                }
                
                _cIndex = (_cIndex + 1) % this.options.length;

                this.selectOption(this.options[_cIndex]);
            }
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || (this.value !== '' && this.value != null))  ? null : {
            required: {
                valid: false
            }
        }
    };
}
