<div class="PGBookingsCalendar">
    <div class="PGBookingsCalendar-Header">
        <div class="PGBookingsCalendar-Header-Controls border-bottom pb-2 mb-3">
            <ng-container *ngIf="confirmBooking == null">
                <div class="PGBookingsCalendar-Header-Controls-Left">
                    <button class="btn btn-link" (click)="goPrevMonth()"><i class="fa-regular fa-chevron-left"></i></button>
                </div>
                <div class="PGBookingsCalendar-Header-Controls-Center">
                    <h4 class="mb-0">{{localizationService.data.monthNames[month]}} {{year}}</h4>
                </div>
                <div class="PGBookingsCalendar-Header-Controls-Right">
                    <button class="btn btn-link" (click)="goNextMonth()"><i class="fa-regular fa-chevron-right"></i></button>
                </div>
            </ng-container>
            <ng-container *ngIf="confirmBooking != null">
                <div class="PGBookingsCalendar-Header-Controls-Left"> 
                    <button class="btn btn-link" (click)="hideConfirmBooking()"><i class="fa-regular fa-chevron-left"></i></button> 
                </div>
                <div class="PGBookingsCalendar-Header-Controls-Center">
                    <h4 class="mb-0">{{confirmBooking.experience.title}} - {{confirmBooking.date | locFormat:'date'}}<ng-container *ngIf="confirmBooking.time != null"> - {{confirmBooking.time | locFormat:'time'}}</ng-container></h4>
                </div>
                <div class="PGBookingsCalendar-Header-Controls-Right">
                </div>
            </ng-container>
        </div>
    </div>
    <div *ngIf="confirmBooking == null" class="PGBookingsCalendar-Content">
        <div *ngIf="requestAs == 'supplier'">
            <ng-container *ngFor="let type of ['supplier/experience','supplier/host','supplier/eatery']">
                <button class="btn btn-white {{type == requestType ? 'btn-white-selected' : ''}}" (click)="requestType = type; reset()">{{ 'pg-bookings-calendar.request-' + type | locTranslate}}</button>
            </ng-container>
        </div>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="bookingsByDay.length == 0">
                <h5 class="text-muted mx-2 my-3">{{ 'pg-bookings-calendar.bookings-none' | locTranslate }}</h5>
            </div>
            <div *ngFor="let date of bookingsByDay" class="PGBookingsCalendar-Content-Date mb-3">
                <ng-container *ngIf="date != null">
                    <div class="PGBookingsCalendar-Content-Date-Heading mt-3 pb-2 mb-2 border-bottom">
                        <h5 class="m-0 mx-2">{{date.day}} {{localizationService.data.dayNames[date.weekday]}}</h5>
                    </div>
                    
                    <div *ngFor="let experience of date.experiences" class="PGBookingsCalendar-Content-Date-Experience">
                        <div class="PGBookingsCalendar-Content-Date-Experience-Heading mb-2 px-2">
                            <div>
                                <div *ngIf="experience.image != null" class="PGBookingsCalendar-Content-Date-Experience-Heading-Image border rounded">
                                    <img src="{{experience.image}}"/>
                                </div>
                                <div>{{experience.title}}</div>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-primary py-0 px-1 d-block" (click)="exportBookings(date.day, experience)">
                                    <i class="fa-regular fa-download"></i><span>{{ 'pg-bookings-calendar.bookings-download' | locTranslate }}</span>
                                </button>
                            </div>
                        </div>
                        <div *ngFor="let slot of experience.slots" class="PGBookingsCalendar-Content-Date-Experience-Slot mb-2">
                            <button class="btn btn-block btn-light" (click)="showConfirmBooking(date.day, experience, slot)">
                                <app-pg-bookings-slot-summary [slotStart]="slot.start" [slotEnd]="slot.end" [productData]="experience.product" [bookingsData]="slot.bookings"></app-pg-bookings-slot-summary>
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <app-pg-view-more [hidden]="isLoading || atEnd" (viewMore)="loadMore()"></app-pg-view-more>
        <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    </div>
    <div *ngIf="confirmBooking != null" class="PGBookingsCalendar-Confirm">
        <app-pg-confirm-booking [requestAs]="requestAs" [productId]="confirmBooking.experience.id" [productType]="confirmBooking.experience.type" [productDate]="confirmBooking.date" [productTime]="confirmBooking.time"></app-pg-confirm-booking>
    </div>
</div>