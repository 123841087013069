<div class="EFList container-fluid {{ full ? 'EFList--Full' : vertical ? 'EFList--Vertical' : 'EFList--Horizontal'}} ">
    <div class="row py-3">
        <div *ngIf="(productsData == null || productsData.length == 0)" class="mb-3 col-12">
            <h5 class="text-muted">{{ 'experience-finder.general.no-results' | locTranslate }}</h5>
        </div>
        <ng-container *ngIf="(!vertical && !full) || (productsData != null && productsData.length > 0)">
            <div *ngFor="let product of visibleProductsData; let i = index" class="{{ full ? 'col-12 col-md-6 col-lg-4' : vertical ? 'col-12': oneItem ? 'col-10' : 'col-10 col-md-5 col-lg-4' }}">
                <app-ef-product-card [productData]="product" [vertical]="vertical || full" (showProduct)="showProduct.emit($event)"></app-ef-product-card>
            </div>
            <div class="{{ full ? 'col-12 col-md-6 col-lg-4' : vertical ? 'col-12': oneItem ? 'col-10' : 'col-10 col-md-5 col-lg-4' }}" [hidden]="visibleProductsData.length == productsData.length">
                <app-pg-view-more (viewMore)="viewMoreProducts()"></app-pg-view-more>
            </div>
        </ng-container>
    </div>
</div>
