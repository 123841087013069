import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigProfileRoleActions } from '../../models/config.profiles.model';
import { ConfigService } from '../../services/config.service';
import { DataService, GroupData } from '../../services/data.service';
import { SaveStatusService } from '../../pg-ui-elements/save-status.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pg-admin-groups',
  templateUrl: './pg-admin-groups.component.html',
  styleUrls: ['./pg-admin-groups.component.scss']
})
export class PgAdminGroupsComponent implements OnInit {


    isLoading = false;

    addGroupName:string = null;

    groups:Array<GroupData> = null;

    constructor(private dataService:DataService, private configService:ConfigService, private saveStatusService:SaveStatusService, private authService:AuthService) { }

    actions:ConfigProfileRoleActions = null;

    ngOnInit() {
        this.actions = this.configService.getResourceActions('Group')

        if(this.actions.edit) {
            this.saveStatusService.createRequest('edit', 'Admin Groups', (action:string) => {
                if(action == 'save') {
                    return this.saveChanges()
                }
            }, 'pg-admin-groups')
        }

        this.loadData();
    }

    ngOnDestroy(): void {
        this.saveStatusService.clearTopic('pg-admin-groups')
    }

    loadData() {
        this.groups = [];

        for(let _cGroup of this.configService.groups) {
            this.groups.push(JSON.parse(JSON.stringify(_cGroup)));
        }
    }

    deleteGroup(group:GroupData) {
        let _cIndex = this.groups.indexOf(group);

        if(_cIndex != -1) {
            this.groups.splice(_cIndex, 1);
        }
    }

    addGroup() {
        if(this.addGroupName != null && this.addGroupName != '') {
            this.groups.push({ id: null, label: this.addGroupName });
            this.addGroupName = null;
        }
    }

    saveChanges() {
        return new Promise<void>((resolve, reject) => {
            let _reqList:Array<Observable<any>> = [];

            let _skipGroups = [];
    
            for(let _cGroupOriginal of this.configService.groups) {
                let _isIn = false;
    
                for(let _cGroup of this.groups) {
                    if(_cGroupOriginal.id == _cGroup.id) {
                        _isIn = true;
    
                        if(JSON.stringify(_cGroupOriginal) == JSON.stringify(_cGroup)) {
                            _skipGroups.push(_cGroup)
                        }
                        break;
                    }
                }
    
                if(!_isIn) {
                    _reqList.push(this.dataService.deleteElement('Group', _cGroupOriginal.id));
                }
            }
    
            for(let _cGroup of this.groups) {
                if(_cGroup.id == null) {
                    _reqList.push(this.dataService.postResourceData('Group', {
                        label: _cGroup.label,
                        realm_id: this.authService.user.realm?.id
                    }).pipe((tap((data) => {
                        _cGroup.id = data.id
                    }))));
                }
                else {
                    if(_skipGroups.indexOf(_cGroup) == -1) {
                        _reqList.push(this.dataService.putElementData('Group', _cGroup.id, { id: _cGroup.id, label: _cGroup.label }))
                    }
                }
            }
    
            if(_reqList.length == 0) {
                resolve()
            }
            else {
                this.isLoading = true;
    
                this._runRequests(_reqList, () => {
                    //this.dataService.clearCache(); 
                    window.location.reload();
                    resolve()
                });
            }
        })
    }

    private _runRequests(list:Array<Observable<any>>, onComplete:() => void, index?:number) {
        if(index == null) index = 0;

        if(list[index] == null) {
            onComplete();
        }
        else {
            list[index].subscribe(() => {
                this._runRequests(list, onComplete, index + 1);
            })
        }
    }
}
