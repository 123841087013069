<div class="FormsFile">
    <app-pg-loading *ngIf="isLoading || isSaving" [isOverlay]="isSaving"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <app-pg-file-preview *ngIf="viewOnly || openMode == 'edit'" [fileData]="elementData" [viewMode]="elementData?.type"></app-pg-file-preview>
        <ng-container *ngIf="openMode == 'create'">
            <h4 class="mb-3">{{ 'forms-files.upload-files' | locTranslate }}</h4>
            <div class="container FormsFile-Upload border-top border-bottom py-3">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex pb-3">
                            <app-pg-switch [small]="true" [(ngModel)]="skipPresentFiles"></app-pg-switch>
                            <div class="ms-2">{{ 'forms-files.skip-present-files' | locTranslate }}</div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div id="{{randomValue}}_upload_directories">
                        <div *ngIf="saveDirectoriesProgress != null" class="pg-animation-slidedown mb-3">
                            <div class="mb-1">{{ 'forms-files.preparing-upload' | locTranslate }}</div>
                            <div class="progress progress-thin">
                                <div class="progress-bar {{ saveDirectoriesProgress == 1 ? 'bg-success' : '' }}" [style.width]="(saveDirectoriesProgress * 100) + '%'"></div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let upload of uploadFiles; let i = index" class="col-12 col-md-6 col-lg-4 pb-3">
                        <div class="FormsFile-Upload-Item pg-animation-slidedown" id="{{randomValue}}_upload_{{i}}">
                            <div>
                                <app-pg-file-preview [fileData]="upload.file"></app-pg-file-preview>
                                <div class="progress progress-thin">
                                    <div class="progress-bar {{ getResultProgressClass(upload.result) }}" [style.width]="(upload.progress * 100) + '%'"></div>
                                </div>
                            </div>
                            <div class="{{ isSaving ? 'opacity-00' : '' }}">
                                <button *ngIf="upload.result != 'success'" [disabled]="isSaving" class="btn btn-link text-danger" (click)="removeUploadFile(i)">
                                    <i class="fa-regular fa-trash fa-fw"></i>
                                </button>
                                <button *ngIf="upload.result == 'success'" [disabled]="isSaving" class="btn btn-link text-success" (click)="removeUploadFile(i)">
                                    <i class="fa-regular fa-check fa-fw"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 d-flex">
                        <div>
                            <app-pg-upload-button buttonIcon="fa-regular fa-folder" buttonText="{{ 'forms-files.add-directory' | locTranslate }}" [directory]="true" [multiple]="true" (setFiles)="addUploadFiles($event)"></app-pg-upload-button>
                        </div>
                        <div class="ms-2">
                            <app-pg-upload-button buttonIcon="fa-regular fa-file" buttonText="{{ 'forms-files.add-files' | locTranslate }}" [multiple]="true" (setFiles)="addUploadFiles($event)"></app-pg-upload-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="viewOnly">
            <app-pg-detail [formLayout]="formLayout" [hideEmpty]="true" [currentLanguage]="true"></app-pg-detail>
        </div>
        <div *ngIf="!viewOnly" class="container-fluid">
            <app-pg-form #formComponent [formLayout]="formLayout" [hasSubmit]="false" (valueChange)="onValueChange($event)"></app-pg-form>
        </div>
    </ng-container>
</div>