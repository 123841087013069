import { Component, OnDestroy, OnInit } from '@angular/core';
import { SaveStatusRequest, SaveStatusService } from '../save-status.service';

@Component({
  selector: 'app-pg-save-status',
  templateUrl: './pg-save-status.component.html',
  styleUrls: ['./pg-save-status.component.scss']
})
export class PgSaveStatusComponent implements OnInit, OnDestroy {

    constructor(private saveStatusService:SaveStatusService) { }

    requests:Array<SaveStatusRequest> = null;

    hasAction(request:SaveStatusRequest, name:string) {
        return request.actions[name] != null;
    }

    isActionDisabled(request:SaveStatusRequest, name:string) {
        return request.disabled || request.status == 'running' || !request.actions[name];
    }

    ngOnInit(): void {
        this.requests = this.saveStatusService.addListener();
    }

    ngOnDestroy(): void {
        this.saveStatusService.removeListener();
    }
}
