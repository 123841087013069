import { Component, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { DataService } from '../../services/data.service';
import { SemanticsService } from '../../services/semantics.service';
import { PgSemanticView } from '../pg-semantic-view-common';

@Component({
  selector: 'app-pg-semantic-view-simplecard',
  templateUrl: './pg-semantic-view-simplecard.component.html',
  styleUrls: ['./pg-semantic-view-simplecard.component.scss']
})
export class PgSemanticViewSimplecardComponent extends PgSemanticView implements AfterViewChecked {
    @ViewChild('simpleCard', { static: true }) simpleCard:ElementRef;

    constructor(dataService:DataService, configService:ConfigService, semanticsService:SemanticsService) {
        super(dataService, configService, semanticsService);
    }

    ngAfterViewChecked() {
        //this.simpleCard.nativeElement.style.height = Math.round(this.simpleCard.nativeElement.offsetWidth * 3 / 4) + 'px';
    }

    encodeURIComponent(value:string) {
        return encodeURIComponent(value);
    }
}

