import { AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

/**
 * Controllo selezione orario
 */

@Component({
    selector: 'app-pg-time-picker',
    templateUrl: './pg-time-picker.component.html',
    styleUrls: ['./pg-time-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgTimePickerComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgTimePickerComponent),
        multi: true,
    }]
})
export class PgTimePickerComponent implements ControlValueAccessor, Validator, AfterViewInit {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() size:'small'|'medium'|'large';

    value: {
        hour: number,
        minute: number
    } = null;

    @ViewChild('timepickerContainer') timepickerContainer:ElementRef;

    ngAfterViewInit() {
        if(this.fieldId != null) {
            let _cInput = this.timepickerContainer.nativeElement.getElementsByTagName('input')[0] as HTMLInputElement

            if(_cInput != null) {
                _cInput.setAttribute('id', this.fieldId)
            }
        }
    }

    onValueChange() {
        let _cOutValue:string = null;
        if(this.value != null) {
            _cOutValue = '';
            if(this.value.hour < 10) _cOutValue += 0;
            _cOutValue += this.value.hour.toString();
            _cOutValue += ':';
            if(this.value.minute < 10) _cOutValue += 0;
            _cOutValue += this.value.minute.toString();
        }

        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(_cOutValue);
    }

    getReadonlyHours() {
        if(this.value == null) return 'HH'
        else {
            let _retVal = this.value.hour.toString();
            if(_retVal.length < 2) _retVal = '0' + _retVal;
            return _retVal;
        }
    }

    getReadonlyMinutes() {
        if(this.value == null) return 'MM'
        else {
            let _retVal = this.value.minute.toString();
            if(_retVal.length < 2) _retVal = '0' + _retVal;
            return _retVal;
        }
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        if(obj != null && /^\d\d:\d\d/.test(obj)) {
            this.value = {
                hour: parseInt(obj.split(':')[0]),
                minute: parseInt(obj.split(':')[1])
            }
        }
        else this.value = null;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        return (!this.required || this.value != null)  ? null : {
            required: {
                valid: false
            }
        }
    };
}