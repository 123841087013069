import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EFDataService, EFProduct, EFSupplier } from 'pg-lib';

@Component({
  selector: 'app-ef-product-view',
  templateUrl: './ef-product-view.component.html',
  styleUrls: ['./ef-product-view.component.scss']
})
export class EfProductViewComponent implements OnInit {

    isLoading = false;

    appMode = 'web';

    productData:EFProduct = null;
    supplierData:EFSupplier = null;
    
    resourceId:string = null;
    elementId:string = null;

    constructor(private dataService:EFDataService, private route:ActivatedRoute) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.isLoading = true;

            this.resourceId = params.resource;
            this.elementId = params.id;

            this.productData = null;
            this.supplierData = null;

            this.dataService.getResourceProduct(this.resourceId, this.elementId).subscribe((data) => {
                this.productData = data;

                if(data.supplier != null) {
                    this.dataService.getResourceSupplier(data.supplier.split('_')[0], data.supplier.split('_')[1]).subscribe((data) => {
                        this.supplierData = data;
                        this.isLoading = false;
                    })
                }
                else {
                    this.isLoading = false;
                }
            })
        })
    }

    onShowProduct(product:string) {

    }
}
