import { Component, Input, OnChanges, OnInit } from '@angular/core';

import * as Mustache from 'mustache';

@Component({
  selector: 'app-pg-slug-editor',
  templateUrl: './pg-slug-editor.component.html',
  styleUrls: ['./pg-slug-editor.component.scss']
})
export class PgSlugEditorComponent implements OnInit, OnChanges {

    @Input() resourceId:any;

    @Input() slugData:any;
    @Input() slugFormat:string;

    slugValue:string = null;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges() {
        if(this.slugFormat == null || this.slugData == null) {
            this.slugValue = null;
        }
        else {
            let _div = document.createElement('div');
            _div.innerHTML = Mustache.render(this.slugFormat, this.slugData);

            this.slugValue = encodeURIComponent(_div.innerText.toLowerCase().replace(/[^\w]+/g,'_').replace(/_+/g,'_')).substring(0, 64);
        }
    }
}
