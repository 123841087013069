<div class="EFFilter">
    <div class="EFFilter-Inner">
        <div *ngIf="!lockFilter && !lockFilterType" class="mt-2">
            <div class="d-flex">
                <div *ngIf="hasFilterTypeCategories(filterData.type) || hasFilterTypeStars(filterData.type) || hasFilterTypeCost(filterData.type)" class="ps-2 mb-2 EFFilter-Toggle {{filterCollapsed ? 'EFFilter-Toggle--Collapsed' : ''}}">
                    <div class="d-inline-block bg-white shadow-sm rounded">
                        <button class="btn btn-white {{filterCollapsed ? '' : 'btn-white-selected'}}" (click)="filterCollapsed = !filterCollapsed">
                            <i class="fa-regular fa-filter"></i>
                        </button>
                    </div>
                </div>

                <div class="EFFilter-Tabs">
                    <div class="d-inline-block bg-white shadow-sm rounded btn-group mx-2">
                        <ng-container *ngFor="let type of ['experience','event','host','eatery','poi','itinerary','article','utility']">
                            <button *ngIf="!isTypeHidden(type)" class="btn text-center btn-white EFFilter-Tabs-Button {{filterData.type == type ? 'btn-white-selected' : ''}}" (click)="setFilterDataType(type)">
                                <div class="d-none d-lg-block">
                                    <div>
                                        <i class="fa-regular fa-lg text-center" [innerHTML]="getTypeIcon(type)"></i>
                                    </div>
                                    <span>{{ 'experience-finder.general.product-type-' + type | locTranslate }}</span>
                                </div>
                                <span class="d-lg-none">
                                    <i class="fa-regular fa text-center" [innerHTML]="getTypeIcon(type)"></i>&nbsp;{{ 'experience-finder.general.product-type-' + type | locTranslate }}
                                </span>
                            </button>
                        </ng-container>

                        <span *ngIf="isLoadingTabs" class="EFFilter-Tabs-Loading">
                            <app-pg-loading></app-pg-loading>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="align-items-center d-block position-relative mt-2">
            <div class="{{lockFilter ? 'EFFilter-Inner--Locked' : ''}}">
                <ng-container *ngIf="!lockFilter">
                    <div class="EFFilter-Inner-Buttons">

                        <div class="d-flex">
                            <ng-container *ngIf="hasFilterTypeCategories(filterData.type)">
                                <div *ngIf="filterData.category[filterData.type]; let categories" class="ps-2 {{filterCollapsed ? 'EFFilter-Item--Collapsed' : ''}}">
                                    <div class="d-inline-block bg-white shadow-sm rounded mb-2">
                                        <div class="d-inline-block" ngbDropdown [autoClose]="'outside'">
                                            <button ngbDropdownToggle class="btn btn-white">
                                                <ng-container *ngIf="categoriesList[filterData.type]; let list">
                                                    <span>
                                                        {{getCategoryListDisplay(filterData.type)}}
                                                    </span>
                                                </ng-container>
                                            </button>
                                            <div ngbDropdownMenu class="EFFilter-Inner-Menu">
                                                <div class="dropdown-item" (click)="toggleAllCategories(filterData.type)">
                                                    <app-pg-switch [small]="true" [ngModel]="filterData.allCategories"></app-pg-switch>
                                                    <label class="ms-2 mb-0">
                                                        <i class="{{getCategoryIcon(filterData.type, '*')}}"></i>
                                                        {{getCategoryTranslation(filterData.type, '*')}}
                                                    </label>
                                                </div>
                                                <hr class="my-2"/>
                                                <ng-container *ngFor="let item of categories | keyvalue">
                                                    <div class="dropdown-item" (click)="toggleCategory(filterData.type, item.key)">
                                                        <app-pg-switch [small]="true" [ngModel]="categories[item.key]"></app-pg-switch>
                                                        <label class="ms-2 mb-0">
                                                            <i class="{{getCategoryIcon(filterData.type, item.key)}}"></i>
                                                            {{getCategoryTranslation(filterData.type, item.key)}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
        
                            <ng-container *ngIf="hasSelectableSubcategories(filterData.type)"> 
                                <ng-container *ngIf="filterData.category[filterData.type]; let categories" >
                                    <div *ngIf="filterData.subcategory[filterData.type]; let subcategories" class="ps-2 {{filterCollapsed ? 'EFFilter-Item--Collapsed' : ''}}">
                                        <div class="d-inline-block bg-white shadow-sm rounded mb-2">
                                            <div class="d-inline-block" ngbDropdown [autoClose]="'outside'">
                                                <button ngbDropdownToggle class="btn btn-white">
                                                    <ng-container *ngIf="subcategoriesList[filterData.type]; let list">
                                                        <span>
                                                            {{getSubcategoryListDisplay(filterData.type)}}
                                                        </span>
                                                    </ng-container>
                                                </button>
                                                <div ngbDropdownMenu class="EFFilter-Inner-Menu">
                                                    <div class="dropdown-item" (click)="toggleAllSubcategories(filterData.type)">
                                                        <app-pg-switch [small]="true" [ngModel]="filterData.allSubcategories"></app-pg-switch>
                                                        <label class="ms-2 mb-0">
                                                            {{getSubcategoryTranslation(filterData.type, '*')}}
                                                        </label>
                                                    </div>
                                                    <hr class="my-2"/>
                
                                                    <ng-container *ngFor="let parent of subcategories | keyvalue">
                                                        <ng-container *ngIf="filterData.allCategories || categories[parent.key]">
                                                            <ng-container *ngFor="let item of parent.value | keyvalue">
                                                                <div class="dropdown-item" (click)="toggleSubcategory(filterData.type, parent.key, item.key)">
                                                                    <app-pg-switch [small]="true" [ngModel]="subcategories[parent.key][item.key]"></app-pg-switch>
                                                                    <label class="ms-2 mb-0">
                                                                        {{getSubcategoryTranslation(filterData.type, item.key)}}
                                                                    </label>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
        
                            <ng-container *ngIf="hasFilterTypeStars(filterData.type)">
                                <div *ngIf="filterData.stars[filterData.type]; let stars" class="ps-2 {{filterCollapsed ? 'EFFilter-Item--Collapsed' : ''}}">
                                    <div class="d-inline-block bg-white shadow-sm rounded mb-2">
            
                                        <div class="d-inline-block" ngbDropdown [autoClose]="'outside'">
                                            <button ngbDropdownToggle class="btn btn-white">
                                                <span>
                                                    {{getStarsListDisplay(filterData.type)}}
                                                </span>
                                            </button>
                                            <div ngbDropdownMenu class="EFFilter-Inner-Menu">
                                                <div class="dropdown-item" (click)="toggleAllStars(filterData.type)">
                                                    <app-pg-switch [small]="true" [ngModel]="filterData.allStars"></app-pg-switch>
                                                    <label class="ms-2 mb-0">{{ 'experience-finder.ef-filter.stars-all' | locTranslate}}</label> 
                                                </div>
                                                <hr class="my-2"/>
                                                <ng-container *ngFor="let item of stars | keyvalue">
                                                    <div class="dropdown-item" (click)="toggleStars(filterData.type, item.key)">
                                                        <app-pg-switch [small]="true" [ngModel]="stars[item.key]"></app-pg-switch>
                                                        <label class="ms-2 mb-0">
                                                            <small class="opacity-80" [innerHTML]="getStarsLabel(item.key)"></small>
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
        
                            <ng-container *ngIf="hasFilterTypeCost(filterData.type)">
                                <div *ngIf="filterData.cost[filterData.type]; let cost" class="ps-2 {{filterCollapsed ? 'EFFilter-Item--Collapsed' : ''}}">
                                    <div class="d-inline-block bg-white shadow-sm rounded mb-2">
            
                                        <div class="d-inline-block" ngbDropdown [autoClose]="'outside'">
                                            <button ngbDropdownToggle class="btn btn-white">
                                                <span>
                                                    {{getCostListDisplay(filterData.type)}}
                                                </span>
                                            </button>
                                            <div ngbDropdownMenu class="EFFilter-Inner-Menu">
                                                <div class="dropdown-item" (click)="toggleAllCosts(filterData.type)">
                                                    <app-pg-switch [small]="true" [ngModel]="filterData.allCosts"></app-pg-switch>
                                                    <label class="ms-2 mb-0">{{ 'experience-finder.ef-filter.cost-all' | locTranslate}}</label>
                                                </div>
                                                <hr class="my-2"/>
                                                <ng-container *ngFor="let item of cost; let i = index">
                                                    <div class="dropdown-item" (click)="toggleCost(filterData.type, i)">
                                                        <app-pg-switch [small]="true" [ngModel]="item.selected"></app-pg-switch>
                                                        <label class="ms-2 mb-0">
                                                            {{getCostLabel(item)}}
                                                        </label>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </div>
                    
                </ng-container>
                <!--
                <div class="px-2 mb-1 EFFilter-Inner-Language">
                    <div *ngIf="filterData.language[filterData.type]; let languages" class="d-inline-block bg-white shadow-sm rounded">
                        <div class="d-inline-block" ngbDropdown [autoClose]="'outside'" placement="bottom-right">
                            <button ngbDropdownToggle class="btn btn-white">
                                <span>
                                    {{getLanguageListDisplay(filterData.type)}}
                                </span>
                            </button>
                            <div ngbDropdownMenu class="EFFilter-Inner-Menu">
                                <div *ngFor="let item of languages | keyvalue" class="dropdown-item" (click)="toggleLanguage(filterData.type, item.key);">
                                    <app-pg-switch [small]="true" [ngModel]="item.value"></app-pg-switch>
                                    <label class="ms-2 mb-0">
                                        <span>{{getLanguageLabel(item.key)}}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                -->
            </div>
        </div>
    </div>
</div>
