import { Directive, OnChanges, AfterViewChecked, Input, ElementRef, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appPgCenterImage]'
})

export class PgCenterImageDirective implements AfterViewChecked, OnChanges, OnInit, OnDestroy {

    @Input()
    appPgCenterImage: string;

    @Input()
    fitMode: 'height' | 'width' | 'cover' | 'contain';

    constructor(private el: ElementRef) {
    }

    private image:HTMLImageElement = null;

    private applyImage:boolean = false;

    ngOnInit() {
        this.el.nativeElement.classList.add('PgCenterImage');
    }

    ngOnDestroy() {

    }

    ngOnChanges() {
        this.applyImage = true;
    }

    ngAfterViewChecked() {
        if(this.applyImage) {

            this.applyImage = false;

            this.el.nativeElement.innerHTML = '';

            if(this.appPgCenterImage == null) {
                this.el.nativeElement.style.backgroundImage = '';
            }
            else {
                this.el.nativeElement.classList.add('PgCenterImage--Loading');

                this.image = new Image();
                this.image.style.opacity = '0';
                this.image.style.position = 'absolute';
    
                this.image.onload = () => {
                    this.image.style.display = 'none';
    
                    this.el.nativeElement.classList.remove('PgCenterImage--Loading');
                    this.el.nativeElement.style.backgroundImage = 'url("' + this.appPgCenterImage.replace(/ /g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') + '")';
                }
    
                this.el.nativeElement.appendChild(this.image);
    
                this.image.src = this.appPgCenterImage;
    
                switch(this.fitMode) {
                    case 'height':
                        this.el.nativeElement.style.backgroundSize = 'auto 100%';
                    break;
                    case 'width':
                        this.el.nativeElement.style.backgroundSize = '100% auto';
                    break;
                    case 'contain':
                        this.el.nativeElement.style.backgroundSize = 'contain';
                    break;
                    default:
                        this.el.nativeElement.style.backgroundSize = 'cover';
                } 
            } 
        }        
    }
}
