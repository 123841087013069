import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';

import { ValidationService } from '../../pg-ui-controls/validation.service';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';

import { ConfigResourceTypesConstraint, ConfigResourceTypesOption, PgFormFieldDisplay } from '../../models/config.resources.model';
import { PgFormField } from '../../models/form.model';
import { LocalizationService } from '../../services/localization.service';
import { DataFilter } from '../../services/data.service';

/**
 * Generico controllo della form.<br/>
 * In base a @Input fieldData crea il controllo appropriato.<br/>
 * Gestisce la validazione del campo e dei constraints
 */

@Component({
    selector: 'app-pg-form-field',
    templateUrl: './pg-form-field.component.html',
    styleUrls: ['./pg-form-field.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgFormFieldComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgFormFieldComponent),
        multi: true,
    }]
})

export class PgFormFieldComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
    @Input()
    disabled:boolean;

    @Input()
    noLabel:boolean;

    @Input()
    display: PgFormFieldDisplay;

    @Input()
    fieldData: PgFormField;

    @Input()
    fieldList: Array<PgFormField>;

    @Input()
    constraintsValues: { [id:string]: string };

    @Input()
    optionsFilter: Array<DataFilter>;

    @Input()
    groupId: string;

    @Input()
    realmId: string;

    @Input()
    languages: Array<string>;

    @Output() setSlave = new EventEmitter<PgFormField>();

    fieldType = 'string';

    fieldId = 'pg_form_field_' + Math.random().toString()

    fieldValue = null;

    fieldSlaves:{ [id:string]: PgFormField } = {}

    constructor(public localizationService: LocalizationService, private validationService: ValidationService) { }

    getDisplayViewType() {
        if(this.display != null && this.display.oneLine) {
            if(this.fieldType == 'html' || this.fieldType == 'text' || this.fieldType == 'blob'|| this.fieldType == 'json') return 'string';
        }

        return this.fieldType;
    }

    private _defaultOptions:{
        [name:string]: Array<ConfigResourceTypesOption>
    } = {
        'language': []
    }

    ngOnInit(): void {
        for(let _language of this.localizationService.availableApplicationLanguages) {
            this._defaultOptions['language'].push({
                value: _language,
                text: this.localizationService.languageLabels[_language]
            })
        }

        if(this.fieldList != null) {
            for(let _field of this.fieldList) {
                let _slaveType = this.fieldData.slaves[_field.name];
                if(_slaveType != null) {
                    this.fieldSlaves[_slaveType] = _field;
                }
            }
        }
    }

    ngOnDestroy() {
    }

    private _lastLanguageValString = null;
    private _lastLanguageValObj = null;

    getAvailableLanguages() {
        let _stringVal = null;

        if(this.languages != null) {
            _stringVal = JSON.stringify(this.languages);
        }
        else if(this._languagesField != null) {
            _stringVal = this._languagesField.value;
            if(_stringVal == null) _stringVal = '["' + this.localizationService.availableApplicationLanguages[0] + '"]'
        }
        else {
            _stringVal = JSON.stringify(this.localizationService.defaultContentLanguages);
        }

        if(_stringVal != this._lastLanguageValString) {
            this._lastLanguageValObj = JSON.parse(_stringVal)
        }

        return this._lastLanguageValObj;
    }

    selectedLanguage:string = null;

    getSelectedLanguage() {
        let _cAvailableLanguages = this.getAvailableLanguages();

        if(this.selectedLanguage == null || _cAvailableLanguages.indexOf(this.selectedLanguage) == -1) return _cAvailableLanguages[0];
        else return this.selectedLanguage;
    }

    setSelectedLanguage(language:string) {
        this.selectedLanguage = language;
        this.validate();
    }

    private _languagesField:PgFormField = null;

    ngOnChanges() {
        if(this.fieldData.type != null) {
            this.fieldType = this.fieldData.type;
        }
        else {
            this.fieldType = 'string';
        }

        if(this.fieldList != null) {
            for(let _cField of this.fieldList) {
                if(_cField.name == 'languages') {
                    this._languagesField = _cField;
                }
            }
        }

        this.validate();
        if(this._onChange != null) this._onChange(this.fieldValue);
    }

    validationIssues = null;
    warningIssues = null;

    writeValue(obj: any) {
        if(this.fieldType == 'date') {
            let _cDate = new Date(obj);
            
            if(isNaN(_cDate.getTime())) {
                this.fieldValue = null;
            }
            else {
                this.fieldValue = _cDate.toISOString().split('T')[0];
            }
        }
        else if(this.fieldType == 'time') {
            this.fieldValue = obj;

            if(this.fieldValue != null && /^\d\d\:\d\d\:\d\d$/.test(this.fieldValue)) {
                this.fieldValue = this.fieldValue.replace(/\:\d\d$/, '');
            }
        }
        else {
            this.fieldValue = obj;
        }

        this.validate();
    }

    emitChange() {
        let changeVal = null;

        if(this.fieldType == 'date') {
            if(this.fieldValue == '') this.fieldValue = null;
            else changeVal = this.fieldValue;
        }
        else if(this.fieldType == 'time') {
            if(this.fieldValue == '') this.fieldValue = null;
            else changeVal = this.fieldValue + ':00';
        }
        else if(this.fieldType == 'decimal' || this.fieldType == 'float') {
            if(this.fieldValue == '') this.fieldValue = null;
            else changeVal = parseFloat(this.fieldValue);
        }
        else if(this.fieldType == 'integer') {
            if(this.fieldValue == '') this.fieldValue = null;
            else changeVal = parseInt(this.fieldValue);
        }
        else {
            changeVal = this.fieldValue;
        }

        if(this._onChange != null) this._onChange(changeVal);
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    validate() {
        this.validationIssues = this.validationService.validateFieldValue(this.getFieldValue(), this.fieldData, this.constraintsValues);

        if(this.validationIssues != null) {
            this.warningIssues = null;
        }
        else {
            this.warningIssues = this.validationService.getWarningsForFieldValue(this.getFieldValue(), this.fieldData);
        }

        return this.validationIssues;
    };

    formatValue(val:any) {
        return this.localizationService.format.byType(val, this.fieldType);
    }

    formatConstraint(constraint:ConfigResourceTypesConstraint) {
        let _fieldLabel = constraint.field;

        for(let _cField of this.fieldList) {
            if(_cField.name == constraint.field) {
                _fieldLabel = _cField.label;
                break;
            }
        }

        return this.localizationService.format.constraint(constraint.operator, _fieldLabel)
    }

    private _lastFieldSlaves:any = {};

    getFieldSlaves() {
        let _retVal = {}

        for(let i in this.fieldSlaves) {
            _retVal[i] = this.fieldSlaves[i].value
            if(this._lastFieldSlaves[i] != _retVal[i]) this._lastFieldSlaves = _retVal
        }

        return this._lastFieldSlaves;
    }

    setFieldSlaves(slavesData:any) {
        for(let i in this.fieldSlaves) {
            let _field = this.fieldSlaves[i];

            _field.value = slavesData[i];
            this.setSlave.emit(_field);
        }
    }

    getFieldValue() {
        if(this.fieldData.locale && !this.localizationService.format.hasTypeInternalLanguageHandling(this.fieldData.type)) {
            try {
                let _cVal = JSON.parse(this.fieldValue);
                if(_cVal == null) _cVal = {};

                return _cVal[this.getSelectedLanguage()]
            }
            catch(ex) {
                return null;
            }
        }
        else {
            return this.fieldValue;
        }
    }

    setFieldValue(val:any) {
        if(this.fieldData.locale && !this.localizationService.format.hasTypeInternalLanguageHandling(this.fieldData.type)) {
            let _cVal = null;

            try {
                _cVal = JSON.parse(this.fieldValue);
            }
            catch(ex) {}

            if(_cVal == null) _cVal = {};

            _cVal[this.getSelectedLanguage()] = val;

            this.fieldValue = JSON.stringify(_cVal);
        }
        else {
            this.fieldValue = val;
        }
    }

    getFieldValueLength() {
        let _value = this.getFieldValue();

        if(_value == null) return 0;
        else {
            if(this.getDisplayViewType() == 'html') {
                _value = _value.replace(/<[^>]*>/g, '');
            }

            return _value.length;
        }
    }

    getFieldValueLengthReal() {
        if(this.getDisplayViewType() == 'html') {
            let _value = this.getFieldValue();

            if(_value == null) return 0;
            else return _value.length;
        }
    }

    private _emptyOptions = []

    getFieldOptions() {
        if(this.fieldData.options != null) return this.fieldData.options;
        else {
            // opzioni di default per campi specifici
            if(this._defaultOptions[this.fieldData.name]) {
                return this._defaultOptions[this.fieldData.name];
            }
            else return this._emptyOptions;
        }
    }

    /* TODO: spostare fuori
    addResourceElement() {
        if(this.canAddResourceElement) {
            let _modalRef = this.modalService.open(PgResourceElementFormComponent);

            let _componentInstance = _modalRef.componentInstance as PgResourceElementFormComponent;
            _componentInstance.activeModal = _modalRef;
            _componentInstance.resourceId = this.fieldData.resource;
            _componentInstance.elementId = null;

            _modalRef.result.then(() => {
                this.disabled = true;
                setTimeout(() => {
                    this.disabled = false;
                }, 250)
            }, () => {
            })
        }
    }
    */
}
