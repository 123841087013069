<div class="PGResourceViewCalendarConfig">
    <div class="container-fluid">
        <div *ngIf="noDateFields" class="row">
            <div class="col">Nessun campo data presente nella risorsa</div>
        </div>
        <div *ngIf="!noDateFields" class="row">
            <label class="col-2">
                dateField
            </label>
            <div class="col">
                <app-pg-select [options]="viewDateOptions" [(ngModel)]="view.config.dateField" [display]="{ oneLine: true }" [required]="true"></app-pg-select>
            </div>
        </div>
    </div>
</div>
        
