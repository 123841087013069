<div class="FormsImport">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <div *ngIf="!isLoading" class="row">
        <div class="col-12 mb-3">
            {{ 'forms-template-import.title' | locTranslate }}
        </div>
        <div class="col-12 col-sm-6 mb-3">
            <h4 class="mb-3">{{ 'forms-template-import.subtitle-with-template' | locTranslate }}</h4>
            <div>
                <div>
                    <div class="form-group">
                        <label><b>{{ 'forms-template-import.category' | locTranslate }}</b></label>
                        <app-pg-select [options]="categoryOptions" [(ngModel)]="selectedCategory" (ngModelChange)="selectedCategoryChange()" [display]="{ oneLine: true }" [required]="true"></app-pg-select>
                    </div>
                    <div class="form-group" appPgAnimations="{{subcategoryVisible ? 'EnterL' : 'ExitL'}}" pgAnimationsIgnoreFirst>
                        <label><b>{{ 'forms-template-import.subcategory' | locTranslate }}</b></label>
                        <app-pg-select [options]="subcategoryOptions" [(ngModel)]="selectedSubcategory" (ngModelChange)="selectedSubcategoryChange()" [display]="{ oneLine: true }" [required]="true"></app-pg-select>
                    </div>
                    <div class="form-group">
                        <label><b>{{ 'forms-template-import.template' | locTranslate }}</b></label>
                        <app-pg-select [options]="templateOptions" [ngModel]="getSelectedTemplateValue()" (ngModelChange)="setSelectedTemplateValue($event)" [display]="{ oneLine: true }"></app-pg-select>
                    </div>
                    <div>
                        <button class="btn btn-primary" [disabled]="selectedTemplate == null" (click)="doImport()">
                            <span>{{ 'forms-template-import.use-template' | locTranslate }}</span>
                            <i class="fa-regular fa-cloud-download-alt"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-1 d-none d-sm-block"></div>
        <div class="col-12 col-sm-5">
            <h4 class="mb-3">{{ 'forms-template-import.or' | locTranslate }}</h4>
            <div>
                <div class="form-group">
                    <label><b>{{ 'forms-template-import.subtitle-without-template' | locTranslate }}</b></label>
                    <div>
                        <button class="btn btn-secondary" (click)="skipImport()">
                            <span>{{ 'forms-template-import.create-original' | locTranslate }}</span>
                            <i class="fa-regular fa-forward"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>