import { Component, Input } from '@angular/core';
import { ConfigService } from '../../services/config.service';
import { DataService } from '../../services/data.service';
import { SemanticsService } from '../../services/semantics.service';
import { PgSemanticView } from '../pg-semantic-view-common';

@Component({
  selector: 'app-pg-semantic-view-card',
  templateUrl: './pg-semantic-view-card.component.html',
  styleUrls: ['./pg-semantic-view-card.component.scss']
})

export class PgSemanticViewCardComponent extends PgSemanticView {
    @Input() showActions:boolean;
    @Input() customActions:Array<{
        icon: string,
        label: string,
        action: Function
    }>
    @Input() discountVal:number;

    constructor(dataService:DataService, configService:ConfigService, semanticsService:SemanticsService) {
        super(dataService, configService, semanticsService);
    }

    encodeURIComponent(value:string) {
        return encodeURIComponent(value);
    }

    hasProperty(val:any) {
        if(val == null || val == '') return false;
        else return true;
    }

    formatPrice(val:string, applyDiscount?:boolean) {
        let _numVal = parseFloat(val);

        if(applyDiscount && this.discountVal != null) _numVal *= (1 - this.discountVal);

        if(_numVal == 0) {
            return 'GRATIS';
        }
        else {
            return parseFloat(val).toFixed(2) + ' €'
        }
    }
}
