import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EfMainAppMode, EfMainExternalFilter, EfMainFragment, EnvironmentService, LocalizationService } from 'pg-lib';

@Component({
  selector: 'app-ef-landing',
  templateUrl: './ef-landing.component.html',
  styleUrls: ['./ef-landing.component.scss']
})
export class EfLandingComponent implements OnInit {

    constructor(private route:ActivatedRoute, private router:Router, private localizationService:LocalizationService, private environmentService:EnvironmentService) {}

    appMode:EfMainAppMode;
    externalFilter:EfMainExternalFilter;
    fragment:EfMainFragment|string;

    ngOnInit(): void {
        this.appMode = null;
        this.externalFilter = null;
        this.fragment = null;

        if(this.route.snapshot.queryParams != null) {
            if(this.route.snapshot.queryParams.iframe == 'true') this.appMode = 'iframe'
            else if(this.route.snapshot.queryParams.iframekiosk == 'true') this.appMode = 'iframekiosk'

            let _externalFilterString = this.route.snapshot.queryParams.filter;

            if(_externalFilterString != null) {
                try {
                    this.externalFilter = JSON.parse(_externalFilterString);
                }
                catch(ex) {
                    console.log('Failed to parse externalFilter', _externalFilterString)
                }
            }

            if(this.route.snapshot.queryParams.language != null) {
                this.localizationService.setLanguage(this.route.snapshot.queryParams.language)
            }
        }

        let _fragmentString = this.route.snapshot.fragment

        if(_fragmentString != null) {
            try {
                this.fragment = JSON.parse(_fragmentString);
            }
            catch(ex) {
                this.fragment = { product: _fragmentString }
            }
        }

        if(this.route.snapshot.params != null) {
            let _realmName = this.route.snapshot.params.realm

            if(_realmName != null && _realmName != '') {
                this.environmentService.setPublicRealm(_realmName);

                if(this.environmentService.publicRealm == null) {
                    this.router.navigate([''])
                }
            }
        }
    }

    onFragmentChange(fragment:EfMainFragment) {
        this.router.navigate([], { fragment: fragment?.product })
    }
}
