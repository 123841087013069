
<span class="PGListEditor">
    <!-- TODO: gestire la ricerca -->
    <app-pg-loading *ngIf="viewAs == null || isLoading"></app-pg-loading>
    <div *ngIf="viewAs != null && !isLoading" class="PGListEditor-Selected">
        <div *ngIf="selectedOptions.length == 0"  class="badge bg-secondary">
            <span>{{ 'pg-select.no-result' | locTranslate }}</span>
        </div>
        <div *ngFor="let option of selectedOptions" class="badge bg-primary">
            <span>{{option.text}}</span>
            <button class="btn btn-primary" [disabled]="readonly" (click)="deselectOption(option)">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>
        <div *ngIf="getVisibleOptions(); let visibleOptions" class="PGListEditor-Add" ngbDropdown [autoClose]="false">
            <button ngbDropdownAnchor class="btn btn-primary" [disabled]="readonly" (focus)="onInputFocus()" (focusout)="onInputBlur()">
                <i class="fa-regular fa-plus"></i>
            </button>
            <div ngbDropdownMenu class="dropdown-menu PGSelect-Select-Dropdown" [appPgAnimations]="isDropdownClosing ? 'FadeOut' : 'FadeIn'">
                <input *ngIf="hasSearch" 
                    class="form-control PGSelect-Select-Dropdown-Search" placeholder="{{ 'pg-select.search' | locTranslate }}"
                    [readonly]="readonly" [tabIndex]="!hasSearch ? -1 : null" [(ngModel)]="search"
                    (focus)="onInputFocus()" (focusout)="onInputBlur()" (keypress)="onKeyPress($event)"/>
                <span class="PGSelect-Select-Dropdown-Options">
                    <app-pg-loading *ngIf="isDropdownLoading" pgAnimations="FadeIn" [isOverlay]="true"></app-pg-loading>
                    <!-- NB: sulle options ho usato il mousedown come evento perché il click dava problemi "strani" -->
                    <div *ngIf="visibleOptions.length == 0" class="dropdown-item disabled">
                        <ng-container *ngIf="showSearchMin()">{{ 'pg-select.search-min' | locTranslate:{ min: searchMin } }}</ng-container>
                        <ng-container *ngIf="!showSearchMin()">{{ 'pg-select.no-result' | locTranslate }}</ng-container>
                    </div>
                    <ng-container *ngFor="let option of visibleOptions; let i = index;" >
                        <div class="PGSelect-Select-Dropdown-Options-Category" *ngIf="showCategoryBefore(i); let category">{{category}}</div>
                        <div class="dropdown-item {{isOptionDisabled(option) ? 'disabled' : ''}}" 
                            (mousedown)="selectOption(option)">
                            <span [innerHTML]="option.view != null ? option.view : option.text"></span>
                        </div>
                    </ng-container>
                    <div *ngIf="isIncomplete" class="dropdown-item disabled">...</div>
                </span>
            </div>
        </div>
    </div>
</span>
