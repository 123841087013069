import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { EnvironmentService } from '../../../services/environment.service';
import { BookingService, BookingServiceListType } from '../../../services/booking.service';
import { LocalizationService } from '../../../services/localization.service';
import { PGBookingData, PGProductData } from '../../../models/booking.model';
import { GetResourceDataOptions } from '../../../services/data.service';
import { Observable, Subscription } from 'rxjs';
import { ExportBookingsService } from '../../export-bookings.service';

class PGBookingsCalendarData {
    day:number
    weekday:number
    experiences:Array<PGBookingsCalendarDataExperience>
}

class PGBookingsCalendarDataExperience {
    type:'experience'|'host'|'eatery'
    id:string
    title:string
    image:string
    product:PGProductData
    slots:Array<PGBookingsCalendarDataExperienceSlot>
}

class PGBookingsCalendarDataExperienceSlot
{
    start:string
    end:string
    bookings:Array<PGBookingData>
}

@Component({
  selector: 'app-pg-bookings-calendar',
  templateUrl: './pg-bookings-calendar.component.html',
  styleUrls: ['./pg-bookings-calendar.component.scss']
})
export class PgBookingsCalendarComponent implements OnInit {

    @Input() requestAs:string;

    constructor(private environmentService:EnvironmentService, private bookingService:BookingService, public localizationService:LocalizationService, private exportBookingsService:ExportBookingsService) { }

    isLoading = false;

    month:number = null;
    year:number = null;

    bookingsByDay:Array<PGBookingsCalendarData>

    today:string = null;

    CheckoutURL = null;

    ngOnInit(): void {
        let _cDate = new Date();
        this.today = this.dateNumsToString(_cDate.getFullYear(), _cDate.getMonth(), _cDate.getDate())

        this.year = _cDate.getFullYear();
        this.month = _cDate.getMonth();

        this.CheckoutURL = this.environmentService.environment.CheckoutURL;

        if(this.requestAs != null) {
            this.requestType = 'supplier/experience'
        }
        else {
            this.requestType = null;
        }
        
        this.reset();
    }

    dateNumsToString(year:number, month:number, day:number) {
        let _retVal = year + '-'
        if(month + 1 < 10) _retVal += '0'
        _retVal += (month + 1);
        _retVal += '-';
        if(day < 10) _retVal += '0'
        _retVal += day;

        return _retVal;
    }

    requestType:BookingServiceListType = null;

    private _bookingData:Array<PGBookingData> = null;

    private _getDataObservable(type:BookingServiceListType, year:number, month:number, limit:number, offset:number) {
        return new Observable<Array<PGBookingData>>((observer) => {
            let _from = this.dateNumsToString(year, month, 1)
            if(_from < this.today) _from = this.today;

            let _toDate = new Date(year, month + 1, 1);
            let _to = this.dateNumsToString(_toDate.getFullYear(), _toDate.getMonth(), _toDate.getDate());

            if(_from >= _to) {
                setTimeout(() => {
                    observer.next([]);
                    observer.unsubscribe();
                }, 100)
            }
            else {
                let _reqOptions:GetResourceDataOptions = { 
                    limit: limit, 
                    offset: offset, 
                    filter: [
                        { field: 'date', operator: '>=', value: [ _from ] },
                        { field: 'date', operator: '<', value: [ _to ] },
                    ]
                }
        
                this.bookingService.listBookings(type, _reqOptions).subscribe((data) => {
                    observer.next(data);
                    observer.unsubscribe();
                })
            }
        })
    }

    private _dataSubscription:Subscription = null;

    private _loadPage:number = null;
    atEnd = false;

    reset() {
        if(this._dataSubscription != null) this._dataSubscription.unsubscribe();

        this.bookingsByDay = [];
        this._bookingData = [];

        this._loadPage = 0;
        this.atEnd = false;

        this.loadMore()
    }

    loadMore() {
        if(!this.atEnd) {
            this.isLoading = true;

            let _limit = 100;

            this._dataSubscription = this._getDataObservable(this.requestType, this.year, this.month, _limit, this._loadPage * _limit).subscribe((data) => {
                this.isLoading = false;

                this._loadPage++;
                this.atEnd = data.length < _limit;
    
                for(let _item of data) {
                    this._bookingData.push(_item)
                }
    
                for(let _item of data) {
    
                    let _cDateSplit = _item.date.split('-');
                    let _cYear = parseInt(_cDateSplit[0]);
                    let _cMonth = parseInt(_cDateSplit[1]) - 1;
                    let _cDay = parseInt(_cDateSplit[2]);
    
                    if(_cYear == this.year && _cMonth == this.month) {
                        let _cDate = new Date(_cYear, _cMonth, _cDay);
    
                        if(this.bookingsByDay[_cDay] == null) {
                            this.bookingsByDay[_cDay] = {
                                day: _cDate.getDate(),
                                weekday: _cDate.getDay(),
                                experiences: []
                            }
                        }
    
                        let _cExperience:PGBookingsCalendarDataExperience = null;
    
                        let _cProductType = _item.experience_type || 'experience'
    
                        for(let _cDayExperience of this.bookingsByDay[_cDay].experiences) {
                            if(_cDayExperience.id == _item.experience_id && _cDayExperience.type == _cProductType) {
                                _cExperience = _cDayExperience;
                                break;
                            }
                        }
    
                        if(_cExperience == null) {
                            let _cProduct = new PGProductData(_item.experience_data, _cProductType);
                            
                            if(_cProduct == null) {
                                _cExperience = { 
                                    id: _item.experience_id,
                                    type: _cProductType,
                                    image: null,
                                    title: this.localizationService.translate('pg-bookings-calendar.' + _cProductType + '-not-found', { id: _item.experience_id }),
                                    product: null,
                                    slots: []
                                }
                            }
                            else {
                                _cExperience = { 
                                    id: _item.experience_id,
                                    type: _cProductType,
                                    image: _cProduct.images != null ? _cProduct.images[0] : null,
                                    title: _cProduct.title,
                                    product: _cProduct,
                                    slots: []
                                }
                            }
    
                            this.bookingsByDay[_cDay].experiences.push(_cExperience)
                        }
    
                        let _cSlot:PGBookingsCalendarDataExperienceSlot = null;
    
                        let _cStart:string = null;
                        let _cEnd:string = null;
    
                        if(_item.start != null && _item.end != null) {
                            _cStart = _item.start.match(/^\d\d:\d\d/)[0]
                            _cEnd = _item.end.match(/^\d\d:\d\d/)[0]
                        }
    
                        for(let _cExperienceSlot of _cExperience.slots) {
                            if(_cExperienceSlot.start == _cStart) {
                                _cSlot = _cExperienceSlot;
                                break;
                            }
                        }
    
                        if(_cSlot == null) {
                            _cSlot = {
                                start: _cStart,
                                end: _cEnd,
                                bookings: []
                            }
    
                            _cExperience.slots.push(_cSlot)
                        }
    
                        _cSlot.bookings.push(_item)
                    }
                }
            })  
        }
    }

    goPrevMonth() {
        this.month--;

        if(this.month < 0) {
            this.month = 11;
            this.year--;
        }

        this.reset();
    }

    goNextMonth() {
        this.month++;
        
        if(this.month > 11) {
            this.month = 0;
            this.year++;
        }

        this.reset();
    }

    /*getPeriodBookingsPreview(bookings:Array<any>) {
        let _retVal = '';

        if(bookings != null) {
            for(let _cBooking of bookings) {
                if(_cBooking.availability != null && _cBooking.availability.from != null && _cBooking.availability.to != null) {
                    if(_retVal != '') _retVal += ', ';
                    _retVal += 'dal ' + this.localizationService.format.date(moment(_cBooking.availability.from, 'YYYY-MM-DD').toDate())
                    _retVal += ' al ' + this.localizationService.format.date(moment(_cBooking.availability.to, 'YYYY-MM-DD').toDate())
                }
            }
        }

        return _retVal == '' ? null : _retVal;
    }*/

    exportBookings(day:number, experience:PGBookingsCalendarDataExperience) {
        let _date = moment(new Date(this.year, this.month, day)).format('YYYY-MM-DD')

        let _bookingList:Array<PGBookingData> = [];

        for(let _booking of this._bookingData) {
            if(_booking.experience_type ==  experience.type && _booking.experience_id == experience.id && _booking.date == _date) {
                _bookingList.push(_booking)
            }
        }

        this.exportBookingsService.exportBookings('pdf', experience.title, experience.type, _bookingList, _date)
    }

    confirmBooking:{
        experience: PGBookingsCalendarDataExperience,
        date:string,
        time:string
    } = null;

    showConfirmBooking(day:number, experience:PGBookingsCalendarDataExperience, slot:PGBookingsCalendarDataExperienceSlot) {
        this.confirmBooking = {
            experience: experience,
            date: this.dateNumsToString(this.year, this.month, day),
            time: slot.start
        }
    }

    hideConfirmBooking() {
        this.confirmBooking = null;
        this.reset();
    }
}
