import { PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { Optional, Inject, Injectable } from '@angular/core';
import { UrlSerializer } from '@angular/router';

@Injectable()
export class PreserveQueryParamsPathLocationStrategy extends PathLocationStrategy {
  private get search(): string {
    return this.platformLocation?.search ?? '';
  }
  constructor(
    private platformLocation: PlatformLocation,
    private urlSerializer: UrlSerializer,
    @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
  ) {
    super(platformLocation, _baseHref);
  }

  prepareExternalUrl(internal: string): string {
    // join path with slashes and baseHref (internal, see angular source :D )
    const path = super.prepareExternalUrl(internal);
    
    const existingURLSearchParams = new URLSearchParams(this.search);

    // create object literal from an iterator of URLSearchParams (key, value) pair
    let existingQueryParams:any = {}
    existingURLSearchParams.forEach((val, key) => {
        existingQueryParams[key] = val;
    })
    
    // parse (deserialize) the final url path and get the corresponding url tree for easier data manipulation
    const urlTree = this.urlSerializer.parse(path);
    
    
    const nextQueryParams = urlTree.queryParams;

    // merge existing query params with the next route's queryParams
    urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams };

    // serialise urlTree
    return urlTree.toString();
  }
}