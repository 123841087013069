import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-pg-stripe-connect',
  templateUrl: './pg-stripe-connect.component.html',
  styleUrls: ['./pg-stripe-connect.component.scss']
})
export class PgStripeConnectComponent implements OnInit {

    @Input() small:boolean;

    constructor(private dataService:DataService) { }

    ngOnInit(): void {
    }

    isLoading = false;

    doConnect() {
        if(!this.isLoading) {
            this.isLoading = true;
            this.dataService.postRequest('/data/user/connect/stripe', null, { responseType: 'text' }).subscribe((data:string) => {
                window.location.href = data;
            }, () => {
                this.isLoading = false;
            })
        }
    }
}
