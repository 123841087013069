import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionMapsService } from '../../services/option-maps.service';

@Component({
  selector: 'app-pg-file-license-picker',
  templateUrl: './pg-file-license-picker.component.html',
  styleUrls: ['./pg-file-license-picker.component.scss']
})
export class PgFileLicensePickerComponent implements OnInit {

    selectLicenseOptions:Array<{ value: string, text: string }> = []
    selectedLicenseValue:string = null;

    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private optionMapsService:OptionMapsService) { }

    ngOnInit(): void {
        this.selectedLicenseValue = this.selectedValue == null ? null : 'other';

        let _optionList = this.optionMapsService.getResourceFieldOptionMap('File', 'license');

        for(let _option of _optionList) {
            if(_option.value == this.selectedValue) this.selectedLicenseValue = _option.value

            this.selectLicenseOptions.push({ value: _option.value, text: _option.text })
        }
    }

    onSelectedLicenseValueChange() {
        if(this.selectedLicenseValue == 'other') {
            this.selectedValue = '';
        }
        else {
            this.selectedValue = this.selectedLicenseValue;
        }
    }
}
