<ng-template #tooltipContent>
    <div class="PGConfirmAction" (mouseenter)="keepOpen(t)" (mouseleave)="closeWithDelay(t)">
        <div class="PGConfirmAction-Title">{{ 'pg-confirm-action.confirm' | locTranslate }}</div>
        <div *ngIf="action != null" class="PGConfirmAction-Text" [innerHTML]="action"></div>
        <div class="PGConfirmAction-Buttons">
            <button class="btn btn-sm btn-primary" (click)="confirmed.emit(); t.close();">
                <i class="fa-regular fa-check"></i>
            </button>
            <button class="btn btn-sm btn-secondary" (click)="t.close();">
                <i class="fa-regular fa-times"></i>
            </button>
        </div>
    </div>
</ng-template>
<span [ngbTooltip]="tooltipContent" triggers="manual" #t="ngbTooltip" (mouseup)="stopEvent($event);" (click)="stopEvent($event); keepOpen(t);" (mouseleave)="closeWithDelay(t)" [container]="tooltipContainer">
    <ng-content></ng-content>
</span>
