<button class="PGHelp btn btn-white {{buttonClass}}" (click)="openModal()">
    <i class="fa-regular fa-lg fa-message-question"></i>
    <span *ngIf="buttonText">{{buttonText}}</span>
</button>

<ng-template #helpModal>
    <app-pg-loading *ngIf="isSendingTicket" [isOverlay]="true"></app-pg-loading>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'pg-help.title' | locTranslate }}</h4>
        <button type="button" class="modal-close btn btn-link" [disabled]="isSendingTicket" (click)="modalRef.dismiss()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="mb-3">{{ 'pg-help.subtitle' | locTranslate }}</div>
        <div *ngIf="getErrorCount(); let count" class="text-danger mb-3" style="line-height: 1.25;">
            <small>{{ 'pg-help.errors' | locTranslate: { count: count } }}</small>
        </div>
        <div class="form-group">
            <label><b>{{ 'pg-help.field-email' | locTranslate }}</b></label>
            <input [(ngModel)]="ticketData.email" class="form-control" [disabled]="emailDisabled" [required]="true">
        </div>
        <div class="mb-3">{{ 'pg-help.text' | locTranslate }}</div>
        <div class="form-group">
            <label><b>{{ 'pg-help.field-type' | locTranslate }}</b></label>
            <app-pg-select [(ngModel)]="ticketData.type" [options]="typeOptions" [required]="true"></app-pg-select>
        </div>
        <div class="form-group mb-0">
            <label><b>{{ 'pg-help.field-message' | locTranslate }}</b></label>
            <textarea [(ngModel)]="ticketData.message" class="form-control"></textarea>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end">
            <button class="btn btn-secondary" [disabled]="isSendingTicket" (click)="modalRef.dismiss()">
                <i class="fa-regular fa-times"></i>
                <span>{{ 'pg-help.button-cancel' | locTranslate }}</span>
            </button>
            <button class="btn btn-primary ms-1" [disabled]="isSendingTicket || !isTicketValid()" (click)="sendHelpTicket();">
                <i class="fa-regular fa-paper-plane"></i>
                <span>{{ 'pg-help.button-send' | locTranslate }}</span>
            </button>
        </div>
    </div>
</ng-template>
