<div *ngIf="showConsent" class="PGCookieConsent">
    <div class="modal-backdrop fade show"></div>
    <div class="modal d-block fade show">
        <div class="modal-dialog text-center">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="mb-1">{{ 'pg-cookie-consent.popup-text' | locTranslate }}</div>
                    <div class="d-flex justify-content-center mb-3">
                        <a *ngIf="privacyPolicyURL != null" target="_blank" href="{{privacyPolicyURL}}" class="mx-1">{{ 'pg-cookie-consent.popup-link-privacy-policy' | locTranslate }}</a>
                    </div>
                    <div class="mb-3 d-flex justify-content-center">
                        <div *ngFor="let config of consentConfig" class="border py-1 px-2 mx-1 rounded">
                            <div class="form-check form-check-inline m-0">
                                <input id="PGCookieConsent_{{config.id}}" class="form-check-input" type="checkbox" [disabled]="config.required" [checked]="consentData[config.id]" (change)="consentData[config.id] = !consentData[config.id]"/>
                                <label for="PGCookieConsent_{{config.id}}" class="form-check-label">{{ 'pg-cookie-consent.cookie-type-' + config.id | locTranslate }}</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="btn btn-primary me-1" (click)="cancelConsent()">{{ 'pg-cookie-consent.button-reject' | locTranslate }}</button>
                        <button class="btn btn-primary" (click)="saveConsent()">{{ 'pg-cookie-consent.button-accept' | locTranslate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
