import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-tag-picker',
  templateUrl: './pg-tag-picker.component.html',
  styleUrls: ['./pg-tag-picker.component.scss']
})
export class PgTagPickerComponent implements OnInit {

  selectedValue:string = null;

  constructor(public modal:NgbActiveModal) { }

  ngOnInit(): void {
  }

}
