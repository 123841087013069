import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import moment from 'moment';
import { Observable } from 'rxjs';
import { PGAvailabilitySelection, PGAvailabilitySelectionLapse, PGBookingsCountData, PgAvailabilitySelectData } from '../../models/booking.model';
import { LocalizationService } from '../../services/localization.service';
import { BookingService } from '../../services/booking.service';
import { CustomerCareService } from '../../services/customer-care.service';
import { TimetableData, TimetableDataList } from '../../models/timetable.model';

class PGCalendarDayFlags {
    today: boolean;
    holiday: boolean;
    disabled: boolean;
}

class PGCalendarDate {
    weekday:number = null;
    num:number = null;

    constructor(public year?: number, public month?: number, public day?: number) {
        if(year == null || month == null || day == null) {
            let _today = new Date();
            this.year = _today.getFullYear();
            this.month = _today.getMonth();
            this.day = _today.getDate();
        }

        let _cDate = new Date(this.year, this.month, this.day);

        this.weekday = _cDate.getDay();
        this.num = _cDate.getTime();
    }
}

class PGCalendarDay {

    flags: PGCalendarDayFlags;
    elements: Array<PGAvailabilitySelection> = [];

    availability?: {
        current:number,
        total:number
    } = null;

    constructor(public date:PGCalendarDate, flags?:PGCalendarDayFlags, elements?:Array<PGAvailabilitySelection>) {
        if(flags != null) {
            this.flags = flags;
        }
        else {
            this.flags = new PGCalendarDayFlags();
        }

        if(elements != null) {
            this.elements = elements;

            let _sumAvailabilityCurrent = 0;
            let _sumAvailabilityTotal = 0;

            for(let _cElement of this.elements) {
                if(_cElement.availability != null) {
                    _sumAvailabilityCurrent += _cElement.availability.current;
                    _sumAvailabilityTotal += _cElement.availability.total;
                }
            }

            if(_sumAvailabilityTotal != 0) {
                this.availability = {
                    current: _sumAvailabilityCurrent,
                    total: _sumAvailabilityTotal
                }
            }
        }
        else {
            this.elements = [];
        }
    }
}

@Component({
    selector: 'app-pg-availability-select',
    templateUrl: './pg-availability-select.component.html',
    styleUrls: ['./pg-availability-select.component.scss']
  })
  export class PgAvailabilitySelectComponent implements OnInit, OnChanges {
    constructor(public localizationService: LocalizationService, private bookingService:BookingService, private customerCareService:CustomerCareService) { }

    @Input() availabilityData:Array<PgAvailabilitySelectData>;
    @Input() availabilityType:'product'|'user';
    @Output() availabilitySelected = new EventEmitter<PGAvailabilitySelection|PGAvailabilitySelectionLapse>();

    @Input() productId:string;
    @Input() productType:string;
    @Input() productCapacity:number;
    @Input() productAvailability:TimetableDataList;

    @Input() dayMode:'lapse'|'single';

    @Input() bookingDeadline:number; // in ore

    private _dateChangeTimeout = null;

    private _month:number = new Date().getMonth();
    private _year:number = new Date().getFullYear();

    get month() { return this._month; }
    set month(val:number) { 
        while(val < 0) {
            val += 12;
            this._year--;
        }

        while(val > 11) {
            val -= 12;
            this._year++;
        }

        this.isLoading = true;

        clearTimeout(this._dateChangeTimeout)
        this._dateChangeTimeout = setTimeout(() => {
            this._month = val; 

            this._dateChangeTimeout = null;

            this.generateCalendar();
        }, 200)
    }

    get year() { return this._year; }
    set year(val:number) {         
        this.isLoading = true;

        clearTimeout(this._dateChangeTimeout)
        this._dateChangeTimeout = setTimeout(() => {
            this._year = val; 

            this._dateChangeTimeout = null;

            this.generateCalendar(); 
        }, 200)
    }

    weeks:Array<Array<PGCalendarDay>> = null;
    daysWithElements:Array<PGCalendarDay> = null;
    daysWithElementsAround:Array<PGCalendarDay> = null;
    daysWithElementsAroundBefore = false;
    daysWithElementsAroundAfter = false;

    selectOnlyWithElements = true;
    selectedDay:PGCalendarDay = null;
    lastSelectedDay:PGCalendarDay = null;

    selectedDayLapseFrom:PGCalendarDay = null;
    selectedDayLapseTo:PGCalendarDay = null;

    isLoading = false;

    notAvailable = false;

    minDate:Date = null;
    isFirstMonth = false;

    globalTimezone:string = null;
    currentTimezone:string = null;

    readonly weeksNum = 6;

    ngOnInit() {
        this.currentTimezone = moment.tz.guess();
    }

    ngOnChanges() {
        if(this.productId != null) {
            this.availabilityType = 'product';
            this.availabilityData = [{
                id: this.productId,
                capacity: this.productCapacity,
                availability: this.productAvailability
            }]
        }

        this.generateCalendar(true);
    }

    private _clearWeeks() {
        this.weeks = []
        
        for(let wi = 0; wi < this.weeksNum; wi++) {
            this.weeks[wi] = [];

            for(let di = 0; di < 7; di++) {
                this.weeks[wi][di] = null;
            }
        }
        
        this.daysWithElements = [];

        this.selectedDay = null;
        this.selectedAvailability = null;
    }

    generateCalendar(firstLoad?:boolean) {   
        return new Promise<void>((resolve) => {
            this.globalTimezone = null;

            setTimeout(() => {
                this.notAvailable = false;

                this.isFirstMonth = false;

                if(this.availabilityData == null) {
                    this._clearWeeks();
                    this.notAvailable = true;
                    this.isFirstMonth = true;
                    resolve()
                }
                else {
                    let _now = new Date();
                    let _today = new Date(_now.getFullYear(), _now.getMonth(), _now.getDate());
                    let _firstAfter = new Date();
                    
                    if(this.bookingDeadline != null) {
                        _firstAfter = new Date(_firstAfter.getTime() + this.bookingDeadline * 60 * 60 * 1000)
                    }

                    let _minDateTime:Date = null;

                    for(let _item of this.availabilityData) {
                        let _firstAvailable = _item.availability.getFirstAvailableAfter(_firstAfter, this.dayMode != null);
                        if(_minDateTime == null || _minDateTime.getTime() > _firstAvailable.getTime()) _minDateTime = _firstAvailable;
                    }

                    if(_minDateTime == null) {
                        this._clearWeeks();
                        this.notAvailable = true;
                        this.isFirstMonth = true;
                        resolve()
                    }
                    else {
                        this.minDate = new Date(_minDateTime.getFullYear(), _minDateTime.getMonth(), _minDateTime.getDate())
                        
                        this.isLoading = true;
                                
                        let _startDate = new Date(this.year, this.month, 1);
        
                        if(_startDate.getTime() <= this.minDate.getTime()) {
                            this._year = this.minDate.getFullYear();
                            this._month = this.minDate.getMonth();
                            _startDate = new Date(this.minDate);
                            this.isFirstMonth = true;
                        }
        
                        _startDate.setDate(_startDate.getDate() - 1 - ((6 + _startDate.getDay() - this.localizationService.data.weekStart) % 7));
                        let _endDate = new Date(_startDate);
                        _endDate.setDate(_endDate.getDate() + this.weeksNum * 7)
        
                        this._getBookingsCount(_startDate, _endDate).then((count) => { 
                            this._clearWeeks();        

                            for(let wi = 0; wi < this.weeks.length; wi++) {
                    
                                for(let di = 0; di < 7; di++) {
                                    let _cDate = new Date(_startDate.getFullYear(), _startDate.getMonth(), _startDate.getDate() + wi * 7 + di);
                    
                                    let _isDisabled = this.isFirstMonth ? _cDate.getTime() < this.minDate.getTime() : _cDate.getMonth() != this.month;

                                    let _elementsList:Array<PGAvailabilitySelection> = [];
                    
                                    if(!_isDisabled) {
                                        for(let _item of this.availabilityData) {
                                            let _isIn = _item.availability.isAvailableOn(_cDate.getFullYear(), _cDate.getMonth() + 1, _cDate.getDate(), this.dayMode != null);
                                            
                                            if(_isIn) {
                                                if(this.dayMode != null) {
                                                    let _vacancy = null;
                            
                                                    if(_item.capacity != null && count != null && count[_item.id] != null) {
                                                        let _itemCount = count[_item.id]

                                                        _vacancy = {
                                                            current: _item.capacity,
                                                            total: _item.capacity
                                                        }
        
                                                        if(_itemCount[_cDate.getFullYear()] != null &&
                                                            _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1] != null &&
                                                            _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1][_cDate.getDate()] != null) {

                                                            let _count = _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1][_cDate.getDate()]

                                                            if(_count['00:00'] != null) {
                                                                _vacancy.current -= _count['00:00'];
                                                            }
                                                        }
                                                    }

                                                    _elementsList.push({
                                                        id: _item.id,
                                                        date: moment(_cDate).format('YYYY-MM-DD'),
                                                        begin: null,
                                                        end: null,
                                                        availability: _vacancy
                                                    })
                                                }
                                                else {
                                                    for(let _availability of _item.availability.list) {
                                                        if(_availability.containsDay(_cDate.getFullYear(), _cDate.getMonth() + 1, _cDate.getDate())) {
                                                            for(let _slot of _availability.hours) {
                                                                if(_slot.days[_cDate.getDay()]) {
                                                                    let _slotTime = new Date(_cDate.getFullYear(), _cDate.getMonth(), _cDate.getDate());
    
                                                                    if(_slot.begin != null) {
                                                                        _slotTime.setHours(parseInt(_slot.begin.split(':')[0]))
                                                                        _slotTime.setMinutes(parseInt(_slot.begin.split(':')[1]))
                                                                    }
    
                                                                    if(_slotTime.getTime() >= _minDateTime.getTime()) {
                                                                        let _vacancy = null;
                            
                                                                        if(_item.capacity != null && count != null && count[_item.id] != null) {
                                                                            let _itemCount = count[_item.id]
    
                                                                            _vacancy = {
                                                                                current: _item.capacity,
                                                                                total: _item.capacity
                                                                            }
                            
                                                                            if(_itemCount[_cDate.getFullYear()] != null &&
                                                                                _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1] != null &&
                                                                                _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1][_cDate.getDate()] != null) {
                    
                                                                                let _count = _itemCount[_cDate.getFullYear()][_cDate.getMonth() + 1][_cDate.getDate()]
                    
                                                                                if(_count[_slot.begin] != null) {
                                                                                    _vacancy.current -= _count[_slot.begin];
                                                                                }
                                                                            }
                                                                        }
                            
                                                                        _elementsList.push({
                                                                            id: _item.id,
                                                                            date: moment(_cDate).format('YYYY-MM-DD'),
                                                                            begin: _slot.begin,
                                                                            end: _slot.end,
                                                                            timezone: _availability.timezone,
                                                                            availability: _vacancy
                                                                        })
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    _elementsList.sort((a, b) => {
                                        if(a.begin < b.begin) return -1;
                                        else if(a.begin > b.begin) return 1;
                                        else if(a.id < b.id) return -1
                                        else if(a.id > b.id) return 1
                                        else return 0;
                                    })
                    
                                    this.weeks[wi][di] = new PGCalendarDay(new PGCalendarDate(_cDate.getFullYear(), _cDate.getMonth(), _cDate.getDate()), {
                                        today: _cDate.getTime() == _today.getTime(),
                                        holiday: false,
                                        disabled: _isDisabled
                                    }, _elementsList)
                    
                                    if(_elementsList.length > 0) {
                                        this.daysWithElements.push(this.weeks[wi][di]);
                                    }
                                }
                            }

                            // GESTIONE TIMEZONE
                            let _timezoneList:Array<string> = [];

                            for(let _week of this.weeks) {
                                for(let _day of _week) {
                                    for(let _element of _day.elements) {
                                        if(_timezoneList.indexOf(_element.timezone) == -1) {
                                            _timezoneList.push(_element.timezone)
                                        }
                                    }
                                }
                            }

                            if(_timezoneList.length == 1) {
                                this.globalTimezone = _timezoneList[0];
                            }

                            // GESTIONE DUPLICATI
                            // TODO: andrebbero mantenuti comunque tutti in degli array raggruppati per ora
                            if(this.availabilityType == 'user') {
                                let _countSumById:{ [user:string]: number } = {}

                                for(let i in count) {
                                    _countSumById[i] = 0;

                                    for(let j in count[i]) {
                                        for(let k in count[i][j]) {
                                            for(let l in count[i][j][k]) {
                                                for(let m in count[i][j][k][l]) {
                                                    _countSumById[i] += count[i][j][k][l][m]
                                                }
                                            }
                                        }
                                    }
                                }

                                for(let _week of this.weeks) {
                                    for(let _day of _week) {
                                        let _elementsByTime:{[time:string]: Array<PGAvailabilitySelection> } = {}

                                        for(let _element of _day.elements) {
                                            if(_elementsByTime[_element.begin] == null) _elementsByTime[_element.begin] = []
                                            _elementsByTime[_element.begin].push(_element)
                                        }

                                        for(let i in _elementsByTime) {
                                            if(_elementsByTime[i].length > 1) {
                                                let _lowerCount:number = null;

                                                for(let _element of _elementsByTime[i]) {
                                                    if(_lowerCount == null || _countSumById[_element.id] < _lowerCount) _lowerCount = _countSumById[_element.id]
                                                }

                                                if(_lowerCount != null) {
                                                    let _prefElementList:Array<PGAvailabilitySelection> = []

                                                    for(let _element of _elementsByTime[i]) {
                                                        if(_lowerCount == _countSumById[_element.id]) _prefElementList.push(_element)
                                                    }

                                                    let _prefElement = _prefElementList[Math.floor(Math.random() * _prefElementList.length)]

                                                    for(let _element of _elementsByTime[i]) {
                                                        if(_element != _prefElement) {
                                                            let _index = _day.elements.indexOf(_element);
                                                            if(_index != -1) {
                                                                _day.elements.splice(_index, 1)
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            setTimeout(() => {
                                this.isLoading = false;
                                resolve();
                            }, 10)
                        })
                    }
                }
            }, firstLoad ? 1 : 200)
        })         
    }

    private _getBookingsCount(from:Date, to:Date) {
        return new Promise<{ [user:string]: PGBookingsCountData }>((resolve) => {

            let _retVal:{ [user:string]: PGBookingsCountData } = {}
            let _reqCount = 0;

            for(let _item of this.availabilityData) {
                let _observable:Observable<PGBookingsCountData> = null;

                if(this.availabilityType == 'product') _observable = this.bookingService.getBookingsCount(this.productType, _item.id, from, to)
                else if(this.availabilityType == 'user') _observable = this.customerCareService.getBookingsCount(_item.id, from, to)
                
                if(_observable != null) {
                    _reqCount++;
                    _observable.subscribe((data) => {

                        _retVal[_item.id] = data;

                        _reqCount--
                        if(_reqCount == 0) resolve(_retVal);
                    })
                }
            }

            if(_reqCount == 0) resolve(_retVal);
        })
    }

    getDayFlagClass(dayData:PGCalendarDay) {
        var _retVal = '';

        if(dayData != null) {
            if(dayData.flags != null) {
                for(var i in dayData.flags) {
                    if(dayData.flags[i]) {
                        _retVal += ' PGCalendar-Day--Is' + i.charAt(0).toUpperCase() + i.slice(1);
                    }
                }
            }

            if(!this.selectOnlyWithElements || (dayData.elements != null && dayData.elements.length > 0)) {
                _retVal += ' PGCalendar-Day--IsSelectable';
            }

            if(dayData == this.lastSelectedDay) {
                _retVal += ' PGCalendar-Day--WasSelected';
            }

            if(dayData == this.selectedDay || (this.selectedAvailability != null && dayData.elements.indexOf(this.selectedAvailability) != -1)) {
                _retVal += ' PGCalendar-Day--IsSelected';
            }

            if(dayData == this.selectedDayLapseFrom) {
                _retVal += ' PGCalendar-Day--IsLapseFrom';
            }

            if(dayData == this.selectedDayLapseTo) {
                _retVal += ' PGCalendar-Day--IsLapseTo';
            }

            if(this.selectedDayLapseFrom != null && this.selectedDayLapseTo != null) {
                if(dayData.date.num >= this.selectedDayLapseFrom.date.num && dayData.date.num <= this.selectedDayLapseTo.date.num) {
                    _retVal += ' PGCalendar-Day--IsLapseInside';
                }
            }
            else if(this.dayHover != null && (this.selectedDayLapseFrom != null || this.selectedDayLapseTo != null)) {
                let _cLapseDay = this.selectedDayLapseFrom || this.selectedDayLapseTo;

                if(dayData.date.num >= _cLapseDay.date.num && dayData.date.num <= this.dayHover.date.num) {
                    _retVal += ' PGCalendar-Day--IsLapseInside';
                }
                else if(dayData.date.num <= _cLapseDay.date.num && dayData.date.num >= this.dayHover.date.num) {
                    _retVal += ' PGCalendar-Day--IsLapseInside';
                }
            }
        }

        return _retVal;
    }

    getDayBackgroundClass(dayData:PGCalendarDay) {
        if(dayData == this.selectedDay) {
            return 'bg-primary'
        }
        else if(this.selectOnlyWithElements && (dayData.elements == null || dayData.elements.length == 0)) {
            return 'bg-light'
        }
        else {
            return 'bg-primary'
        }
    }

    getDayTitleClass(dayData:PGCalendarDay) {
        if(dayData == this.selectedDay) {
            return 'text-white'
        }
        else if(dayData.flags.today) return 'text-primary';
        else if(this.selectOnlyWithElements && (dayData.elements == null || dayData.elements.length == 0)) {
            return 'text-muted'
        } 
    }

    setSelectedDay(dayData:PGCalendarDay) {
        if(this.dayMode != null) {
            if(this.dayMode == 'single') {
                this.lastSelectedDay = this.selectedDay;

                if(dayData == null) {
                    this.selectedDay = null; 
                }
                else if(!this.selectOnlyWithElements || (this.selectedDay != dayData && dayData.elements.length > 0)) {
                    this.selectedDay = dayData;

                    if(dayData.elements[0] != null) {
                        this.availabilitySelected.emit(dayData.elements[0])
                    }
                }
            }
            else if(this.dayMode == 'lapse') {
                if(this.selectedDayLapseFrom == dayData) {
                    this.selectedDayLapseFrom = null;
                    if(this.selectedDayLapseTo != null) {
                        this.selectedDayLapseFrom = this.selectedDayLapseTo;
                        this.selectedDayLapseTo = null;
                    }
                }
                else if(this.selectedDayLapseTo == dayData) {
                    this.selectedDayLapseTo = null;
                }
                else if(!this.selectOnlyWithElements || dayData.elements.length > 0) {
                    if(this.selectedDayLapseFrom == null || this.selectedDayLapseTo != null) {
                        this.selectedDayLapseFrom = dayData;
                        this.selectedDayLapseTo = null;
                    }
                    else {
                        this.selectedDayLapseTo = dayData;

                        let _cFrom = moment(this.selectedDayLapseFrom.date).format('YYYY-MM-DD');
                        let _cTo = moment(this.selectedDayLapseTo.date).format('YYYY-MM-DD')

                        if(_cFrom > _cTo) {
                            let _cSwap = _cFrom;

                            _cFrom = _cTo;
                            _cTo = _cSwap;

                            let _objSwap = this.selectedDayLapseFrom;
                            this.selectedDayLapseFrom = this.selectedDayLapseTo;
                            this.selectedDayLapseTo = _objSwap;
                        }

                        this.availabilitySelected.emit({
                            from: _cFrom,
                            to: _cTo,
                        })
                    }
                }
            }
        }
        else {
            this.daysWithElementsAround = [];   
            this.daysWithElementsAroundBefore = false;
            this.daysWithElementsAroundAfter = false;

            this.lastSelectedDay = this.selectedDay;

            if(dayData == null) {
                this.selectedDay = null; 
            }
            else if(!this.selectOnlyWithElements || (this.selectedDay != dayData && dayData.elements.length > 0)) {
                this.selectedDay = dayData;

                let _selectedIndex = null;
                for(let i = 0; i < this.daysWithElements.length; i++) {
                    if(this.daysWithElements[i] == this.selectedDay) {
                        _selectedIndex = i;
                        break;
                    }
                }
    
                if(_selectedIndex != null) {
                
                    let _aroundNum = 9;
    
                    let _indexFrom = Math.max(0, _selectedIndex - Math.floor(_aroundNum / 2));
                    let _indexTo = Math.min(this.daysWithElements.length, _indexFrom + _aroundNum);
                    _indexFrom = Math.max(0, _indexTo - _aroundNum);
        
                    for(let i = _indexFrom; i < _indexTo; i++) {
                        this.daysWithElementsAround.push(this.daysWithElements[i]);
                    }
                }
    
                if(this.daysWithElementsAround[0] != this.daysWithElements[0]) {
                    this.daysWithElementsAroundBefore = true;
                    this.daysWithElementsAround.shift()
                }
    
                if(this.daysWithElementsAround[this.daysWithElementsAround.length - 1] != this.daysWithElements[this.daysWithElements.length - 1]) {
                    this.daysWithElementsAroundAfter = true;
                    this.daysWithElementsAround.pop()
                }
            }
        }
    }

    getDetailAnimationOrigin() {
        var _selectedIndex = null;

        for(var i = 0; i < this.weeks.length; i++) {
            for(var j = 0; j < this.weeks[i].length; j++) {
                if(this.selectedDay == this.weeks[i][j]) {
                    _selectedIndex = [i, j];
                    break;
                }
            }

            if(_selectedIndex != null) break;
        }

        if(_selectedIndex != null) {
            return ((_selectedIndex[1] + 0.5) / 7 * 100).toString() + '%,' +  ((_selectedIndex[0] + 0.5) / 5 * 86 + 14).toString() + '%';
        }
    }

    getDayAvailabilityStatusClass(dayData:PGCalendarDay) {
        if(dayData == this.selectedDay) return 'white';
        else return this.getAvailabilityStatusClass(dayData.availability)
    }

    getAvailabilityStatusClass(availability:{ current:number, total:number }) {
        if(availability != null) {
            let _cRatio = availability.current / availability.total;

            if(_cRatio > 0.5) return 'success';
            else if (_cRatio > 0.25) return 'warning';
            else if (_cRatio > 0) return 'danger';
            else return 'secondary';
        }
        else {
            return 'primary'
        }
    }

    getDayAvailabilityStatusText(dayData:PGCalendarDay) {
        if(dayData.availability != null) {
            return dayData.availability.current + '/' + dayData.availability.total;
        }
        else {
            return null;
        }
    }

    isElementUnavailable(element:PGAvailabilitySelection) {
        return element.availability != null && element.availability.current <= 0;
    }

    dayHover:PGCalendarDay = null;

    onMouseEnter(dayData:PGCalendarDay) {
        this.dayHover = dayData;
    }

    onMouseLeave(dayData:PGCalendarDay) {
        if(this.dayHover == dayData) {
            this.dayHover = null;
        }
    }

    formatCurrentAvailability(element:PGAvailabilitySelection) {
        if(element.availability == null || element.availability.current <= 0) return 0;
        else return element.availability.current;
    }

    mustDayShowMonth(week:number, day:number) {
        let _prevWeek = week;
        let _prevDay = day - 1;
        if(_prevDay == -1) {
            _prevWeek--;
            _prevDay = 6 
        }

        if(_prevWeek == -1) return true;
        else {
            if(this.weeks[_prevWeek][_prevDay].flags.disabled) return true;
            if(this.weeks[_prevWeek][_prevDay].date.month != this.weeks[week][day].date.month) return true;
            else return false;
        }
    }

    getBorderClass(week:number, day:number) {
        let _borderClass = '';

        let _nextWeek = week + 1;
        
        if(this.weeks[_nextWeek] != null && this.weeks[_nextWeek][day].date.month != this.weeks[week][day].date.month) {
            _borderClass += ' PGCalendar-Content-Week-Day--BorderBottom' 
        }

        let _nextDay = day + 1;
        
        if(this.weeks[week][_nextDay] != null && this.weeks[week][_nextDay].date.month != this.weeks[week][day].date.month) {
            _borderClass += ' PGCalendar-Content-Week-Day--BorderRight' 
        }

        return _borderClass;
    }

    selectedAvailability:PGAvailabilitySelection = null;

    setSelectedAvailability(element:PGAvailabilitySelection) {
        this.selectedAvailability = element;
        this.availabilitySelected.emit(this.selectedAvailability)
    }

    getElementTime(element:PGAvailabilitySelection, type:'begin'|'end', toCurrentTimezone?:boolean) {
        let _time = element[type]

        if(toCurrentTimezone) {
            _time = moment.tz(element.date + ' ' + _time, element.timezone).tz(this.currentTimezone).format('HH:mm');
        }

        return this.localizationService.format.time(_time);
    }
}