import { Component, OnInit, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';


@Component({
  selector: 'app-pg-slider',
  templateUrl: './pg-slider.component.html',
  styleUrls: ['./pg-slider.component.scss'],
  providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgSliderComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgSliderComponent),
        multi: true,
    }]
})

export class PgSliderComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() min:number;
    @Input() max:number;
    @Input() step:number;

    @Input() scale:'normal'|'quadratic';

    value:number = null;

    constructor() { }

    ngOnInit(): void {
    }

    scaledMin:number = null;
    scaledMax:number = null;
    scaledStep:number = null;

    scaledValue:number = null;
    scaledValueList:Array<number> = null;

    ngOnChanges(): void {
        if(this.step == null) this.step = 1;

        this.scaledMin = this.min;
        this.scaledMax = this.max;
        this.scaledStep = this.step;
        this.scaledValueList = null;

        if(this.scale == 'quadratic') {
            this.scaledStep = 1;

            let _diff = this.max - this.min;
            let _stepNum = Math.ceil(Math.sqrt(_diff));

            this.scaledMin = 0;
            this.scaledMax = _stepNum;

            this.scaledValueList = [];

            for(let i = 0; i < _stepNum + 1; i++) {
                let _val = this.min + Math.pow(i / _stepNum, 2) * _diff;
                _val = Math.round(_val / this.step) * this.step;

                this.scaledValueList.push(_val)
            }

            this._generateScaledValue()
        }
    }

    private _generateScaledValue() {
        this.scaledValue = null;

        if(this.value != null && this.scaledValueList != null) {
            let _val = 0;

            for(let i = 1; i < this.scaledValueList.length; i++) {
                let _currDiff = Math.abs(this.scaledValueList[i] - this.value)
                let _valDiff = Math.abs(this.scaledValueList[_val] - this.value)
    
                if(_valDiff > _currDiff) _val = i;
            }
    
            this.scaledValue = _val;
        }
    }

    getValue() {
        if(this.scaledValueList != null) {
            return this.scaledValue;
        } 
        else return this.value;
    }

    setValue(val:number) {
        if(this.scaledValueList != null) {
            this.scaledValue = val;
            val = this.scaledValueList[val];
        }

        if(this.value != val) {
            this.value = val;
            
           this.onValueChange();
        }
    }

    onValueChange() {
        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(this.value);
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
        this._generateScaledValue();
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        return (!this.required || this.value != null)  ? null : {
            required: {
                valid: false
            }
        }
    };
}
