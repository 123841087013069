<div class="PGResourceAssociation">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <div [hidden]="isLoading" class="row">
        <div class="col">
            <div class="PGResourceAssociation-Field">
                <app-pg-list-editor [readonly]="readonly || isSaving" [resource]="resourceId" [resourceData]="resourceData" [resourceSemantic]="resourceSemantic" [ngModel]="selectedElementsString" (ngModelChange)="onSelectedElementsChange($event)">
                </app-pg-list-editor>
            </div>
        </div>
    </div>
</div>
