<div class="PGModal">
    <div class="modal-header">
        <h5 class="modal-title">{{modalData.title}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="modalData.body"></div>
    </div>
    <div class="modal-footer">
        <button *ngFor="let button of modalData.buttons" class="btn btn-{{button.color}}" (click)="modal.close(); button.click();">
            {{button.text}}
        </button>
    </div>
</div>
