import { ConfigData, RoleData } from "../services/data.service";

export class ConfigApplication {
    id:string = null;
    name:string = null;

    config:{
        access: {
            [role:string]: 'read'|'full'
        }
    } = {
        access: {}
    };

    constructor(fromData?:ConfigData) {
        if(fromData != null) {
            this.id = fromData.id;
            this.name = fromData.modello;
            if(fromData.data != null) this.config = JSON.parse(fromData.data);
        }
    }
}

export class ConfigApplicationsIndex {
    public list: Array<ConfigApplication> = [];
    public byName: { [id:string]: ConfigApplication } = {}

    constructor(fromData?:Array<ConfigData>, rolesData?:Array<RoleData>) {
        if(fromData != null) {
            for(let _cRow of fromData) {
                if(_cRow.tipo == 'application') {
                    this._addApplication(new ConfigApplication(_cRow));
                }
            }
        }

        if(rolesData != null) {
            let _availableRoles = [];

            for(let _cRole of rolesData) {
                _availableRoles.push(_cRole.id.toString());
            }

            for(let _cApplication of this.list) {
                let _toBeDeleted = [];

                for(let i in _cApplication.config.access) {
                    if(_availableRoles.indexOf(i) == -1) _toBeDeleted.push(i)
                }

                for(let i of _toBeDeleted) {
                    delete _cApplication.config.access[i];
                }
            }
        }
    }

    private _addApplication(application:ConfigApplication) {

        this.list.push(application);

        this.byName[application.name] = application;
    }

    hasAccess(role:string, application:string) {
        return this.byName[application] != null && this.byName[application].config.access[role] != null;
    }
}