import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ExportCsvService {

    constructor() { }

    
    private _escapeCSV(value:any) {
        if(value == null) value = '';
        else if(typeof value == 'object') {
            if(value.text != null) value = value.text;
            else if(value.value != null) value = value.value;
            else value = '';
        }
        else if(typeof value != 'string') value = value.toString();

        value = value.replace(/<[^>]*>/g, '');

        if(/^[\+|-|=|@]/.test(value)) {
            value = "'" + value
        }

        if(/"|\;|\r|\n/.test(value)) {
            return '"' + value.replace(/"/g, '""') + '"';
        }
        else return value;
    }

    private _sendDownload(fileName:string, csvContent:string) {
        let _tmpElement = document.createElement('a');
        _tmpElement.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent));
        _tmpElement.setAttribute('download', fileName + '.csv');
    
        _tmpElement.style.display = 'none';
        document.body.appendChild(_tmpElement);
    
        _tmpElement.click();
    
        document.body.removeChild(_tmpElement);
    }

    exportArray(fileName:string, values:Array<Array<string>>) {
        let _csvContent = '';

        for(let _cRow of values) {
            let _csvContentRow = '';
            let _isFirst = true;

            if(_cRow != null) {
                for(let i = 0; i < _cRow.length; i++) {
                    if(_isFirst) _isFirst = false;
                    else _csvContentRow += ';'

                    let _cVal = _cRow[i];

                    _csvContentRow += this._escapeCSV(_cVal);
                }
            }

            _csvContent += '\r\n' + _csvContentRow;
        }

        this._sendDownload(fileName, _csvContent);
    }
}
