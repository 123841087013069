import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationService } from '../../services/localization.service';
import { OptionMapsService } from '../../services/option-maps.service';

@Component({
  selector: 'app-pg-priority-picker',
  templateUrl: './pg-priority-picker.component.html',
  styleUrls: ['./pg-priority-picker.component.scss']
})
export class PgPriorityPickerComponent implements OnInit {

    selectOptions:Array<{ value: string, text: string }> = []
    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private optionMapsService:OptionMapsService) { }

    ngOnInit(): void {
        let _optionList = this.optionMapsService.getResourceFieldOptionMap('*', 'priority');

        for(let _option of _optionList) {
            this.selectOptions.push({ value: _option.value, text: _option.value + ' - ' + _option.text })
        }
    }

}
