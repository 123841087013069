
<span class="PGTags {{!readonly ? 'PGTags--NotReadOnly' : ''}}">
    <span ngbDropdown [autoClose]="false" class="PGTags-Select">
        <div ngbDropdownAnchor class="input-group">
            <input #inputElement class="form-control PGTags-Value" placeholder="{{placeholder}}"
                [readonly]="readonly" [ngModel]="value" (ngModelChange)="setValue($event);"
                (focus)="onInputFocus()" (focusout)="onInputBlur()" (keypress)="onKeyPress($event)"/>
        </div>
        <div ngbDropdownMenu class="dropdown-menu PGTags-Select-Dropdown {{isDropdownClosing ? 'pg-animation-fadeout' : 'pg-animation-fadein'}}">
            <span class="PGTags-Select-Dropdown-Options">
                <app-pg-loading *ngIf="isDropdownLoading" pgAnimations="FadeIn" [isOverlay]="true"></app-pg-loading>
                <!-- NB: sulle options ho usato il mousedown come evento perché il click dava problemi "strani" -->
            
                <div *ngIf="options.length == 0" class="dropdown-item disabled">Nessun suggerimento</div>
                <ng-container *ngFor="let option of options; let i = index;" >
                    <div class="dropdown-item {{isOptionSelected(option) ? 'active' : ''}}" 
                        (mousedown)="selectOption(option)">
                        <span [innerHTML]="option.view != null ? option.view : option.value"></span>
                    </div>
                </ng-container>
            </span>
        </div>
    </span>
</span>
