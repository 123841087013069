import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-forms-data-import',
  templateUrl: './forms-data-import.component.html',
  styleUrls: ['./forms-data-import.component.scss']
})
export class FormsDataImportComponent implements OnInit {

    @Output() setImportData = new EventEmitter<google.maps.places.PlaceResult>();

    placeData:google.maps.places.PlaceResult = null;

    constructor() { }

    ngOnInit(): void {
    }

    skipImport() {
        this.setImportData.emit(null)
    }

    doImport() {
        this.setImportData.emit(this.placeData)
    }
}
