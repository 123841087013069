import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { EFProduct } from '../../../models/experience.model';

@Component({
  selector: 'app-ef-list',
  templateUrl: './ef-list.component.html',
  styleUrls: ['./ef-list.component.scss']
})
export class EfListComponent implements OnInit, OnChanges {

    @Input() productsData:Array<EFProduct>;
    @Input() vertical:boolean;
    @Input() oneItem:boolean;
    @Input() full:boolean;

    @Output() showProduct = new EventEmitter<string>();

    constructor() { }

    visibleProductsData:Array<EFProduct> = []

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.visibleProductsData = [];
        this.viewMoreProducts()
    }

    viewMoreProducts() {
        for(let i = 0; i < 10; i++) {
            if(this.visibleProductsData.length < this.productsData.length) {
                this.visibleProductsData.push(this.productsData[this.visibleProductsData.length])
            }
        }
    }
}
