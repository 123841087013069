<div class="PGChat bg-white">
    <div *ngIf="!isLoggedIn" class="PGChat-Login">
        <ng-container *ngIf="authorizationToken == null">
            <div class="form-group mb-3">
                <label>
                    <b>Guest name</b>
                </label>
                <input class="form-control" [(ngModel)]="unathorizedName" (keyup)="onUnathorizedNameKeyUp($event)"/>
            </div>
            <button class="btn btn-primary btn-block" [disabled]="!canUnauthorizedLogIn()" (click)="unauthorizedLogIn()">Login as Guest</button>
        </ng-container>
    </div>
    <div *ngIf="isWaiting()" class="PGChat-Waiting">
        <div *ngIf="chatBookingData != null" class="text-muted text-center px-3 mb-3">
            Your customer care session will start on<br/>{{chatBookingData.from | locFormat:'date'}} at {{chatBookingData.from | locFormat:'time'}}
        </div>
        <ng-container *ngIf="chatBookingData == null">
            <div class="text-muted text-center">
                <ng-container *ngIf="customerCareAvailability == 0">
                    <h4 class="px-3 mb-3">No operator available at this time</h4>
                    <div class="px-3 mb-3">Try again later
                        <span *ngIf="hasCustomerCareBooking"> or<br/>
                            <button class="btn btn-link p-0" (click)="customerCareBookingRequest.emit()">book a customer care session</button>
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="customerCareAvailability > 0">
                    <h4 class="px-3 mb-3">An operator will contact you shortly</h4>
                    <div class="px-3 mb-3">Please wait</div>
                </ng-container>
            </div>
        </ng-container>
        <app-pg-loading></app-pg-loading>
    </div>
    <ng-container *ngIf="!isWaiting()">
        <div *ngIf="hasAccessError" class="text-danger">ERROR</div>
        <div *ngIf="!hasAccessError" class="PGChat-Outer">
            <div *ngIf="callChannel != null" class="PGChat-Call bg-white">
                <app-pg-call [callToken]="callToken" [callChannel]="callChannel" [callURL]="callURL"></app-pg-call>
            </div>
            <div *ngIf="callChannel == null" class="PGChat-Inner">                    
                <div class="PGChat-CurrentChannel">
                    <ng-container *ngIf="channels[getCurrentChannel()]; let channel">
                        <div class="PGChat-CurrentChannel-Content">
                            <ng-container *ngIf="channel.status != null">
                                <div class="PGChat-CurrentChannel-Content-Main">
                                    <div #messagesElement class="PGChat-CurrentChannel-Content-Main-Messages py-1 px-2">
                                        <ng-container *ngFor="let message of channel.status.messages; let i = index;">
                                            <div *ngIf="hasMessageDate(channel.status.messages, i)" class="text-muted border-bottom mb-2">
                                                <small class="text-muted">{{ getMessageDate(message) }}</small>
                                            </div>
                                            <div class="mb-2">
                                                <div *ngIf="hasMessageUserAndTime(channel.status.messages, i)">
                                                    <b class="me-2">{{ getUserName(message.from) }}</b>
                                                    <small class="text-muted">{{ getMessageTime(message) }}</small>
                                                </div>
                                                <div>
                                                    <span class="PGChat-CurrentChannel-Content-Main-Messages-Content">{{ message.content }}</span>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="PGChat-CurrentChannel-Content-Main-Input p-1 border-top">
                                        <input class="form-control form-control-sm" [(ngModel)]="editMessageText" (keyup)="onEditMessageTextKeyUp($event)"/>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</div>
