<div class="PGRealmInvite text-center">
    <div *ngIf="isLoggedIn() && realmData != null">
        <app-pg-loading *ngIf="isRequestingAddRealm" [isOverlay]="true"></app-pg-loading>
        <div class="PGRealmInvite-Card">
            <app-pg-realm-card [realmData]="realmData"></app-pg-realm-card>
        </div>
        <div class="mt-3">
            <button *ngIf="!getRealmStatus()" class="btn btn-primary" [disabled]="isRequestingAddRealm" (click)="requestAddRealm()">
                <i class="fa-regular fa-user-plus"></i>
                <span>{{ 'pg-realm-invite.request-access' | locTranslate }}</span>
            </button>

            <div *ngIf="getRealmStatus(); let status">
                <div *ngIf="status == 'unavailable'" class="text-danger">{{ 'pg-realm-invite.status-unavailable' | locTranslate }}</div>
                <div *ngIf="status == 'available'">{{ 'pg-realm-invite.status-available' | locTranslate }}</div>
                <div *ngIf="status == 'pending'">{{ 'pg-realm-invite.status-pending' | locTranslate }}</div>
            </div>
        </div>
    </div>
</div>
