import { ConfigData, ModelsData, RoleData, ModelFieldData, DataFilter, PermissionData } from '../services/data.service';
import { ConfigRelationsIndex, ConfigRelation } from "./config.relations.model";

export class ConfigProfileRoleActions {
    /**
     * permesso di elencare gli elementi della risorsa, la rende accessibile dall'albero di navigazione
     */

    list:boolean = null;

    /**
     * permesso di visualizzare gli elementi della risorsa, rende accessibili i singoli elementi e di elencare gli elementi in relazione con un'altra risorsa
     */

    view:boolean = null;

    /**
     * permesso di modificare la risorsa
     */

    edit:boolean = null;

    /**
     * permesso di creare nuovi elementi della risorsa
     */

    create:boolean = null;

    /**
     * permesso di eliminare elementi della risorsa
     */

    delete:boolean = null;

    constructor(fromData?:boolean) {
        if(fromData != null) {
            for(let i in this) {
                this[i] = fromData as any;
            }
        }
    }
}

/**
 * classe che definisce i permessi di un ruolo su una risorsa
 */

export class ConfigProfileRole {

    id:string = null;

    model:string = null;

    /**
     * id/nome del ruolo
     */

    role_id:string = null;

    /**
     * definisce le azioni che il ruolo può compiere sulla risorsa
     */

    actions:ConfigProfileRoleActions;

    /**
     * definisce gli accessi per il campo
     */

    fields: {
        [name:string] : {
            view: boolean,
            edit: boolean,
            missing?: boolean,
            removed?: boolean
        }
    } = {}

    /**
     * definisce i filtri impostati di base sulla risorsa (ie: definisce a quali elementi ha accesso)
     */

    //RIMOSSA GESTIONE FILTRI PER PROFILO in favore di gestione con user_id e group_id
    //filter: Array<DataFilter> = null;

    ignore_user_group = false;

    private _ifIsNull(val:any, replace:any) {
        if(val == null) return replace
        else return val;
    }

    constructor(fromData:PermissionData, rolesData:Array<RoleData>, modelFields?:Array<ModelFieldData>, relationsConfig?:Array<ConfigRelation>) {
        if(fromData != null) {
            this.id = fromData.id;
            this.model = fromData.model;
            this.role_id = fromData.role_id;

            this.actions = {
                list: this._ifIsNull(fromData.list, null),
                view: this._ifIsNull(fromData.view, null),
                edit: this._ifIsNull(fromData.edit, null),
                create: this._ifIsNull(fromData.create, null),
                delete: this._ifIsNull(fromData.delete, null),
            };

            if(fromData.fields != null) {
                this.fields = JSON.parse(fromData.fields);
            }

            /*if(fromData.filter != null) {
                this.filter = JSON.parse(fromData.filter);
            }*/

            if(fromData.ignore_user_group != null) this.ignore_user_group = fromData.ignore_user_group;
        }

        let _isBase = false;

        if(rolesData != null) {
            for(let _role of rolesData) {
                if(_role.id == this.role_id) {
                    if(_role.name == 'base') _isBase = true;
                    break;
                }
            }
        }

        if(this.actions == null) {
            this.actions = new ConfigProfileRoleActions(_isBase ? false : null);
        }
        else if(_isBase) {
            for(let i in this.actions) {
                if(this.actions[i] == null) {
                    this.actions[i] = false;
                }
            }
        }    

        if(modelFields != null) {
            for(let _field of modelFields) {
                if(this.fields[_field.name] == null) {
                    this.fields[_field.name] = {
                        view: _isBase ? true : null,
                        edit: _isBase ? true: null,
                        missing: true
                    }
                }

                if(_field.readonly) {
                    this.fields[_field.name].edit = false;
                }
            }

            for(let i in this.fields) {
                let _removed = true;

                for(let _field of modelFields) {
                    if(_field.name == i) {
                        _removed = false;
                        break;
                    }
                }

                if(_removed) {
                    this.fields[i].removed = true;
                }
            }
        }
    }

    toData(save:boolean):PermissionData {
        let _fieldsData = JSON.parse(JSON.stringify(this.fields));

        if(save) {
            let _toRemove = [];

            for(let i in _fieldsData) {
                if(typeof _fieldsData[i].missing != 'undefined') delete _fieldsData[i].missing;
                else if(_fieldsData[i].removed) _toRemove.push(i)
            }

            for(let _item of _toRemove) {
                delete _fieldsData[_item];
            }
        }

        return {
            id: this.id,
            model: this.model,
            role_id: this.role_id,
            list: this.actions.list,
            view: this.actions.view,
            edit: this.actions.edit,
            create: this.actions.create,
            delete: this.actions.delete,
            fields: JSON.stringify(_fieldsData),
            //filter: this.filter == null ? null : JSON.stringify(this.filter)
            ignore_user_group: this.ignore_user_group
        }
    }
}

/**
 * classe che definisce i permessi su una risorsa
 */

export class ConfigProfile {
    /**
     * oggetto che raccoglie i permessi dei ruoli sulla risorsa
     */

    roles: { [role:string] : ConfigProfileRole } = {};

    constructor(public model:string, fromData?:Array<PermissionData>, rolesData?:Array<RoleData>, modelFields?:Array<ModelFieldData>, relationsConfig?:Array<ConfigRelation>) {
        if(fromData != null) {
            for(let _cRow of fromData) {
                this.roles[_cRow.role_id] = new ConfigProfileRole(_cRow, rolesData, modelFields, relationsConfig);
            }
        }

        let _neededRoles = [];

        if(rolesData != null) {
            for(let _cRole of rolesData) {
                _neededRoles.push(_cRole.id);
            }
        }

        for(let _cRole of _neededRoles) {
            if(this.roles[_cRole] == null) {
                this.roles[_cRole] = new ConfigProfileRole({ 
                    id: null, 
                    model: model, 
                    role_id: _cRole 
                }, rolesData, modelFields, relationsConfig)
            }
        }
    }

    /**
     * ritorna il JSON di configurazione
     */

    toData(save:boolean):Array<PermissionData> {
        let _retData:Array<PermissionData> = [];

        for(let i in this.roles) {
            _retData.push(this.roles[i].toData(save))
        }

        return _retData;
    }
}

/**
 * indice delle configurazioni dei profili per le risorse
 */

export class ConfigProfilesIndex {
    list:Array<ConfigProfile> = [];
    byModel: { [model:string] : ConfigProfile } = {};

    baseRoleId:string = null;

    constructor(data?:Array<PermissionData>, rolesData?:Array<RoleData>, modelsData?:ModelsData, relationsIndex?:ConfigRelationsIndex) {
        if(data != null) {
            let _permissionsByModel:{
                [model:string]: Array<PermissionData>
            } = {}

            for(let _cRow of data) {
                if(_permissionsByModel[_cRow.model] == null) {
                    _permissionsByModel[_cRow.model] = [];
                }

                _permissionsByModel[_cRow.model].push(_cRow);
            }

            for(let i in _permissionsByModel) {
                let _cRelations = [];
                if(relationsIndex != null) _cRelations = relationsIndex.byModel[i];

                this._addProfile(new ConfigProfile(i, _permissionsByModel[i], rolesData, modelsData[i], _cRelations))
            }
        }

        
        if(rolesData != null) {
            for(let _cRole of rolesData) {
                if(_cRole.name == 'base') {
                    this.baseRoleId = _cRole.id
                    break;
                }
            }
        }

        if(modelsData != null) {
            for(let i in modelsData) {   
                if(this.byModel[i] == null) { // se la configurazione non è definita
                    let _cRelations = [];
                    if(relationsIndex != null) _cRelations = relationsIndex.byModel[i];

                    this._addProfile(new ConfigProfile(i, [], rolesData, modelsData[i], _cRelations))
                }
            }
        }
    }

    private _addProfile(profile:ConfigProfile) {
        this.list.push(profile);
        this.byModel[profile.model] = profile;
    }

    /**
     * ritorna il profilo per un ruolo su una risorsa, effettuando il merge con il profilo BASE
     */

    getResourceRoleMergedProfile(resourceId:string, roleId?:string):ConfigProfileRole {
        let _retVal:ConfigProfileRole = JSON.parse(JSON.stringify(this.byModel[resourceId].roles[this.baseRoleId]));
        
        if(roleId != null && roleId != this.baseRoleId) {
            let _cProfile = this.byModel[resourceId].roles[roleId];

            _retVal.role_id = roleId;

            // TODO: potrebbe essere fatto in modo più elegante

            for(let i in _retVal.actions) {
                if(_cProfile.actions[i] != null) _retVal.actions[i] = _cProfile.actions[i];
            }

            for(let i in _retVal.fields) {
                for(let j in _cProfile.fields[i]) {
                    if(_cProfile.fields[i][j] != null) _retVal.fields[i][j] = _cProfile.fields[i][j];
                }
            }

            /*if(_cProfile.filter != null) {
                _retVal.filter = _cProfile.filter;
            }*/

            _retVal.ignore_user_group = _cProfile.ignore_user_group
        }

        return _retVal;
    }
}