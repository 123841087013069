import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EfMapComponent } from './elements/ef-map/ef-map.component';
import { EfFilterComponent } from './elements/ef-filter/ef-filter.component';
import { EfListComponent } from './elements/ef-list/ef-list.component';
import { EfProductComponent } from './elements/ef-product/ef-product.component';
import { EfCalendarComponent } from './elements/ef-calendar/ef-calendar.component';
import { EfScreensaverComponent } from './elements/ef-screensaver/ef-screensaver.component';
import { EfShowcaseComponent } from './elements/ef-showcase/ef-showcase.component';
import { EfMainComponent } from './ef-main/ef-main.component';
import { EfProductCardComponent } from './elements/ef-product-card/ef-product-card.component';
import { EfItineraryMapComponent } from './elements/ef-itinerary-map/ef-itinerary-map.component';
import { EfCarouselComponent } from './elements/ef-carousel/ef-carousel.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiMoleculesModule } from '../pg-ui-molecules/pg-ui-molecules.module';
import { EfProductMapComponent } from './elements/ef-product-map/ef-product-map.component';
import { EfItineraryStopCardComponent } from './elements/ef-itinerary-stop-card/ef-itinerary-stop-card.component';

@NgModule({
    declarations: [
        EfProductCardComponent,
        EfItineraryMapComponent,
        EfCarouselComponent,
        EfMapComponent,
        EfFilterComponent,
        EfListComponent,
        EfProductComponent,
        EfCalendarComponent,
        EfScreensaverComponent,
        EfShowcaseComponent,
        EfMainComponent,
        EfProductMapComponent,
        EfItineraryStopCardComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        PgUiElementsModule,
        PgUiControlsModule,
        PgUiMoleculesModule
    ],
    exports: [
        EfProductComponent,
        EfProductCardComponent,
        EfItineraryMapComponent,
        EfCarouselComponent,
        EfProductMapComponent,
        EfShowcaseComponent,
        EfMainComponent,
        EfMapComponent,
        EfListComponent,
        EfItineraryStopCardComponent
    ],
    providers: []
})
export class ExperienceFinderModule { }
