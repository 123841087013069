import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { EFItinerary, EFItineraryMeanOfTransport, EFProduct, EFProductType } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';
import { EFDataService } from '../../../services/ef-data.service';

@Component({
  selector: 'app-ef-itinerary-map',
  templateUrl: './ef-itinerary-map.component.html',
  styleUrls: ['./ef-itinerary-map.component.scss']
})
export class EfItineraryMapComponent implements OnInit, OnChanges {

    @Input() itineraryData:EFItinerary = null;

    constructor(private localizationService:LocalizationService, private mapStylesService:MapStylesService, private dataService:EFDataService) {}

    @ViewChild('gmapsElement', { static: true }) gmapsElement: ElementRef;

    @Output() showProduct = new EventEmitter<string>();

    directionsService:google.maps.DirectionsService = null;
    directionsRenderer:google.maps.DirectionsRenderer = null;

    ngOnInit() {
    }
    
    isMapInitialized = false;

    ngOnChanges() {
        if(!this.isMapInitialized) {
            this.initMap();
        }

        this.refreshMarkers();        
    }

    map:google.maps.Map = null;
    mapMarkers:{ [id:string]: google.maps.Marker } = {};
    mapMarkersNoLabelOverlay:{ [id:string]: boolean } = {};
    
    private labelsOverlay: google.maps.OverlayView = null;

    travelDistance:number = null;
    travelTime:number = null;

    initMap() {
        if(this.itineraryData != null && this.itineraryData.mean_of_transport != null) {
            this.travelMode = this.itineraryData.mean_of_transport[0]
        }

        class LabelsOverlay extends google.maps.OverlayView {

            constructor(private mapMarkers:{ [id:string]: google.maps.Marker }, private markerClusterer:MarkerClusterer, private mapMarkersExcluded:{ [id:string]: boolean }) {
                super();

                this.markerClusterer.addListener('clusteringend', () => { 
                    this._clusteredMarkers = [];

                    for(let _cluster of this.markerClusterer.getClusters()) {
                        let _markerList = _cluster.getMarkers();
                        if(_markerList.length > 1) {
                            for(let _marker of _markerList) {
                                this._clusteredMarkers.push(_marker)
                            }
                        }
                    }
                })
            }

            private _clusteredMarkers:Array<google.maps.Marker> = [];
        
            private _div:HTMLDivElement = null;

            private _mapMarkersDiv:{ [id:string]: HTMLDivElement } = {}
        
            onAdd() {
                this._div = document.createElement("div");
                this._div.className = 'EFMapLabelsOverlay'
                
                let _panes = this.getPanes();
                _panes.floatPane.appendChild(this._div);
            }
        
            draw() {
                let _overlayProjection = this.getProjection();

                for(let i in this.mapMarkers) {
                    if(!this.mapMarkersExcluded[i]) {
                        let _div = this._mapMarkersDiv[i];
                        let _marker = this.mapMarkers[i];
    
                        if(_div == null) {
                            _div = document.createElement("div");
                            _div.className = "EFMapLabelsOverlay-Item"
                            _div.innerHTML = '<span>' + _marker.getTitle() + '</span>';
                            
                            this._div.appendChild(_div);
    
                            this._mapMarkersDiv[i] = _div
                        }
    
                        if(_marker.getVisible() && this._clusteredMarkers.indexOf(_marker) == -1) {
                            _div.style.display = 'block'
                            let _position = _overlayProjection.fromLatLngToDivPixel(_marker.getPosition());
                            _div.style.transform = 'translate(' + _position.x + 'px,' + _position.y + 'px)'
                        }
                        else {
                            _div.style.display = 'none'
                        }
                    }
                }
            }
        }

        this.directionsService = new google.maps.DirectionsService();
        this.directionsRenderer = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
            preserveViewport: true
        });

        this.map = new google.maps.Map(this.gmapsElement.nativeElement, {
            center: new google.maps.LatLng(0, 0),
            zoom: 1,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            styles: this.mapStylesService.mapStyles.light,
            gestureHandling: 'cooperative'
        });

        // NB: lo stile dei clusterer è fatto tramite css dalla classe EFGMapsCluster

        this.markerClusterer = new MarkerClusterer(this.map, [], { 
            imagePath: "assets/marker-clusterer/m", 
            imageSizes: [26,30,34,38,42],
            clusterClass: "EFGMapsCluster",
            ignoreHidden: true,
            zoomOnClick: false,
            gridSize: 30,
            averageCenter: true,
            zIndex: 8
        })

        this.markerClusterer.addListener('click', (cluster) => {  
            let _markersList:Array<google.maps.Marker> = cluster.getMarkers();
            let _mapBounds = new google.maps.LatLngBounds();
            
            for(let i in this.mapMarkers) {
                if(_markersList.indexOf(this.mapMarkers[i]) != -1) {
                    _mapBounds.extend(this.mapMarkers[i].getPosition());
                }
            }

            this.map.fitBounds(_mapBounds);
        })

        this.labelsOverlay = new LabelsOverlay(this.mapMarkers, this.markerClusterer, this.mapMarkersNoLabelOverlay);
        this.labelsOverlay.setMap(this.map);

        this.refreshMarkers();

        this.isMapInitialized = true;

        this.centerMap();

        this._renderItinerayDirections();
    }

    travelMode:EFItineraryMeanOfTransport = 'driving';

    setTravelMode(mode:EFItineraryMeanOfTransport) {
        this.travelMode = mode;
        this._renderItinerayDirections();
    }

    hasDirections = false;
    directionsUnavailable = false;

    private _renderItinerayDirections() {
        this.directionsRenderer.setMap(null);
        this.travelDistance = null;
        this.travelTime = null;

        this.hasDirections = false;
        this.directionsUnavailable = false;

        if(this.itineraryData != null && this.itineraryData.collection.length > 1) {
            let _directionsParameters = this.itineraryData.getDirectionsParameters();

            if(_directionsParameters != null) {
                this.hasDirections = true;

                let _travelMode:google.maps.TravelMode = null;
                if(this.travelMode == 'walking') _travelMode = google.maps.TravelMode.WALKING;
                else if(this.travelMode == 'bicycling') _travelMode = google.maps.TravelMode.BICYCLING;
                else _travelMode = google.maps.TravelMode.DRIVING;

                this.directionsService.route({
                    origin: _directionsParameters.origin,
                    destination: _directionsParameters.destination,
                    waypoints: _directionsParameters.waypoints,
                    travelMode: _travelMode,
                    avoidFerries: true,
                }, (result, status) => {
                    let _distance = 0;
                    let _time = 0;

                    if(status == 'OK') { 
                        if(result.routes != null && result.routes.length > 0) {
                            for(let _leg of result.routes[0].legs) {
                                _distance += _leg.distance.value;
                                _time += _leg.duration.value;
                            }
        
                            let _cOptions:google.maps.PolylineOptions = null;
                            
                            if(this.travelMode == 'walking') {
                                _cOptions = {
                                    icons: [{
                                        icon: {
                                            path: 0,
                                            scale: 3,
                                            fillOpacity: 0.7,
                                            fillColor: "#00b3fd",
                                            strokeOpacity: 0.8,
                                            strokeColor: "#3379c3",
                                            strokeWeight: 1
                                        },
                                        repeat: "10px"
                                    }],
                                    strokeColor: "#000000",
                                    strokeOpacity: 0,
                                    strokeWeight: 5
                                }
                            }
                            
                            this.directionsRenderer.setOptions({
                                polylineOptions: _cOptions
                            })
                            this.directionsRenderer.setDirections(result)
                            this.directionsRenderer.setMap(this.map);
                        }

                        if(_distance > 0) {
                            this.travelDistance = _distance    
                        }
        
                        if(_time > 0) {
                            this.travelTime = _time 
                        }
                    }
                    else {
                        this.directionsUnavailable = true;
                    }
                }) 
            }
        } 
    }

    markerClusterer:MarkerClusterer = null;

    resultNum:number = null;

    refreshMarkers() {

        let _productTypes:Array<EFProductType> = [];
        
        if(this.itineraryData != null) {
            for(let _cItem of this.itineraryData.collection) {
                let _cLocation = _cItem.geolocation;

                if(_cLocation != null) {
                    let _cId = _cItem.resource + '_' + _cItem.element;
                    let _productType = this.dataService.getProductTypeFromResource(_cItem.resource)
    
                    if(_productTypes.indexOf(_productType) == -1) {
                        _productTypes.push(_productType)
                    }

                    let _markerStyle = this.mapStylesService.getMarkerStyle(_productType)

                    if(this.mapMarkers[_cId] == null) {
                        this.mapMarkers[_cId] = new google.maps.Marker({ 
                            position: new google.maps.LatLng(_cLocation.coordinates[0], _cLocation.coordinates[1]),
                            zIndex: 10,
                            title: this.localizationService.getTranslatedValue(_cItem.title),
                            icon: _markerStyle.icon,
                            label: _markerStyle.label,
                        });
                        
                        this.mapMarkers[_cId].addListener('click', () => {    
                            this.showProduct.emit(_cId)
                        })

                        if(_markerStyle.noLabelOverlay) this.mapMarkersNoLabelOverlay[_cId] = true;
                        this.markerClusterer.addMarker(this.mapMarkers[_cId])
                    }
                }
            }
        }

        for(let i in this.mapMarkers) {
            this.mapMarkers[i].setMap(this.map);
        }

        this.resultNum = 0;

        for(let i in this.mapMarkers) {
            let _isIn = false;

            if(this.itineraryData != null) {
                for(let _item of this.itineraryData.collection) {
                    if(_item.resource + '_' + _item.element == i) {
                        _isIn = true;
                        break;
                    }
                }
            }

            if(!_isIn) {
                this.mapMarkers[i].setVisible(false);
            }
            else {
                this.resultNum++;
                this.mapMarkers[i].setVisible(true);
            }
        }

        let _clustererClass:string = null;

        if(_productTypes.length == 1) {
            let _cStyle = this.mapStylesService.getMarkerStyle(_productTypes[0])
            if(_cStyle != null && _cStyle.color != null) {
                _clustererClass = 'EFGMapsCluster bg-' + _cStyle.color 
            }
        }
        
        if(_clustererClass != null) this.markerClusterer.setClusterClass(_clustererClass)
        else this.markerClusterer.setClusterClass('EFGMapsCluster');

        this.markerClusterer.repaint();
    }

    centerMap(onlyVisible?:boolean) {
        let _mapBounds = new google.maps.LatLngBounds();
        
        let _resultsNum = 0;

        for(let i in this.mapMarkers) {
            if(!onlyVisible || this.mapMarkers[i].getVisible()) {
                _mapBounds.extend(this.mapMarkers[i].getPosition());
                _resultsNum++;
            }
        }

        if(_resultsNum == 0) {
            _mapBounds.extend(new google.maps.LatLng(36.619987291, 6.7499552751));
            _mapBounds.extend(new google.maps.LatLng(47.1153931748, 18.4802470232));
        }

        let _boundsCenter = _mapBounds.getCenter();
        _mapBounds.extend(new google.maps.LatLng(_boundsCenter.lat() - 0.002, _boundsCenter.lng() - 0.002))
        _mapBounds.extend(new google.maps.LatLng(_boundsCenter.lat() + 0.002, _boundsCenter.lng() + 0.002))

        this.map.fitBounds(_mapBounds);
    }

    getTranslated(product:EFProduct, prop:string) {
        if(product.translations != null && product.translations[this.localizationService.currentLanguage] != null && product.translations[this.localizationService.currentLanguage][prop] != null) {
            return product.translations[this.localizationService.currentLanguage][prop]
        }
        else return product[prop]
    }

    getFormattedTravelDistance() {
        if(this.travelDistance != null && this.travelDistance > 0) return Math.ceil(this.travelDistance / 1000) + ' Km'
    }

    getFormattedTravelTime() {
        if(this.travelTime != null && this.travelTime > 0) {
            let _hours = Math.floor(this.travelTime / 3600).toString();
            if(_hours.length < 2) _hours = '0' + _hours;
            let _minutes = Math.floor((this.travelTime % 3600) / 60).toString();
            if(_minutes.length < 2) _minutes = '0' + _minutes;

            return _hours + ':' + _minutes;
        }
    }
}
    
