<div class="EfItineraryStopCardComponent card {{ disabled ? 'EfItineraryStopCardComponent--Disabled': '' }} {{ full ? 'EfItineraryStopCardComponent--Full': '' }}" (click)="onCardClick()">
    <ng-container *ngIf="stopData != null">
        <app-pg-loading *ngIf="stopData.loading"></app-pg-loading>
        <ng-container *ngIf="!stopData.loading">

            <div *ngIf="stopData.error" class="card-body text-center">
                <div class="alert alert-danger">
                    <div class="mb-3">
                        <i class="fa-regular fa-exclamation-triangle fa-3x"></i>
                    </div>
                    <div>
                        {{ 'experience-finder.ef-itinerary-stop.product-error' | locTranslate }}
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!stopData.error">
                <div *ngIf="stopData.day != null" class="EfItineraryStopCardComponent-Header p-2">
                    <div class="badge bg-white text-dark">
                        {{ 'experience-finder.ef-itinerary-stop.day' | locTranslate }} {{stopData.day}}
                    </div>
                </div>
                <div class="EfItineraryStopCardComponent-Image">
                    <div class="card-img-top">
                        <div *ngIf="getCoverImage(); let image">
                            <app-pg-image-autosize [src]="image" fit="cover"></app-pg-image-autosize>
                        </div>
                        <div *ngIf="!getCoverImage()" class="bg-light text-white d-flex justify-content-center align-items-center">
                            <i class="fa-regular fa-image fa-8x"></i>
                        </div>
                    </div>
                </div>
                <div class="EfItineraryStopCardComponent-Content">
                    <div class="card-body">
                        <div class="EfItineraryStopCardComponent-Content-Inner mb-2">
                            <h3 class="card-title h5 pb-2 mb-2">
                                <span [innerHTML]="getTranslatedProperty('title')"></span>
                            </h3>

                            <div class="card-text text-muted" [innerHTML]="getTranslatedProperty('text') | safe:'html'"></div>
                        </div>
        
                        <div class="EfItineraryStopCardComponent-Info EfItineraryStopCardComponent-Info--Footer">
                            <span *ngIf="stopData.resource != 'External'" class="badge text-white" [style.backgroundColor]="getTypeColor()">
                                <i class="fa-regular" [innerHTML]="getTypeIcon()"></i>
                                <span class="ms-2">{{getTypeText()}}</span>
                            </span>
                            <a *ngIf="stopData.resource == 'External'" class="EfItineraryStopCardComponent-Info-ExternalLink" target="_blank" [href]="stopData.element | safe:'url'">
                                <i class="fa-regular fa-arrow-up-right-from-square me-2 fa-sm"></i>
                                <span>{{stopData.element}}</span>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>

        </ng-container>
    </ng-container>
</div>