import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EnvironmentService } from './environment.service';

export class PersistenceConsentData { 
    [id:string]: boolean 
}
export type PersistenceConsentType = 'technical'|'analytics'
export class PersistenceConsentConfig { 
    id:PersistenceConsentType; 
    required?:boolean; 
}

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

    constructor(private environmentService:EnvironmentService) { }

    isActive = false;
    needConsent = false;

    consentDataChange = new Subject<PersistenceConsentData>();

    consentData:PersistenceConsentData = null
    consentConfig:Array<PersistenceConsentConfig> = [{
        id: 'technical',
        required: true
    },{
        id: 'analytics'
    }]

    activate() {
        this.isActive = true;

        if(this.environmentService.ElectronWrapper != null) {
            this.needConsent = false;

            this.consentData = {}

            for(let _config of this.consentConfig) {
                this.consentData[_config.id] = true;
            }
        }
        else {
            let _savedConsent = localStorage.getItem('PGPersistenceConsent')

            if(_savedConsent != null) {
                this.consentData = JSON.parse(_savedConsent)
            }
            else {
                this.consentData = {}
            }
    
            for(let _config of this.consentConfig) {
                if(_config.required && !this.consentData[_config.id]) {
                    this.needConsent = true;
                }
            }
        }
    }

    hasConsentFor(id:PersistenceConsentType) {
        if(this.environmentService.ElectronWrapper != null) {
            return true;
        }
        else {
            return !this.isActive || this.consentData[id];
        }
    }

    saveConsent(consentData:PersistenceConsentData) {
        localStorage.setItem('PGPersistenceConsent', JSON.stringify(consentData))
        this.consentData = consentData;
        this.consentDataChange.next(this.consentData)

        setTimeout(() => {
            window.location.reload()
        }, 100)
    }

    // TODO: per ora gestisce solo con local storage

    persistenceStorage = 'localStorage';

    setItem(key:string, val:string, checkConsent:PersistenceConsentType) {
        if(checkConsent == null || this.hasConsentFor(checkConsent)) {
            try {
                localStorage.setItem(key, val)
            }
            catch(ex) {
                console.error(ex)
            }
        }
    }

    getItem(key:string) {
        try {
            return localStorage.getItem(key)
        }
        catch(ex) {
            console.error(ex)
            return null;
        }
    }

    removeItem(key:string) {
        try {
            localStorage.removeItem(key)
        }
        catch(ex) {
            console.error(ex)
        }
    }
}
