<ng-container *ngIf="values != null">
    <div class="row">
        <div class="col-6">
            <ng-container *ngIf="{ label: 'Title', type: 'string', locale: true, name: 'title' }; let field">
                <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [languages]="availableLanguages" [(ngModel)]="values.title" (ngModelChange)="handleValuesChange();"></app-pg-form-field>
                <app-pg-detail-field *ngIf="viewOnly" [fieldData]="field" [value]="values.title"></app-pg-detail-field>
            </ng-container>
        </div>
        <div class="col-6">
            <ng-container *ngIf="{ label: 'Color', type: 'select', options: colorOptions, name: 'color' }; let field">
                <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [(ngModel)]="values.color" (ngModelChange)="handleValuesChange();"></app-pg-form-field>
                <app-pg-detail-field *ngIf="viewOnly" [fieldData]="field" [value]="values.color"></app-pg-detail-field>
            </ng-container>
        </div>
    </div>
    <div *ngIf="widgetFields[widget.name] != null" class="row">
        <div *ngFor="let field of widgetFields[widget.name]" class="{{field.type == 'html' || field.type == 'location' ? 'col-12' : 'col-6'}}">
            <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [languages]="availableLanguages" [ngModel]="getParamField(field.name)" (ngModelChange)="setParamField(field.name, $event); handleValuesChange();"></app-pg-form-field>
            <app-pg-detail-field *ngIf="viewOnly" [fieldData]="field" [ngModel]="getParamField(field.name)"></app-pg-detail-field>
        </div>
    </div>
    <div *ngIf="widgetFields[widget.name] == null" class="row">
        <div class="col-12">
            <ng-container *ngIf="{ label: 'Parameters', type: 'json', name: 'params' }; let field">
                <app-pg-form-field *ngIf="!viewOnly" [fieldData]="field" [ngModel]="JSONStringify(values.params)" (ngModelChange)="values.params = JSONParse($event); handleValuesChange();"></app-pg-form-field>
                <app-pg-detail-field *ngIf="viewOnly" [fieldData]="field" [value]="JSONStringify(values.params)"></app-pg-detail-field>
            </ng-container>
        </div>
    </div>
</ng-container>