import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService, PGNotification } from '../../services/notifications.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-pg-notification',
  templateUrl: './pg-notification.component.html',
  styleUrls: ['./pg-notification.component.scss']
})
export class PgNotificationComponent implements OnInit {

    constructor(private localizationService:LocalizationService, private notificationsService:NotificationsService, private dataService:DataService) { }

    @Input() notification:PGNotification;

    ngOnInit(): void {
    }

    formatTime() {
        return this.localizationService.format.datetime(this.notification.created_at);
    }

    formatContent() {
        return this.notificationsService.getLocalizedNotificationContent(this.notification);
    }

    getMainClass() {
        let _retVal = '';

        if(this.notification.status != null) _retVal = 'PGNotification--' + this.notification.status.toUpperCase();

        if(this.notification.type == 'system') _retVal += ' alert alert-danger';
        else {
            _retVal += ' alert';
            if(this.notification.category == 'danger' || this.notification.category == 'warning' || this.notification.category == 'success') _retVal += ' alert-' + this.notification.category;
            else _retVal += ' py-0'
        }

        return _retVal;
    }
    
    getIconClass() {
        if(this.notification.type == 'system' || this.notification.category == 'danger') return 'fa-regular fa-exclamation-triangle';
        else if(this.notification.category == 'warning') return 'fa-regular fa-exclamation text-warning';
        else if(this.notification.category == 'success') return 'fa-regular fa-check text-success';
        else return 'fa-regular fa-circle-info text-info';
    }

    getStatusClass() {
        if(!this.notification.local && this.notification.type != 'system' && this.notification.status != 'read') {
            return 'fa-regular fa-circle fa-xs text-primary';
        }
    }

    childNotifications:Array<PGNotification> = null;

    isLoadingChildren = false;

    areChildNotificationsShown = false;

    toggleChildNotifications() {
        if(this.areChildNotificationsShown) {
            this.hideChildNotifications();
        }
        else {
            this.showChildNotifications();
        }
    }

    showChildNotifications() {
        if(this.childNotifications == null) {
            this.isLoadingChildren = true;

            this.dataService.getNotifications({ filter: [
                { field: 'parent', operator: '==', value: [ this.notification.id ] }
            ] }).subscribe((data) => {
                this.isLoadingChildren = false;
                this.childNotifications = data;
                this.areChildNotificationsShown = true;
            })
        }
        else {
            this.areChildNotificationsShown = true;
        }
    }

    hideChildNotifications() {
        this.areChildNotificationsShown = false;
    }
}
