import { Component, OnInit, ViewChild } from '@angular/core';

import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-pg-image-crop',
    templateUrl: './pg-image-crop-modal.component.html',
    styleUrls: ['./pg-image-crop-modal.component.scss']
})

export class PgImageCropModalComponent implements OnInit {

    imageURL:string;
    cropperSettings: CropperSettings;

    imageData: any;
    isLoading = false;

    @ViewChild('imageCropper', { static: true })
    imageCropper: ImageCropperComponent;

    constructor(private activeModal:NgbActiveModal) {

        this.cropperSettings = new CropperSettings();

        this.cropperSettings.noFileInput = true;
        this.cropperSettings.preserveSize = true;
        this.cropperSettings.canvasWidth = 480;
        this.cropperSettings.canvasHeight = 480;
        this.cropperSettings.keepAspect = false;
        this.cropperSettings.minWidth = 1;
        this.cropperSettings.minHeight = 1;
        this.cropperSettings.cropperDrawSettings.dragIconStrokeColor = 'rgba(0,0,0,0.5)'
        this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(0,0,0,0.5)'

        this.imageData = {};
    }

    ngOnInit() {
        this.isLoading = true;

        let _image = new Image();

        _image.onload = () => {
            this.cropperSettings.canvasWidth = _image.width;
            this.cropperSettings.canvasHeight = _image.height;

            this.imageCropper.setImage(_image);
            this.isLoading = false;
        }

        _image.onerror = (error) => {
            console.error('Image Crop error loading image');
            console.error(error);
        }

        _image.crossOrigin = "Anonymous";

        _image.src = this.imageURL;
    }

    cropCancel() {
        this.activeModal.dismiss();
    }

    cropSave() {
        this.activeModal.close(this.imageData.image);
    }
}
