import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PgChatComponent } from '../pg-chat/pg-chat.component';

@Component({
  selector: 'app-pg-customer-care',
  templateUrl: './pg-customer-care.component.html',
  styleUrls: ['./pg-customer-care.component.scss']
})
export class PgCustomerCareComponent extends PgChatComponent {
    @Input() unathorizedName:string;
    @Input() chatBooking:string;
    @Input() realmId:string;

    @Input() hasCustomerCareBooking:boolean

    @Output() customerCareBookingRequest = new EventEmitter<void>()

    isWaiting() {
        if(!this.isLoggedIn || this.isLoading || this.channels[this.getCurrentChannel()] == null) return true;
        else {
            if(this.callChannel != null) return false;
            else if(this.channels[this.getCurrentChannel()].status != null && this.channels[this.getCurrentChannel()].status.messages.length > 0) return false;

            return true;
        }
    }
}
