import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PgSelectComponent } from '../../pg-ui-controls/pg-select/pg-select.component';
import { AuthService } from '../../services/auth.service';
import { LocalizationService } from '../../services/localization.service';
import { PgProfileEditorService } from '../pg-profile-editor.service';
import { PgUserStatusService, WSServiceUserStatusValue } from '../pg-user-status.service';
import { NgbModal, NgbModalRef, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from '../../services/environment.service';
import { PgAcceptConditionsComponent } from '../../pg-ui-molecules/pg-accept-conditions/pg-accept-conditions.component';

export class PgUserMenuElementData {
    label:string;
    action?:() => void
}

export class PgUserMenuData {
    category?:string;
    elements:Array<PgUserMenuElementData>
}

@Component({
  selector: 'app-pg-user-menu',
  templateUrl: './pg-user-menu.component.html',
  styleUrls: ['./pg-user-menu.component.scss']
})
export class PgUserMenuComponent implements OnInit {

    constructor(private authService:AuthService, private pgProfileEditorService:PgProfileEditorService, private pgUserStatusService:PgUserStatusService, private localizationService:LocalizationService, private modalService:NgbModal, private environmentService:EnvironmentService) { }

    @Input() menuElements:Array<PgUserMenuData>

    registered = false;

    statusOptions = [
        { value: 'online', text: '' },
        { value: 'busy', text: '' },
        { value: 'offline', text: '' },
    ]

    userStatus:WSServiceUserStatusValue = null;

    languageOptions:Array<{ value: string, text: string }> = null;

    stripeAvailable = false;

    hasRealms = false;

    ngOnInit(): void {
        this.stripeAvailable = this.environmentService.environment.StripeAPIKey != null && this.environmentService.environment.StripeAPIKey != '';

        this.registered = this.pgUserStatusService.registered;
        this.pgUserStatusService.registeredChange.subscribe(() => {
            this.registered = this.pgUserStatusService.registered;
        })
    
        this.userStatus = this.pgUserStatusService.userStatus;
        this.pgUserStatusService.userStatusChange.subscribe(() => {
            this.userStatus = this.pgUserStatusService.userStatus;
        })

        this._generateStatusOptionsLabels()

        this.localizationService.statusChange.subscribe(() => {
            this._generateStatusOptionsLabels()
        })

        this.languageOptions = [];

        for(let _language of this.localizationService.availableApplicationLanguages) {
            this.languageOptions.push({ value: _language, text: this.localizationService.languageLabels[_language] })
        }

        this.hasRealms = this.environmentService.hasRealms()

        setTimeout(() => {
            this._checkRealmsWarning();
            this.authService.statusChange.subscribe(() => {
                this._checkRealmsWarning();
            })
        }, 2500)
    }

    showRealmsWarning = null;

    private _warningTooltipTimeout = null;

    private _checkRealmsWarning() {
        if(this.isLoggedIn() && this.authService.supplierMode && this.hasRealms && !this.hasUserSomeRealms()) {
            this.showRealmsWarning = true;
        
            clearTimeout(this._warningTooltipTimeout)
            this._warningTooltipTimeout = setTimeout(() => {
                this.showRealmsWarning = false;
            }, 7500)
        }
    }

    hasUserSomeRealms() {
        return this.authService.isLoggedIn() && (this.authService.user.availableRealms.length > 0 || this.authService.user.unavailableRealms.length > 0 || this.authService.user.pendingRealms.length > 0)
    }

    @ViewChild('statusSelect') statusSelect:PgSelectComponent;

    private _generateStatusOptionsLabels() {
        for(let _option of this.statusOptions) {
            _option.text = '<i class="fa fa-circle ' + this.getStatusColorClass(_option.value as WSServiceUserStatusValue) + '"></i> ' + this.localizationService.translate('pg-user-menu.status-' + _option.value)
        }

        if(this.statusSelect != null) this.statusSelect.setText();
    }

    isLoggedIn() {
        return this.authService.isLoggedIn();
    }

    getUserName() {
        if(this.authService.isLoggedIn()) { return this.authService.user.name; }
    }

    isSwitchingLanguage = false;

    getLanguage() {
        return this.localizationService.currentLanguage;
    }

    setLanguage(val:string) {
        this.isSwitchingLanguage = true;
        this.localizationService.setLanguage(val).then(() => {
            this.isSwitchingLanguage = false;
        }, () => {
            this.isSwitchingLanguage = false;
        })
    }

    isSwitchingRealm = false;

    getUserRealm() {
        if(this.authService.isLoggedIn()) { return this.authService.user.realm; }
    }

    getUserRealmId() {
        let _realm = this.getUserRealm();
        if(_realm != null) return _realm.id
    }

    getAvailableRealms() {
        if(this.authService.isLoggedIn()) { return this.authService.user.availableRealms; }
    }

    getUnavailableRealms() {
        if(this.authService.isLoggedIn()) { return this.authService.user.unavailableRealms; }
    }

    getPendingRealms() {
        if(this.authService.isLoggedIn()) { return this.authService.user.pendingRealms; }
    }

    setUserRealm(id:string) {
        if(id != this.getUserRealmId()) {
            if(!this.authService.user.hasAcceptedPrivacyPolicy(id) || !this.authService.user.hasAcceptedTermsAndConditions(id)) {
                let _modalAcceptContitions = this.modalService.open(PgAcceptConditionsComponent)
    
                let _component = _modalAcceptContitions.componentInstance as PgAcceptConditionsComponent;
                _component.conditionList = [
                    { title: 'pg-footer.terms-and-conditions', text: 'REALMS.' + id + '.terms-and-conditions' },
                    { title: 'pg-footer.privacy-policy', text: 'REALMS.' + id + '.privacy-policy' },
                ]
        
                _modalAcceptContitions.result.then(() => {
                    this.authService.accept(_component.conditionList[0].accepted, _component.conditionList[1].accepted, id).then(() => {
                        this._doSwitchRealm(id)
                    }, () => {})
                }, () => {})
            }
            else {
                this._doSwitchRealm(id)
            }
        }
    }

    private _doSwitchRealm(id:string) {
        this.isSwitchingRealm = true;
        this.authService.setUserRealm(id).then(() => {
            this.isSwitchingRealm = false;
        }, () => {
            this.isSwitchingRealm = false;
        })
    }

    @ViewChild('modalAddRealm') modalAddRealm:ElementRef;

    private _modalAddRealmRef:NgbModalRef = null;

    isRequestingAddRealm = false;

    editAddRealmValue:string = null;
    editAddRealmOptions:Array<{ value: string, text: string }> = null;

    openAddRealm() {
        this.editAddRealmValue = null;
        this.editAddRealmOptions = [];

        for(let _realm of this.environmentService.realmList) {
            let _isIn = false;

            for(let _item of this.authService.user.availableRealms) {
                if(_item.id == _realm.id) _isIn = true;
            }

            for(let _item of this.authService.user.unavailableRealms) {
                if(_item.id == _realm.id) _isIn = true;
            }

            for(let _item of this.authService.user.pendingRealms) {
                if(_item.id == _realm.id) _isIn = true;
            }

            if(!_isIn) {
                this.editAddRealmOptions.push({ value: _realm.id, text: _realm.name })
            }
        }

        this._modalAddRealmRef = this.modalService.open(this.modalAddRealm)
    }

    cancelAddRealm() {
        this._modalAddRealmRef.dismiss()
    }

    requestAddRealm() {
        this.isRequestingAddRealm = true;

        this.authService.requestAddRealm(this.editAddRealmValue).then((data) => {
            this.isRequestingAddRealm = false;
            this._modalAddRealmRef.dismiss()
        })
    }

    getUserRole() {
        if(this.authService.isLoggedIn()) { return this.authService.user.role; }
    }

    getUserRoleId() {
        let _role = this.getUserRole();
        if(_role != null) return _role.id
    }

    getAvailableRoles() {
        if(this.authService.isLoggedIn()) { return this.authService.user.availableRoles; }
    }

    setUserRole(id:string) {
        if(id != this.getUserRoleId()) {
            this.authService.setUserRole(id);
        }
    }

    getUserStripeId() {
        if(this.authService.isLoggedIn()) { return this.authService.user.stripeId; }
    }

    changePassword() {
        this.pgProfileEditorService.requestChangePassword();
    }

    doLogout() {
        this.authService.logout().then(() => {
        })
    }

    onMenuElementClick(element:PgUserMenuElementData) {
        if(element.action != null) {
            element.action();
        }
    }

    getUserGroups() {
        if(this.authService.isLoggedIn()) { return this.authService.user.groups; }
    }

    getStatusColorClass(status:WSServiceUserStatusValue) {
        switch(status) {
            case 'online': return 'text-success';
            case 'offline': return 'text-muted';
            case 'busy': return 'text-danger';
        }
    }

    setUserStatus(status:WSServiceUserStatusValue) {
        this.pgUserStatusService.setUserStatus(status)
    }
}
