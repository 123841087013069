export class PGColor {
    hue:number = null;
    saturation:number = 0.8;
    luminance:number = 0.45;
    opacity:number = 1;

    constructor(fromData?:{ hue?:number, saturation?:number, luminance?:number, opacity?:number }) {
        if(fromData != null) {
            for(let i in fromData) {
                if(fromData[i] != null) {
                    this[i] = fromData[i];
                }
            }
        }
    }

    toString() {
        if(this.hue == null) {
            return 'rgba(0,0,0,0)';
        }
        else {
            let r = 0;
            let g = 0;
            let b = 0;
    
            if(this.hue <= 1 / 6) {
                r = 1;
                g = this.hue / (1 / 6);
            }
            else if(this.hue <= 2 / 6) {
                g = 1;
                r = 1 - (this.hue - 1 / 6) / (1 / 6);
            }
            else if(this.hue <= 3 / 6) {
                g = 1;
                b = (this.hue - 2 / 6) / (1 / 6);
            }
            else if(this.hue <= 4 / 6) {
                g = 1 - (this.hue - 3 / 6) / (1 / 6);
                b = 1;
            }
            else if(this.hue <= 5 / 6) {
                b = 1;
                r = (this.hue - 4 / 6) / (1 / 6);
            }
            else {
                b = (this.hue - 5 / 6) / (1 / 6);
                r = 1;
            }

            r = (1 - this.saturation) + r * this.saturation;
            g = (1 - this.saturation) + g * this.saturation;
            b = (1 - this.saturation) + b * this.saturation;

            let _cLuminance = (0.299 * r + 0.587 * g + 0.114 * b);
    
            if(_cLuminance > this.luminance) {
                r = r * this.luminance / _cLuminance;
                g = g * this.luminance / _cLuminance;
                b = b * this.luminance / _cLuminance;
            }
            else {
                let _sumCol = (this.luminance - _cLuminance) / (1 - _cLuminance);

                r = _sumCol + r * (1 - _sumCol);
                g = _sumCol + g * (1 - _sumCol);
                b = _sumCol + b * (1 - _sumCol);
            }

            r = Math.round(r * 255);
            g = Math.round(g * 255);
            b = Math.round(b * 255);

            return 'rgba(' + r + ',' + g + ',' + b + ',' + this.opacity + ')';
        }
    }

}