import { Component, forwardRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalizationService } from '../../services/localization.service';

class PGDateIntervalDay { 
    num: number; 
    disabled?:boolean;
    selected?:boolean;
    highlighted?:boolean;
}

class PGDateIntervalMonth {
    year: number;
    num: number;
    weeks: Array<Array<PGDateIntervalDay>>
}

@Component({
    selector: 'app-pg-date-interval',
    templateUrl: './pg-date-interval.component.html',
    styleUrls: ['./pg-date-interval.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgDateIntervalComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgDateIntervalComponent),
        multi: true,
    }]
})
export class PgDateIntervalComponent implements OnInit, ControlValueAccessor, Validator {

    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    value:string = null;

    from:string = null;
    to:string = null;

    nowDate:Date = null;

    months:Array<PGDateIntervalMonth> = [];

    constructor(private modalService:NgbModal, private localizationService:LocalizationService, private zone:NgZone) { }

    ngOnInit(): void {
    }

    private _scrollToMonth(index?:number) {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                if(index == null) {
                    let _cBody = document.querySelector('.PGDateIntervalModal .modal-body');

                    if(_cBody != null) {
                        _cBody.scrollTop = 0;
                    }
                }
                else {
                    let _cMonths = document.querySelectorAll('.PGDateIntervalModal .PGDateInterval-Month');

                    if(_cMonths != null && _cMonths[index] != null) {
                        _cMonths[index].scrollIntoView({ behavior: 'smooth' })
                    }
                }
            }, 100)
        })
    }

    addMonth(skipScroll?:boolean) {
        let _startDate:Date = null;

        if(this.months.length == 0) {
            _startDate = new Date(this.nowDate.getTime());
            _startDate.setDate(1);
        }
        else {
            let _lastMonth = this.months[this.months.length - 1];
            _startDate = new Date(_lastMonth.year, _lastMonth.num, 1);
        }

        let _cMonth:PGDateIntervalMonth = {
            num: _startDate.getMonth() + 1,
            year: _startDate.getFullYear(),
            weeks: []
        };

        this.months.push(_cMonth);

        let _cWeek:Array<PGDateIntervalDay> = [];
        _cMonth.weeks.push(_cWeek);

        let _emptyDays = (6 + _startDate.getDay() - this.localizationService.data.weekStart) % 7;

        for(let i = 0; i <= _emptyDays; i++) {
            _cWeek.push(null)
        }

        let _cDate = new Date(_startDate.getTime())

        while(_cDate.getMonth() == _startDate.getMonth()) {
            if(_cWeek.length == 7) {
                _cWeek = [];
                _cMonth.weeks.push(_cWeek);
            }

            _cWeek.push({
                num: _cDate.getDate(),
                disabled: _cDate < this.nowDate
            })

            _cDate.setDate(_cDate.getDate() + 1);
        }

        while(_cWeek.length < 7) {
            _cWeek.push(null)
        }

        if(!skipScroll) {
            this._scrollToMonth(this.months.length - 1);
        }
    }

    getFormattedValue() {
        if(this.value == null || this.value == '') {
            return this.localizationService.translate('pg-date-interval.no-selection');
        }
        else {
            let _cObj = JSON.parse(this.value);

            return this.localizationService.format.date(_cObj.from) + ' - ' + this.localizationService.format.date(_cObj.to)
        }
    }

    getMonthName(month:number) {
        return this.localizationService.data.monthNames[month - 1]
    }

    private _formatYMD(year:number, month:number, day:number) {
        let _retVal = year.toString() + '-';

        let _cMonth = month.toString();
        if(_cMonth.length == 1) _retVal += '0';
        _retVal += _cMonth + '-';

        let _cDay = day.toString();
        if(_cDay.length == 1) _retVal += '0';
        _retVal += _cDay;

        return _retVal;
    }

    private _formatDate(date:Date) {
        return this._formatYMD(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    private _setValueFromInterval() {
        this.value = null;

        if(this.from != null && this.from != '' && this.to != null && this.to) {
            this.value = JSON.stringify({ from: this.from, to: this.to });
        }
    }

    private _setIntervalFromValue() {
        this.from = null;
        this.to = null;

        if(this.value != null) {
            let _cObj = JSON.parse(this.value);

            this.from = _cObj.from;
            this.to = _cObj.to;
        }
    }

    onDayClick(year:number, month:number, day:number) {
        let _dayVal = this._formatYMD(year, month, day);

        if(this.from == null || this.to == null || this.from != this.to) {
            this.from = _dayVal;
            this.to = this.from;
        }
        else {
            if(_dayVal == this.from && _dayVal == this.to) {
                this.from = null;
                this.to = null;
            }
            else if(_dayVal > this.from) {
                this.to = _dayVal;
            }
            else if(_dayVal < this.from) {
                this.from = _dayVal;
            }
        }

        this._refreshSelectedDays();

        if(this._onTouched) this._onTouched()
    }

    clearSelection() {
        this.from = null;
        this.to = null;

        this._refreshSelectedDays();

        if(this._onTouched) this._onTouched()

        this.closeModal();
    }

    private _refreshSelectedDays() {
        for(let _cMonth of this.months) {
            for(let _cWeek of _cMonth.weeks) {
                for(let _cDay of _cWeek) {
                    if(_cDay != null) {
                        let _cDayVal = this._formatYMD(_cMonth.year, _cMonth.num, _cDay.num);
                        _cDay.highlighted = _cDayVal > this.from && _cDayVal < this.to;
                        _cDay.selected = _cDayVal == this.from || _cDayVal == this.to;
                    }
                }
            }   
        }
    }

    @ViewChild('modalPicker', { static: true }) modalPicker;

    private _modalRef:NgbModalRef = null;

    openModal() { 
        this._setIntervalFromValue();

        this.nowDate = new Date();

        this.months = [];

        let _fromMonthIndex = 0;
        let _toMonthIndex = 0;

        if(this.from != null && this.to != null) {
            let _fromDate = new Date(this.from)
            let _toDate = new Date(this.to)

            let _cDate = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1);

            while(_cDate < _toDate) {
                _toMonthIndex++;

                _cDate.setMonth(_cDate.getMonth() + 1)

                if(_cDate < _fromDate) {
                    _fromMonthIndex++;
                }
            }
        }

        for(let i = 0; i < _toMonthIndex || i < 3; i++) {
            this.addMonth(true);
        }

        this._refreshSelectedDays();

        this._modalRef = this.modalService.open(this.modalPicker, { windowClass: 'PGDateIntervalModal' })

        this._modalRef.result.then(() => {
            this._modalRef = null;

            let _oldValue = this.value;
            this._setValueFromInterval();

            if(_oldValue != this.value) {
                if(this._onChange) this._onChange(this.value)
            }
        }, () => {
            this._modalRef = null;
        });
        
        this._scrollToMonth(_fromMonthIndex == 0 ? null : _fromMonthIndex);

        if(this._onTouched) this._onTouched()
    }

    dismissModal() {
        this._modalRef.dismiss();
    }

    closeModal() {
        this._modalRef.close();
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        this.value = obj;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
    };
}
