<div class="PGUserWidget">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <div *ngIf="!isLoading && userData != null">
        <div><b>{{ 'pg-user-widget.user' | locTranslate }}</b> {{userData.id}}{{userData.name != null && userData.name != '' ? ' - ' + userData.name : ''}}</div>
        <div *ngIf="userData.email != null && userData.email != ''">
            <a target="_blank" href="mailto:{{userData.email}}">{{userData.email}}</a>
        </div>
        <div *ngIf="userData.telephone != null && userData.telephone != ''">
            <a target="_blank" href="tel:{{userData.telephone}}">{{userData.telephone}}</a>
        </div>
    </div>
</div>
