<div class="PGImport container-fluid">
    <h3>{{ 'pg-import.title' | locTranslate }}</h3>
    <app-pg-loading *ngIf="isLoading" [isOverlay]="true" [isLarge]="true" [progressCurrent]="loadCurrent" [progressTotal]="loadtotal"></app-pg-loading>
    <div *ngIf="authToken == null" class="row">
        <ng-container *ngIf="loginOtpSendStatus != 'success'">
            <div class="col-12 form-group">
                <label><b>{{ 'pg-import.api-url' | locTranslate }}</b></label>
                <input *ngIf="APIUrlOptions == null" class="form-control" [(ngModel)]="APIUrl"/>
                <ng-container *ngIf="APIUrlOptions != null">
                    <app-pg-suggest *ngIf="adminMode" [options]="APIUrlOptions" [(ngModel)]="APIUrl" [required]="true"></app-pg-suggest>
                    <app-pg-select *ngIf="!adminMode" [options]="APIUrlOptions" [(ngModel)]="APIUrl" [required]="true"></app-pg-select>
                </ng-container>
            </div>
            <div *ngIf="!adminMode" class="col-12 form-group">
                <label><b>{{ 'pg-import.login-type' | locTranslate }}</b></label>
                <app-pg-select required [options]="[{ value: 'otp', text: 'pg-import.login-type-otp' | locTranslate }, { value: 'default', text: 'pg-import.login-type-default' | locTranslate }]" [(ngModel)]="loginMode"></app-pg-select>
            </div>
            <ng-container *ngIf="loginMode == 'default'">
                <div class="col-12 form-group">
                    <label><b>{{ 'pg-import.login-username' | locTranslate }}</b></label>
                    <input class="form-control" [(ngModel)]="loginDefaultUsername" (keyup)="onLoginKeyup($event)"/>
                </div>
                <div class="col-12 form-group">
                    <label><b>{{ 'pg-import.login-password' | locTranslate }}</b></label>
                    <input class="form-control" type="password" [(ngModel)]="loginDefaultPassword" (keyup)="onLoginKeyup($event)"/>
                </div>
                <div *ngIf="loginStatus == 'error'" class="col-12 form-group">
                    <div class="form-text text-danger">{{ 'pg-import.login-failed' | locTranslate }}</div>
                </div>
                <div class="col-12 form-group">
                    <button class="btn btn-primary" (click)="doLogin()">{{ 'pg-import.login' | locTranslate }}</button>
                </div>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="loginMode == 'otp'">
            <ng-container *ngIf="loginOtpSendStatus != 'success'">
                <div class="col-12 form-group">
                    <label><b>{{ 'pg-import.login-contact' | locTranslate }}</b></label>
                    <app-pg-recipient [(ngModel)]="loginOtpContact" (viaChange)="loginOtpVia = $event"></app-pg-recipient>
                </div>
                <div *ngIf="loginOtpSendStatus == 'error'" class="col-12 form-group">
                    <div class="form-text text-danger">{{ 'pg-import.login-otp-error' | locTranslate }}</div>
                </div>
                <div class="col-12 form-group">
                    <button class="btn btn-primary" [disabled]="loginOtpVia == null" (click)="requestOTP()">{{ 'pg-import.login-otp-request' | locTranslate }}</button>
                </div>
            </ng-container>
            <ng-container *ngIf="loginOtpSendStatus == 'success'">
                <div class="col-12">
                    <div [innerHTML]="'pg-import.login-otp-status-api' | locTranslate:{ api: APIUrl }"></div>
                    <div [innerHTML]="'pg-import.login-otp-status-send' | locTranslate:{ via: loginOtpVia == 'email' ? 'email' : 'SMS', to: loginOtpContact }"></div>
                    <button class="btn btn-link btn-sm p-0" (click)="loginOtpSendStatus = null; loginStatus = null;">{{ 'pg-import.edit' | locTranslate }}</button>
                    <hr/>
                </div>
                <div class="col-12 form-group">
                    <label><b>{{ 'pg-import.login-otp-code' | locTranslate }}</b></label>
                    <input class="form-control" [(ngModel)]="loginOtpCode" (keyup)="onLoginKeyup($event)"/>
                </div>
                <div *ngIf="loginStatus == 'error'" class="col-12 form-group">
                    <div class="form-text text-danger">{{ 'pg-import.login-failed' | locTranslate }}</div>
                </div>
                <div class="col-12 form-group">
                    <button class="btn btn-primary" (click)="doLogin()">{{ 'pg-import.login' | locTranslate }}</button>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="authToken != null" class="row">
        <div class="col-12">
            <div [innerHTML]="'pg-import.login-status' | locTranslate:{ api: APIUrl, user: loginMode == 'default' ? loginDefaultUsername : loginOtpContact }"></div>
            <button class="btn btn-link btn-sm p-0" (click)="elementData = null; resourceData = null; authToken = null; loginOtpSendStatus = null; loginStatus = null;">{{ 'pg-import.logout' | locTranslate }}</button>
            <hr/>
        </div>
        <ng-container *ngIf="resourceData == null">
            <div class="col-12 form-group">
                <label><b>{{ 'pg-import.select-resource' | locTranslate }}</b></label>
                <app-pg-select *ngIf="!adminMode" [options]="resourceOptions" [(ngModel)]="resourceId" [display]="{ oneLine: true }" [required]="true"></app-pg-select>
                <app-pg-suggest *ngIf="adminMode" [options]="resourceOptions" [(ngModel)]="resourceId" [required]="true"></app-pg-suggest>
            </div>
            <div class="col-12 form-group">
                <button class="btn btn-primary" [disabled]="resourceId == null || resourceId == ''" (click)="loadResource()">{{ 'pg-import.select' | locTranslate }}</button>
            </div>
        </ng-container>
        <ng-container *ngIf="resourceData != null">
            <div class="col-12">
                <div [innerHTML]="'pg-import.resource-status' | locTranslate:{ resource: resourceId }"></div>
                <button class="btn btn-link btn-sm p-0" (click)="elementData = null; resourceData = null;">{{ 'pg-import.edit' | locTranslate }}</button>
                <hr/>
            </div>
            <div class="col-12 form-group">
                <label class="mb-3"><b>{{ 'pg-import.select-element' | locTranslate }}</b></label>
                <app-pg-select *ngIf="!adminMode" [options]="resourceData" [ngModel]="elementIdList[0]" (ngModelChange)="elementIdList = [$event]" [required]="true"></app-pg-select>
                <div *ngIf="adminMode" class="PGImport-Elements">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>
                                    <input type="checkbox" class="PGImport-SelectAll {{isAllElementsSelected() || isNoElementSelected() ? '' : 'PGImport-SelectAll--Partial'}}" [ngModel]="isSomeElementsSelected()" (ngModelChange)="setAllElementsSelected($event)">
                                </th>
                                <th *ngFor="let column of resourceColumns; let i = index" class="{{isResourceKeyColumn(column) ? 'bg-light text-primary' : ''}}">
                                    <div>
                                        {{column}}
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let option of resourceData">
                                <td>
                                    <input type="checkbox" [ngModel]="isElementSelected(option.value)" (ngModelChange)="setElementSelected(option.value, $event)">
                                </td>
                                <td *ngFor="let column of resourceColumns; let i = index" class="{{isResourceKeyColumn(column) ? 'bg-light text-primary' : ''}}">
                                    <div>
                                        {{option.data[column]}}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-12 form-group text-end">
                <ng-container *ngIf="adminMode">
                    <app-pg-confirm-action (confirmed)="importElements(true, true)">
                        <button class="btn btn-danger me-1">{{ 'pg-import.clear-import-all' | locTranslate }}</button>
                    </app-pg-confirm-action>
                    <app-pg-confirm-action (confirmed)="importElements(true)">
                        <button class="btn btn-primary me-1">{{ 'pg-import.import-all' | locTranslate }}</button>
                    </app-pg-confirm-action>
                </ng-container>
                <button class="btn btn-primary" [disabled]="elementIdList[0] == null" (click)="importElements()">{{ 'pg-import.import-selected' | locTranslate }}</button>
            </div>
        </ng-container>
    </div>
</div>
