import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PGBookingData, PGProductData } from '../../../models/booking.model';

@Component({
  selector: 'app-pg-booking',
  templateUrl: './pg-booking.component.html',
  styleUrls: ['./pg-booking.component.scss']
})
export class PgBookingComponent implements OnInit, OnChanges {

    constructor() { }

    @Input() bookingData:PGBookingData;

    productData:PGProductData = null;

    ngOnInit() {}

    ngOnChanges() {
        this.productData = null;
        if(this.bookingData != null) {
            this.productData = new PGProductData(this.bookingData.experience_data, this.bookingData.experience_type)
        }
    }
}
