<div class="modal-header">
    <h5 class="modal-title">Scan the QR code</h5>
</div>
<div class="modal-body">
    <p class="text-center">Scan the QR code with your phone to visit the link</p>
    <div>
        <app-pg-qrcode [text]="targetURL"></app-pg-qrcode>
    </div>
    <div class="text-center mt-3">
        <button class="btn btn-primary" (click)="closeModal()">Close</button>
    </div>
</div>