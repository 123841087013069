import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SoundService {

    enabled = false;
    enabledChange = new Subject<boolean>();

    private _mute = false;

    get mute():boolean {
        return this._mute;
    }

    set mute(val:boolean) {
        this._mute = val;
        this._updateElementsVolume()
    }

    private _volume = 1;

    get volume():number {
        return this._volume;
    }
    
    set volume(val:number) {
        this._volume = Math.max(0, Math.min(1, val));
        this._updateElementsVolume()
    }

    audioElements: Array<HTMLAudioElement> = [];

    private _updateElementsVolume() {
        for(let _audio of this.audioElements) {
            if(this._mute) {
                _audio.volume = 0
            }
            else {
                _audio.volume = this._volume
            }
        }
    }

    constructor() {
        let _enableAudio = () => {
            this.enabled = true;
            this.enabledChange.next(this.enabled)
            document.body.removeEventListener('click', _enableAudio)
        }

        document.body.addEventListener('click', _enableAudio)
    }

    add(file:string) {
        let _audio = document.createElement('audio');

        _audio.innerHTML =
            '<source src="assets/sounds/' + file + '.ogg" type="audio/ogg" />' +
            '<source src="assets/sounds/' + file + '.mp3" type="audio/mpeg" />';

        document.body.appendChild(_audio);

        this.audioElements.push(_audio);

        if(this._mute) _audio.volume = 0;
        else _audio.volume = this._volume;

        return _audio;
    }

    remove(audio:HTMLAudioElement) {
        let _index = this.audioElements.indexOf(audio);

        if(_index != -1) {
            this.audioElements.splice(_index, 1);
            document.body.removeChild(audio);
        }
    }
}
