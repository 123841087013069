<div class="PGSemanticViewCard">
    <div class="card">
        <div *ngIf="isLoading" class="PGSemanticViewCard-Loading">
            <app-pg-loading></app-pg-loading>
        </div>
        <ng-container *ngIf="!isLoading">
            <div *ngIf="roles.image != null && roles.image != ''" class="card-img-top bg-light" [appPgCenterImage]="roles.image"></div>
            <div *ngIf="roles.image == null || roles.image == ''" class="card-img-top bg-light text-white d-flex justify-content-center align-items-center">
                <i class="fa-regular fa-image fa-8x"></i>
            </div>
            <div class="card-body">
                <h5 class="card-title">{{roles.title}}</h5>
                <div class="PGSemanticViewCard-Content mb-2">
                    <h6 *ngIf="hasProperty(roles.abstract)" class="card-subtitle" [innerHTML]="roles.abstract"></h6>
                    <div *ngIf="hasProperty(roles.description)" class="card-text text-muted" [innerHTML]="roles.description"></div>
                </div>

                <div *ngIf="hasProperty(roles.location)" class="PGSemanticViewCard-Property mb-2">
                    <div>
                        <i class="text-muted fa fa-map-marker"></i> Luogo
                    </div>
                    <div>{{roles.location}}</div>
                </div>
                <div *ngIf="hasProperty(roles.price)" class="PGSemanticViewCard-Property">
                    <div>
                        <i class="text-muted fa fa-tag"></i> Prezzo
                    </div>
                    <div>
                        <span [ngClass]="{ 'text-success': discountVal != null && discountVal != 0 }">{{formatPrice(roles.price, true)}}</span>
                        <span *ngIf="discountVal != null && discountVal != 0" class="PGSemanticViewCard-FullPrice">{{formatPrice(roles.price)}}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="showActions || customActions" class="card-footer text-center">
                <ng-container *ngIf="showActions">
                    <button *ngIf="actions.book" class="btn btn-primary" routerLink="/main/book/{{resourceId}}/{{elementId}}">Prenota</button>
                </ng-container>
                <ng-container *ngIf="customActions">
                    <button *ngFor="let action of customActions" class="btn btn-primary" (click)="action.action()">
                        <i class="{{action.icon}}"></i>
                        <span>{{action.label}}</span>
                    </button>
                </ng-container>
            </div>
        </ng-container>
      </div>
    <!--
    <div *ngIf="!isLoading" class="card PGSemanticViewCard-Content">
        <div class="row PGSemanticViewCard-Content-Head">
            <div class="col">
                <h3>{{roles.title}}</h3>
                <h5 [innerHTML]="roles.abstract"></h5>
            </div>
        </div>
        <div class="row PGSemanticViewCard-Content-Body">
            <div *ngIf="roles.image != null &amp;&amp; roles.image != ''" class="col-4 PGSemanticViewCard-Content-Image">
                <img [src]="getImage(roles.image)"/>
            </div>
            <div class="col" [innerHTML]="roles.description"></div>
        </div>
        <div class="row PGSemanticViewCard-Content-Footer">
            <div *ngIf="roles.geoloc != null &amp;&amp; roles.geoloc != ''" class="col">
                <a target="_blank" href="https://www.google.com/maps/?q={{encodeURIComponent(roles.geoloc)}}">
                    <i class="fa-regular fa-map-marker text-success"></i> <span [innerHTML]="roles.location"></span>
                </a>
            </div>
            <div *ngIf="(roles.start != null &amp;&amp; roles.start != '') || (roles.end != null &amp;&amp; roles.end != '')" class="col">
                <i class="fa-regular fa-calendar text-muted"></i>
                <span *ngIf="roles.start != null &amp;&amp; roles.start != ''">
                    dal <span [innerHTML]="localizationService.format.date(roles.start)"></span>
                </span>
                <span *ngIf="roles.end != null &amp;&amp; roles.end != ''">
                    al <span [innerHTML]="localizationService.format.date(roles.end)"></span>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="!isLoading &amp;&amp; showActions" class="PGSemanticViewCard-Actions">
        <button *ngIf="actions.book" class="btn btn-primary" routerLink="/main/book/{{resourceId}}/{{elementId}}">Prenota</button>
    </div>
    -->
</div>
