import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EFItineraryContent } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';
import { EFDataService } from '../../../services/ef-data.service';

@Component({
  selector: 'app-ef-itinerary-stop-card',
  templateUrl: './ef-itinerary-stop-card.component.html',
  styleUrls: ['./ef-itinerary-stop-card.component.scss']
})
export class EfItineraryStopCardComponent implements OnInit {

    constructor(private localizationService:LocalizationService, private mapStylesService:MapStylesService, private dataService:EFDataService) { }

    @Input() stopData:EFItineraryContent;
    @Input() disabled:boolean;
    @Input() full:boolean;

    @Output() showStop = new EventEmitter<void>();

    ngOnInit(): void {
    }

    getTranslatedProperty(prop:string) {
        return this.localizationService.getTranslatedValue(this.stopData[prop])
    }

    private _getStopProductType() {
        let _resource = this.stopData.resource;

        if(_resource == 'External') _resource = 'Poi';
        return this.dataService.getProductTypeFromResource(_resource)
    }

    getTypeColor() {
        let _type = this._getStopProductType();

        if(this.mapStylesService.markerStyles[_type] != null) {
            return this.mapStylesService.markerStyles[_type].icon.fillColor;
        }
    }
    
    getTypeIcon() {
        let _type = this._getStopProductType();

        if(this.mapStylesService.markerStyles[_type] != null) {
            return this.mapStylesService.markerStyles[_type].label.text;
        }
    }

    getTypeText() {
        let _type = this._getStopProductType();

        return this.localizationService.translate('experience-finder.general.product-type-' + _type)
    }

    getCoverImage() {
        if(this.stopData.image != null && this.stopData.image != '') return this.stopData.image;
    }

    onCardClick() {
        this.showStop.emit()
    }
}
