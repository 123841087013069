import { PGUtilities } from "../pg-utilities";

export class SurveyDataQuestion {
    text:string|{ [lang:string]: string };
    type:'boolean'|'string';
}

export class SurveyData {
    
    questions:Array<SurveyDataQuestion> =[];

    constructor(fromData?:any) {
        if(fromData != null) {
            let _cObj = fromData;

            if(typeof fromData == 'string') {
                _cObj = PGUtilities.tryParseJSON(fromData);
            }

            for(let i in _cObj) {
                this[i] = _cObj[i];
            }
        }
    }

    toData() {
        return JSON.stringify({
            questions: this.questions
        })
    }
}