<div class="PGRoleViewSimpleCard" #simpleCard>
    <div class="PGRoleViewSimpleCard-Content">
        <app-pg-loading *ngIf="isLoading"></app-pg-loading>
        <ng-container *ngIf="!isLoading">
            <!--<div *ngIf="roles.image != null &amp;&amp; roles.image != ''" class="PGRoleViewSimpleCard-Content-Image" [appPgCenterImage]="roles.image"></div>-->
            <div class="PGRoleViewSimpleCard-Content-Text">
                <div class="PGRoleViewSimpleCard-Content-Text-Title">{{roles.title}}</div>
                <div *ngIf="roles.abstract != null &amp;&amp; roles.abstract != ''" class="PGRoleViewSimpleCard-Content-Text-Abstract text-muted">{{roles.abstract}}</div>
            </div>
        </ng-container>
    </div>
</div>