import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LocalizationService } from '../services/localization.service';

export class SaveStatusRequest {
    constructor(public mode:'edit'|'create', public label:string, public onAction:(action:string) => void|Promise<void>, public topic?:string, public actions?:{ [name:string]: boolean }) {
        if(actions == null) {
            this.actions = { save: true }
        }
    }

    status:'running'|'success'|'error' = null;

    hidden = false;
    disabled = false;

    changed = false;

    private _statusTimeout = null;

    runAction(name:string) {
        if(!this.hidden && !this.disabled && this.status != 'running') {
            let _actionResult = this.onAction(name);

            if(typeof _actionResult != 'undefined') { // TODO: se ci saranno azioni multiple bisognerà tracciale quale è running per gestire il singolo bottone
                clearTimeout(this._statusTimeout)
                this.status = 'running';

                _actionResult.then(() => {
                    this.status = 'success';
                    this._statusTimeout = setTimeout(() => {
                        this.status = null;
                    }, 2000)

                    if(name == 'save' || name == 'delete') {
                        this.changed = false;
                    }
                }, () => {
                    this.status = 'error';
                    this._statusTimeout = setTimeout(() => {
                        this.status = null;
                    }, 2000)
                })
            }
            else {
                if(name == 'save' || name == 'delete') {
                    this.changed = false;
                }
            }
        }
    }
}

@Injectable({
  providedIn: 'root'
})
export class SaveStatusService {

    constructor() {
        window.addEventListener('beforeunload', (e) => {
            if(this.hasChangedRequests()) {
                e.preventDefault();
                e.returnValue = '';
            }
        });
    }

    enabled = false;

    private _requestList:Array<SaveStatusRequest> = []

    createRequest(mode:'edit'|'create', label:string, onAction:(action:string) => void|Promise<void>, topic?:string, actions?:{ [name:string]: boolean }) {
        if(!this.isActive()) {
            console.error('SaveStatusService not active!')
            throw null;
        }
        else {
            let _request = new SaveStatusRequest(mode, label, onAction, topic, actions);

            if(topic != null) {
                this.clearTopic(topic);
            }

            this._requestList.push(_request);

            return _request;
        }
    }

    removeRequest(request:SaveStatusRequest) {
        let _index = this._requestList.indexOf(request);

        if(_index != -1) {
            this._requestList.splice(_index, 1);
        }
    }

    clearTopic(topic:string) {
        for(let i = this._requestList.length - 1; i >= 0; i--) {
            if(this._requestList[i].topic == topic) {
                this._requestList.splice(i, 1);
            }
        }
    }

    hasChangedRequests() {
        for(let _request of this._requestList) {
            if(_request.changed) return true;
        }
    }

    private _listenerCount = 0;

    isActive() {
        return this._listenerCount > 0;
    }

    addListener() {
        this._listenerCount++;
        return this._requestList;
    }

    removeListener() {
        this._listenerCount--;
    }
}

@Injectable({
    providedIn: 'root'
})
export class SaveStatusCanDeactivateGuard implements CanDeactivate<any> {
    constructor(private saveStatusService:SaveStatusService, private localizationService:LocalizationService) {}

    canDeactivate() {
        if(!this.saveStatusService.hasChangedRequests()) {
            return true;
        }
        else {
            if(confirm(this.localizationService.translate('save-status-service.changed-requests'))) {
                return true;
            }
            else return false;
        }
    }
}
