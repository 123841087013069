import { Observable } from 'rxjs';
import { OnInit, Input, Directive } from '@angular/core';
import { CachedDataElements } from '../models/cached-data';
import { ConfigProfileRoleActionsExtended, ConfigService } from '../services/config.service';
import { ConfigSemanticsRelations, SemanticsService } from '../services/semantics.service';
import { ConfigResourceSemantics } from '../models/config.resources.model';
import { DataService } from '../services/data.service';

@Directive()
export class PgSemanticView implements OnInit {
    @Input() roles:ConfigResourceSemantics = null;

    @Input() resourceId:string = null;
    @Input() elementId:string = null;

    @Input() elementData:any = null;

    @Input() useCache:CachedDataElements = null;

    actions:ConfigProfileRoleActionsExtended = null;
    isLoading = false;

    private usedRelations:ConfigSemanticsRelations = null;

    afterInit() {
        return new Observable<any>((observer) => {
            observer.next();
            observer.unsubscribe();
        })
    }

    constructor(protected dataService:DataService, protected configService:ConfigService, protected semanticsService:SemanticsService) { }

    ngOnInit() {
        this.actions = this.configService.getResourceActionsExtended(this.resourceId);

        if(this.roles == null && this.actions.view) {
            this.usedRelations = this.semanticsService.getResourceSemanticsUsedRelations(this.resourceId);

            this.isLoading = true;

            if(this.elementData != null) {
                this.elementId = this.elementData.id;

                this.handleData().subscribe(() => {
                    this.afterInit().subscribe(() => { 
                        this.isLoading = false;
                    })
                });
            }
            else {
                this.loadData().subscribe(() => {
                    this.handleData().subscribe(() => {
                        this.afterInit().subscribe(() => { 
                            this.isLoading = false;
                        });
                    });
                });
            }
        }
    }

    private loadData() {
        return new Observable<any>((observer) => {
            this.isLoading = true;


            this.dataService.getElementData(this.resourceId, this.elementId, this.configService.getWithListFromSemanticsRelations(this.usedRelations)).subscribe(data => {
                this.elementData = data;

                observer.next();
                observer.unsubscribe();
            })
        })
    }

    private getRelatedElementData(resourceId: string, elementId: string) {
        return new Observable<any>((observer) => {
            if(this.useCache != null) {
                this.useCache.getElementData(resourceId, elementId).subscribe((data) => {
                    observer.next(data);
                    observer.unsubscribe();
                })
            }
            else {
                this.dataService.getElementData(resourceId, elementId).subscribe((data) => {
                    observer.next(data);
                    observer.unsubscribe();
                })
            }
        })

    }

    private handleData() {
        return new Observable<any>((observer) => {
            let _relationsRequests = 0;

            for(let _cRelation of this.usedRelations.xTo1) {
                _relationsRequests++;
    
                this.getRelatedElementData(_cRelation.modelB, this.elementData[_cRelation.joinField]).subscribe(relData => {
                    this.elementData['related_' + _cRelation.joinField] = relData;
    
                    _relationsRequests--;
    
                    if(_relationsRequests == 0) {    
                        this.evaluateResourceRoles();

                        observer.next();
                        observer.unsubscribe();
                    }
                })
            }
    
            if(_relationsRequests == 0) {
                this.evaluateResourceRoles();

                observer.next();
                observer.unsubscribe();
            }
        })
    }

    private evaluateResourceRoles() {
        this.roles = this.semanticsService.evaluateResourceSemantics(this.resourceId, this.elementData);
    }
}
