import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PGSurveyAnswers } from '../../../models/booking.model';
import { SurveyData } from '../../../models/survey.model';
import { LocalizationService } from '../../../services/localization.service';

@Component({
  selector: 'app-pg-survey-answers',
  templateUrl: './pg-survey-answers.component.html',
  styleUrls: ['./pg-survey-answers.component.scss']
})
export class PgSurveyAnswersComponent implements OnInit {

    answers:PGSurveyAnswers = null;

    randomId = Math.random()

    @Input() surveyData:SurveyData;
    @Output() surveyAnswered = new EventEmitter<PGSurveyAnswers>();

    constructor(private localizationService:LocalizationService) { }

    ngOnInit(): void {
        this.answers = new PGSurveyAnswers(this.surveyData);
    }

    formatQuestionText(text:string|{ [lang:string]: string }) {
        return this.localizationService.getTranslatedValue(text);
    }
}
