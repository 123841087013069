import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PgFormField, PgFormLayout } from '../../models/form.model';
import { ConfigFormLayoutGroupField } from '../../models/config.resources.model';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-detail',
  templateUrl: './pg-detail.component.html',
  styleUrls: ['./pg-detail.component.scss']
})
export class PgDetailComponent implements OnInit, OnChanges {
    @Input() formLayout:PgFormLayout = null;

    @Input() formValues:any = null;

    @Input() hideEmpty:boolean;
    @Input() currentLanguage:boolean;

    cleanFormValues:any = null;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges() {
        this.cleanFormValues = {};

        if(this.formValues != null) {
            this.cleanFormValues = JSON.parse(JSON.stringify(this.formValues))
        }
        else {
            for(let _field of this.formLayout.fieldList) {
                this.cleanFormValues[_field.name] = _field.value
            }
        }

        console.log(this.cleanFormValues)

        let _languages:Array<string> = PGUtilities.tryParseJSON(this.cleanFormValues.languages)

        if(_languages != null) {
            for(let _field of this.formLayout.fieldList) {
                if(_field.locale) {
                    let _parsed = PGUtilities.tryParseJSON(this.cleanFormValues[_field.name])

                    if(_parsed != null) {
                        let _clean = {}

                        for(let i in _parsed) {
                            if(_languages.indexOf(i) != -1) {
                                _clean[i] = _parsed[i]
                            }
                        }

                        this.cleanFormValues[_field.name] = JSON.stringify(_clean);
                    }
                }
            }
        }
    }

    getValueForField(field:PgFormField) {
        return this.cleanFormValues[field.name]
    }

    isFieldVisible(field:ConfigFormLayoutGroupField) {
        let _cField = this.formLayout.getFieldByName(field.name)
        let _cValue = this.getValueForField(_cField);

        if(this.hideEmpty && (_cValue == null || _cValue == '' || _cValue == '[]')) {
            return false
        }
        else {
            return this.formLayout.isFieldVisible(field, this.cleanFormValues)
        }
    }
}
