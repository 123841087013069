import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import moment from 'moment';

@Component({
    selector: 'app-pg-datetime-picker',
    templateUrl: './pg-datetime-picker.component.html',
    styleUrls: ['./pg-datetime-picker.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgDatetimePickerComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgDatetimePickerComponent),
        multi: true,
    }]
})
export class PgDatetimePickerComponent implements ControlValueAccessor, Validator, OnInit {
    @Input() fieldId:string;
    
    @Input() readonly:boolean;
    @Input() required:boolean;

    value:string = null;

    constructor() { }

    ngOnInit(): void {
    }

    getFieldValueDateTime(part:'date'|'time') {
        if(this.value == null) {
            return null;
        }
        else {
            if(part == 'date') {
                return this.value.split(' ')[0];
            }
            else if(part == 'time') {
                return this.value.split(' ')[1];
            }
        }
    }

    setFieldValueDateTime(part:'date'|'time', value:string) {
        if(part == 'date') {
            if(this.value == null) {
                this.value = value + ' 00:00:00';
            }
            else {
                this.value = value + ' ' + this.value.split(' ')[1];
            }
        }
        else if(part == 'time') {
            if(this.value == null) {
                this.value = new Date().toISOString().split('T')[0] + ' ' + value;
            }
            else {
                this.value = this.value.split(' ')[0] + ' ' + value;
            }
        }

        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(moment(this.value).utc().toISOString());
    }

    setFieldValue(val:any) {
        this.value = val;

        if(this._onTouched != null) this._onTouched();
        if(this._onChange != null) this._onChange(moment(this.value).utc().toISOString());
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = moment(obj).local().format('YYYY-MM-DD HH:mm:ss');
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        return (!this.required || this.value != null)  ? null : {
            required: {
                valid: false
            }
        }
    };
}
