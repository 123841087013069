<div class="PGImageCrop">
    <div *ngIf="isLoading" class="PGImageCrop-Loading">
        <app-pg-loading></app-pg-loading>
    </div>
    <div class="PGImageCrop-Container" [style.opacity]="isLoading ? 0 : 1">
        <img-cropper #imageCropper [image]="imageData" [settings]="cropperSettings"></img-cropper>
        <div class="PGImageCrop-Actions">
            <button class="btn btn-secondary" (click)="cropCancel()">Annulla</button>
            <button class="btn btn-primary" (click)="cropSave()">Salva</button>
        </div>
    </div>
</div>