import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-accept-conditions',
  templateUrl: './pg-accept-conditions.component.html',
  styleUrls: ['./pg-accept-conditions.component.scss']
})
export class PgAcceptConditionsComponent implements OnInit {

    @Input() conditionList:Array<{ title: string, text: string, accepted?:boolean, optional?:boolean }>;

    constructor(public modal:NgbActiveModal) { }

    ngOnInit(): void {
    }

    allRequiredAccepted() {
        for(let _condition of this.conditionList) {
            if(!_condition.accepted && !_condition.optional) {
                return false;
            }
        }

        return true;
    }
}
