import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subject } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { PGUtilities } from '../pg-utilities';

export type Websocket2MessageType = 'register'|'unregister'|'request'|'message';

export class Websocket2Message { 
    type:Websocket2MessageType;
    service:string;
    id?:string;
    data?:any;
    error?:any;
}

export type Websocket2RegistrationStatus = 'offline'|'connected'|'registered'|'unauthorized';

export class Websocket2Registration {
    service:string = null;
    auth:any = null;

    status:Websocket2RegistrationStatus = 'offline';
    profile:any = null;
    error:any = null;
    
    statusChange = new Subject<Websocket2RegistrationStatus>();
    onMessage = new Subject<any>();

    private _sentRequests:{ [id:string]: { 
        resolve:(data:any) => void, 
        reject:(error:any) => void 
    } } = {};

    private _listeners:{ [id:string]: Function } = {};

    constructor(private socket:Socket, service:string, auth?:any) {
        this.service = service;
        this.auth = auth;

        this._listeners['connect'] = () => { this._handleConnect(); }
        this._listeners['disconnect'] = () => { this._handleDisconnect(); }
        this._listeners['message'] = (data) => { this._handleMessage(data); }

        this._addListeners();

        if(socket.ioSocket.connected) {
            this._handleConnect();
        }
    }

    private _addListeners() {
        for(let i in this._listeners) {
            this.socket.on(i, this._listeners[i]);
        }
    }

    private _removeListeners() {
        for(let i in this._listeners) {
            this.socket.removeListener(i, this._listeners[i]);
        }
    }

    private _handleMessage(message:Websocket2Message) {
        if(message.service == null || message.service == this.service) {
            console.log('WS2Reg <RCVD', this.service, message.type, message.data)

            if(message.type == 'register') {
                if(typeof message.error != 'undefined') {
                    this.status = 'unauthorized';
                    this.profile = null;
                    this.error = message.error;
                    this.statusChange.next(this.status);
                }
                else {
                    this.status = 'registered';
                    this.profile = message.data;
                    this.error = null;
                    this.statusChange.next(this.status);
                }
            }
            else if(message.type == 'request') {
                if(this._sentRequests[message.id] != null) {
                    let _cRequest = this._sentRequests[message.id];
                    delete this._sentRequests[message.id];
                    
                    if(typeof message.error != 'undefined') {
                        _cRequest.reject(message.error);
                    }
                    else {
                        _cRequest.resolve(message.data);
                    }
                }
            }
            else {
                this.onMessage.next(message.data);
            }
        }
    }

    private _handleConnect() {
        this.status = 'connected';
        this.profile = null;
        this.error = null;
        this.statusChange.next(this.status);

        if(typeof this.auth == 'function') {
            let _result = this.auth();

            if(_result instanceof Promise) {
                _result.then((data) => {
                    this._emit({ 
                        type: 'register',
                        data: data 
                    });
                })
            }
            else {
                this._emit({ 
                    type: 'register',
                    data: _result 
                });
            }
        }
        else {
            this._emit({ 
                type: 'register',
                data: this.auth 
            });
        }
    }

    private _handleDisconnect() {
        this.status = 'offline';
        this.profile = null;
        this.error = null;
        this.statusChange.next(this.status);

        for(let i in this._sentRequests) {
            let _cRequest = this._sentRequests[i];
            delete this._sentRequests[i];
            _cRequest.reject(null);
        }
    }

    send(data:any) {
        if(this.status == 'registered') {            
            this._emit({ 
                type: 'message',
                data: data 
            });
        }
    }

    request(data:any) {
        return new Promise<any>((resolve, reject) => {
            if(this.status != 'registered') {
                reject();
            }
            else {
                let _cId = PGUtilities.getRandomId();

                this._sentRequests[_cId] = {
                    resolve: resolve,
                    reject: reject
                }

                this._emit({ 
                    type: 'request',
                    id: _cId, 
                    data: data 
                });
            }
        })
    }

    unregister() {
        if(this.status == 'registered') {
            this._emit({ 
                type: 'unregister'
            });
        }

        this._removeListeners();
    }

    private _emit(data:any) {
        data.service = this.service
        
        this.socket.emit('message', data);
        console.log('WS2Reg SENT>', this.service,  data.type, data.data)
    }
}

@Injectable({
    providedIn: 'root'
})
export class Websocket2Service {

    connected:boolean = false;
    version:{ [service:string]: string } = null;

    connectedChange = new Subject<boolean>();

    constructor(public environmentService:EnvironmentService) {
    }

    register(service:string, auth?:any) {
        if(this.environmentService.environment.WebSocket != null) {
            let _socket = new Socket({ 
                url: this.environmentService.environment.WebSocket, 
                options: {} 
            })

            _socket.on('connect', () => { 
                this.connected = true; 
                this.connectedChange.next(this.connected);
                console.log('WS2 connect')
            });
    
            _socket.on('disconnect', () => { 
                this.connected = false; 
                this.connectedChange.next(this.connected);
                console.log('WS2 disconnect')
            });

            return new Websocket2Registration(_socket, service, auth);
        }
    }
}