<div class="PGFileList PGFileList--{{getLayoutMode()}} {{selectionMode != null ? 'PGFileList--Selectable' : ''}}">
    <app-pg-loading *ngIf="isRunningOperation" [isOverlay]="true" [progressRatio]="isRunningOperationProgress"></app-pg-loading>

    <div class="PGFileList-Buttons">
        <div *ngIf="hasViewModeSelection()" class="btn-group pb-3">
            <div *ngFor="let type of [null,'images','audios','videos','documents']" class="btn {{viewMode == type ? 'btn-primary' : 'btn-secondary' }}" (click)="setViewMode(type)">
                <i class="fa-regular {{getCategoryIcon(type)}}"></i>
                <span class="PGFileList-Buttons-Text">{{ 'pg-resource-view-file.type-' + (type || 'all') | locTranslate }}</span>
            </div>
        </div>
        <!-- TODO: QUA CI VANNO DRAFT? -->
        <div *ngIf="selectionMode == null && actions.create" class="pb-3">
            <button class="btn btn-primary" (click)="createFile()">
                <span>{{ 'pg-resource-view-file.upload-resource' | locTranslate:{ resource: resourceName} }}</span>
                <i class="fa-regular fa-upload"></i>
            </button>
        </div>
    </div>

    <div *ngIf="hasDirectoriesNavigation()" class="PGFileList-Navigation ps-2 ms-1">
        <div class="d-flex justify-content-between align-items-center mb-3">
            <nav>
                <ol class="breadcrumb align-items-center mb-0">
                    
                    <li *ngIf="getFilterSearch(); let search" class="breadcrumb-item">
                        <i class="fa-regular fa-search me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-search' | locTranslate }} "{{search}}"</span>
                    </li>

                    <li *ngIf="getFilterTags(); let tags" class="breadcrumb-item">
                        <i class="fa-regular fa-hashtag me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-tags' | locTranslate }} "{{tags}}"</span>
                    </li>

                    <li *ngIf="getFilterSystemTags(); let system_tags" class="breadcrumb-item">
                        <i class="fa-regular fa-hashtag-lock me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-system_tags' | locTranslate }} "{{getSystemTagsLabel(system_tags)}}"</span>
                    </li>

                    <li *ngIf="getFilterCategories(); let categories" class="breadcrumb-item">
                        <i class="fa-regular fa-list me-2"></i>
                        <span>{{ 'pg-resource-view-file.directory-categories' | locTranslate }} "{{getCategoriesLabel(categories)}}"</span>
                    </li>

                    <li *ngIf="hasSearchOrTagsOrCategories()" class="mx-2">
                        <app-pg-select [ngModel]="searchAllValue ? 'true' : 'false'" (ngModelChange)="setSearchAllValue($event)" [options]="searchAllOptions" [required]="true" [display]="{ oneLine: true }"></app-pg-select>
                    </li>
                    
                    <ng-container *ngIf="!hasSearchOrTagsOrCategories() || !searchAllValue">
                        <li class="breadcrumb-item">
                            /&nbsp;
                            <span *ngIf="currentDirectory == null">{{ 'pg-resource-view-file.directory-root' | locTranslate }}</span>
                            <a *ngIf="currentDirectory != null" href="javascript:void(0)" (click)="goToDirectory(null)">{{ 'pg-resource-view-file.directory-root' | locTranslate }}</a>
                        </li>
                        <li *ngFor="let directory of currentDirectoryPath" class="breadcrumb-item {{ directory == currentDirectory ? 'active' : '' }}">
                            <span *ngIf="directory == currentDirectory">{{directory.label}}</span>
                            <a *ngIf="directory != currentDirectory" href="javascript:void(0)" (click)="goToDirectory(directory)">{{directory.label}}</a>
                        </li>
                    </ng-container>
                </ol>
            </nav>
            
            <ng-container *ngIf="selectionMode == null && !hasSearchOrTagsOrCategories()">
                <div class="d-flex align-items-center">
                    <div *ngIf="currentDirectory != null">
                        <button class="btn btn-link" (click)="downloadDirectory()">
                            <span>{{ 'pg-resource-view-file.directory-download' | locTranslate }}</span><i class="fa-regular fa-download"></i>
                        </button>
                    </div>
                    <div class="ms-2">
                        <button class="btn btn-link" (click)="setShowCreateDirectory(true)">
                            <span>{{ 'pg-resource-view-file.directory-create' | locTranslate }}</span><i class="fa-regular fa-folder-plus"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>

        <div *ngIf="selectionMode == null && !hasSearchOrTagsOrCategories()" class="PGFileList-Navigation-Selection d-flex align-items-center justify-content-end mb-3">     
            <ng-container *ngIf="getEditSelectionCount()">
                <div class="ms-2">
                    <button class="btn btn-link" [disabled]="!editSelectionCanMoveHere()" (click)="editSelectionDoMoveHere()">
                        <span>{{ 'pg-resource-view-file.edit-selection-move-here' | locTranslate }}</span>
                        <i class="fa-regular fa-folder-arrow-down"></i>
                    </button>
                </div>

                <div class="ms-2 bg-white ps-2 rounded border-white d-flex align-items-center" [ngbTooltip]="editSelectionGetTooltip()">
                    <span class="ps-1">
                        <span *ngIf="editSelection.directories.length">{{ 'pg-resource-view-file.edit-selection-count-directories' | locTranslate:{ count: editSelection.directories.length } }}</span>
                        <span *ngIf="editSelection.directories.length && editSelection.files.length">, </span>
                        <span *ngIf="editSelection.files.length">{{ 'pg-resource-view-file.edit-selection-count-files' | locTranslate:{ count: editSelection.files.length } }}</span>
                    </span>
                    <button class="btn btn-link text-danger border-0" (click)="editSelectionReset()">
                        <i class="fa-regular fa-times"></i>
                    </button>
                </div>
            </ng-container>

            <div class="ms-2">
                <button class="btn btn-{{ editSelectionMode ? 'primary' : 'link' }}" (click)="editSelectionMode = !editSelectionMode">
                    <span>{{ 'pg-resource-view-file.edit-selection-toggle' | locTranslate }}</span>
                    <i class="fa-regular fa-square-check"></i>
                </button>
            </div>
        </div>
    </div>
    
    <div class="bg-white PGFileList-Main">
        <div *ngIf="hasDirectoriesNavigation()" class="border-bottom PGFileList-Directory container-fluid">
            <div class="row pb-3">
                <ng-container *ngIf="isLoadingDirectoryData"> 
                    <div *ngIf="currentDirectoryList == null" class="PGFileList-Directory-Loading mt-3">
                        <app-pg-loading [progressRatio]="isLoadingDirectoryDataProgress"></app-pg-loading>
                    </div>
    
                    <app-pg-loading *ngIf="currentDirectoryList != null" [isOverlay]="true" [progressRatio]="isLoadingDirectoryDataProgress"></app-pg-loading>
                </ng-container>

                <ng-container *ngIf="currentDirectoryList != null"> 
                    <div *ngIf="showCreateDirectory" class="PGFileList-Directory-Element col-12 col-lg-6 col-xl-4 pt-3">
                        <div class="d-flex align-items-center h-100">
                            <input #createDirectoryInput class="form-control form-control-sm" placeholder="{{ 'pg-resource-view-file.directory-create' | locTranslate }}" [(ngModel)]="createDirectoryLabel" (blur)="setShowCreateDirectory(false, true)">
                            <button class="btn btn-sm btn-primary ms-1" [disabled]="!canCreateDirectory()" (click)="createDirectory()">
                                <i class="fa-regular fa-plus"></i>
                            </button>
                        </div>
                    </div> 
    
                    <div *ngIf="!showCreateDirectory && currentDirectoryList.length == 0" class="PGFileList-Directory-NoDirectories col-12 pt-3 text-muted">
                        {{ 'pg-resource-view-file.directory-no-directories' | locTranslate }}
                    </div>
    
                    <div *ngFor="let directory of currentDirectoryList" class="PGFileList-Directory-Element col-12 col-lg-6 col-xl-4 pt-3">
                        
                        <div class="bg-light d-flex align-items-center {{ isEditSelected(directory) ? 'shadow-highlight' : ''}}">
                            <div *ngIf="editSelectionMode" class="ps-2">
                                <input type="checkbox" [ngModel]="isEditSelected(directory)" (ngModelChange)="setEditSelected(directory, $event)">
                            </div>
                            <div class="PGFileList-Directory-Element-Display" (click)="goToDirectory(directory)">
                                <i class="fa-regular fa-folder"></i>
                                <div class="PGFileList-Directory-Element-Display-Text {{currentRenameDirectory == directory ? 'PGFileList-Directory-Element-Display-Text--Renaming' : ''}}">
                                    <div class="PGFileList-Directory-Element-Display-Text-Name">
                                        <a *ngIf="currentRenameDirectory != directory" href="javascript:void(0)">{{directory.label}}</a>
                                        <div *ngIf="currentRenameDirectory == directory" class="pe-1">
                                            <input #renameDirectoryInput class="form-control form-control-sm" [(ngModel)]="currentRenameDirectoryLabel" (blur)="checkDoCurrentRenameDirectory()" (change)="checkDoCurrentRenameDirectory()">
                                        </div>
                                    </div>
                                    <!-- TODO: completare questa parte per visualizzare il path durante le ricerche 
                                    <div *ngIf="hasSearchOrTagsOrCategories()" class="PGFileList-Directory-Element-Display-Text-Path">
                                        <small class="text-muted">{{directory.pathLabels}}</small>
                                    </div>
                                    -->
                                </div>
                            </div>
                            <div>
                                <app-pg-resource-element-menu *ngIf="selectionMode == null" resourceId="Directory" [elementId]="directory.id" [elementData]="directory" [baseURL]="baseURL" (dataChange)="checkDirectoryPropagateChanges($event)"
                                    [customActions]="{ edit: { label: 'pg-resource-view-file.directory-rename', action: true } }" (customActionRun)="renameDirectory(directory)">
                                    <div afterEdit>
                                        <div class="dropdown-divider"></div>
                                        <div class="dropdown-item d-flex justify-content-between" (mousedown)="$event.stopPropagation()" (click)="directoryPropagateChanges = !directoryPropagateChanges;">
                                            <div>
                                                <i class="fa-regular fa-diagram-project"></i><span>{{ 'pg-resource-view-file.directory-propagate-changes' | locTranslate }}</span>
                                            </div>
                                            <div class="ps-2">
                                                <app-pg-switch [small]="true" color="danger" [ngModel]="directoryPropagateChanges"></app-pg-switch>
                                            </div>
                                        </div>
                                    </div>
                                </app-pg-resource-element-menu>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>   
        </div>

        <div class="PGFileList-Scrollable container-fluid">
            <div class="row pb-3">
                <div *ngIf="isAtEnd && dataRows.length == 0" class="PGFileList-Directory-NoFiles col-12 pt-3 text-muted">
                    {{ 'pg-resource-view-file.directory-no-files' | locTranslate }}
                </div>

                <div *ngFor="let file of dataRows" class="PGFileList-Element col-12 col-lg-6 col-xl-4 pt-3 pg-animation-slidedown {{file.progress != null ? 'PGFileList-Element--Uploading' : ''}}" [appPgAnimations]="file.deleted ? 'ExitT' : ''" (click)="toggleSelection(file)">
                    <div class="PGFileList-Element-Progress bg-light">
                        <app-pg-loading [progressVal]="file.progress" [hasError]="file.error"></app-pg-loading>
                    </div>
                    <div class="PGFileList-Element-Preview bg-light {{isSelected(file) || isEditSelected(file) ? 'shadow-highlight' : ''}} d-flex align-items-center">
                        <div *ngIf="editSelectionMode" class="ps-2">
                            <input type="checkbox" [ngModel]="isEditSelected(file)" (ngModelChange)="setEditSelected(file, $event)">
                        </div>
                        <div class="PGFileList-Element-Preview-Display">
                            <app-pg-file-preview [fileData]="file" [viewMode]="viewMode"></app-pg-file-preview>
                        </div>
                        <div class="PGFileList-Element-Preview-Options {{currentRenameFile == file ? 'PGFileList-Element-Preview-Options--Renaming' : ''}} {{ viewMode == null || viewMode == 'documents' ? 'PGFileList-Element-Preview-Options--HasIcon' : ''}}">
                            <div *ngIf="currentRenameFile == file" class="PGFileList-Element-Preview-Options-Rename pe-1">
                                <input #renameFileInput class="form-control form-control-sm" [(ngModel)]="currentRenameFileName" (blur)="checkDoCurrentRenameFile()" (change)="checkDoCurrentRenameFile()">
                            </div>
                            <div>                                
                                <app-pg-resource-element-menu *ngIf="selectionMode == null" [resourceId]="resourceId" [elementId]="file.id" [elementData]="file" [baseURL]="baseURL" (dataChange)="reset()">
                                    <div beforeEdit>
                                        <button *ngIf="actions.edit" class="dropdown-item btn" (click)="renameFile(file)">
                                            <i class="fa-regular fa-i-cursor"></i><span>{{ 'pg-resource-view-file.file-rename' | locTranslate }}</span>
                                        </button>
                                    </div>
                                    <div afterEdit>
                                        <button class="dropdown-item btn" (click)="downloadFile(file)">
                                            <i class="fa-regular fa-download"></i><span>{{ 'pg-resource-view-file.file-download' | locTranslate }}</span>
                                        </button>
                                    </div>
                                </app-pg-resource-element-menu>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="PGFileList-Footer">
                <app-pg-view-more [hidden]="isLoadingData || isAtEnd" (viewMore)="loadData()"></app-pg-view-more>
                <div *ngIf="isLoadingData" class="PGFileList-Footer-Loading pg-animation-fadein">
                    <app-pg-loading></app-pg-loading>
                </div>
            </div>
        </div>
    </div>
</div>