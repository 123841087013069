import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgDragDropModule } from 'ng-drag-drop';

import { PgSemanticViewModule } from '../pg-semantic-view/pg-semantic-view.module';

import { PgResourceViewHostDirective, PgResourceEmbedComponent } from './pg-resource-embed/pg-resource-embed.component';
import { PgResourceViewTableComponent } from './pg-resource-view-table/pg-resource-view-table.component';
import { PgResourceViewTableConfigComponent } from './pg-resource-view-table/config/pg-resource-view-table-config.component';
import { PgResourceViewCalendarComponent } from './pg-resource-view-calendar/pg-resource-view-calendar.component';
import { PgResourceViewCalendarConfigComponent } from './pg-resource-view-calendar/config/pg-resource-view-calendar-config.component';
import { PgResourceViewFilesComponent } from './pg-resource-view-files/pg-resource-view-files.component';
import { PgResourcePickerComponent } from './pg-resource-picker/pg-resource-picker.component';
import { PgResourceViewCardsComponent } from './pg-resource-view-cards/pg-resource-view-cards.component';
import { CommonModule } from '@angular/common';
import { PGViewResourceManager } from './resource-view-common';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';
import { PgUiMoleculesModule } from '../pg-ui-molecules/pg-ui-molecules.module';
import { PgFilePickerComponent } from './pg-file-picker/pg-file-picker.component';
import { PgHtmlEditorComponent } from './pg-html-editor/pg-html-editor.component';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
    declarations: [
        PgResourceEmbedComponent,
        PgResourceViewHostDirective,
        PgResourceViewTableComponent,
        PgResourceViewTableConfigComponent,
        PgResourceViewCalendarComponent,
        PgResourceViewCalendarConfigComponent,
        PgResourceViewFilesComponent,
        PgResourcePickerComponent,
        PgResourceViewCardsComponent,
        PgFilePickerComponent,
        PgHtmlEditorComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        NgbModule,
        NgDragDropModule,
        PgUiElementsModule,
        PgUiControlsModule,
        PgUiMoleculesModule,
        PgSemanticViewModule,
        EditorModule
    ],
    exports: [
        PgResourceEmbedComponent,
        PgResourceViewTableComponent,
        PgResourceViewTableConfigComponent,
        PgResourceViewCalendarComponent,
        PgResourceViewCalendarConfigComponent,
        PgResourceViewFilesComponent,
        PgResourcePickerComponent,
        PgResourceViewCardsComponent,
        PgFilePickerComponent,
        PgHtmlEditorComponent
    ],
    providers: [{
            provide: APP_INITIALIZER,
            useFactory: (PGViewResourceManager: PGViewResourceManager): Function => {
                return async () => {
                    PGViewResourceManager.register('Table', PgResourceViewTableComponent, PgResourceViewTableConfigComponent);
                    PGViewResourceManager.register('Files', PgResourceViewFilesComponent);
                    PGViewResourceManager.register('Cards', PgResourceViewCardsComponent);
                    PGViewResourceManager.register('Calendar', PgResourceViewCalendarComponent, PgResourceViewCalendarConfigComponent);
                };
            },
            deps: [PGViewResourceManager],
            multi: true
        },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'assets/tinymce/tinymce.min.js' }],
    bootstrap: []
})
export class PgResourceViewModule { }
