import { Directive, OnChanges, Input, ElementRef, OnInit, OnDestroy, NgZone } from '@angular/core';

@Directive({
  selector: '[appPgGrowNumber]'
})

export class PgGrowNumberDirective implements OnChanges, OnInit, OnDestroy {

    @Input() appPgGrowNumber:number;

    constructor(private el: ElementRef, private zone:NgZone) {
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        if(this._growInterval != null) {
            clearInterval(this._growInterval);
            this._growInterval = null;
        }
    }

    private _growInterval = null;
    private _growValue = 1;

    private _decimalsNum:number;

    ngOnChanges() {
        if(this.appPgGrowNumber == null) {
            this.el.nativeElement.innerHTML = '';
        }
        else {
            this.zone.runOutsideAngular(() => {
                this._growValue = 0;
                this._decimalsNum = this.appPgGrowNumber.toString().replace(/^[^\.]*/, '').replace(/^\./, '').length;
    
                this.el.nativeElement.innerHTML = 0;
                this.el.nativeElement.style.opacity = 0;
                this.el.nativeElement.style.transform = 'scale(0, 0)';
        
                this._growInterval = setInterval(() => {
                    this._growValue += 0.1
                    
                    if(this._growValue >= 1) {
                        this._growValue = 1;
                        clearInterval(this._growInterval);
                        this._growInterval = null;

                        this.el.nativeElement.innerHTML = this.appPgGrowNumber;
                        this.el.nativeElement.style.opacity = null;
                        this.el.nativeElement.style.transform = null;
                    }
                    else {
                        let _cEasedGrow = (1 - Math.pow(1 - this._growValue, 2));

                        this.el.nativeElement.innerHTML = (this.appPgGrowNumber * _cEasedGrow).toFixed(this._decimalsNum);  
                        this.el.nativeElement.style.opacity = _cEasedGrow;

                        let _cScale = 0.65 + 0.35 * _cEasedGrow;
                        this.el.nativeElement.style.transform = 'scale(' + _cScale + ', ' + _cScale + ')';
                    }    
                }, 35)
            })
        }
    }
}
