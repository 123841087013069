import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-pg-code-checker',
  templateUrl: './pg-code-checker.component.html',
  styleUrls: ['./pg-code-checker.component.scss']
})
export class PgCodeCheckerComponent implements OnInit {
    
    @Input() value:string;
    @Output() valueChange = new EventEmitter<string>();

    @Input() codeCheck:(code:string) => Observable<any>

    @Output() statusChange = new EventEmitter<'checking'|'valid'|'invalid'>();
    @Output() resultChange = new EventEmitter<any>();
    @Output() errorChange = new EventEmitter<any>();

    constructor() { }

    status:'checking'|'valid'|'invalid' = null;
    result:any = null;
    error:any = null;

    ngOnInit() {
        this.doCodeCheck();
    }

    private _doCodeCheckTimeout = null;
    private _doCodeCheckSubscription:Subscription = null;

    doCodeCheck() {
        if(this.result != null) {
            this.result = null;
            this.resultChange.emit(this.result);
        }

        if(this.error != null) {
            this.error = null;
            this.errorChange.emit(this.result);
        }

        if(this.value == null) {
            this.status = null;
            this.statusChange.emit(this.status);
        }
        else {
            this.status = 'checking';
            this.statusChange.emit(this.status);

            if(this._doCodeCheckTimeout != null) clearTimeout(this._doCodeCheckTimeout);
            if(this._doCodeCheckSubscription != null) this._doCodeCheckSubscription.unsubscribe();

            this._doCodeCheckTimeout = setTimeout(() => {
                this._doCodeCheckTimeout = null;
                
                this._doCodeCheckSubscription = this.codeCheck(this.value).subscribe((data) => {
                    if(data == null) {
                        this.status = 'invalid';
                        this.statusChange.emit(this.status);
                    }
                    else {
                        this.status = 'valid';
                        this.result = data;
    
                        this.statusChange.emit(this.status);
                        this.resultChange.emit(this.result);
                    }
                }, (error) => {
                    this.status = 'invalid';
                    this.statusChange.emit(this.status);
    
                    if(error != null) {
                        this.error = error;
                        this.errorChange.emit(this.error);
                    }
                })
            }, 250)
        }
    }

    getCodeStatusIcon() {
        switch(this.status) {
            case 'valid': return 'fa-regular fa-fw fa-check ';
            case 'invalid': return 'fa-regular fa-fw fa-times';
            case 'checking': return 'fa-regular fa-fw fa-sync-alt pg-animation-spin';
            default: return 'fa-regular fa-fw fa-certificate';
        }
    }

    getCodeStatusColor() {
        switch(this.status) {
            case 'valid': return 'success';
            case 'invalid': return 'danger';
            case 'checking': return 'secondary';
            default: return 'primary';
        }
    }
}
