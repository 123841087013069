import { Component, Input, AfterViewInit, ContentChildren, QueryList, AfterViewChecked } from '@angular/core';


@Component({
    selector: 'app-pg-carousel slide',
    template: '<div class="PGCarousel-Slide" [ngClass]="{ \'PGCarousel-Slide--Visible\': isVisible }"><ng-content></ng-content></div>',
    styleUrls: ['./pg-carousel.component.scss']
})

export class PgCarouselComponentSlide {
    @Input() isVisible:boolean;

    constructor() {}
}

@Component({
  selector: 'app-pg-carousel',
  templateUrl: './pg-carousel.component.html',
  styleUrls: ['./pg-carousel.component.scss']
})
export class PgCarouselComponent implements AfterViewInit {

    @ContentChildren(PgCarouselComponentSlide) carouselSlides: QueryList<PgCarouselComponentSlide>;

    @Input() fullScreen:boolean;

    constructor() { }

    currentSlide = 0;
    maxSlide = 0;

    isInitialized = false;

    ngAfterViewInit() {
        setTimeout(() => {
            this.isInitialized = true;
            this.setCurrentSlideVisible();
        }, 100)
    }

    goPrev() {
        this.setCurrentSlideVisible(this.currentSlide - 1);
    }

    goNext() {
        this.setCurrentSlideVisible(this.currentSlide + 1);
    }

    goTo(index:number) {
        this.setCurrentSlideVisible(index);
    }

    setCurrentSlideVisible(index?:number) {
        if(index == null || index != this.currentSlide) {

            this.maxSlide = this.carouselSlides.length - 1;
            
            if(index != null) {
                let _cMod = index % this.carouselSlides.length;
                if(_cMod < 0) _cMod += this.carouselSlides.length;
    
                this.currentSlide = _cMod;
            }

            this.carouselSlides.forEach((slide, i) => {
                slide.isVisible = i == this.currentSlide;
            })
        }
    }

}
