<div class="PGFilterEditorSearch container-fluid p-0">
    <div class="row">
        <div class="input-group col">
            <div class="input-group-text {{hasAppliedSearch() ? 'bg-primary text-white' : ''}}">
                <i class="fa-regular fa-search fa-fw"></i>
            </div>
            <input type="text" class="form-control" [(ngModel)]="status.search" placeholder="{{ 'pg-filter-editor.search' | locTranslate }}" (change)="applySearch.emit()"/>
        </div>
        <div *ngIf="hasTags" class="input-group col-12 mt-2">
            <div class="input-group-text {{hasAppliedTags() ? 'bg-primary text-white' : ''}}">
                <i class="fa-regular fa-hashtag fa-fw"></i>
            </div>
            <div class="PGFilterEditorSearch-Tags">
                <app-pg-tags [(ngModel)]="status.tags" placeholder="{{ 'pg-filter-editor.tags' | locTranslate }}" (change)="applySearch.emit()"></app-pg-tags>
            </div>
        </div>
        <div *ngIf="hasSystemTags" class="input-group col-12 mt-2">
            <div class="input-group-text {{hasAppliedSystemTags() ? 'bg-primary text-white' : ''}}">
                <i class="fa-regular fa-hashtag-lock fa-fw"></i>
            </div>
            <div class="PGFilterEditorSearch-Select">
                <app-pg-select [(ngModel)]="status.system_tags" [multi]="true" placeholder="{{ 'pg-filter-editor.system_tags' | locTranslate }}" [options]="systemTagsOptions" (ngModelChange)="applySearch.emit()" [display]="{ oneLine: true }"></app-pg-select>
            </div>
        </div>
        <div *ngIf="categoriesOptions != null" class="input-group col-12 mt-2">
            <div class="input-group-text {{hasAppliedCategories() ? 'bg-primary text-white' : ''}}">
                <i class="fa-regular fa-list fa-fw"></i>
            </div>
            <div class="PGFilterEditorSearch-Select">
                <app-pg-select [ngModel]="getFilterCategories()" (ngModelChange)="setFilterCategories($event)" [multi]="true" placeholder="{{ 'pg-filter-editor.categories' | locTranslate }}" [options]="categoriesOptions" [display]="{ oneLine: true }"></app-pg-select>
            </div>
        </div>
    </div>
</div>