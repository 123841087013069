import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EFFilterData, EFProductType } from '../../../models/experience.model';
import { LocalizationService } from '../../../services/localization.service';
import { MapStylesService } from '../../../services/map-styles.service';

@Component({
  selector: 'app-ef-filter',
  templateUrl: './ef-filter.component.html',
  styleUrls: ['./ef-filter.component.scss']
})
export class EfFilterComponent implements OnInit, OnChanges {
    
    constructor(private localizationService:LocalizationService, private mapStylesService:MapStylesService) { }

    @Input() availableTypes:Array<EFProductType>;
    @Input() filterData:EFFilterData;
    @Output() filterDataChange = new EventEmitter<EFFilterData>();
    @Output() centerMap = new EventEmitter<void>();

    @Input() lockFilter:boolean;
    @Input() lockFilterType:boolean;

    @Input() section:string;

    @Input() isLoadingTabs:boolean;

    filterCollapsed = true;

    ngOnInit(): void {
        this._generateCategoriesList();
        this._generateLanguagesList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.filterData != null && this.filterData.type != null && this.isTypeHidden(this.filterData.type)) {
            for(let _type of this.availableTypes) {
                if(!this.isTypeHidden(_type)) {
                    this.filterData.type = _type;
                }
            }
            
            this.onFilterChange();
        }
    }

    onFilterChange() {
        this.filterDataChange.emit(this.filterData)
    }

    setFilterDataType(val:EFProductType) {
        if(this.filterData.type != val) {
            this.filterData.type = val;
            this.onFilterChange();

            setTimeout(() => {
                this.centerMap.emit();
            }, 100)
        }
    }

    getFilterDataInterval() {
        if(this.filterData.interval == null) {
            return null;
        }
        else {
            return JSON.stringify(this.filterData.interval);
        }
    }

    setFilterDataInterval(val:string) {
        if(val == null) {
            this.filterData.interval = null;
        }
        else {
            this.filterData.interval = JSON.parse(val);
        }

        this.onFilterChange();
    }

    hasFilterTypeDates(val:EFProductType) {
        return val != 'poi' && val != 'utility' && val != 'itinerary';
    }

    hasFilterTypeCategories(val:EFProductType) {
        return this.categoriesList[val] != null;
    }

    isTypeHidden(val:EFProductType) {
        return (this.availableTypes != null && this.availableTypes.indexOf(val) == -1) || (this.section == 'calendar' && val != 'experience' && val != 'event' && val != 'poi');
    }

    getTypeIcon(type:string) {
        return this.mapStylesService.markerStyles[type].label.text;
    }

    categoriesList:{ [type:string]: Array<string> } = null;
    subcategoriesList:{ [type:string]: Array<string> } = null;

    private _generateCategoriesList() {
        this.categoriesList = {};
        this.subcategoriesList = {};

        for(let i in this.filterData.category) {
            this.categoriesList[i] = [];
            this.subcategoriesList[i] = [];

            for(let j in this.filterData.category[i]) {
                if(this.filterData.category[i][j]) {
                    this.categoriesList[i].push(j)
                }

                if(this.filterData.subcategory[i] != null && this.filterData.subcategory[i][j] != null) {
                    if(this.filterData.allCategories || this.filterData.category[i][j]) {
                        for(let k in this.filterData.subcategory[i][j]) {
                            if(this.filterData.subcategory[i][j][k]) {
                                this.subcategoriesList[i].push(k)
                            }
                        }   
                    }
                }
            }
        }
    }

    // GESTIONE CATEGORIE
    // TODO: bisogna che getCategoryTranslation e getCategoryIcon non duplichino codice da EFProduct, non si possono usare metodi static perché in EFProduct viene passato LocalizationService nel costruttore

    getCategoryListDisplay(type:string) {
        let _categories = this.categoriesList[type];

        if(this.filterData.allCategories) {
            return this.localizationService.translate('experience-finder.ef-filter.categories-all');
        }
        else if(_categories.length == 0) {
            return this.localizationService.translate('experience-finder.ef-filter.categories-none')
        }
        else if(_categories.length > 3) {
            return this.localizationService.translate('experience-finder.ef-filter.categories-some', { number: _categories.length })
        }
        else {
            let _retVal = '';

            for(let _cCategory of _categories) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.getCategoryTranslation(type, _cCategory);
            }
    
            return _retVal;
        }
    }

    getCategoryTranslation(type:string, category:string) {
        if(category == '*') return this.localizationService.translate('experience-finder.ef-filter.categories-all');
        else {
            if(type == 'poi') {
                return this.localizationService.translate('OPTIONMAPS.EFPOI.category.' + category)
            }
            else {
                let _translType = type.charAt(0).toUpperCase() + type.substring(1)

                if(type == 'eatery' || type == 'host') _translType += '.type'
                else _translType += '.category'
    
                return this.localizationService.translate('OPTIONMAPS.' + _translType + '.' + category)
            }
        }
    }

    getCategoryIcon(type:string, category:string) {
        if(category == '*') return 'far fa-asterisk';
        else {
            if(type == 'poi') {
                return 'OPTIONMAPS.EFPOI.category.' + category + ' ' + this.localizationService.icon('OPTIONMAPS.EFPOI.category.' + category, 'far')
            }
            else {
                let _translType = type.charAt(0).toUpperCase() + type.substring(1)

                if(type == 'eatery' || type == 'host') _translType += '.type'
                else _translType += '.category'

                return 'OPTIONMAPS.' + _translType + '.' + category + ' ' + this.localizationService.icon('OPTIONMAPS.' + _translType + '.' + category, 'far')
            }
        }
    }

    toggleCategory(type:string, category:string) {
        this.filterData.category[type][category] = !this.filterData.category[type][category];

        if(this.filterData.category[type][category]) {
            this.filterData.allCategories = false;
        }

        this.filterData.allCategories = true;

        for(let i in this.filterData.category[type]) {
            if(this.filterData.category[type][i]) {
                this.filterData.allCategories = false;
                break;
            }
        }

        if(!this.filterData.allSubcategories) {
            this.toggleAllSubcategories(type, true)
        }

        this._generateCategoriesList();
        this.onFilterChange();
    }

    toggleAllCategories(type:string) {
        this.filterData.allCategories = !this.filterData.allCategories;

        for(let i in this.filterData.category[type]) {
            this.filterData.category[type][i] = !this.filterData.allCategories;
        }

        if(!this.filterData.allSubcategories) {
            this.toggleAllSubcategories(type, true)
        }

        this._generateCategoriesList();
        this.onFilterChange();
    }

    // GESTIONE SOTTOCATEGORIE

    getSubcategoryListDisplay(type:string) {
        let _subcategories = this.subcategoriesList[type];

        if(this.filterData.allSubcategories) {
            return this.localizationService.translate('experience-finder.ef-filter.subcategories-all');
        }
        else if(_subcategories.length == 0) {
            return this.localizationService.translate('experience-finder.ef-filter.subcategories-none')
        }
        else if(_subcategories.length > 3) {
            return this.localizationService.translate('experience-finder.ef-filter.subcategories-some', { number: _subcategories.length })
        }
        else {
            let _retVal = '';

            for(let _cSubcategory of _subcategories) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.getSubcategoryTranslation(type, _cSubcategory);
            }
    
            return _retVal;
        }
    }

    getSubcategoryTranslation(type:string, subcategory:string) {
        if(subcategory == '*') return this.localizationService.translate('experience-finder.ef-filter.subcategories-all');
        else {
            let _translType = type.charAt(0).toUpperCase() + type.substring(1)

            _translType += '.subcategory'

            return this.localizationService.translate('OPTIONMAPS.' + _translType + '.' + subcategory)
        }
    }

    hasSelectableSubcategories(type:string) {
        if(this.filterData.subcategory[type] != null) {
            for(let i in this.filterData.subcategory[type]) {
                if(this.filterData.allCategories || this.filterData.category[type][i]) return true;
            }
        }
    }

    toggleSubcategory(type:string, category:string, subcategory:string) {
        let _val = !this.filterData.subcategory[type][category][subcategory];

        this.filterData.subcategory[type][category][subcategory] = _val;

        this.filterData.allSubcategories = true;

        for(let i in this.filterData.subcategory[type][category]) {
            if(this.filterData.subcategory[type][category][i]) {
                this.filterData.allSubcategories = false;
                break;
            }
        }

        this._generateCategoriesList();
        this.onFilterChange();
    }

    toggleAllSubcategories(type:string, skipRefresh?:boolean) {
        this.filterData.allSubcategories = !this.filterData.allSubcategories;

        for(let i in this.filterData.subcategory[type]) {
            for(let j in this.filterData.subcategory[type][i]) {
                this.filterData.subcategory[type][i][j] = !this.filterData.allSubcategories;
            }
        }
        
        if(!skipRefresh) {
            this._generateCategoriesList();
            this.onFilterChange();
        }
    }

    // GESTIONE LINGUE

    languagesList:{ [type:string]: Array<string> } = null;

    private _generateLanguagesList() {
        this.languagesList = {};

        for(let i in this.filterData.language) {
            this.languagesList[i] = [];

            let _missesSomething = false;

            for(let j in this.filterData.language[i]) {
                if(this.filterData.language[i][j]) {
                    this.languagesList[i].push(j)
                }
                else {
                    _missesSomething = true;
                }
            }

            if(!_missesSomething) this.languagesList[i] = ['*']
        }
    }

    toggleLanguage(type:string, lang:string) {
        this.filterData.language[type][lang] = !this.filterData.language[type][lang];
        this._generateLanguagesList();
        this.onFilterChange();
    }

    getLanguageListDisplay(type:string) {
        let _languages = this.languagesList[type];

        if(_languages.length == 0) {
            return this.localizationService.translate('experience-finder.ef-filter.languages-none')
        }
        else if(_languages.length > 3) {
            return this.localizationService.translate('experience-finder.ef-filter.languages-some', { number: _languages.length })
        }
        else {
            let _retVal = '';

            for(let _language of _languages) {
                if(_retVal != '') _retVal += ', '

                if(_language == '*') {
                    _retVal += this.localizationService.translate('experience-finder.ef-filter.languages-all')
                }
                else {
                    _retVal += _language.split('_')[0].toUpperCase();
                }
            }
    
            return _retVal;
        }
    }

    getLanguageLabel(language:string) {
        return this.localizationService.languageLabels[language] || language;
    }

    // GESTIONE STELLE

    hasFilterTypeStars(val:EFProductType) {
        return this.filterData.stars[val] != null;
    }

    toggleStars(type:string, stars:number) {
        this.filterData.stars[type][stars] = !this.filterData.stars[type][stars];

        this.filterData.allStars = true;

        for(let i in this.filterData.stars[type]) {
            if(this.filterData.stars[type][i]) {
                this.filterData.allStars = false;
                break;
            }
        }

        this.onFilterChange();
    }

    toggleAllStars(type:string) {
        this.filterData.allStars = !this.filterData.allStars;

        for(let i in this.filterData.stars[type]) {
            this.filterData.stars[type][i] = !this.filterData.allStars;
        }

        this.onFilterChange();
    }

    getStarsListDisplay(type:string) {
        if(this.filterData.allStars) {
            return this.localizationService.translate('experience-finder.ef-filter.stars-all')
        }
        else {
            let _starsList:Array<string> = [];
            
            for(let i in this.filterData.stars[type]) {
                if(this.filterData.stars[type][i]) _starsList.push(i)
            }

            if(_starsList.length == 0) {
                return this.localizationService.translate('experience-finder.ef-filter.stars-none')
            }
            else {
                return this.localizationService.translate('experience-finder.ef-filter.stars-some', { list: _starsList.join(', ') })
            }
        }
    }

    getStarsLabel(val:number|string) {
        if(typeof val == 'string') val = parseInt(val)

        let _retVal = '';

        for(let i = 0; i < 5; i++) {
            if(i < val) {
                _retVal += '<i class="fa-solid fa-star m-0 text-warning opacity-100"></i>'
            }
            else {
                _retVal += '<i class="fa-regular fa-star m-0 text-muted opacity-50"></i>'
            }
        }

        return _retVal
    }

     // GESTIONE PREZZI

    hasFilterTypeCost(val:EFProductType) {
        return this.filterData.cost[val] != null;
    }

    toggleCost(type:string, index:number) {
        this.filterData.cost[type][index].selected = !this.filterData.cost[type][index].selected;

        this.filterData.allCosts = true;

        for(let _item of this.filterData.cost[type]) {
            if(_item.selected) {
                this.filterData.allCosts = false;
                break;
            }
        }

        this.onFilterChange();
    }

    toggleAllCosts(type:string) {
        this.filterData.allCosts = !this.filterData.allCosts;

        for(let _item of this.filterData.cost[type]) {
            _item.selected = !this.filterData.allCosts;
        }

        this.onFilterChange();
    }

    getCostListDisplay(type:string) {
        if(this.filterData.allCosts) {
            return this.localizationService.translate('experience-finder.ef-filter.cost-all')
        }
        else {
            let _costListVal:Array<{ from: number, to: number }> = [];
            
            for(let _item of this.filterData.cost[type]) {
                if(_item.selected) {
                    if(_costListVal.length > 0 && _item.from == _costListVal[_costListVal.length - 1].to) {
                        _costListVal[_costListVal.length - 1].to = _item.to;
                    }
                    else {
                        _costListVal.push({ from: _item.from, to: _item.to })
                    }
                }
            }

            if(_costListVal.length == 0) {
                return this.localizationService.translate('experience-finder.ef-filter.cost-none')
            }
            else {
                let _costrListString = '';
                for(let _item of _costListVal) {
                    if(_costrListString != '') _costrListString += ', ';
                    _costrListString += this.getCostLabel(_item);
                }

                return this.localizationService.translate('experience-finder.ef-filter.cost-some', { list: _costrListString })
            }
        }
    }

    getCostLabel(item:{ from: number, to: number }) {
        return item.from + ' - ' + item.to + ' €'
    }
}
