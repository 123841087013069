import { ErrorHandler, forwardRef, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PgHelpService } from './pg-help.service';
import { PgHelpComponent } from './pg-help/pg-help.component';
import { PgErrorsComponent } from './pg-errors/pg-errors.component';
import { PgUiElementsModule } from '../pg-ui-elements/pg-ui-elements.module';
import { PgUiControlsModule } from '../pg-ui-controls/pg-ui-controls.module';


@NgModule({
    declarations: [
        PgHelpComponent,
        PgErrorsComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        PgUiElementsModule,
        PgUiControlsModule
    ],
    exports: [
        PgHelpComponent,
        PgErrorsComponent
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useExisting: forwardRef(() => PgHelpService),
        multi: true
    }, {
        provide: ErrorHandler,
        useClass: PgHelpService
    }]
})
export class PgHelpModule { }
