export class PGLocation {
    type: string
    coordinates: Array<number>
}

export class PGLocationAddress {
    country?: string
    region?: string
    province?: string
    city?: string
    address?: string
    zipcode?: string
    timezone?: string
}

export class PGMapGeometry {
    type:string;
    coordinates:Array<number>;

    static fromData(data:any):PGMapGeometry {

        if(data == null) {
            return null;
        }
        else if(Array.isArray(data)) {
            return new PGMapGeometryPoint(data as [number,number]);
        }
        else {
            switch(data.type) {
                case 'Point': return new PGMapGeometryPoint(data.coordinates);
            }
        }
    }
}

export class PGMapGeometryPoint extends PGMapGeometry {
    type = 'Point';

    constructor(public coordinates:[number,number]) {
        super();
    }
}