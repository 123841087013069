<div class="PGFooter bg-white border-top">
    <div class="PGFooter-Links">
        <div class="px-3">
            <span class="PGFooter-Links-Item d-flex align-items-center">
                <div *ngIf="appLogo" class="me-1">
                    <img style="height: 3rem;" src="{{appLogo}}">
                </div>
                <div *ngIf="appProvider">{{appProvider}}</div>
                <div *ngIf="!appProvider" class="small text-dark py-2 d-flex">
                    <span>Powered by</span>
                    <span *ngIf="noLinks" class="PGFooter-Links-Item text-dark d-flex ms-1">
                        <img class="me-1 d-block PGFooter-Links-Item-Logo" src="assets/images/poistory.png">
                        <b>Poistory</b>
                    </span>
                    <a *ngIf="!noLinks" class="PGFooter-Links-Item text-dark d-flex ms-1" target="_blank" href="https://www.poistory.tech/">
                        <img class="me-1 d-block PGFooter-Links-Item-Logo" src="assets/images/poistory.png">
                        <b>Poistory</b>
                    </a>
                </div>

                <span class="PGFooter-Links-Item PGFooter-Links-Item--Version d-flex align-items-center" ngbDropdown [autoClose]="'outside'">
                    <button class="btn btn-sm btn-link px-1 py-0 ms-2 text-muted" ngbDropdownToggle>
                        v {{getVersionString()}}
                    </button>
                    <div ngbDropdownMenu>
                        <div class="dropdown-item dropdown-item-text small" (click)="copyVersionsData()">
                            <div class="d-flex justify-content-between align-items-center border-bottom pb-2 mb-2">
                                <div>
                                    <h6 class="m-0"><b>Versions</b></h6>
                                </div>
                                <div>
                                    <button class="btn btn-sm btn-link p-0">
                                        <i class="fa-regular {{ copiedVersionsData ? 'fa-check text-success' : 'fa-copy'}}"></i>
                                    </button>
                                </div>
                            </div>
                            <div [innerHTML]="getVersionsTable() | safe:'html'"></div>
                        </div>
                    </div>
                </span>
            </span>
            <div class="PGFooter-Links-Inner d-flex justify-content-center small">
                <div class="d-flex align-items-center">
                    <ng-container *ngIf="noLinks">
                        <a class="PGFooter-Links-Item text-muted p-1" (click)="openModalText('privacy-policy')">{{ 'pg-footer.privacy-policy' | locTranslate }}</a>
                        <a class="PGFooter-Links-Item text-muted p-1" (click)="openModalText('terms-and-conditions')">{{ 'pg-footer.terms-and-conditions' | locTranslate }}</a>
                    </ng-container>
                    <ng-container *ngIf="!noLinks">
                        <a class="PGFooter-Links-Item text-muted p-1" target="_blank" href="{{privacyPolicyURL}}">{{ 'pg-footer.privacy-policy' | locTranslate }}</a>
                        <a class="PGFooter-Links-Item text-muted p-1" target="_blank" href="{{termsAndConditionsURL}}">{{ 'pg-footer.terms-and-conditions' | locTranslate }}</a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="PGFooter-Messages border rounded bg-light px-3 py-2" [hidden]="!hasSomething()">
        <div [hidden]="!hasErrors()" class="mb-2">
            <app-pg-errors #errorsComponent></app-pg-errors>
        </div>
        <div *ngFor="let notification of notifications; let i = index" class="PGFooter-Notification {{i > 0 ? 'mt-3' : ''}} {{notification.duration != null ? 'pg-animation-fadeout' : ''}}"
            [style.animationDelay]="(notification.duration - 200) + 'ms'" [style.animationDuration]="'200ms'">
            <div class="PGFooter-Notification-Buttons">
                <button *ngIf="notification.duration == null || notification.duration > 5000" class="btn btn-sm btn-link text-dark p-1" (click)="removeNotification(notification)">
                    <i class="fa-regular fa-times"></i>
                </button>
            </div>
            <app-pg-notification [notification]="notification"></app-pg-notification>
        </div>
    </div>
</div>

<ng-template #modalText>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-footer.' + modalTextContent | locTranslate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModalText()"></button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="'ENTOURANCE.' + modalTextContent | locTranslate"></div>
    </div>
</ng-template>