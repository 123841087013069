import { Component, OnInit, Input, ComponentFactoryResolver, OnChanges, ViewChild, Directive, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ConfigProfileRoleActionsExtended, ConfigService } from '../../services/config.service';
import { PGViewResourceComponent, PGViewResourceElementAction, PGViewResourceElementBadge, PGViewResourceManager } from '../resource-view-common';
import { PgFilterStatus } from '../../services/data.service';

@Directive({
    selector: 'pg-resource-view-host',
})

export class PgResourceViewHostDirective {
    constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-pg-resource-embed',
  templateUrl: './pg-resource-embed.component.html',
  styleUrls: ['./pg-resource-embed.component.scss']
})
export class PgResourceEmbedComponent implements OnChanges {
    @ViewChild(PgResourceViewHostDirective, { static: true }) resourceViewHost: PgResourceViewHostDirective;

    @Input() resourceId:string = null;

    @Input() relatedResource:string = null;
    @Input() relatedElement:string = null;

    @Input() filterStatus:PgFilterStatus = null;

    @Input() selectionMode:'single'|'multi' = null;
    @Input() selectionField:string = null;
    @Input() selection:Array<string> = null;

    @Input() elementActions:Array<PGViewResourceElementAction> = null;
    @Input() elementBadges:Array<PGViewResourceElementBadge> = null;

    @Output() selectionChange = new EventEmitter<Array<string>>();

    @Input() readOnly:boolean = null;
    @Input() baseURL:string = null;

    viewInstance:PGViewResourceComponent = null;

    actions:ConfigProfileRoleActionsExtended = null;

    canView = false;

    constructor(private componentFactoryResolver: ComponentFactoryResolver, private configService: ConfigService, private PGViewResourceManager:PGViewResourceManager) {
        
    }

    ngOnChanges(simpleChanges) {
        if(simpleChanges.selection == null || Object.keys(simpleChanges).length > 1) {
            let _cViewConfig = this.configService.getResourceViewConfig(this.resourceId);

            let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.PGViewResourceManager.getViewComponent(_cViewConfig.type));
    
            let viewContainerRef = this.resourceViewHost.viewContainerRef;
            viewContainerRef.clear();
    
            this.actions = this.configService.getResourceActionsExtended(this.resourceId);
    
            this.canView = (this.relatedResource != null && this.relatedElement != null) ? this.actions.view : this.actions.list;
    
            // TODO: verificare anche permessi view e actions
    
            if(this.canView) {
                this.viewInstance = <PGViewResourceComponent>viewContainerRef.createComponent(componentFactory).instance;
                this.viewInstance.resourceId = this.resourceId;
                this.viewInstance.relatedResource = this.relatedResource;
                this.viewInstance.relatedElement = this.relatedElement;
                this.viewInstance.filterStatus = this.filterStatus;
                this.viewInstance.selectionMode = this.selectionMode;
                this.viewInstance.selection = this.selection;
                this.viewInstance.elementActions = this.elementActions;
                this.viewInstance.elementBadges = this.elementBadges;
                this.viewInstance.readOnly = this.readOnly
                this.viewInstance.selectionField = this.selectionField;
                this.viewInstance.baseURL = this.baseURL;
                
                this.viewInstance.selectionChange.subscribe((event) => {
                    this.selectionChange.emit(event);
                })
    
                this.viewInstance.ngOnChanges();
            }
        }
    }
}
