import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EnvironmentService } from './environment.service';
import { v4 as uuid } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
    constructor(private environmentService:EnvironmentService, private router:Router, private http:HttpClient, private persistenceService:PersistenceService) {}

    private _appId:string = null;

    private _refreshSessionTimeout = null;

    private _getSessionId() {
        clearTimeout(this._refreshSessionTimeout);
        this._refreshSessionTimeout = setTimeout(() => {
            this.sendEvent('IDLE')
        }, 5 * 60 * 1000)

        let _cSessionId:string = null;

        let _cSavedSessionString = this.persistenceService.getItem('POIGEST_AnalyticsSession');
        if(_cSavedSessionString != null) {
            try {
                let _cSavedSessionObj = JSON.parse(_cSavedSessionString)
                let _cDate = new Date(_cSavedSessionObj.time);

                if(Date.now() - _cDate.getTime() < 5.5 * 60 * 1000) { // IDLE è ogni 5 minuti e refresha la sessione, gli lascio un po' di margine per refreshare il token
                    _cSessionId = _cSavedSessionObj.id;
                }
            }
            catch(ex) {}
        }

        if(_cSessionId == null) {
            _cSessionId = uuid();

            console.log('AnalyticsService.getSessionId', _cSessionId)
        }

        this.persistenceService.setItem('POIGEST_AnalyticsSession', JSON.stringify({ id: _cSessionId, time: new Date().toISOString() }), 'analytics')

        return _cSessionId;
    }

    isInitialized = false;

    init(appId:string) {
        if(!this.isInitialized && this.persistenceService.hasConsentFor('analytics')) {
            this._appId = appId;

            this.sendEvent('INIT', {
                AppName: this.environmentService.environment.AppName,
                AppMode: this.environmentService.environment.AppMode,
                APIUrl: this.environmentService.environment.APIUrl,
                userAgent: navigator.userAgent || navigator.vendor,
                referrer: document.referrer
            })
    
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.sendEvent('ROUTE', event.urlAfterRedirects);
                }
            })

            this.isInitialized = true;
        }
    }

    sendEvent(event:string, params?:any) {
        if(this.isInitialized) {
            let _cSessionId = this._getSessionId();
            console.log('AnalyticsService.sendEvent', this._appId, _cSessionId, event, params)
    
            this.http.post(this.environmentService.environment.APIUrl + '/public/analytics/event', {
                "app_id": this._appId,
                "session_id": _cSessionId,
                "event": event,
                "params": params
            }).subscribe(() => {}, () => {})
        }
    }
}
