<div class="PGDateInterval">
    <button class="btn btn-outline-primary" (click)="openModal()">{{getFormattedValue()}}</button>
</div>

<ng-template #modalPicker>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'pg-date-interval.modal-title' | locTranslate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
    </div>
    <div class="modal-body">
        <div *ngFor="let month of months" class="PGDateInterval-Month">
            <h4 class="text-center my-2">{{getMonthName(month.num)}} {{month.year}}</h4>
            <div>
                <div *ngFor="let week of month.weeks" class="PGDateInterval-Month-Week">
                    <div *ngFor="let day of week" class="PGDateInterval-Month-Weeks-Day text-center">
                        <button *ngIf="day != null" [disabled]="day.disabled" class="btn my-1 mx-auto {{day.selected ? 'bg-primary text-white' : ''}}" (click)="onDayClick(month.year, month.num, day.num)">
                            <div class="PGDateInterval-Month-Weeks-Day-Highlight rounded-circle {{day.highlighted ? 'bg-primary' : ''}}"></div>
                            <div class="PGDateInterval-Month-Weeks-Day-Number {{day.selected || day.highlighted ? 'text-white' : ''}}">{{day.num}}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-link btn-block" (click)="addMonth()">
                <i class="fa-regular fa-chevron-down"></i>
            </button>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="clearSelection()">
            {{ 'pg-date-interval.modal-button-clear' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="closeModal()">
            {{ 'pg-date-interval.modal-button-select' | locTranslate }}
        </button>
    </div>
</ng-template>
