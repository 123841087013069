import { Component, OnDestroy } from '@angular/core';
import { PgFormField, PgFormLayout } from '../../../models/form.model';
import { DataService } from '../../../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../services/notifications.service';
import { SaveStatusService } from '../../../pg-ui-elements/save-status.service';
import { PgDirectoryData, PgFileData } from '../../../models/file.model';
import { AuthService } from '../../../services/auth.service';
import { SingleFormComponent } from '../single-form-main';
import { LocalizationService } from '../../../services/localization.service';
import { EnvironmentService } from '../../../services/environment.service';
import { OptionMapsService } from '../../../services/option-maps.service';

type FormsFileUploadResult = 'success'|'error'|'skip'

@Component({
  selector: 'app-forms-file',
  templateUrl: './forms-file.component.html',
  styleUrls: ['./forms-file.component.scss']
})
export class FormsFileComponent extends SingleFormComponent {

    constructor(protected dataService:DataService, protected authService:AuthService, protected localizationService:LocalizationService, protected router:Router, protected route:ActivatedRoute, protected notificationsService:NotificationsService, protected saveStatusService:SaveStatusService, protected optionMapsService:OptionMapsService, protected environmentService:EnvironmentService) {
        super(dataService, authService, localizationService, router, route, notificationsService, saveStatusService, optionMapsService, environmentService)
    }

    resourceId = 'File';

    elementData:any = null;

    randomValue = Math.random()

    formLayout = new PgFormLayout([
        new PgFormField({ label: 'auto', type: 'string', name: 'file_name' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'file_extension', readonly: true }),

        new PgFormField({ label: null, type: 'split', name: 'split_0' }),
        
        new PgFormField({ label: 'auto', type: 'select', name: 'directory_id', resource: 'Directory', readonly: true, resourceSemantic: '{{id}} - {{label}}' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'categories', multi: true }),
        new PgFormField({ label: 'auto', type: 'string', name: 'tags' }),
        new PgFormField({ label: 'auto', type: 'select', multi: true, name: 'system_tags' }),

        new PgFormField({ label: null, type: 'split', name: 'split_1' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'license' }),
        new PgFormField({ label: 'auto', type: 'string', name: 'license_other' }),

        new PgFormField({ label: null, type: 'split', name: 'split_2' }),

        new PgFormField({ label: 'auto', type: 'select', name: 'group_id' }),
        new PgFormField({ label: 'auto', type: 'select', name: 'realm_id' }),
    ]);

    protected async afterInitializeForm() {
        this.formLayout.getFieldLayout('tags').display = { fullWidth: true }
        this.formLayout.getFieldByName('file_extension').visible = false;

        this.formLayout.getFieldByName('categories').options = this.optionMapsService.getResourceFieldOptionMap('Poi', 'category')

        this.formLayout.getFieldLayout('directory_id').display = { oneLine: true };  
        
        this.formLayout.getFieldLayout('license_other').condition = '$form.license == "other"';    
        this.formLayout.getFieldLayout('realm_id').condition = '$form.directory_id == null';    

        // NB: per ora metto sempre nascosto realm_id e uso quello corrente, qua è particolarmente importante perché nel caso di upload di directory ho necessità di fare un tot di GET per verificare l'esistenza di file e cartelle che prendono di defualt il current realm
        // probabilmente però è da fare così ovunque
        this.formLayout.getFieldByName('realm_id').visible = false

        this.formLayout.getFieldLayout('split_1').display = { invisible: true }
        this.formLayout.getFieldLayout('split_2').display = { invisible: true }
    }

    protected async afterLoadData(values) {
        this.formLayout.getFieldByName('file_name').visible = this.openMode != 'create';
        this.formLayout.getFieldByName('file_name').required = this.openMode != 'create';
        this.formLayout.getFieldByName('split_0').visible = this.openMode != 'create';

        this.returnFragment = values.directory_id;

        return values;
    }

    private _fileExtensionRegExp = /\.[^\.]*$/

    protected adaptLoadData(values: any) {
        if(values.nome != null) {
            values.file_name = values.nome.replace(this._fileExtensionRegExp, '')

            values.file_extension = null;
            
            let _extensionMatch = values.nome.match(this._fileExtensionRegExp)
            if(_extensionMatch != null) {
                values.file_extension = _extensionMatch[0]
            }

            this.formLayout.getFieldByName('file_name').text = values.file_extension;
        }

        if(values.license != null) {
            let _isIn = false;

            for(let _option of this.selectOptions['license']) {
                if(_option.value == values.license) {
                    _isIn = true;
                    break;
                }
            }

            if(!_isIn) {
                values.license_other = values.license
                values.license = 'other'
            }
        }

        return values
    }

    protected adaptSaveData(values: any) {
        if(values.file_name != null && values.file_extension != null) {
            values.nome = values.file_name + values.file_extension
        }

        if(values.license == 'other') {
            values.license = values.license_other;

            if(values.license_other != null) {
                delete values.license_other;
            }
        }

        return values
    }

    private _isDestroyed = false;

    ngOnDestroy(): void {
        this._isDestroyed = true;

        super.ngOnDestroy();
    }

    uploadFiles:Array<{
        progress:number,
        result:FormsFileUploadResult,
        file:File,
        directory_id?:string
    }> = []

    skipPresentFiles = true;

    addUploadFiles(files:Array<File>) {
        if(files != null) {
            for(let i = 0; i < files.length; i++) {
                this.uploadFiles.push({
                    progress: 0,
                    result: null,
                    file: files[i]
                });
            }
        }

        this._checkCanSave()
    }

    removeUploadFile(index:number) {
        this.uploadFiles.splice(index, 1)

        this._checkCanSave()
    }

    private _checkCanSave() {
        if(this._saveRequest != null) {
            if(this.openMode == 'edit') {
                this._saveRequest.actions.save = true
            }
            else {
                this._saveRequest.actions.save = false;
                for(let _upload of this.uploadFiles) {
                    if(_upload.result != 'success') {
                        this._saveRequest.actions.save = true;
                        break;
                    }
                }
            }
        }
    }
    
    saveData() {
        if(this.openMode == 'edit') {
            super.saveData()
        }
        else {
            if(!this.isSaving) {
                this.isSaving = true;

                let _values = this.adaptSaveData(this.formLayout.getData());
    
                for(let _upload of this.uploadFiles) {
                    if(_upload.result != 'success') {
                        _upload.progress = 0;
                        _upload.result = null;
                    }
                }

                let _time = Date.now()

                console.log('Upload start')
                console.log(this.uploadFiles.length + ' files')

                this._checkWakeLock((release) => {
                    this._checkSaveDirectories(_values).then(() => {

                        console.log('Directories', Date.now() - _time)
    
                        _time = Date.now()
    
                        this._checkSaveFiles(_values).then((hasErrors) => {

                            release();
    
                            console.log('Files', Date.now() - _time)
    
                            this.isSaving = false;
                            this.saveDirectoriesProgress = null;
    
                            if(!hasErrors && !this._isDestroyed) {
                                let _action:'update'|'insert' = 'update';
                                if(this.openMode == 'create') _action = 'insert'

                                let _notificationContent = this.localizationService.translate('forms-result-splash.action-text-' + _action)

                                let _uploaded = 0;
                                let _skipped = 0;

                                if(this.uploadFiles != null) {
                                    for(let _file of this.uploadFiles) {
                                        if(_file.result == 'success') {
                                            _uploaded++;
                                        }
                                        else if(_file.result == 'skip') {
                                            _skipped++;
                                        }
                                    }

                                    if(_uploaded > 0) {
                                        _notificationContent += '<br>' + this.localizationService.translate('forms-result-splash.files-uploaded', { count: _uploaded })
                                    }

                                    if(_skipped > 0) {
                                        _notificationContent += '<br>' + this.localizationService.translate('forms-result-splash.files-skipped', { count: _skipped })
                                    }
                                }
            
                                this.notificationsService.addLocalNotification(_notificationContent, 'success', null, null, 3000 + 
                                    (_uploaded > 0 ? 1000 : 0) + 
                                    (_skipped > 0 ? 1000 : 0))
            
                                this.router.navigate([this.returnUrl], { relativeTo: this.route, fragment: this.returnFragment })
                            }
                        })
                    })
                })
            }
        }
    }

    private _checkWakeLock(fn:(release:() => void) => void) {
        if('wakeLock' in navigator) {
            console.log('wakeLock in navigator')
            navigator['wakeLock'].request().then((lock) => {
                console.log('wakeLock resolve')
                fn(() => {
                    lock.release()
                })
            }, () => {
                console.log('wakeLock reject')
                fn(() => {})
            })
        }
        else {
            console.log('wakeLock not active')
            fn(() => {})
        }
    }

    private _saveDirectoryFromPath(path:string) {
        return path.replace(/[^\/]*$/, '')
    }

    private _getPathParent(path:string) {
        return path.replace(/[^\/]*\/$/, '')
    }

    private _getPathLabel(path:string) {
        let _split = path.split('/')
        return _split[_split.length - 2] // l'ultimo è stringa vuota
    }

    saveDirectoriesProgress:number = null;

    private async _checkSaveDirectories(values:any) {
        let _element = document.getElementById(this.randomValue + '_upload_directories')
        if(_element != null) _element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

        let _toCheckList:Array<string> = [];
        
        for(let _upload of this.uploadFiles) {
            let _dir = this._saveDirectoryFromPath(_upload.file.webkitRelativePath);
            if(_dir != null) {
                let _dirSplit = _dir.split('/')
                let _check = ''

                for(let _split of _dirSplit) {
                    if(_split != '') {
                        _check += _split + '/'

                        if(_toCheckList.indexOf(_check) == -1) _toCheckList.push(_check)
                    }
                }
            }
        }

        let _checkedIndex:{ [label:string]: PgDirectoryData } = {}

        if(values.directory_id != null) {            
            await new Promise<void>((resolve, reject) => {
                this.dataService.getElementData('Directory', values.directory_id).subscribe((data) => {
                    _checkedIndex[''] = data
      
                    resolve();
                })
            })
        }

        if(_toCheckList.length > 0) this.saveDirectoriesProgress = 0;

        let _checkedParents:{ [id:string]: boolean } = {}

        for(let i = 0; i < _toCheckList.length; i++) {
            let _item = _toCheckList[i];

            let _parentPath = this._getPathParent(_item)
            let _parentDir = _checkedIndex[_parentPath]
            let _parentId = _parentDir?.id;

            if(_checkedIndex[_item] == null) {
                if(!_checkedParents[_parentId]) {
                    _checkedParents[_parentId] = true;

                    await new Promise<void>((resolve, reject) => {
                        this.dataService.getResourceData('Directory', { limit: 1000, filter: [
                            { field: 'parent_id', operator: '==', value: [ _parentId ]}
                        ]}).subscribe((data) => {
                            for(let _item of data) {
                                _checkedIndex[_parentPath + _item.label + '/'] = _item
                            }

                            resolve();
                        })
                    })
                }
            }

            if(_checkedIndex[_item] == null) {
                await new Promise<void>((resolve, reject) => {
                    this.dataService.postResourceData('Directory', {
                        parent_id: _parentId,
                        realm_id: values.realm_id,
                        label: this._getPathLabel(_item),
                        tags: values.tags,
                        system_tags: values.system_tags,
                        categories: values.categories
                    }).subscribe((data) => {
                        _checkedIndex[_item] = data

                        resolve();
                    })
                })
            }
            else {
                await new Promise<void>((resolve, reject) => {
                    this.dataService.putElementData('Directory', _checkedIndex[_item].id, {
                        tags: values.tags,
                        system_tags: values.system_tags,
                        categories: values.categories
                    }).subscribe((data) => {
                        _checkedIndex[_item] = data

                        resolve();
                    })
                })
            }

            this.saveDirectoriesProgress = (i + 1) / _toCheckList.length;

            if(this._isDestroyed) return;
        }

        for(let _upload of this.uploadFiles) {
            if(_upload.file.webkitRelativePath != null) {
                let _dir = this._saveDirectoryFromPath(_upload.file.webkitRelativePath);
                if(_dir != '') {
                    _upload.directory_id = _checkedIndex[_dir].id
                }
            }
        }
    }

    private async _checkSaveFiles(values:any) {
        let _hasErrors = false;

        let _checkedIndex: { [directory:string]: Array<PgFileData> } = {}

        let _skipSequence = 0;

        for(let i = 0; i < this.uploadFiles.length; i++) {
            let _upload = this.uploadFiles[i];

            if(_upload.result == null) {

                console.log('Trying to upload file', _upload)

                let _element = document.getElementById(this.randomValue + '_upload_' + i)
                if(_element != null) _element.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
                
                _upload.progress = 0.05;
                _upload.result = null;

                let _skipFile = false;

                if(_upload.directory_id != null) {
                    if(_checkedIndex[_upload.directory_id] == null) {
                        console.log('Loading directory ' + _upload.directory_id)

                        await new Promise<void>((resolve, reject) => {
                            this.dataService.getResourceData('File', { limit: 5000, filter: [
                                { field: 'directory_id', operator: '==', value: [_upload.directory_id] }
                            ] }).subscribe((data) => {
                                _checkedIndex[_upload.directory_id] = data

                                resolve()
                            })
                        })
                    }

                    for(let _file of _checkedIndex[_upload.directory_id]) {
                        if(_file.nome == _upload.file.name) {
                            console.log('File already present in directory', _file)

                            if(this.skipPresentFiles) {
                                _skipFile = true;
                            }
                            else {
                                console.log('Deleting file')
                                await new Promise<void>((resolve, reject) => {
                                    this.dataService.deleteElement('File', _file.id).subscribe((data) => {
                                        resolve()
                                    })
                                })
                            }
                        }
                    }
                }

                if(_skipFile) {
                    console.log('Skipping file')

                    _upload.progress = 1
                    _upload.result = 'skip'

                    _skipSequence++;

                    if(_skipSequence >= 3) {
                        await new Promise<void>((resolve, reject) => {
                            setTimeout(() => {
                                resolve();
                            }, 10)
                        })

                        _skipSequence = 0;
                    }
                }
                else {
                    _skipSequence = 0;

                    console.log('Uploading file')

                    await new Promise<void>((resolve, reject) => {
                        let _postValues = JSON.parse(JSON.stringify(values))

                        if(_upload.directory_id != undefined) {
                            _postValues.directory_id = _upload.directory_id
                        }

                        this.dataService.postFile('File', _upload.file, _postValues).subscribe((status) => {
                            _upload.progress = 0.05 + status.progress * 0.95;
    
                            if(status.complete) {
                                _upload.result = status.data == null ? 'error' : 'success';
                                resolve()
                            }
                        }, () => {
                            _upload.progress = 1;
                            _upload.result = 'error';
                            resolve()
                        });
                    })
    
                    if(_upload.result == 'error') _hasErrors = true;
                }

                if(this._isDestroyed) return _hasErrors;
            }
        }

        return _hasErrors
    }

    getResultProgressClass(result:FormsFileUploadResult) {
        switch(result) {
            case 'success': return 'bg-success';
            case 'error': return 'bg-danger';
            case 'skip': return 'bg-secondary';
            default: return '';
        }
    }
    
    // TODO: gestire crop image

    /*cropImage(file:PgFile) {
        let _modalRef = this.modalService.open(PgImageCropModalComponent);
        _modalRef.componentInstance.imageURL = file.url;

        _modalRef.result.then((result) => {
            if(result != null) {

                let _binaryString = atob(result.split(',')[1])
                let _byteArray = [];

                for(var i = 0; i < _binaryString.length; i++) {
                    _byteArray.push(_binaryString.charCodeAt(i));
                }
                
                this.uploadFile(new File([new Uint8Array(_byteArray)], file.nome_file))
            }
        }, () => {

        })
    }*/
}

