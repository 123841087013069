import { LocalizationService } from "../../services/localization.service";

export class PGCalendarElement {
    start:string;
    end:string;
    location:string;
    title:string;
    description:string;
    status:string;
    id: string;
    value: number;
}

export class PGCalendarDayFlags {
    today: boolean;
    holiday: boolean;
    disabled: boolean;
}

export class PGCalendarDate {
    weekday:number = null;

    constructor(public year?: number, public month?: number, public day?: number) {
        if(year == null || month == null || day == null) {
            let _today = new Date();
            this.year = _today.getFullYear();
            this.month = _today.getMonth();
            this.day = _today.getDate();
        }

        this.weekday = new Date(this.year, this.month, this.day).getDay();
    }
}

export class PGCalendarDay {

    flags: PGCalendarDayFlags;
    elements: Array<PGCalendarElement> = [];

    constructor(public date:PGCalendarDate, flags?:PGCalendarDayFlags) {
        if(flags != null) {
            this.flags = flags;
        }
        else {
            this.flags = new PGCalendarDayFlags();
        }
    }
}

export abstract class PgCalendar {

    constructor(protected localizationService: LocalizationService) { }

    smallMode = false;

    private _month:number = new Date().getMonth();
    private _year:number = new Date().getFullYear();

    get month() { return this._month; }
    set month(val:number) { 
        while(val < 0) {
            val += 12;
            this._year--;
        }

        while(val > 11) {
            val -= 12;
            this._year++;
        }

        this._month = val; 

        this.generateCalendar(); 
    }

    get year() { return this._year; }
    set year(val:number) { 
        this._year = val; 
        this.generateCalendar(); 
    }

    weeks:Array<Array<PGCalendarDay>> = null;
    daysWithElements = null;
    daysWithElementsAround = null;

    selectOnlyWithElements = true;
    selectedDay:PGCalendarDay = null;
    lastSelectedDay:PGCalendarDay = null;

    isLoading = false;
    maxEventsDisplay = 3;

    generateCalendar(onComplete?:Function) {
        
        this.isLoading = true;

        setTimeout(() => {
            
            this.weeks = []

            let _weeksNum = 6;

            for(let wi = 0; wi < _weeksNum; wi++) {
                this.weeks[wi] = [];

                for(let di = 0; di < 7; di++) {
                    this.weeks[wi][di] = null;
                }
            }
    
            this.daysWithElements = [];

            this.selectedDay = null;
    
            this.getData().then((data) => {
                let _elementsByDay = [];

                for(let _cElement of data) {
                    let _cDate = new Date(_cElement.start);

                    if(_cDate.getFullYear() == this.year && _cDate.getMonth() == this.month) {
                        let _cDay = _cDate.getDate();
                        
                        if(_elementsByDay[_cDay] == null) {
                            _elementsByDay[_cDay] = [];
                        }

                        _elementsByDay[_cDay].push(_cElement);
                    }
                }

                let today = new Date();
        
                let startDate = new Date(this.year, this.month, 1);
                startDate.setDate(0 - ((6 + startDate.getDay() - this.localizationService.data.weekStart) % 7));
        
                let _didMonthStart = false;
                let _isMonthOver = false;

                for(let wi = 0; wi < this.weeks.length; wi++) {

                    for(let di = 0; di < 7; di++) {
                        let _cDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + wi * 7 + di);

                        let _isDisabled = _cDate.getMonth() != this.month;

                        if(_isDisabled) { 
                            if(_didMonthStart) _isMonthOver = true; 
                        }
                        else _didMonthStart = true;
        
                        this.weeks[wi][di] = new PGCalendarDay(new PGCalendarDate(_cDate.getFullYear(), _cDate.getMonth(), _cDate.getDate()), {
                            today: (today.getFullYear() == _cDate.getFullYear() && today.getMonth() == _cDate.getMonth() && today.getDate() == _cDate.getDate()),
                            holiday: false,
                            disabled: _isDisabled
                        })

                        if(_cDate.getMonth() == this.month) {
                            if(_elementsByDay[_cDate.getDate()] != null) {
                                this.weeks[wi][di].elements = _elementsByDay[_cDate.getDate()];
                            }
                            else {
                                this.weeks[wi][di].elements = [];
                            }
        
                            if(this.weeks[wi][di].elements.length > 0) {
                                this.daysWithElements.push(this.weeks[wi][di]);
                            }
                        }
                    }

                    if(_isMonthOver) {
                        //this.weeks.splice(wi + 1);
                        //break;
                    }
                }

                if(onComplete != null) onComplete();
    
                setTimeout(() => {
                    this.isLoading = false;
                }, 100);
            }, () => {
                this.isLoading = false;
            })
        }, 100)
    }

    getFlagClasses(dayData:PGCalendarDay) {
        var _retVal = '';

        if(dayData != null) {
            if(dayData.flags != null) {
                for(var i in dayData.flags) {
                    if(dayData.flags[i]) {
                        _retVal += ' PGCalendar-Day--Is' + i.charAt(0).toUpperCase() + i.slice(1);
                    }
                }
            }

            if(!this.selectOnlyWithElements || (dayData.elements != null && dayData.elements.length > 0)) {
                _retVal += ' PGCalendar-Day--IsSelectable';
            }

            if(dayData == this.lastSelectedDay) {
                _retVal += ' PGCalendar-Day--WasSelected';
            }

            if(dayData == this.selectedDay) {
                _retVal += ' PGCalendar-Day--IsSelected';
            }
        }

        return _retVal;
    }

    setSelectedDay(dayData:PGCalendarDay) {
        if(dayData == null) {
            this.lastSelectedDay = this.selectedDay;
            this.selectedDay = null; 
            this.daysWithElementsAround = [];   
        }
        else if(!this.selectOnlyWithElements || (this.selectedDay != dayData && dayData.elements.length > 0)) {
            this.lastSelectedDay = this.selectedDay;
            this.selectedDay = dayData;

            this.daysWithElementsAround = []; 

            let _selectedIndex = null;
            for(let i = 0; i < this.daysWithElements.length; i++) {
                if(this.daysWithElements[i] == this.selectedDay) {
                    _selectedIndex = i;
                    break;
                }
            }

            if(_selectedIndex != null) {
            
                let _indexFrom = Math.max(0, _selectedIndex - 3);
                let _indexTo = Math.min(this.daysWithElements.length, _indexFrom + 7);
    
                for(let i = _indexFrom; i < _indexTo; i++) {
                    this.daysWithElementsAround.push(this.daysWithElements[i]);
                }
            }
        }
    }

    getDetailAnimationOrigin() {
        var _selectedIndex = null;

        for(var i = 0; i < this.weeks.length; i++) {
            for(var j = 0; j < this.weeks[i].length; j++) {
                if(this.selectedDay == this.weeks[i][j]) {
                    _selectedIndex = [i, j];
                    break;
                }
            }

            if(_selectedIndex != null) break;
        }

        if(_selectedIndex != null) {
            return ((_selectedIndex[1] + 0.5) / 7 * 100).toString() + '%,' +  ((_selectedIndex[0] + 0.5) / 5 * 86 + 14).toString() + '%';
        }
    }

    abstract getData():Promise<Array<PGCalendarElement>>

    abstract getEventStatusColor(elementData:PGCalendarElement)

    abstract getEventStatusIcon(elementData:PGCalendarElement)

    abstract getEventStatusTitle(elementData:PGCalendarElement)

    abstract getAvailableActions()

    abstract onElementAction(action:string, elementData:PGCalendarElement)
}
