import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pg-group-picker',
  templateUrl: './pg-group-picker.component.html',
  styleUrls: ['./pg-group-picker.component.scss']
})
export class PgGroupPickerComponent implements OnInit {

    selectOptions:Array<{ value: string, text: string }> = []
    selectedValue:string = null;

    constructor(public modal:NgbActiveModal, private authService:AuthService) { }

    ngOnInit(): void {
        this.selectOptions.push({ value: '', text: '-' })

        for(let _group of this.authService.user.groups) {
            this.selectOptions.push({ value: _group.id, text: _group.id + ' - ' + _group.label})
        }
    }

}
