import { Component, Input, OnChanges } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

export class PGGraphData {
    constructor(public label: string|SafeHtml, public value: number) {}
}

@Component({
    selector: 'app-pg-graph',
    templateUrl: './pg-graph.component.html',
    styleUrls: ['./pg-graph.component.scss']
})
export class PgGraphComponent implements OnChanges {

    @Input() dataSet:Array<PGGraphData>;
    @Input() graphMode:'Bars'|'Line'|'Pie';
    @Input() modeParams:any;

    constructor() { }

    percentValues:Array<number> = null;
    offsetValues:Array<number> = null;
    sumValue:number = null;

    scaleValues:Array<number> = null;

    rebuildGraph = false;

    private _rebuildGraphTimeout = null;

    ngOnChanges(simpleChanges:any) {
        if(this.graphMode == null) this.graphMode = 'Bars';
        if(this.modeParams == null) this.modeParams = {}

        this.sumValue = 0;
        this.offsetValues = [];

        let _maxVal = null;
        let _minVal = null;

        for(let _cData of this.dataSet) {
            this.offsetValues.push(this.sumValue);
            this.sumValue += _cData.value;

            if(_maxVal == null || _maxVal < _cData.value) _maxVal = _cData.value;
            if(_minVal == null || _minVal > _cData.value) _minVal = _cData.value;
        }

        this.scaleValues = [
            Math.ceil(_maxVal),
            null,
            Math.floor(_minVal)
        ]

        this.percentValues = [];

        for(let _cData of this.dataSet) {
            this.percentValues.push((_cData.value - _minVal) / (_maxVal - _minVal));
        }

        if(simpleChanges.dataSet || simpleChanges.graphMode) {

            if(this._rebuildGraphTimeout != null) clearTimeout(this._rebuildGraphTimeout);

            this.rebuildGraph = true;

            this._rebuildGraphTimeout = setTimeout(() => {
                this.rebuildGraph = false;
                this._rebuildGraphTimeout = null;
            }, 100)
        }
    }

    getGraphLinePath(closePath?:boolean) {
        let _retVal = '';

        let _pointList:Array<{ x: number, y: number }> = [];

        for(let i = 0; i < this.dataSet.length; i++) {
            _pointList.push({ 
                x: (i + 0.5) / this.dataSet.length * 100, 
                y: 90 - this.percentValues[i] * 82.5
            });
        }

        if(closePath) {
            _retVal += 'M ' + _pointList[0].x + ',100 ';
            _retVal += 'L ' + _pointList[0].x + ',' + _pointList[0].y + ' ';
        }
        else {
            _retVal += 'M ' + _pointList[0].x + ',' + _pointList[0].y + ' ';
        }

        for(let i = 1; i < _pointList.length; i++) {
            let _fromPoint = _pointList[i - 1];
            let _toPoint = _pointList[i];

            if(this.modeParams.smooth) {
                let _prevPoint = _pointList[i - 2];
                if(_prevPoint == null) _prevPoint = _pointList[i - 1];

                let _nextPoint = _pointList[i + 1];
                if(_nextPoint == null) _nextPoint = _pointList[i];

                let _xDiff = _toPoint.x - _fromPoint.x;
                
                let _y1Diff = _toPoint.y - _prevPoint.y;
                let _y2Diff = _nextPoint.y - _fromPoint.y;

                let _controlPoints = [{ 
                    x: _fromPoint.x + _xDiff / 3,
                    y: _fromPoint.y + _y1Diff / 6
                }, { 
                    x: _toPoint.x - _xDiff / 3,
                    y: _toPoint.y - _y2Diff / 6
                }]

                _retVal += 'C ' + 
                    _controlPoints[0].x + ',' + _controlPoints[0].y + ' ' +
                    _controlPoints[1].x + ',' + _controlPoints[1].y + ' ' +
                    _toPoint.x + ',' + _toPoint.y + ' ';
            }
            else {
                _retVal += 'L ' + 
                    _toPoint.x + ',' + _toPoint.y + ' ';
            }
        }

        if(closePath) _retVal += 'L ' + _pointList[_pointList.length - 1].x + ',100 Z';
        return _retVal
    }

    getGraphPieRotation(index:number, centered?:boolean) {
        let _cRot = this.offsetValues[index];

        if(centered) _cRot += this.dataSet[index].value / 2;

        return _cRot * 360 / this.sumValue;
    }

    getGraphPiePath(index:number) {
        let _cPerc = this.dataSet[index].value / this.sumValue;

        let _cX = Math.sin(_cPerc * Math.PI * 2) * 170;
        let _cY = -Math.cos(_cPerc * Math.PI * 2) * 170;

        return 'M 0,-170 A 170,170,0,' + (_cPerc > 0.5 ? '1' : '0') + ',1,' + _cX + ',' + _cY + ' L 0,0 Z'
    }

    getGraphPieLabelTransform(index:number) {
        let _cRotation = this.getGraphPieRotation(index, true);

        let _translateValY = - 5 * Math.cos(_cRotation / 180 * Math.PI);
        let _translateValX = - Math.abs(_translateValY);
        if(_cRotation > 180) _translateValX *= -1;
        
        return 'rotate(-' + _cRotation + ') translate(' + _translateValX + ',' + _translateValY + ')'
    }
}
