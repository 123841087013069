<div *ngIf="blockList != null && blockList.length > 0" class="PGShowcase">
    <div *ngFor="let block of blockList; let i = index;" class="PGShowcase-Block PGShowcase-Block--Size{{block.length}} PGShowcase-Block--Mod{{i % 2}}" [style.zIndex]="blockList.length - i + 1">
        <div class="PGShowcase-Block-Inner">
            <ng-container *ngFor="let element of block; let j = index;">
                <div class="PGShowcase-Block-Element" [style.animationDelay]="(getGlobalPosition(i, j) * 0.1) + 's'" [style.zIndex]="block.length - j + 1" (click)="toggleElementExpanded(i, j)">
                    <div class="PGShowcase-Block-Element-Inner">
                        <div class="PGShowcase-Block-Element-Image"><img src="{{element.image}}"/></div>
                        <div class="PGShowcase-Block-Element-Title">{{element.title}}</div>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngIf="isElementExpandedInBlock(i)">
            <ng-container *ngFor="let element of block; let j = index;">
                <div *ngIf="isElementExpanded(i, j)" class="PGShowcase-Block-Inner PGShowcase-Block-Inner--Expanded PGShowcase-Block-Inner--Expanded{{j}}">
                    <div class="PGShowcase-Block-Element" (click)="toggleElementExpanded(i, j)">
                        <div class="PGShowcase-Block-Element-Inner">
                            <div class="PGShowcase-Block-Element-Image">
                                <img src="{{element.image}}"/>
                            </div>
                            <div class="PGShowcase-Block-Element-Text">
                                <div class="PGShowcase-Block-Element-Text-Title">{{element.title}}</div>
                                <div *ngIf="element.description != null && element.description != ''" class="PGShowcase-Block-Element-Text-Description text-muted">
                                    <div [innerHTML]="element.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>