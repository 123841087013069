import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EnvironmentService } from '../../services/environment.service';

@Component({
  selector: 'app-pg-customer-care-settings',
  templateUrl: './pg-customer-care-settings.component.html',
  styleUrls: ['./pg-customer-care-settings.component.scss']
})
export class PgCustomerCareSettingsComponent implements OnInit {

    constructor(private environmentService:EnvironmentService, private localizationService:LocalizationService, private authService:AuthService, private modalService:NgbModal) { }

    hasCustomerCare = true;

    customerCareLanguageOptions:Array<{ value: string, text: string }> = null;

    ngOnInit(): void {
        this.hasCustomerCare = (this.environmentService.isWebsocketServiceEnabled('chat') && this.authService.profile != null && typeof this.authService.profile.customer_care != 'undefined')
    
        this.customerCareLanguageOptions = [];
            
        for(let _language of this.localizationService.availableApplicationLanguages) {
            this.customerCareLanguageOptions.push({ value: _language, text: this.localizationService.languageLabels[_language] })
        }
    }

    getUserAvailability() {
        if(this.authService.isLoggedIn()) { 
            let _retVal = this.localizationService.format.timetableSlots(this.authService.availability);

            if(_retVal == '') _retVal = '<span class="text-muted">' + this.localizationService.translate('pg-user-menu.availability-none') + '</span>'

            return _retVal;
        }
    }

    @ViewChild('modalEditAvailability') modalEditAvailability:ElementRef;

    private _modalEditAvailabilityRef:NgbModalRef = null;

    editAvailability:string = null;

    isSavingUserAvailability = false;

    doEditAvailability() {
        this.editAvailability = JSON.stringify(this.authService.availability.toData());

        this._modalEditAvailabilityRef = this.modalService.open(this.modalEditAvailability)
    }

    cancelEditAvailability() {
        this._modalEditAvailabilityRef.dismiss()
    }

    saveEditAvailability() {
        if(!this.isSavingUserAvailability) {
            this.isSavingUserAvailability = true;
    
            this.authService.setUserAvailability(JSON.parse(this.editAvailability)).then((data) => {
                this.isSavingUserAvailability = false;
                this._modalEditAvailabilityRef.close()
            }, () => {
                this.isSavingUserAvailability = false;
            })
        }
    }

    getUserCustomerCare() {
        if(this.authService.isLoggedIn() && this.authService.profile != null) { 
            return this.authService.profile.customer_care; 
        }
    }

    isSettingCustomerCare = false;

    canSetCustomerCare() {
        return this.authService.isLoggedIn() && !this.isSettingCustomerCare;
    }

    setUserCustomerCare(val:boolean) {
        if(this.canSetCustomerCare()) {
            this.isSettingCustomerCare = true;
            this.authService.setUserProfile({ customer_care: val }).then((data) => {
                this.isSettingCustomerCare = false;
            })
        }
    }

    getUserCustomerCareLanguages() {
        if(this.authService.isLoggedIn() && this.authService.profile != null) { 
            return this.authService.profile.customer_care_languages; 
        }
    }

    setUserCustomerCareLanguages(val:string) {
        if(this.canSetCustomerCare()) {
            this.isSettingCustomerCare = true;
            this.authService.setUserProfile({ customer_care_languages: val }).then((data) => {
                this.isSettingCustomerCare = false;
            })
        }
    }
}
