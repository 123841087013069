import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { PGUtilities } from '../../pg-utilities';

@Component({
    selector: 'app-pg-url',
    templateUrl: './pg-url.component.html',
    styleUrls: ['./pg-url.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgUrlComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgUrlComponent),
        multi: true,
    }]
})
export class PgUrlComponent implements OnInit, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly: boolean;
    @Input() required: boolean;

    value:string

    constructor() { }

    ngOnInit(): void {
    }

    onValueChange() {
        if(this._onTouched) this._onTouched();
        if(this._onChange) this._onChange(this.value);
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
    }

    // INTERFACCIA Validator

    validate() {
        if(this.value == null || this.value == '') {
            if(this.required) {
                return {
                    required: {
                        valid: false
                    }
                } 
            }
        }
        else {
            if(!PGUtilities.urlRegExp.test(this.value)) {
                return {
                    format: {
                        valid: false
                    }
                } 
            }
        }
    };
}
  