<div class="PGDetail card-text">
    <ng-container *ngFor="let formGroup of formLayout.formGroups">
        <ng-container *ngIf="formLayout.isGroupVisible(formGroup)">
            <div *ngIf="formGroup.fields != null" class="PGDetail-Group">
                <div *ngIf="formGroup.title != null &amp;&amp; formGroup.title != ''" class="row PGDetail-Group-Head">
                    <div class="col-12">
                        <h4>{{formGroup.title | locTranslate}}</h4>
                        <hr/>
                    </div>
                </div>
                <div class="row PGDetail-Group-Body">
                    <div *ngIf="formGroup.description != null" class="col col-12 mb-3" [innerHTML]="formGroup.description | locTranslate"></div>
                    <ng-container *ngFor="let groupField of formGroup.fields">
                        <ng-container *ngIf="formLayout.getFieldByName(groupField.name); let fieldData;">
                            <div *ngIf="isFieldVisible(groupField)" class="col col-{{formLayout.getFieldWidth(groupField)}}">
                                <div class="PGDetail-Group-Element">
                                    <app-pg-detail-field [fieldData]="fieldData" [value]="getValueForField(fieldData)"></app-pg-detail-field>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>