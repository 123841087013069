import { Component, OnChanges, Input, forwardRef, ViewChild } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { DataFilter, DataService } from '../../services/data.service';

export class PgSuggestOption {
    value:string;
    category?:string;
    disabled?:boolean;
    hidden?:boolean;
    data?:any;

    view?:string;
    filtered?:boolean;
}

@Component({
    selector: 'app-pg-suggest',
    templateUrl: './pg-suggest.component.html',
    styleUrls: ['./pg-suggest.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgSuggestComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgSuggestComponent),
        multi: true,
    }]
})
export class PgSuggestComponent implements OnChanges, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input()
    readonly: boolean;

    @Input()
    required: boolean;

    @Input()
    options: Array<PgSuggestOption>;

     /**
     * opzionale: filtro opzioni
     */

    @Input()
    optionsFilter: Array<DataFilter>;

    /**
     * opzionale: risorsa da cui caricare gli elementi
     */

    @Input()
    resource: string;

    /**
     * opzionale: select semantic per mappare la risorsa
     */

    @Input()
    resourceSemantic: string;

    /**
     * opzionale: minima lunghezza stringa per ricerca
     */

    @Input()
    searchMin: number;

    /**
     * opzionale: opzioni di visualizzazione
     */

    @Input()
    display:any;

    isIncomplete = null;
    isLoading = null;
    isDropdownLoading = false;

    private _value:string = null;

    get value(): string { return this._value; }
    set value(val:string) {
        if (val !== this._value) {
            this._value = val;
            this.doSearch(this.resource != null ? 500 : 250);
        }
    }

    constructor(private dataService:DataService) { }

    private _getSelectOptionsFromResourceData(data:Array<any>, noLimit?:boolean) {
        let _retVal = {
            complete:<boolean> null,
            options:<Array<{ value: string }>> []
        }

        let _replaceMatches = null;
        if(this.resourceSemantic != null) _replaceMatches = this.resourceSemantic.match(/\{\{[^\{]*\}\}/g);

        let _retLength = data.length;

        if(noLimit) {
            _retVal.complete = true;
        }
        else {
            _retVal.complete = data.length <= this.dataService.selectLimit;
            _retLength = Math.min(this.dataService.selectLimit, data.length);
        }

        for(let i = 0; i < _retLength; i++) {
            let _cValue:string = null;

            if(this.resourceSemantic != null) {
                _cValue = this.resourceSemantic;

                for(let _cMatch of _replaceMatches) {
                    _cValue = _cValue.replace(_cMatch, data[i][_cMatch.substr(2, _cMatch.length - 4)])
                }
            }
            else {
                _cValue = data[i].id;

                for(let j in data[i]) {
                    if(j != 'id') {
                        _cValue += ' - ' + data[i][j];
                        break;
                    }
                }
            }

            _retVal.options.push({
                value: _cValue
            })
        }

        return _retVal;
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj: any) {
        this.value = obj;
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate() {
        return (!this.required || (this.value !== '' && this.value != null))  ? null : {
            required: {
                valid: false
            }
        }
    };

    ngOnChanges(simpleChanges) {
        if(this.resource != null && simpleChanges.resource) {
            this.doSearch();
        }
    }

    _searchTimeout = null;
    _searchObserver = null;

    doSearch(delay?:number) {
        if(this._searchTimeout != null) {
            clearTimeout(this._searchTimeout);
            this._searchTimeout = null;
        }

        if(this._searchObserver != null) {
            this._searchObserver.unsubscribe();
            this._searchObserver = null;
        }
        
        if(this.searchMin != null && (this.value == null || this.value.length < this.searchMin)) {
            this.options = [];

            this.isIncomplete = false;
            this.isDropdownLoading = false;
        }
        else {
            if(delay == null) delay = 10;

            this.isDropdownLoading = true;

            let _searchVal = this.value;
    
            this._searchTimeout = setTimeout(() => {
                this._searchTimeout = null;
    
                if(this.resource != null) { // ricerca server
                    this._searchObserver = this.dataService.getResourceData(this.resource, { 
                        search:_searchVal, 
                        limit: this.dataService.selectLimit + 1 
                    }).subscribe(data => {
                        let _selectOptions = this._getSelectOptionsFromResourceData(data);
        
                        this._searchObserver = null;
                        
                        this.options = _selectOptions.options;
        
                        this._highlightSearch();
        
                        this.isIncomplete = !_selectOptions.complete;
                        this.isDropdownLoading = false;

                    })
                }
                else {
                    if(this.value != null && this.value != '') {
                        let _searchRegExp = new RegExp('(' + this.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')', 'i');

                        for(let _cOption of this.options) {
                            _cOption.filtered = !_searchRegExp.test(_cOption.value)
                        }
                    }
                    else {
                        for(let _cOption of this.options) {
                            _cOption.filtered = false;
                        }
                    }

                    this.isDropdownLoading = false;

                    this._highlightSearch();
                }
            }, delay)
        }
    }

    private _highlightSearch() {
        if(this.value != null && this.value != '') {
            let _searchRegExp = new RegExp('(' + this.value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + ')', 'i');

            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].value.replace(_searchRegExp, '<b>$1</b>')
            }
        }
        else {
            for(let i = 0; i < this.options.length; i++) {
                this.options[i].view = this.options[i].value;
            }
        }
    }

    hasFocus = false;
    isDropdownClosing = false;
    isDropdownOpen = false;

    @ViewChild(NgbDropdown, { static: false }) dropdownComponent:NgbDropdown;

    private _blurTimer = null;

    onInputFocus() {
        this.hasFocus = true;
        this.isDropdownOpen = true;
        this.isDropdownClosing = false;

        clearTimeout(this._blurTimer);
        
        this.dropdownOpen();
    }

    onInputBlur() {
        this.hasFocus = false;

        clearTimeout(this._blurTimer);

        this._blurTimer = setTimeout(() => {
            this.isDropdownClosing = true;
            this._blurTimer = setTimeout(() => {
                this.isDropdownClosing = false;
                this.isDropdownOpen = false;
                this.dropdownClose();
            }, 250);
        }, 100);
    }

    dropdownOpen() {
        if(!this.readonly) {
            if(this.dropdownComponent != null) {
                this.dropdownComponent.open()
            }
        }
    }

    dropdownClose() {
        if(this.dropdownComponent != null) {
            this.dropdownComponent.close();
        }
    }

    isOptionDisabled(cOption:PgSuggestOption) {
        if(cOption.disabled) {
            return true;
        }
    }

    isOptionSelected(cOption:PgSuggestOption) {
        return cOption.value == this.value;
    }

    selectOption(cOption:PgSuggestOption, keepOpen?:boolean) {
        if(cOption == null || !cOption.disabled) {

            this.setValue(cOption.value)

            if(!keepOpen) {
                setTimeout(() => {
                    this.dropdownClose();
                }, 10);
            }
        }
    }

    setValue(val:string) {
        let _didChange = this.value != val;
        this.value = val

        if(_didChange) {
            if(this._onChange != null) this._onChange(this.value);
        }
    }

    // interazione via tastiera

    onKeyPress(eventObj:KeyboardEvent) {
        if(eventObj.keyCode == 38) {
            // arrow up
            eventObj.preventDefault();
            eventObj.stopPropagation();

            if(this.value == null || this.value === '') {
                this.selectOption(this.options[this.options.length - 1], true);
            }
            else {
                let _cIndex = -1;
                
                for(let i = 0; i < this.options.length; i++) {
                    if(this.options[i].value == this.value) {
                        _cIndex = i;
                        break;
                    }
                }

                _cIndex--;

                if(_cIndex < 0) {
                    _cIndex = this.options.length - 1;
                }

                this.selectOption(this.options[_cIndex], true);
            }
        }
        else if(eventObj.keyCode == 40) {
            // arrow down
            eventObj.preventDefault();
            eventObj.stopPropagation();

            if(this.value == null || this.value === '') {
                this.selectOption(this.options[0], true);
            }
            else {
                let _cIndex = -1;
                
                for(let i = 0; i < this.options.length; i++) {
                    if(this.options[i].value == this.value) {
                        _cIndex = i;
                        break;
                    }
                }
                
                _cIndex = (_cIndex + 1) % this.options.length;

                this.selectOption(this.options[_cIndex], true);
            }
        }
    }

    showCategoryBefore(index:number) {
        if(this._visibleOptions[index].hidden || this._visibleOptions[index].filtered) return null;
        else {
            let _prevCategory = null;
            let _prevIndex = index - 1;

            while(_prevIndex > -1) {
                if(!this._visibleOptions[_prevIndex].hidden && !this._visibleOptions[_prevIndex].filtered) { 
                    _prevCategory = this._visibleOptions[_prevIndex].category;
                    break;
                }

                _prevIndex--;
            }

            if(this._visibleOptions[index].category != _prevCategory) return this._visibleOptions[index].category;
        }
    }

    private _visibleOptions = [];

    private _evaluateOptionsFilter(option:PgSuggestOption) {
        if(this.optionsFilter == null) return true;
        else {
            for(let _cFilter of this.optionsFilter) {
                if(_cFilter.operator == '==') {
                    if(option.data == null || option.data[_cFilter.field] != _cFilter.value[0]) {
                        return false;
                    }
                }
                else if (_cFilter.operator == '!=') {
                    if(option.data == null || option.data[_cFilter.field] == _cFilter.value[0]) {
                        return false;
                    }
                }
            }

            return true;
        }
    }

    getVisibleOptions() {
        // TODO: bisognerebbe gestirla in maniera migliore, questa chiamata viene effettuata dal template ed è pesantina
        // andrebbe spostato nella ngOnChanges e garantito che l'optionsFilters venga effettivamente cambiato ogni volta che c'è qualche modifica o a limite triggerarlo a mano da fuori
        if(this.options == null) return null;
        else {
            let _cVisibleOptions = [];

            for(let _cOption of this.options) {
                if(!_cOption.hidden && !_cOption.filtered && this._evaluateOptionsFilter(_cOption)) {
                    _cVisibleOptions.push(_cOption);
                }
            }
            
            if(this._visibleOptions.length != _cVisibleOptions.length || JSON.stringify(this._visibleOptions) != JSON.stringify(_cVisibleOptions)) {
                this._visibleOptions = _cVisibleOptions;
            }

            return this._visibleOptions;
        }
    }
}
