<div class="PGCardsList {{selectionMode != null ? 'PGCardsList--Selectable' : ''}}">
    <div class="PGCardsList-Scrollable">
        <div *ngFor="let element of dataRows" class="PGCardsList-Element pg-animation-slidedown" (click)="toggleSelection(element.id)">
            <div *ngIf="elementBadges != null" class="PGCardsList-Element-Badges">
                <ng-container *ngFor="let badge of elementBadges">
                    <span *ngIf="badge.isVisible(element)" 
                        class="badge bg-{{badge.color != null ? badge.color : 'primary'}} me-1">
                        <i *ngIf="badge.icon" class="{{badge.icon}}"></i>
                        <ng-container *ngIf="badge.icon != null && badge.label != null">&nbsp;</ng-container>
                        <span *ngIf="badge.label">{{badge.label}}</span>
                    </span>
                </ng-container>
            </div>
            <div class="PGCardsList-Element-Actions">
                <ng-container *ngIf="elementActions == null">
                    <button *ngIf="actions.edit" class="btn btn-sm btn-primary ms-1" (click)="editElement(element)"><i class="fa-regular fa-edit"></i></button>
                </ng-container>
                <ng-container *ngIf="elementActions != null">
                    <ng-container *ngFor="let action of elementActions">
                        <button *ngIf="action.children == null && (action.isVisible == null || action.isVisible(element))" 
                            class="btn btn-sm btn-{{action.color != null ? action.color : 'primary'}} ms-1" 
                            (click)="action.onAction(element)">
                            <i *ngIf="action.icon" class="{{action.icon}}"></i>
                            <span *ngIf="action.label">{{action.label}}</span>
                        </button>
                        <span *ngIf="action.children != null" ngbDropdown>
                            <button class="btn btn-sm btn-{{action.color != null ? action.color : 'primary'}} ms-1" ngbDropdownToggle>
                                <i *ngIf="action.icon" class="{{action.icon}}"></i>
                                <span *ngIf="action.label">{{action.label}}</span>
                            </button>
                            <div ngbDropdownMenu>
                                <ng-container *ngFor="let child of action.children">
                                    <button *ngIf="child.isVisible == null || child.isVisible(element)" class="dropdown-item btn {{child.color != null ? 'text-' + child.color : ''}}" (click)="child.onAction(element)">
                                        <i *ngIf="child.icon" class="{{child.icon}}"></i>
                                        <span *ngIf="child.label">{{child.label}}</span>
                                    </button>
                                </ng-container>
                            </div>
                        </span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="PGCardsList-Element-Card">
                <app-pg-semantic-view-card [resourceId]="resourceId" [elementData]="element" [useCache]="cache"></app-pg-semantic-view-card>
            </div>
        </div>
        <div class="PGCardsList-LoadMore">
            <app-pg-view-more [hidden]="isLoadingData || isAtEnd" (viewMore)="loadData()"></app-pg-view-more>
        </div>
        <div class="PGCardsList-Loading {{isLoadingData ? 'PGCardsList-Loading--Show' : '' }}">
            <app-pg-loading></app-pg-loading>
        </div>
    </div>
</div>