import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationService } from '../services/localization.service';

@Pipe({
    name: 'locTranslate'
})
export class LocTranslatePipe implements PipeTransform {

    constructor(private localizationService:LocalizationService) {}

    public transform(value:string, replaceData?:any): string {
        return this.localizationService.translate(value, replaceData); 
    }
}

@Pipe({
    name: 'locFormat'
})
export class LocFormatPipe implements PipeTransform {

    constructor(private localizationService:LocalizationService) {}

    public transform(value:string, type:string): string {
        if(this.localizationService.format[type] != null) {
            return this.localizationService.format[type](value); 
        }
    }
}

@Pipe({
    name: 'locIcon'
})
export class LocIconPipe implements PipeTransform {

    constructor(private localizationService:LocalizationService) {}

    public transform(value:string, variant?:string): string {
        return this.localizationService.icon(value, variant); 
    }
}