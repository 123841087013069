import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

export class NavtreeElement {
    id:string = null;

    name:string = null;
    type:'element'|'directory' = null;

    icon?:string = null;
    url?:string = null;
    fragment?:string = null;
    parentId?:string = null;
    expanded?:boolean = null;

    click?:Function = null;
}

class NavtreeDisplayElement extends NavtreeElement {

    parent?:NavtreeDisplayElement = null;
    children?:Array<NavtreeDisplayElement> = null;
    level?:number = 0;
    
    visible?:boolean = true;
    expanded?:boolean = false;
    active?:boolean = false;

    order?:number = null;

    constructor(fromData?:NavtreeElement, order?:number) {
        super();

        this.order = order;

        if(fromData != null) {
            for(let i in fromData) {
                this[i] = fromData[i];
            }
        }
    }
}

@Component({
  selector: 'app-pg-nav-tree',
  templateUrl: './pg-nav-tree.component.html',
  styleUrls: ['./pg-nav-tree.component.scss']
})
export class PgNavTreeComponent implements OnInit, OnChanges {

    @Input() elements:Array<NavtreeElement>;

    navList:Array<NavtreeDisplayElement> = [];

    constructor(private router: Router) { }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.refreshActive(event.urlAfterRedirects);
            }
        });
    }

    ngOnChanges() {
        this.initNavList();
    }

    initNavList() {
        this.buildTree();

        this.refreshActive(this.router.routerState.snapshot.url);
    }

    private _nodesById: {
        [id:string]: NavtreeDisplayElement
    } = null;

    private buildTree() {
        this.navList = [];

        for(let i = 0; i < this.elements.length; i++) {
            this.navList.push(new NavtreeDisplayElement(this.elements[i], i))
        }

        this._nodesById = {};

        let _navTreeRoot:Array<NavtreeDisplayElement> = [];

        for(let _cNavElement of this.navList) {
            this._nodesById[_cNavElement.id] = _cNavElement;
        }

        // per ogni node setto la reference al padre e ai figli

        for(let _cNavElement of this.navList) {
            let _cParentNode = this._nodesById[_cNavElement.parentId];

            if(_cParentNode != null) {
                _cNavElement.parent = _cParentNode;

                if(_cParentNode.children == null) _cParentNode.children = [];
                _cParentNode.children.push(_cNavElement);
            }
            else {
                _cNavElement.level = 0;
                _navTreeRoot.push(_cNavElement);
            }
        }

        // per ogni node setto il level, la visibilità e ordino i children

        let _sortFunction = (a:NavtreeDisplayElement, b:NavtreeDisplayElement) => {
            if(a.order != b.order) {
                if(b.order == null || a.order < b.order) return -1;
                else if(a.order == null || a.order > b.order) return 1;
            }
            else return 0;
        }

        _navTreeRoot.sort(_sortFunction);

        let _checkChildren:Array<NavtreeDisplayElement> = [];
        
        for(let _cChild of _navTreeRoot) {
            _checkChildren.push(_cChild);
        }

        while(_checkChildren.length > 0) {
            let _cChild = _checkChildren.pop();

            if(_cChild.parent != null) {
                _cChild.level = _cChild.parent.level + 1;
                _cChild.visible = null; // visible = null nasconde il nodo senza animazione
            }
            else {
                _cChild.visible = true;
            }

            if(_cChild.children != null) {
                _cChild.children.sort(_sortFunction);

                for(let _cChildChild of _cChild.children) {
                    _checkChildren.push(_cChildChild);
                }
            }
        }

        // ricreo la lista a partire dall'albero, basta andare depth-first

        this.navList = [];

        let _treeElements = [];
        
        for(let _cElement of _navTreeRoot) {
            _treeElements.push(_cElement);
        }

        while(_treeElements.length > 0) {
            let _cElement = _treeElements.shift();

            this.navList.push(_cElement);

            if(_cElement.children != null) {
                for(let i = _cElement.children.length - 1; i >= 0; i--) {
                    _treeElements.unshift(_cElement.children[i]);
                }
            }
        }

        for(let _element of this.navList) {
            if(_element.expanded) this.openNode(_element)
        }
    }

    refreshActive(cUrl:string) {
        for(let i = 0; i < this.navList.length; i++) {
            let _navUrl = this.navList[i].url;
            if(_navUrl != null && this.navList[i].fragment != null) {
                _navUrl += '#' + this.navList[i].fragment
            }

            if(_navUrl == cUrl) {
                this.navList[i].active = true;

                let _cNode = this.navList[i]

                while(_cNode != null) {
                    this.openNode(_cNode);
                    _cNode = _cNode.parent;
                }
            }
            else {
                this.navList[i].active = false;

                if(_navUrl != null) {
                    this.closeNode(this.navList[i]);
                }
            }
        }
    }

    elementClick(navElement:NavtreeDisplayElement) {
        if(navElement.click != null) navElement.click();
        if(navElement.url != null) this.router.navigate([navElement.url], { fragment: navElement.fragment }); 
        
        if(navElement.type == 'directory') {
            if(navElement.expanded) {
                this.closeNode(navElement);
            }
            else {
                this.openNode(navElement);
            }
        }
    }

    openNode(navElement:NavtreeDisplayElement) {
        navElement.expanded = true;

        if(navElement.children != null) {
            let _nodesToCheck:Array<NavtreeDisplayElement> = [];

            for(let _cChild of navElement.children) {
                _nodesToCheck.push(_cChild);
            }

            while(_nodesToCheck.length > 0) {
                let _cNode = _nodesToCheck.pop();
                _cNode.visible = true;

                if(_cNode.expanded && _cNode.children != null) {
                    for(let _cChild of _cNode.children) {
                        _nodesToCheck.push(_cChild);
                    }
                }
            }
        }
    }

    closeNode(navElement:NavtreeDisplayElement) {
        navElement.expanded = false;

        if(navElement.children != null) {
            let _nodesToCheck:Array<NavtreeDisplayElement> = [];

            for(let _cChild of navElement.children) {
                _nodesToCheck.push(_cChild);
            }

            while(_nodesToCheck.length > 0) {
                let _cNode = _nodesToCheck.pop();
                _cNode.visible = false;

                if(_cNode.children != null) {
                    for(let _cChild of _cNode.children) {
                        _nodesToCheck.push(_cChild);
                    }
                }
            }
        }
    }
}
