import { Component, Input, OnInit } from '@angular/core';
import { PGUtilities } from '../../../pg-utilities';
import { LocalizationService } from '../../../services/localization.service';
import { PGAccommodation } from '../../../models/booking.model';
import { TicketsData } from '../../../models/tickets.model';

@Component({
  selector: 'app-pg-product-host-accommodation',
  templateUrl: './pg-product-host-accommodation.component.html',
  styleUrls: ['./pg-product-host-accommodation.component.scss']
})
export class PgProductHostAccommodationComponent implements OnInit {

    @Input() accommodationData:PGAccommodation;

    constructor(private localizationService:LocalizationService) { }

    ngOnInit(): void {
    }

    getAccommodationDescription() {
        if(this.accommodationData.accommodation_description != null) {
            return this.localizationService.getTranslatedValue(PGUtilities.tryParseJSON(this.accommodationData.accommodation_description))
        }
    }

    getAccommodationServices() {
        let _retVal = '';

        if(this.accommodationData.accommodation_type == 'room') {
            if(this.accommodationData.accommodation_room_services != null) {
                let _cServices:Array<string> = PGUtilities.tryParseJSON(this.accommodationData.accommodation_room_services);

                for(let _cService of _cServices) {
                    if(_retVal != '') _retVal += ', '
                    _retVal += this.localizationService.translate('OPTIONMAPS.Host.room_services.' + _cService)
                }
            }
        }
        else if(this.accommodationData.accommodation_type == 'apartment') {
            if(this.accommodationData.accommodation_appartaments_services != null) {
                let _cServices:Array<string> = PGUtilities.tryParseJSON(this.accommodationData.accommodation_appartaments_services);

                for(let _cService of _cServices) {
                    if(_retVal != '') _retVal += ', '
                    _retVal += this.localizationService.translate('OPTIONMAPS.Host.appartaments_services.' + _cService)
                }
            }
        }

        return _retVal;
    }

    getAccommodationPrices() {
        let _retVal = null;

        if(this.accommodationData.accommodation_prices != null) {
            let _cObject:TicketsData = PGUtilities.tryParseJSON(this.accommodationData.accommodation_prices)
            _retVal = this.localizationService.translate('pg-tickets-select.label-full-price-single') + ' ' + _cObject.fullPrice + ' &euro;'

            if(_cObject.otherTickets != null) {
                for(let _cTicket of _cObject.otherTickets) {
                    let _cName:string = null;

                    if(_cTicket.name != null) {
                        if(typeof _cTicket.name == 'string') {
                            _cName = _cTicket.name;
                        }
                        else {
                            _cName = this.localizationService.getTranslatedValue(_cTicket.name);
                        }
                    }

                    if(_cName != null) {
                        _retVal += '<br/>' + _cName + ' ' + _cTicket.price + ' &euro;'
                    }
                }
            }
        }

        return _retVal;
    }
}
