<div *ngIf="isLoadingRoles || isSaving" class="PGAdmin-Loading">
    <app-pg-loading [isLarge]="true" [isOverlay]="true"></app-pg-loading>
</div>
<div class="PGAdminUsers">
    <h4 class="m-0 pt-3">Users</h4>
    <div>
        <div class="PGAdmin-Header navbar row">
            <div class="col col-12">
                <div class="input-group">
                    <div class="input-group-text">
                        <i class="fa-regular fa-search"></i>
                    </div>
                    <input class="form-control ng-untouched ng-pristine ng-valid" placeholder="Search..." type="text" [(ngModel)]="search" (change)="onSearchChange()">
                </div>
            </div>
            <!--TODO: rimuovere questa parte? ora va coi realm-->
            <!--
            <div class="col col-2 text-end font-weight-normal" style="text-transform: none;">
                Filter by role
            </div>
            <div class="col col-4 font-weight-normal" style="text-transform: none;">
                <select *ngIf="!isLoadingRoles" class="form-control" [(ngModel)]="rolesValue" (ngModelChange)="onSearchChange()">
                    <option value="">-</option>
                    <option *ngFor="let option of rolesOptions" value="{{option.value}}">{{option.text}}</option>
                </select>
            </div>
            -->
        </div>
        <div class="container-fluid">
            <div class="row PGAdmin-Header">
                <div class="col col-1">id</div>
                <div class="col">name</div>
                <div class="col">password</div>
                <div class="col">email</div>
                <div class="col">telephone</div>
                <div class="col PGAdminUsers-Col-Buttons"></div>
            </div>
        </div>
        <div class="container-fluid PGAdminUsers-Content">
            <ng-container *ngFor="let user of dataRows">
                <div class="row">
                    <div class="col col-1 PGAdminUsers-Col-Text">{{user.id}}</div>
                    <div class="col">
                        <input class="form-control" [(ngModel)]="user.name" [readonly]="!actions.edit || isElementProtected(user)"/>
                    </div>
                    <div class="col">
                        <input class="form-control" [(ngModel)]="user.password" [readonly]="!actions.edit || isElementProtected(user)"/>
                    </div>
                    <div class="col">
                        <input class="form-control" [(ngModel)]="user.email" [readonly]="!actions.edit || isElementProtected(user)"/>
                    </div>
                    <div class="col">
                        <input class="form-control" [(ngModel)]="user.telephone" [readonly]="!actions.edit || isElementProtected(user)"/>
                    </div>
                    <div class="col PGAdminUsers-Col-Buttons">
                        <button class="btn btn-sm btn-primary me-1" (click)="saveUser(user)" [disabled]="!actions.edit || isElementProtected(user)">
                            <i class="fa-regular fa-save"></i>
                        </button>
                        <app-pg-confirm-action (confirmed)="deleteUser(user)" [disabled]="!actions.delete || isElementProtected(user)">
                            <button class="btn btn-sm btn-danger" [disabled]="!actions.delete || isElementProtected(user)">
                                <i class="fa-regular fa-trash"></i>
                            </button>
                        </app-pg-confirm-action>
                    </div>
                </div>
                <!--TODO: rimuovere questa parte? ora va coi realm-->
                <!--<div class="row">
                    <div class="col col-1 PGAdminUsers-Col-Text">
                        <b>ROLES</b>
                    </div>
                    <div class="col">
                        <app-pg-resource-association resourceId="Role" relatedResource="User" [relatedElement]="user.id" [relationConfig]="{
                            type: 'N:N',
                            modelA: 'User',
                            modelB: 'Role',
                            joinTable: 'RoleUser'
                        }" [resourceSemantic]="idNameSemantic" [resourceData]="user.related_role" [readonly]="isElementProtected(user)"></app-pg-resource-association>
                    </div>
                </div>
                <div class="row border-bottom">
                    <div class="col col-1 PGAdminUsers-Col-Text">
                        <b>GROUPS</b>
                    </div>
                    <div class="col">
                        <app-pg-resource-association resourceId="Group" relatedResource="User" [relatedElement]="user.id" [relationConfig]="{
                            type: 'N:N',
                            modelA: 'User',
                            modelB: 'Group',
                            joinTable: 'UserGroup'
                        }" [resourceSemantic]="idLabelSemantic" [resourceData]="user.related_group" [readonly]="isElementProtected(user)"></app-pg-resource-association>
                    </div>
                </div>-->
            </ng-container>

            <app-pg-view-more [hidden]="isLoadingData || isAtEnd" (viewMore)="loadData()"></app-pg-view-more>
            <app-pg-loading *ngIf="isLoadingData"></app-pg-loading>
        </div>
        <div class="container-fluid PGAdmin-Footer">
            <div class="row">
                <div class="col col-1"></div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="createUserData.name" [readonly]="!actions.create"/>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="createUserData.password" [readonly]="!actions.create"/>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="createUserData.email" [readonly]="!actions.create"/>
                </div>
                <div class="col">
                    <input class="form-control" [(ngModel)]="createUserData.telephone" [readonly]="!actions.create"/>
                </div>
                <div class="col PGAdminUsers-Col-Buttons">
                    <button class="btn btn-sm btn-primary" [disabled]="!actions.create" (click)="createUser()">
                        <i class="fa-regular fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>