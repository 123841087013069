<div class="FormsEatery">
    <app-pg-loading *ngIf="isSaving || isLoading" [isOverlay]="true" [isLarge]="true"></app-pg-loading>
    <div *ngIf="!isLoading">
        <ng-container *ngIf="showImport">
            <app-forms-data-import (setImportData)="setImportData($event)"></app-forms-data-import>
        </ng-container>
        <div *ngIf="!showImport">
            <app-forms-main [notFound]="notFound" [inModal]="inModal" [importData]="importData" [formId]="formId" [formFlow]="formFlow" [flowState]="flowState" (flowStateChange)="onFlowStateChange($event)" [returnUrl]="returnUrl" [viewOnly]="viewOnly" (valuesChange)="onValuesChange($event)" (saveData)="onSaveData($event)" (deleteData)="onDeleteData($event)" (resetForm)="onResetForm()" (cancelEdit)="onCancelEdit()"></app-forms-main>
        </div>
    </div>
</div>
