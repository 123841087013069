import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';
import { EnvironmentService } from '../../services/environment.service';
import { LocalizationService } from '../../services/localization.service';
import { PgProfileEditorService } from '../pg-profile-editor.service';
import { PersistenceService } from '../../services/persistence.service';
import { Subscription } from 'rxjs';

export type PgLoginHandlerMode = 'default'|'otp'|'code';

@Component({
  selector: 'app-pg-login-handler',
  templateUrl: './pg-login-handler.component.html',
  styleUrls: ['./pg-login-handler.component.scss']
})
export class PgLoginHandlerComponent implements OnInit, OnDestroy {

    @Input() customMessage:string;
    @Input() otpMode:boolean;
    @Input() codeMode:boolean;

    availableModes:Array<PgLoginHandlerMode> = ['default'];
    currentMode:PgLoginHandlerMode = 'default';

    disablePhone = false;

    hasGoogleLogin = false;

    private _subscriptionList:Array<Subscription> = [];

    constructor(private authService:AuthService, private environmentService:EnvironmentService, private modalService:NgbModal, private pgProfileEditorService:PgProfileEditorService, private localizationService:LocalizationService, private persistenceService:PersistenceService) { }

    ngOnInit(): void {
        if(this.otpMode) {
            this.availableModes.push('otp')
        }

        if(this.codeMode) {
            if(this.environmentService.environment.LoginCodePath != null && this.environmentService.environment.LoginCodePath != '') {
                this.availableModes.push('code')
            }
        }

        this._subscriptionList.push(this.authService.statusChange.subscribe(() => {
            this.handleLoginStatus();  
        }))

        setTimeout(() => {
            this.handleLoginStatus();  
        }, 10)

        this.privacyPolicyURL = this.environmentService.environment.PrivacyPolicyURL;
        this.termsAndConditionsURL = this.environmentService.environment.TermsAndConditionsURL;

        this.disablePhone = this.environmentService.environment.DisableSMS;

        this._subscriptionList.push(this.localizationService.statusChange.subscribe(() => {
            if(this._loginModalRef != null) {
                this._loginModalRef.dismiss();
            }

            if(this._acceptModalRef != null) {
                this._acceptModalRef.dismiss();
            }
            
            setTimeout(() => {
                this.handleLoginStatus();
            }, 10)
        }))

        this.isLoading = true;

        let _http = new XMLHttpRequest();
        _http.open('HEAD', this.environmentService.environment.APIUrl + '/login/google/');
        _http.onreadystatechange = () => {
            if (_http.readyState === _http.DONE) {
                if(_http.status != 401) {
                    this.hasGoogleLogin = true;
                }

                this.isLoading = false;
            }
        };
        _http.send();
    }

    loadState() {
        this.currentMode = 'default';
        if(this.availableModes.indexOf('code') != -1) {
            this.currentMode = 'code';
        }
        else if(this.availableModes.indexOf('otp') != -1) {
            this.currentMode = 'otp';
        }

        let _savedString = this.persistenceService.getItem('PGLoginStatus')
        if(_savedString != null) {
            try {
                let _savedObj = JSON.parse(_savedString);
                if(_savedObj.mode == 'default') {
                    this.currentMode = 'default';
                    this.loginUsername = _savedObj.user;
                }
                else if(_savedObj.mode == 'otp') {
                    if(this.availableModes.indexOf('otp') != -1) {
                        this.currentMode = 'otp';
                        this.loginOTPRecipient = _savedObj.user;
                    }
                }
                else if(_savedObj.mode == 'code') {
                    if(this.availableModes.indexOf('code') != -1) {
                        this.currentMode = 'code';
                    }
                }
            }
            catch(ex) {
                console.log(ex)
            }
        }
    }

    saveState() {
        let _saveStatus = {
            mode: this.currentMode,
            user: null
        }

        if(this.currentMode == 'default') {
            _saveStatus.user = this.loginUsername;
        }
        else if(this.currentMode == 'otp') {
            _saveStatus.user = this.loginOTPRecipient;
        }

        this.persistenceService.setItem('PGLoginStatus', JSON.stringify(_saveStatus), 'technical');
    }

    ngOnDestroy(): void {
        for(let _subscription of this._subscriptionList) {
            _subscription.unsubscribe();
        }
        
        if(this._loginModalRef != null) {
            this._loginModalRef.dismiss();
        }

        if(this._acceptModalRef != null) {
            this._acceptModalRef.dismiss();
        }
    }

    isLoggedIn = false;
    supplierMode = false;
    userHasAcceptedPrivacyPolicy = false;
    userHasAcceptedTermsAndConditions = false;

    isLoading = false;

    @ViewChild('loginTemplate') loginTemplate: ElementRef;
    @ViewChild('acceptTemplate') acceptTemplate: ElementRef;

    private _loginModalRef:NgbModalRef = null;
    private _acceptModalRef:NgbModalRef = null;

    handleLoginStatus() {
        this.isLoggedIn = this.authService.isLoggedIn()
        this.supplierMode = false;
        this.userHasAcceptedPrivacyPolicy = false;
        this.userHasAcceptedTermsAndConditions = false;

        if(!this.isLoggedIn) {
            if(this._loginModalRef == null) {

                this.resetLoginFields()
                this.loadState();

                let _cOptions:NgbModalOptions = {
                    backdrop: 'static',
                    keyboard: false,
                    windowClass: 'PGLoginHandlerModal',
                    size: 'lg'
                }
    
                this._loginModalRef = this.modalService.open(this.loginTemplate, _cOptions);
                this._loginModalRef.result.then(() => {
                    this._loginModalRef = null;
                }, () => {
                    this._loginModalRef = null;
                })
            }
        }
        else {
            if(this._loginModalRef != null) {
                this._loginModalRef.dismiss();
            }

            this.supplierMode = this.authService.user.supplierMode;
            
            this.userHasAcceptedPrivacyPolicy = this.authService.user.hasAcceptedPrivacyPolicy();
            this.userHasAcceptedTermsAndConditions = this.authService.user.hasAcceptedTermsAndConditions();

            this.inputAcceptPrivacyPolicy = this.userHasAcceptedPrivacyPolicy;
            this.inputAcceptTermsAndConditions = this.userHasAcceptedTermsAndConditions;

            if(!this.canAccept()) {
                if(this._acceptModalRef == null) {
                    let _cOptions:NgbModalOptions = {
                        backdrop: 'static',
                        keyboard: false,
                        windowClass: 'PGLoginHandlerModal',
                        size: 'lg'
                    }
        
                    this._acceptModalRef = this.modalService.open(this.acceptTemplate, _cOptions);
                    this._acceptModalRef.result.then(() => {
                        this._acceptModalRef = null;
                    }, () => {
                        this._acceptModalRef = null;
                    })
                }
            }
            else {
                if(this._acceptModalRef != null) {
                    this._acceptModalRef.dismiss();
                }
            }
        }
    }

    resetLoginFields() {          
        this.loginError = false;
        
        this.loginUsername = null;
        this.loginPassword = null;

        this.loginOTPRecipient = null;
        this.loginOTPVia = null;
        this.loginOTPVerify = null;

        this.loginCode = null;
    }

    loginError:boolean = false;

    // default login

    onLoginDefaultKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            this.doLoginDefault();
        }
    }

    loginUsername:string = null;
    loginPassword:string = null;

    canLoginDefault() {
        return this.loginUsername != null && this.loginUsername != '' && this.loginPassword != null && this.loginPassword != ''
    }

    doLoginDefault() {
        if(this.canLoginDefault()) {
            this.isLoading = true;
            this.loginError = false;
    
            this.authService.login(this.loginUsername, this.loginPassword).then(() => {
                this.saveState();
                this.isLoading = false;
            }, () => {
                this.loginError = true;
                this.isLoading = false;
            })
        }
    }

    // otp login

    onLoginOTPKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            if(this.loginOTPSent) {
                this.doLoginOTP();
            }
            else {
                this.sendOTP();
            }
        }
    }

    loginOTPRecipient:string = null;
    loginOTPVia:'email'|'phone' = null;
    loginOTPVerify:string = null;

    loginOTPSendError:boolean = false;
    loginOTPSent:boolean = false;

    canSendOTP() {
        return this.loginOTPRecipient != null && this.loginOTPRecipient != '' && this.loginOTPVia != null;
    }

    sendOTP() {
        this.isLoading = true;
        this.loginError = false;
        this.loginOTPSendError = false;

        this.isLoading = false;
        this.loginOTPSent = true;

        this.authService.requestOTP(this.loginOTPRecipient, this.loginOTPVia).then(() => {
            this.isLoading = false;
            this.loginOTPSent = true;
        }, () => {
            this.isLoading = false;
            this.loginOTPSent = false;
            this.loginOTPSendError = true;
        })
    }

    resetOTP() {
        this.loginOTPSent = false;
        this.loginError = false;
        this.loginOTPSendError = false;
    }

    canLoginOTP() {
        return this.loginOTPVerify != null && this.loginOTPVerify != '';
    }

    doLoginOTP() {
        this.isLoading = true;
        this.loginError = false;

        this.authService.loginOTP(this.loginOTPRecipient, this.loginOTPVia, this.loginOTPVerify).then(() => {
            this.saveState();
            this.isLoading = false;
        }, () => {
            this.loginError = true;
            this.isLoading = false;
        })
    }

    // code login

    onLoginCodeKeyUp(event:KeyboardEvent) {
        if(event.key == 'Enter') {
            if(this.loginOTPSent) {
                this.doLoginOTP();
            }
            else {
                this.sendOTP();
            }
        }
    }

    loginCode:string = null;

    canLoginCode() {
        return this.loginCode != null && this.loginCode != '';
    }

    doLoginCode() {
        this.isLoading = true;
        this.loginError = false;

        this.authService.loginCode(this.loginCode).then(() => {
            this.saveState();
            this.isLoading = false;
        }, () => {
            this.loginError = true;
            this.isLoading = false;
        })
    }

    // accettazione

    inputAcceptPrivacyPolicy = false;
    inputAcceptTermsAndConditions = false;

    privacyPolicyURL:string = null;
    termsAndConditionsURL:string = null;

    canAccept() {
        return this.inputAcceptPrivacyPolicy && this.inputAcceptTermsAndConditions;
    }

    doAccept() {
        this.isLoading = true;
        
        this.authService.accept(this.inputAcceptPrivacyPolicy, this.inputAcceptTermsAndConditions).then(() => {
            this.isLoading = false;
        }, () => {
            this.isLoading = false;   
        })
    }

    // password recover

    passwordRecover = false;

    startPasswordRecover() {
        this.passwordRecover = true;
        this.resetLoginFields();
    }

    cancelPasswordRecover() {
        this.passwordRecover = false;
        this.resetLoginFields();
    }

    doPasswordRecover() {
        this.isLoading = true;
        this.loginError = false;

        this.authService.loginOTP(this.loginOTPRecipient, this.loginOTPVia, this.loginOTPVerify).then(() => {
            this.pgProfileEditorService.requestChangePassword();
            
            this.isLoading = false;
            this.passwordRecover = false;
        }, () => {
            this.loginError = true;
            this.isLoading = false;
        })
    }

    doGoogleLogin() {
        window.location.href = this.environmentService.environment.APIUrl + '/login/google/?returnUrl=' + encodeURIComponent(window.location.href)
    }
}
