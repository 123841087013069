import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { NgbTooltip, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pg-confirm-action',
  templateUrl: './pg-confirm-action.component.html',
  styleUrls: ['./pg-confirm-action.component.scss']
})
export class PgConfirmActionComponent implements OnInit {

    @Input() action:string;
    @Input() disabled:boolean;
    @Input() tooltipContainer:any;
    @Output() confirmed = new EventEmitter<any>();

    constructor(private tooltipConfig: NgbTooltipConfig) { }

    ngOnInit() {
        if(typeof this.tooltipContainer == 'undefined') {
            this.tooltipContainer = this.tooltipConfig.container;
        }
    }

    private _closeTimeout = null;

    closeWithDelay(t:NgbTooltip) {
        if(this._closeTimeout != null) clearTimeout(this._closeTimeout);

        this._closeTimeout = setTimeout(() => {
            this._closeTimeout = null;
            t.close();
        }, 500000)
    }

    keepOpen(t:NgbTooltip) {
        if(!this.disabled) {
            if(this._closeTimeout != null) clearTimeout(this._closeTimeout);

            if(!t.isOpen()) {
                t.open();
            }
        }
    }

    stopEvent(event:MouseEvent) {
        event.stopPropagation()
    }
}
