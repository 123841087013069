import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';


export class PdfSpan {
    x?:number;
    w?:number;
    weight?:'normal'|'bold';
    size?:number;
    font?:string;
    align?:'left'|'center'|'right'|'justify';
    text:string;
}

export class PdfLine {
    y?:number;
    weight?:'normal'|'bold';
    size?:number;
    font?:string;
    align?:'left'|'center'|'right'|'justify';
    text?:string;
    spans?:Array<PdfSpan>;
}

export class PdfBlock {
    pagebreak?:boolean;
    x?:number;
    y?:number;
    weight?:'normal'|'bold';
    size?:number;
    font?:string;
    lines:Array<PdfLine>;
    hr?:boolean;
}

export class PdfModel {
    font:string;
    size:number;
    line:number;
    margin:number;
    blocks:Array<PdfBlock>;
    footer?:string;
}

@Injectable({
    providedIn: 'root'
})
export class PdfGeneratorService {
    private _loadImages(index:{ [id:string]: string }) {
        return new Promise<{
            [id:string]: HTMLImageElement
        }>((resolve, reject) => {
            let _retVal = {}
    
            let _reqNum = 0;

            for(let i in index) {
                _reqNum++;

                _retVal[i] = new Image();

                _retVal[i].onload = () => {
                    _reqNum--;
                    if(_reqNum == 0) {
                        resolve(_retVal);
                    }
                }

                _retVal[i].onerror = () => {
                    _reqNum--;
                    if(_reqNum == 0) {
                        resolve(_retVal);
                    }
                }

                _retVal[i].src = index[i];
            }
        })
    }

    generateDocumentFromHTML(name:string, element:HTMLElement) {
        let _cModel = new PdfModel();

        _cModel.margin = 12.5;
        _cModel.size = 12;
        _cModel.line = 0.4;

        _cModel.blocks = []

        element.querySelectorAll('[pdfgen="block"]').forEach((element:HTMLElement) => {
            let _cBlock = new PdfBlock();
            _cBlock.lines = [];
            _cBlock.hr = true;
            
            _cModel.blocks.push(_cBlock)

            element.querySelectorAll('[pdfgen="line"]').forEach((element:HTMLElement) => {
                let _cLine = new PdfLine();
                _cLine.y = 5;

                for(let i = 0; i < element.attributes.length; i++) {
                    let _cName = element.attributes.item(i).name;

                    if(_cName.startsWith('pdfgen-')) {
                        _cLine[_cName.split('-')[1]] = element.attributes.item(i).value;
                    }
                }

                let _cH = element.getAttribute('pdfgen-h');
                
                if(_cH) {
                    if(_cH == '1') {
                        _cLine.size = 25;
                    }
                    else {
                        _cLine.size = 20;
                    }
                }

                element.querySelectorAll('[pdfgen="span"]').forEach((element:HTMLElement) => {
                    let _cSpan = new PdfSpan();
                    _cSpan.text = element.innerText;

                    let _cFloat = element.getAttribute('pdfgen-float');
                    
                    if(_cFloat == 'right') {
                        _cSpan.align = 'right';
                        _cSpan.x = 210 - _cModel.margin * 2;
                    }

                    if(_cLine.spans == null) _cLine.spans = [];
                    _cLine.spans.push(_cSpan);
                })

                if(_cLine.spans == null) {
                    _cLine.text = element.innerText.replace(/\n/g, ' ');
                }

                _cBlock.lines.push(_cLine)
            })
        })

        _cModel.blocks[_cModel.blocks.length - 1].hr = false;

        let _cDoc = this._generateDocument(_cModel);
        _cDoc.save(name + '.pdf');
    }

    generateDocument(name:string, document:PdfModel) {
        let _cDoc = this._generateDocument(document);
        _cDoc.save(name + '.pdf');
    }

    private _generateDocument(pdfModel:PdfModel) {
        let _cDoc = new jsPDF();

        let _cFontList = _cDoc.getFontList();

        let _baseFont = null;

        if(_cFontList['arial'] != null) _baseFont = 'arial';
        else if(_cFontList['helvetica'] != null) _baseFont = 'helvetica';

        _cDoc.setFontSize(pdfModel.size);

        let _cTop = pdfModel.margin;

        for(let _cBlock of pdfModel.blocks) {
            if(_cBlock.pagebreak) {
                _cDoc.addPage()
                _cTop = pdfModel.margin;
            }

            _cTop += _cBlock.y || 0;

            for(let _cLine of _cBlock.lines) {
                let _cLineAlign = _cLine.align || 'left';

                _cTop += _cLine.y || 0;

                let _cFontSize = _cLine.size || _cBlock.size || pdfModel.size;
                _cDoc.setFontSize(_cFontSize);

                _cDoc.setFont(_cLine.font || _cBlock.font || pdfModel.font || _baseFont, '', 
                _cBlock.weight || _cLine.weight || 'normal');

                if(_cLine.text != null) {
                    let _cLeft = pdfModel.margin + (_cBlock.x || 0);
                    let _cWidth = 210 - pdfModel.margin - _cLeft;

                    let _cWrapList = _cDoc.splitTextToSize(_cLine.text, _cWidth);

                    for(let _cWrap of _cWrapList) {
                        if(_cTop + pdfModel.line * _cFontSize > 297) {
                            _cTop = pdfModel.line * _cFontSize;
                            _cDoc.addPage();
                        }

                        _cDoc.text(_cWrap, _cLeft, _cTop, { 
                            align: _cLineAlign, 
                            lineHeightFactor: 1.25,
                            maxWidth: _cWidth 
                        });

                        _cTop += pdfModel.line * _cFontSize;
                    }
                }

                if(_cLine.spans != null) {
                    let _cSpanMaxWrap = 0;

                    for(let _cSpan of _cLine.spans) {
                        let _cFontSize = _cSpan.size || _cLine.size || _cBlock.size || pdfModel.size;
                        _cDoc.setFontSize(_cFontSize);
        
                        _cDoc.setFont(_cSpan.font || _cLine.font || _cBlock.font || pdfModel.font || _baseFont, '', 
                        _cSpan.weight || _cLine.weight || _cBlock.weight || 'normal');

                        let _cLeft = pdfModel.margin + (_cBlock.x || 0) + (_cSpan.x || 0);

                        let _cWidth = _cSpan.w;

                        if(_cWidth == null) {
                            if(_cSpan.align == 'right') {
                                _cWidth = _cLeft - pdfModel.margin;
                            }
                            else {
                                _cWidth = (210 - pdfModel.margin - _cLeft);
                            }
                        }

                        let _cWrapList = _cDoc.splitTextToSize(_cSpan.text, _cWidth);

                        let _cSpanTop = 0;

                        for(let _cWrap of _cWrapList) {
                            if(_cTop + pdfModel.line * _cFontSize > 297) {
                                _cTop = pdfModel.line * _cFontSize;
                                _cDoc.addPage();
                            }

                            _cDoc.text(_cWrap, _cLeft, _cTop + _cSpanTop, { 
                                align: _cSpan.align || _cLineAlign, 
                                lineHeightFactor: 1.25,
                                maxWidth: _cWidth 
                            });

                            _cSpanTop += pdfModel.line * _cFontSize;
                        }

                        _cSpanMaxWrap = Math.max(_cSpanMaxWrap, _cSpanTop);
                    }

                    _cTop += _cSpanMaxWrap;
                }
            }

            if(_cBlock.hr) {
                _cDoc.setLineWidth(0.25);

                _cDoc.setDrawColor(150, 150, 150);

                _cDoc.line(pdfModel.margin, _cTop, 210 - pdfModel.margin, _cTop);
                _cTop += pdfModel.line * pdfModel.size;
                _cTop += pdfModel.line * pdfModel.size;
            }
        }

        if(pdfModel.footer != null) {
            _cDoc.text(pdfModel.footer, pdfModel.margin, 295 - pdfModel.line);
        }

        return _cDoc;
    }
}