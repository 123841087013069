import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { PGProductData, PGProductType, PGSupplierData } from '../../../models/booking.model';
import { TicketsData } from '../../../models/tickets.model';
import { DataService } from '../../../services/data.service';
import { LocalizationService } from '../../../services/localization.service';
import { PdfGeneratorService } from '../../../services/pdf-generator.service';
import { PGLocation } from '../../../models/map.model';

export class PGProductCardData {
    id:string;
    name:string;
    price?:number;
    tickets?:TicketsData;
    abstract?:string;
    description?:string;
    location?:string;
    geolocation?:PGLocation;
    image?:string;
    payment?:Array<string>;

    supplier?:string;
    type?:PGProductType;

    price_on_request?:boolean;

    constructor(fromData?:PGProductData, language?:string) {
        if(fromData != null) {
            let _cImage = null;
            try {
                _cImage = fromData.images[0]
            }
            catch(ex) {}

            let _cLocation = null;
            if(fromData.city != null) {
                _cLocation = fromData.city;

                if(fromData.province != null) {
                    _cLocation += ' (' + fromData.province + ')'
                }
            }

            if(language != null && fromData.translations != null) {
                fromData = JSON.parse(JSON.stringify(fromData))

                for(let _cTranslation of fromData.translations) {
                    if(language == _cTranslation.language) {
                        for(let i in _cTranslation) {
                            fromData[i] = _cTranslation[i]
                        }
                    }
                }
            }

            this.id = fromData.id;
            this.name = fromData.title;
            this.description = fromData.description;
            this.tickets = fromData.tickets;
            this.payment = fromData.payment;
            this.image = _cImage;
            this.location = _cLocation;
            this.geolocation = fromData.geolocation;
            this.type = fromData.type;

            this.supplier = fromData.supplier;

            this.price_on_request = fromData.price_on_request;
        }
    }
}

export class PGProductCardSupplierData {
    id:string;
    name:string;
    logo?:string;
    description?:string;
    phone?:string;
    email?:string;
    website?:string;
    store_url?:string;

    translations:Array<{
        id:string,
        language:string,
        name: string,
        description: string
    }>

    constructor(fromData?:PGSupplierData, language?:string) {
        if(fromData != null) {
            this.id = fromData.id;
            this.logo = fromData.logo;

            this.phone = fromData.phone;
            this.email = fromData.email;
            this.website = fromData.website;
            this.store_url = fromData.store_url;

            if(language != null && fromData.translations != null) {
                fromData = JSON.parse(JSON.stringify(fromData))

                for(let _cTranslation of fromData.translations) {
                    if(language == _cTranslation.language) {
                        for(let i in _cTranslation) {
                            fromData[i] = _cTranslation[i]
                        }
                    }
                }
            }

            this.name = fromData.name;
            this.description = fromData.description;
        }
    }
}

@Component({
  selector: 'app-pg-product-card',
  templateUrl: './pg-product-card.component.html',
  styleUrls: ['./pg-product-card.component.scss']
})

export class PgProductCardComponent implements OnInit, AfterViewChecked {
    @Input() resourceId:string;
    @Input() elementId:string;
    @Input() productData:PGProductData;
    @Input() supplierData:PGSupplierData;

    @Input() discountVal:number;

    @Input() compact:boolean;

    productCardData:PGProductCardData = null;
    productCardSupplierData:PGProductCardSupplierData = null;

    isLoading = false;

    hasContent = false;

    constructor(private dataService:DataService, private localizationService:LocalizationService, private pdfGeneratorService:PdfGeneratorService) {}

    ngOnInit() {
        if(typeof this.productData == 'undefined') {
            if(this.resourceId != null && this.elementId != null) {
                this.isLoading = true;

                this.dataService.getElementData(this.resourceId, this.elementId, null, 'public').subscribe((data) => {
                    this.productData = data;

                    this._finishLoadProduct();
                    this._checkLoadSupplier();
                })
            }
        }
        else {
            this._finishLoadProduct();
            this._checkLoadSupplier();
        }
    }

    private _finishLoadProduct() {
        if(this.productData != null) {
            this.productCardData = new PGProductCardData(this.productData, this.localizationService.currentLanguage)
        }
    }

    private _checkLoadSupplier() {
        if(typeof this.supplierData == 'undefined' && this.productData != null && this.productData.supplier != null) {
            let _cSupplierSplit = this.productData.supplier.split('_');

            this.dataService.getElementData(_cSupplierSplit[0], _cSupplierSplit[1], null, 'public', true).subscribe((data) => {
                this.supplierData = data;

                this._finishLoadSupplier();
            })
        }
        else {
            this._finishLoadSupplier();
        }
    }

    private _finishLoadSupplier() {
        if(this.supplierData != null) {
            this.productCardSupplierData = new PGProductCardSupplierData(this.supplierData, this.localizationService.currentLanguage);
        }
    }

    @ViewChild('cardFooter') cardFooter:ElementRef;
    
    hasDescriptionCollapse:boolean = null;
    isDescriptionCollapsed:boolean = true;

    @ViewChild('cardDescription') cardDescription:ElementRef;

    private _hasContentTimeout = null;
    private _hasDescriptionCollapseTimeout = null;

    ngAfterViewChecked() {
        if(this.cardFooter != null) {
            let _currentHasContent = this.cardFooter.nativeElement.innerHTML.replace(/\s/g) != '';

            if(_currentHasContent != this.hasContent) {
                clearTimeout(this._hasContentTimeout);
                this._hasContentTimeout = setTimeout(() => {
                    this.hasContent = _currentHasContent;
                }, 10)
            }
        }

        if(this.cardDescription != null && this.hasDescriptionCollapse == null && this._hasDescriptionCollapseTimeout == null) {
            let _currentHasDescriptionCollapse = this.cardDescription.nativeElement.scrollHeight > this.cardDescription.nativeElement.offsetHeight;

            this._hasDescriptionCollapseTimeout = setTimeout(() => {
                this.hasDescriptionCollapse = _currentHasDescriptionCollapse;
            }, 10)
        }
    }

    hasProperty(val:any) {
        if(val == null || val == '') return false;
        else return true;
    }

    formatPrice(val:string|number, applyDiscount?:boolean) {
        if(typeof val == 'string') {
            val = parseFloat(val);
        }

        if(applyDiscount && this.discountVal != null) val *= (1 - this.discountVal);

        if(val == 0) {
            return this.localizationService.translate('pg-product-card.price-free');
        }
        else {
            return val.toFixed(2) + ' €'
        }
    }

    formatTickets(val:TicketsData, applyDiscount?:boolean) {
        if(applyDiscount && this.discountVal == 1) {
            return this.localizationService.translate('pg-product-card.price-free');
        }
        else {
            let _retVal = '';
            
            if(val.fullPrice != null) {
                let _numVal = val.fullPrice;
                if(applyDiscount && this.discountVal != null) _numVal *= (1 - this.discountVal);

                _retVal += this.localizationService.translate('pg-product-card.tickets-full-price') + ' ' + this.formatPrice(_numVal)
            }

            for(let _cOther of val.otherTickets) {
                let _numVal = _cOther.price;
                if(applyDiscount && this.discountVal != null) _numVal *= (1 - this.discountVal);

                let _cTicketName = '';

                if(typeof _cOther.name == 'string') _cTicketName = _cOther.name as string;
                else {
                    _cTicketName = this.localizationService.getTranslatedValue(_cOther.name);
                }
        
                if(_retVal != '') _retVal += '<br/>';
                _retVal += _cTicketName + ' ' + _numVal.toFixed(2) + ' €'
            }

            return _retVal;
        }
    }

    formatPayment(val:Array<string>) {
        let _retVal = '';

        if(val.indexOf('online') != -1) {
            if(_retVal != '') _retVal += ', ';
            _retVal += this.localizationService.translate('OPTIONMAPS.Experience.payment.online')
        }

        if(val.indexOf('sul_posto') != -1) {
            if(_retVal != '') _retVal += ', ';
            _retVal += this.localizationService.translate('OPTIONMAPS.Experience.payment.sul_posto')
        }

        if(val.indexOf('bonifico') != -1) {
            if(_retVal != '') _retVal += ', ';
            _retVal += this.localizationService.translate('OPTIONMAPS.Experience.payment.bonifico')
        }

        return _retVal
    }

    @ViewChild('productCardElement') productCardElement:ElementRef;

    downloadPDF() {
        this.pdfGeneratorService.generateDocumentFromHTML(this.productCardData.name, this.productCardElement.nativeElement)
    }

    isSupplierProduct() {
        return this.productData.type != 'experience';
    }

    private _isAndroid() {
        let _userAgent = navigator.userAgent || navigator.vendor;

        return /android/i.test(_userAgent);
    }
    private _isIOS() {
        let _userAgent = navigator.userAgent || navigator.vendor

        return /iPad|iPhone|iPod/.test(_userAgent);
    }

    getProductGeolocationURL() { // TODO: spostare in un service, codice replicato
        if(this._isAndroid()) {
            return 'google.navigation:q=' + this.productCardData.geolocation.coordinates[0] + ',' + this.productCardData.geolocation.coordinates[1];
        }
        else if(this._isIOS()) {
            return 'https://maps.apple.com/?q=' + encodeURIComponent(this.productCardData.name) + '&ll=' + this.productCardData.geolocation.coordinates[0] + ',' + this.productCardData.geolocation.coordinates[1];
        }
        else {
            return 'https://maps.google.com/?q=' + this.productCardData.geolocation.coordinates[0] + ',' + this.productCardData.geolocation.coordinates[1];
        }
    }

    getProductTypeIcon() {
        switch(this.productData.type) {
            case 'experience': return 'fa-star';
            case 'host': return 'fa-bed';
            case 'eatery': return 'fa-utentils';
        }
    }

    getProductTypeClass() {
        switch(this.productData.type) {
            case 'experience': return 'danger';
            case 'host': return 'warning';
            case 'eatery': return 'success';
        }
    }
}
