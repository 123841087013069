<div class="FormsImport">
    <div>
        <h2>{{ 'forms-data-import.title' | locTranslate }}</h2>
        <p>{{ 'forms-data-import.text-import' | locTranslate }}</p>
        <p>{{ 'forms-data-import.text-skip' | locTranslate }}</p>
        <app-forms-place-picker (valueChange)="placeData = $event;"></app-forms-place-picker>
    </div>
    <div class="pt-3">
        <span *ngIf="placeData == null">
            {{ 'forms-data-import.selection-empty' | locTranslate }}
        </span>
        <span *ngIf="placeData != null" [innerHTML]=" 'forms-data-import.selection-value' | locTranslate:{ name: placeData.name, address: placeData.formatted_address } ">
        </span>
    </div>
    <div class="FormsImport-Buttons pt-3">
        <button class="btn btn-primary" [disabled]="placeData == null" (click)="doImport()">
            <span>{{ 'forms-data-import.button-import' | locTranslate }}</span>
            <i class="fa-regular fa-cloud-download-alt"></i>
        </button>
        <button class="btn btn-secondary" (click)="skipImport()">
            <span>{{ 'forms-data-import.button-skip' | locTranslate }}</span>
            <i class="fa-regular fa-forward"></i>
        </button>
    </div>
</div>