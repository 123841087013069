import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PgLoadingComponent } from './pg-loading/pg-loading.component';
import { PgCarouselComponentSlide, PgCarouselComponent } from './pg-carousel/pg-carousel.component';
import { PgCenterImageDirective } from './pg-center-image.directive';
import { SafePipe } from './safe.pipe';
import { TranslatorDirective, FormatterDirective } from './localization.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PgFilePreviewComponent } from './pg-file-preview/pg-file-preview.component';
import { ExternalLinksDirective } from './external-links.directive';
import { PgConfirmActionComponent } from './pg-confirm-action/pg-confirm-action.component';
import { LocTranslatePipe, LocFormatPipe, LocIconPipe } from './localization.pipe';
import { PgAnimationsDirective } from './pg-animations.directive';
import { PgImageCropModalComponent } from './pg-image-crop-modal/pg-image-crop-modal.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { AudioMixerService } from '../services/audio-mixer.service';
import { PgUploadButtonComponent } from './pg-upload-button/pg-upload-button.component';
import { PgQrcodeComponent } from './pg-qrcode/pg-qrcode.component';
import { PgGrowNumberDirective } from './pg-grow-number.directive';
import { QrLinksDirective } from './qr-links.directive';
import { QrLinksModalComponent } from './qr-links-modal/qr-links-modal.component';
import { PgGraphComponent } from './pg-graph/pg-graph.component';
import { PgCookieConsentComponent } from './pg-cookie-consent/pg-cookie-consent.component';
import { PgCollapsibleContainerDirective } from './pg-collapsible-container.directive';
import { PgNavTreeComponent } from './pg-nav-tree/pg-nav-tree.component';
import { PgShowcaseComponent } from './pg-showcase/pg-showcase.component';
import { PgMaintenanceComponent } from './pg-maintenance/pg-maintenance.component';
import { PgLanguageSelectorComponent } from './pg-language-selector/pg-language-selector.component';
import { PgNotificationComponent } from './pg-notification/pg-notification.component';
import { PgImageAutosizeComponent } from './pg-image-autosize/pg-image-autosize.component';
import { PgStripeConnectComponent } from './pg-stripe-connect/pg-stripe-connect.component';
import { PgSaveStatusComponent } from './pg-save-status/pg-save-status.component';
import { PgViewMoreComponent } from './pg-view-more/pg-view-more.component';
import { PgCopyClipboardComponent } from './pg-copy-clipboard/pg-copy-clipboard.component';
import { PgModalComponent } from './pg-modal/pg-modal.component';
import { PgRealmCardComponent } from './pg-realm-card/pg-realm-card.component';

@NgModule({
    declarations: [
        PgLoadingComponent,
        PgCarouselComponent,
        PgCarouselComponentSlide,
        PgCenterImageDirective,
        PgGrowNumberDirective,
        TranslatorDirective,
        FormatterDirective,
        SafePipe,
        LocTranslatePipe,
        LocFormatPipe,
        PgFilePreviewComponent,
        ExternalLinksDirective,
        PgConfirmActionComponent,
        PgAnimationsDirective,
        PgImageCropModalComponent,
        PgUploadButtonComponent,
        PgQrcodeComponent,
        QrLinksDirective,
        QrLinksModalComponent,
        PgGraphComponent,
        PgCookieConsentComponent,
        PgCollapsibleContainerDirective,
        PgNavTreeComponent,
        PgShowcaseComponent,
        PgMaintenanceComponent,
        PgLanguageSelectorComponent,
        PgNotificationComponent,
        PgImageAutosizeComponent,
        PgStripeConnectComponent,
        PgSaveStatusComponent,
        LocIconPipe,
        PgViewMoreComponent,
        PgCopyClipboardComponent,
        PgModalComponent,
        PgRealmCardComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        ImageCropperModule
    ],
    exports: [
        PgLoadingComponent,
        PgCarouselComponent,
        PgCarouselComponentSlide,
        PgCenterImageDirective,
        PgGrowNumberDirective,
        TranslatorDirective,
        FormatterDirective,
        SafePipe,
        LocTranslatePipe,
        LocFormatPipe,
        PgFilePreviewComponent,
        ExternalLinksDirective,
        PgConfirmActionComponent,
        PgAnimationsDirective,
        PgImageCropModalComponent,
        PgUploadButtonComponent,
        PgQrcodeComponent,
        QrLinksDirective,
        PgGraphComponent,
        PgCookieConsentComponent,
        PgCollapsibleContainerDirective,
        PgNavTreeComponent,
        PgShowcaseComponent,
        PgMaintenanceComponent,
        PgLanguageSelectorComponent,
        PgNotificationComponent,
        PgImageAutosizeComponent,
        PgStripeConnectComponent,
        PgSaveStatusComponent,
        LocIconPipe,
        PgViewMoreComponent,
        PgCopyClipboardComponent,
        PgModalComponent,
        PgRealmCardComponent
    ],
    providers: [{
        provide: APP_INITIALIZER,
        useFactory: (audioMixerService:AudioMixerService): Function => {
            return () => {
                return new Promise((resolve, reject) => {
                    audioMixerService.init();
                    resolve(null);
                })
            };
        },
        deps: [AudioMixerService],
        multi: true
    }]
})
export class PgUiElementsModule { }
