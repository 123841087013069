import { Component, forwardRef, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimetableData, TimetableDataDays, TimetableDataHour } from '../../models/timetable.model';
import { LocalizationService } from '../../services/localization.service';
import moment from 'moment';

@Component({
    selector: 'app-pg-timetable-editor',
    templateUrl: './pg-timetable-editor.component.html',
    styleUrls: ['./pg-timetable-editor.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR, 
        useExisting: forwardRef(() => PgTimetableEditorComponent),
        multi: true
    }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => PgTimetableEditorComponent),
        multi: true,
    }]
})
export class PgTimetableEditorComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @Input() fieldId:string;

    @Input() readonly:boolean;
    @Input() required:boolean;

    @Input() timetableMode:'slots'|'openings'|'periods'|'dates'|'departures'|'days'

    @Input() multi:boolean;

    @Input() timezone:string;

    value:Array<TimetableData> = null;

    editValue:TimetableData = null;

    private _timetableDataDefault:string = null;

    constructor(private localizationService:LocalizationService, private modalService:NgbModal) {}

    ngOnInit(): void {

    }

    ngOnChanges() {
        // NB: al momento il campo risulta non compilato correttamente se manca slot duration
        // slot duration è obbligatorio, ma se gli setto questo default non dà errore di validazione, anche se il campo è null
        //if(this.timetableMode == 'slots') this._timetableDataDefault = { slotDuration: '01:00' }

        if(this.timetableMode == 'dates') {
            this._timetableDataDefault = JSON.stringify({
                slotDuration:'00:00'
            })
        }
        else if(this.timetableMode == 'days') {
            this._timetableDataDefault = JSON.stringify({
                slotDuration:'00:00',
                hours: [new TimetableDataHour()]
            })
        }
        else {
            this._timetableDataDefault = null;
        }

        if(this.timezone != null) {
            this.timezone = moment.tz.guess()
        }

        this._alignSlotDurationAndTimezone();
    }

    getDayName(day:number, short?:boolean) {
        let _cName = this.localizationService.data.dayNames[day];
        if(short) _cName = _cName.substring(0, 2);
        return _cName;
    }

    editHourTarget:TimetableDataHour = null;
    editHour:TimetableDataHour = null;

    editHourItem(item:TimetableDataHour) {
        this.editHourTarget = item;
        this.editHour = JSON.parse(JSON.stringify(item))
        this.openAddHourModal();
    }

    addHourItem() {
        this.editHourTarget = null;
        this.editHour = new TimetableDataHour();

        this.onEditHourChange();

        this.openAddHourModal();
    }

    onEditHourChange() {
        if(this.timetableMode != 'openings') {
            this.editHour.end = this._calculateEndTime(this.editHour.begin)
        }
    }

    addHour() {
        if(this.editHourTarget != null) {
            for(let i in this.editHour) {
                this.editHourTarget[i] = this.editHour[i];
            }

            this.editHourTarget = null;
        }
        else {
            this.editValue.hours.push(this.editHour)
        }

        this.onValueChange();
    }

    removeHour(item:TimetableDataHour) {
        let _cIndex =  this.editValue.hours.indexOf(item);

        if(_cIndex != -1) {
            this.editValue.hours.splice(_cIndex, 1)
            this.onValueChange();
        }
    }

    editDaysValueTarget:TimetableData = null;

    editValueDates(val:TimetableData) {
        this.editDaysValueTarget = val;

        if(val.days[0] != null) {
            this.editDays = JSON.parse(JSON.stringify(val.days[0]));
        }
        else {
            this.editDays = new TimetableDataDays();
        }

        this.onPeriodMonthChange('begin')
        this.onPeriodMonthChange('end')
        this.openAddDaysModal();
    }

    editDaysTarget:TimetableDataDays = null;
    editDays:TimetableDataDays = null;
    editDaysUnavailable:boolean = null;

    editDaysItem(item:TimetableDataDays) {
        this.editDaysValueTarget = null;
        this.editDaysTarget = item;
        this.editDays = JSON.parse(JSON.stringify(item))
        this.onPeriodMonthChange('begin')
        this.onPeriodMonthChange('end')
        this.openAddDaysModal();
    }

    addDaysItem() {
        this.editDaysValueTarget = null;
        this.editDaysTarget = null;
        this.editDays = new TimetableDataDays();
        this.editDaysUnavailable = null;
        this.onPeriodMonthChange('begin')
        this.onPeriodMonthChange('end')
        this.openAddDaysModal();
    }

    addDays() {
        if(this.editDaysTarget != null) {
            for(let i in this.editDays) {
                this.editDaysTarget[i] = this.editDays[i]
            }

            this.editDaysTarget = null;
        }
        else {
            if(this.multi) {
                if(this.editDaysValueTarget != null) {
                    this.editDaysValueTarget.days = [this.editDays];
                }
                else {
                    let _cValue = new TimetableData(this._timetableDataDefault);
                    _cValue.unavailable = this.editDaysUnavailable;
                    _cValue.days[0] = this.editDays;
                    if(_cValue.unavailable || this.timetableMode == 'days') {
                        _cValue.hours = [{
                            begin: '00:00',
                            end: null,
                            days: [true,true,true,true,true,true,true]
                        }]
                    }
                    this.value.push(_cValue);
                    this._alignSlotDurationAndTimezone();
                    this.editValue = _cValue;
                }
            }
            else {
                this.editValue.days.push(this.editDays)
            }
        }

        this.onValueChange();
    }

    removeDays(item:TimetableDataDays) {
        let _cIndex =  this.editValue.days.indexOf(item);

        if(_cIndex != -1) {
            this.editValue.days.splice(_cIndex, 1)
            this.onValueChange();
        }
    }

    getSlotDuration(days?:boolean) {
        if(days) {
            let _val:number = null;

            if(this.value[0].slotDuration != null) {
                let _timeSplit = this.value[0].slotDuration.split(':');
                _val = parseInt(_timeSplit[0]) * 60 + parseInt(_timeSplit[1])
                _val /= 1440
            }

            return _val;
        }
        else {
            return this.value[0].slotDuration
        }
    }

    setSlotDuration(val:string|number, days?:boolean) {
        if(typeof val == 'number') val = val.toString();

        if(days) {
            let _val:string = null;

            if(val != null) {
                let _minutes = parseFloat(val) * 1440;

                let _hours = Math.floor(_minutes / 60);
                _minutes = (_minutes % 60);

                _val = _hours + ':' + (_minutes < 10 ? '0' : '') + _minutes;
            }

            this.value[0].slotDuration = _val;
        }
        else {
            this.value[0].slotDuration = val;
        }

        this._alignSlotDurationAndTimezone()

        this.onValueChange();
    }

    getTimezone() {
        if(this.timezone != null) return this.timezone;
        else {
            return this.value[0].timezone
        }
    }

    setTimezone(val:string) {
        this.value[0].timezone = val;

        this._alignSlotDurationAndTimezone()

        this.onValueChange();
    }

    private _alignSlotDurationAndTimezone() {
        if(this.value != null) {
            for(let _cVal of this.value) {
                _cVal.slotDuration = this.getSlotDuration() as string;
                _cVal.timezone = this.getTimezone();
            }
        }
    }

    private _originalMonthNames:Array<string> = null;
    private _shortMonthNames:Array<string> = null;

    getMonthSelectOptions() {
        if(this._originalMonthNames != this.localizationService.data.monthNames) {
            this._originalMonthNames = this.localizationService.data.monthNames;

            this._shortMonthNames = [];
            for(let _cName of this._originalMonthNames) {
                this._shortMonthNames.push(_cName)//.substr(0, 3))
            }
        }

        return this._shortMonthNames;
    }

    periodDaySelectOptions = {
        begin: [],
        end: []
    }
    
    getTimetableDataDaysYear(days:TimetableDataDays, part:'begin'|'end', ifNull?:number) {
        let _cYear = days.year;

        if(_cYear == null) {
            if(ifNull == null) return null;
            else {
                _cYear = ifNull;
            }
        }
        
        if(part == 'end' && days.end.month * 100 + days.end.day < days.begin.month * 100 + days.begin.day ) {
            _cYear++;
        }

        return _cYear;
    }

    onPeriodMonthChange(part:'begin'|'end') {
        let _cMonth = this.editDays[part].month;

        let _maxMonth = new Date(this.getTimetableDataDaysYear(this.editDays, part, 1971), _cMonth, 0).getDate(); // NB: uso il 1971 perché non è bisestile, in modo che febbraio risulti di 28 giorni, è possibile che sia da modificare?

        this.periodDaySelectOptions[part] = [];
        for(let i = 0; i < _maxMonth; i++) {
            this.periodDaySelectOptions[part].push(i + 1);
        }

        this.editDays[part].day = Math.min(this.editDays[part].day, _maxMonth)
    }

    private _yearOptions:Array<number> = null;

    getYearSelectOptions() {
        if(this._yearOptions == null) {
            this._yearOptions = [];
            
            let _start = (new Date()).getFullYear();

            for(let i = 0; i < 10; i++) {
                this._yearOptions.push(_start + i)
            }
        }

        return this._yearOptions;
    }

    getPeriodYear() {
        if(this.editDays.year == null) return '';
        else return this.editDays.year.toString();
    }

    setPeriodYear(val:string) {
        if(val == '') this.editDays.year = null;
        else this.editDays.year = parseInt(val);
    }

    onPeriodYearChange() {
        this.onPeriodMonthChange('begin')
        this.onPeriodMonthChange('end')
    }

    dayBeginOptions:Array<number> = [];
    dayEndOptions:Array<number> = [];

    formatPeriodDate(days:TimetableDataDays, part:'begin'|'end', short?:boolean) {
        let _year = this.getTimetableDataDaysYear(days, part)

        return  this.localizationService.format.date({ year: _year, month: days[part].month, day: days[part].day }, !short)
    }

    private _calculateEndTime(begin:string) {
        let _duration = 0;
        if(this.editValue.slotDuration != null) _duration = parseInt(this.editValue.slotDuration.split(':')[0]) * 60 + parseInt(this.editValue.slotDuration.split(':')[1])

        let _cDate = new Date(1970, 1, 1, parseInt(begin.split(':')[0]), parseInt(begin.split(':')[1]));
        _cDate.setMinutes(_cDate.getMinutes() + _duration);

        if(_duration == 0) {
            return null;
        }
        else {
            let _retVal = '';
            if(_cDate.getHours() < 10) _retVal += '0';
            _retVal += _cDate.getHours();
            _retVal += ':'
            if(_cDate.getMinutes() < 10) _retVal += '0';
            _retVal += _cDate.getMinutes();

            return _retVal;
        }
    }

    onValueChange() {        
        if(this.timetableMode == 'slots' || this.timetableMode == 'departures') {
            for(let _cVal of this.value) {
                for(let _cHour of _cVal.hours) {
                    _cHour.end = this._calculateEndTime(_cHour.begin)
                }   
            }
        }

        if(this._onTouched) this._onTouched();
        if(this._onChange) {
            if(this.multi) {
                let _outVal = '';
                
                for(let _cVal of this.value) {
                    if(_outVal != '') _outVal += ','
                    _outVal += JSON.stringify(_cVal);
                }

                this._onChange('[' + _outVal + ']')
            }
            else {
                this._onChange(JSON.stringify(this.value[0]))
            }
        }
    }

    removeValue(val:TimetableData) {
        let _cIndex = this.value.indexOf(val);

        if(_cIndex != -1) {
            this.value.splice(_cIndex, 1)

            if(this.value.length == 0) {
                this.value.push(new TimetableData(this._timetableDataDefault))
            }

            if(this.editValue == val) {
                this.editValue = this.value[Math.max(0, _cIndex - 1)]
            }
        }

        this.onValueChange();
    }

    formatValuePeriods(val:TimetableData, short?:boolean) {
        if(val.days.length == 0) return this.localizationService.translate('pg-timetable-editor.all-year');
        else {
            let _retVal = '';

            for(let _cPeriod of val.days) {
                if(_retVal != '') _retVal += '<br/>'
                _retVal += this.localizationService.translate('pg-timetable-editor.from') + ' ' + this.formatPeriodDate(_cPeriod, 'begin', short) + ' ' + this.localizationService.translate('pg-timetable-editor.to') + ' ' + this.formatPeriodDate(_cPeriod, 'end', short)
            }

            return _retVal;
        }
    }

    @ViewChild('modalAddHour', { static: true }) modalAddHour;

    private _modalAddHourRef = null;

    openAddHourModal() { 
        this._modalAddHourRef = this.modalService.open(this.modalAddHour, { size: 'lg' });
    }

    dismissAddHourModal() {
        this._modalAddHourRef.dismiss();
    }

    @ViewChild('modalAddDays', { static: true }) modalAddDays;

    private _modalAddDaysRef = null;

    openAddDaysModal() { 
        this._modalAddDaysRef = this.modalService.open(this.modalAddDays, { size: 'lg' });
    }

    dismissAddDaysModal() {
        this._modalAddDaysRef.dismiss();
    }

    @ViewChild('modalAddDate', { static: true }) modalAddDate;

    private _modalAddDateRef = null;

    openAddDateModal() { 
        this._modalAddDateRef = this.modalService.open(this.modalAddDate, { size: 'lg' });
    }

    dismissAddDateModal() {
        this._modalAddDateRef.dismiss();
    }

    addDateItem() {
        this.editDateFrom = null;
        this.editDateTo = null;
        this.openAddDateModal();
    }

    editDateFrom:string = null;
    editDateTo:string = null;

    canAddDate() {
        return this.editDateFrom != null && /\d\d\d\d-\d\d-\d\d/.test(this.editDateFrom)
    }

    addDate() {
        let _fromSplit = this.editDateFrom.split('-')

        if(this.editDateTo == null || this.editDateTo == '') this.editDateTo = this.editDateFrom;
        let _toSplit = this.editDateTo.split('-')

        let _addValue = {
            begin: {
                month:  parseInt(_fromSplit[1]),
                day:  parseInt(_fromSplit[2])
            },
            end: {
                month:  parseInt(_toSplit[1]),
                day:  parseInt(_toSplit[2])
            },
            year: parseInt(_fromSplit[0])
        }

        let _cValue = new TimetableData(this._timetableDataDefault);
        _cValue.days[0] = _addValue;
        this.value.push(_cValue);

        this.editDateFrom = null;
        this.editDateTo = null;

        this.onValueChange();
    }

    removeDate(item:TimetableData) {
        let _cIndex =  this.value.indexOf(item);

        if(_cIndex != -1) {
            this.value.splice(_cIndex, 1)
            this.onValueChange();
        }
    }

    stringToNumber(val:string) {
        if(val != null) return parseInt(val)
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        let _cObj = null;
        
        if(obj != null && obj != '') {
            try {
                _cObj = JSON.parse(obj);
            }
            catch(ex) {
            }
        }

        if(_cObj == null) {
            if(this.timetableMode == 'dates') {
                this.value = []
            }
            else {
                this.value = [new TimetableData(this._timetableDataDefault)]
            }
        }
        else {
            if('length' in _cObj) {
                this.value = [];

                if(this.timetableMode != 'dates' && _cObj.length == 0) {
                    this.value = [new TimetableData(this._timetableDataDefault)]
                }
                else {
                    for(let i = 0; i < _cObj.length; i++) {
                        this.value.push(new TimetableData(_cObj[i]))
                    }
                }
            }
            else {
                this.value = [new TimetableData(_cObj)]
            }
        }

        this.editValue = this.value[0]
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        if(this.required && this.value == null) {
            return {
                required: {
                    valid: false
                }
            }
        }
    };
}
