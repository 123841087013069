import { Injectable } from '@angular/core';

import * as Mustache from 'mustache';
import { ConfigRelation } from '../models/config.relations.model';
import { ConfigSelectOptionList } from '../models/form.model';
import { ConfigService } from './config.service';
import { DataService } from './data.service';
import { LocalizationService } from './localization.service';

export class ConfigSemanticsRelations {
    xTo1: Array<ConfigRelation> = [];
    xToN: Array<ConfigRelation> = [];
}

@Injectable({
    providedIn: 'root'
})
export class SemanticsService {
    constructor(private semanticsEvaluatorService:SemanticsEvaluatorService, private dataService:DataService, private configService:ConfigService, private localizationService:LocalizationService) {}

    getResourceSemantics(resourceId:string) {
        return this.configService.getResourceSemantics(resourceId);
    }

    getResourceSemantic(resourceId:string, role:string):string { 
        return this.configService.getResourceSemantic(resourceId, role);
    }

    getSemanticUsedRelations(resourceId:string, role:string, mergeUsedRelations?:ConfigSemanticsRelations) {
        let _usedRelations = mergeUsedRelations;
        if(_usedRelations == null) _usedRelations = new ConfigSemanticsRelations();

        let _allRelations = this.configService.getResourceRelations(resourceId);

        // controllo gli elementi in relazione effettivamente presenti nei role per richiedere solo quelli utili

        let _relatedMatch = role.match(/related_[^\.^\{^\}^\s]*/g);

        if(_relatedMatch != null) {
            for(let _cMatch of _relatedMatch) {
                let _cTarget = _cMatch.match(/related_([^\.^\{^\}^\s]*)/)[1];

                // TODO: andrebbero differenziati i formati dei related in qualche modo, ora hanno lo stesso formato (ie: related_file, related_struttura_id)

                // relazioni su un field x a 1

                for(let _cRelation of _allRelations) {
                    if(_cRelation.type == 'N:1' && _cRelation.joinField == _cTarget) {
                        if(_usedRelations.xTo1.indexOf(_cRelation) == -1) _usedRelations.xTo1.push(_cRelation);
                        break;
                    }
                }

                // relazioni x a n

                _cTarget = _cTarget.charAt(0).toUpperCase() + _cTarget.substr(1, _cTarget.length);

                for(let _cRelation of _allRelations) {
                    if((_cRelation.type == 'N:N' || _cRelation.type == '1:N') && _cTarget == _cRelation.modelB) {
                        if(_usedRelations.xToN.indexOf(_cRelation) == -1) _usedRelations.xToN.push(_cRelation);
                        break;
                    }
                }
            }
        }

        return _usedRelations;
    }

    getResourceSemanticsUsedRelations(resourceId:string) {
        let _usedRelations = new ConfigSemanticsRelations();

        let _roles = this.configService.getResourceGeneralConfig(resourceId).roles;

        for(let i in _roles) {
            if(_roles[i] != null) {
                this.getSemanticUsedRelations(resourceId, _roles[i], _usedRelations);
            }
        }

        return _usedRelations;
    }

    getSelectOptionsFromResourceData(resourceId:string, data:Array<any>, noLimit?:boolean):ConfigSelectOptionList {
        let _retVal = new ConfigSelectOptionList();

        let _resourceSemantic = this.getResourceSemantic(resourceId, 'select');
        let _replaceMatches = _resourceSemantic.match(/\{\{[^\{]*\}\}/g);

        let _retLength = data.length;

        if(noLimit) {
            _retVal.complete = true;
        }
        else {
            _retVal.complete = data.length <= this.dataService.selectLimit;
            _retLength = Math.min(this.dataService.selectLimit, data.length);
        }

        for(let i = 0; i < _retLength; i++) {
            let _cText = _resourceSemantic;

            for(let _cMatch of _replaceMatches) {
                _cText = _cText.replace(_cMatch, data[i][_cMatch.substr(2, _cMatch.length - 4)])
            }

            _retVal.options.push({
                value: data[i].id,
                text: _cText
            })
        }

        return _retVal;
    }

    evaluateResourceSemantics(resourceId:string, data:any) {
        let _rolesIndex = this.configService.getResourceGeneralConfig(resourceId).roles;

        for(let i in _rolesIndex) {
            _rolesIndex[i] = this.evaluateResourceSemanticTemplate(resourceId, _rolesIndex[i], data);
        }

        return _rolesIndex;
    }

    evaluateResourceSemantic(resourceId:string, role:string, data:any) {
        let _rolesIndex = this.configService.getResourceGeneralConfig(resourceId).roles;

        return this.evaluateResourceSemanticTemplate(resourceId, _rolesIndex[role], data);
    }

    evaluateResourceSemanticTemplate(resourceId:string, template:string, data:any) {
        if(template == null) {
            return '';
        }
        else {

            data = JSON.parse(JSON.stringify(data))

            if(data.translations != null) {
                for(let _cTranslation of data.translations) {
                    if(_cTranslation.language == this.localizationService.currentLanguage || _cTranslation.language == this.localizationService.currentLanguage.replace(/_.*$/, '').toUpperCase()) {
                        for(let i in _cTranslation) {
                            if(_cTranslation[i] != null && _cTranslation[i] != '') {
                                data[i] = _cTranslation[i];
                            }
                        }
                    }
                }
            }

            delete data.translations;

            data.link = () => {
                return (val, render) => {
                    
                    let _cSemantic = val.split(',')[0].replace(/\s/g, '');
                    let _cField = val.split(',')[1].replace(/\s/g, '').replace(/^related_/, '');

                    if(data['related_' + _cField] != null) {
                        for(let _cRelation of this.configService.getResourceRelations(resourceId)) {

                            if(_cRelation.joinField == _cField) {
                                return this.semanticsEvaluatorService.evaluateSemanticTemplate(this.configService.getResourceGeneralConfig(_cRelation.modelB).roles[_cSemantic], data['related_' + _cField]);
                            }
                        }
                    }

                    return '';
                };
            }

            let _cConfig = this.configService.getResourceFieldTypes(resourceId);

            for(let _cField in _cConfig) {
                if(_cConfig[_cField].type == 'json') {
                    if(data[_cField] != null && typeof data[_cField] == 'string') {
                        data[_cField] = JSON.parse(data[_cField]);
                    }
                }
            }
            
            return this.semanticsEvaluatorService.evaluateSemanticTemplate(template, data);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class SemanticsEvaluatorService {
    evaluateSemanticTemplate(template:string, data:any) { 
        return Mustache.render(template, data);
    }
}