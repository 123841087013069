import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from '../clipboard.service';

export class PgCopyClipboardOption {
    value: string;
    text: string;
}

@Component({
  selector: 'app-pg-copy-clipboard',
  templateUrl: './pg-copy-clipboard.component.html',
  styleUrls: ['./pg-copy-clipboard.component.scss']
})
export class PgCopyClipboardComponent implements OnInit {

    constructor(private clipboardService:ClipboardService) { }

    @Input() text:string;
    @Input() options:Array<PgCopyClipboardOption>;

    ngOnInit(): void {
    }

    @ViewChild('copyClipboardTooltip') copyClipboardTooltip:NgbTooltip;

    private _copyClipboardTooltipTimeout = null;

    copyClipboardText(text:string) {
        if(text != null) {
            this.clipboardService.copyText(text)
    
            clearTimeout(this._copyClipboardTooltipTimeout);
    
            this.copyClipboardTooltip.open();
    
            this._copyClipboardTooltipTimeout = setTimeout(() => {
                this.copyClipboardTooltip.close();
            }, 2000)
        }
    } 
}
